import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
    StandardForm,
    ParkingTollsForm,
    CarRentalForm,
    BaggageForm,
    AirFareForm,
    MilesDrivenForm,
    HotelForm,
} from './EditableExpenditureForms';
import errorToast from '../../components/messages/ErrorMessage';
import UserApi from '../../api/users';
import TravelExpenseApi from '../../api/travelExpenses';
import { prepareCardHolderOptions } from '../../utils/UserUtils';
import { prepareTravelExpenditureTypeOptions, getTravelExpenditureType } from '../../utils/TravelUtils';
import ExpenditureType from '../../utils/ExpenditureUtils';

const TravelExpenditureFormManager = (props) => {
    const [cardHolderOptions, setCardHolderOptions] = useState([]);
    const [travelExpenditureTypeOptions, setTravelExpenditureTypeOptions] = useState([]);
    const [travelExpenditureFormIndex, setTravelExpenditureFormIndex] = useState(0);
    const [travelExpenditureForms, setTravelExpenditureForms] = useState([]);
    const [selectedTravelExpenditureTypeOption, setSelectedTravelExpenditureTypeOption] = useState('taxi_uber');

    const selectTravelExpenditureTypeOption = (travelExpenditureTypeOptionSelection) => {
        setSelectedTravelExpenditureTypeOption(
            travelExpenditureTypeOptionSelection.value || travelExpenditureTypeOptionSelection
        );
    };

    const pageRight = useCallback(() => {
        setTravelExpenditureFormIndex(travelExpenditureFormIndex + 1);
    }, [travelExpenditureFormIndex]);

    const pageLeft = useCallback(() => {
        setTravelExpenditureFormIndex(travelExpenditureFormIndex - 1);
    }, [travelExpenditureFormIndex]);

    const cancelExpenditure = useCallback(() => {
        const { setExpenditures } = props;
        selectTravelExpenditureTypeOption(travelExpenditureTypeOptions[0].value);
        setExpenditures([]);
    }, [props, travelExpenditureTypeOptions]);

    const { travelExpendituresToUpdate } = props;
    useEffect(() => {
        if (travelExpendituresToUpdate.length > 0) {
            if (travelExpenditureFormIndex === travelExpendituresToUpdate.length) {
                setTravelExpenditureFormIndex(travelExpendituresToUpdate.length - 1);
            } else if (travelExpenditureFormIndex < 0) {
                setTravelExpenditureFormIndex(0);
            }
        }
    }, [travelExpenditureFormIndex, travelExpendituresToUpdate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: users } = await UserApi.getUsers();

                const tempCardHolderOptions = prepareCardHolderOptions(users);
                setCardHolderOptions(tempCardHolderOptions);

                const tempTravelExpenditureTypeOptions = prepareTravelExpenditureTypeOptions();
                setTravelExpenditureTypeOptions(tempTravelExpenditureTypeOptions);
            } catch (error) {
                errorToast(error);
            }
        };
        fetchData();
    }, []);

    const createDefaultTravelExpenditureFormValues = useCallback(() => {
        try {
            const { perDays: perDayCharges, cardHolderId, rowToUpdate } = props;
            const perDayMiles = {};
            const hotelNightRate = {};
            let milesPerCharge = 0;
            if (perDayCharges.length) {
                perDayCharges.forEach((perDayCharge) => {
                    perDayMiles[perDayCharge.date] = '';
                    hotelNightRate[perDayCharge.date] = '';
                });
                milesPerCharge = perDayCharges[0].mileage_rate;
            }

            let defaultCardHolderId = '';

            if (rowToUpdate) {
                const { cc_holder_id } = rowToUpdate;
                defaultCardHolderId = cc_holder_id;
            } else if (cardHolderId) {
                defaultCardHolderId = cardHolderId;
            }

            return {
                cc_holderId: defaultCardHolderId,
                travel_expenditure_type: selectedTravelExpenditureTypeOption,
                cc_type: ExpenditureType.business,
                purchase_date: '',
                store: '',
                cost: '',
                link_to_db: '',
                notes: '',
                expense_start_date: '',
                expense_end_date: '',
                hotel_non_reimburse: '',
                hotel_taxes_fees: '',
                per_day_miles: perDayMiles,
                milecharge: milesPerCharge,
                hotel_per_night_rate: hotelNightRate,
            };
        } catch (error) {
            errorToast(error);
        }
        return false;
    }, [props, selectedTravelExpenditureTypeOption]);

    const createTravelExpenditureFormValues = useCallback(
        (travelExpenditure) => {
            try {
                const { perDays: perDayCharges } = props;
                const perDayMiles = {};
                const hotelNightRate = {};
                let milesPerCharge = 0;
                if (perDayCharges.length) {
                    Object.entries(perDayCharges);
                    for (const [index, perDayCharge] of perDayCharges.entries()) {
                        perDayMiles[perDayCharge.date] = travelExpenditure.per_day_miles[index];
                        hotelNightRate[perDayCharge.date] = travelExpenditure.hotel_per_night_rate[index];
                    }
                    milesPerCharge = perDayCharges[0].mileage_rate;
                }

                const typeKey = getTravelExpenditureType(travelExpenditure);
                selectTravelExpenditureTypeOption(typeKey);
                return {
                    id: travelExpenditure.id,
                    cc_holder_id: travelExpenditure.expenditure.ccHolder_id,
                    travel_expenditure_type: typeKey,
                    cc_type: travelExpenditure.expenditure.cc_type,
                    purchase_date: travelExpenditure.expenditure.purchase_date,
                    store: travelExpenditure.expenditure.store,
                    cost: travelExpenditure.expenditure.cost,
                    link_to_db: travelExpenditure.expenditure.link_to_db,
                    notes: travelExpenditure.expenditure.notes,
                    expense_start_date: travelExpenditure.expense_start_date,
                    expense_end_date: travelExpenditure.expense_end_date,
                    hotel_non_reimburse: travelExpenditure.hotel_non_reimburse,
                    hotel_taxes_fees: travelExpenditure.hotel_taxes_fees,
                    per_day_miles: perDayMiles,
                    milecharge: milesPerCharge,
                    hotel_per_night_rate: hotelNightRate,
                };
            } catch (error) {
                errorToast(error);
            }
            return false;
        },
        [props]
    );

    const createMilesDrivenCharges = (perDayMiles) => {
        const milesDrivenCharges = Object.values(perDayMiles);
        return milesDrivenCharges.map((daysMiles) => Number(daysMiles || 0));
    };

    const createHotelNightRate = (hotelPerNightRate) => {
        const hotelNightRates = Object.values(hotelPerNightRate);
        return hotelNightRates.map((nightRate) => Number(nightRate || 0));
    };

    const sumHotelNightRates = (hotelNightRate) => hotelNightRate.reduce((sum, nightRate) => sum + nightRate, 0);

    const sumHotel = (values, hotelNightRateSum) => {
        const { hotel_non_reimburse, hotel_taxes_fees } = values;
        return Number(hotel_non_reimburse) + Number(hotel_taxes_fees) + Number(hotelNightRateSum);
    };

    const createExpenditure = useCallback(
        (values) => {
            try {
                const { cc_holder_id, cost, link_to_db, notes, purchase_date, store, cc_type } = values;
                const { accountId, isProject } = props;

                let tempPurchaseDate;
                if (purchase_date) {
                    tempPurchaseDate = new Date(purchase_date);
                }

                return {
                    ccHolder_id: cc_holder_id,
                    cost: Number(cost),
                    link_to_db,
                    notes,
                    purchase_date: tempPurchaseDate,
                    store,
                    cc_type,
                    account_id: accountId,
                    account_type: isProject ? 'Project' : 'CoreBudgetType',
                };
            } catch (error) {
                errorToast(error);
            }
            return false;
        },
        [props]
    );

    const createTravelExpenditure = useCallback(
        (values) => {
            try {
                const {
                    id,
                    travel_expenditure_type,
                    hotel_taxes_fees,
                    hotel_non_reimburse,
                    expense_start_date,
                    expense_end_date,
                    per_day_miles,
                    milecharge,
                    hotel_per_night_rate,
                    cc_holder_id,
                } = values;

                let milesDrivenCharges = [];
                let hotelNightRates = [];
                let type;
                const { label } = travel_expenditure_type;
                if (label) {
                    type = label;
                } else {
                    type = travel_expenditure_type;
                }
                if (type === 'miles_driven') {
                    milesDrivenCharges = createMilesDrivenCharges(per_day_miles);
                    values.cost = milesDrivenCharges.reduce((sum, charge) => (sum += charge)) * milecharge;
                } else if (type === 'hotel') {
                    hotelNightRates = createHotelNightRate(hotel_per_night_rate);
                    const hotelNightRateSum = sumHotelNightRates(hotelNightRates);
                    values.cost = sumHotel(values, hotelNightRateSum);
                }

                const expenditure = createExpenditure(values);

                const { value } = travel_expenditure_type;
                let typeValue;
                if (!value) {
                    typeValue = travel_expenditure_type;
                }
                return {
                    id,
                    travel_expenditure_type: typeValue,
                    hotel_taxes_fees: hotel_taxes_fees || 0,
                    hotel_non_reimburse: hotel_non_reimburse || 0,
                    expense_start_date: expense_start_date || null,
                    expense_end_date: expense_end_date || null,
                    per_day_miles: milesDrivenCharges,
                    expenditure,
                    hotel_per_night_rate: hotelNightRates,
                };
            } catch (error) {
                errorToast(error);
            }
            return false;
        },
        [createExpenditure]
    );

    const selectFormOnTravelExpenditureType = (selectedType, formProps) => {
        let travelExpenditureForm;
        switch (selectedType) {
            case 'parking_tolls':
                travelExpenditureForm = <ParkingTollsForm {...formProps} />;
                break;
            case 'miles_driven':
                travelExpenditureForm = <MilesDrivenForm {...formProps} />;
                break;
            case 'hotel':
                travelExpenditureForm = <HotelForm {...formProps} />;
                break;
            case 'car_rental':
                travelExpenditureForm = <CarRentalForm {...formProps} />;
                break;
            case 'baggage':
                travelExpenditureForm = <BaggageForm {...formProps} />;
                break;
            case 'airfare':
                travelExpenditureForm = <AirFareForm {...formProps} />;
                break;
            default:
                travelExpenditureForm = <StandardForm {...formProps} />;
        }

        return travelExpenditureForm;
    };

    const { rowToUpdate } = props;
    useEffect(() => {
        const deleteTravelExpenditure = async (travelExpenditureId) => {
            try {
                const { travelExpendituresToUpdate, setTableExpenditures, setExpenditures, tableExpenditures } = props;
                await TravelExpenseApi.deleteTravelExpenditure(travelExpenditureId);
                const travelExpenditures = travelExpendituresToUpdate.filter(
                    (travelExpenditure) => travelExpenditure.id !== travelExpenditureId
                );
                setExpenditures(travelExpenditures);
                const tableTravelExpenditures = tableExpenditures.filter(
                    (travelExpenditure) => travelExpenditure.id !== travelExpenditureId
                );
                setTableExpenditures(tableTravelExpenditures);
            } catch (error) {
                errorToast(error);
            }
        };

        const handleSubmit = async (values, actions) => {
            try {
                const {
                    setTravelExpenseId,
                    travelExpendituresToUpdate,
                    setTableExpenditures,
                    setExpenditures,
                    tableExpenditures,
                    expenseId,
                } = props;

                const travelExpenditure = createTravelExpenditure(values);

                if (expenseId) {
                    travelExpenditure.travel_expense_id = expenseId;
                } else if (rowToUpdate) {
                    travelExpenditure.travel_expense_id = rowToUpdate.id;
                }

                if (travelExpendituresToUpdate.length) {
                    const { id } = travelExpenditure;
                    const { data: createdTravelExpenditure } = await TravelExpenseApi.updateTravelExpenditure(
                        id,
                        travelExpenditure
                    );
                    const expenditures = travelExpendituresToUpdate.filter((expenditure) => expenditure.id !== id);
                    expenditures.push(createdTravelExpenditure);
                    setExpenditures(expenditures);
                    const filteredTableExpenditures = tableExpenditures.filter(
                        (tableExpenditure) => tableExpenditure.id !== id
                    );
                    filteredTableExpenditures.push(createdTravelExpenditure);
                    setTableExpenditures(filteredTableExpenditures);
                } else {
                    const { data: createdTravelExpenditure } = await TravelExpenseApi.createTravelExpenditure(
                        travelExpenditure
                    );
                    const { travel_expense_id: travelExpenseId } = createdTravelExpenditure;
                    setTravelExpenseId(travelExpenseId);
                    const newTableExpenditures = [...tableExpenditures, createdTravelExpenditure];
                    setTableExpenditures(newTableExpenditures);
                }
                cancelExpenditure();
                actions.setSubmitting(false);
                actions.resetForm();
            } catch (error) {
                errorToast(error);
            }
        };

        try {
            let travelExpenditureFormsValues = [];
            if (travelExpendituresToUpdate.length) {
                travelExpenditureFormsValues = travelExpendituresToUpdate.map((travelExpenditure) =>
                    createTravelExpenditureFormValues(travelExpenditure)
                );
            } else {
                const defaultTravelExpenditureFormValues = createDefaultTravelExpenditureFormValues();
                travelExpenditureFormsValues.push(defaultTravelExpenditureFormValues);
            }

            let disablePaging = true;
            if (travelExpendituresToUpdate && travelExpendituresToUpdate.length > 1) {
                disablePaging = false;
            }

            const tempTravelExpenditureForms = travelExpenditureFormsValues.map(
                (travelExpenditureFormValues, index) => {
                    const { readOnly } = props;
                    const formProps = {
                        formInitValues: travelExpenditureFormValues,
                        existingExpenditureId: travelExpenditureFormValues.id || undefined,
                        expenditureTypeOptions: travelExpenditureTypeOptions,
                        cardHolderOptions,
                        disablePaging,
                        cancelExpenditure,
                        selectTravelExpenditureTypeOption,
                        addExpenditure: handleSubmit,
                        deleteExpenditure: deleteTravelExpenditure,
                        pageRight,
                        pageLeft,
                        readOnly,
                    };

                    const travelExpenditureForm = selectFormOnTravelExpenditureType(
                        selectedTravelExpenditureTypeOption,
                        formProps
                    );
                    return <Fragment key={index}>{travelExpenditureForm}</Fragment>;
                }
            );
            setTravelExpenditureForms(tempTravelExpenditureForms);
        } catch (error) {
            errorToast(error);
        }
    }, [
        props,
        travelExpendituresToUpdate,
        cardHolderOptions,
        travelExpenditureTypeOptions,
        selectedTravelExpenditureTypeOption,
        rowToUpdate,
        pageRight,
        pageLeft,
        cancelExpenditure,
        createDefaultTravelExpenditureFormValues,
        createTravelExpenditureFormValues,
        createTravelExpenditure,
    ]);

    return <div>{travelExpenditureForms[travelExpenditureFormIndex]}</div>;
};

export default TravelExpenditureFormManager;
