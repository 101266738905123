import React from 'react';
import ReactModal from 'react-modal';

if (process.env.NODE_ENV !== 'test') {
    ReactModal.setAppElement('#root');
}

const Modal = ({ children, render, handleClose, style, ...props }) => (
    <ReactModal
        onRequestClose={handleClose}
        style={{
            overlay: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '90vw',
                maxHeight: '90vh',
                whiteSpace: 'normal',
                ...style,
            },
        }}
        shouldCloseOnOverlayClick={false}
        {...props}
    >
        {typeof render === 'function' ? render({ handleClose }) : children}
    </ReactModal>
);

export default Modal;
