import api from './api';

import endpoints from './endpoints';

const resFundRoute = endpoints.RESIDUAL_FUND;
const resFundWithFundsRoute = endpoints.RESIDUAL_FUND_WITH_FUNDS;

export default {
    getResidualFunds() {
        return api.get(resFundRoute, 'Error getting list of residual funds');
    },

    getResidualFundWithFundsTotal() {
        return api.get(resFundWithFundsRoute, 'Error getting list of residual funds with their balanace');
    },

    getResidualFund(rfId) {
        return api.get(`${resFundRoute}/${rfId}`, 'Error getting a residual funds');
    },

    createResidualFund(rfAttributes) {
        return api.post(resFundRoute, rfAttributes, 'Error creating a residual fund');
    },

    updateResidualFund(rfId, rfAttributes) {
        return api.put(`${resFundRoute}/${rfId}`, rfAttributes, 'Error updating a residual fund');
    },

    updateResidualFunds(residualFunds) {
        return api.put(resFundRoute, residualFunds, 'Error updating a group of residual funds');
    },

    getTransactions(rfId) {
        return api.get(
            `${resFundRoute}/${rfId}/transactions`,
            'Error getting list of transactions in within an residual fund'
        );
    },

    removeResidualFund(rfId) {
        return api.delete(`${resFundRoute}/${rfId}`, 'Error deleting an residual fund');
    },
};
