import api from './api';

import endpoints from './endpoints';

const modsRoute = endpoints.MODS;

export default {
    getMods() {
        return api.get(modsRoute, 'Error getting mods');
    },

    createMod(attributes) {
        return api.post(modsRoute, attributes, 'Error creating new mod');
    },

    updateMod(modId, attributes) {
        return api.put(`${modsRoute}/${modId}`, attributes, 'Error updating existing mod');
    },

    removeMod(modId) {
        return api.delete(`${modsRoute}/${modId}`, 'Error deleting existing mod');
    },
};
