import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Rollbar from 'rollbar';

import store, { history } from './store/store';
import AuthApi from './api/auth';
import App from './App';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import * as serviceWorker from './serviceWorker';

AuthApi.refreshToken();

const nodeEnv = process.env.NODE_ENV;

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rollbar: new Rollbar({
                enabled: nodeEnv === 'production',
                accessToken: 'c5970bb2863a4f5a809b6070449a5dec',
                captureUncaught: true,
                captureUnhandledRejections: true,
                payload: {
                    environment: 'production',
                },
            }),
        };
    }

    render() {
        return (
            <ErrorBoundary rollbar={this.state.rollbar}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <App />
                        {/* <div>ERGO Down for Construction</div> */}
                    </ConnectedRouter>
                </Provider>
            </ErrorBoundary>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
