import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import Modal from '../../components/modals/Modal';

const UpdateItineraryModal = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [locations, setLocations] = useState([]);
    const { values, setField, setVis, updateHook = null } = props;
    const { itinerary } = values;

    // Set Default Data / API Data
    useEffect(() => {
        const tempItinerary = itinerary;
        for (let i = 0; i < tempItinerary.length; i++) {
            tempItinerary[i].key = String(i);
        }
        setLocations(tempItinerary);
    }, [itinerary]);

    const getListStyle = () => ({
        width: '580px',
    });

    const deleteItem = (id) => {
        const tempLocations = _.clone(locations);
        _.remove(tempLocations, (n) => n.key === id.toString());

        setLocations(tempLocations);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const tempLocations = reorder(locations, result.source.index, result.destination.index);
        setLocations(tempLocations);
    };

    const updateItinerary = () => {
        const newItinerary = locations;
        for (let i = 0; i < newItinerary.length; i++) {
            newItinerary[i].sort_order = i;
        }
        setField('itinerary', newItinerary);
        setVis(0);
        setShowModal(false);
        if (updateHook) {
            values.itinerary = newItinerary;
            updateHook(values);
        }
    };

    return (
        <>
            <div className="Modal-cmd-container Form__buttons--Reversed">
                <button
                    onClick={() => {
                        setShowModal(true);
                    }}
                    className="Small-button"
                >
                    Edit Itinerary
                </button>

                <Modal
                    isOpen={showModal}
                    contentLabel="Update itinerary modal"
                    handleClose={() => {
                        setShowModal(false);
                    }}
                >
                    <button
                        className="Modal-close-btn "
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        <i className="fa fa-window-close" />
                    </button>

                    <div className="Itinerary-update-table">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <table ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                        <thead>
                                            <tr>
                                                <th className="itinerary-destination">Destination</th>
                                                <th className="itinerary-date-arrival">Date of Arrival</th>
                                                <th className="itinerary-date-return">Date of Return</th>
                                                <th className="itinerary-delete-entry">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {locations.map((item, index) => (
                                                <Draggable
                                                    id={item.key}
                                                    key={item.key}
                                                    draggableId={item.key}
                                                    index={index}
                                                >
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <td>
                                                            <div>{item.location_of_travel || item.city_state}</div>
                                                        </td>
                                                        <td>
                                                            <div>{item.date_of_travel || item.start_date}</div>
                                                        </td>
                                                        <td>
                                                            <div>{item.date_of_return || item.end_date}</div>
                                                        </td>
                                                        <td className="Itinerary-delete-cmd">
                                                            <div>
                                                                <button
                                                                    onClick={() => {
                                                                        deleteItem(index);
                                                                    }}
                                                                    id={index}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <div className="Modal-update-itinerary">
                        <a href="/#" className="text-align-left" onClick={updateItinerary}>
                            Update Itinerary
                        </a>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export { UpdateItineraryModal as default };
