import api from './api';

import endpoints from './endpoints';

const cpoRoute = endpoints.CPO;

export default {
    getCpos() {
        return api.get(cpoRoute, 'Error getting list of CPOs');
    },

    createCpo(cpoAttributes) {
        return api.post(cpoRoute, cpoAttributes, 'Error creating a new CPO');
    },

    updateCpo(cpoId, cpoAttributes) {
        return api.put(`${cpoRoute}/${cpoId}`, cpoAttributes, 'Error creating a new CPO');
    },

    removeCPO(cpoId) {
        return api.delete(`${cpoRoute}/${cpoId}`, 'Error creating a CPO paragraph');
    },
};
