import React from 'react';

const StatusSymbol = (props) => {
    let status = props.status;
    let content = null;

    if (status === 'good' || status === 'Enabled') {
        content = (
            <svg viewBox="0 0 28 28">
                <path
                    className="Good"
                    d="M14,2c6.6,0,12,5.4,12,12s-5.4,12-12,12S2,20.6,2,14S7.4,2,14,2 M14,0C6.3,0,0,6.3,0,14s6.3,14,14,14s14-6.3,14-14S21.7,0,14,0z"
                />
                <g>
                    <polygon className="Good" points="11.6,20.4 5.8,14.7 7.2,13.3 11.6,17.5 20.8,8.3 22.2,9.8 	" />
                </g>
            </svg>
        );
    } else if (status === 'caution') {
        content = (
            <svg viewBox="0 0 28 28">
                <path
                    className="Caution"
                    d="M14,2c6.6,0,12,5.4,12,12s-5.4,12-12,12S2,20.6,2,14S7.4,2,14,2 M14,0C6.3,0,0,6.3,0,14s6.3,14,14,14s14-6.3,14-14S21.7,0,14,0z"
                />
                <g>
                    <rect x="4.1" y="13" className="Caution" width="19.8" height="2" />
                </g>
            </svg>
        );
    } else {
        content = (
            <svg viewBox="0 0 28 28">
                <path
                    className="Bad"
                    d="M14,2c6.6,0,12,5.4,12,12s-5.4,12-12,12S2,20.6,2,14S7.4,2,14,2 M14,0C6.3,0,0,6.3,0,14s6.3,14,14,14s14-6.3,14-14S21.7,0,14,0z"
                />
                <polygon
                    className="Bad"
                    points="21.7,7.7 20.3,6.3 14,12.6 7.7,6.3 6.3,7.7 12.6,14 6.3,20.3 7.7,21.7 14,15.4 20.3,21.7 21.7,20.3 15.4,14 "
                />
            </svg>
        );
    }

    return <div className="StatusSymbol">{content}</div>;
};

export default StatusSymbol;
