import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import useFunctionAsState from '../../hooks/useFunctionAsState';
import TableFactory from './factory/tableFactory';
import PageSizeSelector from './pagingTable/pageSizeSelector';

const PagingTable = ({
    data,
    columns,
    sizeToFit,
    pageSizeProp,
    editable,
    sortable,
    filter,
    onTableReady,
    addedNewData,
    vendorToolTipEnable,
    overrideGridReady,
    tableClassName,
}) => {
    const DEFAULT_PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [startPageSize, setStartPageSize] = useState();
    const [gridApi, setGridApi] = useFunctionAsState();

    const resizeGrid = useCallback(() => {
        if (gridApi) {
            gridApi.resetRowHeights();

            if (sizeToFit) {
                gridApi.sizeColumnsToFit();
            }
        }
    }, [gridApi, sizeToFit]);

    useEffect(() => {
        resizeGrid();
    }, [gridApi, resizeGrid]);

    const onGridReady = useCallback(
        (params) => {
            const { api: gridApi } = params;
            setGridApi(gridApi);

            const exportDataAsCsv = (nameOfExport) => {
                const filename = nameOfExport + '-' + moment().format('YYYYMDDHHmmss');
                gridApi.exportDataAsCsv({ fileName: filename });
            };

            if (overrideGridReady && typeof overrideGridReady === 'function') {
                overrideGridReady(params);
            }

            if (onTableReady && typeof onTableReady === 'function') {
                const tableParams = {
                    ...params,
                    exportDataAsCsv,
                };
                onTableReady(tableParams);
            }
        },
        [setGridApi, onTableReady, overrideGridReady]
    );

    useEffect(() => {
        if (pageSizeProp) {
            setPageSize(pageSizeProp);
            setStartPageSize(pageSizeProp);
        } else {
            setPageSize(DEFAULT_PAGE_SIZE);
            setStartPageSize(DEFAULT_PAGE_SIZE);
        }
    }, [pageSizeProp]);

    const onPageSizeChanged = (e) => {
        let pageSize = e.target.value;
        setPageSize(pageSize);
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.paginationSetPageSize(pageSize);
        }
    }, [gridApi, pageSize]);

    useEffect(() => {
        if (gridApi && addedNewData) {
            gridApi.setRowData(data);
        }
    }, [gridApi, addedNewData, data]);

    const table = useMemo(
        () => (
            <TableFactory
                rowData={data}
                columns={columns}
                gridReady={onGridReady}
                resizeGrid={resizeGrid}
                paging={true}
                pageSize={pageSize}
                sortable={sortable}
                filter={filter}
                editable={editable}
                vendorToolTipEnable={vendorToolTipEnable}
                tableClassName={tableClassName}
            >
                <PageSizeSelector
                    onPageSizeChanged={onPageSizeChanged}
                    pageSize={pageSize}
                    startPageSize={startPageSize}
                />
            </TableFactory>
        ),
        [
            data,
            columns,
            sortable,
            filter,
            editable,
            vendorToolTipEnable,
            tableClassName,
            pageSize,
            startPageSize,
            onGridReady,
            resizeGrid,
        ]
    );

    return <div className="Table_wrap">{table}</div>;
};

export default PagingTable;
