// React
import React, { Component } from 'react';

// Libraries
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

// Components
import FinanceSubNav from './FinanceSubNav';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';
import { confirm } from '../components/modals/ConfirmModal';
import PageHeader from '../components/heading/PageHeader';
import Table from '../components/tables/Table_old';
import errorToast from '../components/messages/ErrorMessage';
import ExportCSVButton from '../components/buttons/ExportCSVButton';

// API
import CommercialsApi from '../api/commercials';
import ProjectsApi from '../api/projects';

class CommercialPage extends Component {
    constructor(props) {
        super(props);
        this.getRowData = this.getRowData.bind(this);
        this.populateProjects = this.populateProjects.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.onTableReady = this.onTableReady.bind(this);

        this.state = {
            commercials: null,
            ccProjects: [],
            projects: null,
            columns: [
                {
                    headerName: 'Company',
                    field: 'company_name',
                    type: 'name',
                    editable: true,
                },
                {
                    headerName: 'Contract No.',
                    field: 'contract_number',
                    editable: true,
                    type: 'idNum',
                },
                {
                    headerName: 'Commercial Received Day',
                    field: 'received_date',
                    type: 'date',
                    sort: 'desc',
                },
                {
                    headerName: 'Contract End Date',
                    field: 'end_date',
                    type: 'date',
                },
                {
                    headerName: 'Funds Received Date',
                    field: 'funds_received',
                    type: 'date',
                },
                {
                    headerName: 'Amount',
                    field: 'amount',
                    type: 'money',
                },
                {
                    headerName: 'Purpose',
                    field: 'purpose',
                    type: 'longtext',
                    editable: true,
                },
                {
                    headerName: 'WBI Project No.',
                    field: 'project_name',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'project_id',
                    },
                    type: 'idNum',
                },
                {
                    headerName: 'Link to DB',
                    field: 'link_to_db',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator',
                    type: 'name',
                    editable: false,
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                    editable: false,
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_update_by',
                    type: 'name',
                    editable: false,
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                    editable: false,
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
        };
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    componentDidMount = async () => {
        try {
            const { data: commercials } = await CommercialsApi.getCommercialContracts();
            const { data: projects } = await ProjectsApi.fetchProjects();

            let ccProjects = [];

            ccProjects = this.populateProjects(commercials, projects, ccProjects);

            this.setState({
                commercials,
                ccProjects,
                projects,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRowValueChanged(event) {
        let ccProjectToUpdate = event.node.data;

        if (ccProjectToUpdate) {
            CommercialsApi.updateCCProject(ccProjectToUpdate.commercial_contract_id, ccProjectToUpdate.id, {
                amount: ccProjectToUpdate.amount,
                purpose: ccProjectToUpdate.purpose,
            })
                .then(() => {
                    //
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });

            CommercialsApi.updateCommercialContract(ccProjectToUpdate.commercial_contract_id, {
                company_name: ccProjectToUpdate.company_name,
                contract_number: ccProjectToUpdate.contract_number,
                received_date: ccProjectToUpdate.received_date,
                end_date: ccProjectToUpdate.end_date,
                funds_received: ccProjectToUpdate.funds_received,
                link_to_db: ccProjectToUpdate.link_to_db,
            })
                .then((response) => {
                    let updatedCommercial = response.data;
                    let newCommercials;
                    let newCCProjects = [];

                    let commercialIndex = _findIndex(this.state.commercials, {
                        id: ccProjectToUpdate.commercial_contract_id,
                    });
                    newCommercials = this.state.commercials.filter((_, j) => commercialIndex !== j);
                    newCommercials.push(updatedCommercial);

                    newCCProjects = this.populateProjects(newCommercials, this.state.projects, newCCProjects);
                    this.setState({
                        row: null,
                        rowIndex: null,
                        colKey: '',
                        ccProjects: newCCProjects,
                        commercials: newCommercials,
                    });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'company_name',
        });
    }

    onRemoveSelected = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to remove a item?',
        });

        let ccProjectToRemove = event.node.data;

        if (ccProjectToRemove && confirmed) {
            let commercialToRemove = _find(this.state.commercials, {
                id: ccProjectToRemove.commercial_contract_id,
            });

            CommercialsApi.removeCCProject(ccProjectToRemove.commercial_contract_id, ccProjectToRemove.id)
                .then(() => {
                    if (commercialToRemove.projects.length <= 1) {
                        CommercialsApi.removeCommercialContract(commercialToRemove.id)
                            .then(() => {
                                //
                            })
                            .catch((errorMessage) => {
                                this.setState({ errorMessage });
                            });
                    }
                    let newCCProjects = this.state.ccProjects.filter((item) => event.node.data.id !== item.id);
                    this.setState({ ccProjects: newCCProjects });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    populateProjects(commercials, projects, ccProjects) {
        commercials.forEach((commercial) => {
            if (commercial.projects.length > 0) {
                commercial.projects.forEach((project) => {
                    let ccProject = _find(projects, { id: project.project_id });

                    project.project_name = ccProject.name;
                    project.company_name = commercial.company_name;
                    project.contract_number = commercial.contract_number;
                    project.received_date = commercial.received_date;
                    project.end_date = commercial.end_date;
                    project.funds_received = commercial.funds_received;
                    project.link_to_db = commercial.link_to_db;

                    ccProjects.push(project);
                });
            }
        });

        return ccProjects;
    }

    render() {
        const { match } = this.props;
        return (
            <div>
                <PageHeader title="Commercial" />
                <FinanceSubNav />
                <SubSubNav>
                    <SubSubNavLink to={match.url} exact>
                        Commercial Funding
                    </SubSubNavLink>
                    <SubSubNavLink to={match.url + '/newCommercial'}>Create New Commercial Funding</SubSubNavLink>
                </SubSubNav>
                <div className="Table-styling">
                    <Table
                        columns={this.state.columns}
                        data={this.state.ccProjects}
                        ref={(r) => (this.grid = r)}
                        rowIndex={this.state.rowIndex}
                        colKey={this.state.colKey}
                        onRowValueChanged={this.onRowValueChanged.bind(this)}
                        onTableReady={this.onTableReady}
                        animate
                        gridAutoHeight
                    />
                </div>
                <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
                    <ExportCSVButton fileName="Commercial" exportFunction={this.exportDataAsCsv} />
                </div>
            </div>
        );
    }
}

export default CommercialPage;
