// React
import React, { Component } from 'react';

// Components
import SelectionTable from '../../components/tables/SelectionTable';

// API
import ProjectsApi from '../../api/projects';

class AccomplishmentReport extends Component {
    constructor(props) {
        super(props);

        this.handleSelectionChange = this.handleSelectionChange.bind(this);

        this.state = {
            columns: [
                {
                    headerName: '',
                    type: 'checkbox',
                },
                {
                    headerName: 'From Date',
                    field: 'from_date',
                    type: 'date',
                },
                {
                    headerName: 'To Date',
                    field: 'to_date',
                    type: 'date',
                },
                {
                    headerName: 'Service Type',
                    field: 'service',
                    type: 'mediumtext',
                },
                {
                    headerName: 'Project Name',
                    field: 'project_name',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'project_id',
                    },
                    type: 'mediumtext',
                },
                {
                    headerName: 'Weekly Accomplishment Report',
                    field: 'impact',
                    type: 'longtext',
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator',
                    type: 'shortname',
                    editable: false,
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                    editable: false,
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_updated_by',
                    type: 'shortname',
                    editable: false,
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                    editable: false,
                },
            ],
            rowData: null,
            selected: [],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: rowData } = await ProjectsApi.fetchAllRecentWeeklyActivityReports();
            if (rowData) this.setState({ rowData });
        } catch (errorMessage) {
            this.setState({ errorMessage });
        }
    };

    handleSelectionChange(event) {
        let nodeList = event.api.getSelectedNodes();
        let listRow = [];
        nodeList.forEach((node) => {
            listRow.push(node.data);
        });

        this.setState({ selected: listRow });
    }

    downloadFile = () => {
        let data = [];

        this.state.selected.forEach((ele) => {
            data.push(ele.id);
        });
        let item = {};
        item.activities = this.state.selected;
        ProjectsApi.generateEDWARActivityReports(data.join('_'));
    };

    render() {
        if (!this.state.rowData) return <div>loading...</div>;
        const { columns, rowData } = this.state;

        return (
            <div>
                <SelectionTable
                    columns={columns}
                    data={rowData}
                    pagingProp={true}
                    pageSizeProp={5}
                    filter={true}
                    sortable={true}
                    selectionChanged={this.handleSelectionChange}
                    tableClassName="Accomplishment-tables"
                />
                <div className="Form__buttons--Reversed Buttons_format Buttons_placement Buttons_placement--right">
                    <button disabled={this.state.selected.length === 0} onClick={this.downloadFile}>
                        Download as Word Document
                    </button>
                </div>
            </div>
        );
    }
}

export default AccomplishmentReport;
