import toastr from 'toastr';
import 'toastr/build/toastr.css';

const errorToast = (error, message = 'Error') => {
    const TIMEOUT_IN_SECONDS = 30 * 1000;

    toastr.options.timeOut = TIMEOUT_IN_SECONDS;
    toastr.error(error, message);
};

export default errorToast;
