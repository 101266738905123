import React from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import SectionHeading from '../components/heading/SectionHeading';
import PagingTable from '../components/tables/PagingTable';
import yup from '../yup';
import LabeledField from '../components/forms/LabeledField';
import errorToast from '../components/messages/ErrorMessage';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import ProjectsApi from '../api/projects';

class ReportsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: null,
            activity_reports: null,
            printlist: [],
            reportOptions: [
                {
                    label: 'Weekly Activity Report',
                    value: 'weekly',
                },
                {
                    label: 'Quarterly Accomplishments',
                    value: 'quarterly',
                },
                {
                    label: 'Notes',
                    value: 'notes',
                },
            ],
            selected_report: 'weekly',
        };
        this.onTableReady = this.onTableReady.bind(this);
    }

    async componentDidMount() {
        try {
            let projectId = this.props.match.params.id;
            const { data: project } = await ProjectsApi.fetchProject(projectId);
            const { data: activity_reports } = await ProjectsApi.fetchActivityReports(projectId);

            if (project.wbi_lead_user_id) {
                project.users.forEach((user) => {
                    if (project.wbi_lead_user_id === user.id) {
                        project.wbi_lead_user_name = user.name;
                    }
                });
            }

            this.setState({
                project,
                activity_reports,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    onTableReady(params) {
        this.gridApi = params.api;
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.setState({
                exportDataAsCsv: params.exportDataAsCsv,
                gridApi: this.gridApi,
            });
        }
    }

    handleSubmit = (values, actions) => {
        const { project, selected_report } = this.state;
        let newReport;
        if (moment(values.toDate) < moment(values.fromDate)) {
            errorToast('There is an error in the Dates you chose please review');
            actions.setSubmitting(false);
            return;
        }
        if (selected_report === 'quarterly') {
            newReport = {
                from_date: values.fromDate,
                to_date: values.toDate,
                impact: values.impact,
                notes: values.notes,
                report_type: 'quarterly',
                project_id: project.id,
            };
        } else if (selected_report === 'notes') {
            newReport = {
                from_date: values.fromDate,
                to_date: values.toDate,
                notes: values.notes,
                project_id: project.id,
                report_type: 'notes',
            };
        } else if (selected_report === 'weekly') {
            newReport = {
                from_date: values.fromDate,
                to_date: values.toDate,
                impact: values.impact,
                project_id: project.id,
                report_type: 'weekly',
            };
        }

        ProjectsApi.createActivityReport(project.id, newReport).then((response) => {
            this.setState((prevState) => ({
                activity_reports: [...prevState.activity_reports, response.data],
            }));
        });

        actions.setSubmitting(false);
        actions.resetForm();
    };

    reportSelection = (evt) => {
        this.setState({
            selected_report: evt.target.value,
        });
    };

    render() {
        // if (!this.state.project) return <div>loading...</div>
        const { activity_reports, selected_report, exportDataAsCsv } = this.state;

        let weekly = (
            <Formik
                initialValues={{
                    report_type: 'weekly',
                    fromDate: '',
                    toDate: '',
                    impact: '',
                }}
                validationSchema={yup.object().shape({
                    fromDate: yup.date().required(),
                    toDate: yup.date().required(),
                    impact: yup.string().required(),
                })}
                onSubmit={this.handleSubmit}
            >
                {({ errors, touched, isSubmitting, resetForm }) => (
                    <Form>
                        <LabeledField
                            label="From:"
                            name="fromDate"
                            type="date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="To:"
                            name="toDate"
                            type="date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                        />

                        <LabeledField
                            label="Activity/Impact:"
                            name="impact"
                            component="textarea"
                            errors={errors}
                            touched={touched}
                            className="width-100"
                        />
                        <div className="Form__buttons--Reversed Buttons_format">
                            <button type="button" disabled={isSubmitting} onClick={() => resetForm()}>
                                Cancel
                            </button>
                            <button type="submit" disabled={isSubmitting}>
                                Add Activity Report
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );

        let quarterly = (
            <Formik
                initialValues={{
                    report_type: 'quarterly',
                    fromDate: '',
                    toDate: '',
                    impact: '',
                    notes: '',
                }}
                validationSchema={yup.object().shape({
                    fromDate: yup.date().required(),
                    toDate: yup.date().required(),
                    impact: yup.string().required(),
                })}
                onSubmit={this.handleSubmit}
            >
                {({ errors, touched, isSubmitting, resetForm }) => (
                    <Form>
                        <LabeledField
                            label="From:"
                            name="fromDate"
                            type="date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="To:"
                            name="toDate"
                            type="date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="Activities/Accomplishments/Impacts:"
                            name="impact"
                            component="textarea"
                            errors={errors}
                            touched={touched}
                            className="width-100"
                        />
                        <LabeledField
                            label="Notes"
                            name="notes"
                            component="textarea"
                            errors={errors}
                            touched={touched}
                            className="width-100"
                        />
                        <div className="Form__buttons--Reversed Buttons_format">
                            <button type="button" disabled={isSubmitting} onClick={() => resetForm()}>
                                Cancel
                            </button>
                            <button type="submit" disabled={isSubmitting}>
                                Add Accomplishment
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );

        let notes = (
            <Formik
                initialValues={{
                    report_type: 'notes',
                    fromDate: '',
                    toDate: '',
                    notes: '',
                }}
                validationSchema={yup.object().shape({
                    fromDate: yup.date().required(),
                    toDate: yup.date().required(),
                    notes: yup.string().required(),
                })}
                onSubmit={this.handleSubmit}
            >
                {({ errors, touched, isSubmitting, resetForm }) => (
                    <Form>
                        <LabeledField
                            label="From:"
                            name="fromDate"
                            type="date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="To:"
                            name="toDate"
                            type="date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="Note:"
                            name="notes"
                            component="textarea"
                            errors={errors}
                            touched={touched}
                            className="width-100"
                        />
                        <div className="Form__buttons--Reversed Buttons_format">
                            <button type="button" disabled={isSubmitting} onClick={() => resetForm()}>
                                Cancel
                            </button>
                            <button type="submit" disabled={isSubmitting}>
                                Add Note
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );

        let reportOutput = null;
        switch (true) {
            case selected_report === 'weekly':
                reportOutput = weekly;
                break;
            case selected_report === 'quarterly':
                reportOutput = quarterly;
                break;
            case selected_report === 'notes':
                reportOutput = notes;
                break;
            default:
                reportOutput = weekly;
                break;
        }

        return (
            <>
                <SectionHeading>Reports</SectionHeading>
                <div className="Project-reports-page">
                    <div className="Single-layout Companion-form">
                        <Formik
                            onSubmit={() => {}}
                            initialValues={{
                                selected_report,
                            }}
                            values={{
                                selected_report,
                            }}
                        >
                            {({ values, errors, touched }) => (
                                <Form
                                    onChange={(e) => {
                                        this.reportSelection(e);
                                    }}
                                    className="Pr-select"
                                >
                                    <LabeledField
                                        label="Report Selection"
                                        name="selected_report"
                                        component="Radio"
                                        currentValue={values.selected_report}
                                        options={this.state.reportOptions}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Form>
                            )}
                        </Formik>
                        {reportOutput}
                    </div>
                    <div className="flex flex-column">
                        <div className="Table-styling">
                            <div className="Form__buttons--Reversed Buttons_format">
                                <ExportCSVButton fileName="WAR-Reports" exportFunction={exportDataAsCsv} />
                            </div>
                            <PagingTable
                                data={activity_reports}
                                columns={[
                                    {
                                        headerName: 'Activity Start Date',
                                        field: 'from_date',
                                        type: 'date',
                                    },
                                    {
                                        headerName: 'Activity End Date',
                                        field: 'to_date',
                                        type: 'date',
                                    },
                                    { headerName: 'Type', field: 'report_type', type: 'shortname' },
                                    { headerName: 'Impact', field: 'impact', type: 'mediumtext' },
                                    { headerName: 'Notes', field: 'notes', type: 'mediumtext' },
                                    {
                                        headerName: 'Initiated By',
                                        field: 'initiator',
                                        type: 'shortname',
                                        editable: false,
                                    },
                                    {
                                        headerName: 'Initiated On',
                                        field: 'created_on',
                                        type: 'date',
                                        editable: false,
                                    },
                                    {
                                        headerName: 'Last Update By',
                                        field: 'last_updated_by',
                                        type: 'shortname',
                                        editable: false,
                                    },
                                    {
                                        headerName: 'Updated On',
                                        field: 'updated_on',
                                        type: 'date',
                                        editable: false,
                                    },
                                ]}
                                onTableReady={this.onTableReady}
                                pageSizeProp={1}
                                sortable={true}
                                filter={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ReportsPage;
