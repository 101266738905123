import React from 'react';
import isPresent from 'is-present';
import { Link } from 'react-router-dom';

import NoteCardBuilder from '../cards/NoteCardBuilder';

const NotificationsLinkRenderer = ({ value, data, colDef: { field }, routeBase, routeKeyField }) => {
    async function handleClick(e, data) {
        await data.mark(data.id);
        if (
            data.route.includes('/projects/') ||
            data.route.includes('/coreFunding/') ||
            data.route.includes('/finance/')
        ) {
            window.location.href = data.route;
        } else {
            window.location.href =
                data[isPresent(routeBase) ? routeBase : field] + data[isPresent(routeKeyField) ? routeKeyField : field];
        }
    }
    let notification;
    switch (data.notification_type) {
        case 'link':
            if (data && data.mark) {
                notification = (
                    <a
                        href="/#"
                        onClick={(e) => {
                            handleClick(e, data);
                        }}
                    >
                        {value}
                    </a>
                );
            } else {
                notification = (
                    <Link
                        to={
                            data[isPresent(routeBase) ? routeBase : field] +
                            data[isPresent(routeKeyField) ? routeKeyField : field]
                        }
                    >
                        {value}
                    </Link>
                );
            }
            break;
        case 'notecard':
            notification = (
                <NoteCardBuilder
                    title={data.title}
                    target={data.target}
                    target_type={data.target_type}
                    notecard_type={data.notecard_type}
                    mark={data.mark}
                    notificationId={data.id}
                />
            );
            break;
        default:
            notification = null;
            break;
    }
    return notification;
};

export default NotificationsLinkRenderer;
