import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import Select from 'react-select';

import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';

import ProjectTypesApi from '../api/projectTypes';
import ServicesApi from '../api/services';

import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';

import { confirm } from '../components/modals/ConfirmModal';

class ProjectTypesPage extends Component {
    constructor(props) {
        super(props);

        this.getRowData = this.getRowData.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            allProjecTypes: [],
            selectedProjectTypes: [],
            serviceFilter: [],
            services: [],
            selectedServices: [],
            forceSetData: false,
            row: null,
            rowIndex: null,
            colKey: null,
            columns: [
                {
                    headerName: 'Project Types',
                    field: 'name',
                    editable: true,
                    sort: 'desc',
                },
                {
                    headerName: 'Service Code',
                    field: 'letter_identifier',
                    maxWidth: 75,
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'disable',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.disableType,
                },
            ],
        };
    }

    componentDidMount = async () => {
        const { data: services } = await ServicesApi.getServices();
        const { data: allProjecTypes } = await ProjectTypesApi.getProjectTypes();

        let newProjectTypes = [];
        allProjecTypes.forEach((pt) => {
            services.forEach((s) => {
                if (pt.service_type_id === s.id) {
                    pt.letter_identifier = s.letter_identifier;
                    newProjectTypes.push(pt);
                }
            });
        });

        newProjectTypes = newProjectTypes.filter((type) => type.disabled !== true);

        this.setState({
            allProjecTypes: newProjectTypes,
            services: services.filter(
                (service) =>
                    (service.disabled !== true || service.disabled === null) &&
                    !service.name.toUpperCase().includes('CORE')
            ),
            selectedProjectTypes: newProjectTypes,
        });
    };

    onRowValueChanged(event) {
        let projectTypeToUpdate = event.node.data;

        if (projectTypeToUpdate) {
            ProjectTypesApi.updateProjectType(projectTypeToUpdate.id, {
                name: projectTypeToUpdate.name,
            })
                .then(() => {
                    this.setState(
                        {
                            forceSetData: true,
                            row: null,
                            rowIndex: null,
                            colKey: null,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'name',
        });
    }

    disableType = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to Disable this Project Type?',
        });

        let typeToDisable = event.data;

        if (confirmed) {
            ProjectTypesApi.updateProjectType(typeToDisable.id, {
                disabled: true,
            })
                .then((response) => {
                    let projectTypes = this.state.allProjecTypes.filter((e) => e.id !== response.data.id);

                    this.setState(
                        {
                            forceSetData: true,
                            allProjecTypes: projectTypes,
                            selectedProjectTypes: projectTypes,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    handleSubmit = (values, actions) => {
        if (values) {
            let selectedServices = this.state.selectedServices;

            let newProjectTypes = this.state.allProjecTypes;
            selectedServices.forEach((service) => {
                ProjectTypesApi.createProjectType({
                    service_type_id: service.value,
                    name: values.name,
                    disabled: false,
                    order: 0,
                })
                    .then((response) => {
                        let data = response.data;
                        data.letter_identifier = service.letter_identifier;
                        newProjectTypes.push(data);

                        newProjectTypes.forEach((pt) => {
                            this.state.services.forEach((s) => {
                                if (pt.service_type_id === s.id) {
                                    pt.letter_identifier = s.letter_identifier;
                                }
                            });
                        });
                        actions.setSubmitting(false);
                        actions.resetForm();

                        this.setState(
                            {
                                selectedServices: [],
                                allProjecTypes: newProjectTypes,
                                forceSetData: true,
                            },
                            () => {
                                this.setState({ forceSetData: false });
                            }
                        );
                    })
                    .catch((errorMessage) => {
                        this.setState({ errorMessage });
                    });
            });
        }
    };

    updateTable(e) {
        if (e) {
            let newSelectedProjects = this.state.allProjecTypes.filter(
                (project) => project.service_type_id === e.value
            );

            this.setState(
                {
                    forceSetData: true,
                    serviceFilter: e,
                    selectedProjectTypes: newSelectedProjects,
                },
                (e) => {
                    this.setState({ forceSetData: false });
                }
            );
        } else {
            this.setState(
                {
                    forceSetData: true,
                    serviceFilter: e,
                    selectedProjectTypes: this.state.allProjecTypes,
                },
                (e) => {
                    this.setState({ forceSetData: false });
                }
            );
        }
    }

    render() {
        let servicesOptions = [];
        let i;
        if (this.state.services) {
            for (i = 0; i < this.state.services.length; i++) {
                if (!this.state.services[i].disabled) {
                    servicesOptions.push({
                        value: this.state.services[i].id,
                        label: this.state.services[i].name,
                    });
                }
            }
        }

        return (
            <div className="Project-types-page">
                <PageHeader title="Project Types" />
                <AdminSubNav />
                <div>
                    <div className="Single-layout Companion-form">
                        <Formik
                            initialValues={{
                                name: '',
                                service: '',
                            }}
                            validationSchema={yup.object().shape({
                                name: yup.string().required(),
                                service: yup
                                    .array()
                                    .of(
                                        yup.object().shape({
                                            value: yup.string().required(),
                                            label: yup.string().required(),
                                        })
                                    )
                                    .required(),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({ errors, touched, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <LabeledField
                                        label="Project Type*"
                                        name="name"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Select Services*"
                                        name="service"
                                        multi={true}
                                        component="select"
                                        options={servicesOptions}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            this.setState({ selectedServices: e });
                                            setFieldValue('service', e);
                                        }}
                                    />
                                    <div className="Form__buttons--Reversed Buttons_format">
                                        <button disabled={isSubmitting} type="submit">
                                            Add Project Type
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="Table-styling">
                        <div className="Simple-search-form">
                            <label className="FieldLabel">Filter by Services</label>
                            <Select
                                value={this.state.serviceFilter}
                                multi={false}
                                options={servicesOptions}
                                placeholder="Select"
                                onChange={(e) => {
                                    this.updateTable(e);
                                }}
                            />
                        </div>
                        <EditingTable
                            columns={this.state.columns}
                            data={this.state.selectedProjectTypes.sort((a, b) => {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (a.name > b.name) {
                                    return 1;
                                }
                                return 0;
                            })}
                            rowIndex={this.state.rowIndex}
                            colKey={this.state.colKey}
                            rowValueChanged={this.onRowValueChanged}
                            addedNewData={this.state.forceSetData}
                            sizeToFit={true}
                            pagingProp={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectTypesPage;
