import React from 'react';
import Table from '../../../../components/tables/Table';
import errorToast from '../../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../../components/buttons/ExportCSVButton';
import ProjectsApi from '../../../../api/projects';

class ActiveLastReview extends React.Component {
    constructor(props) {
        super(props);

        this.onTableReady = this.onTableReady.bind(this);

        this.state = {
            activeProjects: null,
            selectedService: this.props.selectedService,
        };
    }

    async componentDidMount() {
        const { data: activeProjects } = await ProjectsApi.fetchAllActiveProjects();
        try {
            this.setState({ activeProjects });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        try {
            this.setState({ selectedService: nextProps.selectedService });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    render() {
        const { activeProjects, selectedService } = this.state;

        let filteredActiveProjects = [];
        if (activeProjects && selectedService) {
            if (selectedService.length === 1) {
                filteredActiveProjects = activeProjects.filter(
                    (project) => project.service_type_name === selectedService[0].name
                );
            } else {
                filteredActiveProjects = activeProjects;
            }

            if (filteredActiveProjects !== null) {
                filteredActiveProjects.sort((a, b) => {
                    if (a.last_reviewed_on === b.last_reviewed_on) {
                        return 0;
                    }
                    if (a.last_reviewed_on === null) {
                        return -1;
                    }
                    if (b.last_reviewed_on === null) {
                        return 1;
                    }

                    let dateA = new Date(a.last_reviewed_on);
                    let dateB = new Date(b.last_reviewed_on);

                    return dateB - dateA;
                });
            }
        }

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="active-last-review" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div>
                    <Table
                        columns={[
                            {
                                headerName: 'Service',
                                field: 'service_type_name',
                                type: 'mediumtext',
                            },
                            {
                                headerName: 'WBI Project Name',
                                field: 'name',
                                type: 'mediumtext',
                            },
                            {
                                headerName: 'Project No.',
                                field: 'number',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'id',
                                },
                                type: 'idNum',
                            },
                            {
                                headerName: 'Project Lead',
                                field: 'wbi_lead',
                                type: 'name',
                            },
                            {
                                headerName: 'ERGO Reviewed',
                                field: 'last_reviewed_on',
                                type: 'date',
                            },
                        ]}
                        data={filteredActiveProjects}
                        onTableReady={this.onTableReady}
                        filter={true}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}
export default ActiveLastReview;
