// React
import React from 'react';

// Libraries
import yup from '../../../yup';

// Components
import FieldArray from '../../../components/forms/FieldArray';
import FieldWithError from '../../../components/forms/FieldWithError';
import LabeledField from '../../../components/forms/LabeledField';
import EditingTable from '../../../components/tables/EditingTable';
import errorToast from '../../../components/messages/ErrorMessage';

// API
import ProjectsApi from '../../../api/projects';

class StatusUpdatesTable extends React.Component {
    constructor(props) {
        super(props);

        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.addRow = this.addRow.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            row: null,
            rowIndex: null,
            colKey: '',
            errorMessage: '',
        };
    }

    handleStatusUpdates(statusUpdates) {
        this.props.handleStatusUpdates(statusUpdates);
    }

    onRemoveSelected(evt) {
        const { data } = evt;
        ProjectsApi.removeStatus(data.project_id, data.id).then(() => {
            const status_updates = this.props.status_updates.filter((s) => s.id !== data.id);
            this.handleStatusUpdates(status_updates);
        });
    }

    onRowValueChanged(evt) {
        const { data } = evt;
        const attributes = {
            description: data.description,
            status: data.status.toLowerCase(),
            date: new Date(data.date),
        };
        ProjectsApi.updateStatus(data.project_id, data.id, attributes)
            .then((response) => {
                if (response) {
                    this.setState({
                        row: null,
                        rowIndex: null,
                        colKey: '',
                    });
                    const status_updates = this.props.status_updates.map((s) =>
                        s.id === response.data.id ? { ...response.data } : s
                    );
                    this.handleStatusUpdates(status_updates);
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    getRowData(evt) {
        this.setState({
            row: evt.node.data,
            rowIndex: evt.node.rowIndex,
            colKey: 'description',
        });
    }

    addRow(valueToUpdate) {
        const { projectId } = this.props;
        if (projectId !== 'new') {
            let valueDate = new Date(valueToUpdate.date);
            let todayDate = new Date().setHours(0, 0, 0, 0);

            if (valueDate > todayDate) {
                errorToast('Cant add a future status!');
                return;
            }

            const attributes = {
                description: valueToUpdate.description,
                status: valueToUpdate.status,
                date: valueDate,
            };

            ProjectsApi.createStatus(projectId, attributes)
                .then((response) => {
                    const statusUpdates = this.props.status_updates.map((s) => s);
                    const { data } = response;
                    const statusUpdate = {
                        description: data.description,
                        status: data.status,
                        date: data.date,
                        id: data.id,
                        project_id: data.project_id,
                        initiator: data.initiator,
                        last_update_by: data.last_update_by,
                    };
                    statusUpdates.push(statusUpdate);
                    this.handleStatusUpdates(statusUpdates);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        const { values, errors, touched, isEditing } = this.props;
        const { rowIndex, colKey } = this.state;
        let status_updates = this.props.status_updates;
        status_updates.sort((a, b) => new Date(a.date) - new Date(b.date));
        const statusUpdateOptions = [
            { label: 'Good', value: 'good' },
            { label: 'Caution', value: 'caution' },
            { label: 'Stop', value: 'stop' },
        ];

        return (
            <FieldArray
                label="Status Updates"
                name="status_updates"
                initialValues={{ description: '', status: '', date: '' }}
                validationSchema={yup.object().shape({
                    description: yup.string().required(),
                    date: yup.date().required(),
                    status: yup.string().required(),
                })}
                onRemoveSelected={this.onRemoveSelected}
                addRow={this.addRow}
                values={values}
                errors={errors}
                touched={touched}
                readOnly={!isEditing}
                renderArray={() => (
                    <EditingTable
                        data={status_updates}
                        columns={[
                            {
                                headerName: 'Status Updates',
                                field: 'description',
                                editable: true,
                                type: 'mediumtext',
                            },
                            {
                                headerName: 'Status',
                                field: 'status',
                                cellRenderer: 'StatusSymbolRenderer',
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {
                                    values: ['good', 'caution', 'stop'],
                                },
                                editable: true,
                                type: 'symbol',
                            },
                            {
                                headerName: 'Date',
                                field: 'date',
                                type: 'date',
                                editable: true,
                            },
                            {
                                headerName: 'Initiated By',
                                field: 'initiator',
                                type: 'name',
                                editable: false,
                            },
                            {
                                headerName: 'Initiated On',
                                field: 'created_on',
                                type: 'date',
                                editable: false,
                            },
                            {
                                headerName: 'Last Update By',
                                field: 'last_update_by',
                                type: 'name',
                                editable: false,
                            },
                            {
                                headerName: 'Updated On',
                                field: 'updated_on',
                                type: 'date',
                                editable: false,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Del.',
                                field: 'delete',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'TrashSymbolRenderer',
                                onCellClicked: this.onRemoveSelected,
                            },
                        ]}
                        rowIndex={rowIndex}
                        colKey={colKey}
                        rowValueChanged={this.onRowValueChanged}
                    />
                )}
                renderFields={({ errors, touched }) => (
                    <>
                        <FieldWithError
                            className="Text-area-space"
                            name="description"
                            placeholder="Add item..."
                            component="textarea"
                            errors={errors}
                            touched={touched}
                        />
                        <div className="Status-update-box">
                            <label>
                                Date
                                <FieldWithError
                                    name="date"
                                    type="date"
                                    component="Date"
                                    errors={errors}
                                    touched={touched}
                                />
                            </label>
                            <label className="Status-icon">
                                Status Icon
                                <LabeledField
                                    name="status"
                                    component="select"
                                    options={statusUpdateOptions}
                                    errors={errors}
                                    touched={touched}
                                />
                            </label>
                        </div>
                    </>
                )}
            />
        );
    }
}

export default StatusUpdatesTable;
