export const PROJECT_STATUSES = {
    archived: 'archived',
};

export const projectSuffixLength = 2;

export const isProjectStatusArchived = (project) => {
    try {
        return project.status === PROJECT_STATUSES.archived;
    } catch (error) {
        throw new Error('Error checking project status');
    }
};

export const filterProjectsOnPiaId = (projects, id) => {
    try {
        return projects.filter((project) => project.cpo.pia_id === id);
    } catch (error) {
        throw new Error('Error filtering projects on PIA');
    }
};
