import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import TableFactory from './factory/tableFactory';
import useFunctionAsState from '../../hooks/useFunctionAsState';

const Table = ({
    data,
    columns,
    sizeToFit,
    hoistPinnedBottomRowData,
    getRowStyle,
    onTableReady,
    sortable,
    filter,
    tableClassName,
}) => {
    const [gridApi, setGridApi] = useFunctionAsState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();

    const resizeGrid = useCallback(() => {
        if (gridApi) {
            gridApi.resetRowHeights();

            if (sizeToFit) {
                gridApi.sizeColumnsToFit();
            }
        }
    }, [gridApi, sizeToFit]);

    useEffect(() => {
        resizeGrid();
    }, [gridApi, resizeGrid]);

    useEffect(() => {
        setPinnedBottomRowData(hoistPinnedBottomRowData);
    }, [hoistPinnedBottomRowData]);

    const onGridReady = useCallback(
        (params) => {
            const { api: gridApi } = params;
            setGridApi(gridApi);

            const exportDataAsCsv = (nameOfExport) => {
                const filename = nameOfExport + '-' + moment().format('YYYYMDDHHmmss');
                gridApi.exportDataAsCsv({ fileName: filename });
            };

            if (onTableReady && typeof onTableReady === 'function') {
                params.exportDataAsCsv = exportDataAsCsv;
                onTableReady(params);
            }
        },
        [setGridApi, onTableReady]
    );

    const table = useMemo(
        () => (
            <TableFactory
                rowData={data}
                columns={columns}
                gridReady={onGridReady}
                resizeGrid={resizeGrid}
                pinnedBottomRowData={pinnedBottomRowData}
                sortable={sortable}
                filter={filter}
                getRowStyle={getRowStyle}
                tableClassName={tableClassName}
            />
        ),
        [data, columns, sortable, filter, tableClassName, pinnedBottomRowData, getRowStyle, onGridReady, resizeGrid]
    );

    return <div className="Table_wrap">{table}</div>;
};

export default Table;
