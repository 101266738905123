import toastr from 'toastr';
import 'toastr/build/toastr.css';

const successToast = (message) => {
    const TIMEOUT_IN_SECONDS = 15 * 1000;

    toastr.options.timeOut = TIMEOUT_IN_SECONDS;
    toastr.success(message);
};

export default successToast;
