import React from 'react';

const ResultListItem = ({ result }) => (
    <div className="Result-link mb1">
        <h4>{result.name}</h4>
        <div>
            <h6>{result.number}</h6>
            <p>
                Customer Name: {result.customer_name}, WBI Contact: {result.wbi_contact} Status:{}
                {result.status}
            </p>
        </div>
    </div>
);

export default ResultListItem;
