import api from './api';

import endpoints from './endpoints';

const cpoParagraphsRoute = endpoints.CPO_PARAGRAPHS;

export default {
    getCPOparagraphs() {
        return api.get(cpoParagraphsRoute, 'Error getting list of CPO paragraphs');
    },

    createCPOparagraph(paragraphAttributes) {
        return api.post(cpoParagraphsRoute, paragraphAttributes, 'Error creating a CPO paragraph');
    },

    updateCPOparagraph(id, paragraphAttributes) {
        return api.put(`${cpoParagraphsRoute}/${id}`, paragraphAttributes, 'Error creating a CPO paragraph');
    },

    removeCPOparagraph(id) {
        return api.delete(`${cpoParagraphsRoute}/${id}`, 'Error creating a CPO paragraph');
    },
};
