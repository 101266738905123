import React, { Component } from 'react';
import Table from '../../components/tables/Table';

class CpoParagraphsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    headerName: 'No.',
                    field: 'number',
                    editable: false,
                    maxWidth: 75,
                },
                {
                    headerName: 'Paragraph',
                    field: 'paragraph',
                    type: 'longtext',
                    editable: false,
                },
            ],
        };
    }

    render() {
        return (
            <div>
                <Table columns={this.state.columns} data={this.props.cpoParagraphs} sizeToFit={true} />
            </div>
        );
    }
}

export default CpoParagraphsTable;
