import React from 'react';
//Library
import _find from 'lodash/find';
//Api
import TransferRequestApi from '../../api/transferRequest';
import ProjectsApi from '../../api/projects';
import ResidualFundsApi from '../../api/residualFunds';
import UsersApi from '../../api/users';
//Shared component
import SubSubNav, { SubSubNavLink } from '../../components/navigation/SubSubNav';
import TransferApprovalCards from './TransferApprovalCards';
import { history } from '../../store/store';
import errorToast from '../../components/messages/ErrorMessage';

class TransferFundsApprovalPage extends React.Component {
    constructor(props) {
        super(props);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.editSignature = this.editSignature.bind(this);

        this.state = {
            approvals: null,
            projects: null,
        };
    }

    async componentDidMount() {
        try {
            let projectId = this.props.match.params.id;
            const { data: project } = await ProjectsApi.fetchProject(projectId);
            const { data: projects } = await ProjectsApi.fetchProjects();
            const { data: projectFinanceInfo } = await ProjectsApi.fetchProjectFinancialInformation(projectId);
            const { data: residualFunds } = await ResidualFundsApi.getResidualFundWithFundsTotal();
            const { data: users } = await UsersApi.getUsers();
            const { data: approvals } = await TransferRequestApi.getTransferRequestUserApprover(projectId);

            if (approvals) {
                approvals.forEach((approval) => {
                    let sourceProject = _find(projects, { id: approval.source_id });
                    approval.source_number = sourceProject.number;
                    approval.source_name = sourceProject.name;
                    approval.source_funds = projectFinanceInfo.remaining_funds;
                    approval.source_remaining = approval.source_funds - approval.amount;

                    if (approval.destination_type === 'Project') {
                        approval.transfer_type = 'Request Transfer Funds Between Projects';
                        let project = _find(projects, { id: approval.destination_id });
                        approval.destination_number = project.number;
                        approval.destination_name = project.name;
                    } else if (approval.destination_type === 'OfficeSymbol') {
                        approval.transfer_type = 'Request transfer funds from Project to Residual Fund';
                        let residualFund = _find(residualFunds, { id: approval.destination_id });
                        approval.destination_name = residualFund.name;
                    } else if (approval.destination_type === 'Subroject') {
                        // TODO:get subrpoject info
                    }

                    if (approval.initiator_id && users) {
                        let user = _find(users, { id: approval.initiator_id });
                        approval.initiator_name = user.name;
                    }
                });
            }

            this.setState({
                approvals,
                projects,
                project,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    editSignature() {
        // This method should be empty as we don't want to create a signature
        //in withing a project.
    }

    approve(id, index, item) {
        TransferRequestApi.approveTransferRequest(id, item)
            .then(() => {
                let newApprovals = this.state.approvals.filter((_, i) => index !== i);

                this.setState({
                    approvals: newApprovals,
                });

                if (newApprovals.length === 0) {
                    let currentUrl = String(this.props.match.url);
                    let transferProjectUrl = currentUrl.substring(0, currentUrl.length - 9);
                    history.push(transferProjectUrl);
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    reject(id, index) {
        TransferRequestApi.rejectTransferRequest(id)
            .then(() => {
                let newApprovals = this.state.approvals.filter((_, i) => index !== i);

                this.setState({
                    approvals: newApprovals,
                });
                if (newApprovals.length === 0) {
                    let currentUrl = String(this.props.match.url);
                    let transferProjectUrl = currentUrl.substring(0, currentUrl.length - 9);
                    history.push(transferProjectUrl);
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    render() {
        if (!this.state.approvals) return <div>loading...</div>;
        const { match } = this.props;
        let subnav = null;
        let approvals;

        let currentUrl = String(match.url);
        let transferProjectUrl = currentUrl.substring(0, currentUrl.length - 9);

        if (this.state.approvals.length >= 1) {
            subnav = (
                <div>
                    <SubSubNav>
                        <SubSubNavLink to={transferProjectUrl} exact>
                            Transfer Project Funds
                        </SubSubNavLink>
                        <SubSubNavLink to={match.url} exact>
                            Approval
                        </SubSubNavLink>
                    </SubSubNav>
                </div>
            );
            approvals = this.state.approvals;
        }

        return (
            <>
                {subnav}
                <TransferApprovalCards
                    approvals={approvals}
                    editSignature={this.editSignature}
                    approve={this.approve}
                    reject={this.reject}
                />
            </>
        );
    }
}

export default TransferFundsApprovalPage;
