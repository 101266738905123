import React from 'react';
import Moment from 'moment';
import TimerSymbol from './TimerSymbol';

const TimerSymbolRenderer = (props) => {
    let status;
    let deadline = Moment(props.data.deadline);
    let REFERENCE = Moment(new Date());
    let TODAY = REFERENCE.clone().startOf('day');
    let A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
    if (props.data.action) {
        if (TODAY.isSame(deadline, 'day')) {
            //Is today the same day as the deadline?
            status = 'completed';
        } else if (TODAY.isAfter(deadline, 'day')) {
            // Is today past the deadline?
            status = 'pastDue';
        } else if (TODAY.isAfter(A_WEEK_OLD, 'day') && TODAY.isBefore(deadline)) {
            // Is it a week old
            status = 'dueSoon';
        } else {
            status = 'noRush';
        }
    }
    return (
        <div>
            <TimerSymbol status={status} />
        </div>
    );
};

export default TimerSymbolRenderer;
