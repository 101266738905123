import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form, FieldArray as BasicFieldArray } from 'formik';
import yup from '../../yup';
import LabeledField from '../../components/forms/LabeledField';
import errorToast from '../../components/messages/ErrorMessage';
import Message from '../../components/messages/Message';
import ModApi from '../../api/mods';
import TransactionApi from '../../api/transaction';
import { createCpoOptions, filterCposOnPiaId } from '../../utils/CpoUtils';
import createPiaOptions from '../../utils/PiaUtils';
import { filterProjectsOnPiaId } from '../../utils/ProjectUtils';
import ErgoMoney from '../../utils/ErgoMoney';

const ModsForm = (props) => {
    const createDefaultAcrnValues = () => ({
        name: '',
        amount: '',
        purpose: '',
        pr_number: '',
        project_id: '',
    });

    const createDefaultFormValues = () => {
        const defaultAcrns = createDefaultAcrnValues();
        return {
            pia: '',
            cpo_id: '',
            number: '',
            received_date: '',
            funds_received: '',
            link_to_db: '',
            total_amount: '',
            acrns: [defaultAcrns],
        };
    };

    const [selectedCpos, setSelectedCpos] = useState([]);
    const [piaOptions, setPiaOptions] = useState([]);
    const [cpoOptions, setCpoOptions] = useState([]);
    const [projectNameOptions, setProjectNameOptions] = useState([]);
    const [projectNumberOptions, setProjectNumberOptions] = useState([]);
    const [initialFormValues, setInitialFormValues] = useState(createDefaultFormValues());

    useEffect(() => {
        const { cpos } = props;
        const cpoOptions = createCpoOptions(cpos);
        setCpoOptions(cpoOptions);
    }, [props, props.cpos, selectedCpos]);

    useEffect(() => {
        const { pias } = props;
        const piaOptions = createPiaOptions(pias);
        setPiaOptions(piaOptions);
    }, [props, props.pias]);

    const prepareProjectNameOptions = (projects) =>
        projects.map((project) => ({
            label: project.name,
            value: project.id,
            name: project.name,
        }));

    const prepareProjectNumberOptions = (projects) =>
        projects.map((project) => ({
            label: project.number,
            value: project.id,
            name: project.name,
        }));

    const setProjectDropdownOptions = useCallback((projects) => {
        const projectNameOptions = prepareProjectNameOptions(projects);
        setProjectNameOptions(projectNameOptions);
        const projectNumberOptions = prepareProjectNumberOptions(projects);
        setProjectNumberOptions(projectNumberOptions);
    }, []);

    useEffect(() => {
        setProjectDropdownOptions(props.projects);
    }, [props, props.projects, setProjectDropdownOptions]);

    const setOptionsOnPiaSelect = useCallback(
        (event) => {
            const { value: piaId } = event;
            const { cpos, projects } = props;
            const selectedCpos = filterCposOnPiaId(cpos, piaId);
            const piaProjects = filterProjectsOnPiaId(projects, piaId);
            setProjectDropdownOptions(piaProjects);
            setSelectedCpos(selectedCpos);
        },
        [props, setProjectDropdownOptions]
    );

    useEffect(() => {
        if (props.modToUpdate) {
            const initialFormValues = {
                pia: props.modToUpdate.cpo.pia_id,
                cpo_id: props.modToUpdate.cpo_id,
                number: props.modToUpdate.number,
                received_date: props.modToUpdate.received_date ? props.modToUpdate.received_date : '',
                funds_received: props.modToUpdate.funds_received ? props.modToUpdate.funds_received : '',
                link_to_db: props.modToUpdate.link_to_db,
                total_amount: new ErgoMoney(props.modToUpdate.total_amount).getNumberAmount(),
                acrns: props.modToUpdate.acrns,
            };
            initialFormValues.acrns.forEach((acrn) => {
                delete acrn.updated_on;
                delete acrn.created_on;
            });
            setInitialFormValues(initialFormValues);
            setOptionsOnPiaSelect({ value: props.modToUpdate.cpo.pia_id });
        }
    }, [props, props.modToUpdate, setOptionsOnPiaSelect]);

    const resetOptionsFromPiaSelect = () => {
        setSelectedCpos([]);
        setProjectDropdownOptions(props.projects);
    };

    const calculateAcrnTotal = (acrns) => {
        if (!acrns.length) {
            return 0;
        }
        const acrnAmounts = acrns.map((acrn) => new ErgoMoney(acrn.amount).getNumberAmount());
        const acrnTotal = new ErgoMoney().sum(acrnAmounts).getNumberAmount();

        return acrnTotal;
    };

    const prepareFormValuesForSubmit = (formValues) => {
        formValues.acrns.forEach((acrn) => {
            delete acrn.project_name;
            delete acrn.project_number;
            delete acrn.wbi_lead_user_id;
            delete acrn.wbi_lead_user_name;
            delete acrn.initiator;
            delete acrn.last_update_by;
            delete acrn.number;
            delete acrn.pia_name;
            delete acrn.cpo_number;
            delete acrn.received_date;
            delete acrn.funds_received;
            delete acrn.link_to_db;
        });
        let modAttributes = {
            cpo_id: formValues.cpo_id.value ? formValues.cpo_id.value : formValues.cpo_id,
            number: formValues.number,
            link_to_db: formValues.link_to_db,
            total_amount: formValues.total_amount,
            acrns: formValues.acrns,
        };
        if (formValues.received_date) {
            modAttributes.received_date = formValues.received_date;
        }
        if (formValues.funds_received) {
            modAttributes.funds_received = formValues.funds_received;
        }
        return modAttributes;
    };

    const createTransactionFromAcrn = async (acrn) => {
        const acrnAttributes = {
            ga_date: acrn.ga_date,
            amount: acrn.amount,
            description: '',
            destination_id: acrn.project_id,
            reason: acrn.purpose,
            source_id: acrn.mod_id,
            destination_type: 'Project',
            source_type: 'Mod',
            artifact_id: acrn.id,
            artifact_type: 'ACRN',
        };
        try {
            await TransactionApi.createTransaction(acrnAttributes);
        } catch (error) {
            errorToast(error);
        }
    };

    const updateMod = async (formValues) => {
        let modAttributes = prepareFormValuesForSubmit(formValues);
        modAttributes.ga_date = modAttributes.received_date;
        try {
            const { data: updatedMod } = await ModApi.updateMod(props.modToUpdate.id, modAttributes);
            props.returnModToTable(updatedMod);
            setInitialFormValues(createDefaultFormValues());
            resetOptionsFromPiaSelect();
            setSelectedCpos([]);
        } catch (error) {
            errorToast(error);
        }
        console.log({ Update: formValues });
    };

    const createMod = async (formValues, formikBag) => {
        const modAttributes = prepareFormValuesForSubmit(formValues);
        try {
            const { data: createdMod } = await ModApi.createMod(modAttributes);
            createdMod.acrns.forEach(async (acrn) => {
                acrn.ga_date = createdMod.received_date;
                createTransactionFromAcrn(acrn);
            });
            props.addModToTable(createdMod);
            setInitialFormValues(createDefaultFormValues());
            resetOptionsFromPiaSelect();
            formikBag.setSubmitting(false);
        } catch (error) {
            errorToast(error);
        }
        console.log({ Create: formValues });
    };

    const handleSubmit = async (formValues, formikBag) => {
        const { modToUpdate } = props;
        if (modToUpdate) {
            updateMod(formValues, formikBag);
        } else {
            createMod(formValues, formikBag);
        }
        formikBag.resetForm();
        setInitialFormValues(createDefaultFormValues());
        console.log({ Submit: formValues });
    };

    const acrnTotalEqualsTotalAmount = (acrnTotal, totalAmount) => new ErgoMoney(acrnTotal).equalTo(totalAmount);

    const handleAddAcrn = (arrayHelpers) => {
        arrayHelpers.push(createDefaultAcrnValues());
    };

    const createYupSchema = () =>
        yup.object().shape({
            pia: yup.string().required(),
            cpo_id: yup.string().required(),
            number: yup.string().required(),
            received_date: yup.date(),
            funds_received: yup.date(),
            link_to_db: yup.string(),
            total_amount: yup.number().required(),
            name: yup.string().max(10),
            amount: yup.number(),
            purpose: yup.string(),
            pr_number: yup.string().max(10),
            project_id: yup.string(),
            acrns: yup
                .array()
                .of(
                    yup.object().shape({
                        name: yup.string().required(),
                        amount: yup.string().required(),
                        purpose: yup.string().required(),
                        pr_number: yup.string().required(),
                        project_id: yup.string().required(),
                    })
                )
                .min(1),
        });

    return (
        <div className="Centered-form">
            <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                validationSchema={createYupSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, resetForm, setFieldValue }) => (
                    <>
                        <Form className="NewModForm">
                            <div className="NewModForm__Row">
                                <div className="NewModForm__column-one">
                                    <LabeledField
                                        label="PIA*"
                                        name="pia"
                                        component="select"
                                        options={piaOptions}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            setOptionsOnPiaSelect(e);
                                            setFieldValue('pia', e);
                                        }}
                                    />
                                    {values.pia && (
                                        <LabeledField
                                            label="CPO*"
                                            name="cpo_id"
                                            component="select"
                                            options={cpoOptions}
                                            errors={errors}
                                            touched={touched}
                                            onChange={(e) => {
                                                setFieldValue('cpo_id', e);
                                            }}
                                        />
                                    )}
                                    <LabeledField
                                        label="Mod No.*"
                                        name="number"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Mod Received Date"
                                        name="received_date"
                                        type="date"
                                        component="Date"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Funds Received"
                                        name="funds_received"
                                        type="date"
                                        component="Date"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Link to DB"
                                        name="link_to_db"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Total Mod Amount* "
                                        name="total_amount"
                                        component="Money"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Sum of ACRN Amounts"
                                        name="acrnTotal"
                                        component="Money"
                                        value={calculateAcrnTotal(values.acrns)}
                                        readOnly
                                    />
                                    {!acrnTotalEqualsTotalAmount(
                                        calculateAcrnTotal(values.acrns),
                                        values.total_amount
                                    ) ? (
                                        <Message value="*The Total Mod Amount must equal the Sum of ACRN Amounts" />
                                    ) : null}
                                </div>
                                <div className="NewModForm__column-two">
                                    <BasicFieldArray
                                        className="acrn_block"
                                        name="acrns"
                                        render={(arrayHelpers) => (
                                            <div className="NewModForm__acrn-form">
                                                <div className="NewModForm__acrn-form_scroll">
                                                    {values.acrns.map((acrn, index) => (
                                                        <div className="NewModForm__acrn-item" key={index}>
                                                            <LabeledField
                                                                label="ACRN*"
                                                                name={`acrns.${index}.name`}
                                                                type="text"
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                            <LabeledField
                                                                label="Amount* "
                                                                name={`acrns.${index}.amount`}
                                                                component="Money"
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                            <LabeledField
                                                                label="Purpose*"
                                                                name={`acrns.${index}.purpose`}
                                                                type="text"
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                            <LabeledField
                                                                label="PR No.*"
                                                                name={`acrns.${index}.pr_number`}
                                                                type="text"
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                            <LabeledField
                                                                label="WBI Project No.*"
                                                                name={`acrns.${index}.project_id`}
                                                                component="select"
                                                                options={projectNumberOptions}
                                                                errors={errors}
                                                                touched={touched}
                                                                onChange={(e) => {
                                                                    setFieldValue(`acrns.${index}.project_id`, e.value);
                                                                    setFieldValue(
                                                                        `acrns.${index}.project_name`,
                                                                        e.name
                                                                    );
                                                                }}
                                                            />
                                                            {`values.acrns.${index}.project_name` && (
                                                                <LabeledField
                                                                    label="WBI Project Name.*"
                                                                    name={`acrns.${index}.project_id`}
                                                                    component="select"
                                                                    options={projectNameOptions}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            `acrns.${index}.project_id`,
                                                                            e.value
                                                                        );
                                                                        setFieldValue(
                                                                            `acrns.${index}.project_name`,
                                                                            e.name
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="Divider" />
                                                <div className="Divider" />
                                                <div className="Form__buttons--Reversed">
                                                    <button
                                                        type="button"
                                                        className="add_button"
                                                        onClick={() => handleAddAcrn(arrayHelpers)}
                                                    >
                                                        Add ACRN +
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="Form__buttons--Reversed column-span-3 flex justify-content-end">
                                    <button
                                        type="reset"
                                        onClick={() => {
                                            if (props.modToUpdate) {
                                                props.returnModToTable(props.modToUpdate);
                                            }
                                            setInitialFormValues(createDefaultFormValues());
                                            resetForm();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={
                                            !acrnTotalEqualsTotalAmount(
                                                calculateAcrnTotal(values.acrns),
                                                values.total_amount
                                            )
                                        }
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default ModsForm;
