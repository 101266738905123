// React
import React from 'react';

// Libraries
import yup from '../../../yup';

// Components
import FieldWithError from '../../../components/forms/FieldWithError';
import FieldArray from '../../../components/forms/FieldArray';
import DraggingTable from '../../../components/tables/DraggingTable';

// API
import ProjectsApi from '../../../api/projects';
import errorToast from '../../../components/messages/ErrorMessage';

class CustomerRolesTable extends React.Component {
    constructor(props) {
        super(props);

        this.onRowDragEnd = this.onRowDragEnd.bind(this);
        this.onRowDragEnter = this.onRowDragEnter.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.addRow = this.addRow.bind(this);
        this.getRowData = this.getRowData.bind(this);

        this.state = {
            row: null,
            rowIndex: null,
            colKey: '',
            errorMessage: '',
        };
    }

    handleCustomerRoles(customerRoles) {
        this.props.handleCustomerRoles(customerRoles);
    }

    async onRemoveSelected(event) {
        try {
            const { data } = event;
            await ProjectsApi.removeCustomerRole(data.project_id, data.id);
            if (this.props.customer_roles.length) {
                const customerRoles = this.props.customer_roles.filter((c) => c.id !== data.id);
                this.handleCustomerRoles(customerRoles);
            }
        } catch (error) {
            errorToast(error);
        }
    }

    onRowValueChanged(evt) {
        const { data } = evt;
        const { customer_roles } = this.props;
        let idx = customer_roles.findIndex((i) => i.id === evt.node.data.id);
        customer_roles[idx] = data;

        ProjectsApi.updateProjectDataPageTables(data.project_id, 'CustomerRoles', customer_roles)
            .then((response) => {
                if (response) {
                    this.setState({
                        row: null,
                        rowIndex: null,
                        colKey: '',
                    });
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    getRowData(evt) {
        this.setState({
            row: evt.node.data,
            rowIndex: evt.node.rowIndex,
            colKey: 'description',
        });
    }

    onRowDragEnter = (event) => {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
        });
    };

    onRowDragEnd = (event) => {
        let newIndex = event.node.rowIndex;
        let rowIndex = this.state.rowIndex;
        let movingItem = event.node.data;
        let secondPartArray = this.props.customer_roles.filter((_, i) => rowIndex !== i);
        let firstPartArray = secondPartArray.splice(0, newIndex);
        firstPartArray.push(movingItem);

        let newArray = firstPartArray.concat(secondPartArray);

        for (let i = 0; i < newArray.length; i++) {
            newArray[i].order = i;
        }

        ProjectsApi.updateProjectDataPageTables(event.node.data.project_id, 'CustomerRoles', newArray)
            .then(() => {
                this.props.handleCustomerRoles(newArray);
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    };

    addRow(valueToUpdate) {
        const { projectId } = this.props;

        let customer_roles = [];
        if (projectId !== 'new') {
            const attributes = {
                description: valueToUpdate.description,
                order: this.props.customer_roles.length,
            };
            ProjectsApi.createCustomerRole(projectId, attributes)
                .then((response) => {
                    customer_roles = this.props.customer_roles.map((c) => c);
                    const { data } = response;
                    const newCustomerRole = {
                        description: data.description,
                        id: data.id,
                        project_id: data.project_id,
                        order: data.order,
                    };
                    customer_roles.push(newCustomerRole);
                    this.handleCustomerRoles(customer_roles);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        const { values, errors, isEditing } = this.props;
        let customerRoles = this.props.customer_roles.sort((a, b) => a.order - b.order);

        return (
            <FieldArray
                label="Customer Roles & Responsibilities"
                name="customer_roles"
                initialValues={{ description: '' }}
                onRemoveSelected={this.onRemoveSelected}
                addRow={this.addRow}
                validationSchema={yup.object().shape({
                    description: yup.string().required(),
                })}
                values={values}
                errors={errors}
                readOnly={!isEditing}
                renderArray={() => (
                    <DraggingTable
                        data={customerRoles}
                        columns={[
                            {
                                headerName: 'Customer Roles & Responsibilities',
                                field: 'description',
                                editable: true,
                                type: 'longtext',
                                rowDrag: true,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Edit',
                                field: 'edit',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'EditSymbolRenderer',
                                onCellClicked: this.getRowData,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Del.',
                                field: 'delete',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'TrashSymbolRenderer',
                                onCellClicked: this.onRemoveSelected,
                            },
                        ]}
                        drag={isEditing}
                        edit={isEditing}
                        rowDragEnd={this.onRowDragEnd}
                        rowDragEnter={this.onRowDragEnter}
                        rowValueChanged={this.onRowValueChanged}
                        sizeToFit={!isEditing}
                    />
                )}
                renderFields={({ errors, handleKeyPress }) => (
                    <FieldWithError
                        name="description"
                        placeholder="Add item..."
                        component="textarea"
                        onKeyPress={handleKeyPress}
                        errors={errors}
                    />
                )}
            />
        );
    }
}

export default CustomerRolesTable;
