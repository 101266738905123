import React from 'react';
import LabeledField from '../../components/forms/LabeledField';

export default function AddVendorInformation({ errors, touched, values }) {
    return (
        <>
            <LabeledField
                label="*Vendor Name"
                name="name"
                component="input"
                value={values.name}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
            <LabeledField
                label="Address Line 1"
                name="address1"
                component="input"
                value={values.address1}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
            <LabeledField
                label="Address Line 2"
                name="address2"
                component="input"
                value={values.address2}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
            <LabeledField
                label="City"
                name="city"
                component="input"
                value={values.city}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
            <div className="flex flex-row">
                <LabeledField
                    label="State"
                    name="state"
                    component="input"
                    value={values.state}
                    errors={errors}
                    touched={touched}
                    labelClassName="text-align-left mr2"
                />
                <LabeledField
                    label="ZIP"
                    name="zip"
                    component="input"
                    value={values.zip}
                    errors={errors}
                    touched={touched}
                    labelClassName="text-align-left ml2 mr2"
                />
            </div>
            <div className="flex mt1">
                <LabeledField
                    label="Country"
                    name="country"
                    component="input"
                    value={values.country}
                    errors={errors}
                    touched={touched}
                    labelClassName="text-align-left mr2"
                />
            </div>
        </>
    );
}
