import React, { Component } from 'react';
import moment from 'moment';
import _find from 'lodash/find';
import { Formik, Form } from 'formik';

import SectionHeading from '../components/heading/SectionHeading';
import PageHeader from '../components/heading/PageHeader';
import PagingTable from '../components/tables/PagingTable';
import Table from '../components/tables/Table';
import LabeledField from '../components/forms/LabeledField';
import ExportCSVButton from '../components/buttons/ExportCSVButton';

import MetricsApi from '../api/metrics';
import ProjectsApi from '../api/projects';
import errorToast from '../components/messages/ErrorMessage';

class GoalsAndMetricsPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleMetricInspection = this.handleMetricInspection.bind(this);
        this.onTableReady = this.onTableReady.bind(this);
        this.exportDataAsCsv = null;

        this.state = {
            metrics: [],
            projects: null,
            metricHistory: null,
            projectMetrics: [],
            metricYears: [],
            year: moment().format('YY'),
            forceSetData: false,
            errorMessage: null,
        };
    }

    async componentDidMount() {
        try {
            const { data: strategicMetrics } = await MetricsApi.getStrategicMetrics();
            const { data: projectMetrics } = await MetricsApi.fetchAllProjectMetrics();
            const { data: projects } = await ProjectsApi.fetchProjectNames();

            let metricYears = [];
            if (strategicMetrics) {
                strategicMetrics.forEach((metric) => {
                    metricYears.push(metric.metric_year);
                });
                metricYears = metricYears
                    .filter((year, pos) => metricYears.indexOf(year) === pos)
                    .sort((a, b) => a - b);

                for (let i = 0; i < metricYears.length; i++) {
                    metricYears[i] = { label: metricYears[i], value: metricYears[i] };
                }
                metricYears.unshift({ label: 'All', value: null });
            }

            if (strategicMetrics && projectMetrics) {
                strategicMetrics.map((strategicMetric) => {
                    let q1 = 0;
                    let q2 = 0;
                    let q3 = 0;
                    let q4 = 0;
                    strategicMetric.project_metric_ids = [];
                    projectMetrics.map((projectMetric) => {
                        if (strategicMetric.id === projectMetric.strategic_metric_id) {
                            strategicMetric.project_metric_ids.push(projectMetric.id);
                            q1 += projectMetric.q1;
                            q2 += projectMetric.q2;
                            q3 += projectMetric.q3;
                            q4 += projectMetric.q4;
                        }
                        return null;
                    });

                    strategicMetric.q1 = q1;
                    strategicMetric.q2 = q2;
                    strategicMetric.q3 = q3;
                    strategicMetric.q4 = q4;
                    return null;
                });
            }

            strategicMetrics.sort((a, b) => b.metric_year - a.metric_year);

            this.setState(
                {
                    metrics: strategicMetrics,
                    metricYears,
                    projectMetrics,
                    projects,
                    forceSetData: true,
                },
                () => {
                    this.setState({
                        forceSetData: false,
                    });
                }
            );
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    async handleMetricInspection(params) {
        let allMetricHistory = [];
        let data = params.data.project_metric_ids;
        if (data) {
            for (let i = 0; i < data.length; i++) {
                const { data: metricHistory } = await MetricsApi.fetchProjectAchievements(
                    data[i],
                    params.column.colId.substring(1, 2)
                );

                metricHistory.map((metric) => {
                    let project = _find(this.state.projects, {
                        id: metric.project_metric.project_id,
                    });
                    if (project) {
                        metric.project_name = project.name;
                        metric.project_id = project.id;
                    }
                    allMetricHistory.push(metric);
                    return null;
                });
            }
            this.setState(
                {
                    metricHistory: allMetricHistory,
                    forceSetData: true,
                },
                () => {
                    this.setState({
                        forceSetData: false,
                    });
                }
            );
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    async filterTable(year) {
        const { data: projectMetrics } = await MetricsApi.fetchAllProjectMetrics();
        if (year === null) {
            year = 'All';
        }

        MetricsApi.getMetricsByYear(year)
            .then((response) => {
                let filteredMetrics = response.data;

                if (filteredMetrics && projectMetrics) {
                    filteredMetrics.map((strategicMetric) => {
                        let q1 = 0;
                        let q2 = 0;
                        let q3 = 0;
                        let q4 = 0;
                        strategicMetric.project_metric_ids = [];
                        projectMetrics.map((projectMetric) => {
                            if (strategicMetric.id === projectMetric.strategic_metric_id) {
                                strategicMetric.project_metric_ids.push(projectMetric.id);
                                q1 += projectMetric.q1;
                                q2 += projectMetric.q2;
                                q3 += projectMetric.q3;
                                q4 += projectMetric.q4;
                            }
                            return null;
                        });

                        strategicMetric.q1 = q1;
                        strategicMetric.q2 = q2;
                        strategicMetric.q3 = q3;
                        strategicMetric.q4 = q4;
                        return null;
                    });
                }

                this.setState(
                    {
                        forceSetData: true,
                        metrics: filteredMetrics,
                    },
                    function () {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    render() {
        if (!this.state.metrics) return <div>loading...</div>;

        let sourceTable = null;

        if (this.state.metricHistory) {
            sourceTable = (
                <>
                    <div
                        className="flex flex-column"
                        style={{
                            marginLeft: 16,
                        }}
                    >
                        <SectionHeading className="mt0">Source of Numbers</SectionHeading>
                        <Table
                            data={this.state.metricHistory}
                            columns={[
                                {
                                    headerName: 'Date',
                                    field: 'created_on',
                                    type: 'date',
                                },
                                {
                                    headerName: 'Project Name',
                                    field: 'project_name',
                                    cellRenderer: 'LinkRenderer',
                                    cellRendererParams: {
                                        routeBase: '/projects/',
                                        routeKeyField: 'project_id',
                                    },
                                    type: 'longtext',
                                },
                                {
                                    headerName: 'Number',
                                    field: 'value',
                                    type: 'number',
                                },
                                {
                                    headerName: 'Name',
                                    field: 'name',
                                    type: 'name',
                                },
                                {
                                    headerName: 'Note',
                                    field: 'note',
                                    type: 'symbol',
                                    cellRenderer: 'NotesSymbolRenderer',
                                },
                            ]}
                        />
                    </div>
                </>
            );
        }

        return (
            <>
                <PageHeader title="WBI Strategic Metrics" />
                <div className="Wide-single-layout">
                    <div>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                metric_years: '',
                            }}
                        >
                            {({ errors, touched, setFieldValue }) => (
                                <Form className="SGM-form">
                                    <LabeledField
                                        label="Year"
                                        name="metric_years"
                                        component="select"
                                        options={this.state.metricYears}
                                        onChange={(e) => {
                                            this.filterTable(e.value);
                                            setFieldValue('metric_years', e);
                                        }}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="grid">
                        <div className="flex flex-column">
                            <PagingTable
                                data={this.state.metrics}
                                columns={[
                                    {
                                        headerName: 'Metric Year',
                                        field: 'metric_year',
                                        type: 'shortnumber',
                                    },
                                    {
                                        headerName: 'Metric',
                                        field: 'metric',
                                        type: 'longtext',
                                    },
                                    {
                                        headerName: 'Services',
                                        field: 'service_type_names',
                                        type: 'name',
                                    },
                                    {
                                        headerName: '1QFY' + this.state.year,
                                        field: 'q1',
                                        type: 'shortnumber',
                                        onCellClicked: this.handleMetricInspection,
                                        cellStyle: {
                                            color: 'blue',
                                        },
                                    },
                                    {
                                        headerName: '2QFY' + this.state.year,
                                        field: 'q2',
                                        type: 'shortnumber',
                                        onCellClicked: this.handleMetricInspection,
                                        cellStyle: {
                                            color: 'blue',
                                        },
                                    },
                                    {
                                        headerName: '3QFY' + this.state.year,
                                        field: 'q3',
                                        type: 'shortnumber',
                                        onCellClicked: this.handleMetricInspection,
                                        cellStyle: {
                                            color: 'blue',
                                        },
                                    },
                                    {
                                        headerName: '4QFY' + this.state.year,
                                        field: 'q4',
                                        type: 'shortnumber',
                                        onCellClicked: this.handleMetricInspection,
                                        cellStyle: {
                                            color: 'blue',
                                        },
                                    },
                                    {
                                        headerName: 'Actual',
                                        type: 'shortnumber',
                                        valueGetter: ({ data }) => `${data.q1 + data.q2 + data.q3 + data.q4}`,
                                        onCellClicked: this.handleMetricInspection,
                                        cellStyle: {
                                            color: 'blue',
                                        },
                                    },
                                    {
                                        headerName: 'Planned',
                                        field: 'planned_metric',
                                        type: 'number',
                                    },
                                ]}
                                onTableReady={this.onTableReady}
                                tableClassName="Goals-metric-table"
                            />
                            <div className="Form__buttons--Reversed Align-right mt2">
                                <ExportCSVButton fileName="goals-and-metrics" exportFunction={this.exportDataAsCsv} />
                            </div>
                        </div>
                        {sourceTable}
                    </div>
                </div>
            </>
        );
    }
}

export default GoalsAndMetricsPage;
