import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { confirm } from '../components/modals/ConfirmModal';
import LabeledField from '../components/forms/LabeledField';
import FieldWithError from '../components/forms/FieldWithError';
import SectionHeading from '../components/heading/SectionHeading';
import Table from '../components/tables/Table';
import errorToast from '../components/messages/ErrorMessage';
import successToast from '../components/messages/SuccessMessage';
import ProjectsApi from '../api/projects';
import ErgoMoney from '../utils/ErgoMoney';
import { siteRoles, getUserRole } from '../utils/UserUtils';

class CloseoutPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectStatus: '',
            project: null,
            residualFundsMessage: '',
            financialInfo: null,
        };
    }

    async componentDidMount() {
        try {
            const { data: project = {} } = await ProjectsApi.fetchProject(this.props.match.params.id);
            const { data: financialInfo } = await ProjectsApi.fetchProjectFinancialInformation(
                this.props.match.params.id
            );

            if (project && project.milestones) {
                project.milestones.sort((a, b) => new Date(a.to_date) - new Date(b.to_date));
            }

            if (project && project.deliverables) {
                project.deliverables.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
            }

            if (!new ErgoMoney(financialInfo.remaining_funds).equalTo(0)) {
                this.setState({
                    residualFundsMessage: 'Residual Funds must be $0 to archive project',
                });
            }

            this.setState({
                project,
                projectStatus: project.status,
                financialInfo,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    deleteProject = async () => {
        const { project } = this.state;

        const confirmed = await confirm({
            text: 'Are you sure you want to delete the project?  All data will be lost.',
        });
        if (confirmed) {
            ProjectsApi.removeProject(project.id)
                .then(() => {
                    this.props.history.push('/projects');
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
    };

    isDisabled(isSubmitting, financialInfo) {
        return isSubmitting || !new ErgoMoney(financialInfo.remaining_funds).equalTo(0);
    }

    render() {
        if (!this.state.project) {
            return <div>loading...</div>;
        }
        const { project, projectStatus, residualFundsMessage, financialInfo } = this.state;
        const isArchived = projectStatus === 'archived';
        const isSaveOnly = projectStatus === 'save';
        let isAdmin = false;
        const userRole = getUserRole();
        const isFieldRequired = !(isArchived || isSaveOnly);
        if (userRole && userRole === siteRoles.ADMIN) {
            isAdmin = true;
        }

        const initialValues = {
            milestonesSummary: project.milestones_summary,
            objectivesSummary: project.objectives_summary,
            deliverablesSummary: project.deliverables_summary,
            customerSatisfaction: project.customer_satisfaction ? project.customer_satisfaction : '',
            projectImpact: project.project_impact ? project.project_impact : '',
            finalReportLink: project.final_report_link ? project.final_report_link : '',
            customerSatisfactionNotRequired: project.customer_satisfaction === 0,
            projectImpactNotRequired: project.project_impact === 0,
            finalReportLinkOptional: !!project.is_final_report_link_optional,
        };

        const handleSubmit = (values, actions) => {
            let updatedProject;

            updatedProject = {
                status: isSaveOnly ? project.status : projectStatus,
                milestones_summary: values.milestonesSummary,
                objectives_summary: values.objectivesSummary,
                deliverables_summary: values.deliverablesSummary,
                customer_satisfaction:
                    !project.customer_satisfaction || values.customerSatisfactionNotRequired
                        ? 0
                        : values.customerSatisfaction,
                project_impact: !project.project_impact || values.finalReportLinkOptional ? 0 : values.projectImpact,
                final_report_link: values.finalReportLink,
                is_final_report_link_optional: values.finalReportLinkOptional,
                // Added to fix issue on closeout, patch fix
                last_reviewed_on: project.last_reviewed_on,
                people: [],
                wbi_lead_user_id: project.wbi_lead_user_id,
                wbi_assistant_user_id: project.wbi_assistant_user_id,
            };

            project.users.forEach((e) => {
                updatedProject.people.push(e.id);
            });
            // End patch fix

            try {
                ProjectsApi.updateProject(project.id, updatedProject).then((response) => {
                    const projectData = response.data;
                    this.setState({
                        project: projectData,
                        projectStatus: projectData.status,
                    });
                    actions.setSubmitting(false);
                    if (response.status === 200) {
                        return successToast('Changes saved successfully!');
                    }

                    if (!isSaveOnly) {
                        actions.resetForm();
                    }
                });
            } catch (error) {
                return errorToast(error);
            }
        };

        const handleActivate = async () => {
            const confirmed = await confirm({
                text: 'Are you sure you want to Activate the Project?',
            });
            if (!confirmed) return false;
            this.setState({ projectStatus: 'active' });
            return true;
        };
        const handleArchive = async () => {
            const confirmed = await confirm({
                text: 'Are you sure you want to Archive the Project?',
            });
            if (!confirmed) return false;
            this.setState({ projectStatus: 'archived' });
            return true;
        };

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={yup.object().shape({
                    milestonesSummary: isFieldRequired ? yup.string().required() : yup.string(),
                    objectivesSummary: isFieldRequired ? yup.string().required() : yup.string(),
                    deliverablesSummary: isFieldRequired ? yup.string().required() : yup.string(),
                    customerSatisfaction: isFieldRequired
                        ? yup.number()
                        : yup.number().when('customerSatisfactionNotRequired', {
                              is: false,
                              then: yup.number().min(1).max(5).required(),
                          }),
                    projectImpact: isFieldRequired
                        ? yup.number().when('projectImpactNotRequired', {
                              is: false,
                              then: yup.number().min(1).max(5).required(),
                          })
                        : yup.number(),
                    finalReportLink: isFieldRequired
                        ? yup.string().when('finalReportLinkOptional', {
                              is: false,
                              then: yup.string().required(),
                          })
                        : yup.string(),
                    customerSatisfactionNotRequired: yup.boolean(),
                    projectImpactNotRequired: yup.boolean(),
                    finalReportLinkOptional: yup.boolean(),
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, isValid, isSubmitting, submitForm }) => (
                    <Form className="flex flex-column">
                        <div className="Closeout-page-form">
                            <div className="flex flex-column column-span-2">
                                <SectionHeading>Milestones</SectionHeading>
                                <Table
                                    data={project.milestones}
                                    columns={[
                                        {
                                            headerName: 'Time Frame',
                                            field: 'to_date',
                                            type: 'date',
                                        },
                                        {
                                            headerName: 'Completed',
                                            field: 'completed',
                                            cellRenderer: 'BooleanRenderer',
                                            type: 'status',
                                        },
                                        {
                                            headerName: 'Description',
                                            field: 'description',
                                            type: 'longtext',
                                        },
                                    ]}
                                />
                                <LabeledField
                                    label="Summary*"
                                    name="milestonesSummary"
                                    component="textarea"
                                    errors={errors}
                                    touched={touched}
                                    readOnly={isArchived}
                                    labelClassName="text-align-left mt2 mb2"
                                />
                            </div>
                            <div className="flex flex-column column-span-2">
                                <SectionHeading>Key Goals and Objectives</SectionHeading>
                                <Table
                                    data={project.key_goals}
                                    columns={[
                                        {
                                            headerName: 'Reached',
                                            field: 'reached',
                                            cellRenderer: 'BooleanRenderer',
                                            type: 'status',
                                        },
                                        {
                                            headerName: 'Description',
                                            field: 'description',
                                            type: 'longtext',
                                        },
                                    ]}
                                />
                                <LabeledField
                                    label="Summary*"
                                    name="objectivesSummary"
                                    component="textarea"
                                    errors={errors}
                                    touched={touched}
                                    readOnly={isArchived}
                                    labelClassName="text-align-left mt2 mb2"
                                />
                            </div>
                            <div className="flex flex-column column-span-2">
                                <SectionHeading>Deliverables</SectionHeading>
                                <Table
                                    data={project.deliverables}
                                    columns={[
                                        {
                                            headerName: 'Time Frame',
                                            field: 'due_date',
                                            type: 'date',
                                        },
                                        {
                                            headerName: 'Completed',
                                            field: 'completed',
                                            cellRenderer: 'BooleanRenderer',
                                            type: 'status',
                                        },
                                        {
                                            headerName: 'Description',
                                            field: 'description',
                                            type: 'longtext',
                                        },
                                    ]}
                                />
                                <LabeledField
                                    label="Summary*"
                                    name="deliverablesSummary"
                                    component="textarea"
                                    errors={errors}
                                    touched={touched}
                                    readOnly={isArchived}
                                    labelClassName="text-align-left mt2 mb2"
                                />
                            </div>
                            <div className="flex flex-column">
                                <label className="bold">Residual Funds*</label>
                                {new ErgoMoney(financialInfo.remaining_funds).getStringAmount()}
                                <div className="FieldError">{residualFundsMessage}</div>
                                <Link to={`/projects/${this.props.match.params.id}/transfer-funds`}>(Move Funds)</Link>
                            </div>
                            <div className="flex flex-column">
                                <LabeledField
                                    label={
                                        project.customer_satisfaction === 0
                                            ? 'Customer Satisfaction'
                                            : 'Customer Satisfaction*'
                                    }
                                    name="customerSatisfaction"
                                    component="Rating"
                                    value={project.customer_satisfaction}
                                    errors={errors}
                                    touched={touched}
                                    labelClassName="text-align-left pt0 mb2"
                                />
                                {isArchived ? (
                                    <LabeledField
                                        name="customerSatisfactionNotRequired"
                                        type="text"
                                        value={!project.customer_satisfaction ? 'Not Required' : ''}
                                        readOnly
                                    />
                                ) : (
                                    <FieldWithError
                                        label="No Response from Client"
                                        name="customerSatisfactionNotRequired"
                                        component="Checkbox"
                                        className="mt1"
                                    />
                                )}
                            </div>
                            <div className="flex flex-column">
                                <LabeledField
                                    label={project.project_impact === 0 ? 'Project Impact' : 'Project Impact*'}
                                    name="projectImpact"
                                    component="Rating"
                                    value={isArchived ? project.project_impact : undefined}
                                    errors={errors}
                                    touched={touched}
                                    labelClassName="text-align-left pt0 mb2"
                                />
                                {isArchived ? (
                                    <LabeledField
                                        name="projectImpactNotRequired"
                                        type="text"
                                        value={!project.project_impact ? 'Not Required' : ''}
                                        readOnly
                                    />
                                ) : (
                                    <FieldWithError
                                        label="No Response from Client"
                                        name="projectImpactNotRequired"
                                        component="Checkbox"
                                        className="mt1"
                                    />
                                )}
                            </div>
                            <div className="flex flex-column">
                                <div>
                                    <LabeledField
                                        label={
                                            project.is_final_report_link_optional
                                                ? 'Link to Final Report'
                                                : 'Link to Final Report*'
                                        }
                                        name="finalReportLink"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                        readOnly={isArchived}
                                        value={isArchived ? project.final_report_link : undefined}
                                        labelClassName="text-align-left pt0 mb2"
                                    />
                                    {isArchived ? (
                                        <LabeledField
                                            name="finalReportLinkOptional"
                                            type="text"
                                            value={project.is_final_report_link_optional ? 'Not Required' : ''}
                                            readOnly
                                        />
                                    ) : (
                                        <FieldWithError
                                            label="No Report Required"
                                            name="finalReportLinkOptional"
                                            component="Checkbox"
                                            className="mt1"
                                            disabled={!isAdmin}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button
                                    type="button"
                                    disabled={this.isDisabled(isSubmitting, financialInfo)}
                                    onClick={async () => {
                                        this.setState({ projectStatus: 'save' });
                                        submitForm();
                                    }}
                                    className="align-self-start"
                                    style={{ gridColumn: '-2' }}
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    disabled={this.isDisabled(isSubmitting, financialInfo)}
                                    onClick={async () => {
                                        if (isValid) {
                                            const result = isArchived ? await handleActivate() : await handleArchive();
                                            if (result) {
                                                submitForm();
                                            }
                                        }
                                        return false;
                                    }}
                                    className="align-self-start"
                                    style={{ gridColumn: '-2' }}
                                >
                                    {isArchived ? 'Activate' : 'Archive'}
                                </button>
                                <button
                                    disabled={this.isDisabled(isSubmitting, financialInfo)}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.deleteProject();
                                    }}
                                >
                                    Delete Project
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default CloseoutPage;
