import React from 'react';
import Fieldset from '../Fieldset';
import LabeledField from '../../components/forms/LabeledField';
import WbiRolesTable from './tables/WbiRolesTable';
import CustomerRolesTable from './tables/CustomerRolesTable';
import OtherRolesTable from './tables/OtherRolesTables';
import ViewPageExternalLink from '../../utils/ViewPageExternalLink';

const ProjectDetailsSection = (props) => {
    const { values, errors, touched, isEditing, pdStatusOptions, projectId, isNew } = props;

    let estimatedEndDateField = (
        <LabeledField
            label="Estimated End Date"
            name="estimated_end_date"
            type="date"
            component="Date"
            errors={errors}
            touched={touched}
            readOnly={!isEditing}
        />
    );

    let requiredAstrisk = isEditing ? '*' : '';

    return (
        <Fieldset title="Project Details">
            {!isNew && (
                <LabeledField
                    label="Project Funding Plan"
                    name="funding_plan"
                    component="textarea"
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
            )}
            {!isNew && (
                <WbiRolesTable
                    handleWbiRoles={props.handleWbiRoles}
                    wbi_roles={props.wbi_roles}
                    values={values}
                    errors={errors}
                    isEditing={isEditing}
                    projectId={projectId}
                />
            )}
            {!isNew && (
                <CustomerRolesTable
                    handleCustomerRoles={props.handleCustomerRoles}
                    customer_roles={props.customer_roles}
                    values={values}
                    errors={errors}
                    isEditing={isEditing}
                    projectId={projectId}
                />
            )}
            {!isNew && (
                <OtherRolesTable
                    handleOtherRoles={props.handleOtherRoles}
                    other_roles={props.other_roles}
                    values={values}
                    errors={errors}
                    isEditing={isEditing}
                    projectId={projectId}
                />
            )}
            {!isNew && (
                <LabeledField
                    label="Information Security"
                    name="information_security"
                    component="textarea"
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
            )}
            {!isNew && (
                <LabeledField
                    label="Customer Value Proposition"
                    name="customer_value_proposition"
                    component="textarea"
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
            )}
            {!isEditing && !isNew && (
                <label htmlFor="link_to_db" className="FieldLabel">
                    Link to DB:
                </label>
            )}
            {!isNew && !isEditing && (
                <div className="FieldInputWrapper" style={{ gridColumn: 'span 2 / auto' }}>
                    <div className="flex flex-column flex-1 external-link">
                        <ViewPageExternalLink link={values.link_to_db} text="Open in DB" />
                    </div>
                </div>
            )}
            {!isNew && isEditing && (
                <LabeledField
                    label="Link to DB"
                    name="link_to_db"
                    type="text"
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
            )}
            {!isNew && (
                <LabeledField
                    label="Project Description (PD) Status"
                    name="pd_status"
                    component="select"
                    options={pdStatusOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
            )}
            <LabeledField
                label={'Start Date' + requiredAstrisk}
                name="start_date"
                type="date"
                component="Date"
                errors={errors}
                touched={touched}
                readOnly={!isEditing}
            />
            {isNew && <div />}
            {!isNew && estimatedEndDateField}
        </Fieldset>
    );
};

export default ProjectDetailsSection;
