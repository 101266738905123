export const INNOVATION = 'innovation';
export const COMMERCIALIZATION = 'commercialization';
export const MAKER_HUB = 'makerhub';
export const TECH_WORKS = 'techworks';
export const SMALL_BUSINESS_HUB = 'smallbusiness';
export const CORE = 'core';
export const OPS_CENTER = 'opscenter';
export const ALL_SERVICES = 'all';

export const projectServiceTypes = [
    { label: 'Innovation Service', value: INNOVATION },
    { label: 'Commercialization Service', value: COMMERCIALIZATION },
    { label: 'Maker^Hub', value: MAKER_HUB },
    { label: 'Tech Works', value: TECH_WORKS },
    { label: 'Small Business Hub', value: SMALL_BUSINESS_HUB },
    { label: 'Core*', value: CORE },
];
export const allServiceTypes = [
    ...projectServiceTypes,
    { label: 'Ops Center', value: OPS_CENTER },
    { label: 'All Services', value: ALL_SERVICES },
];
export const labelForServiceType = (type) => allServiceTypes.find((service) => service.value === type).label;

export const projectStatuses = [
    { label: 'Potential', value: 'potential' },
    { label: 'Active', value: 'active' },
    { label: 'On Hold', value: 'onhold' },
    { label: 'Completed', value: 'completed' },
    { label: 'Terminated', value: 'terminated' },
];
export const projectTypes = [
    'AFRL Initiative',
    'Capturing Opportunities',
    'Commercial Initiatives',
    'Connect and Explore',
    'Divergent Collaboration',
    'Facilitation',
    'Focus Group',
    'Forum',
    'Graphic Facil/ Recording',
    'Graphic Innovation',
    'Hackathon',
    'Innovation Discovery',
    "Innovation Tool Dev't",
    'Open Innovation',
    'Problem Deconstruction',
    'Tech Sprint - Focused',
    'Tech Sprint - Long Term',
    'Technology Insights/Landscaping',
    'Technology Search',
    'Workforce Development',
    'Workshop - Connectors',
    'Workshop - Org Align',
    'Workshop - Road Mapping',
    'Workshop - SME Deep Dive',
    'Workshop - Strategy',
];
export const projectDescriptionStatuses = [
    'In Draft',
    'Yes - Final Complete',
    'Sub',
    'Sub Addendum',
    'Grant',
    'Not Required',
    'Not Done :(',
];
export const reportTypes = [
    { label: 'Project Summary Report', value: 'summary' },
    { label: 'Project Funds Status Report', value: 'fundsStatus' },
    { label: 'Project Description', value: 'description' },
    { label: 'Project Schedule', value: 'schedule' },
    { label: 'Configuration Change Requests', value: 'changeRequests' },
    { label: 'Sub-Contracts Report', value: 'subContracts' },
];
export const expenditureTypes = ['Contract', 'HW', 'Other', 'Registration', 'SW', 'SME Invoice', 'Travel'];
export const ccTypes = ['Business', 'Personal'];
export const subContractorInvoiceTypes = ['Purchase Order', 'Consulting Agreement', 'Sub-Recipient Agreement'];
export const strategicGoalsAndMetrics = [
    { label: '# of Colliders @ 444 - AFRL', value: 'afrlColliders', service: ALL_SERVICES },
    {
        label: '# of Colliders @ 444 - Small businesses',
        value: 'smallBizColliders',
        service: ALL_SERVICES,
    },
    { label: '# of collaborative Events @ 444', value: '444Events', service: ALL_SERVICES },
    {
        label: '# of employees developing from unique development opportunities',
        value: 'employeesDeveloping',
        service: ALL_SERVICES,
    },
    { label: '# of WFD Products produced', value: 'wfdProductsProduced', service: ALL_SERVICES },
    {
        label: '# of Collaborative Events @ Springfield St.',
        value: 'springfieldEvents',
        service: OPS_CENTER,
    },
    {
        label: '# of S&T Products (collaboration teams, other teams) @ Springfield',
        value: 'productsAtSpringfield',
        service: OPS_CENTER,
    },
    {
        label: '# of S&T Products @ 444 (SB)',
        value: 'sbProductsAt444',
        service: SMALL_BUSINESS_HUB,
    },
    { label: '# of S&T Products (MH)', value: 'mhProducts', service: MAKER_HUB },
    { label: '# warfighter needs addressed', value: 'warfighterNeeds', service: TECH_WORKS },
    {
        label: '# of projects provided with innovative insights or solutions',
        value: 'projectsWithInnovationInsights',
        service: INNOVATION,
    },
    {
        label: '# of WFD Products produced',
        value: 'innovationWfdProductsProduced',
        service: INNOVATION,
    },
    {
        label: '# of technologies moved towards Commercialization',
        value: 'techMovedTowardCommercialization',
        service: COMMERCIALIZATION,
    },
    { label: '# of commercialization showcases', value: 'showcases', service: COMMERCIALIZATION },
    {
        label: '# of AFRL scientists supported',
        value: 'scientistsSupported',
        service: COMMERCIALIZATION,
    },
    {
        label: '# of Colliders @ 444 - Commercialization',
        value: 'commercializationColliders',
        service: COMMERCIALIZATION,
    },
];
export const strategicGoalsAndMetricsForServiceType = (service) =>
    strategicGoalsAndMetrics.filter(
        (metric) => metric.service === service || metric.service === OPS_CENTER || metric.service === ALL_SERVICES
    );
export const labelForStrategicGoal = (code) => strategicGoalsAndMetrics.find((metric) => metric.value === code).label;

export const fundTransferTypes = [
    { label: 'Transfer Funds Between Project and Sub-Project', value: 'Subproject' },
    { label: 'Request Transfer Funds Between Projects', value: 'Project' },
    { label: 'Transfer Funds to Residual Funds', value: 'ResidualFund' },
];

export const projectAccountOptions = [
    { value: 41010, label: '41010 - Professional Development' },
    { value: 41400, label: '41400 - TEW Supplemental' },
    { value: 41400, label: '41400 - SBI Travel' },
    { value: 41461, label: '41461 - Innovation Services Travel' },
    { value: 62000, label: '62000 - G & A Travel' },
    { value: 62200, label: '62200 - G & A Professional Development' },
];
