// React
import React, { Component } from 'react';

// Libraries
import { Formik, Form } from 'formik';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';

// Components
import SelectionTable from '../components/tables/SelectionTable';

class StrategicMetricsForm extends Component {
    constructor(props) {
        super(props);
        this.hideAddMetric = this.hideAddMetric.bind(this);

        this.state = {
            metric: null,
            metric_year: null,
            target_year: null,
            planned_metric: null,
            forceSetData: false,
            gridApi: {},
        };
    }

    componentDidMount() {
        this.setState(
            {
                forceSetData: true,
                metric: '',
                metric_year: new Date().getFullYear(),
                target_year: '',
                planned_metric: '',
            },
            function () {
                this.setState({ forceSetData: false });
            }
        );
    }

    hideAddMetric() {
        if (this.props.new_annual_plan === 'yes') {
            if (this.props.copy_plan === 'yes') {
                return false;
            }
        }
        return true;
    }

    render() {
        const yesNoOptions = [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ];
        const { new_annual_plan, copy_plan, metric_services, listRowIndex } = this.props;

        return (
            <>
                <div className="Companion-form">
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            metric: '',
                            metric_year: '',
                            planned_metric: '',
                            new_annual_plan: 'no',
                            target_year: '',
                            copy_plan: 'no',
                            listRowIndex,
                            metric_services,
                        }}
                        validationSchema={yup.object().shape({
                            metric: yup.string().required(),
                            metric_year: yup.string().required(),
                            planned_metric: yup.string().required(),
                        })}
                        onSubmit={() => {}}
                    >
                        {({ values, errors, touched, resetForm, setFieldValue }) => (
                            <Form
                                onChange={(e) => {
                                    this.props.onRadioChange(e, setFieldValue);
                                }}
                            >
                                <LabeledField
                                    label="Create New Annual Plan"
                                    name="new_annual_plan"
                                    component="Radio"
                                    currentValue={values.new_annual_plan}
                                    options={[
                                        { label: 'Yes', value: 'yes' },
                                        { label: 'No', value: 'no' },
                                    ]}
                                    errors={errors}
                                    touched={touched}
                                />
                                {new_annual_plan === 'yes' && (
                                    <LabeledField
                                        label="Copy Existing Annual Plan"
                                        name="copy_plan"
                                        component="Radio"
                                        currentValue={values.copy_plan}
                                        options={yesNoOptions}
                                        errors={errors}
                                        touched={touched}
                                    />
                                )}
                                <LabeledField
                                    label="New Annual Plan Year*"
                                    name="metric_year"
                                    type="text"
                                    component="Year"
                                    errors={errors}
                                    touched={touched}
                                    onChange={(e) => {
                                        this.setState({
                                            metric_year: e.target.value,
                                        });
                                        setFieldValue('metric_year', e.target.value);
                                    }}
                                />
                                {new_annual_plan === 'yes' && copy_plan === 'yes' && (
                                    <LabeledField
                                        label="Existing Annual Plan Year*"
                                        name="target_year"
                                        type="text"
                                        component="Year"
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            this.setState({
                                                target_year: e.target.value,
                                            });
                                            setFieldValue('target_year', e.target.value);
                                        }}
                                    />
                                )}
                                {this.hideAddMetric() && (
                                    <LabeledField
                                        label="Add New Metric*"
                                        name="metric"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            this.setState({
                                                metric: e.target.value,
                                            });
                                            setFieldValue('metric', e.target.value);
                                        }}
                                    />
                                )}
                                {this.hideAddMetric() && (
                                    <div className="In-form-table Width-in-form">
                                        <SelectionTable
                                            data={this.props.options}
                                            columns={[
                                                {
                                                    type: 'checkbox',
                                                },
                                                {
                                                    headerName: 'Service Type',
                                                    field: 'name',
                                                    type: 'longtext',
                                                },
                                            ]}
                                            selectionChanged={(e) => {
                                                this.props.handleSelectionChange(e);
                                                this.setState({
                                                    gridApi: e.api,
                                                });
                                            }}
                                            sizeToFit={true}
                                            enableAutoDomLayout={true}
                                            addedNewData={this.state.forceSetData}
                                            tableClassName="Service-type-table"
                                        />
                                    </div>
                                )}
                                {this.hideAddMetric() && (
                                    <LabeledField
                                        label="Planned Metric Per Service Area*"
                                        name="planned_metric"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            this.setState({
                                                planned_metric: e.target.value,
                                            });
                                            setFieldValue('planned_metric', e.target.value);
                                        }}
                                    />
                                )}
                                <div className="Form__buttons--Reversed Buttons_format">
                                    <button type="button" onClick={() => resetForm()}>
                                        Cancel
                                    </button>
                                    {this.hideAddMetric() ? (
                                        <button
                                            type="submit"
                                            onClick={(e) => {
                                                this.props.handleSubmit(e, values, { resetForm }, this.state.gridApi);
                                            }}
                                        >
                                            Add Metric
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            onClick={(e) => {
                                                this.props.handleSubmit(e, values, { resetForm }, this.state.gridApi);
                                            }}
                                        >
                                            Create Plan
                                        </button>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </>
        );
    }
}

export default StrategicMetricsForm;
