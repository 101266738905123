import React from 'react';
import EditingTable from '../../components/tables/EditingTable';
import errorToast from '../../components/messages/ErrorMessage';
import StatementsApi from '../../api/bankStatements';

class ImportedStatementsTable extends React.Component {
    constructor(props) {
        super(props);

        this.deleteStatement = this.deleteStatement.bind(this);

        this.state = {
            forceSetData: false,
            rowData: [],
            columns: [
                {
                    headerName: 'Name',
                    field: 'name',
                    type: 'name',
                    editable: true,
                    isPopup: true,
                },
                {
                    headerName: 'From Date',
                    field: 'from_date',
                    type: 'date',
                    editable: true,
                    isPopup: true,
                },
                {
                    headerName: 'To Date',
                    field: 'to_date',
                    type: 'date',
                    editable: true,
                    isPopup: true,
                },
                {
                    headerName: 'Notes',
                    field: 'notes',
                    type: 'longtext',
                    editable: true,
                    isPopup: true,
                },
                {
                    headerName: 'Link to DB',
                    type: 'symbol',
                    field: 'link_to_db',
                    editable: false,
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                },
                {
                    headerName: 'Edit',
                    type: 'symbol',
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.props.clickEditHandler,
                },
                {
                    headerName: 'Close',
                    type: 'symbol',
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.deleteStatement,
                },
            ],
        };
    }

    UNSAFE_componentWillReceiveProps = (prevProps) => {
        const { statements } = this.props;

        if (prevProps.statements !== statements) {
            this.setState(
                {
                    rowData: prevProps.statements,
                    forceSetData: true,
                },
                () => {
                    this.setState({ forceSetData: false });
                }
            );
        }
    };

    deleteStatement(event) {
        let rowData = this.state.rowData;
        StatementsApi.deleteStatement(event.data.id)
            .then(() => {
                rowData = rowData.filter((el) => el.id !== event.data.id);
                this.setState(
                    {
                        rowData,
                        forceSetData: true,
                    },
                    () => {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    render() {
        const { rowData, columns } = this.state;

        return (
            <div className="Issues-table">
                <EditingTable
                    data={rowData}
                    columns={columns}
                    pagingProp={true}
                    addNewData={this.props.forceSetData}
                    sizeToFit={true}
                />
            </div>
        );
    }
}

export default ImportedStatementsTable;
