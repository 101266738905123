const createDropdownOption = (label, value) => {
    try {
        return {
            label,
            value,
        };
    } catch (error) {
        throw new Error('Error creating dropdown option');
    }
};
export { createDropdownOption as default };
