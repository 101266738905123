import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import moment from 'moment';

/* eslint-disable */

const DateEditor = forwardRef((props, ref) => {
    const getDateInputStr = (dt) => {
        let ergoDate;
        if (dt) {
            let temp = dt.split('-');
            ergoDate = temp[1] + '/' + temp[2] + '/' + temp[0];
        } else {
            ergoDate = '';
        }
        return ergoDate;
    };

    const getDateInputStrDefault = (dt) => {
        return dt ? moment(dt).format('YYYY-MM-DD') : '';
    };

    const inputRef = useRef();
    const [valueDate, setValueDate] = useState(props.value);

    const handleOnChange = (e) => {
        setValueDate(getDateInputStr(e.target.value));
    };

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return valueDate;
            },
        };
    });

    return (
        <div tabIndex={1} className="Table-date-editor">
            <input
                className="Table-date-editor"
                id="date"
                type="date"
                ref={inputRef}
                defaultValue={getDateInputStrDefault(valueDate)}
                onChange={handleOnChange}
            />
        </div>
    );
});

export default DateEditor;
