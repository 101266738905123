import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dropboxApi from '../../api/dropbox';

export default class DropboxLink extends Component {
    constructor(props) {
        super(props);

        this.onClickLink = this.onClickLink.bind(this);
    }

    onClickLink(e) {
        e.preventDefault();
        const dbid = this.props.value;

        dropboxApi.getLinkFromId(dbid).then((response) => {
            const link = response.data.link;
            window.open(link);
        });
    }

    render() {
        return (
            <div className="text-align-center">
                <Link
                    to=""
                    onClick={this.onClickLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Opens in new tab"
                >
                    <i className="fa fa-download" />
                </Link>
            </div>
        );
    }
}
