import React from 'react';
import { FieldArray as BasicFieldArray } from 'formik';
import Select from 'react-select';
import Fieldset from '../Fieldset';
import LabeledField from '../../components/forms/LabeledField';
import EditingTable from '../../components/tables/EditingTable';

class PeopleInvolvedSection extends React.Component {
    constructor(props) {
        super(props);

        this.addSelectedTeamMember = this.addSelectedTeamMember.bind(this);
        this.collectUsers = this.collectUsers.bind(this);

        this.state = {
            selectedUsers: null,
            users: null,
            people: [],
            selectedUserToAdd: null,
            counters: {},
            initialSignatures: null,
        };
    }

    componentDidMount() {
        const { selectedUsers, initialSignatures, values } = this.props;
        let counters = {};
        this.setState({
            selectedUsers,
            initialSignatures,
            people: values.users || [],
            counters,
        });
    }

    collectUsers(e) {
        const { selectedUsers } = this.state;
        const { users } = this.props;
        let oldUsers = users;
        let usersToUpdate = [];

        oldUsers.forEach((user) => {
            if (user.id !== e.value) usersToUpdate.push(user);
        });

        let newSelectedUsers = [];
        if (selectedUsers) {
            newSelectedUsers = selectedUsers;
            newSelectedUsers.push(e);
        }

        this.setState({
            selectedUsers: newSelectedUsers,
            users: usersToUpdate,
        });
    }

    addSelectedTeamMember(setFieldValue) {
        this.props.handleTeamMembers(this.state.selectedUserToAdd, 'add');
        const people = Array.from(new Set([...this.state.people, this.state.selectedUserToAdd]));
        this.setState({ people, selectedUserToAdd: null });
        setFieldValue('people', people);
    }

    removeUser(id, setFieldValue) {
        this.props.handleTeamMembers(id, 'remove');
        const people = this.state.people.filter((p) => p.id !== id);
        this.setState({ people });
        setFieldValue('people', people);
    }

    render() {
        const { values, errors, touched, userOptions, setFieldValue, isEditing, isNew, officeSymbolOptions } =
            this.props;

        let requiredAstrisk = isEditing ? '*' : '';
        return (
            <Fieldset title="People Involved">
                <h3 className="text-align-right">WBI Lead PM</h3>
                <div className="column-span-2" />
                <LabeledField
                    label={'Name' + requiredAstrisk}
                    name="wbi_lead_name"
                    component="select"
                    options={userOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                    clearable={false}
                    onChange={(e) => {
                        this.collectUsers(e);
                        setFieldValue('wbi_lead_name', e);
                        setFieldValue('wbi_lead_user_id', e.id);
                        if (e.email_address !== null) {
                            setFieldValue('wbi_lead_email_address', e.email_address);
                        }
                        if (e.phone_number !== null) {
                            setFieldValue('wbi_lead_phone_number', e.phone_number);
                        }
                    }}
                />
                {!isNew && values.wbi_lead_name && (
                    <LabeledField
                        label="Email Address"
                        name="wbi_lead_email_address"
                        type="email"
                        errors={errors}
                        touched={touched}
                        readOnly={true}
                    />
                )}
                {!isNew && values.wbi_lead_name && (
                    <LabeledField
                        label="Phone No."
                        name="wbi_lead_phone_number"
                        component="Phone"
                        type="text"
                        errors={errors}
                        touched={touched}
                        readOnly={true}
                    />
                )}
                {!isNew && <h3 className="text-align-right">WBI Assistant PM</h3>}
                {!isNew && <div className="column-span-2" />}
                {!isNew && (
                    <LabeledField
                        label="Name"
                        name="wbi_assistant_name"
                        component="select"
                        options={userOptions}
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                        onChange={(e) => {
                            if (typeof e !== 'string') {
                                setFieldValue('wbi_assistant_user_id', e.id);
                                setFieldValue('wbi_assistant_name', e);
                                if (e.email_address !== null) {
                                    setFieldValue('wbi_assistant_email_address', e.email_address);
                                }
                                if (e.phone_number !== null) {
                                    setFieldValue('wbi_assistant_phone_number', e.phone_number);
                                }
                            } else {
                                setFieldValue('wbi_assistant_user_id', null);
                                setFieldValue('wbi_assistant_name', e);
                                if (e.email_address !== null) {
                                    setFieldValue('wbi_assistant_email_address', e.email_address);
                                }
                                if (e.phone_number !== null) {
                                    setFieldValue('wbi_assistant_phone_number', e.phone_number);
                                }
                            }
                        }}
                    />
                )}
                {!isNew && values.wbi_assistant_name && (
                    <LabeledField
                        label="Email Address"
                        name="wbi_assistant_email_address"
                        type="email"
                        errors={errors}
                        touched={touched}
                        readOnly={true}
                    />
                )}
                {!isNew && values.wbi_assistant_name && (
                    <LabeledField
                        label="Phone No."
                        name="wbi_assistant_phone_number"
                        component="Phone"
                        errors={errors}
                        touched={touched}
                        readOnly={true}
                    />
                )}
                {!isNew && (
                    <BasicFieldArray
                        name="users"
                        render={() => (
                            <>
                                <div className="column-span-3">
                                    <h3 className="">WBI Team Members</h3>
                                    <EditingTable
                                        data={this.state.people}
                                        columns={[
                                            {
                                                headerName: 'User',
                                                field: 'name',
                                                editable: true,
                                                type: 'longtext',
                                            },
                                            {
                                                hide: !isEditing,
                                                headerName: 'Delete',
                                                type: 'symbol',
                                                editable: false,
                                                cellRenderer: 'TrashSymbolRenderer',
                                                onCellClicked: (event) => {
                                                    this.removeUser(event.data.id, setFieldValue);
                                                },
                                            },
                                        ]}
                                        rowIndex={this.state.rowIndex}
                                        colKey={this.state.colKey}
                                        sizeToFit={!isEditing}
                                    />
                                </div>
                                <div className="column-span-2" />
                            </>
                        )}
                    />
                )}
                {isEditing && !isNew && (
                    <div className="column-span-3 flex justify-content-end">
                        <div className="Peeps-select">
                            <Select
                                value={this.state.selectedUserToAdd}
                                options={userOptions}
                                onChange={(e) => {
                                    this.setState({ selectedUserToAdd: e });
                                }}
                            />
                        </div>
                        <div>
                            <button
                                className="End-button"
                                type="button"
                                disabled={this.state.selectedUserToAdd === null}
                                onClick={() => {
                                    this.addSelectedTeamMember(setFieldValue);
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                )}
                <hr className="column-span-3" />
                <h3 className="text-align-right">Customer</h3>
                <div className="column-span-2" />
                {!isNew && (
                    <LabeledField
                        label="Office Symbol"
                        name="customer_organization"
                        component="select"
                        options={officeSymbolOptions}
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                        onChange={(e) => {
                            setFieldValue('customer_organization', e);
                            setFieldValue('office_symbol_id', e);
                        }}
                    />
                )}
                <LabeledField
                    label={'Point of Contact' + requiredAstrisk}
                    name="customer_name"
                    type="text"
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                {!isNew && (
                    <LabeledField
                        label="Email Address"
                        name="customer_email_address"
                        type="email"
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                    />
                )}
                {!isNew && (
                    <LabeledField
                        label="Phone No."
                        name="customer_phone_number"
                        type="tel"
                        component="Phone"
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                    />
                )}
                {!isNew && <h3 className="text-align-right">Customer Assistant</h3>}
                {!isNew && <div className="column-span-2" />}
                {!isNew && (
                    <LabeledField
                        label="Office Symbol"
                        name="customer_assistant_organization"
                        component="select"
                        options={officeSymbolOptions}
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                        onChange={(e) => {
                            setFieldValue('customer_assistant_organization', e);
                        }}
                    />
                )}
                {!isNew && (
                    <LabeledField
                        label="Assistant Point of Contact"
                        name="customer_assistant_name"
                        type="text"
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                    />
                )}
                {!isNew && (
                    <LabeledField
                        label="Email Address"
                        name="customer_assistant_email_address"
                        type="email"
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                    />
                )}
                {!isNew && (
                    <LabeledField
                        label="Phone No."
                        name="customer_assistant_phone_number"
                        type="tel"
                        component="Phone"
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                    />
                )}
                <hr className="column-span-3" />
            </Fieldset>
        );
    }
}

export default PeopleInvolvedSection;
