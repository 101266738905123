import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as yup from 'yup';
import LabeledField from '../../components/forms/LabeledField';

const FormButtonBlock = (props) => {
    const {
        deleteExpenditure,
        existingExpenditureId,
        pageLeft,
        pageRight,
        disablePaging,
        resetForm,
        cancelExpenditure,
    } = props;
    const [submitButtonLabel, setSubmitButtonLabel] = useState('Add');

    const handleCancelEvent = () => {
        cancelExpenditure();
        resetForm();
    };

    const handleDeleteEvent = () => {
        deleteExpenditure(existingExpenditureId);
    };

    useEffect(() => {
        if (existingExpenditureId) {
            setSubmitButtonLabel('Update Expenditure');
        } else {
            setSubmitButtonLabel('Add');
        }
    }, [existingExpenditureId]);

    return (
        <div className="Form__buttons--Reversed Buttons_format">
            <button type="button" onClick={pageLeft} disabled={disablePaging}>
                Page Left
            </button>
            <button type="button" onClick={pageRight} disabled={disablePaging}>
                Page Right
            </button>
            <button type="reset" onClick={handleCancelEvent}>
                Cancel
            </button>
            {existingExpenditureId && (
                <button type="button" onClick={handleDeleteEvent}>
                    Delete
                </button>
            )}
            <button type="submit">{submitButtonLabel}</button>
        </div>
    );
};

// Standard form.  Covers Taxi, Other, Hotel parking, car rental gas
export const StandardForm = (props) => {
    const {
        formInitValues,
        selectTravelExpenditureTypeOption,
        addExpenditure,
        expenditureTypeOptions,
        cardHolderOptions,
        existingExpenditureId,
        readOnly,
    } = props;

    if (!expenditureTypeOptions) {
        return <div>...Loading</div>;
    }

    return (
        <div className="Companion-form">
            <Formik
                enableReinitialize={true}
                initialValues={formInitValues}
                validationSchema={yup.object().shape({
                    cc_holder_id: yup.string().required(),
                    travel_expenditure_type: yup.string().required(),
                    purchase_date: yup.date().required(),
                    store: yup.string().required(),
                    cost: yup.string().required(),
                    link_to_db: yup.string().required(),
                    cc_type: yup.string().required(),
                    notes: yup.string(),
                })}
                onSubmit={addExpenditure}
            >
                {({ errors, touched, values, resetForm }) => (
                    <Form>
                        <LabeledField
                            label="Category*"
                            name="travel_expenditure_type"
                            component="select"
                            options={expenditureTypeOptions}
                            getValue={selectTravelExpenditureTypeOption}
                            errors={errors}
                            touched={touched}
                            readOnly={!!existingExpenditureId}
                        />
                        <LabeledField
                            label="Traveler/Cardholder*"
                            name="cc_holder_id"
                            component="select"
                            options={cardHolderOptions}
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                        />
                        <LabeledField
                            label="Transaction date*"
                            name="purchase_date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField label="Vendor*" name="store" errors={errors} touched={touched} />
                        <LabeledField label="Amount*" name="cost" component="Money" errors={errors} touched={touched} />
                        <LabeledField label="Link To DB*" name="link_to_db" errors={errors} touched={touched} />
                        <LabeledField
                            label="Personal Charge*"
                            name="cc_type"
                            component="Radio"
                            currentValue={values.cc_type}
                            options={[
                                { label: 'Yes', value: 'personal' },
                                { label: 'No', value: 'business' },
                            ]}
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="Comments"
                            name="notes"
                            component="textarea"
                            errors={errors}
                            touched={touched}
                        />
                        <FormButtonBlock {...props} resetForm={resetForm} />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export const ParkingTollsForm = (props) => {
    const {
        formInitValues,
        selectTravelExpenditureTypeOption,
        addExpenditure,
        expenditureTypeOptions,
        cardHolderOptions,
        existingExpenditureId,
        readOnly,
    } = props;

    return (
        <div className="Companion-form">
            <Formik
                enableReinitialize={true}
                initialValues={formInitValues}
                validationSchema={yup.object().shape({
                    cc_holder_id: yup.string().required(),
                    travel_expenditure_type: yup.string().required(),
                    purchase_date: yup.date().required(),
                    cost: yup.string().required(),
                    link_to_db: yup.string().required(),
                    cc_type: yup.string().required(),
                    notes: yup.string(),
                })}
                onSubmit={addExpenditure}
            >
                {({ errors, touched, values, resetForm }) => (
                    <Form>
                        <LabeledField
                            label="Category*"
                            name="travel_expenditure_type"
                            component="select"
                            options={expenditureTypeOptions}
                            getValue={selectTravelExpenditureTypeOption}
                            errors={errors}
                            touched={touched}
                            readOnly={!!existingExpenditureId}
                        />
                        <LabeledField
                            label="Traveler/Cardholder*"
                            name="cc_holder_id"
                            component="select"
                            options={cardHolderOptions}
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                        />
                        <LabeledField
                            label="Transaction date*"
                            name="purchase_date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField label="Amount*" name="cost" component="Money" errors={errors} touched={touched} />
                        <LabeledField label="Link To DB*" name="link_to_db" errors={errors} touched={touched} />
                        <LabeledField
                            label="Personal Charge*"
                            name="cc_type"
                            component="Radio"
                            currentValue={values.cc_type}
                            options={[
                                { label: 'Yes', value: 'personal' },
                                { label: 'No', value: 'business' },
                            ]}
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="Comments"
                            name="notes"
                            component="textarea"
                            errors={errors}
                            touched={touched}
                        />
                        <FormButtonBlock {...props} resetForm={resetForm} />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export const CarRentalForm = (props) => {
    const {
        formInitValues,
        selectTravelExpenditureTypeOption,
        addExpenditure,
        expenditureTypeOptions,
        cardHolderOptions,
        existingExpenditureId,
        readOnly,
    } = props;

    return (
        <>
            <div className="Companion-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formInitValues}
                    validationSchema={yup.object().shape({
                        cc_holder_id: yup.string().required(),
                        travel_expenditure_type: yup.string().required(),
                        store: yup.string().required(),
                        expense_start_date: yup.date().required(),
                        expense_end_date: yup.date().required(),
                        purchase_date: yup.date().required(),
                        cost: yup.string().required(),
                        link_to_db: yup.string().required(),
                        cc_type: yup.string().required(),
                        notes: yup.string(),
                    })}
                    onSubmit={addExpenditure}
                >
                    {({ errors, touched, values, resetForm }) => (
                        <Form>
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={expenditureTypeOptions}
                                getValue={selectTravelExpenditureTypeOption}
                                errors={errors}
                                touched={touched}
                                readOnly={!!existingExpenditureId}
                            />
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                errors={errors}
                                touched={touched}
                                readOnly={readOnly}
                            />
                            <LabeledField label="Vendor*" name="store" errors={errors} touched={touched} />
                            <LabeledField
                                label="Start Date*"
                                name="expense_start_date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="End Date*"
                                name="expense_end_date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Transaction Date*"
                                name="purchase_date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Total Charges*"
                                name="cost"
                                component="Money"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField label="Link To DB*" name="link_to_db" errors={errors} touched={touched} />
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Comments"
                                name="notes"
                                component="textarea"
                                errors={errors}
                                touched={touched}
                            />
                            <FormButtonBlock {...props} resetForm={resetForm} />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export const BaggageForm = (props) => {
    const {
        formInitValues,
        selectTravelExpenditureTypeOption,
        addExpenditure,
        expenditureTypeOptions,
        cardHolderOptions,
        existingExpenditureId,
        readOnly,
    } = props;

    return (
        <>
            <div className="Companion-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formInitValues}
                    validationSchema={yup.object().shape({
                        cc_holder_id: yup.string().required(),
                        travel_expenditure_type: yup.string().required(),
                        purchase_date: yup.date().required(),
                        store: yup.string().required(),
                        cost: yup.string().required(),
                        link_to_db: yup.string().required(),
                        cc_type: yup.string().required(),
                        notes: yup.string(),
                    })}
                    onSubmit={addExpenditure}
                >
                    {({ errors, touched, values, resetForm }) => (
                        <Form>
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={expenditureTypeOptions}
                                getValue={selectTravelExpenditureTypeOption}
                                errors={errors}
                                touched={touched}
                                readOnly={!!existingExpenditureId}
                            />
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                errors={errors}
                                touched={touched}
                                readOnly={readOnly}
                            />
                            <LabeledField
                                label="Transaction date*"
                                name="purchase_date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField label="Airline*" name="store" errors={errors} touched={touched} />
                            <LabeledField
                                label="Amount*"
                                name="cost"
                                component="Money"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField label="Link To DB*" name="link_to_db" errors={errors} touched={touched} />
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Comments"
                                name="notes"
                                component="textarea"
                                errors={errors}
                                touched={touched}
                            />
                            <FormButtonBlock {...props} resetForm={resetForm} />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export const AirFareForm = (props) => {
    const {
        formInitValues,
        selectTravelExpenditureTypeOption,
        addExpenditure,
        expenditureTypeOptions,
        cardHolderOptions,
        existingExpenditureId,
        readOnly,
    } = props;

    return (
        <>
            <div className="Companion-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formInitValues}
                    validationSchema={yup.object().shape({
                        cc_holder_id: yup.string().required(),
                        travel_expenditure_type: yup.string().required(),
                        purchase_date: yup.date().required(),
                        expense_start_date: yup.date().required(),
                        store: yup.string().required(),
                        cost: yup.string().required(),
                        link_to_db: yup.string().required(),
                        cc_type: yup.string().required(),
                        notes: yup.string(),
                    })}
                    onSubmit={addExpenditure}
                >
                    {({ errors, touched, values, resetForm }) => (
                        <Form>
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={expenditureTypeOptions}
                                getValue={selectTravelExpenditureTypeOption}
                                errors={errors}
                                touched={touched}
                                readOnly={!!existingExpenditureId}
                            />
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                errors={errors}
                                touched={touched}
                                readOnly={readOnly}
                            />
                            <LabeledField
                                label="Transaction date*"
                                name="purchase_date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Date of Departure*"
                                name="expense_start_date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField label="Airline*" name="store" errors={errors} touched={touched} />
                            <LabeledField
                                label="Amount*"
                                name="cost"
                                component="Money"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField label="Link To DB*" name="link_to_db" errors={errors} touched={touched} />
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Comments"
                                name="notes"
                                component="textarea"
                                errors={errors}
                                touched={touched}
                            />
                            <FormButtonBlock {...props} resetForm={resetForm} />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export const MilesDrivenForm = (props) => {
    const {
        formInitValues,
        selectTravelExpenditureTypeOption,
        addExpenditure,
        expenditureTypeOptions,
        cardHolderOptions,
        existingExpenditureId,
        readOnly,
    } = props;

    return (
        <>
            <div className="Companion-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formInitValues}
                    validationSchema={yup.object().shape({
                        cc_holder_id: yup.string().required(),
                        travel_expenditure_type: yup.string().required(),
                        cc_type: yup.string().required(),
                        notes: yup.string(),
                    })}
                    onSubmit={addExpenditure}
                >
                    {({ errors, touched, values, resetForm }) => (
                        <Form>
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={expenditureTypeOptions}
                                getValue={selectTravelExpenditureTypeOption}
                                errors={errors}
                                touched={touched}
                                readOnly={!!existingExpenditureId}
                            />
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                errors={errors}
                                touched={touched}
                                readOnly={readOnly}
                            />
                            <label className="FieldLabel"> Miles Driven Per Day</label>
                            <div className="FieldArrayWrapper">
                                <FieldArray
                                    name="per_day_miles"
                                    render={() => {
                                        if (Object.keys(values.per_day_miles).length === 0) {
                                            return <div>No date range selected!</div>;
                                        }
                                        return (
                                            <>
                                                {Object.keys(values.per_day_miles).map((key, index) => (
                                                    <div className="PerDayInput" key={index}>
                                                        <LabeledField
                                                            label={key}
                                                            name={`per_day_miles.${key}`}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                ))}
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Comments"
                                name="notes"
                                component="textarea"
                                errors={errors}
                                touched={touched}
                            />
                            <FormButtonBlock {...props} resetForm={resetForm} />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export const HotelForm = (props) => {
    const {
        formInitValues,
        selectTravelExpenditureTypeOption,
        addExpenditure,
        expenditureTypeOptions,
        cardHolderOptions,
        existingExpenditureId,
        readOnly,
    } = props;

    const createYupValidationSchema = () =>
        yup.object().shape({
            cc_holder_id: yup.string().required(),
            travel_expenditure_type: yup.string().required(),
            store: yup.string().required(),
            cost: yup.string(),
            link_to_db: yup.string().required(),
            cc_type: yup.string().required(),
            notes: yup.string(),
            hotel_non_reimburse: yup.string(),
            hotel_taxes_fees: yup.string().required(),
        });

    return (
        <>
            <div className="Companion-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formInitValues}
                    validationSchema={createYupValidationSchema}
                    onSubmit={addExpenditure}
                >
                    {({ errors, touched, values, resetForm }) => (
                        <Form>
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={expenditureTypeOptions}
                                getValue={selectTravelExpenditureTypeOption}
                                errors={errors}
                                touched={touched}
                                readOnly={!!existingExpenditureId}
                            />
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                errors={errors}
                                touched={touched}
                                readOnly={readOnly}
                            />
                            <LabeledField label="Vendor*" name="store" errors={errors} touched={touched} />
                            <LabeledField
                                label="Transaction date*"
                                name="purchase_date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <label className="FieldLabel">Rate Per Night</label>
                            <div className="FieldArrayWrapper">
                                <FieldArray
                                    name="hotel_per_night_rate"
                                    render={() => {
                                        if (Object.keys(values.hotel_per_night_rate).length === 0) {
                                            return <div>No date range selected!</div>;
                                        }
                                        return (
                                            <>
                                                {Object.keys(values.hotel_per_night_rate).map((key, index) => (
                                                    <div className="PerDayInput" key={index}>
                                                        <LabeledField
                                                            label={key}
                                                            name={`hotel_per_night_rate.${key}`}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                ))}
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Non-Reimbursables"
                                name="hotel_non_reimburse"
                                component="Money"
                                errors={errors}
                                touched={touched}
                                required={false}
                            />
                            <LabeledField
                                label="Total Taxes & Fees*"
                                name="hotel_taxes_fees"
                                component="Money"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Total Charges*"
                                name="cost"
                                component="Money"
                                value={
                                    Number(values.hotel_taxes_fees) +
                                    Number(values.hotel_non_reimburse) +
                                    Number(
                                        Object.keys(values.hotel_per_night_rate).reduce((sum, key) => {
                                            let value = sum;
                                            value += Number(values.hotel_per_night_rate[key]);
                                            return value;
                                        }, 0)
                                    )
                                }
                                errors={errors}
                                touched={touched}
                                readOnly={true}
                            />
                            <LabeledField label="Link To DB*" name="link_to_db" errors={errors} touched={touched} />
                            <LabeledField
                                label="Comments"
                                name="notes"
                                component="textarea"
                                errors={errors}
                                touched={touched}
                            />
                            <FormButtonBlock {...props} resetForm={resetForm} />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};
