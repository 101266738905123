import _ from 'lodash';
import errorToast from '../../components/messages/ErrorMessage';
import { travelExpenditureType } from '../../utils/TravelUtils';
import ErgoMoney from '../../utils/ErgoMoney';

export function sanitizeNumbers(value) {
    let newVal = _.clone(value);
    if (typeof newVal === 'undefined' || newVal === null) {
        return false;
    }
    if (typeof newVal === 'string') {
        newVal = newVal.replace('$', '');
    }
    newVal = parseFloat(newVal);
    return newVal;
}

export const recalculatePerDays = (travelExpense, manualPerDiem) => {
    if (!manualPerDiem) {
        manualPerDiem = [];
    }

    const travelItineraries = travelExpense.itinerary;

    if (!travelItineraries.length) {
        throw new Error('No travel itinerary entries');
    }

    const travelExpenseStartDate = new Date(travelItineraries[0].start_date);
    const travelExpenseEndDate = new Date(travelItineraries[travelItineraries.length - 1].end_date);

    const perDayCharges = [];
    let currentDate = new Date(travelExpenseStartDate);
    const dateStringOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    while (currentDate <= travelExpenseEndDate) {
        let dayIndex = 0;
        let dayPerDiem = travelItineraries[dayIndex].per_diem_rate;
        const dayMileageRate = travelItineraries[dayIndex].mileage_rate;
        const dayMaxLodgeRate = travelItineraries[dayIndex].max_lodge_rate;

        if (manualPerDiem.length > 0) {
            dayPerDiem = manualPerDiem[dayIndex];
        }

        if (
            currentDate.getDate() === travelExpenseStartDate.getDate() ||
            currentDate.getDate() === travelExpenseEndDate.getDate()
        ) {
            dayPerDiem *= 0.75;
        }

        const perDayCharge = {
            date: new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * -60000).toLocaleDateString(
                'en-US',
                dateStringOptions
            ),
            per_diem_rate: dayPerDiem,
            mileage_rate: dayMileageRate,
            max_lodge_rate: dayMaxLodgeRate,
        };

        perDayCharges.push(perDayCharge);

        currentDate.setDate(currentDate.getDate() + 1);
        dayIndex += 1;
    }
    return perDayCharges;
};

export const tableDataPrep = (perDayCharges, travelExpenditures) => {
    if (!perDayCharges) {
        perDayCharges = [];
    }

    if (!travelExpenditures) {
        travelExpenditures = [];
    }

    if (!Array.isArray(travelExpenditures)) {
        throw Error('Travel expenditures needs to be an array!');
    }

    const tableColumns = [];
    const tableRows = {};
    // Sample tableRows = {
    //     '03/23/2012': {
    //         Airfare: [{ num: 10 }, { num: 20 }],
    //         'Baggage Fees': [{ num: 10 }],
    //          ...
    //     },
    const createExpenditureRow = () => ({
        'Miles Driven': [],
        'Mileage Reimbursement': [],
        Airfare: [],
        'Baggage Fees': [],
        'Parking/Tolls': [],
        'Car Rental': [],
        'Car Rental Gas': [],
        'Taxi/Uber': [],
        Hotel: [],
        'Hotel Taxes & Fees': [],
        'Hotel Parking': [],
        'M&IE (First & Last day @ 75%)': [],
        'Non-Reimbursables': [],
        Other: [],
        Total: [],
    });

    const createExpenditureColumn = () => ({
        headerName: '',
        field: 'date',
        type: 'inputColumn',
    });

    try {
        for (const charge of perDayCharges) {
            const chargeColDef = createExpenditureColumn();
            chargeColDef.headerName = charge.date;
            tableColumns.push(chargeColDef);
            tableRows[charge.date] = createExpenditureRow();
        }

        for (const charge of perDayCharges) {
            const perDiemCharge = {
                perDiem: true,
                expenditure: {
                    cost: Number(charge.per_diem_rate),
                    cc_type: 'personal',
                },
            };
            tableRows[charge.date]['M&IE (First & Last day @ 75%)'].push(perDiemCharge);
        }
    } catch (error) {
        errorToast('Error setting up per day charges');
    }

    try {
        for (const travelExpenditure of travelExpenditures) {
            const expenditurePurchaseDate = travelExpenditure.expenditure.purchase_date;

            if (expenditurePurchaseDate && !tableRows[expenditurePurchaseDate]) {
                const outOfDateRangeColumn = createExpenditureColumn();
                outOfDateRangeColumn.headerName = expenditurePurchaseDate;
                tableColumns.push(outOfDateRangeColumn);
                tableRows[expenditurePurchaseDate] = createExpenditureRow();
            }
        }

        tableColumns.sort((columnA, columnB) => new Date(columnA.headerName) - new Date(columnB.headerName));
    } catch (error) {
        errorToast('Error adding out of travel expense date range expenditures');
    }

    try {
        for (const travelExpenditure of travelExpenditures) {
            switch (travelExpenditure.travel_expenditure_type) {
                case travelExpenditureType.miles_driven:
                    for (const [day, charge] of perDayCharges.entries()) {
                        tableRows[charge.date][travelExpenditure.travel_expenditure_type].push({
                            ...travelExpenditure,
                            milesDriven: travelExpenditure.per_day_miles[day],
                        });

                        tableRows[charge.date]['Mileage Reimbursement'].push({
                            ...travelExpenditure,
                            mileageCharge: travelExpenditure.per_day_miles[day] * charge.mileage_rate,
                        });
                    }
                    break;
                case travelExpenditureType.hotel:
                    if (travelExpenditure.expenditure.purchase_date) {
                        tableRows[travelExpenditure.expenditure.purchase_date]['Hotel Taxes & Fees'].push(
                            travelExpenditure
                        );
                        tableRows[travelExpenditure.expenditure.purchase_date]['Non-Reimbursables'].push(
                            travelExpenditure
                        );
                    } else {
                        // We have to do this as there exist some entries without purchase dates.
                        // This is a data integrity issue
                        tableRows[Object.keys(tableRows)[0]]['Hotel Taxes & Fees'].push(travelExpenditure);
                        tableRows[Object.keys(tableRows)[0]]['Non-Reimbursables'].push(travelExpenditure);
                        // ************************
                    }

                    for (const [day, charge] of perDayCharges.entries()) {
                        tableRows[charge.date]['Hotel'].push({
                            ...travelExpenditure,
                            nightRate: travelExpenditure.hotel_per_night_rate[day],
                        });
                    }
                    break;
                default:
                    tableRows[travelExpenditure.expenditure.purchase_date][
                        travelExpenditure.travel_expenditure_type
                    ].push(travelExpenditure);
                    break;
            }
        }
    } catch (error) {
        errorToast(error);
    }

    const summaryCardValues = {
        total_expenses: new ErgoMoney(),
        total_non_reimbursables: new ErgoMoney(),
        company_charges: new ErgoMoney(),
        amount_to_employee: new ErgoMoney(),
    };

    const summaryCardValuesOutput = {
        total_expenses: 0,
        total_non_reimbursables: 0,
        company_charges: 0,
        amount_to_employee: 0,
    };
    try {
        try {
            for (const travelExpenditure of travelExpenditures) {
                summaryCardValues.total_non_reimbursables.add(travelExpenditure.hotel_non_reimburse);

                if (travelExpenditure.expenditure.cc_type === 'personal') {
                    summaryCardValues.amount_to_employee.add(travelExpenditure.expenditure.cost);

                    if (travelExpenditure.travel_expenditure_type === travelExpenditureType.hotel) {
                        summaryCardValues.amount_to_employee.subtract(travelExpenditure.hotel_non_reimburse);
                    }
                } else if (travelExpenditure.expenditure.cc_type === 'business') {
                    summaryCardValues.company_charges.add(travelExpenditure.expenditure.cost);

                    if (travelExpenditure.travel_expenditure_type === travelExpenditureType.hotel) {
                        summaryCardValues.company_charges.subtract(travelExpenditure.hotel_non_reimburse);
                    }
                }
            }
        } catch (error) {
            errorToast('Error looping travel expenditures ' + error);
        }

        for (const charge of perDayCharges) {
            const chargeExpenditure = {
                perDiem: true,
                expenditure: {
                    cost: Number(charge.per_diem_rate),
                    cc_type: 'personal',
                },
            };

            summaryCardValues.amount_to_employee.add(chargeExpenditure.expenditure.cost);
        }

        summaryCardValues.amount_to_employee.subtract(summaryCardValues.total_non_reimbursables.getNumberAmount());

        summaryCardValues.total_expenses
            .add(summaryCardValues.amount_to_employee.getNumberAmount())
            .add(summaryCardValues.company_charges.getNumberAmount());
    } catch (error) {
        errorToast('Error calculating summary card values');
    }

    tableRows['personal'] = createExpenditureRow();
    tableRows['business'] = createExpenditureRow();
    tableRows['grand_total'] = createExpenditureRow();

    tableRows['personal']['Total'].push({
        type: 'personal',
        value: summaryCardValues.amount_to_employee.getNumberAmount(),
    });

    tableRows['business']['Total'].push({
        type: 'business',
        value: summaryCardValues.company_charges.getNumberAmount(),
    });

    tableRows['grand_total']['Total'].push({
        type: 'grand_total',
        value: summaryCardValues.total_expenses.getNumberAmount(),
    });

    summaryCardValuesOutput.amount_to_employee = summaryCardValues.amount_to_employee.getNumberAmount();
    summaryCardValuesOutput.company_charges = summaryCardValues.company_charges.getNumberAmount();
    summaryCardValuesOutput.total_expenses = summaryCardValues.total_expenses.getNumberAmount();
    summaryCardValuesOutput.total_non_reimbursables = summaryCardValues.total_non_reimbursables.getNumberAmount();

    return {
        columns: tableColumns,
        rowData: tableRows,
        summaryCardValues: summaryCardValuesOutput,
    };
};
