// React
import React, { Component } from 'react';
import { Formik, Form } from 'formik';

import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';

import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';

import PiaApi from '../api/pia';
import errorToast from '../components/messages/ErrorMessage';

class NewPiaPage extends Component {
    constructor(props) {
        super(props);

        this.getRowData = this.getRowData.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            pias: [],
            row: null,
            rowIndex: null,
            colKey: '',
            forceSetData: false,
            columns: [
                {
                    headerName: 'PIA',
                    field: 'name',
                    editable: true,
                },
                {
                    headerName: 'Year Received',
                    field: 'year_received',
                    editable: true,
                    type: 'number',
                    sort: 'desc',
                },
                {
                    headerName: 'Client',
                    field: 'client',
                    editable: true,
                },
                {
                    headerName: 'Contract No.',
                    field: 'contract_number',
                    type: 'idNum',
                    editable: true,
                },

                {
                    headerName: 'Ceiling Amount',
                    field: 'ceiling_amount',
                    type: 'money',
                    editable: true,
                },
                {
                    headerName: 'Link to DB',
                    field: 'link_to_db',
                    editable: false,
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                    onCellClicked: this.directToDb,
                    type: 'symbol',
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    editable: false,
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                    type: 'symbol',
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: pias } = await PiaApi.getPias();
            this.setState({
                pias,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRowValueChanged(event) {
        let piaToUpdate = event.node.data;

        if (piaToUpdate) {
            PiaApi.updatePia(piaToUpdate.id, {
                name: piaToUpdate.name,
                year_received: piaToUpdate.year_received,
                client: piaToUpdate.client,
                ceiling_amount: piaToUpdate.ceiling_amount,
                contract_number: piaToUpdate.contract_number,
                link_to_db: piaToUpdate.link_to_db,
            })
                .then(() => {
                    //
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'name',
        });
    }

    handleSubmit = (values, actions) => {
        if (values) {
            PiaApi.createPia({
                name: values.name,
                year_received: values.year_received,
                client: values.client,
                ceiling_amount: values.ceiling_amount,
                contract_number: values.contract_number,
                link_to_db: values.link_to_db,
            })
                .then((response) => {
                    let newPias = this.state.pias;
                    newPias.push(response.data);

                    this.setState(
                        {
                            pias: newPias,
                            forceSetData: true,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }

        actions.resetForm();
    };

    reload() {
        window.location.reload();
    }

    render() {
        return (
            <div>
                <PageHeader title="New PIA" />
                <AdminSubNav />
                <div>
                    <div className="Single-layout Companion-form">
                        <Formik
                            initialValues={{
                                name: '',
                                year_received: '',
                                client: '',
                                contract_number: '',
                                ceiling_amount: '',
                                link_to_db: '',
                            }}
                            validationSchema={yup.object().shape({
                                name: yup.string().max(10).required(),
                                year_received: yup.number().min(0).max(9999).required(),
                                client: yup.string().required(),
                                contract_number: yup.string().required(),
                                ceiling_amount: yup.number().required(),
                                link_to_db: yup.string(),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({ errors, touched, resetForm }) => (
                                <Form>
                                    <LabeledField
                                        label="PIA*"
                                        name="name"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Year Received*"
                                        name="year_received"
                                        type="number"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Client*"
                                        name="client"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Contract No.*"
                                        name="contract_number"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Ceiling Amount*"
                                        name="ceiling_amount"
                                        type="text"
                                        component="Money"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Link to DB"
                                        name="link_to_db"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />

                                    <div className="Form__buttons--Reversed Buttons_format">
                                        <button type="button" onClick={() => resetForm()}>
                                            Cancel
                                        </button>
                                        <button type="submit">Add PIA</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="Table-styling">
                        <EditingTable
                            columns={this.state.columns}
                            data={this.state.pias}
                            rowIndex={this.state.rowIndex}
                            colKey={this.state.colKey}
                            addedNewData={this.state.forceSetData}
                            rowValueChanged={this.onRowValueChanged}
                            sizeToFit={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default NewPiaPage;
