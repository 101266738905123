import React from 'react';

const BooleanRenderer = (props) => {
    const negative = props.negative ? props.negative : 'No';
    const positive = props.positive ? props.positive : 'Yes';
    if (!props.value || props.value === 'No') {
        return <div>{negative}</div>;
    }
    return <div>{positive}</div>;
};

export default BooleanRenderer;
