// React
import React, { Component } from 'react';

// Components
import FinanceSubNav from './FinanceSubNav';
import ImportForm from './bankStatements/ImportForm';
import ImportedStatementsTable from './bankStatements/ImportedStatementsTable';
import PageHeader from '../components/heading/PageHeader';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';
import errorToast from '../components/messages/ErrorMessage';

// API
import StatementsApi from '../api/bankStatements';

class BankStatementsImportPage extends Component {
    constructor(props) {
        super(props);

        this.addToStatements = this.addToStatements.bind(this);
        this.clickEditHandler = this.clickEditHandler.bind(this);
        this.cancelUpdate = this.cancelUpdate.bind(this);

        this.state = {
            statements: [],
            toUpdate: null,
            columns: [
                {
                    headerName: 'Card Holder',
                    field: 'card_holder',
                    editable: false,
                },
                {
                    headerName: 'Transaction Date',
                    field: 'transaction_date',
                    editable: false,
                },
                {
                    headerName: 'Post Date',
                    field: 'post_date',
                    editable: false,
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    editable: false,
                },
                {
                    headerName: 'Amount',
                    field: 'amount',
                    type: 'money',
                    editable: false,
                },
                {
                    headerName: 'Category',
                    field: 'category',
                    maxWidth: 250,
                    editable: false,
                },
                {
                    headerName: 'Memo',
                    field: 'memo',
                    editable: false,
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    editable: false,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: statements } = await StatementsApi.fetchStatements();
            this.setState(
                {
                    statements,
                    forceSetData: true,
                },
                () => {
                    this.setState({ forceSetData: false });
                }
            );
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    addToStatements(newStatement) {
        this.setState(
            {
                statements: [...this.state.statements, newStatement],
                forceSetData: true,
            },
            () => {
                this.setState({ forceSetData: false });
            }
        );
        //to clear out file input
        window.location.reload(true);
    }

    clickEditHandler(event) {
        let statements;
        if (this.state.statements) {
            statements = this.state.statements.filter((item) => event.node.data !== item);
        }
        this.setState({
            toUpdate: event.node.data,
            statements,
        });
    }

    cancelUpdate(data) {
        this.setState(
            {
                statements: [...this.state.statements, data],
                toUpdate: null,
                forceSetData: true,
            },
            () => {
                this.setState({ forceSetData: false });
            }
        );
    }

    render() {
        const { toUpdate } = this.state;

        let initialValues = null;
        if (toUpdate) {
            initialValues = {
                id: toUpdate.id,
                name: toUpdate.name,
                from_date: toUpdate.from_date,
                to_date: toUpdate.to_date,
                link_to_db: toUpdate.link_to_db,
                notes: toUpdate.notes ? toUpdate.notes : '',
                file: toUpdate.file,
                existing: true,
            };
        } else {
            initialValues = {
                id: '',
                name: '',
                from_date: '',
                to_date: '',
                link_to_db: '',
                notes: '',
                file: '',
                existing: false,
            };
        }

        return (
            <div>
                <PageHeader title="Bank Statements" />
                <FinanceSubNav />
                <SubSubNav>
                    <SubSubNavLink to="/finance/bankStatements" exact>
                        View Bank Statements
                    </SubSubNavLink>
                    <SubSubNavLink to="/finance/bankStatements/import">Import Bank Statement</SubSubNavLink>
                    <SubSubNavLink to="/finance/bankStatements/review">Review &amp; Reconcile</SubSubNavLink>
                </SubSubNav>
                <div>
                    <div className="Single-layout Companion-form">
                        <ImportForm
                            onNewData={this.addToStatements}
                            itemToUpdate={this.state.toUpdate}
                            initialValues={initialValues}
                            cancelUpdate={this.cancelUpdate}
                        />
                    </div>
                    <div className="Table-styling">
                        <ImportedStatementsTable
                            statements={this.state.statements}
                            forceSetData={this.state.forceSetData}
                            clickEditHandler={this.clickEditHandler}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default BankStatementsImportPage;
