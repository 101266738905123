// React
import React from 'react';

// Libraries
import moment from 'moment';
import { Formik, Form } from 'formik';
import yup from '../yup';

// Components
import LabeledField from '../components/forms/LabeledField';
import GanttChart from '../components/tables/GanttChart';
import errorToast from '../components/messages/ErrorMessage';

// API
import ProjectsApi from '../api/projects';

class Schedule extends React.Component {
    constructor(props) {
        super(props);

        this.handleMilestoneSubmit = this.handleMilestoneSubmit.bind(this);
        this.handleDeliverableSubmit = this.handleDeliverableSubmit.bind(this);
        this.handleTimeframeSubmit = this.handleTimeframeSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);

        this.state = {
            newItem: null,
            timeframeStart: new Date().getFullYear() + '-01-01',
            timeframeEnd: new Date().getFullYear() + '-12-31',
            milestone: '',
            deliverable: '',
            startDate: '',
            endDate: '',
            dueDate: '',
            projectId: this.props.match.params.id,
        };
    }

    componentDidMount() {
        this.setState({ projectId: this.props.match.params.id });
    }

    handleMilestoneSubmit(values, actions) {
        const { projectId } = this.state;
        if (moment(values.endDate) < moment(values.startDate)) {
            errorToast('There is an error in the Dates you chose please review');
            actions.setSubmitting(false);
            return;
        }
        if (values.milestone && values.startDate && values.endDate) {
            const milestoneObj = {
                description: values.milestone,
                from_date: values.startDate,
                to_date: values.endDate,
                event_type: values.type,
            };
            ProjectsApi.addMilestone(projectId, milestoneObj).then(() => {
                actions.resetForm();
                this.setState({ dataUpdated: new Date() });
            });
        }
    }

    handleDeliverableSubmit(values, actions) {
        const { projectId } = this.state;
        const { deliverable, dueDate, type } = values;
        if (deliverable && dueDate) {
            let deliverableObj = {
                description: deliverable,
                due_date: dueDate,
                event_type: type,
            };
            ProjectsApi.addDeliverable(projectId, deliverableObj).then(() => {
                actions.resetForm();
                this.setState({ dataUpdated: new Date() });
            });
        }
    }

    handleTimeframeSubmit(values) {
        if (moment(values.toDate) < moment(values.fromDate)) {
            errorToast('There is an error in the dates you chose, please review');
            return;
        }
        this.setState({
            timeframeStart: values.fromDate,
            timeframeEnd: values.toDate,
            dataUpdated: new Date(),
        });
    }

    handleFocus() {
        this.setState({ newItem: null });
    }

    render() {
        const { projectId, timeframeStart, timeframeEnd } = this.state;

        return (
            <>
                <div className="Schedule-page">
                    <div className="Companion-form--top">
                        <Formik
                            initialValues={{
                                startDate: '',
                                endDate: '',
                                milestone: '',
                                type: 'milestone',
                            }}
                            validationSchema={yup.object().shape({
                                startDate: yup.date().required(),
                                endDate: yup.date().required(),
                                milestone: yup.string().required(),
                            })}
                            onSubmit={this.handleMilestoneSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <LabeledField
                                        label="Milestone*"
                                        name="milestone"
                                        type="text"
                                        onFocus={this.handleFocus}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="From*"
                                        name="startDate"
                                        type="date"
                                        component="Date"
                                        onFocus={this.handleFocus}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="To*"
                                        name="endDate"
                                        type="date"
                                        component="Date"
                                        onFocus={this.handleFocus}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <button type="submit">Schedule Milestone</button>
                                </Form>
                            )}
                        </Formik>

                        <Formik
                            initialValues={{
                                dueDate: '',
                                deliverable: '',
                                type: 'deliverable',
                            }}
                            validationSchema={yup.object().shape({
                                dueDate: yup.date().required(),
                                deliverable: yup.string().required(),
                            })}
                            onSubmit={this.handleDeliverableSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <LabeledField
                                        label="Deliverable*"
                                        name="deliverable"
                                        type="text"
                                        onFocus={this.handleFocus}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Due Date*"
                                        name="dueDate"
                                        type="date"
                                        component="Date"
                                        onFocus={this.handleFocus}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <button type="submit">Schedule Deliverable</button>
                                </Form>
                            )}
                        </Formik>

                        <Formik
                            initialValues={{
                                fromDate: this.state.timeframeStart,
                                toDate: this.state.timeframeEnd,
                            }}
                            validationSchema={yup.object().shape({
                                startDate: yup.date(),
                            })}
                            onSubmit={this.handleTimeframeSubmit}
                        >
                            {(errors, touched) => (
                                <Form>
                                    <LabeledField
                                        label="Chart Filter From: "
                                        name="fromDate"
                                        type="date"
                                        component="Date"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Chart Filter To: "
                                        name="toDate"
                                        type="date"
                                        component="Date"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <button type="submit">Set Range</button>
                                </Form>
                            )}
                        </Formik>
                        <button
                            type="button"
                            className="End-button"
                            onClick={() => {
                                window.print();
                                return false;
                            }}
                        >
                            Print
                        </button>
                    </div>
                    <div className="flex Wide-single-layout GanttContainer">
                        <GanttChart
                            timeframe={[timeframeStart, timeframeEnd]}
                            projectId={projectId}
                            dataUpdated={this.state.dataUpdated}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default Schedule;
