import React from 'react';
import PagingTable from '../../components/tables/PagingTable';

const FinancePageModsTable = (props) => (
    <div className="Table-styling">
        <PagingTable
            columns={[
                // Mod No. / PIA / CPO / CPO Ceiling / Mod Amount / Mod Rcvd Date / Funds Rcvd Date / No. of Projects Funded
                { headerName: 'Mod No.', field: 'number', sort: 'desc' },
                { headerName: 'PIA', field: 'pia_name', type: 'number' },
                { headerName: 'CPO', field: 'cpo_name', type: 'number' },
                { headerName: 'CPO Ceiling', field: 'ceiling_amount', type: 'money' },
                { headerName: 'Mod Amount', field: 'amount_to_date', type: 'money' },
                {
                    headerName: 'Mod Rec. Date',
                    field: 'received_date',
                    type: 'date',
                },
                {
                    headerName: 'Funds Rec. Date',
                    field: 'funds_received',
                    type: 'date',
                },
                { headerName: 'No. of Projects Funded', field: 'projects_funded', type: 'number' },
            ]}
            data={props.mods}
            filter={true}
            sortable={true}
            sizeToFit={true}
        />
    </div>
);

export default FinancePageModsTable;
