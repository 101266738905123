import React, { useState, useEffect, useRef, useCallback } from 'react';
import { confirm } from '../../components/modals/ConfirmModal';
import ExportCSVButton from '../../components/buttons/ExportCSVButton';
import PagingTable from '../../components/tables/PagingTable';
import errorToast from '../../components/messages/ErrorMessage';
import ModsApi from '../../api/mods';
import useFunctionAsState from '../../hooks/useFunctionAsState';

const ModsTable = (props) => {
    const [exportDataAsCsv, setExportDataAsCsv] = useFunctionAsState(() => {});
    const [tableData, setTableData] = useState([]);
    const [forceSetData, setForceSetData] = useState(false);

    const propMods = useRef();
    const propProjects = useRef();

    const mapModDataToAcrns = (mod) => {
        if (mod.acrns.length) {
            return mod.acrns.map((acrn) => {
                acrn.pia_name = mod.pia_name;
                acrn.cpo_number = mod.cpo.number;
                acrn.received_date = mod.received_date;
                acrn.funds_received = mod.funds_received;
                acrn.number = mod.number;
                acrn.link_to_db = mod.link_to_db;
                acrn.created_on = mod.created_on;
                acrn.updated_on = mod.updated_on;
                return acrn;
            });
        }
    };

    const populateAcrnData = useCallback(
        (acrn) => {
            const { users } = props;
            const { current: currentProjects } = propProjects;
            const matchedProject = currentProjects.find((project) => acrn.project_id === project.id);
            if (matchedProject) {
                acrn.project_name = matchedProject.name;
                acrn.project_number = matchedProject.number;
                acrn.wbi_lead_user_id = matchedProject.wbi_lead_user_id;
            }
            const matchedWbiLead = users.find((user) => acrn.wbi_lead_user_id === user.id);
            if (matchedWbiLead) {
                acrn.wbi_lead_user_name = matchedWbiLead.name;
            }
            return acrn;
        },
        [props]
    );

    const reduceModsToAcrns = useCallback(
        (mods) => {
            const { pias } = props;
            return mods
                .map((mod) => {
                    const matchedPia = pias.find((pia) => pia.id === mod.cpo.pia_id);
                    if (matchedPia) {
                        mod.pia_name = matchedPia.name;
                    }
                    return mapModDataToAcrns(mod);
                })
                .flat();
        },
        [props]
    );

    const mapAcrnsToTableData = useCallback(
        (acrns) => {
            if (acrns.length) {
                return acrns.map(populateAcrnData);
            }
        },
        [populateAcrnData]
    );

    const filterOutAFRLPIA = useCallback(
        (data) => {
            const { pias } = props;
            let variousClientPia = [];
            pias.forEach((element) => {
                if (element.client === 'Various') {
                    variousClientPia.push(element.name);
                }
            });
            if (data && data.length > 0) {
                let filterResult = data.filter((element) => {
                    if (variousClientPia.includes(element.pia_name)) {
                        return false;
                    }
                    return true;
                });
                return filterResult;
            }
            return data;
        },
        [props]
    );

    const deleteMod = async (event) => {
        let acrnToDelete = event.node.data;

        const confirmed = await confirm({
            text: 'Are you sure you want to delete the MOD? All data will be lost.',
        });

        if (confirmed) {
            try {
                await ModsApi.removeMod(acrnToDelete.mod_id);
                props.removeMod(acrnToDelete.mod_id);
            } catch (error) {
                console.error(error);
                errorToast(error);
            }
        }
    };

    const onTableReady = (params) => {
        if (params.exportDataAsCsv) {
            setExportDataAsCsv(params.exportDataAsCsv);
        }
    };

    const handleEdit = async (event) => {
        try {
            const {
                node: { data: acrn },
            } = event;
            const { current: currentMods } = propMods;
            const modToUpdate = currentMods.find((mod) => mod.id === acrn.mod_id);
            props.updateMod(modToUpdate);
        } catch (error) {
            errorToast(error);
        }
    };

    useEffect(() => {
        propMods.current = props.mods;
        propProjects.current = props.projects;
        const acrns = reduceModsToAcrns(props.mods);
        const tableData = mapAcrnsToTableData(acrns);
        if (!props.isAFRL) {
            setTableData(tableData);
        } else {
            const filterData = filterOutAFRLPIA(tableData);
            setTableData(filterData);
        }
        setForceSetData(true);
    }, [props.mods, props.projects, props.isAFRL, reduceModsToAcrns, mapAcrnsToTableData]);

    useEffect(() => {
        setForceSetData(false);
    }, [forceSetData]);

    const tableColumns = [
        {
            headerName: 'Mod No.',
            field: 'number',
            editable: true,
            hide: props.isAFRL,
            type: 'mediumnumber',
        },
        {
            headerName: 'PIA',
            field: 'pia_name',
            editable: false,
            type: 'shortnumber',
        },
        {
            headerName: 'CPO',
            field: 'cpo_number',
            editable: false,
            type: 'mediumnumber',
        },
        {
            headerName: 'Mod No.',
            field: 'number',
            editable: true,
            hide: !props.isAFRL,
            type: 'idNum',
        },
        {
            headerName: 'ACRN',
            field: 'name',
            editable: true,
            type: 'shortnumber',
        },
        {
            headerName: 'Mod Rcvd Date',
            field: 'received_date',
            type: 'date',
            sort: 'desc',
        },
        {
            headerName: 'Funds Rcvd Date',
            field: 'funds_received',
            type: 'date',
            editable: true,
            hide: props.isAFRL,
        },
        {
            headerName: 'Amount Rcvd',
            field: 'amount',
            type: 'money',
            editable: false,
        },
        {
            headerName: 'Purpose',
            field: 'purpose',
            type: 'shorttext',
            editable: true,
        },
        {
            headerName: 'PR No.',
            field: 'pr_number',
            type: 'shorttext',
            editable: true,
            hide: props.isAFRL,
        },
        {
            headerName: 'WBI Project Name',
            field: 'project_name',
            type: 'idNum',
            hide: !props.isAFRL,
        },
        {
            headerName: 'WBI Project Name',
            field: 'project_name',
            type: 'mediumtext',
            cellRenderer: 'LinkRenderer',
            cellRendererParams: {
                routeBase: '/projects/',
                routeKeyField: 'project_id',
            },
            hide: props.isAFRL,
        },
        {
            headerName: 'Project No.',
            field: 'project_number',
            type: 'idNum',
            cellRenderer: 'LinkRenderer',
            cellRendererParams: {
                routeBase: '/projects/',
                routeKeyField: 'project_id',
            },
            hide: props.isAFRL,
        },
        {
            headerName: 'Project No.',
            field: 'project_number',
            hide: !props.isAFRL,
            type: 'idNum',
        },
        {
            headerName: 'WBI Lead',
            field: 'wbi_lead_user_name',
            hide: props.isAFRL,
            type: 'shortname',
        },

        {
            headerName: 'Link to DB',
            field: 'link_to_db',
            editable: false,
            type: 'symbol',
            cellRenderer: 'ExternalLinkSymbolRenderer',
            hide: props.isAFRL,
        },
        {
            headerName: 'Initiated By',
            field: 'initiator',
            type: 'shortname',
        },
        {
            headerName: 'Initiated On',
            field: 'created_on',
            type: 'date',
        },
        {
            headerName: 'Last Update By',
            field: 'last_update_by',
            type: 'shortname',
        },
        {
            headerName: 'Updated On',
            field: 'updated_on',
            type: 'date',
        },
        {
            headerName: 'Edit',
            field: 'edit',
            type: 'symbol',
            editable: false,
            cellRenderer: 'EditSymbolRenderer',
            onCellClicked: handleEdit,
            hide: props.isAFRL,
        },
        {
            headerName: 'Del.',
            field: 'delete',
            type: 'symbol',
            editable: false,
            cellRenderer: 'TrashSymbolRenderer',
            onCellClicked: deleteMod,
            hide: props.isAFRL,
        },
    ];

    return (
        <div>
            <div className="Table-styling">
                <PagingTable
                    columns={tableColumns}
                    data={tableData}
                    onTableReady={onTableReady}
                    addedNewData={forceSetData}
                    filter={true}
                    sortable={true}
                />
            </div>
            <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
                <ExportCSVButton fileName="MODs" exportFunction={exportDataAsCsv} />
            </div>
        </div>
    );
};

export default ModsTable;
