import React, { Component } from 'react';
import { Formik, Form } from 'formik';

import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';

import CCHoldersApi from '../api/ccHolders';
import UsersApi from '../api/users';

import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import { confirm } from '../components/modals/ConfirmModal';
import yup from '../yup';
import errorToast from '../components/messages/ErrorMessage';

class CCHolderPage extends Component {
    constructor(props) {
        super(props);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            ccHolders: [],
            nonCCHolders: null,
            errorMessage: null,
            forceSetData: false,
            rowIndex: null,
            columns: [
                {
                    headerName: 'Credit Card Holder',
                    field: 'name',
                    type: 'name',
                    editable: false,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: ccHolders } = await CCHoldersApi.getCCholders();
            const { data: nonCCHolders } = await CCHoldersApi.getNonCCholders();
            this.setState({
                ccHolders,
                nonCCHolders,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRemoveSelected = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to remove credit card holder?',
        });

        let userToUpdate = event.node.data;

        if (userToUpdate && confirmed) {
            UsersApi.updateUser(userToUpdate.id, {
                cc_holder: false,
            })
                .then(() => {
                    let newCCHolders = this.state.ccHolders.filter((item, i) => userToUpdate.id !== item.id);
                    let newNonCCHolders = this.state.nonCCHolders;
                    newNonCCHolders.push(userToUpdate);
                    this.setState({
                        ccHolders: newCCHolders,
                        nonCCHolders: newNonCCHolders,
                    });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    handleSubmit = (values) => {
        if (values.ccHolder) {
            UsersApi.updateUser(values.ccHolder, {
                cc_holder: true,
            })
                .then((response) => {
                    let userToUpdate = response.data;
                    let newCCHolders = this.state.ccHolders;

                    let newNonCCHolders = this.state.nonCCHolders.filter(
                        (ccHolder, _) => userToUpdate.name !== ccHolder.name
                    );

                    newCCHolders.push(userToUpdate);

                    this.setState(
                        {
                            ccHolders: newCCHolders,
                            nonCCHolders: newNonCCHolders,
                            forceSetData: true,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    render() {
        let options = [];

        let i;
        if (this.state.nonCCHolders) {
            for (i = 0; i < this.state.nonCCHolders.length; i++) {
                options.push({
                    value: this.state.nonCCHolders[i].id,
                    label: this.state.nonCCHolders[i].name,
                });
            }

            options.sort((a, b) => {
                let aLabel = a.label.toUpperCase();
                let bLable = b.label.toUpperCase();
                if (aLabel > bLable) {
                    return 1;
                }
                return -1;
            });
        }

        return (
            <div>
                <PageHeader title="Credit Card Holders" />
                <AdminSubNav />
                <div className="Companion-form Single-layout">
                    <Formik
                        initialValues={{ ccHolder: '' }}
                        validationSchema={yup.object().shape({
                            ccHolder: yup.string().required(),
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <LabeledField
                                    label="Credit Card Holder*"
                                    name="ccHolder"
                                    component="select"
                                    options={options}
                                    errors={errors}
                                    touched={touched}
                                />
                                <div className="Form__buttons--Reversed Buttons_format">
                                    <button type="submit">Add Credit Card Holder</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="Table-styling">
                    <EditingTable
                        columns={this.state.columns}
                        data={this.state.ccHolders}
                        sizeToFit={true}
                        addedNewData={this.state.forceSetData}
                    />
                </div>
            </div>
        );
    }
}

export default CCHolderPage;
