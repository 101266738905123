import React from 'react';

const UserInput = (props) => (
    <div className="Form__field">
        <label htmlFor={props.name} title={props.title}>
            {props.label}
        </label>
        <p>{props.message}</p>
        <input
            className={props.className}
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            autoCapitalize="off"
            autoCorrect="off"
            required="required"
            value={props.value}
            onChange={props.onChange}
        />
    </div>
);

export default UserInput;
