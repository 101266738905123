import React, { Component } from 'react';
import _find from 'lodash/find';
import FinanceSubNav from './FinanceSubNav';
import GridSection from '../components/section/GridSection';
import PageHeader from '../components/heading/PageHeader';
import errorToast from '../components/messages/ErrorMessage';
import FinancePageModsTable from './financePageTables/FinancePageModsTable';
import OpenNotifications from '../components/notifications/OpenNotifications';

import CpoApi from '../api/cpo';
import ModsApi from '../api/mods';
import PiaApi from '../api/pia';

class FinancePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fundings: [],
            mods: [],
            row: null,
            rowIndex: null,
            colKey: null,
            forceSetData: false,
            gridApi: null,
        };
    }

    componentDidMount = async () => {
        let fundings = [];

        function refactor(item, cpos, isMod) {
            if (item && isMod) {
                if (item.acrns.length === 1) {
                    if (item.acrns[0].purpose === 'adding project') {
                        item.source = item.number;
                    } else {
                        item.source = 'Mod ' + item.number;
                    }
                } else {
                    item.source = 'Mod ' + item.number;
                }
                let cpo = _find(cpos, { id: item.cpo_id });
                if (cpo) {
                    item.cpo_name = cpo.number;
                    item.pia_id = cpo.pia_id;
                    item.ceiling_amount = cpo.ceiling_amount;
                }
            } else {
                item.source = item.company_name;
            }
            item.amount_to_date = item.total_amount;
            if (item.acrns) {
                item.projects_funded = item.acrns.length;
            }
            if (item.projects) {
                item.projects_funded = item.projects.length;
            }
            return item;
        }

        try {
            const { data: mods } = await ModsApi.getMods();
            const { data: cpos } = await CpoApi.getCpos();
            const { data: pias } = await PiaApi.getPias();

            mods.forEach((mod) => {
                if (mod.funds_received === null) {
                    mod.funds_received = '';
                }
                mod = refactor(mod, cpos, true);
                let pia = _find(pias, { id: mod.pia_id });
                if (pia) mod.pia_name = pia.name;
                if (mod.funds_received === '') mod.funds_received = null;
                fundings.push(mod);
            });

            this.setState({
                mods: fundings,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    render() {
        return (
            <div>
                <PageHeader title="Finance Dashboard" />
                <FinanceSubNav />
                <div className="Double-layout-70-30">
                    <div>
                        <div className="Table-styling">
                            <FinancePageModsTable mods={this.state.mods} />
                        </div>
                    </div>
                    <GridSection title="Notifications">
                        <OpenNotifications isFinance={true} />
                    </GridSection>
                </div>
            </div>
        );
    }
}

export default FinancePage;
