import React, { Component } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';

import Logo from '../images/Logo.png';
import { history } from '../store/store';
import AuthService from '../auth/auth.service';

class Header extends Component {
    constructor(props, context) {
        super(props, context);

        this.onChange = this.onChange.bind(this);
        this.checkForPermissions = this.checkForPermissions.bind(this);
        this.logIn = this.logIn.bind(this);
        this.logOut = this.logOut.bind(this);
        this.getLogInOutButton = this.getLogInOutButton.bind(this);
        this.getAdminNav = this.getAdminNav.bind(this);

        this.state = {
            isUserAdmin: false,
            isUserFinancial: false,
            isUserExecutiveDirector: false,
            isUserServiceDirector: false,
            isUserAFRL: false,
            project: '',
            needsRedirected: false,
        };

        AuthService.setSignInCallback(this.checkForPermissions);
    }

    componentDidMount() {
        this.checkForPermissions();
    }

    onChange(e) {
        if (e.target.value) {
            this.setState({ project: e.target.value });
        } else {
            this.setState({ project: '' });
        }
    }

    getLogInOutButton() {
        return AuthService.isAuthenticated() ? (
            <button type="submit" onClick={this.logOut}>
                LOGOUT
            </button>
        ) : (
            <button type="submit" onClick={this.logIn}>
                LOGIN
            </button>
        );
    }

    getAdminNav() {
        return AuthService.isAuthenticated() &&
            (this.state.isUserAdmin || this.state.isUserFinancial || this.state.isUserExecutiveDirector) ? (
            <NavLink className="Button--primary-nav" activeClassName="Selected" to="/admin/vendorsAndAgreements">
                Admin
            </NavLink>
        ) : null;
    }

    getFinancialNav() {
        return AuthService.isAuthenticated() &&
            (this.state.isUserFinancial || this.state.isUserAdmin || this.state.isUserExecutiveDirector) ? (
            <NavLink className="Button--primary-nav" activeClassName="Selected" to="/finance">
                Finance
            </NavLink>
        ) : null;
    }

    logIn() {
        history.push('/account/signin');
    }

    logOut() {
        AuthService.signOut();
        this.checkForPermissions();
        history.push('/account/signin');
    }

    checkForPermissions() {
        const user = AuthService.getUser();

        let isUserAdmin = false;
        let isUserFinancial = false;
        let isUserExecutiveDirector = false;
        let isUserServiceDirector = false;
        let isUserAFRL = false;
        if (user != null) {
            isUserAdmin = user.site_role === 'Admin';
            isUserFinancial = user.site_role === 'Financial';
            isUserExecutiveDirector = user.site_role === 'Executive Director';
            isUserServiceDirector = user.site_role === 'Service Director';
            isUserAFRL = user.site_role === 'PIA Program Manager';
        }

        this.setState({
            isUserAdmin,
            isUserFinancial,
            isUserExecutiveDirector,
            isUserServiceDirector,
            isUserAFRL,
        });
    }

    render() {
        return (
            <div>
                <div className="Header">
                    <div className="Header__Top-section">
                        <img className="Top-section" alt="logo" src={Logo} />
                        {AuthService.isAuthenticated() && !this.state.isUserAFRL ? (
                            <div className="Top-section__Search Form__iconfield">
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search Project"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                    required
                                    value={this.state.project}
                                    onChange={this.onChange}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            if (history.location.pathname === '/search') {
                                                history.push({ state: { term: e.target.value } });
                                            } else {
                                                history.push({
                                                    pathname: '/search',
                                                    state: { term: e.target.value },
                                                });
                                            }
                                        }
                                    }}
                                />
                                {this.props.location.pathname === '/search' ? (
                                    <button
                                        htmlFor="search"
                                        title="Search Project"
                                        className="Header__Search-btn"
                                        disabled={this.state.project === ''}
                                        onClick={() => {
                                            history.push({ state: { term: this.state.project } });
                                        }}
                                    >
                                        <i className="fa fa-search" />
                                    </button>
                                ) : (
                                    <button
                                        htmlFor="search"
                                        title="Search Project"
                                        className="Header__Search-btn"
                                        disabled={this.state.project === ''}
                                    >
                                        {this.state.project === '' ? (
                                            <i className="fa fa-search" />
                                        ) : (
                                            <Link
                                                to={{
                                                    pathname: '/search',
                                                    state: { term: this.state.project },
                                                }}
                                            >
                                                <i className="fa fa-search" />{' '}
                                            </Link>
                                        )}
                                    </button>
                                )}
                            </div>
                        ) : null}
                        <div className="Top-section__Button Form__buttons">{this.getLogInOutButton()}</div>
                    </div>
                    {AuthService.isAuthenticated() && !this.state.isUserAFRL ? (
                        <div className="header__primary-nav">
                            <NavLink exact className="Button--primary-nav" activeClassName="Selected" to="/">
                                Home
                            </NavLink>
                            {this.getFinancialNav()}
                            <NavLink className="Button--primary-nav" activeClassName="Selected" to="/projects">
                                Projects Dashboard
                            </NavLink>
                            <NavLink className="Button--primary-nav" activeClassName="Selected" to="/coreFunding">
                                Core Funding
                            </NavLink>
                            <NavLink className="Button--primary-nav" activeClassName="Selected" to="/goalsAndMetrics">
                                WBI Strategic Goals & Metrics
                            </NavLink>
                            <NavLink className="Button--primary-nav" activeClassName="Selected" to="/reports">
                                Reports
                            </NavLink>
                            {this.getAdminNav()}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default withRouter(Header);
