import React, { Component } from 'react';
import ResidualFundsApi from '../api/residualFunds';
import TransactionApi from '../api/transaction';
import PagingTable from '../components/tables/PagingTable';
import { nodeFundsValueComparator } from '../components/tables/utils/tableUtils';
import FinanceSubNav from './FinanceSubNav';
import SectionHeading from '../components/heading/SectionHeading';
import PageHeader from '../components/heading/PageHeader';
import SourceFundsTable from './residualFunds/SourceFundsTable';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import { history } from '../store/store';
import errorToast from '../components/messages/ErrorMessage';

class ResidualFundsPage extends Component {
    constructor(props) {
        super(props);
        this.getSources = this.getSources.bind(this);
        this.transferFunds = this.transferFunds.bind(this);
        this.onTableReady = this.onTableReady.bind(this);
        this.state = {
            residualFunds: [],
            sources: null,
            haveApprovals: false,
            columns: [
                {
                    headerName: 'Office Symbol',
                    field: 'name',
                    type: 'idNum',
                    sort: 'desc',
                },
                {
                    headerName: 'Residual Funds',
                    field: 'funds_total',
                    type: 'money',
                },
                {
                    headerName: 'Funds Transfer',
                    field: 'fundsTransfer',
                    sortable: true,
                    comparator: nodeFundsValueComparator,
                    onCellClicked: this.transferFunds,
                    cellRenderer(params) {
                        if (params.data.funds_total > 0) {
                            return '<a>Funds Transfer</a>';
                        }
                        return null;
                    },
                },
                {
                    headerName: 'Source of Funds',
                    field: 'sourceFunds',
                    sortable: true,
                    comparator: nodeFundsValueComparator,
                    onCellClicked: this.getSources,
                    cellRenderer(params) {
                        if (params.data.funds_total === 0) {
                            return null;
                        }
                        return '<a>Source of Funds</a>';
                    },
                },
            ],
        };
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    componentDidMount = async () => {
        try {
            const { data: residualFunds } = await ResidualFundsApi.getResidualFundWithFundsTotal();

            this.setState({
                residualFunds,
            });

            const { data: approvals } = await TransactionApi.getTransactionsByApprover();
            if (approvals) {
                this.setState({ haveApprovals: true });
            }
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    getSources(event) {
        let officeSymbolId = event.data.id;
        ResidualFundsApi.getTransactions(officeSymbolId)
            .then((response) => {
                this.setState({
                    sources: response.data,
                });
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    transferFunds(event) {
        if (event.data.funds_total > 0) {
            let destination = '/finance/residualFunds/fundsTransfers';
            history.push(destination);
        }
    }

    render() {
        let srcs;
        if (this.state.sources) {
            srcs = this.state.sources;
        }

        let sourceTable = (
            <div>
                <SectionHeading>Source of Funds</SectionHeading>
                <SourceFundsTable data={srcs} />
            </div>
        );

        let mainSection = (
            <div className="Tables">
                <div>
                    <SectionHeading>Residual Funds</SectionHeading>
                    <div className="Table-styling">
                        <PagingTable
                            columns={this.state.columns}
                            data={this.state.residualFunds}
                            sortable={true}
                            filter={true}
                            onTableReady={this.onTableReady}
                        />
                    </div>
                    <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
                        <ExportCSVButton fileName="Residual-Funds" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div className="Vertical_line" />
                {srcs ? sourceTable : null}
            </div>
        );
        const { match } = this.props;

        return (
            <div>
                <PageHeader title="Residual Funds & Transfers" />
                <FinanceSubNav />

                <SubSubNav>
                    <SubSubNavLink to={match.url} exact>
                        Residual Funds
                    </SubSubNavLink>
                    <SubSubNavLink to="/finance/residualFunds/fundsTransfers" exact>
                        Funds Transfer
                    </SubSubNavLink>
                </SubSubNav>
                {mainSection}
            </div>
        );
    }
}

export default ResidualFundsPage;
