import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';
import TravelApprovalPage from './TravelApprovalPage';
import { TravelRequestForm } from './TravelRequestForm';
import { TravelExpensePage } from './TravelExpensePage';

const TravelPage = (props) => {
    const { match, isProject, isArchived } = props;
    return (
        <>
            <SubSubNav>
                <SubSubNavLink to={match.url} exact>
                    Request Travel
                </SubSubNavLink>
                <SubSubNavLink to={match.url + '/approvals'}>Travel Approvals</SubSubNavLink>
                <SubSubNavLink to={match.url + '/expense'} exact>
                    Travel Expense Form
                </SubSubNavLink>
            </SubSubNav>
            <Switch>
                <Route
                    path={match.path}
                    exact
                    render={(props) => <TravelRequestForm {...props} isProject={isProject} isArchived={isArchived} />}
                />
                <Route
                    path={match.path + '/approvals'}
                    exact
                    render={(props) => <TravelApprovalPage {...props} approval />}
                />
                <Route
                    path={match.path + '/expense'}
                    exact
                    render={(props) => <TravelExpensePage {...props} isProject={isProject} isArchived={isArchived} />}
                />
            </Switch>
        </>
    );
};

export default TravelPage;
