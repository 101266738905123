// React
import React from 'react';

// Libraries
import _find from 'lodash/find';

// Components
import { confirm } from '../components/modals/ConfirmModal';
import EditingTable from '../components/tables/EditingTable';
import PagingTable from '../components/tables/PagingTable';
import ExpendituresForm from './ExpendituresForm';
import errorToast from '../components/messages/ErrorMessage';
import { isEquivalent } from '../utils/utils';

// API
import ProjectsApi from '../api/projects';
import ExpendituresApi from '../api/expenditures';
import UsersApi from '../api/users';

class ExpendituresPage extends React.Component {
    constructor(props) {
        super(props);

        this.getRowData = this.getRowData.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.createExpenditure = this.createExpenditure.bind(this);
        this.submitExpenditure = this.submitExpenditure.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);

        this.state = {
            project: null,
            isArchived: false,
            expenditures: [],
            users: null,
            expenditureTypes: null,
            ccHolderOptions: null,
            forceSetData: false,
            rowToUpdate: null,
            rowIndex: 0,
            columns: [
                {
                    headerName: 'Type',
                    field: 'type',
                    type: 'mediumnumber',
                },
                {
                    headerName: 'Credit Card Type',
                    field: 'cc_type',
                    type: 'mediumnumber',
                },
                { headerName: 'Transaction Date', field: 'purchase_date', type: 'date' },
                { headerName: 'Item Description', field: 'item_description', type: 'mediumtext' },
                { headerName: 'Cost', field: 'cost', type: 'shortmoney' },
                { headerName: 'Store', field: 'store', type: 'name' },
                { headerName: 'Card Holder', field: 'cardHolder', type: 'name' },
                { headerName: 'Status', field: 'status', type: 'mediumnumber' },
                {
                    headerName: 'Link to DB',
                    field: 'link_to_db',
                    type: 'symbol',
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator',
                    type: 'name',
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_updated_by',
                    type: 'name',
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    editable: false,
                    type: 'symbol',
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
            archivedColumns: [
                {
                    headerName: 'Type',
                    field: 'type',
                    type: 'mediumnumber',
                },
                {
                    headerName: 'Credit Card Type',
                    field: 'cc_type',
                    type: 'mediumnumber',
                },
                { headerName: 'Transaction Date', field: 'purchase_date', type: 'date' },
                { headerName: 'Item Description', field: 'item_description', type: 'mediumtext' },
                { headerName: 'Cost', field: 'cost', type: 'shortmoney' },
                { headerName: 'Store', field: 'store', type: 'name' },
                { headerName: 'Card Holder', field: 'cardHolder', type: 'name' },
                { headerName: 'Status', field: 'status', type: 'mediumnumber' },
                {
                    headerName: 'Link to DB',
                    field: 'link_to_db',
                    type: 'symbol',
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator',
                    type: 'name',
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_updated_by',
                    type: 'name',
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                },
            ],
        };
    }

    async componentDidMount() {
        try {
            let projectId = this.props.match.params.id;

            const { data: project } = await ProjectsApi.fetchProject(projectId);
            let { data: projectExpends } = await ProjectsApi.fetchExpendituresInProject(projectId);
            const { data: users } = await UsersApi.getUsers();
            const { data: expenditureTypes } = await ExpendituresApi.fetchExpenditureTypes();

            if (projectExpends) {
                projectExpends = projectExpends.filter((e) => e.type !== 'Travel Expenditure');
            }

            let approvalUsers = users.filter((user) => user.approver === true);
            let approverUserOptions = [];
            approvalUsers.forEach((user) => {
                approverUserOptions.push({ label: user.name, value: user.id });
            });

            let ccHolders = users.filter((e) => e.cc_holder === true);
            let ccHolderOptions = [];
            ccHolders.forEach((user) => {
                ccHolderOptions.push({ label: user.name, value: user.id });
            });

            this.setState({
                project,
                expenditures: projectExpends,
                approverUserOptions,
                expenditureTypes,
                ccHolderOptions,
                users,
                isArchived: project.status === 'archived',
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    getRowData = async (event) => {
        if (this.state.isArchived) {
            return;
        }
        let rowToUpdate = event.node.data;
        let rowIndex = event.node.rowIndex;
        if (rowToUpdate.status === 'Applied') {
            await confirm({
                text: 'This expenditure has already been applied and can no longer be edited by a project user, please contact finance.',
                acceptText: 'Ok',
                rejectText: null,
            });
        } else {
            // Handle the item that is already being edited.
            this.cancelEdit();
            let { expenditures } = this.state;
            if (expenditures) {
                expenditures = expenditures.filter((expenditure) => rowToUpdate.id !== expenditure.id);
            }
            this.setState({
                rowToUpdate,
                rowIndex,
                expenditures,
            });
        }
    };

    cancelEdit = () => {
        let { rowToUpdate, expenditures, rowIndex } = this.state;

        if (rowToUpdate) {
            expenditures.splice(rowIndex, 0, rowToUpdate);
            this.setState(
                {
                    expenditures,
                    rowToUpdate: null,
                    rowIndex: 0,
                    forceSetData: true,
                },
                () => {
                    this.setState({ forceSetData: false });
                }
            );
        }
    };

    onRemoveSelected = async (event) => {
        if (this.state.isArchived) {
            return;
        }
        let expToUpdate = event.data;
        if (expToUpdate.status === 'Applied') {
            await confirm({
                text: 'This expenditure has already been applied and can no longer be deleted by a project user, please contact finance.',
                acceptText: 'Ok',
                rejectText: null,
            });
        } else {
            const confirmed = await confirm({
                text: 'Are you sure you want to remove this expenditure?  All data will be lost.',
            });

            if (confirmed) {
                ExpendituresApi.removeExpenditures(expToUpdate.id)
                    .then(() => {
                        let newExpenditures = this.state.expenditures.filter(
                            (expenditure) => expToUpdate.id !== expenditure.id
                        );
                        this.setState({ expenditures: newExpenditures });
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            }
        }
    };

    handleSubmit = async (values, actions) => {
        const { rowToUpdate } = this.state;
        if (this.state.isArchived) {
            return;
        }

        let attributes = {
            cc_type: values.cc_type,
            ccHolder_id: values.ccHolder_id,
            cost: values.cost,
            greatest_unit_cost: values.greatest_unit_cost,
            item_description: values.item_description,
            link_to_db: values.link_to_db,
            purchase_date: values.purchase_date,
            store: values.store,
            type: values.type,
            account_type: 'Project',
            account_id: this.props.match.params.id,
        };

        attributes.type = values.type.value ? values.type.value : values.type;
        if (values.approver_id) {
            attributes.approver_id = values.approver_id.value ? values.approver_id.value : values.approver_id;
        }

        if (!values.approver_id && !rowToUpdate) {
            // Create new expenditure with no approver
            this.createExpenditure('createExpenditureNoApprover', attributes);
        } else if (values.approver_id && !rowToUpdate) {
            // Create new expenditure with approver
            this.createExpenditure('createExpenditure', attributes);
        } else if (rowToUpdate && (rowToUpdate.status === 'Submitted' || rowToUpdate.status === 'Pending')) {
            let changeOfApprover = false;
            if (rowToUpdate.approver) {
                changeOfApprover = rowToUpdate.approver.id !== attributes.approver_id;
            } else if (!rowToUpdate.approver && values.approver_id) {
                changeOfApprover = true;
            }
            if (changeOfApprover) {
                this.submitExpenditure('updateAndSubmitApprovalExpenditure', rowToUpdate.id, attributes);
            } else {
                ExpendituresApi.updateExpenditure(rowToUpdate.id, attributes)
                    .then((response) => {
                        let { expenditures, rowIndex } = this.state;
                        expenditures.splice(rowIndex, 0, response.data);

                        this.setState(
                            {
                                expenditures,
                                forceSetData: true,
                                rowToUpdate: null,
                                rowIndex: 0,
                            },
                            () => {
                                this.setState({ forceSetData: false });
                            }
                        );
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            }
        } else if (rowToUpdate && rowToUpdate.status === 'Approved') {
            // Submits expenditure for payment
            let comparisonAttributes = {
                cc_type: rowToUpdate.cc_type,
                ccHolder_id: rowToUpdate.ccHolder_id,
                cost: rowToUpdate.cost,
                greatest_unit_cost: rowToUpdate.greatest_unit_cost,
                item_description: rowToUpdate.item_description,
                link_to_db: rowToUpdate.link_to_db,
                purchase_date: rowToUpdate.purchase_date,
                store: rowToUpdate.store,
                type: rowToUpdate.type.toLowerCase(),
                approver_id: rowToUpdate.approver.id,
                account_type: 'Project',
                account_id: this.props.match.params.id,
            };

            let equal = isEquivalent(attributes, comparisonAttributes);
            if (!equal) {
                delete comparisonAttributes.link_to_db;
                delete attributes.link_to_db;
            }
            equal = isEquivalent(attributes, comparisonAttributes);

            let changeOfApprover = rowToUpdate.approver.id !== attributes.approver_id;
            if (!equal || changeOfApprover) {
                const confirmed = await confirm({
                    text: 'You have edited a field other than Link to DB.  This will resubmit for approval.  Proceed?',
                });

                if (confirmed) {
                    this.submitExpenditure('updateAndSubmitApprovalExpenditure', rowToUpdate.id, attributes);
                } else {
                    this.cancelEdit();
                }
            } else {
                attributes.link_to_db = values.link_to_db;
                this.submitExpenditure('submitExpenditure', rowToUpdate.id, attributes);
            }
        } else if (rowToUpdate && rowToUpdate.status === 'Rejected') {
            // Expenditure needs to be submitted for approval after being rejected
            this.submitExpenditure('updateAndSubmitApprovalExpenditure', rowToUpdate.id, attributes);
        } else if (rowToUpdate && rowToUpdate.status === 'Returned') {
            // Updates and submit existing expenditure
            ExpendituresApi.updateAndSubmitExpenditure(rowToUpdate.id, attributes)
                .then((response) => {
                    let { expenditures, rowIndex } = this.state;

                    expenditures.splice(rowIndex, 0, response.data);

                    this.setState(
                        {
                            expenditures,
                            forceSetData: true,
                            rowToUpdate: null,
                            rowIndex: 0,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
        actions.resetForm();
    };

    submitExpenditure = (method, id, attributes) => {
        if (this.state.isArchived) {
            return;
        }
        ExpendituresApi[method](id, attributes)
            .then((response) => {
                response.data.purchase_date = attributes.purchase_date;

                let { expenditures, rowIndex } = this.state;
                expenditures.splice(rowIndex, 0, response.data);

                this.setState(
                    {
                        expenditures,
                        rowToUpdate: null,
                        rowIndex: 0,
                        forceSetData: true,
                    },
                    () => {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    };

    createExpenditure = (method, attributes) => {
        ExpendituresApi[method](attributes)
            .then((response) => {
                response.data.purchase_date = attributes.purchase_date;

                let { expenditures } = this.state;
                expenditures.unshift(response.data);

                this.setState(
                    {
                        expenditures,
                        forceSetData: true,
                    },
                    () => {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    };

    render() {
        if (!this.state.expenditures) return <div>loading...</div>;

        const {
            columns,
            archivedColumns,
            rowToUpdate,
            expenditures,
            forceSetData,
            ccHolderOptions,
            approverUserOptions,
            isArchived,
        } = this.state;

        let expendituresTypeOptions = [];
        if (this.state.expenditureTypes) {
            for (let pd in this.state.expenditureTypes) {
                expendituresTypeOptions.push({
                    label: this.state.expenditureTypes[pd],
                    value: pd,
                });
            }
        }

        let initialValues = null;
        if (rowToUpdate) {
            let invType;
            if (rowToUpdate.type) {
                invType = _find(expendituresTypeOptions, {
                    label: rowToUpdate.type,
                });
            }

            let invApprover;
            if (rowToUpdate.approver) {
                invApprover = _find(approverUserOptions, {
                    value: rowToUpdate.approver.id,
                });
            }

            initialValues = {
                type: invType,
                cc_type: rowToUpdate.cc_type,
                ccHolder_id: rowToUpdate.ccHolder_id,
                purchase_date: rowToUpdate.purchase_date,
                item_description: rowToUpdate.item_description,
                cost: rowToUpdate.cost,
                greatest_unit_cost: rowToUpdate.greatest_unit_cost,
                store: rowToUpdate.store,
                link_to_db: rowToUpdate.link_to_db,
                approver_id: invApprover,
                status: rowToUpdate.status,
            };
        } else {
            initialValues = {
                type: '',
                cc_type: '',
                ccHolder_id: '',
                purchase_date: '',
                item_description: '',
                cost: '',
                greatest_unit_cost: '',
                store: '',
                link_to_db: '',
                approver_id: '',
            };
        }

        return (
            <>
                <ExpendituresForm
                    initialValues={initialValues}
                    expendituresTypeOptions={expendituresTypeOptions}
                    ccHolderOptions={ccHolderOptions}
                    approverUserOptions={approverUserOptions}
                    handleSubmit={this.handleSubmit}
                    cancelEdit={this.cancelEdit}
                    isArchived={isArchived}
                />
                <div className="Table-styling">
                    {isArchived ? (
                        <PagingTable data={expenditures} columns={archivedColumns} />
                    ) : (
                        <EditingTable
                            data={expenditures}
                            columns={columns}
                            pagingProp={true}
                            addedNewData={forceSetData}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default ExpendituresPage;
