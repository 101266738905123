import React, { useState, useEffect } from 'react';
import LabeledField from '../../components/forms/LabeledField';

export default function EndDateChange({ errors, touched, values, oldAgreement }) {
    const [showFields, setShowFields] = useState(false);
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        values.show_date === 'yes' ? setShowFields(true) : setShowFields(false);
    }, [values.show_date]);

    useEffect(() => {
        if (showFields === false) {
            // eslint-disable-next-line no-param-reassign
            values.end_date = oldAgreement.end_date;
        }
    }, [values, oldAgreement, showFields]);

    return (
        <>
            <LabeledField
                label="Did the agreement end date change?"
                name="show_date"
                component="Radio"
                currentValue={values.show_date || 'no'}
                options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                ]}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mb0 mt0"
            />
            {showFields && (
                <>
                    <div className="mr2 flex flex-row justify-content-between">
                        <div>Old end date</div> {oldAgreement.end_date && <div>{oldAgreement.end_date}</div>}
                    </div>

                    <div className="mr2 flex flex-row justify-content-between">
                        <LabeledField
                            label="New end date"
                            name="end_date"
                            component="Date"
                            errors={errors}
                            touched={touched}
                            labelClassName="text-align-left mr2"
                        />
                    </div>
                </>
            )}
        </>
    );
}
