import api, { getHeaders } from './api';

import endpoints from './endpoints';

const projectsRoute = endpoints.PROJECTS;
const projectNamesRoute = endpoints.PROJECT_NAMES;

export default {
    createProject(projectAttributes) {
        return api.post(projectsRoute, projectAttributes, 'Error creating a project');
    },

    fetchProjects() {
        return api.get(projectsRoute, 'Error getting projects');
    },

    fetchAllProjectsOverview() {
        return api.get(`${projectsRoute}/overview_all`, 'Error getting all projects overview');
    },

    fetchAllActiveProjects() {
        let route = `${projectsRoute}/get_active`;
        return api.get(route, 'Error getting active projects');
    },

    fetchAllFinancialActiveProjects() {
        let route = `${projectsRoute}/service_area_financial_info`;
        return api.get(route, 'Error getting financially active projects');
    },

    fetchProjectsCurrentUser(serviceTypeID) {
        let route;
        if (serviceTypeID) {
            route = `${projectsRoute}/current_user?service_type_id=${serviceTypeID}`;
        } else {
            route = `${projectsRoute}/current_user`;
        }

        return api.get(route, 'Error getting projects involving current user');
    },

    fetchProjectNames() {
        return api.get(projectNamesRoute, 'Error getting project names');
    },

    fetchSubProjectNames() {
        return api.get(`${projectNamesRoute}?subproject=True`, 'Error getting subproject names');
    },

    fetchParentProjectNames() {
        return api.get(`${projectNamesRoute}?subproject=False`, 'Error getting parent project names');
    },

    fetchParentProjects() {
        return api.get(`${projectsRoute}/parent_projects`, 'Error getting all parent projects');
    },

    fetchSubprojects(id) {
        return api.get(`${projectsRoute}/${id}/child_projects`, 'Error getting supbrojects in within a project');
    },

    fetchTransferRequestsInProject(id) {
        return api.get(
            `${projectsRoute}/${id}/transfers_requests`,
            'Error getting transfer requests in within a project'
        );
    },

    fetchExpendituresInProject(id) {
        return api.get(`${projectsRoute}/${id}/expenditures`, 'Error getting expenditures in within a project');
    },

    fetchProject(id) {
        return api.get(`${projectsRoute}/${id}`, 'Error getting project');
    },

    updateProject(id, projectAttributes) {
        return api.put(`${projectsRoute}/${id}`, projectAttributes, 'Error updating project');
    },

    fetchAllFinancialInformation() {
        return api.get(
            `${projectsRoute}/financial_information`,
            'Error getting financial information for all projects'
        );
    },

    removeProject(id, projectAttributes) {
        return api.delete(`${projectsRoute}/${id}`, projectAttributes, 'Error deleting project', true);
    },

    fetchProjectFinancialInformation(id) {
        return api.get(`${projectsRoute}/${id}/financial_information`, 'Error getting project financial information');
    },

    fetchProjectTransactions(id) {
        return api.get(`${projectsRoute}/${id}/transactions`, 'Error getting project transactions');
    },

    fetchProjectTransactionsTransfers(id) {
        return api.get(`${projectsRoute}/${id}/transactions_transfers`, 'Error getting project transfer transactions');
    },

    fetchStatus() {
        return api.get(`${projectsRoute}/status`, 'Error getting project status');
    },

    fetchPdStatus() {
        return api.get(`${projectsRoute}/pdstatus`, 'Error getting project PD status');
    },

    fetchGoals(projectId) {
        return api.get(`${projectsRoute}/${projectId}/key_goals`, 'Error getting project key goals');
    },

    createGoal(projectId, goalAttributes) {
        return api.post(
            `${projectsRoute}/${projectId}/key_goals`,
            goalAttributes,
            'Error creating a goal in within a project'
        );
    },

    updateGoal(projectId, goalId, goalAttributes) {
        return api.put(
            `${projectsRoute}/${projectId}/key_goals/${goalId}`,
            goalAttributes,
            'Error updating a goal in within a project'
        );
    },

    removeGoal(projectId, goalId) {
        return api.delete(
            `${projectsRoute}/${projectId}/key_goals/${goalId}`,
            'Error removing a goal in within a project'
        );
    },

    createTask(projectId, taskAttributes) {
        return api.post(
            `${projectsRoute}/${projectId}/tasks`,
            taskAttributes,
            'Error creating a task within a project'
        );
    },

    updateTask(projectId, taskId, taskAttributes) {
        return api.put(
            `${projectsRoute}/${projectId}/tasks/${taskId}`,
            taskAttributes,
            'Error updating a task in within a project'
        );
    },

    removeTask(projectId, taskId) {
        return api.delete(`${projectsRoute}/${projectId}/tasks/${taskId}`, 'Error removing a task within a project');
    },

    fetchDeliverables(id) {
        return api.get(`${projectsRoute}/${id}/deliverables`, 'Error getting project deliverables');
    },

    addDeliverable(id, deliverable) {
        return api.post(`${projectsRoute}/${id}/deliverables`, deliverable, 'Error adding deliverable to project');
    },

    updateDeliverable(projectId, deliverableId, deliverable) {
        return api.put(
            `${projectsRoute}/${projectId}/deliverables/${deliverableId}`,
            deliverable,
            'Error updating deliverable'
        );
    },

    removeDeliverable(projectId, deliverableId) {
        return api.delete(
            `${projectsRoute}/${projectId}/deliverables/${deliverableId}`,
            'Error removing deliverable from project'
        );
    },

    fetchMilestones(id) {
        return api.get(`${projectsRoute}/${id}/milestones`, 'Error getting project milestones');
    },

    addMilestone(id, milestone) {
        return api.post(`${projectsRoute}/${id}/milestones`, milestone, 'Error adding milestone to project');
    },

    updateMilestone(projectId, milestoneId, milestone) {
        return api.put(
            `${projectsRoute}/${projectId}/milestones/${milestoneId}`,
            milestone,
            'Error updating milestone'
        );
    },

    removeMilestone(projectId, milestoneId) {
        return api.delete(
            `${projectsRoute}/${projectId}/milestones/${milestoneId}`,
            'Error removing milestone from project'
        );
    },

    createStatus(projectId, statusAttributes) {
        return api.post(`${projectsRoute}/${projectId}/statuses`, statusAttributes, 'Error creating a project status');
    },

    listStatus(projectId) {
        return api.get(`${projectsRoute}/${projectId}/statuses`, 'Error getting a list of project statuses');
    },

    createActivityReport(projectId, reportAttributes) {
        return api.post(
            `${projectsRoute}/${projectId}/activity_reports`,
            reportAttributes,
            'Error creating a goal in within a project'
        );
    },

    fetchActivityReports(projectId) {
        return api.get(`${projectsRoute}/${projectId}/activity_reports`, 'Error creating a goal in within a project');
    },

    updateStatus(projectId, statusId, statusAttributes) {
        return api.put(
            `${projectsRoute}/${projectId}/statuses/${statusId}`,
            statusAttributes,
            'Error updating status update'
        );
    },

    removeStatus(projectId, statusId) {
        return api.delete(`${projectsRoute}/${projectId}/statuses/${statusId}`, 'Error removing a project status');
    },

    createWbiRole(projectId, wbiRoleAttributes) {
        return api.post(
            `${projectsRoute}/${projectId}/wbi_roles`,
            wbiRoleAttributes,
            'Error creating a project WBI role'
        );
    },

    updateWbiRole(projectId, wbiRoleId, wbiRoleAttributes) {
        return api.put(
            `${projectsRoute}/${projectId}/wbi_roles/${wbiRoleId}`,
            wbiRoleAttributes,
            'Error updating wbi role'
        );
    },

    removeWbiRole(projectId, wbiRoleId) {
        return api.delete(`${projectsRoute}/${projectId}/wbi_roles/${wbiRoleId}`, 'Error removing a project WBI role');
    },

    createCustomerRole(projectId, customerRoleAttributes) {
        return api.post(
            `${projectsRoute}/${projectId}/customer_roles`,
            customerRoleAttributes,
            'Error creating a project customer role'
        );
    },

    updateCustomerRole(projectId, customerRoleId, customerRoleAttributes) {
        return api.put(
            `${projectsRoute}/${projectId}/customer_roles/${customerRoleId}`,
            customerRoleAttributes,
            'Error updating customer role'
        );
    },

    removeCustomerRole(projectId, customerRoleId) {
        return api.delete(
            `${projectsRoute}/${projectId}/customer_roles/${customerRoleId}`,
            'Error removing a project customer role'
        );
    },

    createOtherRole(projectId, otherRoleAttributes) {
        return api.post(
            `${projectsRoute}/${projectId}/other_roles`,
            otherRoleAttributes,
            'Error creating a project other role'
        );
    },

    updateOtherRole(projectId, otherRoleId, otherRoleAttributes) {
        return api.put(
            `${projectsRoute}/${projectId}/other_roles/${otherRoleId}`,
            otherRoleAttributes,
            'Error updating other role'
        );
    },

    removeOtherRole(projectId, otherRoleId) {
        return api.delete(
            `${projectsRoute}/${projectId}/other_roles/${otherRoleId}`,
            'Error removing a project other role'
        );
    },

    createUser(projectId, userAttributes) {
        return api.post(`${projectsRoute}/${projectId}/users`, userAttributes, 'Error creating a project user');
    },

    removeUser(projectId, userId) {
        return api.delete(`${projectsRoute}/${projectId}/users/${userId}`, 'Error removing a project user');
    },
    removeSubcontractor(projectId, subcontractorId) {
        return api.delete(
            `${projectsRoute}/${projectId}/subcontractors/${subcontractorId}`,
            'Error removing a project subcontractor'
        );
    },

    getSignatures(projectId) {
        return api.get(`${projectsRoute}/${projectId}/signatures`, 'Error getting signatures in within a project');
    },

    createSignature(projectId, signatureAttr) {
        return api.post(
            `${projectsRoute}/${projectId}/signatures`,
            signatureAttr,
            'Error creating a project signature'
        );
    },

    updateSignature(projectId, signatureId, signatureAttributes) {
        return api.put(
            `${projectsRoute}/${projectId}/signatures/${signatureId}`,
            signatureAttributes,
            'Error updating signature'
        );
    },

    removeSignature(projectId, signatureId) {
        return api.delete(
            `${projectsRoute}/${projectId}/signatures/${signatureId}`,
            'Error removing a project signature'
        );
    },

    requestProjectSignatures(projectId) {
        return api.get(`${projectsRoute}/${projectId}/request_signatures`, 'Error requesting project signatures.');
    },

    createIssue(projectId, issueAttr) {
        return api.post(`${projectsRoute}/${projectId}/issues`, issueAttr, 'Error creating a project issue');
    },

    updateIssue(projectId, issueId, issueAttr) {
        return api.put(`${projectsRoute}/${projectId}/issues/${issueId}`, issueAttr, 'Error updating project issue');
    },

    fetchIssues(projectId) {
        return api.get(`${projectsRoute}/${projectId}/issues`, 'Error getting project issues');
    },

    removeIssue(projectId, issueId) {
        return api.delete(`${projectsRoute}/${projectId}/issues/${issueId}`, 'Error removing project issue');
    },

    createBudgetItem(projectId, budgetItemAttr) {
        return api.post(
            `${projectsRoute}/${projectId}/budget_items`,
            budgetItemAttr,
            'Error creating a project budget item'
        );
    },

    updateBudgetItem(projectId, budgetItemId, budgetItemAttr) {
        return api.put(
            `${projectsRoute}/${projectId}/budget_items/${budgetItemId}`,
            budgetItemAttr,
            'Error updating project budget item'
        );
    },

    fetchBudgetItems(projectId) {
        return api.get(`${projectsRoute}/${projectId}/budget_items`, 'Error getting project budget items');
    },

    removeBudgetItem(projectId, budgetItemId) {
        return api.delete(
            `${projectsRoute}/${projectId}/budget_items/${budgetItemId}`,
            'Error removing project budget item'
        );
    },

    getUserOpenActionItem() {
        return api.get(`${projectsRoute}/open_action_items`, 'Error getting all project open action items');
    },

    createOpenActionItem(projectId, openActionItemAttr) {
        return api.post(
            `${projectsRoute}/${projectId}/open_action_items`,
            openActionItemAttr,
            'Error creating a project open action item'
        );
    },

    updateOpenActionItem(projectId, openActionItemId, openActionItemAttr) {
        return api.put(
            `${projectsRoute}/${projectId}/open_action_items/${openActionItemId}`,
            openActionItemAttr,
            'Error updating project open action item'
        );
    },

    fetchOpenActionItems(projectId) {
        return api.get(`${projectsRoute}/${projectId}/open_action_items`, 'Error getting open action items');
    },

    removeOpenActionItem(projectId, openActionItemId) {
        return api.delete(
            `${projectsRoute}/${projectId}/open_action_items/${openActionItemId}`,
            'Error removing project open action item'
        );
    },

    createProjectVersion(projectId, versionAttr) {
        return api.post(`${projectsRoute}/${projectId}/versions`, versionAttr, 'Error creating new project version');
    },

    updateProjectVersion(projectId, versionId, versionAttr) {
        return api.put(
            `${projectsRoute}/${projectId}/versions/${versionId}`,
            versionAttr,
            'Error updating project version'
        );
    },

    fetchProjectVersions(projectId) {
        return api.get(`${projectsRoute}/${projectId}/versions`, 'Error getting project versions');
    },

    removeProjectVersion(projectId, versionId) {
        return api.delete(`${projectsRoute}/${projectId}/versions/${versionId}`, 'Error removing project version');
    },

    generateDescription(id) {
        return window.open(`${projectsRoute}/${id}/docx`);
    },

    generateVersionDescription(projectId, versionId, projectName, versionNumber) {
        return fetch(`${projectsRoute}/${projectId}/docx/${versionId}`, {
            headers: getHeaders(),
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${projectName}_V${versionNumber}.docx`;
                link.click();
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });
    },

    generateEDWARActivityReports(activities) {
        return window.open(`${projectsRoute}/ed_war_docx?data=${activities}`);
    },

    fetchAllProjectsDeliverablesMilestones(settings) {
        return api.get(
            `${projectsRoute}/all_deliverables_all_milestones`,
            'Error getting milestones and deliverables',
            null,
            settings
        );
    },

    fetchAllProjectsPDSignatureStatus() {
        return api.get(`${projectsRoute}/all_pd_signatures`, 'Error getting pd sigantures');
    },

    fetchAllProjectsMissingWBILead() {
        return api.get(`${projectsRoute}/all_missing_lead`, 'Error getting projects with missing lead');
    },

    fetchAllProjectsPiaMods() {
        return api.get(`${projectsRoute}/all_pia_mods`, 'Error getting PIA Mods summaries');
    },

    fetchProjectManagerProjects() {
        return api.get(`${projectsRoute}/pm_projects`, 'Error getting project manager projects');
    },

    fetchAllActivityReports() {
        return api.get(`${projectsRoute}/activity_reports`, 'Error getting activities');
    },

    fetchAllRecentWeeklyActivityReports() {
        return api.get(`${projectsRoute}/all_recent_weekly_activity_reports`, 'Error getting activities');
    },

    fetchAllAgreementSummaries() {
        return api.get(`${projectsRoute}/agreement_summaries`, 'Error getting agreements');
    },

    fetchAllStatusUpdates(settings) {
        return api.get(`${projectsRoute}/stale_status`, 'Error getting status', null, settings);
    },

    fetchAllRecentStatusUpdates() {
        return api.get(`${projectsRoute}/all_recent_statuses`, 'Error getting statuses');
    },

    updateProjectDataPageTables(projectId, tableType, data) {
        return api.put(
            `${projectsRoute}/${projectId}/data_tables?data=${tableType}`,
            data,
            'Error updating project data table'
        );
    },
};
