import api from './api';

import endpoints from './endpoints';

const transferRoute = endpoints.TRANSFER_REQUESTS;

export default {
    createTransferRequest(attributes) {
        return api.post(transferRoute, attributes, 'Error creating a transfer request');
    },

    updateTransferRequest(attributes, id) {
        return api.put(`${transferRoute}/${id}`, attributes, 'Error updating transfer request');
    },

    getTransferRequests() {
        return api.get(transferRoute, 'Error getting transfer requests');
    },

    getTransferRequestUserApprover(projectId) {
        return api.get(`${transferRoute}/${projectId}`, 'Error getting pending transfer requests approvals in project');
    },

    applyTransferRequest(transactionId) {
        return api.put(`${transferRoute}/${transactionId}/approve`, 'Error aproving transfer request');
    },

    rejectTransferRequest(transactionId) {
        return api.put(`${transferRoute}/${transactionId}/reject`, 'Error rejecting transfer request');
    },

    deleteTransferRequest(id) {
        return api.delete(`${transferRoute}/${id}`, 'Error deleting transfer request');
    },
};
