import api from './api';

import endpoints from './endpoints';

const dropboxRoute = endpoints.DROPBOX;

export default {
    getLinkFromId(id) {
        return api.get(dropboxRoute + '/get_db_link/' + id, 'Error getting link to Dropbox file');
    },
};
