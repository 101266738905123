// React
import React, { Component } from 'react';
import Select from 'react-select';
// import { history } from '../../store/store';

//AFRL Components
import AfrlNotifications from './afrl/AflrNotifications';
import TravelApprovalPop from './afrl/TravelApprovalPop';
import ExpApprovalPop from './afrl/ExpApprovalPop';
import SignApprovalPop from './afrl/SignApprovalPop';
import MilestonesTable from './afrl/Milestones';
import ProjectDesc from './afrl/ProjectDesc';

//Utils
import { shortDate } from '../utils/utils';

//Shared Component
import GridSection from '../components/section/GridSection';
import PageHeader from '../components/heading/PageHeader';
import errorToast from '../components/messages/ErrorMessage';
import Modal from '../components/modals/Modal';
import StatusSymbolRenderer from '../components/symbols/StatusSymbol';
import ProjectFinancialInfo from '../projects/ProjectFinancialInfo';
import ModsTable from '../finance/mods/modsTable';
import FinancialSumChartWrapper from '../projects/OverviewTables/FinancialSumChartWrapper';

//Api
import ProjectsApi from '../api/projects';
import TravelRequestsApi from '../api/travelRequests';
import ExpendituresApi from '../api/expenditures';
import ModsApi from '../api/mods';
import PiaApi from '../api/pia';
import CpoApi from '../api/cpo';
import UsersApi from '../api/users';

class AFRLHomePage extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.displayNotification = this.displayNotification.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.editSignature = this.editSignature.bind(this);

        this.state = {
            projectInfo: null,
            project: '',
            projects: [],
            projectOptions: [],
            projectSelected: false,
            errorMessage: null,
            notification: null,
            isOpen: false,
            mods: [],
            pias: [],
            cpos: [],
            users: [],
        };
    }

    componentDidMount = async () => {
        try {
            let projectSelectLabels = [];
            let projectLabels = [];
            const { data: mods } = await ModsApi.getMods();
            const { data: pias } = await PiaApi.getPias();
            const { data: cpos } = await CpoApi.getCpos();
            const { data: projects } = await ProjectsApi.fetchProjects();
            const { data: users } = await UsersApi.getAllUsers();
            if (projects) {
                projects.forEach((project) => {
                    projectSelectLabels.push({
                        label: project.name,
                        value: project.id,
                        number: project.number,
                    });
                    projectLabels.push({
                        name: project.name,
                        id: project.id,
                        number: project.number,
                    });
                });
            }

            this.setState({
                projectOptions: projectSelectLabels,
                projects: projectLabels,
                mods,
                pias,
                cpos,
                users,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onChange(selectedOption) {
        if (selectedOption) {
            ProjectsApi.fetchProject(selectedOption.value)
                .then((response) => {
                    let project = response.data;
                    if (project.wbi_lead_user_id) {
                        project.users.forEach((user) => {
                            if (project.wbi_lead_user_id === user.id) {
                                project.wbi_lead_user_name = user.name;
                            }
                        });
                    }

                    this.setState({
                        projectInfo: selectedOption,
                        project,
                        projectSelected: true,
                    });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    closeNotification = () => this.setState({ isOpen: false });

    displayNotification(notification) {
        this.setState({
            notification,
            isOpen: true,
        });
    }

    approve(id, type) {
        if (type === 'travel') {
            TravelRequestsApi.approveTravelRequest(id, {})
                .then(() => {
                    this.setState({ notification: null });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        } else {
            ExpendituresApi.approveExpenditure(id)
                .then(() => {
                    this.setState({ notification: null });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    reject(id, type) {
        if (type === 'travel') {
            TravelRequestsApi.rejectTravelRequest(id)
                .then(() => {
                    this.setState({ notification: null });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        } else {
            ExpendituresApi.rejectExpenditure(id)
                .then(() => {
                    this.setState({ notification: null });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    editSignature(values, projectId) {
        let attributes = {
            link_to_db: values.link_to_db,
            signed: values.signed,
            name: values.name,
        };

        ProjectsApi.updateSignature(projectId, values.id, attributes)
            .then(() => {
                this.setState({ notification: null });
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    render() {
        if (!this.state.projects) return <div>Loading...</div>;
        const { project, mods, pias, cpos, users, projects } = this.state;
        let identifyingInfo = null;
        let milestones = null;
        let overview = null;
        let statusUpdate = null;
        let financialInfo = null;
        let projectDesc = null;
        let financialSum = null;
        let approvalSection = null;

        if (this.state.notification) {
            approvalSection = (
                <Modal isOpen={this.state.isOpen} handleClose={this.closeNotification}>
                    <button onClick={this.closeNotification} className="align-self-end">
                        <i className="fa fa-window-close" />
                    </button>
                    {this.state.notification.target_type === 'Project' && (
                        <SignApprovalPop approval={this.state.notification} editSignature={this.editSignature} />
                    )}
                    {this.state.notification.target_type === 'Expenditure' && (
                        <ExpApprovalPop
                            approval={this.state.notification}
                            approve={this.approve}
                            reject={this.reject}
                        />
                    )}
                    {this.state.notification.target_type === 'TravelRequest' && (
                        <TravelApprovalPop
                            approval={this.state.notification}
                            approve={this.approve}
                            reject={this.reject}
                        />
                    )}
                </Modal>
            );
        }

        if (project) {
            identifyingInfo = (
                <GridSection title="Identifying Information" area="info">
                    <table className="data">
                        <tbody>
                            <tr>
                                <td>WBI PM:</td>
                                <td>{project.wbi_lead_user_name}</td>
                            </tr>
                            <tr>
                                <td>AFRL Customer Name:</td>
                                <td>{project.customer_name}</td>
                            </tr>
                            <tr>
                                <td>Office Symbol:</td>
                                <td>{project.office_symbol ? project.office_symbol.name : null}</td>
                            </tr>
                            <tr>
                                <td>Project Status:</td>
                                <td>{project.status}</td>
                            </tr>
                            <tr>
                                <td>Service Type:</td>
                                <td>{project.service_type.name}</td>
                            </tr>
                            <tr>
                                <td>Project Description Status: </td>
                                <td>
                                    {project.pd_status_complete_date
                                        ? `${project.pd_status} on ${shortDate(project.pd_status_complete_date)}`
                                        : project.pd_status}
                                </td>
                            </tr>
                            <tr>
                                <td>Funds Received Date:</td>
                                <td>{project.mod_recieved_date ? shortDate(project.mod_recieved_date) : null}</td>
                            </tr>
                            {project.last_reviewed_on ? (
                                <tr>
                                    <td>Last Reviewed:</td>
                                    <td>{shortDate(project.last_reviewed_on)}</td>
                                </tr>
                            ) : null}
                            <tr>
                                <td>Link to DB:</td>
                                <td>
                                    {project.link_to_db && (
                                        <a name="link_to_db" href={`${project.link_to_db}`}>
                                            Open in DB
                                        </a>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GridSection>
            );

            milestones = (
                <GridSection title="Milestones" area="milestones">
                    <MilestonesTable project={project} />
                </GridSection>
            );

            overview = (
                <GridSection title="Brief Overview" area="overview">
                    <p>{project.overview}</p>
                </GridSection>
            );

            let sortedStatus = project.status_updates.sort(
                (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            );
            let latestStatus = sortedStatus[0];
            statusUpdate = (
                <GridSection title="Last Status Update" area="statusUpdate">
                    <div className="bold">Last Updated: {latestStatus ? shortDate(latestStatus.date) : ''}</div>
                    <div className="flex">
                        {latestStatus ? (
                            <div className="overview-status">
                                <StatusSymbolRenderer status={latestStatus.status} />
                            </div>
                        ) : null}
                        <p>{latestStatus ? latestStatus.description : ''}</p>
                    </div>
                </GridSection>
            );

            financialInfo = (
                <GridSection title="Financial Information" area="finance">
                    <ProjectFinancialInfo projectId={this.state.project.id} />
                </GridSection>
            );

            projectDesc = <ProjectDesc project={this.state.project} />;

            financialSum = <FinancialSumChartWrapper project={this.state.project} />;
        }

        if (!this.state.projects) return <div>loading...</div>;

        return (
            <div>
                {approvalSection}
                <PageHeader title="PIA Project Manager Home" />
                <div className="Homepage Homepage--Afrl">
                    <div
                        className={this.state.project ? 'ProjectSummaryArea' : 'ProjectSummaryAreaNoProject'}
                        area="search"
                    >
                        <GridSection title="Project Search" area="topSearch">
                            <div className="Project_search">
                                <label>Project Name:</label>
                                <Select
                                    placeholder="Search Project"
                                    autoFocus={true}
                                    value={this.state.projectInfo}
                                    options={this.state.projectOptions}
                                    onChange={this.onChange}
                                />
                                <label>Project Number:</label>
                                <input readOnly value={this.state.project ? this.state.project.number : ''} />
                            </div>
                        </GridSection>
                        {identifyingInfo}
                        {milestones}
                        {overview}
                        {statusUpdate}
                        {financialInfo}
                        {projectDesc}
                        {financialSum}
                    </div>
                    <GridSection title="Mods" area="mods">
                        <ModsTable
                            isAFRL={true}
                            mods={mods}
                            pias={pias}
                            cpos={cpos}
                            users={users}
                            projects={projects}
                        />
                    </GridSection>
                    <GridSection title="Open Notifications" area="notifications">
                        <AfrlNotifications displayNotification={this.displayNotification} />
                    </GridSection>
                </div>
            </div>
        );
    }
}

export default AFRLHomePage;
