import React from 'react';
import Table from '../../components/tables/Table';

const Milestones = (props) => (
    <Table
        data={props.project.milestones}
        columns={[
            {
                headerName: 'Milestones',
                field: 'description',
                type: 'mediumtext',
            },
            {
                headerName: 'From',
                field: 'from_date',
                type: 'date',
            },
            {
                headerName: 'To',
                field: 'to_date',
                type: 'date',
            },
            {
                headerName: 'Completed',
                field: 'completed',
                type: 'status',
                cellRenderer: 'BooleanRenderer',
            },
        ]}
    />
);

export default Milestones;
