import React from 'react';
import { Formik, Form } from 'formik';
import { getInitialProjectSignatures } from '../components/signatureForm/SignaturesForm';
import { shortDate } from '../utils/utils';
import yup from '../yup';
import ProjectsApi from '../api/projects';
import PiaApi from '../api/pia';
import CpoApi from '../api/cpo';
import ServicesApi from '../api/services';
import OfficeSymbolsApi from '../api/officeSymbols';
import CpoParagraphsApi from '../api/cpoParagraphs';
import UsersApi from '../api/users';
import ProjectTypesApi from '../api/projectTypes';
import ProjectIntroSection from './DataPage/ProjectIntroSection';
import InnovationSection from './DataPage/InnovationSection';
import ProjectDescriptionSection from './DataPage/ProjectDescriptionSection';
import VersionHistorySection from './DataPage/VersionHistorySection';
import ProjectDetailsSection from './DataPage/ProjectDetailsSection';
import PeopleInvolvedSection from './DataPage/PeopleInvolvedSection';
import SignaturesSection from './DataPage/SignaturesSection';
import errorToast from '../components/messages/ErrorMessage';

const re = /[^A-Za-z0-9_]/g;

class DataPage extends React.Component {
    constructor(props) {
        super(props);

        this.establishAttributes = this.establishAttributes.bind(this);
        this.handleEditMode = this.handleEditMode.bind(this);
        this.handleKeyGoals = this.handleKeyGoals.bind(this);
        this.handleTasks = this.handleTasks.bind(this);
        this.handleDeliverables = this.handleDeliverables.bind(this);
        this.handleMilestones = this.handleMilestones.bind(this);
        this.handleStatusUpdates = this.handleStatusUpdates.bind(this);
        this.handleTeamMembers = this.handleTeamMembers.bind(this);
        this.handleWbiRoles = this.handleWbiRoles.bind(this);
        this.handleCustomerRoles = this.handleCustomerRoles.bind(this);
        this.handleOtherRoles = this.handleOtherRoles.bind(this);
        this.handleVersions = this.handleVersions.bind(this);
        this.handleTeamMembers = this.handleTeamMembers.bind(this);
        this.handleCreateNewPd = this.handleCreateNewPd.bind(this);
        this.filterCpoParagraphs = this.filterCpoParagraphs.bind(this);
        this.handleCPOparaSelection = this.handleCPOparaSelection.bind(this);
        this.selectInnovationServices = this.selectInnovationServices.bind(this);

        this.state = {
            project: {},
            pias: null,
            cpos: null,
            selectedCpo: null,
            selectedCpos: null,
            services: null,
            officeSymbols: null,
            cpoParagraphs: null,
            users: null,
            selectedUsers: null,
            projectTypes: null,
            status: null,
            pdStatus: null,
            isLoading: this.props.match.params.id !== 'new',
            isEditing: this.props.match.params.id === 'new',
            initialSignatures: getInitialProjectSignatures(),
            key_goals: [],
            tasks: [],
            deliverables: [],
            milestones: [],
            status_updates: [],
            wbi_roles: [],
            customer_roles: [],
            other_roles: [],
            versions: [],
            filteredParagraphs: [],
            selectedParagraphs: [],
            isNew: this.props.match.params.id === 'new',
            isInnovative: false,
            listRowIndex: [],
            teamMembers: [],
        };
    }

    async componentDidMount() {
        const projectId = this.props.match.params.id;
        try {
            const { data: pias } = await PiaApi.getPias();
            const { data: cpos } = await CpoApi.getCpos();
            const { data: services } = await ServicesApi.getServices();
            const { data: officeSymbols } = await OfficeSymbolsApi.getOfficeSymbols();
            const { data: cpoParagraphs } = await CpoParagraphsApi.getCPOparagraphs();
            const { data: users } = await UsersApi.getUsers();
            const { data: usersOptions } = await UsersApi.getUserOptions();
            const { data: projectTypes } = await ProjectTypesApi.getProjectTypes();
            const { data: status } = await ProjectsApi.fetchStatus();
            const { data: pdStatus } = await ProjectsApi.fetchPdStatus();

            this.setState({
                pias,
                cpos,
                services,
                officeSymbols,
                cpoParagraphs,
                users,
                projectTypes,
                status,
                pdStatus,
                usersOptions,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }

        if (projectId !== 'new') {
            this.setState({ isNew: false });
            try {
                const { data: project = {} } = await ProjectsApi.fetchProject(projectId);
                const { data: financialInformation } = await ProjectsApi.fetchProjectFinancialInformation(projectId);
                const { data: status } = await ProjectsApi.fetchStatus();
                const { data: pdStatus } = await ProjectsApi.fetchPdStatus();

                let cpoInfo;
                if (project.cpo_id) cpoInfo = { label: project.cpo.number, value: project.cpo_id };

                const getKey = (obj, val) => Object.keys(obj).find((key) => obj[key] === val);

                project.status = getKey(status, project.status);
                project.pd_status = getKey(pdStatus, project.pd_status);

                let additionalUsers = [];
                let isInnovative = false;

                if (project.users) {
                    project.users.forEach((user) => {
                        let userObject = {
                            label: user.name,
                            value: user.id,
                            email_address: user.email_address,
                            phone_number: user.phone_number,
                            name: user.name,
                            id: user.id,
                            approver: user.approver,
                            cc_holder: user.holder,
                            site_role: user.site_role,
                        };
                        if (project.wbi_lead_user_id && project.wbi_lead_user_id === user.id) {
                            project.wbi_lead_name = userObject;
                            project.wbi_lead_name_txt = user.name;
                            project.wbi_lead_email_address = user.email_address;
                            project.wbi_lead_phone_number = user.phone_number;
                        } else if (project.wbi_assistant_user_id && project.wbi_assistant_user_id === user.id) {
                            project.wbi_assistant_name = userObject;
                            project.wbi_assistant_email_address = user.email_address;
                            project.wbi_assistant_phone_number = user.phone_number;
                        } else {
                            additionalUsers.push(user);
                        }
                    });
                }
                let teamMembers = [];
                project.users = additionalUsers;
                project.users.forEach((u) => {
                    teamMembers.push(u.id);
                });

                if (project.start_date) {
                    if (navigator.userAgent.indexOf('Firefox') !== -1 || navigator.userAgent.indexOf('Edge') !== -1) {
                        project.start_date_txt = project.start_date;
                    } else {
                        project.start_date = shortDate(project.start_date);
                        project.start_date_txt = shortDate(project.start_date);
                    }
                }

                if (project.pia.name) {
                    project.pia_name = project.pia.name;
                    project.pia = project.pia.id;
                }

                let selectedCpos = [];
                if (project.cpo.number) {
                    project.cpo_name = project.cpo.number;
                    this.filterCpoParagraphs(project.cpo);
                    this.handleCPOparaSelection(project.applicable_cpo_paragraphs);
                    if (this.state.cpos) {
                        this.state.cpos.forEach((cpo) => {
                            if (cpo.pia_id === project.pia) {
                                selectedCpos.push(cpo);
                            }
                        });
                    }
                }
                if (
                    project.service_type.name.includes('Innovation') ||
                    project.service_type.name.includes('Commercialization')
                ) {
                    isInnovative = true;

                    project.legal ? (project.legal = 'yes') : (project.legal = 'no');

                    project.primarily_pass_through
                        ? (project.primarily_pass_through = 'yes')
                        : (project.primarily_pass_through = 'no');

                    project.just_facility_use
                        ? (project.just_facility_use = 'yes')
                        : (project.just_facility_use = 'no');

                    project.try_something_new
                        ? (project.try_something_new = 'yes')
                        : (project.try_something_new = 'no');

                    project.cpo_focus_area ? (project.cpo_focus_area = 'yes') : (project.cpo_focus_area = 'no');

                    project.sufficent_resources
                        ? (project.sufficent_resources = 'yes')
                        : (project.sufficent_resources = 'no');

                    project.more_than_one_service_area
                        ? (project.more_than_one_service_area = 'yes')
                        : (project.more_than_one_service_area = 'no');

                    project.wbi_add_value ? (project.wbi_add_value = 'yes') : (project.wbi_add_value = 'no');

                    project.acceptance_recommendation
                        ? (project.acceptance_recommendation = 'true')
                        : (project.acceptance_recommendation = 'false');
                }

                this.setState({
                    project,
                    financialInformation,
                    selectedCpo: cpoInfo,
                    selectedCpos,
                    background: project.background,
                    key_goals: project.key_goals,
                    tasks: project.tasks,
                    deliverables: project.deliverables,
                    milestones: project.milestones,
                    status_updates: project.status_updates,
                    wbi_roles: project.wbi_roles,
                    customer_roles: project.customer_roles,
                    other_roles: project.other_roles,
                    versions: project.versions,
                    isInnovative,
                    teamMembers,
                });
            } catch (errorMessage) {
                errorToast(errorMessage);
            } finally {
                this.setState({ isLoading: false });
            }
        }
    }

    handleSubmit = (values, actions) => {
        const projectId = this.props.match.params.id;
        let peopleIds = [];

        if (this.state.isNew) {
            this.setState({
                isEditing: false,
                isNew: false,
            });
        } else {
            this.setState({ isEditing: false });
        }

        let projectAttributes = this.establishAttributes(values);

        if (values.people && values.people.length > 0) {
            peopleIds = values.people.map((peep) => peep.id);
        } else {
            peopleIds = this.state.teamMembers;
        }

        peopleIds.push(values.wbi_lead_user_id);

        if (values.wbi_assistant_user_id) {
            peopleIds.push(values.wbi_assistant_user_id);
        }
        projectAttributes.people = [...new Set(peopleIds)];

        if (values.pd_status.toUpperCase() === 'YES') {
            if (!values.pd_status_complete_date) {
                projectAttributes.pd_status_complete_date = new Date();
            }
        } else {
            projectAttributes.pd_status_complete_date = null;
        }

        if (projectId === 'new') {
            if (values.category === 'sub-project') {
                projectAttributes.subproject = true;
                projectAttributes.number = values.number;
                projectAttributes.parent_name = values.parent_name;
                projectAttributes.cpo_id = values.cpo_id;
            } else {
                projectAttributes.subproject = false;
                delete projectAttributes['parent_name'];
            }
            if (this.state.selectedUsers) {
                let peopleIds = [];
                this.state.selectedUsers.forEach((user) => {
                    peopleIds.push(user.id);
                });
                projectAttributes.people = peopleIds;
            }

            if (values.wbi_roles) projectAttributes.wbi_roles = values.wbi_roles;

            if (values.customer_roles) projectAttributes.customer_roles = values.customer_roles;

            if (values.other_roles) projectAttributes.other_roles = values.other_roles;

            if (values.status_updates) projectAttributes.status_updates = values.status_updates;

            if (values.tasks) projectAttributes.tasks = values.tasks;

            if (values.deliverables) projectAttributes.deliverables = values.deliverables;

            if (values.milestones) projectAttributes.milestones = values.milestones;

            if (values.applicable_cpo_paragraphs) {
                let paragraphsIds = [];
                values.applicable_cpo_paragraphs.forEach((cpoParagraph) => {
                    paragraphsIds.push(cpoParagraph.id);
                });
                projectAttributes.applicable_cpo_paragraphs = paragraphsIds;
            }

            if (values.key_goals) {
                let newKeyGoals = [];
                values.key_goals.forEach((goal) => {
                    if (goal.reached === 'Yes') goal.reached = true;
                    else goal.reached = false;

                    newKeyGoals.push(goal);
                });
                values.key_goals = newKeyGoals;
                projectAttributes.key_goals = values.key_goals;
            }
            if (
                values.service_type_id.label.includes('Innovation') ||
                values.service_type_id.label.includes('Commercialization')
            ) {
                values.score.legal === 'yes' ? (projectAttributes.legal = true) : (projectAttributes.legal = false);

                values.score.primarily_pass_through === 'yes'
                    ? (projectAttributes.primarily_pass_through = true)
                    : (projectAttributes.primarily_pass_through = false);

                values.score.just_facility_use === 'yes'
                    ? (projectAttributes.just_facility_use = true)
                    : (projectAttributes.just_facility_use = false);

                if (values.score.afrl_importance) projectAttributes.afrl_importance = values.score.afrl_importance;

                if (values.score.client_jazzed) projectAttributes.client_jazzed = values.score.client_jazzed;

                if (values.score.advocate_strength)
                    projectAttributes.advocate_strength = values.score.advocate_strength;

                if (values.score.promote_thought_leadership)
                    projectAttributes.promote_thought_leadership = values.score.promote_thought_leadership;

                values.score.try_something_new === 'yes'
                    ? (projectAttributes.try_something_new = true)
                    : (projectAttributes.try_something_new = false);

                values.score.cpo_focus_area === 'yes'
                    ? (projectAttributes.cpo_focus_area = true)
                    : (projectAttributes.cpo_focus_area = false);

                if (values.score.client_probability)
                    projectAttributes.client_probability = values.score.client_probability;

                if (values.score.potential_for_promotion)
                    projectAttributes.potential_for_promotion = values.score.potential_for_promotion;

                values.score.sufficent_resources === 'yes'
                    ? (projectAttributes.sufficent_resources = true)
                    : (projectAttributes.sufficent_resources = false);

                if (values.score.can_obtain_resources)
                    projectAttributes.can_obtain_resources = values.score.can_obtain_resources;

                if (values.score.client_willing_to_supply)
                    projectAttributes.client_willing_to_supply = values.score.client_willing_to_supply;

                if (values.score.problem_significance)
                    projectAttributes.problem_significance = values.score.problem_significance;

                values.score.more_than_one_service_area === 'yes'
                    ? (projectAttributes.more_than_one_service_area = true)
                    : (projectAttributes.more_than_one_service_area = false);

                values.score.wbi_add_value === 'yes'
                    ? (projectAttributes.wbi_add_value = true)
                    : (projectAttributes.wbi_add_value = false);

                values.score.acceptance_recommendation === 'true'
                    ? (projectAttributes.acceptance_recommendation = true)
                    : (projectAttributes.acceptance_recommendation = false);
            }

            return ProjectsApi.createProject(projectAttributes)
                .then((response) => {
                    const projectId = response.data.id;
                    this.setState({ project: response.data });
                    // redirect to project data page
                    this.props.history.push('/projects/' + projectId + '/data');
                    actions.setSubmitting(false);
                    // *************
                    // We have to reload because Formik and ag-grid because there are issues with shared state
                    window.location.reload(true);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage, isEditing: true });
                    actions.setSubmitting(false);
                });
        }
        delete projectAttributes['parent_name'];

        if (
            this.state.project.service_type.name.includes('Innovation') ||
            this.state.project.service_type.name.includes('Commercialization')
        ) {
            values.score.legal === 'yes' ? (projectAttributes.legal = true) : (projectAttributes.legal = false);

            values.score.primarily_pass_through === 'yes'
                ? (projectAttributes.primarily_pass_through = true)
                : (projectAttributes.primarily_pass_through = false);

            values.score.just_facility_use === 'yes'
                ? (projectAttributes.just_facility_use = true)
                : (projectAttributes.just_facility_use = false);

            if (values.score.afrl_importance) projectAttributes.afrl_importance = values.score.afrl_importance;

            if (values.score.client_jazzed) projectAttributes.client_jazzed = values.score.client_jazzed;

            if (values.score.advocate_strength) projectAttributes.advocate_strength = values.score.advocate_strength;

            if (values.score.promote_thought_leadership)
                projectAttributes.promote_thought_leadership = values.score.promote_thought_leadership;

            values.score.try_something_new === 'yes'
                ? (projectAttributes.try_something_new = true)
                : (projectAttributes.try_something_new = false);

            values.score.cpo_focus_area === 'yes'
                ? (projectAttributes.cpo_focus_area = true)
                : (projectAttributes.cpo_focus_area = false);

            if (values.score.client_probability) projectAttributes.client_probability = values.score.client_probability;

            if (values.score.potential_for_promotion)
                projectAttributes.potential_for_promotion = values.score.potential_for_promotion;

            values.score.sufficent_resources === 'yes'
                ? (projectAttributes.sufficent_resources = true)
                : (projectAttributes.sufficent_resources = false);

            if (values.score.can_obtain_resources)
                projectAttributes.can_obtain_resources = values.score.can_obtain_resources;

            if (values.score.client_willing_to_supply)
                projectAttributes.client_willing_to_supply = values.score.client_willing_to_supply;

            if (values.score.problem_significance)
                projectAttributes.problem_significance = values.score.problem_significance;

            values.score.more_than_one_service_area === 'yes'
                ? (projectAttributes.more_than_one_service_area = true)
                : (projectAttributes.more_than_one_service_area = false);

            values.score.wbi_add_value === 'yes'
                ? (projectAttributes.wbi_add_value = true)
                : (projectAttributes.wbi_add_value = false);

            values.score.acceptance_recommendation === 'true'
                ? (projectAttributes.acceptance_recommendation = true)
                : (projectAttributes.acceptance_recommendation = false);
        }

        if (values.information_security) {
            let offSym = values.office_symbol_id;
            if (typeof offSym === 'string') {
                projectAttributes.information_security = values.information_security;
            } else {
                let split = values.information_security.split(' ');
                let shift = split.shift();
                if (shift && offSym) {
                    projectAttributes.information_security = values.office_symbol_id.label + ' ' + split.join(' ');
                }
            }
        }
        if (projectAttributes.wbi_assistant_user_id === undefined) projectAttributes.wbi_assistant_user_id = null;
        ProjectsApi.updateProject(projectId, projectAttributes)
            .then((response) => {
                actions.setSubmitting(false);
                window.location.reload(true);
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage, isEditing: true });
                actions.setSubmitting(false);
            });
    };

    handleTeamMembers(value, type) {
        let teamMembers = [];

        if (type === 'remove') {
            teamMembers = this.state.teamMembers.filter((e) => e !== value);
        } else {
            teamMembers = this.state.teamMembers;
            teamMembers.push(value.value);
        }

        this.setState({ teamMembers });
    }

    handleEditMode(editing) {
        this.setState({ isEditing: editing });
    }

    handleKeyGoals(key_goals) {
        this.setState({ key_goals });
    }

    handleTasks(tasks) {
        this.setState({ tasks });
    }

    handleDeliverables(deliverables) {
        this.setState({ deliverables });
    }

    handleMilestones(milestones) {
        this.setState({ milestones });
    }

    handleStatusUpdates(status_updates) {
        this.setState({ status_updates });
    }

    handleWbiRoles(wbi_roles) {
        this.setState({ wbi_roles });
    }

    handleCustomerRoles(customer_roles) {
        this.setState({ customer_roles });
    }

    handleOtherRoles(other_roles) {
        this.setState({ other_roles });
    }

    handleVersions(versions) {
        this.setState({ versions });
    }

    handleCreateNewPd() {
        const { project, key_goals, tasks, deliverables, milestones, wbi_roles, customer_roles, other_roles } =
            this.state;
        key_goals.forEach((goal) => {
            ProjectsApi.removeGoal(project.id, goal.id).then(() => {
                this.setState({ key_goals: null });
            });
        });

        tasks.forEach((task) => {
            ProjectsApi.removeTask(project.id, task.id).then(() => {
                this.setState({ tasks: null });
            });
        });

        deliverables.forEach((deliverable) => {
            ProjectsApi.removeDeliverable(project.id, deliverable.id).then(() => {
                this.setState({ deliverables: null });
            });
        });

        milestones.forEach((milestone) => {
            ProjectsApi.removeMilestone(project.id, milestone.id).then(() => {
                this.setState({ milestones: null });
            });
        });

        wbi_roles.forEach((wbiRole) => {
            ProjectsApi.removeWbiRole(project.id, wbiRole.id).then(() => {
                this.setState({ wbi_roles: null });
            });
        });

        customer_roles.forEach((customerRole) => {
            ProjectsApi.removeCustomerRole(project.id, customerRole.id).then(() => {
                this.setState({ customer_roles: null });
            });
        });

        other_roles.forEach((otherRole) => {
            ProjectsApi.removeOtherRole(project.id, otherRole.id).then(() => {
                this.setState({ other_roles: null });
            });
        });

        const newProject = {
            background: '',
            applicable_cpo_paragraphs: [],
            funding_plan: '',
            initial_budget: null,
            information_security: ` ${project.office_symbol.name} will ensure that only Public Domain data/information will be used in the execution of this project and no Controlled Unclassified Information (CUI) will be involved.  If CUI is required, the project will be executed at the WBI Springfield Street location and an approved AFRL information security plan will be in place before work begins.
          `,
            customer_value_proposition: '',
        };

        let peopleIds = [];
        if (project.people && project.people.length > 0) {
            peopleIds = project.people.map((peep) => peep.id);
        }
        peopleIds.push(project.wbi_lead_user_id);
        peopleIds.push(project.wbi_assistant_user_id);
        newProject.people = [...new Set(peopleIds)];

        ProjectsApi.updateProject(project.id, newProject).then(() => {
            window.location.reload(true);
        });
    }

    handleCPOparaSelection(selectedParagraphs, listRowIndex) {
        this.setState({
            selectedParagraphs,
            listRowIndex,
        });
    }

    establishAttributes = (values) => {
        let projectAttributes = {};

        if (values) {
            projectAttributes = {
                name: values.name,
                cpo_id: values.cpo_id.value,
                parent_name: values.parent_name || '',
                service_type_id: values.service_type_id.value,
                wbi_lead_user_id: values.wbi_lead_user_id,
                number: values.number,
                status: values.status,
                start_date: values.start_date,
                customer_name: values.customer_name,
                overview: values.overview,
                background: values.background,
                funding_plan: values.funding_plan,
                information_security: values.information_security,
                customer_value_proposition: values.customer_value_proposition,
                link_to_db: values.link_to_db,
                initial_budget: values.initial_budget || 0,
                customer_organization: values.customer_organization.label,
                customer_phone_number: values.customer_phone_number.replace(re, '') || null,
                customer_email_address: values.customer_email_address,
                customer_assistant_name: values.customer_assistant_name,
                customer_assistant_organization: values.customer_assistant_organization.label,
                customer_assistant_phone_number: values.customer_assistant_phone_number.replace(re, '') || null,
                customer_assistant_email_address: values.customer_assistant_email_address,
            };

            if (values.office_symbol_id) projectAttributes.office_symbol_id = values.office_symbol_id.value;

            if (values.customer_organization)
                projectAttributes.customer_organization = values.customer_organization.value;

            if (values.customer_assistant_organization)
                projectAttributes.customer_assistant_organization = values.customer_assistant_organization.value;

            if (values.estimated_end_date) projectAttributes.estimated_end_date = values.estimated_end_date;

            if (values.pd_status) projectAttributes.pd_status = values.pd_status;

            if (values.pd_status_complete_date)
                projectAttributes.pd_status_complete_date = values.pd_status_complete_date;

            if (values.mod_recieved_date) projectAttributes.mod_recieved_date = values.mod_recieved_date;

            if (values.money_receieved_date) projectAttributes.money_recieved_date = values.money_recieved_date;

            if (values.primary_type_id) projectAttributes.primary_type_id = values.primary_type_id;

            if (values.secondary_type_id) projectAttributes.secondary_type_id = values.secondary_type_id;

            if (values.wbi_assistant_user_id) {
                projectAttributes.wbi_assistant_user_id = values.wbi_assistant_user_id;
            } else {
                projectAttributes.wbi_assistant_user_id = null;
            }

            if (values.applicable_cpo_paragraphs) {
                let paragraphs = [];
                if (values.applicable_cpo_paragraphs) {
                    paragraphs = values.applicable_cpo_paragraphs;
                }
                let paragraphsIds = [];
                paragraphs.forEach((cpoParagraph) => {
                    paragraphsIds.push(cpoParagraph.id);
                });
                projectAttributes.applicable_cpo_paragraphs = paragraphsIds;
            }
        }

        return projectAttributes;
    };

    selectInnovationServices(services) {
        if (
            (services && services.label.includes('Innovation')) ||
            (services && services.label.includes('Commercialization'))
        ) {
            this.setState({ isInnovative: true });
        } else {
            this.setState({ isInnovative: false });
        }
    }

    filterCpoParagraphs(e) {
        let value;
        if (e.hasOwnProperty('value')) {
            value = e.value;
        } else {
            value = e.id;
        }
        let filteredParagraphs = [];
        if (this.state.cpoParagraphs) {
            this.state.cpoParagraphs.forEach((paragraph) => {
                if (paragraph.cpo_id === value) {
                    filteredParagraphs.push(paragraph);
                }
            });
        }

        this.setState({
            selectedCpo: e,
            filteredParagraphs,
        });
    }

    render() {
        const projectId = this.props.match.params.id;
        let piaOptions = [];
        let cpoOptions = [];
        let serviceTypeOptions = [];
        let officeSymbolOptions = [];
        let projectTypeOptions = [];
        let statusOptions = [];
        let pdStatusOptions = [];
        let spacer = ' ';
        let serviceOptionsToShow = [];
        let projectOptionsToShow = [];
        let userOptions = [];
        const {
            isEditing,
            pias,
            selectedCpos,
            services,
            officeSymbols,
            users,
            projectTypes,
            status,
            pdStatus,
            key_goals,
            tasks,
            deliverables,
            milestones,
            status_updates,
            wbi_roles,
            customer_roles,
            other_roles,
            selectedUsers,
            initialSignatures,
            project,
            financialInformation,
            versions,
            selectedParagraphs,
            filteredParagraphs,
            isNew,
        } = this.state;

        if (this.state.isLoading) return <div>loading...</div>;

        if (pias) {
            pias.forEach((pia) => {
                piaOptions.push({ label: pia.name, value: pia.id });
            });
        }

        if (selectedCpos) {
            selectedCpos.forEach((cpo) => {
                cpoOptions.push({ label: cpo.number, value: cpo.id });
            });
        }

        if (services) {
            services.forEach((service) => {
                serviceTypeOptions.push({
                    label: service.name,
                    value: service.id,
                    disabled: service.disabled,
                });
            });
        }

        if (officeSymbols) {
            officeSymbols.forEach((symbol) => {
                officeSymbolOptions.push({ label: symbol.name, value: symbol.id });
            });
        }

        if (projectTypes) {
            projectTypes.forEach((projectType) => {
                projectTypeOptions.push({
                    label: projectType.name,
                    value: projectType.id,
                    disabled: projectType.disabled,
                    service_type_id: projectType.service_type_id,
                });
            });
            if (isEditing) {
                projectOptionsToShow = projectTypeOptions.filter((projectType) => projectType.disabled !== true);
            } else {
                projectOptionsToShow = projectTypeOptions;
            }
        }

        if (status) {
            for (let key in status) {
                statusOptions.push({ label: this.state.status[key], value: key });
            }
            statusOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        if (pdStatus) {
            for (let pd in pdStatus) {
                pdStatusOptions.push({ label: pdStatus[pd], value: pd });
            }
            pdStatusOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        if (this.state.usersOptions) {
            userOptions = this.state.usersOptions;
        }

        return (
            <>
                <Formik
                    initialValues={{
                        name: project.name || '',
                        number: project.number || '',
                        category: project.subproject ? 'sub-project' : 'project',
                        parent_name: project.parent_name || '',
                        pia: project.pia || '',
                        cpo_id: project.cpo_id || '',
                        status: project.status || '',
                        service_type_id: project.service_type_id || '',
                        office_symbol_id: project.office_symbol_id || '',
                        primary_type_id: project.primary_type_id || '',
                        secondary_type_id: project.secondary_type_id || '',
                        score: {
                            legal: project.legal || '',
                            primarily_pass_through: project.primarily_pass_through || '',
                            just_facility_use: project.just_facility_use || '',
                            afrl_importance: project.afrl_importance || '',
                            client_jazzed: project.client_jazzed || '',
                            advocate_strength: project.advocate_strength || '',
                            promote_thought_leadership: project.promote_thought_leadership || '',
                            try_something_new: project.try_something_new || '',
                            cpo_focus_area: project.cpo_focus_area || '',
                            client_probability: project.client_probability || '',
                            potential_for_promotion: project.potential_for_promotion || '',
                            sufficent_resources: project.sufficent_resources || '',
                            can_obtain_resources: project.can_obtain_resources || '',
                            client_willing_to_supply: project.client_willing_to_supply || '',
                            problem_significance: project.problem_significance || '',
                            more_than_one_service_area: project.more_than_one_service_area || '',
                            wbi_add_value: project.wbi_add_value || '',
                        },
                        acceptance_recommendation: project.acceptance_recommendation || '',
                        overview: project.overview || '',
                        background: project.background || '',
                        funding_plan: project.funding_plan || '',
                        information_security:
                            project.information_security ||
                            'will ensure that only Public Domain data/information will be used in the execution of this project and no Controlled Unclassified Information (CUI) will be involved.  If CUI is required, the project will be executed at the WBI Springfield Street location and an approved AFRL information security plan will be in place before work begins.',
                        customer_value_proposition: project.customer_value_proposition || '',
                        link_to_db: project.link_to_db || '',
                        initial_budget: project.initial_budget || '',
                        mod_recieved_date: project.mod_recieved_date || '',
                        money_recieved_date: project.money_receieved_date || '',
                        pd_status: project.pd_status || '',
                        pd_status_complete_date: project.pd_status_complete_date || '',
                        start_date: project.start_date || '',
                        estimated_end_date: project.estimated_end_date || '',
                        applicable_cpo_paragraphs: project.applicable_cpo_paragraphs || [],
                        wbi_lead_user_id: project.wbi_lead_user_id || '',
                        wbi_assistant_user_id: project.wbi_assistant_user_id || '',
                        wbi_lead_name_txt: project.wbi_lead_name_txt || '',
                        wbi_lead_name: project.wbi_lead_name || '',
                        wbi_lead_email_address: project.wbi_lead_email_address || '',
                        wbi_lead_phone_number: project.wbi_lead_phone_number || '',
                        wbi_assistant_name: project.wbi_assistant_name || '',
                        wbi_assistant_email_address: project.wbi_assistant_email_address || '',
                        wbi_assistant_phone_number: project.wbi_assistant_phone_number || '',
                        customer_organization: project.customer_organization || '',
                        customer_name: project.customer_name || '',
                        customer_email_address: project.customer_email_address || '',
                        customer_phone_number: project.customer_phone_number || '',
                        customer_assistant_organization: project.customer_assistant_organization || '',
                        customer_assistant_name: project.customer_assistant_name || '',
                        customer_assistant_email_address: project.customer_assistant_email_address || '',
                        customer_assistant_phone_number: project.customer_assistant_phone_number || '',
                        users: project.users || [],
                        signatures: !project.id ? this.state.initialSignatures : project.signatures,
                    }}
                    validationSchema={yup.object().shape({
                        name: yup.string().required(),
                        category: yup.string(),
                        parent_name: yup.string().when('category', {
                            is: 'sub-project',
                            then: yup.string().required(),
                            otherwise: yup.string().notRequired(),
                        }),
                        start_date: yup.string().required(),
                        pia: yup.string().when('category', {
                            is: 'sub-project',
                            then: yup.string().notRequired(),
                            otherwise: yup.string().required(),
                        }),
                        cpo_id: yup.string().required(),
                        status: yup.string().required(),
                        service_type_id: yup.string().required(),
                        wbi_lead_name: yup.string().required(),
                        customer_name: yup.string().required(),
                    })}
                    onSubmit={this.handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        isValid,
                        dirty,
                        setFieldValue,
                        handleChange,
                        resetForm,
                        submitForm,
                    }) => (
                        <Form className="Double-layout--equal-size Project-data-page">
                            {/* COLUMN 1 */}
                            <div>
                                <ProjectIntroSection
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    isEditing={isEditing}
                                    piaOptions={piaOptions}
                                    cpos={this.state.cpos}
                                    cpoOptions={cpoOptions}
                                    statusOptions={statusOptions}
                                    serviceTypeOptions={serviceTypeOptions}
                                    officeSymbolOptions={officeSymbolOptions}
                                    projectTypeOptions={projectOptionsToShow}
                                    setFieldValue={setFieldValue}
                                    filterCpoParagraphs={this.filterCpoParagraphs}
                                    selectInnovationServices={this.selectInnovationServices}
                                    project={project}
                                    financialInformation={financialInformation}
                                    isNew={this.state.isNew}
                                />

                                {this.state.isInnovative ? (
                                    <InnovationSection
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        isEditing={isEditing}
                                        serviceTypeOptions={serviceOptionsToShow}
                                    />
                                ) : null}

                                {!isNew && (
                                    <ProjectDescriptionSection
                                        projectId={projectId}
                                        key_goals={key_goals}
                                        handleKeyGoals={this.handleKeyGoals}
                                        tasks={tasks}
                                        handleTasks={this.handleTasks}
                                        deliverables={deliverables}
                                        handleDeliverables={this.handleDeliverables}
                                        milestones={milestones}
                                        handleMilestones={this.handleMilestones}
                                        handleStatusUpdates={this.handleStatusUpdates}
                                        status_updates={status_updates}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        isEditing={isEditing}
                                        setFieldValue={setFieldValue}
                                        applicable_cpo_paragraphs={filteredParagraphs}
                                        selectedCPOparagraph={selectedParagraphs}
                                        setSelectedCpoParagraph={this.handleCPOparaSelection}
                                        listRowIndex={this.state.listRowIndex}
                                        isNew={isNew}
                                    />
                                )}
                                <ProjectDetailsSection
                                    projectId={projectId}
                                    wbi_roles={wbi_roles}
                                    handleWbiRoles={this.handleWbiRoles}
                                    customer_roles={customer_roles}
                                    handleCustomerRoles={this.handleCustomerRoles}
                                    other_roles={other_roles}
                                    handleOtherRoles={this.handleOtherRoles}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    isEditing={isEditing}
                                    pdStatusOptions={pdStatusOptions}
                                    isNew={isNew}
                                />

                                {!isNew && (
                                    <VersionHistorySection
                                        handleVersions={this.handleVersions}
                                        handleCreateNewPd={this.handleCreateNewPd}
                                        versions={versions}
                                        isEditing={isEditing}
                                        projectId={projectId}
                                        project={project}
                                    />
                                )}
                            </div>
                            {/* COLUMN 2 */}
                            <div
                                className="flex flex-column "
                                style={{ flexBasis: '40%', borderLeft: '1px solid black' }}
                            >
                                <PeopleInvolvedSection
                                    values={values}
                                    isEditing={isEditing}
                                    errors={errors}
                                    touched={touched}
                                    userOptions={userOptions}
                                    officeSymbolOptions={officeSymbolOptions}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    users={users}
                                    selectedUsers={selectedUsers}
                                    handleTeamMembers={this.handleTeamMembers}
                                    initialSignatures={initialSignatures}
                                    isNew={isNew}
                                />

                                <SignaturesSection
                                    values={values}
                                    isEditing={isEditing}
                                    isSubmitting={isSubmitting}
                                    submitForm={submitForm}
                                    dirty={dirty}
                                    resetForm={resetForm}
                                    isValid={isValid}
                                    projectId={projectId}
                                    setFieldValue={setFieldValue}
                                    handleEditMode={this.handleEditMode}
                                    isNew={isNew}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>

                <div className="Edit-bar">{spacer}</div>
            </>
        );
    }
}

export default DataPage;
