import React from 'react';
import _find from 'lodash/find';
import SelectionTable from '../../components/tables/SelectionTable';
import MatchCard from './MatchCard';
import ExpendituresApi from '../../api/expenditures';

class BankStatementItemReviewTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            matchOpen: false,
            columns: [
                {
                    headerName: 'Bank Statement Line Items',
                    children: [
                        {
                            type: 'checkbox',
                        },
                        {
                            headerName: 'Card Holder',
                            field: 'card_holder',
                            type: 'name',
                        },
                        {
                            headerName: 'Transaction Date',
                            field: 'transaction_date',
                            sort: 'desc',
                            type: 'date',
                        },
                        {
                            headerName: 'Amount',
                            field: 'amount',
                            type: 'money',
                        },
                        {
                            headerName: 'Description',
                            field: 'description',
                            type: 'mediumtext',
                        },
                    ],
                },
                {
                    headerName: 'User CC Entries',
                    children: [
                        {
                            headerName: 'Cardholder',
                            field: 'expenditure.cardHolder',
                            type: 'name',
                            cellStyle: (row) => {
                                let statementItem = row.data;
                                if (statementItem.card_holder_id !== statementItem.expenditure.ccHolder_id) {
                                    return { color: 'red' };
                                }
                            },
                        },
                        {
                            headerName: 'Transaction Date',
                            field: 'expenditure.purchase_date',
                            type: 'date',
                            cellStyle: (row) => {
                                let statementItem = row.data;
                                if (statementItem.transaction_date !== statementItem.expenditure.purchase_date) {
                                    return { color: 'red' };
                                }
                            },
                        },
                        {
                            headerName: 'Amount',
                            field: 'expenditure.cost',
                            type: 'money',
                            cellStyle: (row) => {
                                let statementItem = row.data;
                                if (statementItem.amount * -1 !== statementItem.expenditure.cost) {
                                    return { color: 'red' };
                                }
                            },
                        },
                        {
                            headerName: 'Description',
                            field: 'expenditure.item_description',
                            type: 'mediumtext',
                        },
                        {
                            headerName: 'Match',
                            field: 'view',
                            type: 'symbol',
                            onCellClicked: this.viewCardDetails,
                            cellRenderer: () => '<a>View</a>',
                        },
                    ],
                },
            ],
        };
    }

    linkMatchedExpenditures = (records, expenditures) => {
        if (records && expenditures) {
            for (let i = 0; i < records.length; i++) {
                let matchedExpenditure = _find(expenditures, {
                    transaction_id: records[i].transaction_id,
                });
                records[i].expenditure = matchedExpenditure;
            }
        }

        return records;
    };

    viewCardDetails = async (event) => {
        let matchedExpenditure = event.data.expenditure;

        const { data: expenditure } = await ExpendituresApi.fetchExpenditure(matchedExpenditure.id);
        this.setState({
            matchOpen: true,
            matchedExpenditure: expenditure,
        });
    };

    closeCard = () => {
        this.setState({ matchOpen: false });
    };

    render() {
        let { records, expenditures, setSelectedReview } = this.props;
        const { columns, matchOpen, matchedExpenditure } = this.state;

        records = this.linkMatchedExpenditures(records, expenditures);

        let tableContent;
        if (!records || records.length === 0) {
            tableContent = <div>No records are currently under review.</div>;
        } else {
            tableContent = (
                <SelectionTable
                    columns={columns}
                    data={records}
                    sizeToFit={true}
                    filter={true}
                    sortable={true}
                    selectionChanged={setSelectedReview}
                />
            );
        }

        return (
            <div>
                <MatchCard matchOpen={matchOpen} matchedExpenditure={matchedExpenditure} closeCard={this.closeCard} />
                {tableContent}
            </div>
        );
    }
}

export default BankStatementItemReviewTable;
