import React from 'react';
import ProjectsApi from '../../../api/projects';

const ProjectDescription = ({ data }) => {
    const downloadFile = () => {
        const id = data.id;
        ProjectsApi.generateDescription(id);
    };

    return (
        <div className="Report-output">
            <div className="Form__buttons--Reversed Buttons_format">
                <button onClick={downloadFile}>Download</button>
            </div>
        </div>
    );
};
export default ProjectDescription;
