import api from './api';

import endpoints from './endpoints';

const offSymRoute = endpoints.OFFICE_SYMBOL;

export default {
    getOfficeSymbols() {
        return api.get(offSymRoute, 'Error getting a list of office symbols');
    },

    getOfficeSymbol(ID) {
        return api.get(`${offSymRoute}/${ID}`, 'Error getting a office symbol');
    },

    createOfficeSymbols(attributes) {
        return api.post(offSymRoute, attributes, 'Error creating a office symbol');
    },

    updateOfficeSymbol(ID, attributes) {
        return api.put(`${offSymRoute}/${ID}`, attributes, 'Error updating an office symbol');
    },

    removeOfficeSymbols(ID) {
        return api.delete(`${offSymRoute}/${ID}`, 'Error deleting an office symbol');
    },
};
