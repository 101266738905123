const convert = (str) => {
    let date = new Date(str);
    let month = date.getMonth();
    let day = date.getDate();
    return new Date(date.getFullYear(), month, day);
};

const transformedDate = (date) => {
    let convertedTableDate;
    let tableDateParts;
    let tableDateString = date;

    if (tableDateString) {
        if (tableDateString.search('/') !== -1) {
            tableDateParts = tableDateString.split('/');
            if (tableDateParts[2]) {
                convertedTableDate = new Date(
                    Number(tableDateParts[2]),
                    Number(tableDateParts[0]) - 1,
                    Number(tableDateParts[1])
                );
            }
        } else if (tableDateString.search('T') !== -1) {
            convertedTableDate = convert(tableDateString);
        } else if (tableDateString.search('-') !== -1) {
            tableDateParts = tableDateString.split('-');
            convertedTableDate = new Date(
                Number(tableDateParts[0]),
                Number(tableDateParts[1]) - 1,
                Number([tableDateParts[2]])
            );
        }
    }

    return convertedTableDate;
};

export const dateComparator = (filterToDate, tableDate) => {
    if (tableDate === null || !filterToDate) {
        return -1;
    }
    let convertedTableDate = transformedDate(tableDate);
    if (convertedTableDate && filterToDate.getTime() === convertedTableDate.getTime()) {
        return 0;
    }
    if (convertedTableDate < filterToDate) {
        return -1;
    }
    if (convertedTableDate > filterToDate) {
        return 1;
    }
};

export const dateSortComparator = (tableDate1, tableDate2) => {
    if (tableDate1 === null && tableDate2 === null) {
        return 0;
    }
    if (tableDate2 === null) {
        return -1;
    }
    if (tableDate1 === null) {
        return 1;
    }
    let date1 = transformedDate(tableDate1);
    let date2 = transformedDate(tableDate2);
    if (date1 > date2) {
        return -1;
    }
    if (date1 < date2) {
        return 1;
    }
    if (date1 === date2) {
        return 0;
    }
};
