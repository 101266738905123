import React, { useEffect, useMemo, useState } from 'react';
import FinanceSubNav from './FinanceSubNav';
import BankStatementItemsTable from './bankStatements/BankStatementItemsTable';
import PageHeader from '../components/heading/PageHeader';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';
import errorToast from '../components/messages/ErrorMessage';
import StatementsApi from '../api/bankStatements';
import ExpendituresApi from '../api/expenditures';

const BankStatementsPage = () => {
    const [bankStatements, setBankStatements] = useState([]);
    const [matchedExpenditures, setMatchedExpenditures] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const { data: statements } = await StatementsApi.fetchStatements();
                const { data: expenditures } = await ExpendituresApi.fetchAllMatchedExpenditures();
                setBankStatements(statements);
                setMatchedExpenditures(expenditures);
                setLoading(false);
            } catch (error) {
                errorToast(error);
            }
        };
        fetchData();
    }, []);

    const bankStatementTables = useMemo(
        () =>
            bankStatements.map((item) => (
                <BankStatementItemsTable key={item.id} statement={item} matchedExpenditures={matchedExpenditures} />
            )),
        [bankStatements, matchedExpenditures]
    );

    return (
        <div>
            <PageHeader title="Bank Statements" />
            <FinanceSubNav />
            <SubSubNav>
                <SubSubNavLink to="/finance/bankStatements" exact>
                    View Bank Statements
                </SubSubNavLink>
                <SubSubNavLink to="/finance/bankStatements/import">Import Bank Statement</SubSubNavLink>
                <SubSubNavLink to="/finance/bankStatements/review">Review &amp; Reconcile</SubSubNavLink>
            </SubSubNav>
            {loading ? <h2>Loading Bank Statements</h2> : bankStatementTables}
        </div>
    );
};

export default BankStatementsPage;
