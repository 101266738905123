// React
import React from 'react';

// Libraries
import { Formik, Form } from 'formik';
import moment from 'moment';
import _find from 'lodash/find';
import yup from '../../yup';

// Components
import errorToast from '../../components/messages/ErrorMessage';
import PagingTable from '../../components/tables/PagingTable';
import LabeledField from '../../components/forms/LabeledField';
import StrategicGoalsHistory from './StrategicGoalsHistory';
import { confirm } from '../../components/modals/ConfirmModal';

// API
import MetricsApi from '../../api/metrics';

class StrategicGoalsActualPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            metrics: [],
            filteredMetrics: [],
            metricsDropdown: [],
            metricYears: [],
            selectedYear: '',
            year: '',
            quartersForThisYear: ['1QFY', '2QFY', '3QFY', '4QFY'],
            metricHistory: [],
            rowToUpdate: null,
            forceSetData: false,
        };
        this.handleMetricInspection = this.handleMetricInspection.bind(this);
        this.handleNewMetricSubmit = this.handleNewMetricSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
    }

    async componentDidMount() {
        try {
            const { data: metrics } = await MetricsApi.fetchProjectMetrics(this.props.match.params.id);
            let metricsOptions = [];
            if (metrics.length > 0) {
                metrics.map((metric) => {
                    metricsOptions.push({
                        label: metric.strategic_metric.metric,
                        value: metric.id,
                    });
                    return null;
                });
            }

            let metricYears = [];
            if (metrics) {
                metrics.forEach((metric) => {
                    metricYears.push(metric.strategic_metric.metric_year);
                });
                metricYears = metricYears
                    .filter((year, pos) => metricYears.indexOf(year) === pos)
                    .sort((a, b) => a - b);

                for (let i = 0; i < metricYears.length; i++) {
                    metricYears[i] = {
                        label: metricYears[i],
                        value: metricYears[i],
                    };
                }
                metricYears.unshift({ label: 'All', value: null });
            }

            metrics.sort((a, b) => b.strategic_metric.metric_year - a.strategic_metric.metric_year);

            this.setState(
                {
                    metrics,
                    filteredMetrics: metrics,
                    metricsDropdown: metricsOptions,
                    metricYears,
                    forceSetData: true,
                },
                () => {
                    this.setState({
                        forceSetData: false,
                    });
                }
            );
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    handleEdit(event) {
        this.setState({
            rowToUpdate: event.node.data,
        });
    }

    async handleMetricInspection(params) {
        try {
            const { data: metricHistory } = await MetricsApi.fetchProjectAchievements(
                params.data.id,
                params.column.colId.substring(1, 2)
            );
            this.setState({
                metricHistory,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    async handleNewMetricSubmit(values, actions) {
        if (this.state.rowToUpdate) {
            try {
                let achievement = this.state.metricHistory.filter(
                    (metricHistory) =>
                        values.metric.value === metricHistory.project_metric_id &&
                        metricHistory.quarter === parseInt(values.quarter.substring(0, 1), 10)
                )[0];

                let achievementId = achievement.id;
                let projectMetricId = achievement.project_metric_id;

                let { data: changedAchievement } = await MetricsApi.updateProjectAchievement(
                    projectMetricId,
                    achievementId,
                    values.note,
                    parseInt(values.actual, 10)
                );
                let unchangedAchievements = this.state.metricHistory.filter(
                    (history) => history.id !== changedAchievement.id
                );

                let newMetric = changedAchievement.project_metric;
                let unchangedMetrics = this.state.metrics.filter((metric) => metric.id !== newMetric.id);

                this.setState(
                    {
                        metrics: [...unchangedMetrics, newMetric],
                        filteredMetrics: [...unchangedMetrics, newMetric],
                        metricHistory: [...unchangedAchievements, changedAchievement],
                        forceSetData: true,
                        rowToUpdate: null,
                    },
                    () => {
                        this.setState({
                            forceSetData: false,
                        });
                    }
                );
                actions.resetForm();
            } catch (errorMessage) {
                errorToast(errorMessage);
            }
        } else {
            try {
                let { data: newMetric } = await MetricsApi.createProjectAchievement(
                    values.metric,
                    values.metric_year,
                    values.quarter.substring(0, 1),
                    values.note,
                    values.actual
                );
                newMetric = newMetric.project_metric;
                let unchangedMetrics = this.state.metrics.filter((metric) => metric.id !== newMetric.id);

                unchangedMetrics.push(newMetric);

                this.setState(
                    {
                        forceSetData: true,
                        metrics: unchangedMetrics,
                        filteredMetrics: unchangedMetrics,
                        metricHistory: [],
                        rowToUpdate: null,
                    },
                    () => {
                        this.setState({
                            forceSetData: false,
                        });
                    }
                );
                actions.resetForm();
            } catch (errorMessage) {
                errorToast(errorMessage);
            }
        }
    }

    onRemoveSelected = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to remove metric?',
        });

        if (confirmed) {
            let achievementId = event.data.id;
            let projectMetricId = event.data.project_metric_id;
            MetricsApi.removeProjectAchievement(projectMetricId, achievementId, event.data)
                .then((response) => {
                    let changedAchievement = response.data;
                    let unchangedMetrics = this.state.metrics.filter((metric) => metric.id !== changedAchievement.id);

                    this.setState(
                        {
                            metrics: [...unchangedMetrics, changedAchievement],
                            metricHistory: [],
                            rowToUpdate: null,
                            forceSetData: true,
                        },
                        () => {
                            this.setState({
                                forceSetData: false,
                            });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
    };

    filterMetricsByYear(year, type) {
        let filteredMetrics = [];
        let metrics = this.state.metrics;
        let quarters = this.state.quartersForThisYear;
        let newYear;

        if (year === null) {
            year = 'All';
            filteredMetrics = this.state.metrics;
            newYear = '';
            for (let i = 0; i < quarters.length; i++) {
                quarters[i] = quarters[i].substring(0, 4);
            }
        } else {
            if (year) {
                newYear = moment(String(year)).format('YY');
                newYear = String(parseInt(newYear, 10) + 1, 0);
            } else {
                newYear = '';
            }

            for (let i = 0; i < quarters.length; i++) {
                quarters[i] = quarters[i].substring(0, 4);
                quarters[i] += newYear;
            }

            metrics.forEach((metric) => {
                if (metric.strategic_metric.metric_year === year) {
                    filteredMetrics.push(metric);
                }
            });
        }

        let metricsOptions = [];
        if (filteredMetrics.length > 0) {
            filteredMetrics.map((metric) => {
                metricsOptions.push({
                    label: metric.strategic_metric.metric,
                    value: metric.id,
                });
                return metric;
            });
        }

        if (type === 'table') {
            this.setState(
                {
                    forceSetData: true,
                    year: newYear,
                    filteredMetrics,
                    quartersForThisYear: quarters,
                    metricsDropdown: metricsOptions,
                },
                () => {
                    this.setState({ forceSetData: false });
                }
            );
        } else {
            this.setState(
                {
                    quartersForThisYear: quarters,
                    year: newYear,
                    metricsDropdown: metricsOptions,
                },
                () => {
                    this.setState({ forceSetData: false });
                }
            );
        }
    }

    render() {
        if (!this.state.metrics) return <div>loading...</div>;

        let metric = '';
        let quarter = '';
        let actual = '';
        let note = '';
        let submitText = 'Add Metric';
        let isEditing = false;
        if (this.state.rowToUpdate) {
            isEditing = true;
            let rowToUpdate = this.state.rowToUpdate;

            if (rowToUpdate.project_metric_id) {
                metric = _find(this.state.metricsDropdown, {
                    value: rowToUpdate.project_metric_id,
                });
            }

            if (rowToUpdate.quarter) {
                quarter = rowToUpdate.quarter + 'QFY' + (rowToUpdate.fiscal_year - 2000).toString();
            }

            if (rowToUpdate.value) {
                actual = rowToUpdate.value;
            }

            if (rowToUpdate.note) {
                note = rowToUpdate.note;
            }

            submitText = 'Save Metric';
        }

        return (
            <>
                <div className="Project-metrics">
                    <div className="Double-layout">
                        <div className="Single-layout">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    metric_years: '',
                                    metric,
                                    quarter,
                                    actual,
                                    note,
                                }}
                                validationSchema={yup.object().shape({
                                    metric_years: yup.string().required(),
                                    metric: yup.string().required(),
                                    quarter: yup.string().required(),
                                    actual: yup.number().required(),
                                    note: yup.string(),
                                })}
                                onSubmit={this.handleNewMetricSubmit}
                            >
                                {({ errors, touched, isSubmitting, setFieldValue }) => (
                                    <Form>
                                        <LabeledField
                                            label="Year*"
                                            name="metric_years"
                                            component="select"
                                            options={this.state.metricYears}
                                            onChange={(e) => {
                                                this.filterMetricsByYear(e.value, 'form');
                                                setFieldValue('metric_years', e.label);
                                            }}
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <LabeledField
                                            label="Metric*"
                                            name="metric"
                                            component="select"
                                            options={this.state.metricsDropdown}
                                            errors={errors}
                                            touched={touched}
                                        />
                                        {isEditing ? (
                                            <LabeledField
                                                label="Quarter*"
                                                name="quarter"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                                readOnly={isEditing}
                                            />
                                        ) : (
                                            <LabeledField
                                                label="Quarter*"
                                                name="quarter"
                                                component="select"
                                                options={this.state.quartersForThisYear}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        )}
                                        <LabeledField
                                            label="Actual*"
                                            name="actual"
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <LabeledField
                                            label="Notes"
                                            name="note"
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            inputSpan={4}
                                        />
                                        <div className="Form__buttons--Reversed Buttons_format">
                                            <button type="submit" disabled={isSubmitting}>
                                                {submitText}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <StrategicGoalsHistory
                            history={this.state.metricHistory}
                            handleEdit={this.handleEdit}
                            onRemoveSelected={this.onRemoveSelected}
                            forceSetData={this.state.forceSetData}
                        />
                    </div>
                    <div className="Table-styling">
                        <div>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    metric_years: '',
                                }}
                                onSubmit={this.props.handleSubmit}
                            >
                                {({ errors, touched, setFieldValue }) => (
                                    <Form className="SGM-form">
                                        <LabeledField
                                            label="Year"
                                            name="metric_years"
                                            component="select"
                                            options={this.state.metricYears}
                                            onChange={(e) => {
                                                this.filterMetricsByYear(e.value, 'table');
                                                setFieldValue('metric_years', e);
                                            }}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <PagingTable
                            data={this.state.filteredMetrics}
                            columns={[
                                {
                                    headerName: 'Metric Year',
                                    field: 'strategic_metric.metric_year',
                                    type: 'shortnumber',
                                },
                                {
                                    headerName: 'Metric',
                                    field: 'strategic_metric.metric',
                                    type: 'longtext',
                                },
                                {
                                    headerName: '1QFY' + this.state.year,
                                    field: 'q1',
                                    onCellClicked: this.handleMetricInspection,
                                    cellStyle: {
                                        color: 'blue',
                                    },
                                    type: 'shortnumber',
                                },
                                {
                                    headerName: '2QFY' + this.state.year,
                                    field: 'q2',
                                    onCellClicked: this.handleMetricInspection,
                                    cellStyle: {
                                        color: 'blue',
                                    },
                                    type: 'shortnumber',
                                },
                                {
                                    headerName: '3QFY' + this.state.year,
                                    field: 'q3',
                                    onCellClicked: this.handleMetricInspection,
                                    cellStyle: {
                                        color: 'blue',
                                    },
                                    type: 'shortnumber',
                                },
                                {
                                    headerName: '4QFY' + this.state.year,
                                    field: 'q4',
                                    onCellClicked: this.handleMetricInspection,
                                    cellStyle: {
                                        color: 'blue',
                                    },
                                    type: 'shortnumber',
                                },
                                {
                                    headerName: 'Total',
                                    valueGetter: ({ data }) => `${data.q1 + data.q2 + data.q3 + data.q4}`,
                                    onCellClicked: this.handleMetricInspection,
                                    cellStyle: {
                                        color: 'blue',
                                    },
                                    type: 'shortnumber',
                                },
                            ]}
                            filter={true}
                            sortable={true}
                            tableClassName="Goals-table"
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default StrategicGoalsActualPage;
