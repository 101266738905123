import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import OverviewPage from './OverviewPage';
import DataPage from './DataPage';
import BudgetAndFinancialPage from './BudgetAndFinancialPage';
import SchedulePage from './SchedulePage';
import ReportsPage from './ReportsPage';
import ExpendituresAndInvoicesPage from './ExpendituresAndInvoicesPage';
import StrategicGoalsPage from './strategic-goals/StrategicGoalsActualPage';
import TransferFundsPage from './TransferFundsPage';
import TransferFundsApprovalPage from './transferFunds/TransferFundsApprovalPage';
import TravelPage from '../travel';
import CloseoutPage from './CloseoutPage';
import SubNav, { SubNavLink } from '../components/navigation/SubNav';
import errorToast from '../components/messages/ErrorMessage';
import AgreementsProjectPage from '../vendorsAndAgreements/AgreementsProjectPage';
import VendorViewPage from '../vendorsAndAgreements/VendorViewPage';
import AgreementViewPage from '../vendorsAndAgreements/AgreementViewPage';
import ProjectsApi from '../api/projects';

class ProjectPage extends Component {
    constructor() {
        super();
        this.state = {
            project: {},
        };
    }

    componentDidMount = async () => {
        if (
            this.props.match.params.id !== 'new' &&
            this.props.match.params.id !== 'vendor' &&
            this.props.match.params.id !== 'agreement'
        ) {
            try {
                const { data: project } = await ProjectsApi.fetchProject(this.props.match.params.id);
                this.setState({ project });
            } catch (errorMessage) {
                errorToast(errorMessage);
            }
        }
    };

    componentDidUpdate = async (prevProps) => {
        if (
            typeof this.props.match.params.id === 'string' &&
            typeof prevProps.match.params.id === 'string' &&
            this.props.match.params.id !== 'vendor' &&
            this.props.match.params.id !== 'agreement' &&
            this.props.match.params.id !== prevProps.match.params.id
        ) {
            try {
                const { data: project } = await ProjectsApi.fetchProject(this.props.match.params.id);
                this.setState({ project });
            } catch (errorMessage) {
                errorToast(errorMessage);
            }
        }
    };

    render() {
        const { match } = this.props;
        const { project } = this.state;
        const isArchived = project.status === 'archived';
        const isNewProject = match.params.id === 'new';
        let updateInfoMessage;

        if (project.last_update_by) {
            updateInfoMessage = 'Updated by ' + project.last_update_by + ' on ' + project.updated_on;
            updateInfoMessage = '';
        }

        const showVandA = process.env.REACT_APP_VandA_TOGGLE !== 'false';

        return (
            <>
                <h1>{isNewProject ? 'New Project ' : project.name}</h1>
                <p className="Project-number">{project.number}</p>
                <p className="Project-number">{updateInfoMessage}</p>

                <SubNav>
                    <SubNavLink to={match.url} exact disabled={isNewProject}>
                        Project Overview
                    </SubNavLink>
                    <SubNavLink to={match.url + '/data'} disabled={isNewProject}>
                        Project Data
                    </SubNavLink>
                    <SubNavLink to={match.url + '/budget/summary'} disabled={isNewProject}>
                        Budget & Financial Summary
                    </SubNavLink>
                    {showVandA ? (
                        <SubNavLink to={match.url + '/vendorsandagreements'} exact>
                            Vendors & Agreements
                        </SubNavLink>
                    ) : (
                        <SubNavLink to={match.url + '/sub-contractors'} disabled={isNewProject}>
                            Sub-Contracts & Agreements
                        </SubNavLink>
                    )}

                    <SubNavLink to={match.url + '/expenditures'} disabled={isNewProject}>
                        Expenditures
                    </SubNavLink>
                    <SubNavLink to={match.url + '/schedule'} disabled={isNewProject}>
                        Schedule
                    </SubNavLink>
                    <SubNavLink to={match.url + '/reports'} disabled={isNewProject}>
                        Project & Activity Reports
                    </SubNavLink>
                    <SubNavLink to={match.url + '/strategic-goals'} disabled={isNewProject}>
                        Strategic Goals & Metrics
                    </SubNavLink>
                    <SubNavLink to={match.url + '/transfer-funds'} disabled={isNewProject}>
                        Transfer Project Funds
                    </SubNavLink>
                    <SubNavLink to={match.url + '/travel'} disabled={isNewProject}>
                        Travel
                    </SubNavLink>
                    <SubNavLink to={match.url + '/closeout'} disabled={isNewProject}>
                        Project Closeout Checklist
                    </SubNavLink>
                </SubNav>

                <Switch>
                    <Route
                        path={match.path}
                        exact
                        render={(props) =>
                            isNewProject ? <Redirect to={match.url + '/data'} /> : <OverviewPage {...props} />
                        }
                    />
                    <Route path={match.path + '/data'} component={DataPage} />
                    <Route path={match.path + '/budget'} component={BudgetAndFinancialPage} />
                    <Route path={match.path + '/vendorsandagreements'} component={AgreementsProjectPage} />
                    <Route path="/projects/:projectID/vendor/:vendorID" component={VendorViewPage} />
                    <Route path="/projects/agreement/:id" component={AgreementViewPage} />
                    <Route path={match.path + '/expenditures'} component={ExpendituresAndInvoicesPage} />
                    <Route path={match.path + '/schedule'} component={SchedulePage} />
                    <Route path={match.path + '/reports'} component={ReportsPage} />
                    <Route path={match.path + '/strategic-goals'} component={StrategicGoalsPage} />
                    <Route path={match.path + '/transfer-funds'} exact component={TransferFundsPage} />
                    <Route path={match.path + '/transfer-funds/approval'} exact component={TransferFundsApprovalPage} />
                    <Route
                        path={match.path + '/travel'}
                        render={(props) => <TravelPage {...props} isProject={true} isArchived={isArchived} />}
                    />
                    <Route path={match.path + '/closeout'} component={CloseoutPage} />
                </Switch>
            </>
        );
    }
}

export default ProjectPage;
