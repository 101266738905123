// Libraries
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import AuthService from '../auth/auth.service';

export const getHeaders = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `${AuthService.getToken()}`,
    idtoken: `${AuthService.getIdToken()}`,
    refreshtoken: `${AuthService.getRefreshToken()}`,
});

const checkHeaders = (data) => {
    const headers = data.config.headers;
    return (
        headers.Authorization === getHeaders().Authorization &&
        headers.idtoken === getHeaders().idtoken &&
        headers.refreshtoken === getHeaders().refreshtoken
    );
};

export default {
    displayError(message) {
        toastr.error(message, 'Data Access Error');
    },

    get(route, errMessage, errorHandler = false, params = null) {
        return axios
            .get(route, {
                headers: getHeaders(),
                params,
            })
            .then((response) => {
                if (checkHeaders(response) === false) {
                    if (response.config.headers.Authorization) {
                        AuthService.setToken(response.config.headers.Authorization);
                    }
                    if (response.config.headers.idtoken) {
                        AuthService.setRefreshToken(response.config.headers.idtoken);
                    }
                    if (response.config.headers.refreshtoken) {
                        AuthService.setIdToken(response.config.headers.refreshtoken);
                    }
                }

                return response;
            })
            .catch((err) => {
                let message = errMessage;
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }

                if (!errorHandler) {
                    this.displayError(message);
                }

                throw message;
            });
    },

    post_form_data(route, formData, errMessage, errorHandler = false) {
        return axios({
            method: 'post',
            url: route,
            data: formData,
            headers: {
                ...getHeaders(),
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
        })
            .then((response) => {
                if (checkHeaders(response) === false) {
                    if (response.config.headers.Authorization) {
                        AuthService.setToken(response.config.headers.Authorization);
                    }
                    if (response.config.headers.idtoken) {
                        AuthService.setRefreshToken(response.config.headers.idtoken);
                    }
                    if (response.config.headers.refreshtoken) {
                        AuthService.setIdToken(response.config.headers.refreshtoken);
                    }
                }
                return response;
            })
            .catch((err) => {
                let message = errMessage;
                if (err && err.response && err.response.data) {
                    let errorMessageObject = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err.response.data))
                    );
                    message = errorMessageObject.message;
                }

                if (!errorHandler) {
                    this.displayError(message);
                }

                throw message;
            });
    },

    post(route, request, errMessage, errorHandler = false) {
        return axios
            .post(route, request, {
                headers: getHeaders(),
            })
            .then((response) => {
                if (checkHeaders(response) === false) {
                    if (response.config.headers.Authorization) {
                        AuthService.setToken(response.config.headers.Authorization);
                    }
                    if (response.config.headers.idtoken) {
                        AuthService.setRefreshToken(response.config.headers.idtoken);
                    }
                    if (response.config.headers.refreshtoken) {
                        AuthService.setIdToken(response.config.headers.refreshtoken);
                    }
                }
                return response;
            })
            .catch((err) => {
                let message = errMessage;
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }

                if (!errorHandler) {
                    this.displayError(message);
                }

                throw message;
            });
    },

    put(route, request, errMessage, errorHandler = false) {
        return axios
            .put(route, request, {
                headers: getHeaders(),
            })
            .then((response) => {
                if (checkHeaders(response) === false) {
                    if (response.config.headers.Authorization) {
                        AuthService.setToken(response.config.headers.Authorization);
                    }
                    if (response.config.headers.idtoken) {
                        AuthService.setRefreshToken(response.config.headers.idtoken);
                    }
                    if (response.config.headers.refreshtoken) {
                        AuthService.setIdToken(response.config.headers.refreshtoken);
                    }
                }
                return response;
            })
            .catch((err) => {
                let message = errMessage;
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }

                if (!errorHandler) {
                    this.displayError(message);
                }

                throw message;
            });
    },

    patch(route, request, errMessage, errorHandler = false) {
        return axios
            .patch(route, request || {}, {
                headers: getHeaders(),
            })
            .then((response) => {
                if (checkHeaders(response) === false) {
                    if (response.config.headers.Authorization) {
                        AuthService.setToken(response.config.headers.Authorization);
                    }
                    if (response.config.headers.idtoken) {
                        AuthService.setRefreshToken(response.config.headers.idtoken);
                    }
                    if (response.config.headers.refreshtoken) {
                        AuthService.setIdToken(response.config.headers.refreshtoken);
                    }
                }
                return response;
            })
            .catch((err) => {
                let message = errMessage;
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }

                if (!errorHandler) {
                    this.displayError(message);
                }

                throw message;
            });
    },

    delete(route, errMessage, errorHandler = false) {
        return axios
            .delete(route, {
                headers: getHeaders(),
            })
            .then((response) => {
                if (checkHeaders(response) === false) {
                    if (response.config.headers.Authorization) {
                        AuthService.setToken(response.config.headers.Authorization);
                    }
                    if (response.config.headers.idtoken) {
                        AuthService.setRefreshToken(response.config.headers.idtoken);
                    }
                    if (response.config.headers.refreshtoken) {
                        AuthService.setIdToken(response.config.headers.refreshtoken);
                    }
                }
                return response;
            })
            .catch((err) => {
                let message = errMessage;
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }

                if (!errorHandler) {
                    this.displayError(message);
                }

                throw message;
            });
    },
};
