import React, { Component } from 'react';

import { history } from '../store/store';
import AuthApi from '../api/auth';
import UserButton from '../components/buttons/UserButton';
import UserMessage from '../components/messages/UserMessage';

class LoginForm extends Component {
    constructor(props, context) {
        super(props, context);

        // let initialEmail = AuthService.getEmail() || '';

        this.state = {
            email: props.data.email,
            isbusy: false,
            password: props.data.password,
            remember: true,
            errorMessage: null,
            code: '',
            session: props.data.session,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(evt) {
        let changes = {};
        changes[evt.target.name] = evt.target.value;
        this.setState(changes);
    }

    onSubmit(event) {
        event.preventDefault();
        const { email, password } = this.state;
        this.setState({ errorMessage: null, isbusy: true }, () => {
            AuthApi.confirm_auth(email, password, this.state.code.trim(), this.state.session, this.state.remember)
                .then(() => {
                    this.setState({ errorMessage: null, isbusy: false });

                    let destination = localStorage.getItem('WBI.RETURN_LOCATION');
                    if (destination) {
                        localStorage.removeItem('WBI.RETURN_LOCATION');
                    } else {
                        destination = '/';
                    }
                    history.push(destination);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage, isbusy: false });
                });
        });
    }

    render() {
        return (
            <form onSubmit={this.onSubmit} className="LoginForm">
                <div className="AccountPage__form">
                    <div className="Form__iconfield">
                        <label htmlFor="smsCode" title="Enter the Code you received">
                            <i className="fa fa-envelope" />
                        </label>
                        <input
                            type="text"
                            name="code"
                            value={this.state.code}
                            onChange={this.onChange}
                            autoComplete="off"
                        />
                    </div>
                    <div className="Form__field hidden">
                        <label htmlFor="smsCode" title="Remember this device">
                            Remember Device
                            <input
                                type="checkbox"
                                checked={this.state.remember}
                                onChange={this.onChange}
                                name="remember"
                                autoComplete="off"
                            />
                        </label>
                    </div>

                    <div className="Form__buttons--Reversed-clean">
                        <UserButton
                            type="submit"
                            defaultText="Confirm Signin"
                            busyText="Signing in"
                            isbusy={this.state.isbusy}
                        />
                    </div>

                    <div className="Form__footer">
                        <UserMessage error={this.state.errorMessage} />
                    </div>
                </div>
            </form>
        );
    }
}

export default LoginForm;
