import React, { Component } from 'react';
import { Formik, Form, FieldArray as BasicFieldArray } from 'formik';

import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';
import CpoParagraphsTable from './cpo/CpoParagraphTable';

import PiaApi from '../api/pia';
import CpoApi from '../api/cpo';
import CpoParagraphsApi from '../api/cpoParagraphs';
import ProjectsApi from '../api/projects';

import { confirm } from '../components/modals/ConfirmModal';
import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';
import Modal from '../components/modals/Modal';
import errorToast from '../components/messages/ErrorMessage';

const getInitialCpoParagraphValues = () => ({
    number: '',
    paragraph: '',
});

class NewCpoPage extends Component {
    constructor(props) {
        super(props);

        this.getRowData = this.getRowData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.deleteParagraph = this.deleteParagraph.bind(this);
        this.deleteCpo = this.deleteCpo.bind(this);
        this.finishEditing = this.finishEditing.bind(this);
        this.onParagraphChange = this.onParagraphChange.bind(this);

        this.state = {
            newParagraphs: '',
            pias: null,
            cpos: [],
            cpoParagraphs: null,
            projects: null,
            showCpoModal: false,
            row: null,
            rowIndex: null,
            colKey: [],
            openedCpo: {},
            quickViewParagraphs: [],
            splice: null,
            columns: [
                {
                    headerName: 'PIA',
                    field: 'pia_name',
                    editable: false,
                    type: 'number',
                },
                {
                    headerName: 'PIA Ceiling Amount',
                    field: 'pia_ceiling_amount',
                    editable: false,
                    type: 'money',
                },
                {
                    headerName: 'CPO',
                    field: 'number',
                    editable: true,
                    sort: 'desc',
                    type: 'number',
                },
                {
                    headerName: 'Contract No.',
                    field: 'contract_number',
                    editable: true,
                    type: 'idNum',
                },
                {
                    headerName: 'CPO Ceiling Amount',
                    field: 'ceiling_amount',
                    editable: true,
                    type: 'money',
                },
                {
                    headerName: 'Applicable CPO Reference Paragraphs',
                    field: 'cpoParagraphs',
                    type: 'longtext',
                    editable: false,
                    cellStyle: { color: '#5B86B0' },
                    onCellClicked: this.openModal,
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.deleteCpo,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: pias } = await PiaApi.getPias();
            const { data: cpos } = await CpoApi.getCpos();
            const { data: cpoParagraphs } = await CpoParagraphsApi.getCPOparagraphs();
            const { data: projects } = await ProjectsApi.fetchProjects();

            cpos.forEach((cpo) => {
                if (cpo.pia_id) {
                    pias.forEach((pia) => {
                        if (pia.id === cpo.pia_id) {
                            cpo.pia_name = pia.name;
                            cpo.pia_ceiling_amount = pia.ceiling_amount;
                        }
                    });
                }

                let applicableCPOparagraphs = [];
                cpoParagraphs.forEach((paragraph) => {
                    if (cpo.id === paragraph.cpo_id) {
                        applicableCPOparagraphs.push(paragraph.number);
                    }
                });
                cpo.cpoParagraphs = applicableCPOparagraphs;
            });

            this.setState({
                pias,
                cpos,
                projects,
                cpoParagraphs,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    getRowData = (event) => {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'number',
        });
    };

    onRowValueChanged = (event) => {
        let cpoToUpdate = event.node.data;

        if (cpoToUpdate) {
            CpoApi.updateCpo(cpoToUpdate.id, {
                number: cpoToUpdate.number,
                client: cpoToUpdate.client,
                contract_number: cpoToUpdate.contract_number,
                ceiling_amount: cpoToUpdate.ceiling_amount,
            })
                .then(() => {
                    //
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    deleteCpo = async (event) => {
        let cpoToRemove = event.node.data;
        let item = this.state.projects.find((ele) => ele.cpo_id === cpoToRemove.id);
        if (item) {
            await confirm({
                text: `This CPO has been assigned to Project: ${item.name}, if you want delete it, you must reset the Project's assigned CPO`,
                acceptText: null,
                rejectText: 'Cancel',
            });
        } else {
            const confirmed = await confirm({
                text: 'Are you sure you want to remove CPO?',
            });

            if (confirmed) {
                CpoApi.removeCPO(cpoToRemove.id)
                    .then(() => {
                        if (cpoToRemove.cpoParagraphs.length >= 1) {
                            cpoToRemove.cpoParagraphs.forEach((ele) => {
                                let item = this.state.cpoParagraphs.find((el) => el.number === ele);
                                CpoParagraphsApi.removeCPOparagraph(item.id).catch((errorMessage) => {
                                    this.setState({
                                        errorMessage,
                                    });
                                });
                            });
                        }

                        let cpos = this.state.cpos;
                        let newCPOs = cpos.filter((item, i) => cpoToRemove.id !== item.id);
                        this.setState({
                            cpos: newCPOs,
                        });
                    })
                    .catch((errorMessage) => {
                        this.setState({ errorMessage });
                    });
            }
        }
    };

    openModal = (event) => {
        let data = event.data.cpoParagraphs;
        let paragraphs = [];

        data.forEach((d) => {
            let item = this.state.cpoParagraphs.find((el) => el.number === d);
            paragraphs.push(item);
        });

        this.setState({
            showCpoModal: true,
            openedCpo: event.data,
            quickViewParagraphs: paragraphs,
            row: null,
            rowIndex: null,
            colKey: [],
        });
    };

    closeModal = () => {
        this.setState({
            showCpoModal: false,
            openedCpo: {},
            row: null,
            rowIndex: null,
            colKey: [],
        });
    };

    deleteParagraph = async (event) => {
        let paragraphToRemove = event.data.id;
        let cpoId = event.data.cpo_id;
        if (paragraphToRemove) {
            CpoParagraphsApi.removeCPOparagraph(paragraphToRemove)
                .then((response) => {
                    if (response.status === 200) {
                        let cpos = this.state.cpos;

                        let quickViewParagraphs = this.state.quickViewParagraphs;

                        let newQuickViewParagraphs = quickViewParagraphs.filter(
                            (item) => paragraphToRemove !== item.id
                        );
                        let arr = [];
                        newQuickViewParagraphs.forEach((el) => {
                            arr.push(el.number);
                        });
                        cpos.forEach((cpo) => {
                            if (cpo.id === cpoId) {
                                cpo.cpoParagraphs = arr;
                            }
                        });

                        this.setState({
                            cpos,
                            quickViewParagraphs: newQuickViewParagraphs,
                        });
                    }
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    onParagraphChange = (event) => {
        let paragraphToUpdate = event.node.data;
        paragraphToUpdate = event.data;
        if (paragraphToUpdate) {
            CpoParagraphsApi.updateCPOparagraph(paragraphToUpdate.id, {
                number: paragraphToUpdate.number,
                paragraph: paragraphToUpdate.paragraph,
            })
                .then((result) => {
                    if (result.status !== 200) {
                        this.setState({ errorMessage: 'not 200s' });
                    }
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    handleSubmit = async (values, actions) => {
        try {
            if (values) {
                CpoApi.createCpo({
                    number: values.number,
                    pia_id: values.pia_id,
                    contract_number: values.contract_number,
                    ceiling_amount: values.ceiling_amount,
                    paragraphs: values.newParagraphs,
                })
                    .then((response) => {
                        let cpos = this.state.cpos;
                        let newCpo = response.data;
                        let allParagraphs = this.state.cpoParagraphs;
                        newCpo.cpoParagraphs = [];

                        this.state.pias.forEach((pia) => {
                            if (pia.id === newCpo.pia_id) {
                                newCpo.pia_name = pia.name;
                                newCpo.pia_ceiling_amount = pia.ceiling_amount;
                            }
                        });

                        cpos.push(newCpo);
                        this.setState(
                            {
                                cpos,
                                cpoParagraphs: allParagraphs,
                            },
                            () => {
                                actions.setSubmitting(false);
                                actions.resetForm();
                                window.location.reload(true);
                                // ****** Due to formik to get the paragraph to load into the table after creation a relod is required
                            }
                        );
                    })
                    .catch((errorMessage) => {
                        this.setState({
                            errorMessage,
                        });
                    });
            }
        } catch (errorMessage) {
            this.setState({ errorMessage });
        }
    };

    handleParagraphSubmit = async (values, actions) => {
        let cpos = this.state.cpos;
        let cpo = this.state.openedCpo;
        let idx = cpos.findIndex((el) => el === cpo);
        let quickViewParagraphs = this.state.quickViewParagraphs;
        let allParagraphs = [];
        if (values) {
            if (values.newParagraphs) {
                for (let i = 0; i < values.newParagraphs.length; i++) {
                    const { data: created } = await CpoParagraphsApi.createCPOparagraph({
                        cpo_id: cpo.id,
                        number: values.newParagraphs[i].number,
                        paragraph: values.newParagraphs[i].paragraph,
                    });
                    cpo.cpoParagraphs.push(created.number);
                    quickViewParagraphs.push(created);
                    allParagraphs.push(created);
                }
            }
        }

        cpos[idx].cpoParagraphs = cpo.cpoParagraphs;
        actions.resetForm();

        this.setState(
            {
                cpos,
                quickViewParagraphs,
            },
            () => {
                actions.setSubmitting(false);
            }
        );
    };

    finishEditing() {
        this.setState({
            row: null,
            rowIndex: null,
            colKey: [],
        });
    }

    render() {
        let piaOptions = [];
        const { showCpoModal, quickViewParagraphs, cpos, columns, rowIndex, colKey } = this.state;

        if (this.state.pias) {
            this.state.pias.forEach((pia) => {
                piaOptions.push({ label: pia.name, value: pia.id });
            });
            piaOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        return (
            <div>
                <PageHeader title="New CPO" />
                <AdminSubNav />
                <div>
                    <div className="Single-layout Companion-form">
                        <Formik
                            initialValues={{
                                pia_id: '',
                                number: '',
                                contract_number: '',
                                ceiling_amount: '',
                                newParagraphs: [getInitialCpoParagraphValues()],
                            }}
                            validationSchema={yup.object().shape({
                                pia_id: yup.string().required(),
                                number: yup.string().required(),
                                contract_number: yup.string().required(),
                                ceiling_amount: yup.number().required(),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({ values, errors, touched, resetForm }) => (
                                <Form>
                                    <LabeledField
                                        label="PIA*"
                                        name="pia_id"
                                        component="select"
                                        options={piaOptions}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="CPO*"
                                        name="number"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Contract No.*"
                                        name="contract_number"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="CPO Ceiling Amount*"
                                        name="ceiling_amount"
                                        type="text"
                                        component="Money"
                                        errors={errors}
                                        touched={touched}
                                    />

                                    <div className="Space-in-form" />
                                    <div className="Space-in-form" />
                                    <div className="Space-in-form" />

                                    <BasicFieldArray
                                        className="acrn_block"
                                        name="newParagraphs"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.newParagraphs.map((newParagraph, index) => (
                                                    <React.Fragment key={index}>
                                                        <LabeledField
                                                            label="SOW Paragraph No."
                                                            name={`newParagraphs.${index}.number`}
                                                            type="text"
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                        <LabeledField
                                                            label="Paragraph"
                                                            name={`newParagraphs.${index}.paragraph`}
                                                            type="text"
                                                            errors={errors}
                                                            component="textarea"
                                                            touched={touched}
                                                        />
                                                    </React.Fragment>
                                                ))}

                                                <div className="Form__buttons--Reversed Buttons_format">
                                                    <button
                                                        type="button"
                                                        className="add_button"
                                                        onClick={() =>
                                                            arrayHelpers.push(getInitialCpoParagraphValues())
                                                        }
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    />

                                    <div className="In-form-table">
                                        <CpoParagraphsTable cpoParagraphs={values.newParagraphs} />
                                    </div>
                                    <div className="Form__buttons--Reversed Buttons_format">
                                        <button type="button" onClick={() => resetForm()}>
                                            Cancel
                                        </button>
                                        <button type="submit">Add CPO</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="Table-styling">
                        <EditingTable
                            columns={columns}
                            data={cpos}
                            rowIndex={rowIndex}
                            animateRows={true}
                            sizeToFit={true}
                            colKey={colKey}
                            cellEditingStopped={this.finishEditing}
                            rowValueChanged={this.onRowValueChanged.bind(this)}
                            pagingProp={true}
                        />
                    </div>
                </div>

                <Modal
                    isOpen={showCpoModal}
                    handleClose={this.closeModal}
                    shouldCloseOnOverlayClick
                    render={({ handleClose }) => (
                        <>
                            <div className="Cpo-modal-container">
                                <div className="Companion-form">
                                    <Formik
                                        initialValues={{
                                            newParagraphs: [getInitialCpoParagraphValues()],
                                        }}
                                        validationSchema={yup.object().shape({
                                            number: yup.string(),
                                        })}
                                        onSubmit={this.handleParagraphSubmit}
                                    >
                                        {({ values, errors, touched, resetForm }) => (
                                            <Form>
                                                <BasicFieldArray
                                                    className="acrn_block"
                                                    name="newParagraphs"
                                                    render={(arrayHelpers) => (
                                                        <>
                                                            {values.newParagraphs.map((newParagraph, index) => (
                                                                <React.Fragment key={index}>
                                                                    <LabeledField
                                                                        label="SOW Paragraph No."
                                                                        name={`newParagraphs.${index}.number`}
                                                                        type="text"
                                                                        errors={errors}
                                                                        touched={touched}
                                                                    />
                                                                    <LabeledField
                                                                        label="Paragraph"
                                                                        name={`newParagraphs.${index}.paragraph`}
                                                                        type="text"
                                                                        errors={errors}
                                                                        component="textarea"
                                                                        touched={touched}
                                                                    />
                                                                </React.Fragment>
                                                            ))}

                                                            <div className="Form__buttons--Reversed Buttons_format">
                                                                <button
                                                                    type="button"
                                                                    className="add_button"
                                                                    onClick={() =>
                                                                        arrayHelpers.push(
                                                                            getInitialCpoParagraphValues()
                                                                        )
                                                                    }
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                                <div className="In-form-table">
                                                    <CpoParagraphsTable cpoParagraphs={values.newParagraphs} />
                                                </div>
                                                <div className="In-form-table">
                                                    <EditingTable
                                                        data={quickViewParagraphs}
                                                        rowValueChanged={this.onParagraphChange}
                                                        columns={[
                                                            {
                                                                headerName: 'No.',
                                                                field: 'number',
                                                                editable: false,
                                                                width: 75,
                                                            },
                                                            {
                                                                headerName: 'Paragraph',
                                                                field: 'paragraph',
                                                                editable: true,
                                                                type: 'longtext',
                                                            },
                                                            {
                                                                headerName: 'Edit',
                                                                field: 'edit',
                                                                type: 'symbol',
                                                                editable: false,
                                                                cellRenderer: 'EditSymbolRenderer',
                                                                onCellClicked: this.getRowData,
                                                            },
                                                            {
                                                                headerName: 'Del.',
                                                                field: 'delete',
                                                                type: 'symbol',
                                                                editable: false,
                                                                cellRenderer: 'TrashSymbolRenderer',
                                                                onCellClicked: this.deleteParagraph,
                                                            },
                                                        ]}
                                                        rowIndex={rowIndex}
                                                        colKey={colKey}
                                                        cellEditingStopped={this.finishEditing}
                                                    />
                                                </div>
                                                <div className="Form__buttons--Reversed Buttons_format">
                                                    <button onClick={handleClose}>Close</button>
                                                    <button type="button" onClick={() => resetForm()}>
                                                        Cancel
                                                    </button>
                                                    <button type="submit">Add Paragraphs</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </>
                    )}
                />
            </div>
        );
    }
}

export default NewCpoPage;
