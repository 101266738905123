import React from 'react';

const TrashSymbolRenderer = (params) => {
    let visiblityMode = false;
    if (params.type === 'creationCheck') {
        if (params.data.change_type === 'Agreement Created') {
            visiblityMode = true;
        }
    }
    if (visiblityMode) {
        return (
            <div className="trashSymbolHidden">
                <svg viewBox="0 0 24 24">
                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                </svg>
            </div>
        );
    }
    return (
        <div className="TrashSymbol">
            <svg viewBox="0 0 24 24">
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
            </svg>
        </div>
    );
};

export default TrashSymbolRenderer;
