// React
import React, { Component } from 'react';

// Components
import PagingTable from '../components/tables/PagingTable';
import errorToast from '../components/messages/ErrorMessage';

// API
import ProjectsApi from '../api/projects';

class StatusUpdate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statuses: null,
            service: props.service,
            columns: [
                {
                    headerName: 'Last Update',
                    field: 'date',
                    type: 'date',
                },
                {
                    headerName: 'Project Name',
                    field: 'project_name',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'project_id',
                    },
                    type: 'mediumtext',
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    cellRenderer: 'StatusSymbolRenderer',
                    type: 'symbol',
                },
                {
                    headerName: 'Status Update',
                    field: 'description',
                    type: 'longtext',
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator',
                    type: 'name',
                    editable: false,
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                    editable: false,
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_update_by',
                    type: 'shortname',
                    editable: false,
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                    editable: false,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            let { data: statuses } = await ProjectsApi.fetchAllRecentStatusUpdates();

            if (this.state.service) {
                statuses = statuses.filter((status) => status.service.id === this.state.service.id);
            }

            let stop = statuses.filter((ele) => ele.status.toUpperCase() === 'STOP');
            let caution = statuses.filter((ele) => ele.status.toUpperCase() === 'CAUTION');
            let good = statuses.filter((ele) => ele.status.toUpperCase() === 'GOOD');

            let statusSymbols = stop
                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                .concat(caution.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()))
                .concat(good.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()));

            this.setState({
                statuses: statusSymbols,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    render() {
        const { statuses } = this.state;
        if (!statuses) return <div>loading...</div>;

        return (
            <div>
                <PagingTable
                    columns={this.state.columns}
                    data={statuses}
                    pageSizeProp={7}
                    sortable={true}
                    filter={true}
                    tableClassName="Ed-tables"
                />
            </div>
        );
    }
}

export default StatusUpdate;
