import React, { useState } from 'react';
import SelectionTable from '../../components/tables/SelectionTable';
import Checkbox from '../../components/buttons/Checkbox';
import ExpendituresApi from '../../api/expenditures';

const toggleMatchableAndDisplay = async (event) => {
    const matchedExpenditure = event.data;
    const { gridApi } = event.node;

    if (matchedExpenditure.matchable) {
        gridApi.updateRowData({ remove: [event.data] });
        await ExpendituresApi.markExpenditureAsUnmatchable(matchedExpenditure.id);
    } else {
        await ExpendituresApi.markExpenditureAsMatchable(matchedExpenditure.id);
    }
    matchedExpenditure.matchable = !matchedExpenditure.matchable;
    event.api.onFilterChanged();
    event.api.redrawRows({ rowNodes: [event.node] });
};

const CreditCardItemReviewTableColumns = [
    {
        type: 'checkbox',
    },
    {
        headerName: 'Card Holder',
        field: 'cardHolder',
        type: 'name',
    },
    {
        headerName: 'Trans. Date',
        field: 'purchase_date',
        sort: 'asc',
        type: 'date',
    },
    {
        headerName: 'Amount',
        field: 'cost',
        type: 'money',
    },
    {
        headerName: 'Vendor',
        field: 'store',
        type: 'name',
    },
    {
        headerName: 'Unmatchable?',
        field: 'hide',
        type: 'name',
        filter: true,
        cellRenderer: (params) => {
            if (params.data.matchable) {
                return '<a>Hide</a>';
            }
            return '<a>Show</a>';
        },
        onCellClicked: (e) => {
            toggleMatchableAndDisplay(e);
        },
    },
];

const CreditCardItemReviewTable = ({ records, hoistSelectedCreditCardItem, loadAllExpenditures }) => {
    const [showUnmatchable, setShowUnmatchable] = useState(true);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const isExternalFilterPresent = () => showUnmatchable !== true;

    const handleSelectionChange = (event) => {
        const result = event.api.getSelectedNodes();
        hoistSelectedCreditCardItem(result[0]);
    };

    const doesExternalFilterPass = (node) => {
        switch (showUnmatchable) {
            case true:
                return node.data.matchable !== false;
            default:
        }
    };

    const handleLoadAllExpenditures = () => {
        loadAllExpenditures();
    };

    return (
        <div>
            <SelectionTable
                columns={CreditCardItemReviewTableColumns}
                data={records}
                doesExternalFilterPass={doesExternalFilterPass}
                isExternalFilterPresent={isExternalFilterPresent}
                filter={true}
                pagingProp={true}
                selectionChanged={handleSelectionChange}
                selectionType="single"
                sortable={true}
                sizeToFit={true}
                tableClassName="Review-bank-statements-table"
            />
            {/* <Checkbox
                style={{ zIndex: 1, fontSize: 14 }}
                name="matchable"
                component="Checkbox"
                label="Show Unmatchable?"
                onChange={() => handleLoadAllExpenditures()}
            /> */}
        </div>
    );
};

export default CreditCardItemReviewTable;

// gridOptions.isExternalFilterPresent = () => {return true;}
// gridOptions.doesExternalFilterPass = (node) => {
//     return gridOptions.api.getValue("isVisible", node)
// }
