// React
import React from 'react';

// Components
import Fieldset from '../Fieldset';
import LabeledField from '../../components/forms/LabeledField';
import SelectionTable from '../../components/tables/SelectionTable';
import Modal from '../../components/modals/Modal';
import KeyGoalsTable from './tables/KeyGoalsTable';
import TasksTable from './tables/TasksTable';
import DeliverablesTable from './tables/DeliverablesTable';
import MilestonesTable from './tables/MilestonesTable';
import StatusUpdatesTable from './tables/StatusUpdatesTable';

class ProjectDescriptionSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCpoModal: false,
            selectedParagraphs: null,
            rowData: [],
            gridApi: null,
        };
        this.onGridReady = this.onGridReady.bind(this);
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.forEachNode((node) => {
            this.props.selectedCPOparagraph.forEach((p) => {
                if (p.id === node.data.id) {
                    this.gridApi.selectNode(node, true);
                }
            });
        });
    };

    handleSelection = (e) => {
        let selected = [];
        let listRowIndex = [];
        let nodeList = [];
        nodeList = e.api.getSelectedNodes();
        nodeList.forEach((node) => {
            listRowIndex.push(node.childIndex);
        });

        listRowIndex.forEach((i) => {
            selected.push(this.props.applicable_cpo_paragraphs[i]);
        });
        this.props.setSelectedCpoParagraph(selected, listRowIndex);

        return selected;
    };

    render() {
        const {
            values,
            errors,
            touched,
            isEditing,
            setFieldValue,
            projectId,
            applicable_cpo_paragraphs,
            selectedCPOparagraph,
            isNew,
        } = this.props;
        const { showCpoModal } = this.state;

        return (
            <div>
                <Fieldset title="Project Description">
                    <LabeledField
                        label="Brief Overview"
                        className="Text-area-space"
                        name="overview"
                        value={values.overview}
                        component="textarea"
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                    />
                    <LabeledField
                        label="Background"
                        className="Text-area-space"
                        name="background"
                        component="textarea"
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                    />
                    <label className="FieldLabel">Applicable CPO Reference Paragraphs</label>
                    <div className="FieldInputWrapper column-span-2 Select-cpo-btn-container" style={{ marginTop: 8 }}>
                        {selectedCPOparagraph
                            .map((p) => p.number)
                            .reduce((acc, curr, index) => (index === 0 ? curr : acc + ', ' + curr), '')}
                        {isEditing && (
                            <button
                                type="button"
                                className="Cpo-select-btn End-button End-button--and"
                                onClick={() => this.setState({ showCpoModal: true })}
                            >
                                Select
                            </button>
                        )}
                        <Modal
                            isOpen={showCpoModal}
                            handleClose={() => {
                                // Save selected items to the form state with setFieldValue
                                this.setState({ showCpoModal: false });
                            }}
                            style={{
                                'min-width': '65vw',
                                'max-width': '90vw',
                            }}
                            render={({ handleClose }) => (
                                <>
                                    <SelectionTable
                                        data={applicable_cpo_paragraphs}
                                        columns={[
                                            { type: 'checkbox', width: 50 },
                                            {
                                                headerName: 'No.',
                                                field: 'number',
                                                width: 75,
                                            },
                                            {
                                                headerName: 'Paragraph',
                                                field: 'paragraph',
                                                type: 'longtext',
                                            },
                                        ]}
                                        selectionChanged={(e) => {
                                            let selected = this.handleSelection(e);

                                            setFieldValue('applicable_cpo_paragraphs', selected);
                                        }}
                                        overrideGridReady={this.onGridReady}
                                        sizeToFit={true}
                                    />
                                    <button onClick={handleClose} className="align-self-end End-button Cpo-save-btn">
                                        Close
                                    </button>
                                </>
                            )}
                        />
                    </div>
                    {!isNew && (
                        <KeyGoalsTable
                            handleKeyGoals={this.props.handleKeyGoals}
                            key_goals={this.props.key_goals}
                            values={values}
                            errors={errors}
                            touched={touched}
                            isEditing={isEditing}
                            projectId={projectId}
                        />
                    )}
                    {!isNew && (
                        <TasksTable
                            handleTasks={this.props.handleTasks}
                            tasks={this.props.tasks}
                            values={values}
                            errors={errors}
                            touched={touched}
                            isEditing={isEditing}
                            projectId={projectId}
                        />
                    )}
                    {!isNew && (
                        <DeliverablesTable
                            handleDeliverables={this.props.handleDeliverables}
                            deliverables={this.props.deliverables}
                            values={values}
                            errors={errors}
                            touched={touched}
                            isEditing={isEditing}
                            projectId={projectId}
                        />
                    )}
                    {!isNew && (
                        <MilestonesTable
                            handleMilestones={this.props.handleMilestones}
                            milestones={this.props.milestones}
                            values={values}
                            errors={errors}
                            touched={touched}
                            isEditing={isEditing}
                            projectId={projectId}
                        />
                    )}
                    {!isNew && (
                        <StatusUpdatesTable
                            handleStatusUpdates={this.props.handleStatusUpdates}
                            status_updates={this.props.status_updates}
                            values={values}
                            errors={errors}
                            touched={touched}
                            isEditing={isEditing}
                            projectId={projectId}
                        />
                    )}
                </Fieldset>
            </div>
        );
    }
}

export default ProjectDescriptionSection;
