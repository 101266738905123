import React from 'react';

const UserButton = (props) => {
    let icon = null;
    let value = props.defaultText;
    if (props.icon) {
        icon = <i className={`fa ${props.icon}`} />;
    }

    if (props.isbusy) {
        value = props.busyText;
        icon = <i className="fa fa-circle-o-notch M2spin" />;
    }
    let className = 'Button';
    if (props.size) {
        className = `Button-${props.size}`;
    }
    return (
        <div className={className} onClick={props.onClick}>
            <button disabled={props.isbusy}>
                {' '}
                {icon} {value}{' '}
            </button>
        </div>
    );
};

export default UserButton;
