import React, { useCallback, useEffect, useState } from 'react';
import SectionHeading from '../../components/heading/SectionHeading';
import errorToast from '../../components/messages/ErrorMessage';
import EditingTable from '../../components/tables/EditingTable';
import useFunctionAsState from '../../hooks/useFunctionAsState';
import ExpendituresApi from '../../api/expenditures';
import UserApi from '../../api/users';
import CoreBudgetTypesApi from '../../api/coreBudgetTypes';
import { confirm } from '../../components/modals/ConfirmModal';
import { addNote } from '../../components/modals/AddNoteModal';
import CreditCardButtonBar from './CreditCardButtonBar';
import { prepareTableCardHolderOptions, prepareCardHolderOptions } from '../../utils/UserUtils';

const CreditCardTable = ({ isProject }) => {
    const [tableTitle, setTableTitle] = useState();
    const [ccEntries, setCCEntries] = useState([]);
    const [coreBudgetTypes, setCoreBudgetTypes] = useState([]);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [cardHolderOptions, setCardHolderOptions] = useState([]);
    const [cardHolderDisplayOptions, setCardHolderDisplayOptions] = useState([]);
    const [exportTableAsCsv, setExportTableAsCsv] = useFunctionAsState(() => {});
    const [removeSelectedRows, setRemoveSelectedRows] = useFunctionAsState(() => {});

    const onTableReady = ({ exportDataAsCsv }) => {
        if (exportDataAsCsv && typeof exportDataAsCsv === 'function') {
            setExportTableAsCsv(exportDataAsCsv);
        }
    };

    const removeRows = ({ removeSelected }) => {
        if (removeSelected && typeof removeSelected === 'function') {
            setRemoveSelectedRows(removeSelected);
        }
    };

    const handleApplyToAccount = useCallback(async () => {
        try {
            const confirmed = await confirm({
                text: 'Are you sure you want to apply this/these expenditure(s)?',
            });
            if (confirmed) {
                const ids = selectedNodes.map((node) => node.data.id);
                await ExpendituresApi.applyExpenditures(ids);
                for (const node of selectedNodes) {
                    node.setDataValue('status', 'Applied');
                    node.setSelected(false);
                }
                setSelectedNodes([]);
            }
        } catch (error) {
            errorToast(error);
        }
    }, [selectedNodes]);

    const handleReturnToSender = useCallback(async () => {
        try {
            const confirmed = await confirm({
                text: 'Are you sure you want to return this/these expenditure(s)?',
            });
            if (confirmed) {
                for (const node of selectedNodes) {
                    const notes = await addNote(node.data, 'Expenditure');
                    await ExpendituresApi.returnExpenditure(node.data.id, { notes });
                }
                removeSelectedRows();
                setSelectedNodes([]);
            }
        } catch (error) {
            errorToast(error);
        }
    }, [selectedNodes, removeSelectedRows]);

    const handleDeleteExpenditure = async (event) => {
        const {
            data: { id },
            data,
            api,
        } = event;
        try {
            await ExpendituresApi.removeExpenditures(id);
            api.applyTransaction({ remove: [data] });
        } catch (error) {
            errorToast(error);
        }
    };

    const hoistUpdatedRowData = async (data) => {
        try {
            const {
                id,
                account,
                purchase_date,
                cost,
                item_description,
                store,
                cardHolder,
                bank_reference,
                link_to_db,
                cc_type,
                approver,
            } = data;
            let { approverID, ccHolder_id, account_id } = data;
            if (approver) {
                approverID = approver.id;
            }
            if (account && !isProject) {
                let accountNumber;
                if (typeof account.number === 'number') {
                    accountNumber = account.number.toString();
                } else {
                    accountNumber = account.number;
                }
                let accountFound = coreBudgetTypes.find((cbt) => cbt.number.toString() === accountNumber);
                if (accountFound) {
                    account_id = accountFound.id;
                } else {
                    errorToast('Entered an invalid Core Fund No. ');
                }
            }
            if (cardHolder) {
                let cardHolderFound = cardHolderOptions.find((holder) => holder.label === cardHolder);
                ccHolder_id = cardHolderFound.value;
            }
            const attributes = {
                account_id,
                purchase_date,
                cost,
                item_description,
                store,
                ccHolder_id,
                bank_reference,
                link_to_db,
                cc_type,
                approver_id: approverID,
            };
            await ExpendituresApi.updateExpenditure(id, attributes);
        } catch (error) {
            errorToast(error);
        }
    };

    const hoistSelectedRows = (event) => {
        const nodes = event.api.getSelectedNodes();
        setSelectedNodes(nodes);
    };

    useEffect(() => {
        if (isProject === true) {
            setTableTitle('Project Credit Card Entries');
        } else {
            setTableTitle('Core Fund Credit Card Entries');
        }

        const fetchCCUser = async () => {
            try {
                const { data: users } = await UserApi.getUsers();
                setCardHolderOptions(prepareCardHolderOptions(users));
                setCardHolderDisplayOptions(prepareTableCardHolderOptions(users));
            } catch (error) {
                errorToast(error);
            }
        };

        const fetchCoreBudgetType = async () => {
            try {
                const { data: coreBudgetTypesData } = await CoreBudgetTypesApi.getCoreBudgetTypes();
                setCoreBudgetTypes(coreBudgetTypesData);
            } catch (error) {
                errorToast(error);
            }
        };

        const fetchNonProjectExpenditures = async () => {
            try {
                const { data } = await ExpendituresApi.fetchNonProjectExpenditures();
                setCCEntries(data);
            } catch (error) {
                errorToast(error);
            }
        };

        const fetchProjectExpenditures = async () => {
            try {
                const { data } = await ExpendituresApi.fetchProjectExpenditures();
                setCCEntries(data);
            } catch (error) {
                errorToast(error);
            }
        };

        if (isProject === true) {
            fetchProjectExpenditures();
        } else {
            fetchNonProjectExpenditures();
        }
        fetchCCUser();
        fetchCoreBudgetType();
    }, [isProject]);

    const columns = [
        { type: 'checkbox', editable: false },
        {
            headerName: 'Transaction Date',
            field: 'purchase_date',
            type: 'date',
            editable: true,
        },
        {
            hide: isProject,
            headerName: 'Core Fund No.',
            field: 'account.number',
            type: 'idNum',
            editable: true,
        },
        {
            hide: !isProject,
            headerName: 'Project No.',
            field: 'account.number',
            cellRenderer: 'LinkRenderer',
            cellRendererParams: {
                routeBase: '/projects/',
                routeKeyField: 'account_id',
            },
            type: 'idNum',
            editable: false,
        },
        {
            hide: !isProject,
            headerName: 'CPO No.',
            field: 'account.cpo_number',
            editable: false,
            type: 'mediumname',
        },
        {
            headerName: 'Cost',
            field: 'cost',
            type: 'shortmoney',
            editable: true,
        },
        {
            headerName: 'Description',
            field: 'item_description',
            type: 'shorttext',
            editable: false,
        },
        {
            headerName: 'Store',
            field: 'store',
            type: 'shorttext',
            editable: false,
        },
        {
            headerName: 'Card Holder',
            field: 'cardHolder',
            type: 'shortname',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: cardHolderDisplayOptions,
            },
            editable: true,
        },
        {
            headerName: 'Approval',
            field: 'approver.name',
            type: 'shortname',
            editable: false,
        },
        {
            headerName: 'Bank Statement',
            field: 'bank_reference',
        },
        {
            headerName: 'Status',
            field: 'status',
            type: 'shortname',
            editable: false,
        },
        {
            headerName: 'Link to DB',
            field: 'link_to_db',
            type: 'symbol',
            cellRenderer: 'ExternalLinkSymbolRenderer',
        },
        {
            headerName: 'CC Type',
            field: 'cc_type',
            type: 'shortname',
            editable: false,
        },
        {
            headerName: 'Initiated By',
            field: 'initiator',
            type: 'name',
            editable: false,
        },
        {
            headerName: 'Initiated On',
            field: 'created_on',
            type: 'date',
            editable: false,
        },
        {
            headerName: 'Last Update By',
            field: 'last_updated_by',
            type: 'name',
            editable: false,
        },
        {
            headerName: 'Updated On',
            field: 'updated_on',
            type: 'date',
            editable: false,
        },
        {
            headerName: 'Edit',
            field: 'edit',
            type: 'symbol',
            editable: false,
            cellRenderer: 'EditSymbolRenderer',
        },
        {
            headerName: 'Del.',
            field: 'delete',
            type: 'symbol',
            editable: false,
            cellRenderer: 'TrashSymbolRenderer',
            onCellClicked: handleDeleteExpenditure,
        },
    ];

    return (
        <div>
            <SectionHeading>{tableTitle}</SectionHeading>
            <div className="Table-styling">
                <EditingTable
                    data={ccEntries}
                    columns={columns}
                    pagingProp={true}
                    selectionProp={true}
                    cellEditingStopped={hoistUpdatedRowData}
                    selectionChanged={hoistSelectedRows}
                    onTableReady={onTableReady}
                    removeRows={removeRows}
                    tableClassName="Credit-card-tables"
                />
            </div>
            <CreditCardButtonBar
                applyToAccount={handleApplyToAccount}
                returnToSender={handleReturnToSender}
                isProject={isProject}
                exportTableAsCsv={exportTableAsCsv}
            />
        </div>
    );
};

export default CreditCardTable;
