import React, { useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import TableFactory from './factory/tableFactory';
import useFunctionAsState from '../../hooks/useFunctionAsState';

const DraggingTable = ({
    data,
    columns,
    sortable,
    filter,
    editable,
    sizeToFit,
    edit,
    drag,
    rowDragEnd,
    rowDragEnter,
    rowValueChanged,
    addedNewData,
    onTableReady,
    tableClassName,
}) => {
    const [gridApi, setGridApi] = useFunctionAsState();
    const [onRowDragEnd, setRowDragEnd] = useFunctionAsState();
    const [onRowDragEnter, setRowDragEnter] = useFunctionAsState();
    const [onRowValueChanged, setRowValueChanged] = useFunctionAsState();

    const resizeGrid = useCallback(() => {
        if (gridApi) {
            gridApi.resetRowHeights();

            if (sizeToFit) {
                gridApi.sizeColumnsToFit();
            }
        }
    }, [gridApi, sizeToFit]);

    useEffect(() => {
        resizeGrid();
    }, [gridApi, resizeGrid]);

    const onGridReady = useCallback(
        (params) => {
            const { api: gridApi } = params;
            setGridApi(gridApi);

            const exportDataAsCsv = (nameOfExport) => {
                const filename = nameOfExport + '-' + moment().format('YYYYMDDHHmmss');
                gridApi.exportDataAsCsv({ fileName: filename });
            };

            if (onTableReady && typeof onTableReady === 'function') {
                params.exportDataAsCsv = exportDataAsCsv;
                onTableReady(params);
            }
        },
        [setGridApi, onTableReady]
    );

    useEffect(() => {
        setRowDragEnd(rowDragEnd);
    }, [setRowDragEnd, rowDragEnd]);

    useEffect(() => {
        setRowDragEnter(rowDragEnter);
    }, [setRowDragEnter, rowDragEnter]);

    useEffect(() => {
        setRowValueChanged(rowValueChanged);
    }, [setRowValueChanged, rowValueChanged]);

    useEffect(() => {
        if (gridApi && addedNewData) {
            gridApi.setRowData(data);
        }
    }, [gridApi, addedNewData, data]);

    const table = useMemo(
        () => (
            <TableFactory
                rowData={data}
                columns={columns}
                gridReady={onGridReady}
                resizeGrid={resizeGrid}
                rowDragEnter={onRowDragEnter}
                rowDragEnd={onRowDragEnd}
                rowValueChanged={onRowValueChanged}
                animateRows={true}
                edit={edit}
                drag={drag}
                sortable={sortable}
                filter={filter}
                editable={editable}
                tableClassName={tableClassName}
            />
        ),
        [
            data,
            columns,
            sortable,
            filter,
            editable,
            edit,
            drag,
            tableClassName,
            onRowDragEnter,
            onRowDragEnd,
            onRowValueChanged,
            onGridReady,
            resizeGrid,
        ]
    );

    return <div className="Table_wrap">{table}</div>;
};

export default DraggingTable;
