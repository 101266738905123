import React from 'react';

const PageHeader = ({ title, children }) => (
    <div className="title">
        <h1>{title}</h1>
        <div>{children}</div>
    </div>
);

export default PageHeader;
