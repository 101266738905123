import React, { useEffect, useState } from 'react';
import Table from '../../components/tables/Table';
import NumericEditor from '../../components/numbers/NumericEditor';

const EstimatedBudgetItemTable = ({
    hoistBudgetItemToDelete,
    hoistBudgetItemToEdit,
    hoistOnTableReady,
    budgetItems,
    budgetSummaryRows,
}) => {
    const [rowData, setRowData] = useState([]);

    const isSummaryRow = (row) => {
        if (
            row.item === 'Total Expended' ||
            row.item === 'Total CC Expenses' ||
            row.item === 'Total Invoice Expenses' ||
            row.item === 'Total Planned Budget (NTE)' ||
            row.item === 'Amount Available to Project' ||
            row.item === 'Unbudgeted'
        ) {
            return true;
        }
        return false;
    };

    const handleDeleteEvent = (event) => {
        hoistBudgetItemToDelete(event.data);
    };

    const handleEditEvent = (event) => {
        hoistBudgetItemToEdit(event.data);
    };

    const columns = [
        {
            headerName: 'Category',
            field: 'category',
            editable: true,
            type: 'type',
        },
        {
            headerName: 'Item',
            field: 'item',
            cellStyle: ({ node }) => (isSummaryRow(node.data) ? { fontWeight: 'bold', textAlign: 'right' } : null),
            editable: true,
            type: 'mediumtext',
        },
        {
            headerName: 'Contract No.',
            field: 'contract',
            editable: true,
            cellStyle: ({ node }) => (isSummaryRow(node.data) ? { visibility: 'hidden' } : null),
            type: 'mediumname',
        },
        {
            headerName: 'Planned Expense',
            field: 'nte',
            type: 'money',
            cellEditorFramework: NumericEditor,
            cellStyle: ({ node }) => (isSummaryRow(node.data) ? { fontWeight: 'bold' } : null),
            editable: true,
        },
        {
            headerName: 'Actual Expense',
            field: 'expense',
            type: 'money',
            cellEditorFramework: NumericEditor,
            cellStyle: ({ node }) => (isSummaryRow(node.data) ? { fontWeight: 'bold' } : null),
            editable: true,
        },
        {
            headerName: 'Remaining',
            field: 'remaining',
            type: 'shortmoney',
            valueGetter: ({ data }) => {
                let result = 0;
                if (data) {
                    result = data.nte - data.expense;
                }
                return result;
            },
            cellStyle: ({ node }) => (isSummaryRow(node.data) ? { visibility: 'hidden' } : null),
        },
        {
            headerName: 'Notes/Assumptions',
            field: 'notes',
            editable: true,
            type: 'mediumtext',
            cellStyle: ({ node }) => (isSummaryRow(node.data) ? { visibility: 'hidden' } : null),
        },
        {
            headerName: 'Edit',
            type: 'symbol',
            editable: false,
            sortable: false,
            cellRenderer: 'EditSymbolRenderer',
            onCellClicked: handleEditEvent,
            cellStyle: ({ node }) => (isSummaryRow(node.data) ? { visibility: 'hidden' } : null),
        },
        {
            headerName: 'Delete',
            editable: false,
            cellRenderer: 'TrashSymbolRenderer',
            type: 'symbol',
            onCellClicked: handleDeleteEvent,
            cellStyle: ({ node }) => (isSummaryRow(node.data) ? { visibility: 'hidden' } : null),
        },
    ];

    useEffect(() => {
        const combinedRows = budgetItems.concat(budgetSummaryRows);
        setRowData(combinedRows);
    }, [budgetItems, budgetSummaryRows]);

    return (
        <Table
            data={rowData}
            columns={columns}
            sizeToFit={true}
            onTableReady={hoistOnTableReady}
            filter={true}
            sortable={true}
            tableClassName="Estimated-Budget-Table"
        />
    );
};

export default EstimatedBudgetItemTable;
