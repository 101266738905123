import React from 'react';
import { Link } from 'react-router-dom';

import ChangePasswordForm from './ChangePasswordForm';

const ChangePasswordPage = () => (
    <main className="page">
        <div className="page__subnav">
            <Link to="/user/account">&laquo; Back to Account</Link>
        </div>

        <div className="page__body">
            <h2>Change Your Password</h2>
            <p className="lead"> Use the form below to change the password, you use to sign in to your account.</p>
            <ChangePasswordForm />
        </div>
    </main>
);

export default ChangePasswordPage;
