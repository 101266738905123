import React, { Fragment, useState, useEffect } from 'react';
import Modal from '../../components/modals/Modal';
import GridSection from '../../components/section/GridSection';
import {
    ROAirFareForm,
    ROBaggageForm,
    ROCarRentalForm,
    ROHotelForm,
    ROMilesDrivenForm,
    ROParkingTollsForm,
    ROStandardForm,
    ROTravelExpenseForm,
} from './ReadOnlyForms';
import { TravelExpenseTable } from './TravelExpenseTable';
import errorToast from '../../components/messages/ErrorMessage';
import { recalculatePerDays, tableDataPrep } from './HelperFunctions';
import UserApi from '../../api/users';
import { SummaryCard } from './SummaryCard';
import { prepareCardHolderOptions } from '../../utils/UserUtils';
import {
    prepareTravelExpenditureTypeOptions,
    getTravelExpenditureType,
    travelExpenditureType,
} from '../../utils/TravelUtils';

const ReadOnlyFormModal = (props) => {
    const [travelExpenditures, setTravelExpenditures] = useState([]);
    const [travelExpenditureTypeOptions, setTravelExpenditureTypeOptions] = useState([]);
    const [cardHolderOptions, setCardHolderOptions] = useState([]);
    const [perDayCharges, setPerDayCharges] = useState([]);
    const [tableData, setTableData] = useState({
        columns: [],
        rowData: {},
        summaryCardValues: {
            total_expenses: 0,
            total_non_reimbursables: 0,
            company_charges: 0,
            amount_to_employee: 0,
        },
    });
    const [travelExpenditureForms, setTravelExpenditureForms] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [travelExpenditureIndex, setTravelExpenditureIndex] = useState(0);

    const createDefaultFormValues = () => ({
        cc_holder_id: '',
        travel_expenditure_type: Object.keys(travelExpenditureType)[0],
        cc_type: 'personal',
        purchase_date: '',
        store: '',
        cost: '',
        link_to_db: '',
        notes: '',
        expense_start_data: '',
        expense_end_data: '',
        hotel_non_reimburse: '',
        hotel_taxes_fees: '',
    });

    const pageRight = () => {
        setTravelExpenditureIndex(travelExpenditureIndex + 1);
    };

    const pageLeft = () => {
        setTravelExpenditureIndex(travelExpenditureIndex - 1);
    };

    useEffect(() => {
        if (travelExpenditures.length > 0) {
            if (travelExpenditureIndex === travelExpenditures.length) {
                setTravelExpenditureIndex(travelExpenditures.length - 1);
            } else if (travelExpenditureIndex < 0) {
                setTravelExpenditureIndex(0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [travelExpenditureIndex]);

    const shouldPagingBeDisabled = (travelExpenditures) => {
        if (!travelExpenditures.length || travelExpenditures.length <= 1) {
            return true;
        }
        return false;
    };

    const setNullToEmptyString = (formInitialValues) => {
        for (const key in formInitialValues) {
            if (formInitialValues[key] === null) {
                formInitialValues[key] = '';
            }
        }
        return formInitialValues;
    };

    const buildFormInitialValues = (travelExpenditure) => {
        let formInitValues = createDefaultFormValues();
        if (travelExpenditure) {
            const travelExpenditureTypeKey = getTravelExpenditureType(travelExpenditure);

            formInitValues = {
                id: travelExpenditure.id,
                cc_holder_id: travelExpenditure.expenditure.ccHolder_id,
                travel_expenditure_type: travelExpenditureTypeKey,
                cc_type: travelExpenditure.expenditure.cc_type,
                purchase_date: travelExpenditure.expenditure.purchase_date,
                store: travelExpenditure.expenditure.store,
                cost: travelExpenditure.expenditure.cost,
                link_to_db: travelExpenditure.expenditure.link_to_db,
                notes: travelExpenditure.expenditure.notes,
                expense_start_data: travelExpenditure.expense_start_data,
                expense_end_data: travelExpenditure.expense_end_data,
                hotel_non_reimburse: travelExpenditure.hotel_non_reimburse,
                hotel_taxes_fees: travelExpenditure.hotel_taxes_fees,
                per_day_miles: travelExpenditure.per_day_miles,
                hotel_per_night_rate: travelExpenditure.hotel_per_night_rate,
            };

            formInitValues = setNullToEmptyString(formInitValues);
        }
        return formInitValues;
    };

    const switchFormOnTravelExpenditureType = (formProps) => {
        let form = null;
        const travelExpenditureType = formProps.formInitValues.travel_expenditure_type;
        switch (travelExpenditureType) {
            case travelExpenditureType.parking_tolls:
                form = <ROParkingTollsForm {...formProps} />;
                break;
            case travelExpenditureType.miles_driven:
                form = <ROMilesDrivenForm {...formProps} />;
                break;
            case travelExpenditureType.hotel:
                form = <ROHotelForm {...formProps} />;
                break;
            case travelExpenditureType.car_rental:
                form = <ROCarRentalForm {...formProps} />;
                break;
            case travelExpenditureType.baggage:
                form = <ROBaggageForm {...formProps} />;
                break;
            case travelExpenditureType.airfare:
                form = <ROAirFareForm {...formProps} />;
                break;
            default:
                form = <ROStandardForm {...formProps} />;
        }
        return form;
    };

    const chooseForm = (travelExpenditure) => {
        const formInitialValues = buildFormInitialValues(travelExpenditure);
        const disablePaging = shouldPagingBeDisabled(travelExpenditures);

        const formProps = {
            formInitValues: formInitialValues,
            travelExpenditureTypeOptions,
            cardHolderOptions,
            disablePaging,
            perDayCharges,
            pageRight,
            pageLeft,
        };

        return switchFormOnTravelExpenditureType(formProps);
    };

    const createTravelExpenditureFormList = () => {
        const travelExpenditureFormList = [];
        if (travelExpenditures.length) {
            for (const travelExpenditure of travelExpenditures) {
                const form = chooseForm(travelExpenditure);
                travelExpenditureFormList.push(form);
            }
        } else {
            const form = chooseForm(null);
            travelExpenditureFormList.push(form);
        }
        return travelExpenditureFormList;
    };

    const hoistTravelExpenditures = (travelExpenditures, isPerDiemRowFlag) => {
        try {
            if (isPerDiemRowFlag) {
                setTravelExpenditures([]);
            } else {
                setTravelExpenditures(travelExpenditures);
            }
        } catch (error) {
            errorToast(error);
        }
    };

    useEffect(() => {
        let travelExpenditureFormList = createTravelExpenditureFormList();
        travelExpenditureFormList = travelExpenditureFormList.map((form, index) => (
            <Fragment key={index}>{form}</Fragment>
        ));
        setTravelExpenditureForms(travelExpenditureFormList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perDayCharges, travelExpenditures]);

    useEffect(() => {
        setOpenModal(props.openModal);
    }, [props.openModal]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data: users } = await UserApi.getUsers();

                const travelExpenditureTypeOptions = prepareTravelExpenditureTypeOptions(travelExpenditureType);
                setTravelExpenditureTypeOptions(travelExpenditureTypeOptions);

                const cardHolderOptions = prepareCardHolderOptions(users);
                setCardHolderOptions(cardHolderOptions);
            } catch (errorMessage) {
                errorToast(errorMessage);
            }
        };
        fetch();
    }, []);

    const { travelExpense } = props;
    useEffect(() => {
        if (travelExpense && travelExpense.manual_per_diem) {
            const perDayCharges = recalculatePerDays(travelExpense, travelExpense.manual_per_diem);
            setPerDayCharges(perDayCharges);
        }

        if (travelExpense && travelExpense.travel_expenditures) {
            const perDayCharges = recalculatePerDays(travelExpense, travelExpense.manual_per_diem);
            const tableData = tableDataPrep(perDayCharges, travelExpense.travel_expenditures);
            setTableData(tableData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.travelExpense]);

    const { closeModal } = props;
    return (
        <Fragment>
            <Modal isOpen={openModal}>
                <button onClick={closeModal} className="Travel-expense-close-button align-self-end">
                    <i className="fa fa-window-close" />
                </button>
                <div className="Travel-expense-modal">
                    <GridSection title="Expense Form Data" area="expense">
                        {travelExpense && <ROTravelExpenseForm travelExpense={travelExpense} />}
                    </GridSection>
                    <GridSection title="Current Expenditure" area="expenditure">
                        <div>{travelExpenditureForms[travelExpenditureIndex]}</div>
                    </GridSection>
                    <GridSection area="card" title="Summary">
                        <SummaryCard summaryCardValues={tableData.summaryCardValues} />
                    </GridSection>
                    <GridSection title="Expenses" area="breakdown">
                        <TravelExpenseTable
                            tableData={tableData}
                            columns={tableData.columns}
                            rowData={tableData.rowData}
                            hoistTravelExpenditures={hoistTravelExpenditures}
                        />
                    </GridSection>
                </div>
            </Modal>
        </Fragment>
    );
};

export default ReadOnlyFormModal;
