import React from 'react';
import classNames from 'classnames';

const Checkbox = ({ label, name, inputComponent: InputComponent = 'input', readOnly, className, ...props }) => {
    const id = 'Field-' + name;

    const styleProps = props.style;
    delete props.style;
    return (
        <div className={classNames('flex', className)}>
            <InputComponent
                type="checkbox"
                name={name}
                disabled={readOnly}
                id={id}
                style={{ marginTop: 4, ...styleProps }}
                {...props}
            />
            <label htmlFor={id} style={{ marginLeft: 8, marginRight: 16, ...styleProps }} {...props}>
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
