import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LoginForm from './LoginForm';
import LoginConfirmationForm from './LoginConfirmationForm';

class LoginPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isConfirming: false,
            data: {},
        };
        this.onDone = (resp) => {
            if (resp.need_mfa) {
                this.setState({
                    isConfirming: resp.need_mfa,
                    data: {
                        email: resp.email,
                        password: resp.password,
                        session: resp.session,
                    },
                });
            }
        };
    }

    render() {
        return (
            <div className="AccountPage">
                <div className="AccountPage__content">
                    <h2>Welcome!</h2>
                    {this.state.isConfirming ? (
                        <p>Enter the authorization code from the text message on your phone </p>
                    ) : (
                        <p>Sign in to access your ERGO account.</p>
                    )}
                    {this.state.isConfirming ? (
                        <LoginConfirmationForm data={this.state.data} />
                    ) : (
                        <LoginForm onDone={this.onDone} />
                    )}
                    <p>
                        <strong>Forgot your password?</strong>
                        Request to{' '}
                        <Link to="/account/reset">
                            <strong>reset it</strong>
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}

export default LoginPage;
