import React, { useEffect, useMemo, useCallback, useState } from 'react';
import moment from 'moment';
import useFunctionAsState from '../../hooks/useFunctionAsState';
import PageSizeSelector from './pagingTable/pageSizeSelector';
import TableFactory from './factory/tableFactory';

const SelectionTable = ({
    data,
    columns,
    sizeToFit,
    pagingProp,
    pageSizeProp,
    onTableReady,
    selectionChanged,
    selectionType,
    externalFilterPass,
    externalFilterPresent,
    overrideGridReady,
    addedNewData,
    enableAutoDomLayout,
    sortable,
    filter,
    editable,
    tableClassName,
}) => {
    const DEFAULT_PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [selectType, setSelectType] = useState();
    const [startPageSize, setStartPageSize] = useState();
    const [paging, setPaging] = useState(pagingProp);
    const [gridApi, setGridApi] = useFunctionAsState();
    const [onSelectionChanged, setOnSelectionChanged] = useFunctionAsState();
    const [isExternalFilterPresent, setIsExternalFilterPresent] = useFunctionAsState();
    const [doesExternalFilterPass, setDoesExternalFilterPass] = useFunctionAsState();

    const resizeGrid = useCallback(() => {
        if (gridApi) {
            gridApi.resetRowHeights();

            if (sizeToFit) {
                gridApi.sizeColumnsToFit();
            }
        }
    }, [gridApi, sizeToFit]);

    useEffect(() => {
        resizeGrid();
    }, [gridApi, resizeGrid]);

    const onGridReady = useCallback(
        (params) => {
            const { api: gridApi } = params;
            setGridApi(gridApi);

            const exportDataAsCsv = (nameOfExport) => {
                const filename = nameOfExport + '-' + moment().format('YYYYMDDHHmmss');
                gridApi.exportDataAsCsv({ fileName: filename });
            };

            if (overrideGridReady && typeof overrideGridReady === 'function') {
                overrideGridReady(params);
            }

            if (onTableReady && typeof onTableReady === 'function') {
                const tableParams = {
                    ...params,
                    exportDataAsCsv,
                };
                onTableReady(tableParams);
            }
        },
        [setGridApi, onTableReady, overrideGridReady]
    );

    useEffect(() => {
        setOnSelectionChanged(selectionChanged);
    }, [setOnSelectionChanged, selectionChanged]);

    useEffect(() => {
        setDoesExternalFilterPass(externalFilterPass);
    }, [setDoesExternalFilterPass, externalFilterPass]);

    useEffect(() => {
        setIsExternalFilterPresent(externalFilterPresent);
    }, [setIsExternalFilterPresent, externalFilterPresent]);

    useEffect(() => {
        if (pageSizeProp) {
            setPageSize(pageSizeProp);
            setStartPageSize(pageSizeProp);
        } else {
            setPageSize(DEFAULT_PAGE_SIZE);
            setStartPageSize(DEFAULT_PAGE_SIZE);
        }
    }, [pageSizeProp]);

    useEffect(() => {
        if (pagingProp) {
            setPaging(pagingProp);
        } else {
            setPaging(false);
        }
    }, [pagingProp]);

    useEffect(() => {
        if (gridApi) {
            if (enableAutoDomLayout) {
                gridApi.setDomLayout('autoHeight');
            } else {
                gridApi.setDomLayout('normal');
            }
        }
    }, [gridApi, enableAutoDomLayout]);

    useEffect(() => {
        if (selectionType) {
            setSelectType(selectionType);
        } else {
            setSelectType('multiple');
        }
    }, [selectionType]);

    useEffect(() => {
        if (gridApi) {
            gridApi.paginationSetPageSize(pageSize);
        }
    }, [gridApi, pageSize]);

    const onPageSizeChanged = (e) => {
        let pageSize = e.target.value;
        setPageSize(pageSize);
    };

    useEffect(() => {
        if (gridApi && addedNewData) {
            gridApi.setRowData(data);
        }
    }, [gridApi, addedNewData, data]);

    const table = useMemo(
        () => (
            <TableFactory
                rowData={data}
                columns={columns}
                selection={true}
                selectionType={selectType}
                paging={paging}
                pageSize={pageSize}
                gridReady={onGridReady}
                resizeGrid={resizeGrid}
                animateRows={true}
                selectionChanged={onSelectionChanged}
                externalFilterPresent={isExternalFilterPresent}
                externalFilterPass={doesExternalFilterPass}
                sortable={sortable}
                filter={filter}
                editable={editable}
                tableClassName={tableClassName}
            >
                {paging === true && (
                    <PageSizeSelector
                        onPageSizeChanged={onPageSizeChanged}
                        pageSize={pageSize}
                        startPageSize={startPageSize}
                    />
                )}
            </TableFactory>
        ),
        [
            data,
            columns,
            sortable,
            filter,
            editable,
            tableClassName,
            pageSize,
            startPageSize,
            paging,
            selectType,
            onGridReady,
            resizeGrid,
            onSelectionChanged,
            doesExternalFilterPass,
            isExternalFilterPresent,
        ]
    );

    return <div className="Table_wrap">{table}</div>;
};

export default SelectionTable;
