import React from 'react';
import { useLocation } from 'react-router-dom';
import PagingTable from '../components/tables/PagingTable';

const AgreementsTable = ({ data, onTableReady }) => {
    const { pathname } = useLocation();
    const route = pathname.includes('admin') ? 'admin' : 'projects';

    const subtractOriginalAgreementFromAmendmentCount = (amendmentCount) => amendmentCount - 1;

    const columns = [
        {
            headerName: 'Status',
            field: 'status',
            type: 'shorttextstatus',
        },
        {
            headerName: 'Start Date',
            field: 'start_date',
            type: 'date',
        },
        {
            headerName: 'End Date',
            field: 'end_date',
            type: 'date',
        },
        {
            headerName: 'Description',
            field: 'description',
            type: 'shorttext',
        },
        {
            headerName: 'Agreement No.',
            field: 'number',
            cellRenderer: 'LinkRenderer',
            cellRendererParams: {
                routeBase: `/${route}/agreement/`,
                routeKeyField: 'id',
            },
            type: 'shorttext',
        },
        {
            headerName: 'Type',
            field: 'type',
            type: 'shorttext',
        },
        {
            headerName: 'NTE',
            field: 'nte',
            type: 'money',
        },
        {
            headerName: 'Total Expended',
            field: 'total_amount_paid',
            type: 'money',
        },
        {
            headerName: 'Total Charges',
            field: 'total_invoices',
            type: 'money',
        },
        {
            headerName: 'No. Of Amendments',
            field: 'amendment_count',
            type: 'number',
            valueGetter: ({ data: { amendment_count } }) =>
                subtractOriginalAgreementFromAmendmentCount(amendment_count),
        },
    ];

    return <PagingTable columns={columns} pageSizeProp={25} data={data} sizeToFit={true} onTableReady={onTableReady} />;
};

export default AgreementsTable;
