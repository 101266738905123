import React from 'react';
import { Formik, Form } from 'formik';
import Modal from '../../components/modals/Modal';
import LabeledField from '../../components/forms/LabeledField';

const MatchCard = ({ matchOpen, matchedExpenditure, closeCard }) => (
    <Modal isOpen={matchOpen} style={{ minWidth: '40vw' }}>
        <button onClick={closeCard} className="align-self-end">
            <i className="fa fa-window-close" />
        </button>
        <>
            <label className="Card_title">Match Details:</label>
            <div className="Note-card-form">
                <Formik initialValues={matchedExpenditure} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="note_grid">
                                <LabeledField label="Project Name:" name="account.name" readOnly />
                                <LabeledField label="Card Holder:" name="cardHolder" readOnly />
                                <LabeledField
                                    label="Transaction Date:"
                                    name="purchase_date"
                                    component="Date"
                                    readOnly
                                />
                                <LabeledField label="Amount($):" name="cost" component="Money" readOnly />
                                <LabeledField label="Store:" name="store" component="textarea" readOnly />
                                <LabeledField label="Type:" name="type" readOnly />
                                <LabeledField label="Description" name="item_description" readOnly />
                                <LabeledField label="Link To DB" name="link_to_db" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    </Modal>
);

export default MatchCard;
