const NAME_KEY = 'WBI.AUTH.NAME';
const EMAIL_KEY = 'WBI.AUTH.EMAIL';
const PHONE_KEY = 'WBI.AUTH.PHONE';
const TOKEN_KEY = 'WBI.AUTH.TOKEN';
const USER_KEY = 'WBI.USER';
const REFRESH_TOKEN_KEY = 'WBI.AUTH.REFRESH_TOKEN';
const ID_TOKEN_KEY = 'WBI.AUTH.ID_TOKEN';
const DEVICE_KEY_KEY = 'WBI.AUTH.DEVICE_KEY_KEY';
const DEVICE_KEY_GROUP = 'WBI.AUTH.DEVICE_KEY_GROUP';

const AuthService = {
    getEmail() {
        const email = localStorage.getItem(EMAIL_KEY);
        if (!email) {
            const user = this.getUser();
            if (user) {
                return user.email;
            }
            return null;
        }
        return email;
    },
    getToken() {
        return localStorage.getItem(TOKEN_KEY);
    },
    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    },
    getIdToken() {
        return localStorage.getItem(ID_TOKEN_KEY);
    },
    // Get user vars as an object
    getUser() {
        const user = localStorage.getItem(USER_KEY);
        return JSON.parse(user);
    },
    getDeviceKey() {
        return localStorage.getItem(DEVICE_KEY_KEY);
    },

    // Checks for the auth token
    isAuthenticated() {
        const token = this.getToken();
        if (!token) {
            return false;
        }
        return true;
    },

    // Sets the jwt token
    setToken(token) {
        localStorage.setItem(TOKEN_KEY, token);
    },
    setRefreshToken(token) {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
    },
    setIdToken(token) {
        localStorage.setItem(ID_TOKEN_KEY, token);
    },
    // Sets the user data in storage given a user object
    setUser(user) {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    },
    setDeviceKey(deviceKey) {
        if (deviceKey) {
            localStorage.setItem(DEVICE_KEY_KEY, deviceKey);
        }
    },
    setDeviceKeyGroup(deviceKeyGroup) {
        if (deviceKeyGroup) {
            localStorage.setItem(DEVICE_KEY_GROUP, deviceKeyGroup);
        }
    },

    setName(name) {
        localStorage.setItem(NAME_KEY, name);
    },

    setEmail(email) {
        localStorage.setItem(EMAIL_KEY, email);
    },

    setPhoneNumber(phoneNumber) {
        localStorage.setItem(PHONE_KEY, phoneNumber);
    },

    clearName() {
        localStorage.removeItem(NAME_KEY);
    },

    clearEmail() {
        localStorage.removeItem(EMAIL_KEY);
    },

    clearPhoneNumber() {
        localStorage.removeItem(PHONE_KEY);
    },

    setSignInCallback(signInCallback) {
        this.signInCallback = signInCallback;
    },

    // Sign in - stores jwt token and user data in local storage
    signIn(token, user, refreshToken, idToken) {
        // console.log(token, user, refreshToken, idToken);

        this.setToken(token);
        this.setRefreshToken(refreshToken);
        this.setIdToken(idToken);
        this.setUser(user);
        this.setEmail(user.email_address);
        this.signInCallback();
    },

    // Sign out - removes jwt token and user data from local storage
    signOut() {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(ID_TOKEN_KEY);
    },
};

export default AuthService;
