import React, { useState, useEffect } from 'react';
import LabeledField from '../../components/forms/LabeledField';

export default function AddPointOfContact({ errors, touched, index, values, agreementList }) {
    const [phoneMode, setPhoneMode] = useState(true);
    const handlePhone = () => {
        if (phoneMode) {
            setPhoneMode(false);
        } else {
            setPhoneMode(true);
        }
    };
    useEffect(() => {
        let phoneValue = values[index]['phone'];
        if (phoneValue[0] === '+') {
            setPhoneMode(false);
        }
    }, [values, index]);

    return (
        <>
            <div className="mr2 flex flex-column column-span-2">
                <LabeledField
                    label="First Name"
                    name={`contacts.${index}.first_name`}
                    component="input"
                    value={values[index]['first_name']}
                    errors={errors}
                    touched={touched}
                    labelClassName="text-align-left"
                />
                <LabeledField
                    label="Last Name"
                    name={`contacts.${index}.last_name`}
                    component="input"
                    value={values[index]['last_name']}
                    errors={errors}
                    touched={touched}
                    labelClassName="mt2 text-align-left"
                />
                <LabeledField
                    label="Email"
                    name={`contacts.${index}.email`}
                    component="input"
                    value={values[index]['email']}
                    errors={errors}
                    touched={touched}
                    labelClassName="mt2 text-align-left"
                />

                {phoneMode ? (
                    <LabeledField
                        label="Phone"
                        name={`contacts.${index}.phone`}
                        component="Phone"
                        value={values[index]['phone']}
                        errors={errors}
                        touched={touched}
                        type="tel"
                        labelClassName="mt2 text-align-left mb0"
                    />
                ) : (
                    <LabeledField
                        label="Phone"
                        name={`contacts.${index}.phone`}
                        component="Phone"
                        value={values[index]['phone']}
                        errors={errors}
                        touched={touched}
                        type="itel"
                        labelClassName="mt2 text-align-left mb0"
                    />
                )}
                <div>
                    <input type="checkbox" value="phone" onChange={handlePhone} checked={phoneMode ? '' : 'checked'} />
                    <i className="fa fa-phone" aria-hidden="true" />
                    <label htmlFor="phone"> International Phone Mode</label>
                </div>

                <LabeledField
                    label="Associated Agreement"
                    name={`contacts.${index}.associated_agreement`}
                    value={values[index]['associated_agreement']}
                    component="select"
                    options={agreementList}
                    errors={errors}
                    touched={touched}
                    labelClassName="mt2 text-align-left mb0"
                />

                <LabeledField
                    label="Notes"
                    name={`contacts.${index}.notes`}
                    component="textarea"
                    value={values[index]['notes']}
                    errors={errors}
                    touched={touched}
                    labelClassName="text-align-left mt2"
                />
            </div>
            <div className="VerticalLine" />
        </>
    );
}
