import React from 'react';

const PageSizeSelector = ({ onPageSizeChanged, pageSize, startPageSize }) => (
    <div className="Table_page_size">
        <span className="label">Page Size:</span>
        <select value={pageSize} onChange={onPageSizeChanged}>
            {[10, 25, 50, 100, '10', '25', '50', '100'].indexOf(startPageSize) === -1 && (
                <option value={pageSize}>{pageSize}</option>
            )}
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </div>
);

export default PageSizeSelector;
