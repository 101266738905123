// React
import React, { Component } from 'react';

// Component
import SelectionTable from '../../components/tables/SelectionTable';
import { history } from '../../store/store';
import errorToast from '../../components/messages/ErrorMessage';

class FundsTransferTable extends Component {
    constructor(props) {
        super(props);

        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.navigate = this.navigate.bind(this);
        this.navigateToSource = this.navigateToSource.bind(this);
        this.navigateToDestination = this.navigateToDestination.bind(this);

        this.state = {
            listRowIndex: [],
            rowIndex: 0,
            colKey: null,
            transfers: [],
            forceSetData: false,
            columns: [
                {
                    type: 'checkbox',
                },
                {
                    headerName: 'Transaction Date',
                    field: 'created_on',
                    type: 'date',
                    sort: 'desc',
                },
                {
                    headerName: 'Transfer Type',
                    field: 'type',
                    type: 'mediumshorttext',
                },
                {
                    headerName: 'Source',
                    field: 'source',
                    onCellClicked: this.navigateToSource,
                    cellRenderer(params) {
                        if (params.data.source && params.data.source.name) {
                            return `<a>${params.data.source.name}</a>`;
                        }
                        return null;
                    },
                    type: 'mediumtext',
                },
                {
                    headerName: 'Destination',
                    field: 'destination',
                    onCellClicked: this.navigateToDestination,
                    cellRenderer(params) {
                        if (params.data.destination && params.data.destination.name) {
                            return `<a>${params.data.destination.name}</a>`;
                        }
                        return null;
                    },
                    type: 'mediumtext',
                },
                {
                    headerName: 'Amount',
                    field: 'amount',
                    type: 'money',
                },
                {
                    headerName: 'Description of Transfer',
                    field: 'description',
                    type: 'mediumtext',
                },
                {
                    headerName: 'Reason for Transfer',
                    field: 'reason',
                    type: 'mediumtext',
                },
                {
                    headerName: 'Link to DB',
                    field: 'link_to_db',
                    type: 'symbol',
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                    onCellClicked: this.directToDb,
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    type: 'shortname',
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator.name',
                    type: 'shortname',
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_updater.name',
                    type: 'shortname',
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.props.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.props.deleteTransfer,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            this.setState({
                transfers: this.props.transfers.sort((a) => {
                    if (a.transaction.status === 'Submitted') {
                        return -1;
                    }
                    if (a.transaction.status !== 'Submitted') {
                        return 1;
                    }
                    return 0;
                }),
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    handleSelectionChange(event) {
        let nodeList = event.api.getSelectedNodes();

        let items = [];
        if (nodeList) {
            nodeList.forEach((el) => {
                items.push(el.data);
            });
        }

        let setSelected = this.props.setSelected;
        setSelected(items);

        let listRowIndex = [];
        nodeList.forEach((node) => {
            listRowIndex.push(node.childIndex);
        });

        this.setState({
            listRowIndex,
        });
    }

    navigate(type, id) {
        let location = '';
        if (type === 'OfficeSymbol') {
            location = '/finance/residualFunds/';
        } else if (type === 'Project') {
            location = '/projects/' + id;
        }
        if (location !== '') {
            history.push(location);
        }
    }

    navigateToDestination(event) {
        let type = event.data.destination_type;
        let id = event.data.destination.id;
        this.navigate(type, id);
    }

    navigateToSource(event) {
        let type = event.data.source_type;
        let id = event.data.source.id;
        this.navigate(type, id);
    }

    render() {
        if (!this.state.transfers) return <div>loading...</div>;

        const { columns } = this.state;
        const { transfers, forceSetData } = this.props;

        return (
            <>
                <SelectionTable
                    columns={columns}
                    data={transfers}
                    addedNewData={forceSetData}
                    selectionChanged={this.handleSelectionChange}
                    pagingProp={true}
                    sortable={true}
                    filter={true}
                />
            </>
        );
    }
}

export default FundsTransferTable;
