import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import LabeledField from '../../components/forms/LabeledField';

const emptySummaryCard = {
    totalExpenses: 0,
    companyCharges: 0,
    totalNonReimbursables: 0,
    amountDueToEmployee: 0,
};

export const SummaryCard = (props) => {
    const [summaryCardValues, setSummaryCardValues] = useState(emptySummaryCard);

    useEffect(() => {
        if (summaryCardValues) {
            props.summaryCardValues.total_non_reimbursables *= -1;
            setSummaryCardValues(props.summaryCardValues);
        }
    }, [props, props.summaryCardValues, summaryCardValues]);

    return (
        <>
            <div className="Companion-form">
                <Formik enableReinitialize={true} initialValues={summaryCardValues} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <LabeledField
                                label="Total Expenses"
                                name="total_expenses"
                                component="Money"
                                readOnly={true}
                                class="Decimal_aligned"
                            />
                            <LabeledField
                                label="Charges on Company Card"
                                name="company_charges"
                                component="Money"
                                readOnly={true}
                                class="Decimal_aligned"
                            />
                            <LabeledField
                                label="Total Non-Reimbursables"
                                name="total_non_reimbursables"
                                component="Money"
                                readOnly={true}
                                class="Decimal_aligned"
                            />
                            <LabeledField
                                label="Amount Due to Employee"
                                name="amount_to_employee"
                                component="Money"
                                readOnly={true}
                                class="Decimal_aligned"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default SummaryCard;
