import React from 'react';
import Modal from './Modal';

/*
 * HOW TO USE:
 *
 * import {confirm} from '../shared/ConfirmModal'
 *
  <button
    onClick={async () => {
      const confirmed = await confirm({text: 'Are you sure you want to Initiate the Project?'})
      if (!confirmed) return
      resetForm()
    }}
    Cancel
  </button>
 *
 * This actual component should be mounted once in a global location like in <Layout />
*/

let instance;

class ConfirmModal extends React.Component {
    static confirm = ({ text = 'TODO', acceptText = 'Yes', rejectText = 'No' }) =>
        new Promise((resolve) => {
            instance.setState({
                isOpen: true,
                text,
                acceptText,
                rejectText,
            });
            instance.resolve = resolve;
        });

    constructor() {
        super();
        this.state = {
            isOpen: false,
            text: '',
            acceptText: '',
            rejectText: '',
        };
    }

    componentDidMount() {
        if (instance) {
            console.error('More than one <ConfirmModal> exists! Remove all except for one');
            return;
        }
        instance = this;
    }

    openModal = () => this.setState({ isOpen: true });

    closeModal = () => this.setState({ isOpen: false });

    handleReject = () => {
        this.resolve && this.resolve(false);
        this.closeModal();
    };

    handleAccept = () => {
        this.resolve && this.resolve(true);
        this.closeModal();
    };

    render() {
        const { children, ...props } = this.props;

        return (
            <Modal style={{ maxWidth: '65vw' }} isOpen={this.state.isOpen} {...props}>
                <p style={{ marginTop: 16, marginBottom: 16 }}>{this.state.text}</p>
                <div className="flex justify-space-around">
                    {this.state.rejectText && (
                        <button onClick={this.handleReject} className="">
                            {this.state.rejectText}
                        </button>
                    )}
                    {this.state.acceptText && (
                        <button onClick={this.handleAccept} className="" style={{ background: '#009CDB' }}>
                            {this.state.acceptText}
                        </button>
                    )}
                </div>
            </Modal>
        );
    }
}

export const confirm = ConfirmModal.confirm;
export default ConfirmModal;
