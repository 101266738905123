import React, { useCallback, useEffect } from 'react';
import useFunctionAsState from '../../hooks/useFunctionAsState';

const ExportCSVButton = ({ fileName, exportFunction }) => {
    const [exportCsv, setExportCsv] = useFunctionAsState(() => {});

    useEffect(() => {
        if (typeof exportFunction === 'function') {
            setExportCsv(exportFunction);
        }
    }, [exportFunction, setExportCsv]);

    const handleClick = useCallback(() => {
        exportCsv(fileName);
    }, [fileName, exportCsv]);

    return (
        <button type="button" onClick={() => handleClick()}>
            Export CSV
        </button>
    );
};

export default ExportCSVButton;
