import React from 'react';

const TimerSymbol = (props) => {
    let status = props.status;

    if (status) {
        return (
            <div className="TimerSymbol">
                <svg viewBox="0 0 71 71">
                    <g className={status}>
                        <path d="M35.5,7.7C35.5,7.7,35.5,7.7,35.5,7.7c-15.5,0-28,12.5-28,27.9C7.6,20.2,20.2,7.7,35.5,7.7z" />
                        <path d="M35.5,63.5c-7.7,0-14.7-3.1-19.7-8.2C20.9,60.4,27.9,63.6,35.5,63.5c8.6,0.1,16.2-3.7,21.3-9.8C51.7,59.7,44.1,63.6,35.5,63.5z" />
                        <path d="M35.6,1.7c-18.7,0-34,15.2-34,33.9c0,18.7,15.2,33.9,33.9,33.9s33.9-15.2,33.9-33.9C69.5,16.9,54.3,1.7,35.6,1.7z M35.6,63.6c-7.7,0-14.7-3.2-19.8-8.2c-5.1-5.1-8.2-12.1-8.2-19.8c0-15.4,12.5-27.9,27.9-27.9c0,0,0,0,0,0c15.4,0,27.9,12.5,27.9,27.9c0,6.9-2.4,13.2-6.6,18.1C51.7,59.8,44.1,63.6,35.6,63.6z" />
                        <polygon
                            className="st1"
                            points="51.8,50.6 38.5,34.4 38.5,14.5 32.5,14.5 32.5,36.6 47.2,54.4 	"
                        />
                    </g>
                </svg>
            </div>
        );
    }
    return null;
};

export default TimerSymbol;
