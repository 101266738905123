import React from 'react';
import _find from 'lodash/find';
import { history } from '../store/store';
import ExpenseApprovalCards from '../components/cards/ExpenseApprovalCards';
import errorToast from '../components/messages/ErrorMessage';
import ProjectsApi from '../api/projects';
import ExpendituresApi from '../api/expenditures';
import UsersApi from '../api/users';

class ApprovalsPage extends React.Component {
    constructor(props) {
        super(props);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);

        this.state = {
            project: null,
            approvals: [],
            approvalsHistory: [],
        };
    }

    async componentDidMount() {
        try {
            if (this.props.match.path.includes('projects')) {
                const { data: project } = await ProjectsApi.fetchProject(this.props.match.params.id);
                const { data: expenditureApprovals } = await ExpendituresApi.fetchExpendituresApprover();
                const { data: users } = await UsersApi.getUsers();

                if (expenditureApprovals) {
                    expenditureApprovals.forEach((exp) => {
                        exp.typeOfApproval = 'Expenditure Approval Request';
                        if (users) {
                            let initiator = _find(users, { id: exp.initiator_id });
                            if (initiator) exp.initiator_name = initiator.name;
                        }
                    });
                }

                let approvals = expenditureApprovals;

                approvals = approvals.filter((approval) => approval.account_type === 'Project');

                this.setState({
                    project,
                    approvals,
                });
            } else if (this.props.match.path.includes('coreFunding')) {
                const { data: expenditureApprovals } = await ExpendituresApi.fetchExpendituresApprover();
                const { data: users } = await UsersApi.getUsers();

                if (expenditureApprovals) {
                    expenditureApprovals.forEach((exp) => {
                        exp.typeOfApproval = 'Expenditure Approval Request';
                        if (users) {
                            let initiator = _find(users, { id: exp.initiator_id });
                            if (initiator) exp.initiator_name = initiator.name;
                        }
                    });
                }

                let approvals = expenditureApprovals;
                approvals = approvals.filter((approval) => approval.account_type === 'CoreBudgetType');

                this.setState({
                    approvals,
                });
            }
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    approve(id, index, type) {
        ExpendituresApi.approveExpenditure(id)
            .then(() => {
                let newApprovals = this.state.approvals.filter((_, i) => index !== i);

                this.setState({
                    approvals: newApprovals,
                });

                if (this.props.match.path.includes('projects')) {
                    if (this.state.approvals.length === 0) {
                        history.push(`/projects/${this.props.match.params.id}/expenditures`);
                    }
                }
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    reject(id, index, type) {
        ExpendituresApi.rejectExpenditure(id)
            .then(() => {
                let newApprovals = this.state.approvals.filter((_, i) => index !== i);

                this.setState({
                    approvals: newApprovals,
                });
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    render() {
        if (!this.state.approvals) return <div>loading...</div>;

        return (
            <>
                {this.state.approvals ? (
                    <ExpenseApprovalCards
                        approvals={this.state.approvals}
                        approve={this.approve}
                        reject={this.reject}
                    />
                ) : null}
            </>
        );
    }
}

export default ApprovalsPage;
