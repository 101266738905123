import React, { forwardRef, useImperativeHandle, useState } from 'react';

export default forwardRef((props, ref) => {
    const [data] = useState(props.api.getDisplayedRowAtIndex(props.rowIndex).data);

    useImperativeHandle(ref, () => ({
        getReactContainerClasses() {
            return ['Custom-tooltip'];
        },
    }));
    const result = (data, props) => {
        let message = '';
        if (props.column.colId === 'total_all_open_ntes') {
            if (data.total_all_open_ntes > 750000) {
                message = 'Audit Required';
            } else {
                message = 'No Audit Required';
            }
        }
        if (props.column.colId === 'open_agreements') {
            if (data.expired_agreements > 0 || data.agreements_past_nte > 0) {
                message = 'Agreement(s) ready for closing';
            } else {
                message = 'No agreements ready for closing';
            }
        }
        return message;
    };
    return (
        <div className="Custom-tooltip">
            <p>{data.name}</p>
            <p>{result(data, props)}</p>
        </div>
    );
});
