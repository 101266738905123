import React from 'react';
import PageHeader from '../components/heading/PageHeader';
import PagingTable from '../components/tables/PagingTable';
import ButtonLink from '../components/buttons/ButtonLink';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import errorToast from '../components/messages/ErrorMessage';
import ProjectsApi from '../api/projects';

class ProjectsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            columns: [
                {
                    headerName: 'Project Number',
                    field: 'number',
                    sort: 'desc',
                    type: 'idNum',
                },
                {
                    headerName: 'Project Name',
                    field: 'name',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'id',
                    },
                    type: 'name',
                },
                {
                    headerName: 'PIA',
                    field: 'pia.name',
                    type: 'shortnumber',
                },
                {
                    headerName: 'CPO',
                    field: 'cpo.number',
                    type: 'mediumnumber',
                },
                {
                    headerName: 'Start Date',
                    field: 'start_date',
                    type: 'date',
                },
                {
                    headerName: 'End Date',
                    field: 'estimated_end_date',
                    type: 'date',
                },
                {
                    headerName: 'WBI Contact',
                    field: 'wbi_contact',
                    type: 'shortname',
                },
                {
                    headerName: 'Customer Contact',
                    field: 'customer_name',
                    type: 'mediumname',
                },
                {
                    headerName: 'Office Symbol',
                    field: 'office_symbol.name',
                    type: 'status',
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    type: 'shortname',
                },
            ],
        };
        this.onTableReady = this.onTableReady.bind(this);
        this.gridApi = null;
        this.exportDataAsCsv = null;
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    componentDidMount = async () => {
        try {
            const { data: projects } = await ProjectsApi.fetchProjects();

            projects.forEach((project) => {
                project.users.forEach((user) => {
                    if (project.wbi_lead_user_id === user.id) {
                        project.wbi_contact = user.name;
                    }
                });
            });

            projects.sort((a) => {
                if (a.status !== 'active') {
                    return 1;
                }
                if (a.status === 'active') {
                    return -1;
                }
                return 0;
            });

            this.setState({ projects });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    render() {
        const { columns, projects } = this.state;
        return (
            <>
                <PageHeader title="Projects Dashboard" />
                <div className="Floating-button Floating-button--Buttonlink">
                    <ButtonLink to="/projects/new">Create New Project</ButtonLink>
                </div>
                <PagingTable
                    columns={columns}
                    data={projects}
                    onTableReady={this.onTableReady}
                    sortable={true}
                    filter={true}
                    sizeToFit={true}
                    tableClassName="Projects-table"
                />
                <div className="Form__buttons Floating-button">
                    <ExportCSVButton fileName="projects" exportFunction={this.exportDataAsCsv} />
                </div>
            </>
        );
    }
}

export default ProjectsPage;
