import api from './api';

import endpoints from './endpoints';

const searchRoute = endpoints.SEARCH;

export default {
    fetchResults(searchTerm) {
        return api.get(`${searchRoute}?term=${searchTerm}`, 'Error getting search results');
    },
};
