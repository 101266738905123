import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import FieldWithError from './FieldWithError';
import { history } from '../../store/store';

const idForName = (name) => 'Field-' + name;

const LabeledField = ({
    label,
    longLabel,
    className,
    labelClassName,
    renderPrefix,
    renderSuffix,
    inputSpan,
    ...props
}) => {
    const id = idForName(props.name);
    const link = _.has(props, 'link') && props.link;
    if (inputSpan === null || inputSpan === undefined) {
        inputSpan = 2;
    }

    return (
        <>
            <label
                htmlFor={id}
                className={classNames('FieldLabel', labelClassName)}
                style={longLabel ? { gridColumn: 'span 2' } : null}
            >
                {label}
            </label>
            <div className="FieldInputWrapper left" style={!longLabel ? { gridColumn: 'span ' + inputSpan } : null}>
                {typeof renderPrefix === 'function' && renderPrefix()}
                {link ? (
                    <a
                        href="#/"
                        onClick={() => {
                            history.push(link);
                        }}
                    >
                        <FieldWithError id={id} {...props} />
                    </a>
                ) : (
                    <FieldWithError id={id} {...props} />
                )}
                {typeof renderSuffix === 'function' && renderSuffix()}
            </div>
        </>
    );
};

export default LabeledField;
