import React from 'react';
import PagingTable from '../components/tables/PagingTable';

const AgreementsProjectTable = ({ onTableReady, agreementsData, projectID }) => {
    const columns = [
        {
            headerName: 'Agreement No.',
            field: 'number',
            cellRenderer: 'LinkRenderer',
            cellRendererParams: {
                routeBase: '/projects/agreement/',
                routeKeyField: 'id',
            },
            type: 'shorttext',
        },
        {
            headerName: 'Agreement Status',
            field: 'status',
            type: 'shorttextstatus',
        },
        {
            headerName: 'Vendor Name',
            field: 'vendor_name',
            cellRenderer: 'LinkRenderer',
            cellRendererParams: {
                routeBase: `/projects/${projectID}/vendor/`,
                routeKeyField: 'vendor_id',
            },
            type: 'mediumtext',
        },
        {
            headerName: 'Project NTE Commitments',
            field: 'total_all_open_ntes',
            type: 'money',
            cellRenderer: 'AuditRenderer',
            tooltipField: 'total_all_open_ntes',
        },
        {
            headerName: 'Project NTEs Amount Expended',
            field: 'total_used_all_open_ntes',
            type: 'money',
        },
        {
            headerName: 'Total Remaining of Project Open NTEs',
            field: 'total_remaining_all_open_ntes',
            type: 'money',
        },
        {
            headerName: 'CAGE Code',
            field: 'vendor_sams',
            type: 'shorttext',
        },
        {
            headerName: 'UEI No.',
            field: 'vendor_uei',
            type: 'shorttext',
        },
        {
            headerName: 'Approved',
            field: 'vendor_approved',
            type: 'shorttext',
        },
    ];
    return (
        <PagingTable
            columns={columns}
            data={agreementsData}
            pageSizeProp={25}
            onTableReady={onTableReady}
            sortable={true}
            filter={true}
            sizeToFit={true}
        />
    );
};

export default AgreementsProjectTable;
