import React from 'react';
import SelectionTable from '../../components/tables/SelectionTable';

const BankStatementLineItemTableColumns = [
    {
        type: 'checkbox',
    },
    {
        headerName: 'Card Holder',
        field: 'card_holder',
        type: 'name',
    },
    {
        headerName: 'Trans. Date',
        field: 'transaction_date',
        sort: 'asc',
        type: 'date',
    },
    {
        headerName: 'Amount',
        field: 'amount',
        type: 'money',
    },
    {
        headerName: 'Description',
        field: 'description',
        type: 'mediumtext',
    },
];

const BankStatementLineItemTable = ({ records, hoistSelectedStatementLineItem }) => {
    const handleSelectionChange = (event) => {
        const result = event.api.getSelectedNodes();
        hoistSelectedStatementLineItem(result[0]);
    };

    return (
        <SelectionTable
            columns={BankStatementLineItemTableColumns}
            selectionType="single"
            data={records}
            pagingProp={true}
            sizeToFit={true}
            filter={true}
            sortable={true}
            selectionChanged={handleSelectionChange}
            tableClassName="Review-bank-statements-table"
        />
    );
};

export default BankStatementLineItemTable;
