// React
import React, { Component } from 'react';

//Library
import _find from 'lodash/find';

// Component
import FinanceSubNav from '../FinanceSubNav';
import PageHeader from '../../components/heading/PageHeader';
import SubSubNav, { SubSubNavLink } from '../../components/navigation/SubSubNav';
import TransferFundsForm from './TransferFundsForm';
import FundsTransferTable from './FundsTransferTable';
import errorToast from '../../components/messages/ErrorMessage';
import { confirm } from '../../components/modals/ConfirmModal';

// API
import ResidualFundsApi from '../../api/residualFunds';
import TransferRequestApi from '../../api/transferRequest';
import ProjectsApi from '../../api/projects';

class FundsTransferPage extends Component {
    constructor(props) {
        super(props);

        this.applyTransfers = this.applyTransfers.bind(this);
        this.rejectTransfers = this.rejectTransfers.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.deleteTransfer = this.deleteTransfer.bind(this);

        this.state = {
            forceSetData: false,
            residualFund: null,
            transfers: [],
            selected: [],
            rowToUpdate: null,
            financialPatches: [],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: residualFunds } = await ResidualFundsApi.getResidualFundWithFundsTotal();
            const { data: transfers } = await TransferRequestApi.getTransferRequests();

            let residualFund;
            if (residualFunds.length > 0) residualFund = _find(residualFunds, { id: residualFunds[0].id });

            this.setState({
                residualFund,
                transfers,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    handleSubmit(values, actions) {
        let source_type = '';
        let dest_type = '';
        switch (values.transferType) {
            case 'Residual-Residual':
                source_type = 'ResidualFund';
                dest_type = 'ResidualFund';
                break;
            case 'Project-Project':
                source_type = 'Project';
                dest_type = 'Project';
                break;
            case 'Project-Residual':
                source_type = 'Project';
                dest_type = 'ResidualFund';
                break;
            case 'Residual-Project':
                source_type = 'ResidualFund';
                dest_type = 'Project';
                break;
            default:
                source_type = '';
                dest_type = '';
                break;
        }

        let dest_id;
        if (values.destination_id.value) {
            dest_id = values.destination_id.value;
        } else {
            dest_id = values.destination_id;
        }
        let source_id;
        if (values.source_id) {
            source_id = values.source_id;
        } else {
            source_id = values.destination_id;
        }

        let attributes = {
            amount: values.amount,
            description: values.description,
            destination_id: dest_id,
            reason: values.reason,
            link_to_db: values.link_to_db,
            source_id,
            source_type,
            destination_type: dest_type,
            reviewer_ids: [],
            status: 'submitted',
        };

        const { rowToUpdate } = this.state;
        if (rowToUpdate) {
            // Dont need this, if they want to apply it as well, go through the table apply process
            delete attributes.status;

            TransferRequestApi.updateTransferRequest(attributes, rowToUpdate.id)
                .then((response) => {
                    this.makeFinancialPatch(attributes);
                    let { transfers } = this.state;
                    transfers = transfers.filter((transfer) => transfer.id !== response.data.id);
                    transfers.push(response.data);
                    this.setState(
                        {
                            transfers,
                            rowToUpdate: null,
                            forceSetData: true,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        } else {
            TransferRequestApi.createTransferRequest(attributes)
                .then((response) => {
                    this.makeFinancialPatch(attributes);
                    let transfers = this.state.transfers;
                    transfers.unshift(response.data);
                    this.setState(
                        {
                            transfers,
                            forceSetData: true,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
        actions.resetForm();
    }

    getRowData(event) {
        event.node.gridApi.updateRowData({ remove: [event.node.data] });
        this.setState({ rowToUpdate: event.data });
    }

    setSelected(items) {
        this.setState({ selected: items });
    }

    deleteTransfer = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to delete the transfer? All data will be lost.',
        });

        if (confirmed) {
            let rowToRemove = event.node.data;
            TransferRequestApi.deleteTransferRequest(rowToRemove.id)
                .then(() => {
                    this.makeFinancialPatch(rowToRemove);
                    let { transfers } = this.state;
                    transfers = transfers.filter((transfer) => transfer.id !== rowToRemove.id);
                    this.setState(
                        {
                            transfers,
                            forceSetData: true,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => [errorToast(errorMessage)]);
        }
    };

    deselectRows = () => {
        this.setState(
            {
                selected: null,
                forceSetData: true,
            },
            () => {
                this.setState({ forceSetData: false });
            }
        );
    };

    makeFinancialPatch = async (data) => {
        let patches = [];
        if (data.source_type === 'ResidualFund' || data.dest_type === 'ResidualFund') {
            const { data: residualFunds } = await ResidualFundsApi.getResidualFundWithFundsTotal();
            patches.push({
                data: residualFunds,
                type: 'residualFunds',
                id: data.source_id,
                source: data.source_type === 'ResidualFund',
            });
        }
        if (data.source_type === 'Project') {
            const { data: projectFinInfo } = await ProjectsApi.fetchProjectFinancialInformation(data.source_id);
            projectFinInfo.sourceId = data.source_id;
            patches.push({
                data: projectFinInfo,
                type: 'project',
                id: data.source_id,
                source: true,
            });
        }
        if (data.dest_type === 'Project') {
            const { data: projectFinInfo } = await ProjectsApi.fetchProjectFinancialInformation(data.dest_id);
            projectFinInfo.sourceId = data.dest_id;
            patches.push({
                data: projectFinInfo,
                type: 'project',
                id: data.dest_id,
            });
        }
        this.setState({ financialPatches: patches });
    };

    cancelEdit() {
        this.setState(
            {
                rowToUpdate: null,
                forceSetData: true,
            },
            () => {
                this.setState({ forceSetData: false });
            }
        );
    }

    applyTransfers() {
        let transfers = [];
        let listRows = 0;
        if (this.state.transfers && this.state.selected) {
            transfers = this.state.transfers;
            listRows = this.state.selected;

            for (let i = 0; i < listRows.length; i++) {
                let transfer = listRows[i];
                TransferRequestApi.applyTransferRequest(transfer.id)
                    .then((resp) => {
                        this.makeFinancialPatch(transfer);
                        let idx = transfers.findIndex((t) => t.id === resp.data.id);
                        transfers[idx] = resp.data;

                        this.setState(
                            {
                                transfers,
                                forceSetData: true,
                            },
                            () => {
                                this.setState({ forceSetData: false });
                            }
                        );
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            }
        }
    }

    rejectTransfers() {
        let transfers = [];
        let listRows = 0;
        if (this.state.transfers && this.state.selected) {
            transfers = this.state.transfers;
            listRows = this.state.selected;

            for (let i = 0; i < listRows.length; i++) {
                let transfer = listRows[i];
                TransferRequestApi.rejectTransferRequest(transfer.id, {})
                    .then((response) => {
                        let idx = transfers.findIndex((t) => t.id === response.data.id);
                        transfers[idx] = response.data;
                        this.setState(
                            {
                                transfers,
                                forceSetData: true,
                            },
                            function () {
                                this.setState({ forceSetData: false });
                            }
                        );
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            }
        }
    }

    render() {
        const { match } = this.props;
        const { residualFund, transfers, forceSetData, rowToUpdate } = this.state;

        return (
            <div>
                <PageHeader title="Transfer Residual Funds" />
                <FinanceSubNav />
                <SubSubNav>
                    <SubSubNavLink to="/finance/residualFunds" exact>
                        Residual Funds
                    </SubSubNavLink>
                    <SubSubNavLink to={match.url} exact>
                        Funds Transfer
                    </SubSubNavLink>
                </SubSubNav>
                <div className="Centered-form">
                    <TransferFundsForm
                        handleSubmit={this.handleSubmit}
                        rowToUpdate={rowToUpdate}
                        cancelEdit={this.cancelEdit}
                        financialPatches={this.state.financialPatches}
                    />
                </div>
                <div className="Table-styling Transfer-table">
                    <FundsTransferTable
                        residualFundId={residualFund}
                        transfers={transfers}
                        setSelected={this.setSelected}
                        getRowData={this.getRowData}
                        forceSetData={forceSetData}
                        deleteTransfer={this.deleteTransfer}
                    />
                </div>
                <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
                    <button className="Buttonlink" type="button" onClick={this.deselectRows}>
                        Cancel
                    </button>
                    <button type="button" onClick={this.rejectTransfers}>
                        Reject Transfer
                    </button>
                    <button type="button" onClick={this.applyTransfers}>
                        Apply Transfer
                    </button>
                </div>
            </div>
        );
    }
}

export default FundsTransferPage;
