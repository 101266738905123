import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const SubNav = ({ children }) => <div className="SubNav">{children}</div>;

export const SubNavLink = withRouter(({ disabled, match, location, history, staticContext, ...props }) => {
    if (disabled) {
        return (
            <span className={props.to === location.pathname ? 'SubNavLink Selected' : 'SubNavLink'}>
                {props.children}
            </span>
        );
    }
    return <NavLink className="SubNavLink" activeClassName="Selected" {...props} />;
});

export default SubNav;
