import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../auth/auth.service';
import VendorsTable from './VendorsTable';
import VendorsApi from '../api/vendors';
import AddNewVendorsModal from './vendorForm/AddNewVendorModal';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import errorToast from '../components/messages/ErrorMessage';
import useFunctionAsState from '../hooks/useFunctionAsState';

const VendorsPage = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [vendorsData, setVendorsData] = useState([]);
    const user = AuthService.getUser();
    const [isExecDir, setIsExecDir] = useState(false);
    const [fetchState, setFetchState] = useState(true);
    const { pathname } = useLocation();
    const [exportDataAsCsv, setExportDataAsCsv] = useFunctionAsState(null);

    const openAddNewVendor = () => {
        setModalOpen(true);
    };

    const closeAddNewVendor = () => {
        setModalOpen(false);
        setVendorsData([]);
    };

    const hoistTableParams = (params) => {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            setExportDataAsCsv(params.exportDataAsCsv);
        }
    };

    useEffect(() => {
        if (user !== null && user.site_role === 'Executive Director') {
            setIsExecDir(true);
        }
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await VendorsApi.getVendors();
                setVendorsData(data);
                if (data.length === 0) {
                    setFetchState(false);
                }
            } catch (error) {
                errorToast(error);
            }
        };

        if (vendorsData.length === 0 && fetchState) {
            fetchData();
        }
    }, [vendorsData, fetchState]);

    return (
        <div>
            <VendorsTable onTableReady={hoistTableParams} vendorsData={vendorsData} />
            <div className="Form__buttons Align-right">
                <ExportCSVButton fileName="Vendors" exportFunction={exportDataAsCsv} />
                {pathname.includes('admin') && !isExecDir && (
                    <button type="button" onClick={openAddNewVendor}>
                        Add New Vendor
                    </button>
                )}
            </div>
            <AddNewVendorsModal vendor="" openModal={modalOpen} closeModal={closeAddNewVendor} />
        </div>
    );
};

export default VendorsPage;
