// React
import React from 'react';

// Libraries
import { FieldArray as BasicFieldArray } from 'formik';
import Select from 'react-select';
import moment from 'moment';

// Components
import Fieldset from '../Fieldset';
import EditingTable from '../../components/tables/EditingTable';
import { confirm } from '../../components/modals/ConfirmModal';
import errorToast from '../../components/messages/ErrorMessage';

// API
import ProjectsApi from '../../api/projects';
import UsersApi from '../../api/users';

class SignaturesSection extends React.Component {
    constructor(props) {
        super(props);

        this.updateTable = this.updateTable.bind(this);
        this.addSelectedSignature = this.addSelectedSignature.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            isEditing: false,
            errorMessage: '',
            users: null,
            isRequestingSignatures: false,
            signatures: [],
            selectedUserToAdd: null,
            people: [],
            rowIndex: null,
            colKey: '',
            isEditing_Signature: false,
            forceSetData: false,
            listRowIndex: [],
        };
    }

    async componentDidMount() {
        const { data: users } = await UsersApi.getUsers();
        const { data: userOptions } = await UsersApi.getUserOptions();
        let signatures = [];

        this.props.values.signatures.forEach((element) => {
            if (element.user_id) {
                if (element.signed) {
                    element.status = 'good';
                } else {
                    element.status = 'bad';
                }
                signatures.push(element);
            }
        });

        this.setState({
            users,
            userOptions,
            signatures,
        });
    }

    handleEditMode(editing) {
        this.props.handleEditMode(editing);
    }

    onRowValueChanged(event) {
        let selectedUserToEdit = event.node.data;
        let signatureID = selectedUserToEdit.id;
        let projectID = event.data.project_id;

        let attributes = {
            name: selectedUserToEdit.name,
            title: selectedUserToEdit.title ? selectedUserToEdit.title : '',
            organization: selectedUserToEdit.organization,
            link_to_db: selectedUserToEdit.link_to_db,
            signed: selectedUserToEdit.signed,
            project_id: projectID,
            user_id: selectedUserToEdit.user_id,
        };

        if (attributes.link_to_db !== '' && !attributes.signed) {
            attributes.signed = true;
            this.updateTable(projectID, signatureID, attributes);
        }

        this.setState({
            isEditing_Signature: false,
            row: null,
            rowIndex: null,
            colKey: '',
        });
    }

    getRowData(event) {
        this.setState({
            isEditing_Signature: true,
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'link_to_db',
        });
    }

    addSelectedSignature() {
        const selectedUserToAdd = this.state.selectedUserToAdd;
        const projectId = this.props.projectId;

        let attributes = {
            name: selectedUserToAdd.name,
            title: selectedUserToAdd.site_role,
            organization: selectedUserToAdd.organization,
            link_to_db: selectedUserToAdd.link_to_db,
            signed: false,
            project_id: projectId,
            user_id: selectedUserToAdd.id,
        };

        let signatureExists = this.state.signatures.filter((sig) => sig.user_id === attributes.user_id);
        if (signatureExists.user_id === undefined) {
            ProjectsApi.createSignature(projectId, attributes)
                .then((response) => {
                    let newSignatures = this.state.signatures;
                    newSignatures.push(response.data);

                    newSignatures.forEach((element) => {
                        if (element.user_id === response.data.user_id) {
                            if (element.signed) {
                                element.status = 'good';
                            } else {
                                element.status = 'bad';
                            }
                        }
                    });

                    this.setState(
                        {
                            forceSetData: true,
                            signatures: newSignatures,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                    errorToast(errorMessage);
                });
        }
    }

    updateTable(projectID, signatureID, signatureAttributes) {
        if (signatureAttributes.signed) {
            signatureAttributes.date_signed = moment().startOf('day').format('YYYY-MM-DD');
        }

        ProjectsApi.updateSignature(projectID, signatureID, signatureAttributes)
            .then((response) => {
                let newSignatures = this.state.signatures;
                newSignatures.forEach((element) => {
                    if (element.user_id === response.data.user_id) {
                        element.signed = response.data.signed;
                        if (element.signed) {
                            element.status = 'good';
                        } else {
                            element.status = 'bad';
                        }
                    }
                });

                this.setState(
                    {
                        forceSetData: true,
                        signatures: newSignatures,
                    },
                    () => {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
                errorToast(errorMessage);
            });
    }

    removeSignature(user) {
        const projectId = user.project_id;

        if (!user.signed) {
            ProjectsApi.removeSignature(projectId, user.id)
                .then(() => {
                    let signatures = this.state.signatures;

                    signatures = signatures.filter((signature) => signature.id !== user.id);

                    this.setState(
                        {
                            forceSetData: true,
                            signatures,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        } else {
            errorToast("Can't remove a signature");
        }
    }

    render() {
        const { isEditing, isSubmitting, submitForm, dirty, resetForm, isValid, isNew } = this.props;
        const { isRequestingSignatures, signatures, isEditing_Signature, userOptions } = this.state;
        let title = 'Signatures';

        if (isNew) {
            title = ' ';
        }

        return (
            <Fieldset title={title} isNew={isNew}>
                {!isNew && (
                    <div className="column-span-3 Signatures-section">
                        <BasicFieldArray
                            name="users"
                            render={() => (
                                <>
                                    <div className="column-span-2 Signatures-table">
                                        <EditingTable
                                            data={signatures}
                                            columns={[
                                                {
                                                    headerName: 'User',
                                                    field: 'name',
                                                    type: 'name',
                                                },
                                                {
                                                    headerName: 'Signed',
                                                    field: 'status',
                                                    cellRenderer: 'StatusSymbolRenderer',
                                                    cellEditor: 'agSelectCellEditor',
                                                    cellEditorParams: {
                                                        values: ['Good', 'Caution', 'stop'],
                                                    },
                                                    editable: true,
                                                    type: 'symbol',
                                                },
                                                {
                                                    headerName: 'Link to Signed PD',
                                                    field: 'link_to_db',
                                                    editable: true,
                                                    type: 'symbol',
                                                    cellRenderer: 'ExternalLinkSymbolRenderer',
                                                },
                                                {
                                                    hide: !isEditing,
                                                    headerName: 'Edit',
                                                    editable: false,
                                                    field: 'edit',
                                                    type: 'symbol',
                                                    cellRenderer: 'EditSymbolRenderer',
                                                    onCellClicked: this.getRowData,
                                                },
                                                {
                                                    hide: !isEditing,
                                                    headerName: 'Delete',
                                                    editable: false,
                                                    type: 'symbol',
                                                    cellRenderer: 'TrashSymbolRenderer',
                                                    onCellClicked: (event) => {
                                                        this.removeSignature(event.data);
                                                    },
                                                },
                                            ]}
                                            addedNewData={this.state.forceSetData}
                                            rowValueChanged={this.onRowValueChanged}
                                            rowIndex={this.state.rowIndex}
                                            colKey={this.state.colKey}
                                            sizeToFit={!isEditing}
                                        />
                                    </div>
                                    <div className="column-span-2" />
                                </>
                            )}
                        />
                        {isEditing && (
                            <div className="column-span-3 flex justify-content-end">
                                {isEditing_Signature && (
                                    <>
                                        <button
                                            className="flex flex-column flex-1 Link-button"
                                            type="button"
                                            onClick={() => {
                                                let projectID = this.props.projectId;
                                                let signatureID = this.state.row.id;
                                                let signatureAttributes = {
                                                    name: this.state.row.name,
                                                    project_id: projectID,
                                                    signed: true,
                                                };

                                                this.setState({
                                                    isEditing_Signature: false,
                                                    row: null,
                                                    rowIndex: null,
                                                    colKey: '',
                                                });

                                                this.updateTable(projectID, signatureID, signatureAttributes);
                                            }}
                                        >
                                            Digitally Sign
                                        </button>
                                    </>
                                )}
                                <div className="Peeps-select">
                                    <Select
                                        value={this.state.selectedUserToAdd}
                                        options={userOptions}
                                        onChange={(e) => {
                                            this.setState({
                                                selectedUserToAdd: e,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="Peeps-select-btn">
                                    <button
                                        className="End-button"
                                        type="button"
                                        disabled={this.state.selectedUserToAdd === null}
                                        onClick={() => {
                                            this.addSelectedSignature();
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className="column-span-3 text-align-right Request-btn">
                            {!isNew && this.state.signatures && (
                                <button
                                    className="End-button"
                                    type="button"
                                    disabled={isSubmitting || isRequestingSignatures}
                                    onClick={async () => {
                                        const confirmed = await confirm({
                                            text: 'Are you sure you want to request signatures?',
                                        });
                                        if (!confirmed) return;
                                        this.setState({ isRequestingSignatures: true }, () => {
                                            ProjectsApi.requestProjectSignatures(this.props.projectId).then(() => {
                                                setTimeout(() => {
                                                    this.setState({
                                                        isRequestingSignatures: false,
                                                    });
                                                }, 2000);
                                            });
                                        });
                                    }}
                                >
                                    Request Signatures
                                </button>
                            )}
                        </div>
                    </div>
                )}
                <div className="end-buttons_format Edit-section">
                    {isEditing ? (
                        <>
                            <button
                                className="End-button"
                                type="button"
                                onClick={async () => {
                                    if (dirty) {
                                        const confirmed = await confirm({
                                            text: 'Are you sure you want to cancel?',
                                        });
                                        if (!confirmed) return;
                                    }
                                    resetForm();
                                    if (!isNew) this.handleEditMode(false);
                                }}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </button>
                            <button
                                className="End-button"
                                type="button"
                                disabled={isSubmitting}
                                onClick={async () => {
                                    if (isNew && isValid) {
                                        const confirmed = await confirm({
                                            text: 'Are you sure you want to Initiate the Project?',
                                        });
                                        if (!confirmed) return;
                                    }
                                    submitForm();
                                }}
                            >
                                {isNew ? 'Initiate New Project' : 'Save Changes'}
                            </button>
                        </>
                    ) : (
                        <button className="End-button" type="button" onClick={() => this.handleEditMode(true)}>
                            Edit Project
                        </button>
                    )}
                </div>
            </Fieldset>
        );
    }
}

export default SignaturesSection;
