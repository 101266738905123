import React from 'react';
import ErgoMoney from '../../utils/ErgoMoney';

const AuditRenderer = (params) => {
    let auditNTE = 750000;
    if (params.value >= auditNTE) {
        return (
            <span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    viewBox="0 0 24 24"
                    fill="red"
                    width="18px"
                    height="18px"
                >
                    <rect fill="none" height="24" width="24" />
                    <path d="M19,5v9l-5,0l0,5H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h10l6-6V5C21,3.9,20.1,3,19,3z M12,14H7v-2h5V14z M17,10H7V8h10V10z" />
                </svg>
                {new ErgoMoney(params.value).getStringAmount()}
            </span>
        );
    }
    return <span> {new ErgoMoney(params.value).getStringAmount()} </span>;
};

export default AuditRenderer;
