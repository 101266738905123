// React
import React from 'react';
import moment from 'moment';
import _find from 'lodash/find';

// Libraries
import { Formik, Form } from 'formik';
import LabeledField from '../../components/forms/LabeledField';
import yup from '../../yup';

// Component
import Message from '../../components/messages/Message';
import ErgoMoney from '../../utils/ErgoMoney';
import errorToast from '../../components/messages/ErrorMessage';

// API
import ProjectsApi from '../../api/projects';
import ResidualFundsApi from '../../api/residualFunds';

class TransferFundsForm extends React.Component {
    constructor(props) {
        super(props);

        this.calculateFundsAvailable = this.calculateFundsAvailable.bind(this);
        this.makeOption = this.makeOption.bind(this);
        this.makeOptions = this.makeOptions.bind(this);

        this.state = {
            destination: null,
            destinationId: null,
            destinationType: null,
            listRowIndex: [],
            project: null,
            projects: null,
            projectOptions: [],
            residualFund: null,
            residualFunds: [],
            residualFundOptions: [],
            source: '',
            sourceId: '',
            sourceType: null,
            transferType: null,
            financialInfo: [],
            fundsAvailable: null,
            transferTypeOptions: [
                {
                    label: 'Transfer Funds Between Projects',
                    value: 'Project-Project',
                    source_type: 'Project',
                    destination_type: 'Project',
                },
                {
                    label: 'Transfer Funds From Project to Residual Fund',
                    value: 'Project-Residual',
                    source_type: 'Project',
                    destination_type: 'ResidualFund',
                },
                {
                    label: 'Transfer Funds From Residual Fund to Project',
                    value: 'Residual-Project',
                    source_type: 'ResidualFund',
                    destination_type: 'Project',
                },
                {
                    label: 'Transfer Funds From Residual Fund to Residual Fund',
                    value: 'Residual-Residual',
                    source_type: 'ResidualFund',
                    destination_type: 'ResidualFund',
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            let ResidualFundId = this.props.residualFundId;
            const { data: residualFunds } = await ResidualFundsApi.getResidualFundWithFundsTotal();
            const { data: projects } = await ProjectsApi.fetchProjectNames();
            let finInfo = [];
            for (let i = 0; i < projects.length; i++) {
                const { data: projectFinInfo } = await ProjectsApi.fetchProjectFinancialInformation(projects[i].id);
                projectFinInfo.sourceId = projects[i].id;
                finInfo.push(projectFinInfo);
            }

            let residualFundOptions = this.makeOptions(residualFunds);
            let projectOptions = this.makeOptions(projects);

            let residualFund = _find(residualFunds, { id: ResidualFundId });

            let transferType = '';
            let sourceType = '';
            let sourceId = '';
            let source = null;
            let destinationType = null;

            if (residualFund) {
                transferType = 'Residual-Project';
                sourceType = 'ResidualFund';
                sourceId = residualFund.id;
                source = residualFund;
                destinationType = 'Project';
            } else {
                transferType = this.state.transferTypeOptions[0].value;
            }

            this.calculateFundsAvailable(this.state.source);

            this.setState({
                destinationType,
                projects,
                projectOptions,
                residualFunds,
                residualFundOptions,
                source,
                sourceId,
                sourceType,
                transferType,
                financialInfo: finInfo,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    componentDidUpdate = (previousProps) => {
        if (previousProps.financialPatches !== this.props.financialPatches && this.props.financialPatches.length > 0) {
            for (const financialPatch of this.props.financialPatches) {
                if (financialPatch.type === 'residualFunds') {
                    this.setState({ residualFunds: financialPatch.data });

                    if (financialPatch.source) {
                        const residualFund = financialPatch.data.find((patch) => patch.id === financialPatch.id);

                        if (residualFund) {
                            this.calculateFundsAvailable(residualFund);
                        }
                    }
                } else if (financialPatch.type === 'project') {
                    const projectFinancialInfos = this.state.financialInfo.filter(
                        (financialInfo) => financialInfo.sourceId !== financialPatch.id
                    );
                    projectFinancialInfos.push(financialPatch.data);
                    this.setState({ financialInfo: projectFinancialInfos });
                    this.calculateFundsAvailable(financialPatch.data);
                }
            }
        }
        if (previousProps.rowToUpdate !== this.props.rowToUpdate) {
            const { rowToUpdate } = this.props;
            const { transferTypeOptions } = this.state;

            if (rowToUpdate) {
                if (rowToUpdate.source_type === 'ResidualFund') {
                    const resFund = this.state.residualFunds.find((f) => f.id === rowToUpdate.source_id);
                    if (resFund) {
                        this.calculateFundsAvailable(resFund);
                    }
                } else {
                    this.calculateFundsAvailable(rowToUpdate.source);
                }

                let sourceType = rowToUpdate.source_type;
                let destinationType = rowToUpdate.destination_type;
                let transferType;
                if (sourceType === 'ResidualFund') {
                    if (destinationType === 'Project') {
                        transferType = 'Residual-Project';
                    } else if (destinationType === 'ResidualFund') {
                        transferType = 'Residual-Residual';
                    } else {
                        errorToast('Unknown destination type');
                    }
                } else if (sourceType === 'Project') {
                    if (destinationType === 'Project') {
                        transferType = 'Project-Project';
                    } else if (destinationType === 'ResidualFund') {
                        transferType = 'Project-Residual';
                    } else {
                        errorToast('Unknown destination type');
                    }
                } else {
                    errorToast('Unknown source type');
                }

                transferType = _find(transferTypeOptions, { value: transferType });

                this.setState({
                    destinationType: rowToUpdate.destination_type,
                    sourceType: rowToUpdate.source_type,
                    transferType: transferType.value,
                });
            }
        }
    };

    makeOption(thing) {
        let option = {
            label: '',
            value: '',
            object: {},
        };
        if (thing && thing.name && thing.id) {
            option.label = thing.name;
            option.value = thing.id;
            option.object = thing;
        }
        return option;
    }

    makeOptions(things) {
        let options = [];
        things.forEach((thing) => {
            options.push(this.makeOption(thing));
        });
        return options;
    }

    calculateFundsAvailable(source) {
        let residualFunds = 0;
        let funding = 0;
        let result = 0;
        if (source) {
            if (source.hasOwnProperty('funds_total')) {
                // ResidualFund is source
                residualFunds = source.funds_total;
            } else {
                // Project is source
                if (source.remaining_funds) {
                    funding = source.remaining_funds;
                } else if (this.state.financialInfo.lenght > 0) {
                    const sourceId = source.id ? source.id : source.sourceId;
                    let fundingArr = this.state.financialInfo.filter((e) => e.sourceId === sourceId);
                    funding = fundingArr[0].remaining_funds;
                }
                residualFunds = funding;
            }
            result = residualFunds;
        }

        this.setState({
            fundsAvailable: result,
            source,
            sourceId: source.id || source.sourceId,
        });
    }

    render() {
        if (!this.state.financialInfo.length > 0 || !this.state.projects) return <div>...loading</div>;

        const { rowToUpdate, handleSubmit, cancelEdit } = this.props;

        let userName = '';
        let user = localStorage.getItem('WBI.USER');
        if (user !== null) {
            userName = JSON.parse(user).name;
        }

        let sourceLabel = '';
        let sourceOptions = [];
        if (this.state.transferType && this.state.transferType.startsWith('Residual')) {
            sourceLabel = 'Funds from AFRL Symbol*';
            sourceOptions = this.state.residualFundOptions;
        } else {
            sourceLabel = 'Funds from Project*';
            sourceOptions = this.state.projectOptions;
        }

        let destinationLabel = '';
        let destinationOptions = [];
        if (this.state.transferType && this.state.transferType.endsWith('Project')) {
            destinationLabel = 'Funds to WBI Project*';
            destinationOptions = this.state.projectOptions;
        } else {
            destinationLabel = 'Funds to AFRL Symbol*';
            destinationOptions = this.state.residualFundOptions;
        }

        let name = '';
        if (this.state.source) {
            name = this.state.source.name;
        }

        if (this.state.sourceType === this.state.destinationType) {
            destinationOptions = destinationOptions.filter((option) => option.value !== this.state.sourceId);
        }

        let formInitValues;
        if (rowToUpdate) {
            formInitValues = {
                transferType: this.state.transferType,
                initiator: rowToUpdate.initiator.name,
                date: rowToUpdate.transaction_date,
                name: rowToUpdate.source.name,
                funds_available: this.state.fundsAvailable,
                amount: rowToUpdate.amount,
                description: rowToUpdate.description,
                reason: rowToUpdate.reason,
                link_to_db: rowToUpdate.link_to_db,
                source_id: this.state.sourceId,
                destination_id: rowToUpdate.destination_id,
                source_type: rowToUpdate.source_type,
                destination_type: rowToUpdate.destination_type,
            };
        } else {
            formInitValues = {
                transferType: this.state.transferType,
                initiator: userName,
                date: moment().format('YYYY-MM-DD'),
                name,
                funds_available: this.state.fundsAvailable,
                amount: '',
                description: '',
                reason: '',
                link_to_db: '',
                source_id: this.state.sourceId,
                destination_id: '',
                source_type: '',
                destination_type: '',
            };
        }

        return (
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={formInitValues}
                    validationSchema={yup.object().shape({
                        transferType: yup.string().required(),
                        amount: yup.number().required(),
                        description: yup.string(),
                        reason: yup.string(),
                        link_to_db: yup.string(),
                        source_id: yup.string().required(),
                        destination_id: yup.string().required(),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, isSubmitting, resetForm, setFieldValue }) => (
                        <Form>
                            <LabeledField
                                label="Initiated By"
                                name="initiator"
                                type="text"
                                errors={errors}
                                touched={touched}
                                readOnly
                            />
                            <LabeledField
                                label="Date"
                                name="date"
                                type="date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                                readOnly
                            />
                            <LabeledField
                                label="Transfer Type*"
                                name="transferType"
                                component="select"
                                options={this.state.transferTypeOptions}
                                onChange={(selection) => {
                                    if (selection) {
                                        this.setState(
                                            {
                                                transferType: selection.value,
                                                sourceType: selection.source_type,
                                                destinationType: selection.destination_type,
                                            },
                                            () => {
                                                setFieldValue('transferType', selection.value);
                                                setFieldValue('source_type', selection.source_type);
                                                setFieldValue('destination_type', selection.destination_type);
                                            }
                                        );
                                    }
                                }}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label={sourceLabel}
                                name="source_id"
                                component="select"
                                options={sourceOptions}
                                onChange={(selection) => {
                                    if (selection) setFieldValue('source_id', selection.value);
                                    this.calculateFundsAvailable(selection.object);
                                }}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Funds Available"
                                name="funds_available"
                                value={new ErgoMoney(this.state.fundsAvailable).getStringAmount()}
                                type="text"
                                readOnly
                            />
                            <LabeledField
                                label={destinationLabel}
                                name="destination_id"
                                component="select"
                                options={destinationOptions}
                                onChange={(selection) => {
                                    if (selection) {
                                        this.setState(
                                            {
                                                destination: selection.object,
                                                destinationId: selection.object.id,
                                            },
                                            () => {
                                                setFieldValue('destination_id', selection);
                                            }
                                        );
                                    }
                                }}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Amount*"
                                name="amount"
                                component="Money"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Description of Transfer"
                                name="description"
                                type="text"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Reason of Transfer"
                                name="reason"
                                type="text"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Link to DB"
                                name="link_to_db"
                                type="text"
                                errors={errors}
                                touched={touched}
                            />

                            {values.amount > this.state.fundsAvailable ? (
                                <Message value="*Amount cannot exceed Funds Available" />
                            ) : null}
                            <div className="Form__buttons--Reversed column-span-3 flex justify-content-end">
                                <button
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        cancelEdit();
                                        resetForm();
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" disabled={values.amount > this.state.fundsAvailable}>
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default TransferFundsForm;
