import React from 'react';

const GridSection = ({ title, area, action, children, className = '' }) => (
    <section className={`GridSection ${className}`} style={{ gridArea: area }}>
        <header>
            {title}
            <span>{action}</span>
        </header>
        <div className="Contents">{children}</div>
    </section>
);

export default GridSection;
