import React from 'react';
import Select from 'react-select';
import ReportSubnav from './ReportsSubNav';
import PageHeader from '../components/heading/PageHeader';
import { ProjectManagerForm } from './ReportForms';
import ReportHeader from './ReportHeader';
import ProjectsApi from '../api/projects';
import ActionItemSummary from './output/PMROutput/ActionItemSummary';
import MilestoneDeliverablesStatus from './output/PMROutput/MilestoneDeliverablesStatus';
import ProjectSummaries from './output/PMROutput/ProjectSummaries';

// ******* TODO Filter based on Version Date ********

class ProjectManagerPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isPM: false,
            reportType: [
                {
                    name: 'Action Items Summary',
                    inputId: 1,
                    reportOutput: 1,
                },
                {
                    name: 'Milestone/Deliverables Status Report',
                    inputId: 1,
                    reportOutput: 2,
                },
                {
                    name: 'Project Summary',
                    inputId: 1,
                    reportOutput: 3,
                },
            ],
            selectedReportType: null,
            selectedValue: 2,
            isSubmitted: false,
            user: JSON.parse(localStorage.getItem('WBI.USER')),
            projects: null,
            isReady: false,
            message: 'Loading...',
        };

        this.handleBasicSubmit = this.handleBasicSubmit.bind(this);
    }

    componentDidMount = async () => {
        const { data: pmprojects } = await ProjectsApi.fetchProjectManagerProjects();
        if (pmprojects.length > 0) {
            this.setState({
                isPM: true,
                projects: pmprojects,
            });
        } else {
            this.setState({ message: 'You currently do not have any projects that you are managing.' });
        }
    };

    handleBasicSubmit(values, actions) {
        this.setState({
            date: values.date,
            isReady: true,
        });
        actions.resetForm();
        actions.setSubmitting(false);
    }

    updateContent(e) {
        let item = e;
        this.setState({
            selectedReportType: item,
            isReady: false,
        });
    }

    render() {
        const { reportType, selectedReportType, projects, date, isPM, isReady, message } = this.state;

        let reportOutput;

        if (isReady) {
            switch (selectedReportType.reportOutput) {
                case 1:
                    reportOutput = <ActionItemSummary data={projects} date={date} />;
                    break;
                case 2:
                    reportOutput = <MilestoneDeliverablesStatus data={projects} date={date} />;
                    break;
                case 3:
                    reportOutput = <ProjectSummaries data={projects} date={date} />;
                    break;
                default:
                    break;
            }
        }

        let reportOptions = [];
        let i;
        if (reportType) {
            for (i = 0; i < reportType.length; i++) {
                reportOptions.push({
                    value: reportType[i].inputId,
                    label: reportType[i].name,
                    reportOutput: reportType[i].reportOutput,
                });
            }
        }

        return (
            <div>
                <PageHeader title="Reports" />
                <ReportSubnav />
                <div className="Wide-single-layout">
                    {isPM ? (
                        <div className="Inline-form-container">
                            <div className="Report-form-container">
                                <label className="FieldLabel">Report</label>
                                <Select
                                    value={selectedReportType}
                                    options={reportOptions}
                                    onChange={(e) => {
                                        this.updateContent(e);
                                    }}
                                />
                            </div>
                            <div className="Pm-r-date-form">
                                <ProjectManagerForm handleSubmit={this.handleBasicSubmit} />
                            </div>
                        </div>
                    ) : (
                        <div>{message}</div>
                    )}
                </div>
                <div className="Wide-single-layout Report-card">
                    {isReady ? (
                        <div>
                            <ReportHeader title={selectedReportType.label} />
                            {reportOutput}
                        </div>
                    ) : (
                        <h3>Please select a report to run </h3>
                    )}
                </div>
            </div>
        );
    }
}

export default ProjectManagerPage;
