import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { sanitizeNumbers } from './HelperFunctions';
import LabeledField from '../../components/forms/LabeledField';
import errorToast from '../../components/messages/ErrorMessage';
import UpdateItineraryModal from '../travelRequest/UpdateItineraryModal';
import UserApi from '../../api/users';
import CoreBudgetApi from '../../api/coreBudgetTypes';
import TravelExpenseApi from '../../api/travelExpenses';
import ProjectsApi from '../../api/projects';
import { prepareUserOptions, prepareApproverOptions } from '../../utils/UserUtils';
import prepareCoreFundOptions from '../../utils/CoreFundUtils';
import { isProjectStatusArchived } from '../../utils/ProjectUtils';

export const TravelExpenseForm = (props) => {
    const createDefaultItineraryEntry = () => ({
        city_state: '',
        zip_code: '',
        start_date: '',
        end_date: '',
        per_diem_rate: '',
        max_lodge_rate: '',
        mileage_rate: '',
        notes: '',
    });

    const createDefaultTripValues = () => ({
        cc_holder_id: '',
        preparer_id: '',
        approver_id: '',
        core_account_id: '',
        address: '',
        itinerary: [],
    });

    const createDefaultFormValues = () => {
        const defaultTripValues = createDefaultTripValues();
        const defaultItineraryEntry = createDefaultItineraryEntry();
        defaultTripValues.itinerary.push(defaultItineraryEntry);
        return defaultTripValues;
    };

    const [draftTravelExpense, setDraftTravelExpense] = useState(false);
    const [approverOptions, setApproverOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [coreAccountOptions, setCoreAccountOptions] = useState([]);
    const [multiCity, setMultiCity] = useState(false);
    const [isProjectArchived, setIsProjectArchived] = useState(false);
    const [formValues, setFormValues] = useState(createDefaultFormValues());
    const [visibleCity, setVisibleCity] = useState(0);

    const setOnMultiCityOnItineraryLength = (itinerary) => {
        try {
            if (itinerary.length > 1) {
                setMultiCity(true);
            } else {
                setMultiCity(false);
            }
        } catch (error) {
            errorToast(error);
        }
    };

    const createFormValuesFromRowToUpdate = (rowToUpdate) => {
        try {
            const { cc_holder_id, preparer_id, address, itinerary, approver_id, account_id } = rowToUpdate;
            const defaultFormValues = createDefaultFormValues();

            defaultFormValues.cc_holder_id = cc_holder_id;
            defaultFormValues.preparer_id = preparer_id;
            defaultFormValues.address = address;
            defaultFormValues.itinerary = itinerary;
            defaultFormValues.approver_id = approver_id;
            defaultFormValues.account_id = account_id;
            defaultFormValues.core_account_id = account_id;

            for (const key in defaultFormValues) {
                if (!defaultFormValues[key]) {
                    defaultFormValues[key] = '';
                }
            }

            return defaultFormValues;
        } catch (error) {
            return errorToast(error);
        }
    };

    useEffect(() => {
        try {
            const { rowToUpdate } = props;
            if (rowToUpdate) {
                const newFormValues = createFormValuesFromRowToUpdate(rowToUpdate);
                setFormValues(newFormValues);
                setOnMultiCityOnItineraryLength(newFormValues.itinerary);
            } else {
                const defaultFormValues = createDefaultFormValues();
                setFormValues(defaultFormValues);
            }
        } catch (error) {
            errorToast(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rowToUpdate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: users } = await UserApi.getUsers();
                const userOptions = prepareUserOptions(users);
                setUserOptions(userOptions);
                const approverOptions = prepareApproverOptions(users);
                setApproverOptions(approverOptions);

                const { isProject, projectId } = props;
                if (!isProject) {
                    const { data: coreFunds } = await CoreBudgetApi.getCoreBudgetTypes();
                    const coreFundOptions = prepareCoreFundOptions(coreFunds);
                    setCoreAccountOptions(coreFundOptions);
                } else {
                    const { data: project } = await ProjectsApi.fetchProject(projectId);
                    const archived = isProjectStatusArchived(project);
                    setIsProjectArchived(archived);
                }
            } catch (errorMessage) {
                errorToast(errorMessage);
            }
        };
        fetchData();
    }, [props]);

    const resetForm = (formikBag) => {
        const { clearAllData } = props;
        clearAllData();
        setVisibleCity(0);
        setMultiCity(false);
        formikBag.setSubmitting(false);
        formikBag.resetForm(createDefaultFormValues());
    };

    const hoistTravelExpenseForPerDayCalc = (values) => {
        const { hoistTravelExpenseForPerDayCalc } = props;
        hoistTravelExpenseForPerDayCalc(values);
    };

    const selectItineraryField = (values, setFieldValue) => (
        <Fragment>
            <label className="FieldLabel itinerary-label">Itinerary</label>
            <div className="Travel-itinerary-select-container">
                <select
                    className="Travel-itinerary-select"
                    onChange={(event) => {
                        setVisibleCity(parseInt(event.target.value, 10));
                    }}
                >
                    {values.itinerary.map((item, index) => (
                        <option key={index} value={index} selected={index === visibleCity && 'True'}>
                            ({index + 1}) {item.city_state}
                        </option>
                    ))}
                </select>
            </div>
            <UpdateItineraryModal
                setVis={setVisibleCity}
                values={values}
                setField={setFieldValue}
                updateHook={hoistTravelExpenseForPerDayCalc}
            />
        </Fragment>
    );

    const isAccountYupRequired = (isProject) => {
        let accountRequired = yup.string();
        if (!isProject) {
            accountRequired = yup.string().required();
        }
        return accountRequired;
    };

    const createYupValidationSchema = (isProject) =>
        yup.object().shape({
            cc_holder_id: yup.string().required(),
            preparer_id: yup.string().required(),
            address: yup.string().required(),
            approver_id: yup.string().required(),
            core_account_id: isAccountYupRequired(isProject),
            itinerary: yup
                .array()
                .of(
                    yup.object().shape({
                        city_state: yup.string().required(),
                        zip_code: yup.string().required(),
                        start_date: yup.date().required(),
                        end_date: yup
                            .date()
                            .min(yup.ref('start_date'), 'Date of Return cannot be before Date of Arrival')
                            .required(),
                        per_diem_rate: yup.string().required(),
                        max_lodge_rate: yup.string().required(),
                        mileage_rate: yup.string().required(),
                        notes: yup.string(),
                    })
                )
                .min(1),
        });

    const validateTravelExpenseFormValues = (values) => {
        const { isProject, projectId } = props;
        const travelExpense = { ...values };

        if (isProject) {
            travelExpense.account_id = projectId;
            travelExpense.account_type = 'Project';
        } else {
            travelExpense.account_id = values.core_account_id;
            travelExpense.account_type = 'CoreBudgetType';
        }
        delete travelExpense.core_account_id;
        return travelExpense;
    };

    const callSubmitCallbacks = () => {
        const { setTravelExpenseId, setCardHolderId, refreshHistoryTable, setRowToUpdate } = props;
        setTravelExpenseId(null);
        setCardHolderId({ value: null });
        setRowToUpdate({ event: null });
        refreshHistoryTable();
    };

    const saveDraftTravelExpense = async (travelExpense) => {
        try {
            travelExpense.in_draft = draftTravelExpense;
            setDraftTravelExpense(false);
            await TravelExpenseApi.createTravelExpenseDraft(travelExpense);
            callSubmitCallbacks();
        } catch (error) {
            errorToast(error);
        }
    };

    const updateTravelExpense = async (travelExpense) => {
        try {
            const { rowToUpdate, expenseId } = props;
            let id = null;
            if (rowToUpdate) {
                id = rowToUpdate.id;
            } else if (expenseId) {
                id = expenseId;
            } else {
                throw new Error('No expense ID');
            }

            travelExpense.in_draft = draftTravelExpense;
            setDraftTravelExpense(false);

            await TravelExpenseApi.updateTravelExpense(id, travelExpense);
            callSubmitCallbacks();
        } catch (error) {
            errorToast(error);
        }
    };

    const submitTravelExpense = async (travelExpense) => {
        try {
            // We set in_draft to true because if we get here,
            // we are adding an expense with no expenditures which is defined
            // as in_draft
            travelExpense.in_draft = true;
            await TravelExpenseApi.createTravelExpense(travelExpense);
            callSubmitCallbacks();
        } catch (error) {
            errorToast(error);
        }
    };

    const handleSubmitEvent = (values, formikBag) => {
        const { expenseId, rowToUpdate } = props;
        const travelExpense = validateTravelExpenseFormValues(values);

        if (draftTravelExpense && !(rowToUpdate || expenseId)) {
            saveDraftTravelExpense(travelExpense);
        } else if (rowToUpdate || expenseId) {
            updateTravelExpense(travelExpense);
        } else {
            submitTravelExpense(travelExpense);
        }

        resetForm(formikBag);
    };

    const handleTravelerChangeEvent = (travelerOption) => {
        const { setCardHolderId } = props;
        setCardHolderId(travelerOption.value);
    };

    const handleAccountChangeEvent = (accountOption, setFieldValue) => {
        const { setAccountId } = props;

        setFieldValue('core_account_id', accountOption.value);
        setAccountId(accountOption.value);
    };

    const handleAddCityEvent = (values) => {
        try {
            setMultiCity(true);
            values.itinerary.push(createDefaultItineraryEntry());
            setVisibleCity(values.itinerary.length - 1);
        } catch (error) {
            errorToast(error);
        }
    };

    const handleMileageRateChangeEvent = (fieldData, values, index) => {
        try {
            const tempValues = _.clone(values);
            tempValues.itinerary[index].mileage_rate = sanitizeNumbers(fieldData);
            hoistTravelExpenseForPerDayCalc(tempValues);
        } catch (error) {
            errorToast(error);
        }
    };

    const handlePerDiemRateChangeEvent = (fieldData, values, index) => {
        try {
            const tempValues = _.clone(values);
            tempValues.itinerary[index].per_diem_rate = sanitizeNumbers(fieldData);
            hoistTravelExpenseForPerDayCalc(tempValues);
        } catch (error) {
            errorToast(error);
        }
    };

    const handleDateOfReturnChangeEvent = (fieldData, values, index) => {
        try {
            const tempValues = _.clone(values);
            tempValues.itinerary[index].end_date = fieldData.target.value;
            hoistTravelExpenseForPerDayCalc(tempValues);
        } catch (error) {
            errorToast(error);
        }
    };

    const { isProject } = props;
    return (
        <Fragment>
            <div className="Companion-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={createYupValidationSchema(isProject)}
                    onSubmit={handleSubmitEvent}
                >
                    {({ errors, touched, setFieldValue, values, handleReset }) => (
                        <Form>
                            <LabeledField
                                label="Traveler*"
                                name="cc_holder_id"
                                component="select"
                                options={userOptions}
                                getValue={handleTravelerChangeEvent}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField label="Personal Address*" name="address" errors={errors} touched={touched} />
                            <LabeledField
                                label="Prepared By*"
                                name="preparer_id"
                                component="select"
                                options={userOptions}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Approver*"
                                name="approver_id"
                                component="select"
                                options={approverOptions}
                                errors={errors}
                                touched={touched}
                            />
                            {!isProject && (
                                <LabeledField
                                    label="Account No*"
                                    name="core_account_id"
                                    component="select"
                                    options={coreAccountOptions}
                                    onChange={(accountOption) => handleAccountChangeEvent(accountOption, setFieldValue)}
                                    errors={errors}
                                    touched={touched}
                                />
                            )}
                            {multiCity && selectItineraryField(values, setFieldValue)}
                            <div className="text-align-right Additional-city-button">
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleAddCityEvent(values);
                                    }}
                                >
                                    Add Additional City
                                </button>
                            </div>
                            <FieldArray
                                name="itinerary"
                                render={() => (
                                    <div className="column-span-3">
                                        {values.itinerary.map((itineraryEntry, index) => (
                                            <div
                                                id={`vis_${index}`}
                                                key={index}
                                                className={`grid columns-3 ${index !== visibleCity && 'hidden'}`}
                                            >
                                                <LabeledField
                                                    label="Destination City, State*"
                                                    name={`itinerary.${index}.city_state`}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Destination ZIP Code*"
                                                    name={`itinerary.${index}.zip_code`}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Date of Arrival*"
                                                    name={`itinerary.${index}.start_date`}
                                                    component="Date"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Date of Return*"
                                                    name={`itinerary.${index}.end_date`}
                                                    component="Date"
                                                    onChange={(fieldData) =>
                                                        handleDateOfReturnChangeEvent(fieldData, values, index)
                                                    }
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Per Diem Rate (M&IE)*"
                                                    name={`itinerary.${index}.per_diem_rate`}
                                                    component="Money"
                                                    getOnChangeValue={(fieldData) =>
                                                        handlePerDiemRateChangeEvent(fieldData, values, index)
                                                    }
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Maximum Lodging Rate*"
                                                    name={`itinerary.${index}.max_lodge_rate`}
                                                    component="Money"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Mileage Rate*"
                                                    name={`itinerary.${index}.mileage_rate`}
                                                    component="Money"
                                                    getOnChangeValue={(fieldData) =>
                                                        handleMileageRateChangeEvent(fieldData, values, index)
                                                    }
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Travel Purpose"
                                                    name={`itinerary.${index}.notes`}
                                                    component="textarea"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button type="button" onClick={handleReset}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isProjectArchived}
                                    onClick={setDraftTravelExpense(true)}
                                >
                                    Save Draft
                                </button>
                                <button
                                    type="submit"
                                    onClick={setDraftTravelExpense(false)}
                                    disabled={isProjectArchived}
                                >
                                    Submit Expense
                                </button>
                                <div>
                                    <span style={{ fontSize: '0.8em', color: '#707070', marginLeft: '17px' }}>
                                        Note: Submitting this will not save any changes to your expenditure
                                    </span>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export default TravelExpenseForm;
