import React from 'react';
import LabeledField from '../../components/forms/LabeledField';

export default function AddAgreementInfo({ values, errors, touched, edit }) {
    return (
        <>
            <LabeledField
                label="Agreement No."
                name="number"
                component="input"
                value={values.number}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mr2"
            />
            <LabeledField
                label="Agreement Description"
                name="description"
                component="input"
                value={values.description}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mr2 mt2"
            />
            <LabeledField
                label="Agreement Type"
                name="type"
                component="select"
                options={[
                    { value: 'purchase_order', label: 'Purchase Order' },
                    { value: 'subrecipient', label: 'Subrecipient' },
                    { value: 'contract', label: 'Contract' },
                    { value: 'consulting', label: 'Consulting' },
                ]}
                value={values.type}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mr2 mt2"
            />
            <LabeledField
                label="Not to Exceed (NTE)"
                name="nte"
                component="Money"
                value={values.nte}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mr2 mt2"
            />
            <div className="flex flex-wrap mt2 mb2">
                <div className="flex-column mr3">
                    <LabeledField
                        label="Start Date"
                        name="start_date"
                        component="Date"
                        value={values.start_date}
                        errors={errors}
                        touched={touched}
                        labelClassName="text-align-left"
                    />
                </div>
                <div className="flex-column">
                    <LabeledField
                        label="End Date"
                        name="end_date"
                        component="Date"
                        value={values.end_date}
                        errors={errors}
                        touched={touched}
                        labelClassName="text-align-left"
                    />
                </div>
            </div>
        </>
    );
}
