import React from 'react';
import LabeledField from '../../components/forms/LabeledField';

export default function AmendmentInfo({ errors, touched }) {
    return (
        <>
            <LabeledField
                label="New Agreement No."
                name="number"
                component="input"
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
            <LabeledField
                label="Dropbox Link to Document"
                name="doc_link"
                component="input"
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />

            <LabeledField
                label="Primary Change"
                name="change_type"
                component="select"
                options={[
                    { value: 'updated_change_request', label: 'Updated change request' },
                    { value: 'modified_timelines', label: 'Modified Timelines (including cancellations)' },
                    { value: 'added_work', label: 'Added work / Scope change' },
                    { value: 'funding_update', label: 'Funding Update' },
                    { value: 'terms_and_conditions', label: 'Terms & Conditions change' },
                ]}
                // value={values.type}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mr2 mt2"
            />

            <LabeledField
                label="Notes (Optional)"
                name="notes"
                component="textarea"
                errors={errors}
                touched={touched}
                labelClassName="text-align-left ml2 mr2"
            />
        </>
    );
}
