import React from 'react';
import LabeledField from '../../components/forms/LabeledField';

export default function AddVendorAccountInfo({ errors, touched, values }) {
    return (
        <>
            <LabeledField
                label="Approved"
                name="approved"
                component="Radio"
                currentValue={values.approved}
                options={[
                    { label: 'Yes', value: 'approved' },
                    { label: 'No', value: 'not_approved' },
                ]}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mb2"
            />
            <div className="InlineField">
                <LabeledField
                    label="Debarment &amp; Ineligibility Certification Not Verified"
                    name="watchlist"
                    component="input"
                    type="checkbox"
                    defaultChecked={values.watchlist}
                    errors={errors}
                    touched={touched}
                    labelClassName="ml2"
                />
            </div>
            <div className="mt2 flex flex-row">
                <div className="flex-column mr2 AlignedField">
                    <LabeledField
                        label="CAGE Code"
                        name="sams"
                        component="input"
                        value={values.sams}
                        errors={errors}
                        touched={touched}
                        labelClassName="text-align-left mr2"
                    />
                    <div style={{ margin: '3px', display: 'flex', justifyContent: 'flex-end' }}>
                        <span style={{ fontSize: '12px', textAlign: 'right' }}>{values.sams?.length}/5</span>
                    </div>
                </div>
                <div className="flex-column">
                    <LabeledField
                        label="Expires"
                        name="sams_expire_on"
                        component="Date"
                        value={values.sams_expire_on}
                        errors={errors}
                        touched={touched}
                        labelClassName="text-align-left"
                    />
                </div>
            </div>
            <div className="mt2 flex flex-row">
                <div className="flex-column mr2 AlignedField">
                    <LabeledField
                        label="UEI Number"
                        name="uei"
                        component="input"
                        value={values.uei}
                        errors={errors}
                        touched={touched}
                        labelClassName="text-align-left mr2"
                    />
                    <div style={{ margin: '3px', display: 'flex', justifyContent: 'flex-end' }}>
                        <span style={{ fontSize: '12px', textAlign: 'right' }}>{values.uei?.length}/12</span>
                    </div>
                </div>
                <div className="flex-column">
                    <LabeledField
                        label="Expires"
                        name="uei_expire_on"
                        component="Date"
                        value={values.uei_expire_on}
                        errors={errors}
                        touched={touched}
                        labelClassName="text-align-left"
                    />
                </div>
            </div>
            <LabeledField
                label="Notes"
                name="notes"
                component="textarea"
                value={values.notes}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mb2"
            />
        </>
    );
}
