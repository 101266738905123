import React, { useCallback, useEffect, useState } from 'react';
import EditingTable from '../../components/tables/EditingTable';
import errorToast from '../../components/messages/ErrorMessage';
import ReadOnlyFormModal from './ReadOnlyFormModal';
import { confirm } from '../../components/modals/ConfirmModal';
import TravelExpenseApi from '../../api/travelExpenses';

const TravelHistoryTable = (props) => {
    const [travelExpenses, setTravelExpenses] = useState([]);
    const [openModalFlag, setOpenModalFlag] = useState(false);
    const [modalTravelExpense, setModalTravelExpense] = useState(null);
    const [columns, setColumns] = useState([]);
    const [refreshTravelExpensesFlag, setRefreshTravelExpensesFlag] = useState(false);

    const deleteTravelExpense = async (travelExpense) => {
        try {
            await TravelExpenseApi.deleteTravelExpense(travelExpense.id);
        } catch (error) {
            errorToast(error);
        }
    };

    /* const handleRemoveRowEvent = async (event) => {
        try {
            const travelExpense = event.data;

            if (travelExpense.status === 'Applied') {
                await confirm({
                    text:
                        'This expense has already been applied and can no longer be deleted by a project user, please contact finance.',
                    acceptText: 'Ok',
                    rejectText: null,
                });
                return;
            }

            await deleteTravelExpense(travelExpense);
            setRefreshTravelExpensesFlag(true);
        } catch (error) {
            errorToast(error);
        }
    }; */

    const openModal = (travelExpense) => {
        setModalTravelExpense(travelExpense);
        setOpenModalFlag(true);
    };

    const handleOpenModalEvent = useCallback((event) => {
        openModal(event.data);
    }, []);

    const closeModal = () => {
        setOpenModalFlag(false);
    };

    const totalPersonalChargesValueGetter = (tableData) => {
        const travelExpense = tableData.data;
        const travelExpenditures = travelExpense.travel_expenditures;
        let amountSum = 0;

        amountSum = travelExpenditures
            .filter((travelExpenditure) => travelExpenditure.expenditure.cc_type === 'personal')
            .reduce((sum, travelExpenditure) => sum + travelExpenditure.expenditure.cost, amountSum);

        amountSum = travelExpenditures
            .filter((travelExpenditure) => travelExpenditure.travel_expenditure_type === 'Hotel')
            .reduce((sum, travelExpenditure) => sum - travelExpenditure.hotel_non_reimburse, amountSum);

        amountSum += travelExpense.per_diem_sum || 0;

        return amountSum;
    };

    const totalCompanyChargesValueGetter = (tableData) => {
        const travelExpenditures = tableData.data.travel_expenditures;
        let amountSum = 0;

        amountSum = travelExpenditures
            .filter((travelExpenditure) => travelExpenditure.expenditure.cc_type === 'business')
            .reduce((sum, travelExpenditure) => sum + travelExpenditure.expenditure.cost, amountSum);

        return amountSum;
    };

    const createColumns = useCallback(() => {
        const handleRemoveRowEvent = async (event) => {
            try {
                const travelExpense = event.data;
                if (travelExpense.status === 'Applied') {
                    await confirm({
                        text: 'This expense has already been applied and can no longer be deleted by a project user, please contact finance.',
                        acceptText: 'Ok',
                        rejectText: null,
                    });
                    return;
                }
                await deleteTravelExpense(travelExpense);
                setRefreshTravelExpensesFlag(true);
            } catch (error) {
                errorToast(error);
            }
        };
        return [
            {
                headerName: 'Traveler',
                type: 'name',
                field: 'traveler',
            },
            {
                headerName: 'Personal Charges',
                type: 'shortmoney',
                field: 'total_personal_charges',
                valueGetter: totalPersonalChargesValueGetter,
            },
            {
                headerName: 'Company Charges',
                type: 'shortmoney',
                field: 'total_company_charges',
                valueGetter: totalCompanyChargesValueGetter,
            },
            {
                headerName: 'Status',
                type: 'status',
                field: 'status',
            },
            {
                headerName: 'Approver',
                type: 'name',
                field: 'approver',
            },
            {
                headerName: 'Initiated By',
                type: 'name',
                field: 'initiator',
            },
            {
                headerName: 'Initiated On',
                type: 'date',
                field: 'created_on',
            },
            {
                headerName: 'Last Updated By',
                type: 'name',
                field: 'last_updater',
            },
            {
                headerName: 'Last Updated On',
                type: 'date',
                field: 'updated_on',
            },
            {
                headerName: 'View Full Expense',
                cellRenderer: () => '<a>View</a>',
                editable: 'false',
                onCellClicked: handleOpenModalEvent,
            },
            {
                headerName: 'Edit',
                field: 'edit',
                type: 'symbol',
                editable: 'false',
                cellRenderer: 'EditSymbolRenderer',
                onCellClicked: props.setRowToUpdate,
            },
            {
                headerName: 'Del.',
                field: 'delete',
                type: 'symbol',
                editable: 'false',
                cellRenderer: 'TrashSymbolRenderer',
                onCellClicked: handleRemoveRowEvent,
            },
        ];
    }, [props, handleOpenModalEvent]);

    const getIdForDataFetch = useCallback(() => {
        const { isProject, projectId } = props;
        let id = 'Project-' + projectId;
        if (!isProject) {
            id = 'CoreBudgetType';
        }
        return id;
    }, [props]);

    useEffect(() => {
        setColumns(createColumns());
    }, [createColumns]);

    const { tableRefresh } = props;
    useEffect(() => {
        setRefreshTravelExpensesFlag(true);
    }, [tableRefresh]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (refreshTravelExpensesFlag) {
                    const id = getIdForDataFetch();
                    const { data: travelExpenses } = await TravelExpenseApi.getTravelExpenses(id);
                    setTravelExpenses(travelExpenses);
                    setRefreshTravelExpensesFlag(false);
                }
            } catch (error) {
                errorToast(error);
            }
        };
        fetchData();
    }, [refreshTravelExpensesFlag, getIdForDataFetch]);

    return (
        <div className="Table-styling">
            <ReadOnlyFormModal openModal={openModalFlag} closeModal={closeModal} travelExpense={modalTravelExpense} />
            <EditingTable data={travelExpenses} columns={columns} sizeToFit={true} pagingProp={true} />
        </div>
    );
};

export default TravelHistoryTable;
