import React, { Component } from 'react';
import { shortDate } from '../../utils/utils';
import ErgoMoney from '../../utils/ErgoMoney';

import expendituresApi from '../../api/expenditures';
import errorToast from '../../components/messages/ErrorMessage';

class ExpApprovalPop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            approval: null,
        };
    }

    async componentDidMount() {
        try {
            const { data: approval } = await expendituresApi.fetchExpenditure(this.props.approval.target.id);
            this.setState({ approval });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        let status = false;
        let item = this.props.approval;

        if (!this.state.approval) {
            return <div>...Loading</div>;
        }
        status = this.state.approval.status;

        return (
            <div className="Card-styling">
                <table className="Approval_table">
                    <h6 className="Table_label">{item.title}</h6>
                    <tbody>
                        <tr>
                            <td className="Table_label">Type:</td>
                            <td>{item.target.type}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">CC Type:</td>
                            <td>{item.target.cc_type}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Initiated By:</td>
                            <td>{item.target.initiator_name}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Date:</td>
                            <td>{shortDate(item.target.purchase_date)}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Cost:</td>
                            <td>{new ErgoMoney(item.target.cost).getStringAmount()}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Store:</td>
                            <td>{item.target.store}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Description:</td>
                            <td>{item.target.item_description}</td>
                        </tr>
                        {status !== 'Pending' && (
                            <tr>
                                <td className="Table_label">Status:</td>
                                <td>{status}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {status === 'Pending' && (
                    <div className="Form__buttons--Reversed Card-buttons">
                        <button type="button" onClick={() => this.props.reject(item.target.id, 'expenditure')}>
                            Reject
                        </button>
                        <button type="button" onClick={() => this.props.approve(item.target.id, 'expenditure')}>
                            Approve
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default ExpApprovalPop;
