import api from './api';

import endpoints from './endpoints';

const commercialsRoute = endpoints.COMMERCIAL_CONTRACTS;

export default {
    getCommercialContracts() {
        return api.get(commercialsRoute, 'Error getting commercial contracts');
    },

    createCommercialContract(attributes) {
        return api.post(commercialsRoute, attributes, 'Error creating a commercial contract');
    },

    updateCommercialContract(cc_id, attributes) {
        return api.put(`${commercialsRoute}/${cc_id}`, attributes, 'Error updating commercial contract');
    },

    removeCommercialContract(cc_id) {
        return api.delete(`${commercialsRoute}/${cc_id}`, 'Error removing commercial contract');
    },

    updateCCProject(cc_id, id, attributes) {
        return api.put(
            `${commercialsRoute}/${cc_id}/commercial_projects/${id}`,
            attributes,
            'Error updating a commercial contract project'
        );
    },

    removeCCProject(cc_id, id) {
        return api.delete(
            `${commercialsRoute}/${cc_id}/commercial_projects/${id}`,
            'Error removing a commercial contract project'
        );
    },
};
