import React from 'react';
import oPath from 'object-path';
import { Field } from 'formik';
import classNames from 'classnames';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Select from '../select/Select';
import Radio from '../buttons/Radio';
import Checkbox from '../buttons/Checkbox';
import Rating from '../ratings/Rating';
import { phoneFormat } from '../../utils/utils';
import ErgoMoney from '../../utils/ErgoMoney';

const FieldWithError = ({ errors, touched, className, ...props }) => {
    const childProps = props;
    let readOnly;
    let Component;
    let render;
    delete childProps.render;
    switch (props.component) {
        case 'select':
            Component = Field;
            delete childProps.component;
            render = ({ field, form: { setFieldValue } }) => (
                <Select
                    {...field}
                    onChange={(option) => {
                        setFieldValue(field.name, option && typeof option === 'object' ? option.value : option);
                        if (props.getValue) {
                            props.getValue(option);
                        }
                    }}
                    {...childProps}
                />
            );
            break;
        case 'Date':
            Component = Field;
            readOnly = childProps.readOnly;
            delete childProps.component;
            render = ({ field, form: { setFieldValue } }) => {
                const tempDt = field.value || childProps.value;
                let dt = tempDt ? moment.utc(tempDt) : '';
                const dtformatted = dt ? dt.format('YYYY-MM-DD') : '';
                if (readOnly) {
                    return <div className={props.fieldclassname}>{dt ? dt.format('YYYY-MM-DD') : ''}</div>;
                }
                // Remove to get rid of warning about child props render propertie having invalid value
                delete childProps.render;
                return (
                    <input
                        {...field}
                        id="date"
                        type="date"
                        value={dtformatted}
                        onChange={(evt) => {
                            console.log({ date: evt.target.value });
                            let day = evt.target.value;
                            setFieldValue(field.name, day && typeof day === 'object' ? day.toDate() : day);
                        }}
                        {...childProps}
                    />
                );
            };

            break;
        case 'Radio':
            Component = Radio;
            childProps.inputComponent = Field;
            break;
        case 'Checkbox':
            Component = Checkbox;
            delete childProps.component;
            childProps.inputComponent = Field;
            break;
        case 'Rating':
            Component = Field;
            delete childProps.component;
            render = ({ field, form: { setFieldValue } }) => (
                <Rating {...field} onChange={(value) => setFieldValue(field.name, value)} {...childProps} />
            );
            break;
        case 'Money':
            Component = Field;
            readOnly = childProps.readOnly;
            delete childProps.component;
            render = ({ field, form: { setFieldValue } }) => {
                let moneyVal = field.value;
                if (!moneyVal && childProps.readOnly) {
                    moneyVal = childProps.value;
                }

                if (readOnly && childProps && childProps.class) {
                    return (
                        <div className={childProps.class}>
                            {moneyVal ? `${new ErgoMoney(moneyVal).getStringAmount()}` : ''}
                        </div>
                    );
                }
                if (readOnly) {
                    return <div>{moneyVal ? `${new ErgoMoney(moneyVal).getStringAmount()}` : ''}</div>;
                }
                // Remove to get rid of warning about child props render property having invalid value
                delete childProps.render;
                return (
                    <NumberFormat
                        {...field}
                        id="money"
                        thousandSeparator={true}
                        isNumericString={true}
                        className="money-input"
                        value={moneyVal}
                        prefix="$"
                        mask=""
                        onChange={(evt) => {
                            console.log({ money: evt.target.value });
                            let moneyVal = evt.target.value.replace(/[$ ]/g, '').replace(/,/g, '');
                            setFieldValue(field.name, moneyVal);
                            if (props.getOnChangeValue) {
                                props.getOnChangeValue(moneyVal);
                            }
                        }}
                        {...childProps}
                    />
                );
            };
            break;
        case 'Phone':
            Component = Field;
            readOnly = childProps.readOnly;
            delete childProps.component;
            render = ({ field, form: { setFieldValue } }) => {
                let val = field.value;
                let typePhone = childProps.type;
                if (readOnly) {
                    return <div>{val ? phoneFormat(val) : ''}</div>;
                }
                delete childProps.render;
                if (typePhone === 'tel') {
                    return (
                        <NumberFormat
                            {...field}
                            id="phone"
                            type="tel"
                            className="Phone-input"
                            format="(###) ###-####"
                            value={val}
                            mask=""
                            onChange={(evt) => {
                                let val = evt.target.value;
                                setFieldValue(field.name, val);
                            }}
                            {...childProps}
                        />
                    );
                }
                if (typePhone === 'itel') {
                    return (
                        <NumberFormat
                            {...field}
                            id="phone"
                            type="tel"
                            className="Phone-input"
                            format="+##(###)###-####"
                            value={val}
                            mask=""
                            onChange={(evt) => {
                                let val = evt.target.value;
                                setFieldValue(field.name, val);
                            }}
                            {...childProps}
                        />
                    );
                }
            };
            break;
        case 'Year':
            Component = Field;
            delete childProps.component;
            render = ({ field, form: { setFieldValue } }) => {
                let val = field.value;

                if (readOnly) {
                    return <div>{val || ''}</div>;
                }
                return (
                    <NumberFormat
                        {...field}
                        id="year"
                        type="yr"
                        className="Phone-input"
                        format="####"
                        value={val}
                        placeholder="YYYY"
                        onChange={(evt) => {
                            let val = evt.target.value;
                            setFieldValue(field.name, val);
                        }}
                        {...childProps}
                    />
                );
            };
            break;
        case 'Link':
            Component = Field;
            delete childProps.component;
            render = ({ field }) => <a href={childProps.value}>Link</a>;
            break;
        default:
            Component = Field;
    }

    if (props.readOnly && props.component === 'textarea') {
        delete childProps.component;
        render = ({ field: { value } }) => <p style={{ marginTop: 8 }}>{value}</p>;
    }

    return (
        <div className="flex flex-column flex-1">
            <Component className={classNames('FieldInput', className)} {...childProps}>
                {render}
            </Component>
            {oPath.get(errors, props.name) && oPath.get(touched, props.name) && (
                <div className="FieldError">{oPath.get(errors, props.name)}</div>
            )}
        </div>
    );
};

export default FieldWithError;
