import React, { Component } from 'react';
import Moment from 'moment';

import NotifApi from '../../api/notifications';
import UsersApi from '../../api/users';

import Table from '../../components/tables/Table_old';
import errorToast from '../../components/messages/ErrorMessage';

const isNewNotification = (params) => {
    let todaysDate = new Moment().startOf('day');
    let createdOn = Moment(params.data.created_on).startOf('day');
    if (createdOn.isSame(todaysDate)) return { backgroundColor: '#EDEDED' };
    return null;
};

class AflrNotifications extends Component {
    constructor(props) {
        super(props);
        this.displayNotification = this.displayNotification.bind(this);

        this.state = {
            notifications: null,
            errorMessage: null,
            columns: [
                {
                    headerName: 'Open Notifications',
                    field: 'title',
                    cellClass: 'Cell-wrap-text',
                    onCellClicked: this.displayNotification,
                    cellRenderer(params) {
                        if (params.data.title) {
                            return `<a>${params.data.title}</a>`;
                        }
                        return null;
                    },
                    cellStyle(params) {
                        return isNewNotification(params);
                    },
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    minWidth: 50,
                    maxWidth: 50,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: notifications } = await NotifApi.getNotifications();
            const { data: users } = await UsersApi.getUsers();
            if (notifications && users) {
                notifications.forEach((notification) => {
                    if (notification.target_type === 'TravelRequest') {
                        users.forEach((user) => {
                            if (notification.target.traveler_id === user.id)
                                notification.target.traveler_name = user.name;

                            if (notification.target.approver.id === user.id)
                                notification.target.approver_name = user.name;
                        });
                    } else if (notification.target_type === 'Expenditure') {
                        users.forEach((user) => {
                            if (notification.target.initiator_id === user.id)
                                notification.target.initiator_name = user.name;
                        });
                    }
                });
            }

            this.setState({
                notifications,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRemoveSelected = (event) => {
        let notifyToDelete = event.data;

        NotifApi.deleteNotifications(notifyToDelete.id)
            .then(() => {
                let notifications = this.state.notifications;
                let idx = notifications.indexOf(notifyToDelete);
                notifications.splice(idx, 1);
                this.setState(
                    {
                        notifications,
                        forceSetData: true,
                    },
                    () => {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((error) => {
                console.error(error);
            });
    };

    displayNotification(params) {
        let data = params.data;
        if (data.action) this.props.displayNotification(data);
    }

    render() {
        const { notifications } = this.state;
        if (!notifications) return <div>loading...</div>;
        return (
            <div>
                <Table
                    columns={this.state.columns}
                    data={notifications}
                    ref={(r) => (this.grid = r)}
                    style={{ height: '100vh' }}
                    domLayout
                    forceSetData={this.state.forceSetData}
                    pagination={false}
                    paginationPageSize={notifications.length}
                    enableSorting
                    enableFilter
                    animate
                />
            </div>
        );
    }
}

export default AflrNotifications;
