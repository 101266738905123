import React from 'react';
import { Field } from 'formik';
import fileIcon from '../../icons/file-icon.svg';

const UploadThumb = ({ errors, touched, values }) => (
    <div className="Left">
        <div className="inner">
            <div className="innerRight">
                <div className="fileIcon">
                    <img src={fileIcon} alt="file icon" />
                </div>
            </div>
            <div className="innerLeft">
                <Field errors={errors} touched={touched} readOnly={true} name="file.name" values={values['name']} />
            </div>
        </div>
    </div>
);
export default UploadThumb;
