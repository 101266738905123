// React
import React, { Component } from 'react';

// Libraries
import { Formik, Form } from 'formik';
import moment from 'moment';
import yup from '../../yup';

// Components
import LabeledField from '../../components/forms/LabeledField';
import errorToast from '../../components/messages/ErrorMessage';

// API
import StatementsApi from '../../api/bankStatements';

class ImportForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: this.props.initialValues,
        };
        this.fileInput = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { initialValues } = this.props;

        if (prevProps.initialValues !== initialValues) {
            this.setState({
                initialValues: this.props.initialValues,
            });
        }
    }

    handleSubmit = (values, actions) => {
        const { itemToUpdate, onNewData } = this.props;

        if (moment(values.to_date) < moment(values.from_date)) {
            errorToast('There is an error in the Dates you chose please review');
            actions.setSubmitting(false);
            return;
        }

        if (!itemToUpdate) {
            let formData = new FormData();
            formData.append('csv_file', values.csv_file);
            formData.set('name', values.name);
            formData.set('from_date', values.from_date);
            formData.set('to_date', values.to_date);
            formData.set('link_to_db', values.link_to_db);

            if (values.notes) {
                formData.set('notes', values.notes);
            }

            StatementsApi.importStatement(formData)
                .then((response) => {
                    onNewData(response.data);
                })
                .catch((errorMessage) => {
                    // Removed the errorToast here - no need to double present the error.
                    if (actions.setSubmitting) {
                        actions.setSubmitting(false);
                    }
                    console.error(errorMessage);
                });
        } else {
            let attributes = {
                name: values.name,
                from_date: values.from_date,
                to_date: values.to_date,
                notes: values.notes,
                link_to_db: values.link_to_db,
            };

            StatementsApi.updateStatement(itemToUpdate.id, attributes)
                .then((response) => {
                    onNewData(response.data);
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
    };

    render() {
        const { initialValues } = this.state;
        const { cancelUpdate, itemToUpdate } = this.props;

        return (
            <div className="Centered-form width-80">
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        csv_file: yup.string().required(),
                        name: yup.string().required(),
                        from_date: yup.date().required(),
                        to_date: yup.date().required(),
                        link_to_db: yup.string(),
                        notes: yup.string(),
                    })}
                    onSubmit={() => {}}
                >
                    {({ values, errors, touched, isSubmitting, setFieldValue, resetForm, setSubmitting }) => (
                        <Form>
                            {isSubmitting && (
                                <p className="Import-warning column-span-3">
                                    The Import takes some time, if the page hasn&apos;t automatically refreshed in 60
                                    secs and this message is still here, please refresh the page.
                                </p>
                            )}
                            {!itemToUpdate && (
                                <div className="Form__buttons--Reversed column-span-3 flex justify-content-end">
                                    <input
                                        id="file"
                                        name="csv_file"
                                        type="file"
                                        ref={this.fileInput}
                                        onChange={(event) => {
                                            const file = event.currentTarget.files[0];
                                            if (
                                                (file && values.name === '') ||
                                                (values.csv_file &&
                                                    values.csv_file.name.replace('.csv', '') === values.name)
                                            ) {
                                                setFieldValue('name', file.name.replace('.csv', ''));
                                            }
                                            if (!file) {
                                                setFieldValue('name', '');
                                            }
                                            setFieldValue('csv_file', file);
                                        }}
                                    />
                                </div>
                            )}
                            <LabeledField label="Name*" name="name" type="text" errors={errors} touched={touched} />
                            <LabeledField
                                label="From*"
                                name="from_date"
                                type="date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="To*"
                                name="to_date"
                                type="date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Link to DB"
                                name="link_to_db"
                                type="text"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField label="Notes" name="notes" type="text" errors={errors} touched={touched} />
                            <div className="Form__buttons--Reversed column-span-3 flex justify-content-end">
                                <button
                                    type="reset"
                                    onClick={() => {
                                        if (itemToUpdate) {
                                            cancelUpdate(values);
                                        }
                                        resetForm();
                                        if (!this.props.itemToUpdate) {
                                            this.fileInput.current.value = '';
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    onClick={
                                        (values,
                                        () => {
                                            // For some reason on the submit for update it doesnt work
                                            this.handleSubmit(values, { setSubmitting });
                                        })
                                    }
                                >
                                    {itemToUpdate ? 'Update Info' : 'Import CSV'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default ImportForm;
