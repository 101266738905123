import React from 'react';
import _find from 'lodash/find';
import Fieldset from '../../projects/Fieldset';
import PagingTable from '../../components/tables/PagingTable';
import errorToast from '../../components/messages/ErrorMessage';
import MatchCard from './MatchCard';
import StatementsApi from '../../api/bankStatements';
import ExpendituresApi from '../../api/expenditures';

class BankStatementItemTable extends React.Component {
    constructor(props) {
        super(props);

        this.onToggleCollapsed = this.onToggleCollapsed.bind(this);
        this.fetchStatementItems = this.fetchStatementItems.bind(this);

        this.state = {
            statementItems: null,
            matchedExpenditures: null,
            matchOpen: false,
            matchedExpenditure: null,
            columns: [
                {
                    headerName: 'Card Holder',
                    field: 'card_holder',
                    type: 'name',
                    editable: false,
                },
                {
                    headerName: 'Transaction Date',
                    field: 'transaction_date',
                    sort: 'desc',
                    type: 'date',
                    editable: false,
                },
                {
                    headerName: 'Post Date',
                    field: 'post_date',
                    type: 'date',
                    editable: false,
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    type: 'mediumtext',
                    editable: false,
                },
                {
                    headerName: 'Amount',
                    field: 'amount',
                    type: 'money',
                    editable: false,
                },
                {
                    headerName: 'Category',
                    field: 'category',
                    type: 'type',
                    editable: false,
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    type: 'name',
                    cellRenderer: 'itemStatusEditorRenderer',
                    autoHeight: true,
                },
                {
                    headerName: 'Match',
                    field: 'view',
                    type: 'symbol',
                    onCellClicked: this.viewCardDetails,
                    cellRenderer: (rowData) => {
                        if (rowData.data.expenditure !== undefined) {
                            return '<a>View</a>';
                        }
                        return '';
                    },
                },
            ],
        };
    }

    onToggleCollapsed(collapsed) {
        if (!collapsed) {
            this.fetchStatementItems();
        }
    }

    linkMatchedExpenditures = (records, expenditures) => {
        if (records && expenditures) {
            for (let i = 0; i < records.length; i++) {
                let matchedExpenditure = _find(expenditures, {
                    transaction_id: records[i].transaction_id,
                });
                records[i].expenditure = matchedExpenditure;
            }
        }

        return records;
    };

    viewCardDetails = async (event) => {
        const { matchedExpenditures } = this.state;

        let statementItem = event.data;

        let matchedExpenditure = _find(matchedExpenditures, {
            transaction_id: statementItem.transaction_id,
        });

        const { data: expenditure } = await ExpendituresApi.fetchExpenditure(matchedExpenditure.id);

        this.setState({
            matchOpen: true,
            matchedExpenditure: expenditure,
        });
    };

    closeCard = () => {
        this.setState({ matchOpen: false });
    };

    async fetchStatementItems() {
        try {
            const { data: statementItems } = await StatementsApi.fetchStatementItems(this.props.statement.id);
            this.setState({
                statementItems,
                matchedExpenditures: this.props.matchedExpenditures,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        let { statementItems, columns, matchedExpenditures, matchedExpenditure, matchOpen } = this.state;
        const { statement } = this.props;

        statementItems = this.linkMatchedExpenditures(statementItems, matchedExpenditures);

        let colData;
        let data;
        let tableContent;
        if (!statementItems || statementItems.length === 0) {
            colData = [
                {
                    headerName: 'General Information',
                    field: 'info',
                    editable: false,
                },
            ];
            data = [{ info: '0 Statement Items' }];
            tableContent = <div>No Data</div>;
        } else {
            data = statementItems;
            colData = columns;
            tableContent = (
                <PagingTable
                    columns={colData}
                    data={data}
                    sizeToFit={true}
                    filter={true}
                    sortable={true}
                    tableClassName="View-bank-statements-table"
                />
            );
        }

        return (
            <div key={statement.id} className="Bank-statement-fieldset">
                <Fieldset title={statement.name} collapsed={true} onToggleCollapsed={this.onToggleCollapsed}>
                    <MatchCard
                        matchOpen={matchOpen}
                        matchedExpenditure={matchedExpenditure}
                        closeCard={this.closeCard}
                    />
                    {tableContent}
                </Fieldset>
                <p />
            </div>
        );
    }
}

export default BankStatementItemTable;
