import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
// Pages
import HomePage from '../homepage/HomePage';
import ExecDirHomePage from '../homepage/ExecDirHomePage';

// User
// import AccountManagerPage from './components/user/AccountManagerPage';
import ChangePasswordPage from '../user/change/ChangePasswordPage';
import LoginPage from '../user/LoginPage';

// import RegistrationPage from './components/user/registration/RegistrationPage';
import RegistrationConfirmPage from '../user/registration/RegistrationConfirmPage';

import ResetConfirmPage from '../user/reset/ResetConfirmPage';
import ResetRequestPage from '../user/reset/ResetRequestPage';

// Finance sub-navigation links
import FinancePage from '../finance/FinancePage';
import BankStatementsPage from '../finance/BankStatements';
import BankStatementsImportPage from '../finance/BankStatementsImport';
import BankStatementsReviewPage from '../finance/BankStatementsReview';
import ReconciliationPage from '../finance/ReconciliationPage';
// import InvoicesPage from '../finance/InvoicesPage';
import CreditCardPage from '../finance/CreditCardPage';
import ModsPage from '../finance/ModsPage';
import CommercialPage from '../finance/CommercialPage';
import NewCommercialPage from '../finance/commercial/NewCommercialPage';
import GrantPage from '../finance/GrantPage';
import NewGrantPage from '../finance/grant/NewGrantPage';
import FinanceResidualFundsPage from '../finance/ResidualFundsPage';
import FundsTransferPage from '../finance/residualFunds/FundsTransferPage';
import FundsApprovalsPage from '../finance/residualFunds/FundsApprovalsPage';
import TravelPage from '../finance/TravelPage';

// Projects sub-navigation links
import ProjectsPage from '../projects/ProjectsPage';
import ProjectPage from '../projects/ProjectPage';

// Core Funding sub-navigation links
import ExpendituresPage from '../coreFunding/ExpendituresPage';
import CoreFundingTravelPage from '../coreFunding/TravelPage';
import ApprovalsPage from '../coreFunding/ApprovalsPage';

// WBI Strategic Goals & Metrics sub-navigation links
import GoalsAndMetricsPage from '../goalsAndMetrics/GoalsAndMetricsPage';

// Reports sub-navigation links
import ProjectReportPage from '../reports/ProjectReportPage';
import ProjectManagerPage from '../reports/ProjectManagerPage';
import AllReportsPage from '../reports/AllProjectsPage';

// Admin sub-navigation links
import AdminPage from '../admin/AdminPage';
import CCHolderPage from '../admin/CCHolderPage';
import CoreBudgetTypesPage from '../admin/CoreBudgetTypesPage';
import NewPiaPage from '../admin/NewPiaPage';
import NewCpoPage from '../admin/NewCpoPage';
import OldProjectsPage from '../admin/OldProjectsPage';
import ArchivedProjectsPage from '../admin/old_projects/ArchivedProjectsPage';
import ProjectTypesPage from '../admin/ProjectTypesPage';
import ResidualFundsPage from '../admin/ResidualFundsPage';
import OfficeSymbolPage from '../admin/OfficeSymbolPage';
import ServicesPage from '../admin/ServicesPage';
import SetStrategicMetricsPage from '../admin/SetStrategicMetricsPage';
import UsersAndPermissionsPage from '../admin/UsersAndPermissionsPage';
import GARatesPage from '../admin/GARates';

// Vendors & Agreements
import VendorsAndAgreementsPage from '../vendorsAndAgreements/VendorsAndAgreementsPage';
import VendorViewPage from '../vendorsAndAgreements/VendorViewPage';
import AgreementViewPage from '../vendorsAndAgreements/AgreementViewPage';

// search results link
import SearchResultsPage from '../search/SearchResultsPage';

// Events
// import EventDetailsPage from './components/event/EventDetailsPage';

import AxiosHelper from '../setupInterceptors';
import AgreementInvoicesPage from '../finance/agreementInvoicesPage';

// setup the interceptor for 401s
AxiosHelper.setup();

const Routes = () => (
    <div className="Router-outlet">
        <Switch>
            {/* ---- Public Routes ---- */}
            <Route exact path="/account/signin" component={LoginPage} />
            {/* <Route exact path="/account/register" component={RegistrationPage}/> */}
            <Route exact path="/account/reset" component={ResetRequestPage} />
            <Route exact path="/account/confirm" component={ResetConfirmPage} />
            <Route exact path="/account/register/confirm" component={RegistrationConfirmPage} />

            {/* ---- testing as public ---- */}
            <AuthenticatedRoute exact path="/" component={HomePage} />
            <AuthenticatedRoute exact path="/account/change" component={ChangePasswordPage} />
            <AuthenticatedRoute exact path="/executiveDirector" component={ExecDirHomePage} />

            {/* ---- finance---- */}
            <AuthenticatedRoute exact path="/finance" component={FinancePage} />
            <AuthenticatedRoute exact path="/finance" component={FinancePage} />
            <AuthenticatedRoute exact path="/finance/bankStatements" component={BankStatementsPage} />
            <AuthenticatedRoute exact path="/finance/reconciliation" component={ReconciliationPage} />
            <AuthenticatedRoute exact path="/finance/bankStatements/import" component={BankStatementsImportPage} />
            <AuthenticatedRoute exact path="/finance/bankStatements/review" component={BankStatementsReviewPage} />
            <AuthenticatedRoute exact path="/finance/commercial" component={CommercialPage} />
            <AuthenticatedRoute exact path="/finance/commercial/newCommercial" component={NewCommercialPage} />
            <AuthenticatedRoute exact path="/finance/creditCard" component={CreditCardPage} />
            <AuthenticatedRoute exact path="/finance/grant" component={GrantPage} />
            <AuthenticatedRoute exact path="/finance/grant/newGrant" component={NewGrantPage} />
            <AuthenticatedRoute exact path="/finance/invoices" component={AgreementInvoicesPage} />
            <AuthenticatedRoute exact path="/finance/mods" component={ModsPage} />
            <AuthenticatedRoute exact path="/finance/residualFunds" component={FinanceResidualFundsPage} />
            <AuthenticatedRoute exact path="/finance/travel" component={TravelPage} />

            <AuthenticatedRoute exact path="/finance/residualFunds/fundsApprovals" component={FundsApprovalsPage} />
            <AuthenticatedRoute path="/finance/residualFunds/fundsTransfers" component={FundsTransferPage} />

            {/* ---- projects ---- */}
            <AuthenticatedRoute exact path="/projects" component={ProjectsPage} />
            <AuthenticatedRoute path="/projects/:id" component={ProjectPage} />

            {/* ---- core funding ---- */}
            <AuthenticatedRoute exact path="/coreFunding" component={ExpendituresPage} />
            <AuthenticatedRoute path="/coreFunding/travel" component={CoreFundingTravelPage} />
            <AuthenticatedRoute path="/coreFunding/expenditures/approvals/" component={ApprovalsPage} />

            {/* ---- wbi strategic goals & metrics ---- */}
            <AuthenticatedRoute exact path="/goalsAndMetrics" component={GoalsAndMetricsPage} />

            {/* ---- reports---- */}
            <AuthenticatedRoute exact path="/reports" component={ProjectReportPage} />
            <AuthenticatedRoute exact path="/reports/project-manager-reports" component={ProjectManagerPage} />
            <AuthenticatedRoute exact path="/reports/all-projects-reports" component={AllReportsPage} />

            {/* ---- vendors & agreements ---- */}
            <AuthenticatedRoute exact path="/admin/vendor/:vendorID" component={VendorViewPage} />
            <AuthenticatedRoute path="/admin/agreement/:id" component={AgreementViewPage} />

            {/* ---- admin ---- */}
            <AdminRoute exact path="/admin" component={AdminPage} />
            <AdminRoute exact path="/admin/coreBudgetTypes" component={CoreBudgetTypesPage} />
            <AdminRoute exact path="/admin/ccHolder" component={CCHolderPage} />
            <AdminRoute exact path="/admin/newPia" component={NewPiaPage} />
            <AdminRoute exact path="/admin/newCpo" component={NewCpoPage} />
            <AdminRoute exact path="/admin/oldProjects" component={OldProjectsPage} />
            <AdminRoute exact path="/admin/oldProjects/archivedProjects" component={ArchivedProjectsPage} />
            <AdminRoute exact path="/admin/projectTypes" component={ProjectTypesPage} />
            <AdminRoute exact path="/admin/residualFunds" component={ResidualFundsPage} />
            <AdminRoute exact path="/admin/officeSymbols" component={OfficeSymbolPage} />
            <AdminRoute exact path="/admin/services" component={ServicesPage} />
            <AdminRoute exact path="/admin/setStrategicMetrics" component={SetStrategicMetricsPage} />
            <AdminRoute exact path="/admin/usersAndPermissions" component={UsersAndPermissionsPage} />
            <AdminRoute exact path="/admin/vendorsAndAgreements" component={VendorsAndAgreementsPage} />
            <AdminRoute exact path="/admin/gaRates" component={GARatesPage} />

            {/* ---- search results ---- */}
            <AuthenticatedRoute exact path="/search" component={SearchResultsPage} />
        </Switch>
    </div>
);

export default Routes;
