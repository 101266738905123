import React, { Component } from 'react';
import { shortDate } from '../../utils/utils';

import travelRequestsApi from '../../api/travelRequests';
import errorToast from '../../components/messages/ErrorMessage';

class TravelApprovalProp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            approvals: null,
        };
    }

    async componentDidMount() {
        try {
            const { data: approvals } = await travelRequestsApi.fetchTravelRequestsApprover(
                this.props.approval.target.account_type
            );
            this.setState({ approvals });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        let status = false;
        let item = this.props.approval;

        if (!this.state.approvals) return <div>...Loading</div>;

        this.state.approvals.forEach((approval) => {
            if (approval.id === item.target.id) status = approval.status;
        });

        return (
            <div className="Card-styling">
                <table className="Approval_table">
                    <h6 className="Table_label">{item.title}</h6>
                    <tbody>
                        <tr>
                            <td className="Table_label">Traveler:</td>
                            <td>{item.target.traveler_name}</td>
                        </tr>
                        {item.account_type === 'CoreBudgetType' ? (
                            <tr>
                                <td className="Table_label">Type:</td>
                                <td>{item.target.account.name}</td>
                            </tr>
                        ) : (
                            <tr>
                                <td className="Table_label">Type:</td>
                                <td>{item.target.travel_request_type}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="Table_label">Date of Travel:</td>
                            <td>{shortDate(item.target.date_of_travel)}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Date of Return:</td>
                            <td>{shortDate(item.target.date_of_return)}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Location of Travel:</td>
                            <td>{item.target.location_of_travel}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">ZIP Code:</td>
                            <td>{item.target.zip_code}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Description:</td>
                            <td>{item.target.description}</td>
                        </tr>
                        <tr>
                            <td className="Table_label">Approver:</td>
                            <td>{item.target.approver_name}</td>
                        </tr>
                        {status !== 'Pending' && (
                            <tr>
                                <td className="Table_label">Status:</td>
                                <td>{status}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {status === 'Pending' && (
                    <div className="Form__buttons--Reversed Card-buttons">
                        <button type="button" onClick={() => this.props.reject(item.target.id, 'travel')}>
                            Reject
                        </button>
                        <button type="button" onClick={() => this.props.approve(item.target.id, 'travel')}>
                            Approve
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default TravelApprovalProp;
