import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';

const ExternalLinkEditor = forwardRef((props, ref, disabled) => {
    const inputRef = useRef();
    const data = useState(props.data)[0];
    const [valueLinkName, setValueLinkName] = useState(props.value);
    const [externalLink, setExternalLink] = useState(data[props.docLink]);
    useImperativeHandle(ref, () => ({
        getValue: () => ({ name: valueLinkName, link: externalLink }),
    }));
    const handleNameOnChange = (e) => {
        if (e.target.value.trim() !== '') {
            setValueLinkName(e.target.value);
        }
    };
    const handleLinkOnChange = (e) => {
        if (e.target.value.trim() !== '') {
            let linkValue = e.target.value;
            let externalLinkChange;
            if (linkValue.includes('http://') || linkValue.includes('https://')) {
                externalLinkChange = linkValue;
            } else {
                externalLinkChange = 'http://' + linkValue;
            }
            setExternalLink(externalLinkChange);
        }
    };
    return (
        !disabled && (
            <div className="Table-Link-editor">
                <div className="Left">
                    <label htmlFor="name">{props.name}</label>
                    <input
                        id="name"
                        type="text"
                        defaultValue={valueLinkName}
                        ref={inputRef}
                        onChange={handleNameOnChange}
                    />
                </div>
                <div className="Right">
                    <label htmlFor="docLink">{props.nameLink}</label>
                    <input
                        id="docLink"
                        type="text"
                        defaultValue={externalLink}
                        ref={inputRef}
                        onChange={handleLinkOnChange}
                    />
                </div>
            </div>
        )
    );
});

export default ExternalLinkEditor;
