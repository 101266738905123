import React, { Component } from 'react';

import Table from '../../components/tables/Table_old';

import ProjectsApi from '../../api/projects';
import GridSection from '../../components/section/GridSection';

class ProjectDesc extends Component {
    constructor(props) {
        super(props);
        this.handleDownloadLink = this.handleDownloadLink.bind(this);
    }

    handleDownloadLink(evt) {
        const projectId = this.props.project.id;
        const versiondId = evt.data.id;
        ProjectsApi.generateVersionDescription(projectId, versiondId);
    }

    render() {
        return (
            <GridSection title="Project Descriptions" area="descriptions">
                <Table
                    data={this.props.project.versions}
                    columns={[
                        {
                            headerName: 'Project Description',
                            field: 'number',
                            sort: { direction: 'desc' },
                        },
                        { headerName: 'Date', field: 'created_on', type: 'date' },
                        {
                            headerName: 'Download',
                            cellRenderer: 'DownloadLinkRenderer',
                            onCellClicked: this.handleDownloadLink,
                        },
                    ]}
                    gridAutoHeight
                />
            </GridSection>
        );
    }
}

export default ProjectDesc;
