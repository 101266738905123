import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import tableColumnsTypes from '../utils/tableColumnsTypes';
import TimerSymbolRenderer from '../../symbols/TimerSymbolRenderer';
import TrashSymbolRenderer from '../../symbols/TrashSymbolRenderer';
import DateEditor from '../../symbols/DateEditor';
import EditSymbolRenderer from '../../symbols/EditSymbolRenderer';
import StatusSymbolRenderer from '../../symbols/StatusSymbolRenderer';
import NotesSymbolRenderer from '../../symbols/NotesSymbolRenderer';
import BooleanRenderer from '../../symbols/BooleanRenderer';
import DownloadLinkRenderer from '../../symbols/DownloadLinkRenderer';
import DropboxLink from '../../dropbox/DropboxLink';
import ExternalLinkSymbolRenderer from '../../symbols/ExternalLinkSymbolRender';
import ExternalLinkRenderer from '../../symbols/ExternalLinkRenderer';
import ExternalLinkEditor from '../../symbols/ExternalLinkEditor';
import LinkRenderer from '../../symbols/LinkRenderer';
import NotificationsLinkRenderer from '../../symbols/NotificationsLinkRenderer';
import itemStatusEditorRenderer from '../../symbols/ItemStatusEditorRenderer';
import ExclamationPointRenderer from '../../symbols/ExclamationPointRenderer';
import AuditRenderer from '../../symbols/AuditRenderer';
import VendorTableToolTip from '../../../vendorsAndAgreements/utils/VendorTableToolTip';

const frameworkComponents = {
    LinkRenderer,
    TimerSymbolRenderer,
    TrashSymbolRenderer,
    BooleanRenderer,
    DownloadLinkRenderer,
    EditSymbolRenderer,
    ExternalLinkSymbolRenderer,
    ExternalLinkRenderer,
    ExternalLinkEditor,
    NotesSymbolRenderer,
    StatusSymbolRenderer,
    DropboxLink,
    DateEditor,
    NotificationsLinkRenderer,
    itemStatusEditorRenderer,
    ExclamationPointRenderer,
    AuditRenderer,
    VendorTableToolTip,
};

const createAgGridTable = (tableProps) => {
    const {
        rowData,
        columns,
        paging,
        pageSize,
        rowSelection,
        rowMultiSelectWithClick,
        selectionChanged,
        gridReady,
        resizeGrid,
        cellEditingStopped,
        drag,
        edit,
        rowDragEnter,
        rowDragEnd,
        rowValueChanged,
        animateRows,
        defaultColDef,
        editType,
        externalFilterPass,
        externalFilterPresent,
        getRowStyle,
        pinnedBottomRowData,
        defaultColDef: { editable },
        stopEditingWhenGridLosesFocus,
        children,
        tableClassName,
    } = tableProps;

    return (
        <div className="Ag-theme-blue">
            <div className={tableClassName}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columns}
                    columnTypes={tableColumnsTypes}
                    defaultColDef={defaultColDef}
                    frameworkComponents={frameworkComponents}
                    suppressClickEdit={!editable}
                    stopEditingWhenGridLosesFocus={stopEditingWhenGridLosesFocus}
                    suppressScrollOnNewData={true}
                    suppressRowClickSelection={true}
                    domLayout="normal"
                    headerHeight={48}
                    pagination={paging}
                    paginationPageSize={pageSize}
                    rowSelection={rowSelection}
                    rowMultiSelectWithClick={rowMultiSelectWithClick}
                    onSelectionChanged={selectionChanged}
                    onGridReady={gridReady}
                    onGridSizeChanged={resizeGrid}
                    onColumnEverythingChanged={resizeGrid}
                    onRowDataChanged={resizeGrid}
                    onRowValueChanged={rowValueChanged}
                    onRowDragEnter={rowDragEnter}
                    onRowDragEnd={rowDragEnd}
                    animateRows={animateRows}
                    rowDragManaged={drag}
                    suppressMoveWhenRowDragging={!edit}
                    editType={editType}
                    onCellEditingStopped={cellEditingStopped}
                    isExternalFilterPresent={externalFilterPresent}
                    getRowStyle={getRowStyle}
                    doesExternalFilterPass={externalFilterPass}
                    pinnedBottomRowData={pinnedBottomRowData}
                    tooltipShowDelay={0}
                />
                {children}
            </div>
        </div>
    );
};

const setEditingProps = (props, tableDefs) => {
    const { edit } = props;
    if (edit) {
        tableDefs.defaultColDef.sortable = true;
        tableDefs.defaultColDef.filter = true;
        tableDefs.defaultColDef.editable = true;
        tableDefs.stopEditingWhenGridLosesFocus = true;
        tableDefs.editType = 'fullRow';
    }
};

const setDraggingProps = (props, tableDefs) => {
    const { rowDragEnter, rowDragEnd, drag, edit } = props;
    tableDefs.defaultColDef.sortable = true;
    if (rowDragEnter && typeof rowDragEnter === 'function') {
        tableDefs.rowDragEnter = props.rowDragEnter;
    }
    if (rowDragEnd && typeof rowDragEnd === 'function') {
        tableDefs.rowDragEnd = props.rowDragEnd;
    }
    if (drag) {
        tableDefs.drag = true;
    }
    if (edit === false) {
        tableDefs.drag = false;
    }
};

const setSelectionProps = (props, tableDefs) => {
    const { selection, selectionType } = props;

    if (selection) {
        tableDefs.rowMultiSelectWithClick = 'true';
    }
    if (selectionType === 'single') {
        tableDefs.rowSelection = 'single';
    } else {
        tableDefs.rowSelection = 'multiple';
    }
};

const setPagingProps = (props, tableDefs) => {
    const { paging, pageSize } = props;
    if (paging) {
        tableDefs.paging = true;
    }
    if (pageSize) {
        tableDefs.pageSize = pageSize;
    }
};

const setChildren = (props, tableDefs) => {
    const { children } = props;
    if (children) {
        tableDefs.children = children;
    }
};

const createDefaultColumnDef = () => ({
    width: 150,
    resizable: true,
    autoHeight: true,
    editable: false,
    filter: false,
    sortable: false,
    tooltipComponent: '',
});

const createDefaultTableProps = () => ({
    rowData: [],
    columns: [],
    paging: false,
    pageSize: 5,
    pageSelector: null,
    rowSelection: 'single',
    rowMultiSelectWithClick: false,
    selectionChanged: null,
    gridReady: null,
    resizeGrid: null,
    cellEditingStopped: null,
    drag: false,
    rowDragEnter: null,
    rowDragEnd: null,
    rowValueChanged: null,
    animateRows: false,
    edit: false,
    editType: '',
    externalFilterPass: null,
    externalFilterPresent: null,
    getRowStyle: null,
    pinnedBottomRowData: null,
    stopEditingWhenGridLosesFocus: false,
    tableClassName: 'Custom-style',
    defaultColDef: createDefaultColumnDef(),
});

const TableFactory = (props) => {
    const {
        rowData,
        columns,
        editable,
        sortable,
        filter,
        animateRows,
        gridReady,
        resizeGrid,
        rowValueChanged,
        cellEditingStopped,
        selectionChanged,
        externalFilterPass,
        externalFilterPresent,
        pinnedBottomRowData,
        getRowStyle,
        vendorToolTipEnable,
        tableClassName,
    } = props;
    const tableDefs = createDefaultTableProps();

    if (rowData && columns) {
        tableDefs.rowData = rowData;
        tableDefs.columns = columns;
    }
    if (editable) {
        tableDefs.defaultColDef.editable = true;
    }
    if (sortable) {
        tableDefs.defaultColDef.sortable = true;
    }
    if (filter) {
        tableDefs.defaultColDef.filter = true;
    }
    if (tableClassName) {
        tableDefs.tableClassName = tableClassName;
    }
    if (vendorToolTipEnable) {
        tableDefs.defaultColDef.tooltipComponent = 'VendorTableToolTip';
    }
    if (animateRows) {
        tableDefs.animateRows = animateRows;
    }
    if (gridReady && typeof gridReady === 'function') {
        tableDefs.gridReady = gridReady;
    }
    if (resizeGrid && typeof resizeGrid === 'function') {
        tableDefs.resizeGrid = resizeGrid;
    }
    if (rowValueChanged && typeof rowValueChanged === 'function') {
        tableDefs.rowValueChanged = rowValueChanged;
    }
    if (cellEditingStopped && typeof cellEditingStopped === 'function') {
        tableDefs.cellEditingStopped = cellEditingStopped;
    }
    if (selectionChanged && typeof selectionChanged === 'function') {
        tableDefs.selectionChanged = selectionChanged;
    }
    if (externalFilterPass && typeof externalFilterPass === 'function') {
        tableDefs.externalFilterPass = externalFilterPass;
    }
    if (externalFilterPresent && typeof externalFilterPresent === 'function') {
        tableDefs.externalFilterPresent = externalFilterPresent;
    }
    if (getRowStyle && typeof getRowStyle === 'function') {
        tableDefs.getRowStyle = getRowStyle;
    }

    tableDefs.pinnedBottomRowData = pinnedBottomRowData;

    setPagingProps(props, tableDefs);
    setSelectionProps(props, tableDefs);
    setEditingProps(props, tableDefs);
    setDraggingProps(props, tableDefs);
    setChildren(props, tableDefs);

    return createAgGridTable(tableDefs);
};

export default TableFactory;
