import createDropdownOption from './DropdownUtils';

const prepareCoreFundOptions = (coreFunds) => {
    try {
        return coreFunds.map((coreFund) => createDropdownOption(coreFund.name, coreFund.id));
    } catch (error) {
        throw new Error('Error preparing core fund options');
    }
};

export { prepareCoreFundOptions as default };
