import React from 'react';

import PageHeader from '../../components/heading/PageHeader';
import FinanceSubNav from '../FinanceSubNav';
import SubSubNav, { SubSubNavLink } from '../../components/navigation/SubSubNav';
import NewGrantForm from './NewGrantForm';

const NewGrantPage = (props) => {
    const { match } = props;
    return (
        <div>
            <PageHeader title="New Grant Funding" />
            <FinanceSubNav />
            <SubSubNav>
                <SubSubNavLink to="/finance/grant" exact>
                    Commercial Funding
                </SubSubNavLink>
                <SubSubNavLink to={match.url}>Create New Grant Funding</SubSubNavLink>
            </SubSubNav>
            <NewGrantForm />
        </div>
    );
};

export default NewGrantPage;
