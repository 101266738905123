// React
import React, { Component } from 'react';

// Libraries
import { Formik, Form } from 'formik';

// Components
import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';
import { confirm } from '../components/modals/ConfirmModal';
import LabeledField from '../components/forms/LabeledField';
import EditingTable from '../components/tables/EditingTable';
import StrategicMetricsForm from './StrategicMetricsForm';
import errorToast from '../components/messages/ErrorMessage';

// API
import MetricsApi from '../api/metrics';
import ServicesApi from '../api/services';

class SetStrategicMetricsPage extends Component {
    constructor(props) {
        super(props);

        this.getRowData = this.getRowData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getAllServices = this.getAllServices.bind(this);
        this.createMetric = this.createMetric.bind(this);
        this.removeMetric = this.removeMetric.bind(this);
        this.filterTable = this.filterTable.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.handleMetriceChange = this.handleMetriceChange.bind(this);

        this.state = {
            errorMessage: null,
            metrics: [],
            metricYears: [],
            services: [],
            row: null,
            rowIndex: null,
            forceSetData: false,
            colKey: '',
            copy_plan: 'no',
            new_annual_plan: 'no',
            metric_services: [],
            listRowIndex: [],
            selected: [],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: metrics } = await MetricsApi.getStrategicMetrics();
            const { data: services } = await ServicesApi.getServices();

            let servicesOptions = [];
            let filterServices = [];
            if (services) {
                filterServices = services.filter(
                    (service) =>
                        (service.disabled !== true || service.disabled === null) &&
                        !service.name.toUpperCase().includes('CORE')
                );
                filterServices.forEach((service) => {
                    servicesOptions.push({ value: service.id, label: service.name });
                });
            }

            let metricYears = [];
            if (metrics) {
                metrics.forEach((metric) => {
                    metricYears.push(metric.metric_year);
                });
                metricYears = metricYears
                    .filter((year, pos) => metricYears.indexOf(year) === pos)
                    .sort((a, b) => a - b);

                for (let i = 0; i < metricYears.length; i++) {
                    metricYears[i] = { label: metricYears[i], value: metricYears[i] };
                }
                metricYears.unshift({ label: 'All', value: null });
            }

            let sortedMetrics = metrics.sort((a, b) => b.metric_year - a.metric_year);

            this.setState(
                {
                    forceSetData: true,
                    servicesOptions: servicesOptions.filter((service) => service.disabled === false),
                    services: filterServices,
                    metricYears,
                    metrics: sortedMetrics,
                },
                function () {
                    this.setState({ forceSetData: false });
                }
            );
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    handleSelectionChange(event) {
        let nodeList = event.api.getSelectedNodes();
        let listRowIndex = [];
        let metric_services = [];

        nodeList.forEach((node) => {
            listRowIndex.push(node.childIndex);
        });
        listRowIndex.forEach((i) => {
            metric_services.push(this.state.services[i]);
        });
        this.setState({
            metric_services,
            listRowIndex,
        });
    }

    handleMetriceChange(selected) {
        this.setState({ selected });
    }

    onRadioChange(evt, setFieldValue) {
        let target = evt.target;
        if (target.name === 'new_annual_plan') {
            setFieldValue('new_plan', target.value);
            this.setState({
                new_annual_plan: target.value,
            });
        } else if (target.name === 'copy_plan') {
            setFieldValue('plan_copy', target.value);
            this.setState({
                copy_plan: target.value,
            });
        }
    }

    onRowValueChanged(event) {
        let item = event.data;
        this.updateMetric(item.id, item);
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'metric',
        });
    }

    getAllServices(serviceTypes) {
        let newServiceTypes = [];
        if (this.state.services) {
            for (let i = 0; i < serviceTypes.length; i++) {
                newServiceTypes.push(serviceTypes[i].id);
            }
        }
        return newServiceTypes;
    }

    removeMetric = async (id) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to remove strategic metric?',
        });
        if (confirmed && id) {
            MetricsApi.removeMetric(id)
                .then(() => {
                    let newMetrics = this.state.metrics.filter((p) => p.id !== id);

                    this.setState(
                        {
                            forceSetData: true,
                            metrics: newMetrics,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
    };

    handleSubmit = (evt, values, actions, grid) => {
        if (this.state.metric_services.length === 0) {
            errorToast('Please select a service!');
            return;
        }
        if (values.new_plan === 'yes' && values.plan_copy === 'yes') {
            let attributes = {
                new_year: values.metric_year,
                target_year: values.target_year,
            };
            this.copyYearPlan(attributes, actions);
        }
        if (values) {
            let attributes = {
                metric: values.metric,
                metric_year: values.metric_year,
                planned_metric: values.planned_metric,
            };
            let services = this.getAllServices(this.state.metric_services);
            if (services) {
                attributes.service_types = services;
                this.createMetric(attributes, actions);
            }
        }

        this.setState({
            new_annual_plan: 'no',
            copy_plan: 'no',
            metric_services: [],
            listRowIndex: [],
        });
        grid.deselectAll();
    };

    updateMetric(id, item) {
        let attributes = {
            metric: item.metric,
            metric_year: item.metric_year,
            planned_metric: item.planned_metric,
            service_types: item.service_types,
        };
        MetricsApi.updateMetric(id, attributes)
            .then((response) => {
                let updatedMetric = response.data;
                let metrics = this.state.metrics;
                let metricYears = [];
                this.state.metricYears.forEach((year) => {
                    metricYears.push(year.value);
                });
                metricYears.push(updatedMetric.metric_year);
                metricYears = metricYears
                    .filter((year, pos) => metricYears.indexOf(year) === pos)
                    .sort((a, b) => a - b);

                for (let i = 0; i < metricYears.length; i++) {
                    metricYears[i] = { label: metricYears[i], value: metricYears[i] };
                }
                metricYears.unshift({ label: 'All', value: null });
                let idx = metrics.findIndex((e) => e.id === updatedMetric.id);
                metrics[idx] = updatedMetric;
                let sortedMetrics = metrics.sort((a, b) => b.metric_year - a.metric_year);

                this.setState(
                    {
                        forceSetData: true,
                        metricYears,
                        metrics: sortedMetrics,
                        row: null,
                        rowIndex: null,
                        colKey: '',
                    },
                    function () {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    async createMetric(attributes, actions) {
        let metrics = this.state.metrics;
        let metricYears = [];

        for (let i = 0; i < attributes.service_types.length; i++) {
            let metric = {
                service_types: [attributes.service_types[i]],
                metric: attributes.metric,
                metric_year: attributes.metric_year,
                planned_metric: attributes.planned_metric,
            };

            try {
                let { data: metricobj } = await MetricsApi.createMetric(metric);
                metrics.push(metricobj[0]);
                metricYears.push(metricobj[0].metric_year);
                this.setState(
                    {
                        metrics,
                        metricYears,
                        forceSetData: true,
                        rowToUpdate: null,
                    },
                    function () {
                        this.setState({ forceSetData: false });
                    }
                );
                actions.resetForm();
            } catch (errorMessage) {
                this.setState({
                    errorMessage,
                });
            }
        }
    }

    copyYearPlan(attributes, actions) {
        MetricsApi.copyAnnualPlan(attributes)
            .then((response) => {
                let newMetrics = response.data;

                let metrics = this.state.metrics;
                newMetrics.forEach((metric) => {
                    metrics.push(metric);
                });

                let metricYears = [];
                this.state.metricYears.forEach((year) => {
                    metricYears.push(year.value);
                });
                metricYears.push(attributes.new_year);
                metricYears = metricYears
                    .filter((year, pos) => metricYears.indexOf(year) === pos)
                    .sort((a, b) => a - b);

                for (let i = 0; i < metricYears.length; i++) {
                    metricYears[i] = { label: metricYears[i], value: metricYears[i] };
                }
                metricYears.unshift({ label: 'All', value: null });

                this.setState(
                    {
                        forceSetData: true,
                        metrics,
                        metricYears,
                        rowToUpdate: null,
                    },
                    function () {
                        this.setState({ forceSetData: false });
                    }
                );
                actions.resetForm();
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    filterTable(year) {
        if (year === null) {
            year = 'All';
        }
        MetricsApi.getMetricsByYear(year)
            .then((response) => {
                let filteredMetrics = response.data;

                this.setState(
                    {
                        forceSetData: true,
                        metrics: filteredMetrics,
                    },
                    function () {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    updateServiceTypeData(event) {
        let newMetrics = this.state.metrics;
        newMetrics[this.state.rowIndex].service_type_names = event.target.value;
        let metricToUpdate = newMetrics[this.state.rowIndex];
        this.setState({ MetricsApi: newMetrics, forceSetData: true }, function () {
            this.setState({ forceSetData: false });
        });

        if (metricToUpdate) {
            this.updateMetric(metricToUpdate.id, metricToUpdate);
        }
    }

    render() {
        let serviceOptions = [];
        if (this.state.services.length > 0) {
            this.state.services.forEach((e) => {
                serviceOptions.push({ label: e.name, value: e.id, object: e });
            });
        }

        return (
            <div>
                <PageHeader title="Set Strategic Metrics" />
                <AdminSubNav />
                <div>
                    <div className="Single-layout Companion-form">
                        <StrategicMetricsForm
                            options={this.state.services}
                            copy_plan={this.state.copy_plan}
                            new_annual_plan={this.state.new_annual_plan}
                            onRadioChange={this.onRadioChange}
                            handleSubmit={this.handleSubmit}
                            handleSelectionChange={this.handleSelectionChange}
                        />
                    </div>
                    <div className="Table-styling">
                        <div>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    metric_years: '',
                                }}
                                onSubmit={this.props.handleSubmit}
                            >
                                {({ errors, touched, setFieldValue }) => (
                                    <Form className="SGM-form">
                                        <LabeledField
                                            label="Year"
                                            name="metric_years"
                                            component="select"
                                            options={this.state.metricYears}
                                            onChange={(e) => {
                                                this.filterTable(e.value);
                                                setFieldValue('metric_years', e);
                                            }}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <EditingTable
                            columns={[
                                {
                                    headerName: 'Metric year',
                                    field: 'metric_year',
                                    type: 'shortnumber',
                                    editable: true,
                                    sort: 'desc',
                                },
                                {
                                    headerName: 'Metric',
                                    field: 'metric',
                                    type: 'longtext',
                                    editable: true,
                                },
                                {
                                    headerName: 'Service',
                                    field: 'service_type_names',
                                    type: 'mediumtext',
                                    editable: false,
                                },
                                {
                                    headerName: 'Total WBI Planned Metrics',
                                    field: 'planned_metric',
                                    type: 'mediumtext',
                                    editable: true,
                                },
                                {
                                    headerName: 'Edit',
                                    field: 'edit',
                                    type: 'symbol',
                                    editable: false,
                                    cellRenderer: 'EditSymbolRenderer',
                                    onCellClicked: this.getRowData,
                                },
                                {
                                    headerName: 'Del.',
                                    field: 'delete',
                                    type: 'symbol',
                                    editable: false,
                                    cellRenderer: 'TrashSymbolRenderer',
                                    onCellClicked: (event) => {
                                        this.removeMetric(event.data.id);
                                    },
                                },
                            ]}
                            data={this.state.metrics}
                            rowIndex={this.state.rowIndex}
                            colKey={this.state.colKey}
                            pagingProp={true}
                            addedNewData={this.state.forceSetData}
                            rowValueChanged={this.onRowValueChanged}
                            tableClassName="Metrics-table"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SetStrategicMetricsPage;
