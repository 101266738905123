import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import yup from '../../yup';
import LabeledField from '../../components/forms/LabeledField';

const emptyBudgetItem = {
    category: '',
    item: '',
    nte: '',
    expense: '',
    contract: '',
    notes: '',
};

const EstimatedBudgetItemForm = (props) => {
    const [budgetItem, setBudgetItem] = useState(emptyBudgetItem);
    const [submitButtonLabel, setSubmitButtonLabel] = useState('Add Item');

    const resetForm = (formikBag) => {
        formikBag.setSubmitting(false);
        formikBag.resetForm(emptyBudgetItem);
        props.resetBudgetItemToEdit();
    };

    const handleSubmitEvent = (budgetItem, formikBag) => {
        if (props.budgetItemToEdit) {
            props.hoistBudgetItemToEdit(budgetItem);
        } else {
            props.hoistBudgetItemToAdd(budgetItem);
        }
        resetForm(formikBag);
    };

    useEffect(() => {
        if (props.budgetItemToEdit) {
            setBudgetItem(props.budgetItemToEdit);
            setSubmitButtonLabel('Edit Item');
        } else {
            setBudgetItem(emptyBudgetItem);
            setSubmitButtonLabel('Add Item');
        }
    }, [props.budgetItemToEdit]);

    const createYupSchema = () =>
        yup.object().shape({
            category: yup.string().required(),
            item: yup.string().required(),
            nte: yup.number().required(),
            contract: yup.string(),
            notes: yup.string(),
        });

    return (
        <div className="Single-layout Companion-form">
            <Formik
                initialValues={budgetItem}
                validationSchema={createYupSchema}
                onSubmit={handleSubmitEvent}
                validateOnChange={true}
                enableReinitialize={true}
            >
                {({ errors, touched, isSubmitting, handleReset }) => (
                    <Form
                        className="grid"
                        style={{
                            gridTemplateColumns: '1fr 1fr 1fr',
                            gridGap: 16,
                            maxWidth: 500,
                        }}
                    >
                        <LabeledField label="Category*" name="category" type="text" errors={errors} touched={touched} />
                        <LabeledField label="Item*" name="item" type="text" errors={errors} touched={touched} />
                        <LabeledField
                            label="Contract No."
                            name="contract"
                            type="text"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="Planned Expense(NTE)*"
                            name="nte"
                            component="Money"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="Actual Expense"
                            name="expense"
                            component="Money"
                            errors={errors}
                            touched={touched}
                        />
                        <LabeledField
                            label="Notes/Assumptions"
                            name="notes"
                            type="text"
                            errors={errors}
                            touched={touched}
                        />
                        <div className="Form__buttons--Reversed Buttons_format">
                            <button type="submit" disabled={isSubmitting} style={{ gridColumnEnd: -1 }}>
                                {submitButtonLabel}
                            </button>
                            <button type="button" onClick={handleReset}>
                                Cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EstimatedBudgetItemForm;
