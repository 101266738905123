import React from 'react';
import { Link } from 'react-router-dom';

import ResetRequestForm from './ResetRequestForm';

const ResetRequestPage = () => (
    <main className="AccountPage__content">
        <div className="page__subnav">
            <Link to="/account/signin">&laquo; Back to Sign In</Link>
        </div>
        <div className="page__body">
            <h2>Request to Reset Your Password</h2>
            <p className="lead">
                Please provide the email for your account in order to request a secure link to reset your password.
            </p>
            <ResetRequestForm />
        </div>
    </main>
);

export default ResetRequestPage;
