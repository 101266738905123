import React from 'react';
import { Formik, FieldArray as BasicFieldArray } from 'formik';
import oPath from 'object-path';

const fieldClassName = 'FieldInput';
const idForName = (name) => 'Field-' + name;

const FieldArray = ({
    label,
    initialValues,
    validationSchema,
    readOnly,
    renderArray,
    renderFields,
    values,
    errors,
    touched,
    ...props
}) => {
    const id = idForName(props.name);
    const fieldProps = {
        id,
        className: fieldClassName,
        ...props,
    };

    return (
        <>
            <div className="column-span-3">
                <label className="FieldLabel">{label}</label>
                <BasicFieldArray
                    {...fieldProps}
                    render={(arrayHelpers) => (
                        <div>
                            {renderArray({
                                data: values[props.name],
                                deleteColumnDef: {
                                    hide: readOnly,
                                    headerName: 'Delete',
                                    maxWidth: 80,
                                    autoHeight: true,
                                    suppressSorting: true,
                                    suppressFilter: true,
                                    suppressResize: true,
                                    cellRenderer: 'TrashSymbolRenderer',
                                    onCellClicked: (event) => {
                                        props.onRemoveSelected(event);
                                        arrayHelpers.remove(event.node.rowIndex);
                                    },
                                },
                            })}

                            {!readOnly && (
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, actions) => {
                                        let context = props.name;
                                        if (context === 'key_goals') {
                                            if (values.attainment !== '100') values.reached = 'No';
                                            else values.reached = 'Yes';
                                        }

                                        arrayHelpers.push(values);
                                        props.addRow(values, context);
                                        actions.resetForm();
                                    }}
                                >
                                    {(props) => (
                                        <div className="Complex-add-field">
                                            {renderFields({
                                                handleKeyPress: (event) =>
                                                    event.key === 'Enter' && !event.shiftKey && props.submitForm(),
                                                ...props,
                                            })}

                                            <button
                                                className="End-button"
                                                type="button"
                                                onClick={() => {
                                                    props.submitForm();
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    )}
                                </Formik>
                            )}
                        </div>
                    )}
                />

                {oPath.get(errors, props.name) && oPath.get(touched, props.name) && (
                    <div className="FieldError">{oPath.get(errors, props.name)}</div>
                )}
            </div>
        </>
    );
};

export default FieldArray;
