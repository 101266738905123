import axios from 'axios';
import AuthService from './auth/auth.service';
import { history } from './store/store';

export default {
    setup() {
        axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                let originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                    const refreshToken = await AuthService.getRefreshToken();
                    if (refreshToken) {
                        AuthService.setToken(refreshToken);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + AuthService.getToken();
                        history.push('/account/signin');
                        return axios(originalRequest);
                    }
                }
                if (error.response.status === 404 && !originalRequest._retry) {
                    AuthService.signOut();
                    history.push('/account/signin');
                    throw new Error(`${error.config.url} not found`);
                }
                return Promise.reject(error);
            }
        );

        axios.interceptors.request.use(
            (req) => {
                let headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `${AuthService.getToken()}`,
                    idtoken: `${AuthService.getIdToken()}`,
                    refreshtoken: `${AuthService.getRefreshToken()}`,
                };
                req.headers = headers;
                return req;
            },
            (error) => {
                if (error.response.status === 404) {
                    throw new Error(`${error.config.url} not found`);
                }
                return Promise.reject(error);
            }
        );
    },
};
