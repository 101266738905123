import api from './api';
import endpoints from './endpoints';

export default {
    getVendors() {
        return api.get(`${endpoints.VENDORS}/list`, 'Error getting vendors');
    },

    createVendor(data) {
        return api.post(endpoints.VENDORS, data, 'Error creating vendor');
    },

    getVendorById(id) {
        return api.get(`${endpoints.VENDORS}/${id}`, 'Error getting vendor');
    },
    getVendorByProjectId(id) {
        return api.get(`${endpoints.VENDORS}/project/${id}`, 'Error getting vendor');
    },
    getVendorByIdForProject(id, projectId) {
        return api.get(`${endpoints.VENDORS}/${id}/project/${projectId}`, 'Error getting vendor');
    },

    getVendorsListByProjectId(id) {
        return api.get(`${endpoints.VENDORS}/list/project/${id}`, 'Error getting vendor');
    },

    updateVendor(id, data) {
        return api.put(`${endpoints.VENDORS}/${id}`, data, 'Error updaing vendor');
    },

    deleteVendor(id) {
        return api.delete(`${endpoints.VENDORS}/${id}`, 'Error deleting vendor');
    },
};
