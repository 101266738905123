import React from 'react';
import PagingTable from '../components/tables/PagingTable';

const VendorsTable = ({ onTableReady, vendorsData }) => {
    const columns = [
        {
            headerName: 'Vendor Name',
            field: 'name',
            cellRenderer: 'LinkRenderer',
            cellRendererParams: {
                routeBase: '/admin/vendor/',
                routeKeyField: 'id',
            },
            type: 'mediumtext',
        },
        {
            headerName: 'Open Agreements',
            field: 'open_agreements',
            type: 'number',
            cellRenderer: 'ExclamationPointRenderer',
            tooltipField: 'open_agreements',
        },
        {
            headerName: 'Total Agreements',
            field: 'total_agreements',
            type: 'number',
        },
        {
            headerName: 'Total NTE Commitments',
            field: 'total_all_open_ntes',
            type: 'money',
            cellRenderer: 'AuditRenderer',
            tooltipField: 'total_all_open_ntes',
        },
        {
            headerName: 'Total NTEs Amount Expended',
            field: 'total_used_all_open_ntes',
            type: 'money',
        },
        {
            headerName: 'Total Remaining of All Open NTEs',
            field: 'total_remaining_all_open_ntes',
            type: 'money',
        },
        {
            headerName: 'CAGE Code',
            field: 'sams',
            type: 'shorttext',
        },
        {
            headerName: 'UEI No.',
            field: 'uei',
            type: 'shorttext',
        },
        {
            headerName: 'Approved',
            field: 'approved',
            type: 'shorttext',
        },
    ];

    return (
        <PagingTable
            columns={columns}
            pageSizeProp={25}
            onTableReady={onTableReady}
            data={vendorsData}
            sortable={true}
            filter={true}
            sizeToFit={true}
            vendorToolTipEnable={true}
            tableClassName="VendorsTable"
        />
    );
};

export default VendorsTable;
