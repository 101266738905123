import React from 'react';
import yup from '../../../yup';
import FieldArray from '../../../components/forms/FieldArray';
import FieldWithError from '../../../components/forms/FieldWithError';
import DraggingTable from '../../../components/tables/DraggingTable';
import ProjectsApi from '../../../api/projects';

class TasksTable extends React.Component {
    constructor(props) {
        super(props);

        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.addRow = this.addRow.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.onRowDragEnd = this.onRowDragEnd.bind(this);
        this.onRowDragEnter = this.onRowDragEnter.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            row: null,
            rowIndex: null,
            colKey: '',
            errorMessage: '',
        };
    }

    handleTasks(tasks) {
        this.props.handleTasks(tasks);
    }

    onRemoveSelected(evt) {
        const { data } = evt;
        ProjectsApi.removeTask(data.project_id, data.id).then(() => {
            const tasks = this.props.tasks.filter((t) => t.id !== data.id);
            this.handleTasks(tasks);
        });
    }

    onRowValueChanged(evt) {
        const { data } = evt;
        const { tasks } = this.props;
        let idx = tasks.findIndex((t) => t.id === data.id);
        tasks[idx] = data;
        ProjectsApi.updateProjectDataPageTables(data.project_id, 'Tasks', tasks)
            .then((response) => {
                if (response) {
                    this.setState({
                        row: null,
                        rowIndex: null,
                        colKey: '',
                    });
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    getRowData(evt) {
        this.setState({
            row: evt.node.data,
            rowIndex: evt.node.rowIndex,
            colKey: 'description',
        });
    }

    onRowDragEnter = (event) => {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
        });
    };

    onRowDragEnd = (event) => {
        let newIndex = event.node.rowIndex;
        let rowIndex = this.state.rowIndex;
        let movingItem = event.node.data;
        movingItem.order = newIndex;
        let secondPartArray = this.props.tasks.filter((_, i) => rowIndex !== i);
        let firstPartArray = secondPartArray.splice(0, newIndex);
        firstPartArray.push(movingItem);

        let newArray = firstPartArray.concat(secondPartArray);
        for (let i = 0; i < newArray.length; i++) {
            newArray[i].order = i;
        }
        ProjectsApi.updateProjectDataPageTables(event.node.data.project_id, 'Tasks', newArray)
            .then(() => {
                this.props.handleTasks(newArray);
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    };

    addRow(valueToUpdate) {
        const { projectId } = this.props;
        if (projectId !== 'new') {
            const attributes = {
                description: valueToUpdate.description,
                order: this.props.tasks.length,
            };
            ProjectsApi.createTask(projectId, attributes)
                .then((response) => {
                    const tasks = this.props.tasks.map((t) => t);
                    const { data } = response;
                    const newTask = {
                        description: data.description,
                        id: data.id,
                        project_id: data.project_id,
                        order: data.order,
                    };
                    tasks.push(newTask);
                    this.handleTasks(tasks);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        const { values, errors, touched, isEditing, tasks } = this.props;
        if (tasks) {
            tasks.sort((a, b) => a.order - b.order);
        }
        return (
            <FieldArray
                label="Tasks/Approach"
                name="tasks"
                initialValues={{ description: '' }}
                onRemoveSelected={this.onRemoveSelected}
                addRow={this.addRow}
                validationSchema={yup.object().shape({
                    description: yup.string().required(),
                })}
                values={values}
                errors={errors}
                touched={touched}
                readOnly={!isEditing}
                renderArray={() => (
                    <DraggingTable
                        data={tasks}
                        columns={[
                            {
                                headerName: 'Tasks/Approach',
                                field: 'description',
                                type: 'longtext',
                                editable: true,
                                rowDrag: true,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Edit',
                                field: 'edit',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'EditSymbolRenderer',
                                onCellClicked: this.getRowData,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Del.',
                                field: 'delete',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'TrashSymbolRenderer',
                                onCellClicked: this.onRemoveSelected,
                            },
                        ]}
                        edit={isEditing}
                        drag={isEditing}
                        rowDragEnd={this.onRowDragEnd}
                        rowDragEnter={this.onRowDragEnter}
                        rowValueChanged={this.onRowValueChanged}
                        sizeToFit={!isEditing}
                    />
                )}
                renderFields={({ errors, touched, handleKeyPress }) => (
                    <FieldWithError
                        className="Text-area-space"
                        name="description"
                        placeholder="Add item..."
                        component="textarea"
                        onKeyPress={handleKeyPress}
                        errors={errors}
                        touched={touched}
                    />
                )}
            />
        );
    }
}

export default TasksTable;
