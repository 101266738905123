import React from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

//Api
import ProjectsApi from '../../api/projects';
import errorToast from '../messages/ErrorMessage';

class ProjectSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            errorMessage: null,
            projectOptions: [],
        };
        this.makeOptions = this.makeOptions.bind(this);
    }

    componentDidMount = async () => {
        let projectOptions = [];
        try {
            const { data: projects } = await ProjectsApi.fetchProjects();
            projectOptions = this.makeOptions(projects);
        } catch (error) {
            errorToast(error);
        }

        this.setState({
            projectOptions,
        });
    };

    makeOption(thing) {
        let option = {
            label: '',
            value: '',
            object: {},
        };
        if (thing && thing.name && thing.id) {
            option.label = thing.name;
            option.value = thing.id;
            option.object = thing;
        }
        return option;
    }

    makeOptions(things) {
        let options = [];
        things.forEach((thing) => {
            options.push(this.makeOption(thing));
        });
        return options;
    }

    render() {
        const { projectOptions } = this.state;
        const { onChange, readOnly, className, selected, initialName } = this.props;
        return (
            <ReactSelect
                placeholder={initialName || 'Please select a project'}
                options={projectOptions}
                resetValue=""
                value={selected}
                inputProps={{ readOnly }}
                disabled={readOnly}
                onChange={onChange}
                clearable={false}
                className={classNames(className, readOnly ? 'read-only' : null)}
            />
        );
    }
}

export default ProjectSelect;
