// React
import React from 'react';

// Components
import CoreFundingSubNav from './CoreFundingSubNav';
import PageHeader from '../components/heading/PageHeader';
import Travel from '../travel';

const RequestTravelPage = (props) => (
    <>
        <PageHeader title="Travel" />
        <CoreFundingSubNav />
        <Travel {...props} isProject={false} isArchived={false} />
    </>
);

export default RequestTravelPage;
