import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Form, FieldArray as BasicFieldArray } from 'formik';

import yup from '../../yup';
import VendorsApi from '../../api/vendors';
import { phoneFormat } from '../../utils/utils';
import errorToast from '../../components/messages/ErrorMessage';
import Modal from '../../components/modals/Modal';
import GridSection from '../../components/section/GridSection';
import SectionHeading from '../../components/heading/SectionHeading';
import ExportCSVButton from '../../components/buttons/ExportCSVButton';
import useFunctionAsState from '../../hooks/useFunctionAsState';

import AddPointOfContact from './AddPointOfContact';
import AddVendorContactInfo from './AddVendorContactInfo';
import AddVendorDocuments from './AddVendorDocuments';
import AddVendorAccountInfo from './AddVendorAccountInfo';
import AddGeneralDocuments from '../AddGeneralDocuments';
import AgreementsTable from '../AgreementsTable';
import AddAgreementModal from '../agreementForm/AddAgreementModal';

export const AddNewVendorModal = (props) => {
    const { vendor } = props;
    const [agreementsModalOpen, setAgreementsModalOpen] = useState(false);
    const [exportDataAsCsv, setExportDataAsCsv] = useFunctionAsState(() => {});
    const [agreementList, setAgreementList] = useState([]);
    const hoistTableParams = (params) => {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            setExportDataAsCsv(params.exportDataAsCsv);
        }
    };

    const openAddAgreement = () => {
        setAgreementsModalOpen(true);
    };

    const closeAddAgreement = () => {
        setAgreementsModalOpen(false);
    };

    const createDefaultDocuments = (newDocument) => {
        const defaultValues = {
            name: '',
            doc_link: '',
        };
        if (!newDocument && vendor.name && vendor.documents) {
            let documents = [];
            // eslint-disable-next-line array-callback-return
            vendor.documents.map((doc) => {
                documents.push({
                    name: doc.name || '',
                    doc_link: doc.doc_link || '',
                    id: doc.id,
                });
            });

            return documents;
        }

        if (newDocument) {
            return defaultValues;
        }
        return [defaultValues];
    };

    const createDefaultPointOfContacts = (newPointOfContact) => {
        const defaultValues = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            notes: '',
            associated_agreement: '',
        };
        if (!newPointOfContact && vendor.name && vendor.contacts) {
            let contacts = [];
            // eslint-disable-next-line array-callback-return
            vendor.contacts.map((poc) => {
                contacts.push({
                    first_name: poc.first_name || '',
                    last_name: poc.last_name || '',
                    email: poc.email || '',
                    phone: phoneFormat(poc.phone) || '',
                    notes: poc.notes || '',
                    id: poc.id,
                    associated_agreement: poc.associated_agreement || '',
                });
            });

            return contacts;
        }

        if (newPointOfContact) {
            return defaultValues;
        }

        return [defaultValues];
    };

    const createDefaultFormValues = () => {
        const defaultDocuments = createDefaultDocuments(false);
        const defaultPointOfContacts = createDefaultPointOfContacts(false);
        return {
            name: vendor.name || '',
            address1: vendor.address1 || '',
            address2: vendor.address2 || '',
            city: vendor.city || '',
            state: vendor.state || '',
            zip: vendor.zip || '',
            country: vendor.country || '',
            watchlist: vendor.watchlist,
            approved: vendor.approved || 'approved',
            sams: vendor.sams || '',
            sams_expire_on: vendor.sams_expire_on || '',
            uei: vendor.uei || '',
            uei_expire_on: vendor.uei_expire_on || '',
            notes: vendor.notes || '',
            w9: vendor.w9 || '',
            financial_audit: vendor.financial_audit || '',
            general_audit: vendor.general_audit || '',
            documents: defaultDocuments,
            contacts: defaultPointOfContacts,
        };
    };
    const { closeModal } = props;
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState(createDefaultFormValues());

    useEffect(() => {
        if (vendor) {
            setInitialValues(createDefaultFormValues());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendor]);

    useEffect(() => {
        setOpenModal(props.openModal);
    }, [props.openModal]);

    useEffect(() => {
        if (Object.entries(vendor).length !== 0) {
            if (vendor.agreements !== null) {
                let options = vendor.agreements.map((agreement) => agreement.number);
                setAgreementList(options);
            }
        }
    }, [vendor]);

    const handleAddDocs = (arrayHelpers) => {
        arrayHelpers.push(createDefaultDocuments(true));
    };

    const handleAddPointOfContacts = (arrayHelpers) => {
        arrayHelpers.push(createDefaultPointOfContacts(true));
    };

    const handleRemovePointOfContacts = (arrayHelpers, idx) => {
        arrayHelpers.remove(idx);
    };

    const validUrl = yup.string().url('Must be a valid url');
    const defaultSchema = {
        name: yup.string().required(),
        uei: yup
            .string()
            .min(9, 'UEI Code must be at least 9 characters')
            .max(12, 'UEI Code cannot exceed 12 characters')
            .matches(/^[a-zA-Z0-9]{9,12}$/, 'UEI Code must be 9 or 12 characters'),
        sams: yup.string().matches('^[A-Za-z0-9]{5}$', {
            message: 'CAGE Code must be 5 characters',
            excludeEmptyString: true,
        }),
        sams_expire_on: yup.date().notRequired(),
        uei_expire_on: yup.date().notRequired(),
        w9: validUrl,
        financial_audit: validUrl,
        general_audit: validUrl,
        documents: yup.array().of(yup.object().shape({ name: yup.string(), doc_link: validUrl })),
    };

    const createYupSchema = () => {
        try {
            if (vendor === '') {
                return yup.object({
                    ...defaultSchema,
                    address1: yup.string().required(),
                    city: yup.string().required(),
                    state: yup
                        .string()
                        .matches('^[A-Z]{2}$', { message: 'Enter valid State', excludeEmptyString: true })
                        .required(),
                    zip: yup
                        .string()
                        .matches('^[A-Za-z0-9]{5}$', { message: 'Enter valid Zip Code', excludeEmptyString: true })
                        .required(),
                    country: yup.string().required(),
                    contacts: yup.array().of(
                        yup.object().shape({
                            first_name: yup.string().required(),
                            last_name: yup.string().required(),
                            email: yup.string().email('Must be a valid email'),
                            phone: yup.string().min(13).max(16),
                            notes: yup.string(),
                        })
                    ),
                });
            }
            return yup.object({
                ...defaultSchema,
            });
        } catch (error) {
            return errorToast(error);
        }
    };

    const handleFormSubmit = (values) => {
        try {
            if (vendor) {
                VendorsApi.updateVendor(vendor.id, values).then(() => closeModal());
            } else {
                VendorsApi.createVendor(values).then(() => closeModal());
            }
        } catch (error) {
            errorToast(error);
        }
    };

    return (
        <Modal isOpen={openModal} style={{ minWidth: '90vw' }}>
            <div className="AddVendorForm">
                <div className="flex justify-content-between">
                    <SectionHeading className="align-left">{vendor ? vendor.name : 'Add New Vendor'}</SectionHeading>
                    <button onClick={closeModal} aria-label="Close Modal">
                        <i className="fa fa-window-close" />
                    </button>
                </div>
                {vendor && (
                    <div className="VendorStatus mb2 flex justify-content-between">
                        <div className="flex">
                            <div className="mr2">
                                {vendor.approved === 'approved' ? (
                                    <>
                                        <i className="mr1 fa fa-check-circle-o Status-green" aria-hidden="true" />
                                        <span>Approved</span>
                                    </>
                                ) : (
                                    <>
                                        <i className="mr1 fa fa-times-circle-o Status-red" aria-hidden="true" />
                                        <span>Not Approved</span>
                                    </>
                                )}
                            </div>
                            <div>
                                {vendor.active ? (
                                    <>
                                        <i className="fa fa-circle Status-green" aria-hidden="true" />{' '}
                                        <span>Active</span>
                                    </>
                                ) : (
                                    <>
                                        <i className="fa fa-circle-o Status-red" aria-hidden="true" />{' '}
                                        <span>Inactive</span>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="mt2 flex justify-content-between">
                            <button type="reset" className="TextButton" onClick={closeModal}>
                                Cancel
                            </button>
                            <button type="submit" form="addNewVendorForm">
                                Save Vendor
                            </button>
                        </div>
                    </div>
                )}
                <Formik
                    validateOnChange
                    validateOnBlur
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={createYupSchema}
                    onSubmit={(values) => {
                        handleFormSubmit(values);
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form id="addNewVendorForm" className="flex flex-row flex-wrap">
                            <GridSection title="Vendor Information">
                                <div className="mr2 flex flex-column width-30">
                                    <AddVendorContactInfo errors={errors} touched={touched} values={values} />
                                </div>
                                <div className="VerticalLine mr2" />
                                <div className="mr2 flex flex-column width-30">
                                    <AddVendorAccountInfo errors={errors} touched={touched} values={values} />
                                </div>
                                <div className="VerticalLine mr2" />
                                <div className="mr2 flex flex-column width-30">
                                    <h4 className="Subheading">Documents</h4>
                                    <AddVendorDocuments values={values} errors={errors} touched={touched} />
                                    <BasicFieldArray
                                        name="documents"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.documents.map((doc, index) => (
                                                    <Fragment key={'General_Docs-' + index}>
                                                        <div
                                                            key={'docs-horz-' + index}
                                                            className="HorizontalLine mt2 mb2"
                                                        />
                                                        <AddGeneralDocuments
                                                            key={'docs-' + index}
                                                            errors={errors}
                                                            touched={touched}
                                                            index={index}
                                                            values={values.documents}
                                                        />
                                                    </Fragment>
                                                ))}
                                                <button
                                                    type="button"
                                                    className="TextButton"
                                                    onClick={() => handleAddDocs(arrayHelpers)}
                                                >
                                                    + Documents
                                                </button>
                                            </>
                                        )}
                                    />
                                </div>
                            </GridSection>
                            <GridSection title="Point of Contacts">
                                <BasicFieldArray
                                    name="contacts"
                                    render={(arrayHelpers) => (
                                        <>
                                            {values.contacts.map((poc, index) => (
                                                <Fragment key={'Contact-' + index}>
                                                    <button
                                                        aria-label="Remove Point of Contact"
                                                        type="button"
                                                        className="Trash"
                                                        key={'Trash ' + index}
                                                        onClick={() => handleRemovePointOfContacts(arrayHelpers, index)}
                                                    >
                                                        <i className="fa fa-trash-o" />
                                                    </button>
                                                    <AddPointOfContact
                                                        key={'PointOfContact' + index}
                                                        index={index}
                                                        errors={errors}
                                                        touched={touched}
                                                        values={values.contacts}
                                                        agreementList={agreementList}
                                                    />
                                                </Fragment>
                                            ))}
                                            <button
                                                type="button"
                                                className="TextButton align-self-start"
                                                onClick={() => handleAddPointOfContacts(arrayHelpers)}
                                            >
                                                + Point of Contact
                                            </button>
                                        </>
                                    )}
                                />
                            </GridSection>
                        </Form>
                    )}
                </Formik>
                {!vendor && (
                    <div className="mt2 flex justify-content-between">
                        <button type="reset" onClick={closeModal}>
                            Cancel
                        </button>
                        <button type="submit" form="addNewVendorForm">
                            Save Vendor
                        </button>
                    </div>
                )}
                {vendor && (
                    <>
                        <GridSection title="Agreements">
                            <AgreementsTable data={vendor.agreements} onTableReady={hoistTableParams} />
                        </GridSection>
                        <div className="ViewVendorButtons Form__buttons Align-right">
                            <ExportCSVButton fileName={vendor.name + '-Agreements'} exportFunction={exportDataAsCsv} />
                            <button type="button" onClick={openAddAgreement}>
                                Add Agreement
                            </button>
                        </div>
                        <AddAgreementModal
                            vendor={vendor}
                            openModal={agreementsModalOpen}
                            closeModal={closeAddAgreement}
                        />
                    </>
                )}
            </div>
        </Modal>
    );
};

export default AddNewVendorModal;
