import React from 'react';

import PageHeader from '../../components/heading/PageHeader';
import FinanceSubNav from '../FinanceSubNav';
import SubSubNav, { SubSubNavLink } from '../../components/navigation/SubSubNav';
import NewCommercialForm from './NewCommercialForm';

const NewCommercialPage = (props) => {
    const { match } = props;
    return (
        <div>
            <PageHeader title="New Commercial Funding" />
            <FinanceSubNav />
            <SubSubNav>
                <SubSubNavLink to="/finance/commercial" exact>
                    Commercial Funding
                </SubSubNavLink>
                <SubSubNavLink to={match.url}>Create New Commercial Funding</SubSubNavLink>
            </SubSubNav>
            <NewCommercialForm />
        </div>
    );
};

export default NewCommercialPage;
