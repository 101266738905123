import React from 'react';
import Table from '../../../../components/tables/Table';
import ExportCSVButton from '../../../../components/buttons/ExportCSVButton';
import ProjectsApi from '../../../../api/projects';

class LastStatusUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.onTableReady = this.onTableReady.bind(this);

        this.state = {
            data: null,
            selectedService: this.props.selectedService,
        };
    }

    async componentDidMount() {
        const { data: updates } = await ProjectsApi.fetchAllStatusUpdates({
            stale: false,
            active: false,
            service: this.state.selectedService,
        });

        this.setState({ data: updates });
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    render() {
        const { data } = this.state;

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="project-statuses" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div>
                    <Table
                        columns={[
                            {
                                headerName: 'Project No.',
                                field: 'number',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'id',
                                },
                                type: 'idNum',
                            },
                            { headerName: 'Project', field: 'name', type: 'mediumtext' },
                            { headerName: 'Project Lead', field: 'project_lead', type: 'name' },
                            { headerName: 'Project Status', field: 'status', type: 'shortname' },
                            {
                                headerName: 'Office Symbol',
                                field: 'office_symbol',
                                type: 'status',
                            },
                            {
                                headerName: 'Last Status Update',
                                field: 'last_status_update',
                                type: 'date',
                            },
                            {
                                headerName: 'Last Reviewed',
                                field: 'last_reviewed_on',
                                type: 'date',
                            },
                            { headerName: 'Start Date', field: 'start_date', type: 'date' },
                            { headerName: 'End Date', field: 'estimated_end_date', type: 'date' },
                            { headerName: 'Status Description', field: 'status_desc', type: 'mediumtext' },
                        ]}
                        data={data}
                        onTableReady={this.onTableReady}
                        filter={true}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}
export default LastStatusUpdate;
