import React from 'react';
import { withRouter } from 'react-router';
import SubNav, { SubNavLink } from '../components/navigation/SubNav';

const FundingSubNav = () => {
    let url = '/finance';
    const showReconciliation = process.env.REACT_APP_RECONCILIATION_TOGGLE !== false;
    return (
        <SubNav>
            <SubNavLink to={url} exact>
                Dashboard
            </SubNavLink>
            <SubNavLink to={url + '/invoices'}>Agreement Invoices</SubNavLink>
            <SubNavLink to={url + '/creditCard'}>Credit Card</SubNavLink>
            <SubNavLink to={url + '/bankStatements'}>Bank Statements</SubNavLink>
            {showReconciliation && <SubNavLink to={url + '/reconciliation'}>Reconciliation</SubNavLink>}
            <SubNavLink to={url + '/Mods'}>Mods</SubNavLink>
            <SubNavLink to={url + '/commercial'}>Commercial</SubNavLink>
            <SubNavLink to={url + '/grant'}>Grant</SubNavLink>
            <SubNavLink to={url + '/residualFunds'}>Residual Funds & Transfers</SubNavLink>
            <SubNavLink to={url + '/travel'}>Travel</SubNavLink>
        </SubNav>
    );
};

export default withRouter(FundingSubNav);
