// React
import React from 'react';

// Libraries
import moment from 'moment';
import yup from '../../../yup';

// Components
import FieldArray from '../../../components/forms/FieldArray';
import FieldWithError from '../../../components/forms/FieldWithError';
import EditingTable from '../../../components/tables/EditingTable';

// API
import ProjectsApi from '../../../api/projects';

class DeliverablesTable extends React.Component {
    constructor(props) {
        super(props);

        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.addRow = this.addRow.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            row: null,
            rowIndex: null,
            colKey: '',
            errorMessage: '',
        };
    }

    handleDeliverables(deliverables) {
        this.props.handleDeliverables(deliverables);
    }

    onRemoveSelected(evt) {
        const { data } = evt;
        ProjectsApi.removeDeliverable(data.project_id, data.id).then(() => {
            const deliverables = this.props.deliverables.filter((d) => d.id !== data.id);
            this.handleDeliverables(deliverables);
        });
    }

    onRowValueChanged(evt) {
        const { data } = evt;
        if (data.completed === 'Yes' || data.completed === 'No') {
            data.completed = data.completed === 'Yes';
        }
        const attributes = {
            description: data.description,
            due_date: moment(data.due_date),
            event_type: 'deliverable',
            completed: data.completed,
        };
        ProjectsApi.updateDeliverable(data.project_id, data.id, attributes)
            .then((response) => {
                if (response) {
                    this.setState({
                        row: null,
                        rowIndex: null,
                        colKey: '',
                    });
                }
                if (response.status !== 200) return;
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    getRowData(evt) {
        this.setState({
            row: evt.node.data,
            rowIndex: evt.node.rowIndex,
            colKey: 'description',
        });
    }

    addRow(valueToUpdate) {
        const { projectId } = this.props;
        if (projectId !== 'new') {
            const attributes = {
                description: valueToUpdate.description,
                due_date: new Date(valueToUpdate.due_date),
                event_type: 'deliverable',
                completed: valueToUpdate.completed,
            };
            ProjectsApi.addDeliverable(projectId, attributes)
                .then((response) => {
                    const deliverables = this.props.deliverables.map((d) => d);
                    const { data } = response;
                    const newDeliverable = {
                        description: data.description,
                        due_date: data.due_date,
                        id: data.id,
                        project_id: data.project_id,
                        completed: data.completed,
                    };
                    deliverables.push(newDeliverable);
                    this.handleDeliverables(deliverables);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        const { values, errors, touched, isEditing, isNew } = this.props;
        const { rowIndex, colKey } = this.state;
        let deliverables = this.props.deliverables;
        if (deliverables) {
            deliverables.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
        }

        return (
            <FieldArray
                label="Deliverables"
                name="deliverables"
                initialValues={{ description: '', due_date: '' }}
                onRemoveSelected={this.onRemoveSelected}
                addRow={this.addRow}
                validationSchema={yup.object().shape({
                    description: yup.string().required(),
                    due_date: yup.date().required(),
                })}
                values={values}
                errors={errors}
                touched={touched}
                readOnly={!isEditing}
                renderArray={() => (
                    <EditingTable
                        className={isNew ? 'Visibile' : 'Hide'}
                        data={deliverables}
                        columns={[
                            {
                                headerName: 'Deliverables',
                                field: 'description',
                                editable: true,
                                type: 'mediumtext',
                            },
                            {
                                headerName: 'Due Date',
                                field: 'due_date',
                                type: 'date',
                                editable: true,
                            },
                            {
                                headerName: 'Complete',
                                field: 'completed',
                                type: 'shortnumber',
                                cellRenderer: 'BooleanRenderer',
                                editable: true,
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {
                                    values: ['No', 'Yes'],
                                },
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Edit',
                                field: 'edit',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'EditSymbolRenderer',
                                onCellClicked: this.getRowData,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Del.',
                                field: 'delete',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'TrashSymbolRenderer',
                                onCellClicked: this.onRemoveSelected,
                            },
                        ]}
                        rowIndex={rowIndex}
                        colKey={colKey}
                        rowValueChanged={this.onRowValueChanged}
                        sizeToFit={!isEditing}
                    />
                )}
                renderFields={({ errors, touched }) => (
                    <>
                        <FieldWithError
                            className="Text-area-space"
                            name="description"
                            placeholder="Add item..."
                            component="textarea"
                            errors={errors}
                            touched={touched}
                        />
                        <div className="Deliverable-date-box">
                            <label>
                                Due Date
                                <FieldWithError
                                    name="due_date"
                                    type="date"
                                    component="Date"
                                    errors={errors}
                                    touched={touched}
                                />
                            </label>
                        </div>
                        <FieldWithError
                            name="completed"
                            component="Checkbox"
                            label="Completed?"
                            errors={errors}
                            touched={touched}
                        />
                    </>
                )}
            />
        );
    }
}

export default DeliverablesTable;
