import React from 'react';
import Select from 'react-select';
import ReportSubnav from './ReportsSubNav';
import PageHeader from '../components/heading/PageHeader';
import { FundsTransferSumForm, BasicProjectReportForm } from './ReportForms';
import ReportHeader from './ReportHeader';
import { history } from '../store/store';

import ProjectsApi from '../api/projects';

import ProjectFundsStatusReport from './output/PROutput/ProjectFundsStatusReport';
import ProjectStatusReport from './output/PROutput/ProjectStatusReport';
import FundsTransferSummary from './output/PROutput/FundsTransferSummary';
import ProjectDescription from './output/PROutput/ProjectDescription';
import errorToast from '../components/messages/ErrorMessage';

class ProjectReportPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProject: null,
            reportType: [
                {
                    name: 'Funds Transfer Summary',
                    inputId: 1,
                    reportOutput: 1,
                },
                {
                    name: 'Project Description',
                    inputId: 2,
                    reportOutput: 2,
                },
                {
                    name: 'Project Status Report',
                    inputId: 2,
                    reportOutput: 3,
                },
                {
                    name: 'Project Funds Status Report',
                    inputId: 2,
                    reportOutput: 4,
                },
            ],
            selectedReportType: null,
            selectedValue: 2,
            projects: [],
            isSubmitted: false,
            name: '',
            num: '',
            fromDate: '',
            toDate: '',
            date: '',
        };
        this.handleBasicSubmit = this.handleBasicSubmit.bind(this);
        this.handleTransferSubmit = this.handleTransferSubmit.bind(this);
    }

    componentDidMount = async () => {
        try {
            const { data: projects } = await ProjectsApi.fetchProjects();

            projects.forEach((project) => {
                project.users.forEach((user) => {
                    if (project.wbi_lead_user_id === user.id) {
                        project.wbi_contact = user.name;
                    }
                });
            });
            this.setState({ projects });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    handleBasicSubmit(values, actions) {
        let projects = this.state.projects;
        if (values) {
            history.push({
                query: {
                    projectName: values.name,
                    projectNum: values.number,
                    date: values.date,
                },
            });
            this.setState({
                name: values.name,
                num: values.number,
                date: values.date,
                isSubmitted: true,
            });
        }
        let project;
        if (values.number) {
            project = projects.find((e) => e.number.toUpperCase() === values.number.toUpperCase());

            actions.setSubmitting(false);
        } else if (values.name) {
            project = projects.find((project) => project.name.toUpperCase() === values.name.toUpperCase());
            actions.setSubmitting(false);
        }
        this.setState({ project });
    }

    handleTransferSubmit(values, actions) {
        if (values) {
            history.push({
                query: {
                    projectName: values.name,
                    projectNum: values.number,
                    toDate: values.endDate,
                    fromDate: values.startDate,
                },
            });
        }
        let project;
        if (values.number) {
            project = this.state.projects.find(
                (project) => project.number.toUpperCase() === values.number.toUpperCase()
            );
            actions.setSubmitting(false);
        } else if (!values.number && values.name) {
            project = this.state.projects.find((project) => project.name.toUpperCase() === values.name.toUpperCase());
            actions.setSubmitting(false);
        }

        this.setState({
            name: values.name,
            num: values.number,
            project,
            fromDate: values.startDate,
            toDate: values.endDate,
            isSubmitted: true,
        });
    }

    onchange = (event, values) => {
        if (event) {
            values.number = event.object.number;
            values.name = event.label;
            this.setState({
                selectedProject: event,
                number: event.object.number,
                name: event.label,
                isSubmitted: false,
            });
        }
    };

    updateContent(e) {
        let item = e;
        this.setState({
            selectedReportType: item,
            selectedProject: null,
            isSubmitted: false,
        });
    }

    render() {
        let { reportType, selectedReportType, selectedProject, project, toDate, fromDate, isSubmitted, name, num } =
            this.state;

        let reportOutput;

        if (isSubmitted) {
            switch (selectedReportType.reportOutput) {
                case 1:
                    reportOutput = <FundsTransferSummary data={project} filterRange={[fromDate, toDate]} />;
                    break;
                case 2:
                    reportOutput = <ProjectDescription data={project} />;
                    break;
                case 3:
                    reportOutput = <ProjectStatusReport data={project} />;
                    break;
                case 4:
                    reportOutput = <ProjectFundsStatusReport data={project} />;
                    break;
                default:
                    break;
            }
        }

        let reportOptions = [];
        let i;
        if (reportType) {
            for (i = 0; i < reportType.length; i++) {
                reportOptions.push({
                    value: reportType[i].inputId,
                    label: reportType[i].name,
                    reportOutput: reportType[i].reportOutput,
                });
            }
        }
        let form;

        if (selectedReportType) {
            if (selectedReportType.value === 1) {
                form = (
                    <FundsTransferSumForm
                        handleSubmit={this.handleTransferSubmit}
                        onChange={this.onchange}
                        selectedProject={selectedProject}
                        name={name}
                        num={num}
                    />
                );
            } else {
                form = (
                    <BasicProjectReportForm
                        handleSubmit={this.handleBasicSubmit}
                        onChange={this.onchange}
                        selectedProject={selectedProject}
                        name={name}
                        num={num}
                    />
                );
            }
        }
        let reportName = '';
        if (selectedProject) {
            console.log(selectedProject);

            reportName = selectedProject.label ? selectedProject.label : '';
        }

        return (
            <div>
                <PageHeader title="Reports" />
                <ReportSubnav />
                <div className="Wide-single-layout">
                    <div className="Report-form-container">
                        <label className="FieldLabel">Report</label>
                        <Select
                            value={selectedReportType}
                            options={reportOptions}
                            onChange={(e) => {
                                this.updateContent(e);
                            }}
                        />
                        {form}
                    </div>
                </div>
                <div className="Wide-single-layout Report-card">
                    {isSubmitted ? (
                        <div>
                            <ReportHeader title={reportName} />
                            {reportOutput}
                        </div>
                    ) : (
                        <h3>Please select a report to run </h3>
                    )}
                </div>
            </div>
        );
    }
}

export default ProjectReportPage;
