// TravelExpenditureType Enum found in travel_expense.py
export const travelExpenditureType = {
    taxi_uber: 'Taxi/Uber', // Standard form
    parking_tolls: 'Parking/Tolls', // Parking tolls form
    other: 'Other', // Standard form
    miles_driven: 'Miles Driven', // Miles driven form
    hotel_parking: 'Hotel Parking', // Standard form
    hotel: 'Hotel', // Hotel form
    car_rental_gas: 'Car Rental Gas', // Standard form
    car_rental: 'Car Rental', // Car rental form
    baggage: 'Baggage Fees', // Baggage form
    airfare: 'Airfare', // Airfare form
};

export const prepareTravelExpenditureTypeOptions = () => {
    try {
        const travelExpenditureTypeOptions = [];
        Object.keys(travelExpenditureType).forEach((key) => {
            travelExpenditureTypeOptions.push({
                label: travelExpenditureType[key],
                value: key,
            });
        });
        return travelExpenditureTypeOptions;
    } catch (error) {
        throw new Error('Error preparing travel expenditure type options');
    }
};

export const getTravelExpenditureType = (travelExpenditure) => {
    try {
        let typeKey = Object.keys(travelExpenditureType).find(
            (key) => travelExpenditureType[key] === travelExpenditure.travel_expenditure_type
        );
        if (!typeKey) {
            typeKey = 'Per Diem';
        }
        return typeKey;
    } catch (error) {
        throw new Error('Error getting travel expenditure type');
    }
};
