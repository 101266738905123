import React from 'react';
import moment from 'moment';
import { WeeklyReportForm } from '../ReportForms';
import SelectionTable from '../../components/tables/SelectionTable';
import ReportHeader from '../ReportHeader';
import ExportCSVButton from '../../components/buttons/ExportCSVButton';

//API connections
import ProjectsApi from '../../api/projects';
import errorToast from '../../components/messages/ErrorMessage';

class WeeklyActivityReport extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onTableReady = this.onTableReady.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);

        this.state = {
            activities: null,
            data: null,
            selected: [],
        };
    }

    componentDidMount = async () => {
        const { data: activities } = await ProjectsApi.fetchAllActivityReports();
        this.setState({
            activities: activities.weekly,
            data: activities.weekly,
        });
    };

    handleSelectionChange(event) {
        let nodeList = event.api.getSelectedNodes();
        let listRow = [];
        nodeList.forEach((node) => {
            listRow.push(node.data);
        });

        this.setState({ selected: listRow });
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    handleSubmit = (values, actions) => {
        const momentEnd = moment(values.endDate);
        const momentStart = moment(values.startDate);

        if (!momentEnd.isValid() || !momentStart.isValid() || momentEnd < momentStart) {
            errorToast('There is an error in the Dates you chose please review');
            actions.setSubmitting(false);
            return;
        }
        let start = momentStart.toDate();
        let end = momentEnd.toDate();

        let acts = this.state.activities;

        let results = acts.filter((a) => new Date(a.to_date) <= end && new Date(a.from_date) >= start);
        this.setState({
            data: results,
        });
        actions.resetForm();
        actions.setSubmitting(false);
    };

    downloadFile = () => {
        let data = [];

        this.state.selected.forEach((ele) => {
            data.push(ele.id);
        });
        let item = {};
        item.activities = this.state.selected;

        ProjectsApi.generateEDWARActivityReports(data.join('_'));
    };

    render() {
        let form = <WeeklyReportForm handleSubmit={this.handleSubmit} />;
        return (
            <div>
                <div className="Report-form-container Wide-single-layout">
                    <label className="FieldLabel">Report</label>
                    <div>{form}</div>
                </div>
                <div className="Report-card Wide-single-layout">
                    <ReportHeader title="Weekly Activity Reports" />
                    <div className="Report-output">
                        <div className="Form__buttons--Reversed Buttons_format">
                            <div className="Print-button-with-csv">
                                <button disabled={this.state.selected.length === 0} onClick={this.downloadFile}>
                                    Download Selected as Word Document
                                </button>
                                <ExportCSVButton
                                    fileName="Weekly-activity-reports"
                                    exportFunction={this.exportDataAsCsv}
                                />
                            </div>
                        </div>
                        <div>
                            <SelectionTable
                                columns={[
                                    { headerName: '', type: 'checkbox' },
                                    {
                                        headerName: 'Activity Start Date',
                                        field: 'from_date',
                                        type: 'date',
                                    },
                                    {
                                        headerName: 'Activity End Date',
                                        field: 'to_date',
                                        type: 'date',
                                    },
                                    { headerName: 'Service', field: 'service', type: 'name', filter: true },
                                    {
                                        headerName: 'Office Symbol',
                                        field: 'office_symbol',
                                        type: 'shortname',
                                    },
                                    {
                                        headerName: 'Project Name',
                                        field: 'project_name',
                                        type: 'name',
                                    },
                                    {
                                        headerName: 'Project No.',
                                        field: 'project_num',
                                        cellRenderer: 'LinkRenderer',
                                        cellRendererParams: {
                                            routeBase: '/projects/',
                                            routeKeyField: 'project_id',
                                        },
                                        type: 'idNum',
                                    },
                                    {
                                        headerName: 'Activity/Impact',
                                        field: 'impact',
                                        type: 'longtext',
                                    },
                                    {
                                        headerName: 'Date Entered',
                                        field: 'created_on',
                                        type: 'date',
                                    },
                                ]}
                                data={this.state.data}
                                filter={true}
                                sortable={true}
                                selectionChanged={this.handleSelectionChange}
                                onTableReady={this.onTableReady}
                                tableClassName="Activity-report"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WeeklyActivityReport;
