import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import errorToast from '../../components/messages/ErrorMessage';
import { travelExpenditureType } from '../../utils/TravelUtils';
import ErgoMoney from '../../utils/ErgoMoney';

const templateRows = [
    { rowHeader: 'Miles Driven' },
    { rowHeader: 'Mileage Reimbursement' },
    { rowHeader: 'Airfare' },
    { rowHeader: 'Baggage Fees' },
    { rowHeader: 'Parking/Tolls' },
    { rowHeader: 'Car Rental' },
    { rowHeader: 'Car Rental Gas' },
    { rowHeader: 'Taxi/Uber' },
    { rowHeader: 'Hotel' },
    { rowHeader: 'Hotel Taxes & Fees' },
    { rowHeader: 'Hotel Parking' },
    { rowHeader: 'M&IE (First & Last day @ 75%)' },
    { rowHeader: 'Non-Reimbursables' },
    { rowHeader: 'Other' },
    { rowHeader: 'Total' },
];

const templateColumns = [
    {
        headerName: 'Category',
        field: 'rowHeader',
        type: 'rowHeaders',
    },
    {
        headerName: 'Personal',
        field: 'personal',
        type: 'pinnedColumn',
    },
    {
        headerName: 'Business',
        field: 'business',
        type: 'pinnedColumn',
    },
    {
        headerName: 'Grand Total',
        field: 'grand_total',
        type: 'pinnedColumn',
    },
];

export const TravelExpenseTable = (props) => {
    const [rows, setRows] = useState(templateRows);
    const [columns, setColumns] = useState(templateColumns);

    const insertDataColumns = (columns) => [...templateColumns.slice(0, 1), ...columns, ...templateColumns.slice(1)];

    const createRowData = (rowData) =>
        templateRows.map((row) => ({
            ...row,
            ...rowData,
        }));

    useEffect(() => {
        const {
            tableData: { rowData, columns },
        } = props;

        if (columns) {
            const newColumns = insertDataColumns(columns);
            setColumns(newColumns);
        }
        if (rowData) {
            const newRowData = createRowData(rowData);
            setRows(newRowData);
        }
    }, [props.tableData, props]);

    const getCellAmountFromRow = (cellData, columnKey, rowKey) =>
        cellData.map((item) => {
            let returnValue = 0;
            switch (rowKey) {
                case 'Mileage Reimbursement':
                    returnValue = item.mileageCharge;
                    break;
                case 'Hotel':
                    returnValue = item.nightRate;
                    break;
                case 'Hotel Taxes & Fees':
                    if (columnKey === item.expenditure.purchase_date) {
                        returnValue = item.hotel_taxes_fees;
                    }
                    break;
                case 'Non-Reimbursables':
                    if (columnKey === item.expenditure.purchase_date) {
                        returnValue = item.hotel_non_reimburse;
                    }
                    break;
                default:
                    if (item.expenditure) {
                        returnValue = item.expenditure.cost;
                    }
            }
            return returnValue;
        });

    const sumCellAmounts = (column, colKey, rowKey) => {
        const cellAmounts = getCellAmountFromRow(column[rowKey], colKey, rowKey);
        return cellAmounts.reduce((sum, cellAmount) => {
            const totalCell = new ErgoMoney().add(cellAmount).getNumberAmount();
            sum += totalCell;
            return sum;
        }, 0);
    };

    const sumColumn = (column, colKey) => {
        let columnSum = new ErgoMoney();
        for (const rowKey in column) {
            if (rowKey === travelExpenditureType.miles_driven) {
                continue;
            } else if (rowKey === 'Non-Reimbursables') {
                continue;
            }
            const cellTotal = sumCellAmounts(column, colKey, rowKey);
            columnSum.add(cellTotal);
        }
        return columnSum.getNumberAmount();
    };

    const sumCellExpendituresForDisplay = (travelExpenditures) =>
        travelExpenditures.reduce((sum, travelExpenditure) => {
            const totalCell = new ErgoMoney().add(travelExpenditure.expenditure.cost).getNumberAmount();
            sum += totalCell;
            return sum;
        }, 0);

    const hotelTaxesAndFees = (travelExpenditures, columnKey) => {
        travelExpenditures = travelExpenditures.filter(
            (travelExpenditure) => travelExpenditure.expenditure.purchase_date === columnKey
        );
        return travelExpenditures.reduce((sum, travelExpenditure) => {
            const totalCell = new ErgoMoney().add(travelExpenditure.hotel_taxes_fees).getNumberAmount();
            sum += totalCell;
            return sum;
        }, 0);
    };

    const renderInputColumnCell = (cell) => {
        try {
            if (cell.rowIndex === 0) {
                return '<a>' + cell.value + '</a>';
            }
            if (cell.rowIndex === 14) {
                return new ErgoMoney(cell.value).getStringAmount();
            }
            if (cell.value) {
                return '<a>' + new ErgoMoney(cell.value).getStringAmount() + '</a>';
            }
        } catch (error) {
            errorToast('Error rendering input column cells');
        }
    };

    const renderPinnedColumnCell = (cell) => {
        try {
            if (cell.rowIndex === 0) {
                return cell.value;
            }
            if (cell.value) {
                return new ErgoMoney(cell.value).getStringAmount();
            }
        } catch (error) {
            errorToast('Error rendering pinned column cells');
        }
    };

    const isTravelExpenditurePersonal = (travelExpenditure) => {
        if (!travelExpenditure.expenditure) {
            return false;
        }

        return travelExpenditure.expenditure.cc_type === 'personal' || travelExpenditure.type === 'personal';
    };

    const isTravelExpenditureOnColumnDate = (travelExpenditure, columnDate) =>
        travelExpenditure.expenditure.purchase_date === columnDate;

    const sumPersonalRow = (valueGetterParams) => {
        try {
            const rowData = valueGetterParams.data;
            const rowKey = rowData.rowHeader;

            let result = 0;
            for (const colKey in rowData) {
                if (colKey !== 'rowHeader') {
                    const travelExpenditures = rowData[colKey][rowKey];

                    for (const travelExpenditure of travelExpenditures) {
                        if (rowKey === 'Miles Driven') {
                            result += isTravelExpenditurePersonal(travelExpenditure)
                                ? travelExpenditure.milesDriven
                                : 0;
                        } else if (rowKey === 'Mileage Reimbursement') {
                            result = new ErgoMoney(result)
                                .add(
                                    isTravelExpenditurePersonal(travelExpenditure) ? travelExpenditure.mileageCharge : 0
                                )
                                .getNumberAmount();
                        } else if (rowKey === 'Hotel') {
                            result = new ErgoMoney(result)
                                .add(isTravelExpenditurePersonal(travelExpenditure) ? travelExpenditure.nightRate : 0)
                                .getNumberAmount();
                        } else if (rowKey === 'Hotel Taxes & Fees') {
                            if (isTravelExpenditureOnColumnDate(travelExpenditure, colKey)) {
                                result = new ErgoMoney(result)
                                    .add(
                                        isTravelExpenditurePersonal(travelExpenditure)
                                            ? travelExpenditure.hotel_taxes_fees
                                            : 0
                                    )
                                    .getNumberAmount();
                            }
                        } else if (rowKey === 'Non-Reimbursables') {
                            if (isTravelExpenditureOnColumnDate(travelExpenditure, colKey)) {
                                result = new ErgoMoney(result)
                                    .subtract(travelExpenditure.hotel_non_reimburse)
                                    .getNumberAmount();
                            }
                        } else if (rowKey === 'Total') {
                            result = rowData.personal.Total[0].value;
                        } else {
                            result = new ErgoMoney(result)
                                .add(
                                    isTravelExpenditurePersonal(travelExpenditure)
                                        ? travelExpenditure.expenditure.cost
                                        : 0
                                )
                                .getNumberAmount();
                        }
                    }
                }
            }
            return result;
        } catch (error) {
            errorToast('Error summing personal row');
        }
    };

    const isTravelExpenditureBusiness = (travelExpenditure) => {
        if (!travelExpenditure.expenditure) {
            return false;
        }

        return travelExpenditure.expenditure.cc_type === 'business' || travelExpenditure.type === 'business';
    };

    const sumBusinessRow = (valueGetterParams) => {
        try {
            const rowData = valueGetterParams.data;
            const rowKey = rowData.rowHeader;

            let result = 0;
            for (const colKey in rowData) {
                if (colKey !== 'rowHeader') {
                    const travelExpenditures = rowData[colKey][rowKey];

                    for (const travelExpenditure of travelExpenditures) {
                        if (rowKey === 'Miles Driven') {
                            result += isTravelExpenditureBusiness(travelExpenditure)
                                ? travelExpenditure.milesDriven
                                : 0;
                        } else if (rowKey === 'Mileage Reimbursement') {
                            result = new ErgoMoney(result)
                                .add(
                                    isTravelExpenditureBusiness(travelExpenditure) ? travelExpenditure.mileageCharge : 0
                                )
                                .getNumberAmount();
                        } else if (rowKey === 'Hotel') {
                            result = new ErgoMoney(result)
                                .add(isTravelExpenditureBusiness(travelExpenditure) ? travelExpenditure.nightRate : 0)
                                .getNumberAmount();
                        } else if (rowKey === 'Hotel Taxes & Fees') {
                            if (colKey === travelExpenditure.expenditure.purchase_date) {
                                result = new ErgoMoney(result)
                                    .add(
                                        isTravelExpenditureBusiness(travelExpenditure)
                                            ? travelExpenditure.hotel_taxes_fees
                                            : 0
                                    )
                                    .getNumberAmount();
                            }
                        } else if (rowKey === 'Non-Reimbursables') {
                            if (isTravelExpenditureOnColumnDate(travelExpenditure, colKey)) {
                                result = new ErgoMoney(result).add(0).getNumberAmount();
                            }
                        } else if (rowKey === 'Total') {
                            result = rowData.business.Total[0].value;
                        } else {
                            result = new ErgoMoney(result)
                                .add(
                                    isTravelExpenditureBusiness(travelExpenditure)
                                        ? travelExpenditure.expenditure.cost
                                        : 0
                                )
                                .getNumberAmount();
                        }
                    }
                }
            }
            return result;
        } catch (error) {
            errorToast('Error summing business row');
        }
    };

    const sumGrandTotalRow = (valueGetterParams) => {
        const rowData = valueGetterParams.data;
        const rowKey = rowData.rowHeader;

        let result = 0;
        for (const colKey in rowData) {
            if (colKey !== 'rowHeader') {
                let travelExpenditures = rowData[colKey][rowKey];

                for (const travelExpenditure of travelExpenditures) {
                    if (rowKey === 'Miles Driven') {
                        result += travelExpenditure.milesDriven;
                    } else if (rowKey === 'Mileage Reimbursement') {
                        result = new ErgoMoney(result).add(travelExpenditure.mileageCharge).getNumberAmount();
                    } else if (rowKey === 'Hotel') {
                        result = new ErgoMoney(result).add(travelExpenditure.nightRate).getNumberAmount();
                    } else if (rowKey === 'Hotel Taxes & Fees') {
                        if (colKey === travelExpenditure.expenditure.purchase_date) {
                            result = new ErgoMoney(result).add(travelExpenditure.hotel_taxes_fees).getNumberAmount();
                        }
                    } else if (rowKey === 'Non-Reimbursables') {
                        if (colKey === travelExpenditure.expenditure.purchase_date) {
                            result = new ErgoMoney(result)
                                .subtract(travelExpenditure.hotel_non_reimburse)
                                .getNumberAmount();
                        }
                    } else if (rowKey === 'Total') {
                        if (travelExpenditure.type === 'grand_total') {
                            result = travelExpenditure.value;
                        }
                    } else {
                        result = new ErgoMoney(result).add(travelExpenditure.expenditure.cost).getNumberAmount();
                    }
                }
            }
        }
        return result;
    };

    const sumInputColumnToTotal = (valueGetterParams) => {
        const colKey = valueGetterParams.colDef.headerName;
        const rowData = valueGetterParams.data;
        const column = rowData[colKey];
        return sumColumn(column, colKey);
    };

    const sumMilesDrivenCell = (valueGetterParams) => {
        try {
            const colKey = valueGetterParams.colDef.headerName;
            const column = valueGetterParams.data[colKey];
            const rowKey = valueGetterParams.data.rowHeader;
            if (column) {
                const cell = column[rowKey];
                if (cell) {
                    return cell.reduce((sum, travelExpenditure) => {
                        sum += travelExpenditure.milesDriven;
                        return sum;
                    }, 0);
                }
            }
        } catch (error) {
            errorToast('Error summing miles driven');
        }
    };

    const sumMileageReimbursmentCell = (valueGetterParams) => {
        try {
            const colKey = valueGetterParams.colDef.headerName;
            const column = valueGetterParams.data[colKey];
            const rowKey = valueGetterParams.data.rowHeader;
            if (column) {
                const cell = column[rowKey];
                if (cell) {
                    return cell.reduce((sum, travelExpenditure) => {
                        const totalCell = new ErgoMoney().add(travelExpenditure.mileageCharge).getNumberAmount();
                        sum += totalCell;
                        return sum;
                    }, 0);
                }
            }
        } catch (error) {
            errorToast('Error summing mileage reimbursement');
        }
    };

    const sumHotelCell = (valueGetterParams) => {
        const colKey = valueGetterParams.colDef.headerName;
        const column = valueGetterParams.data[colKey];
        const rowKey = valueGetterParams.data.rowHeader;
        if (column) {
            const cell = column[rowKey];
            if (cell) {
                return cell.reduce((sum, travelExpenditure) => {
                    const totalCell = new ErgoMoney().add(travelExpenditure.nightRate).getNumberAmount();
                    sum += totalCell;
                    return sum;
                }, 0);
            }
        }
    };

    const sumHotelTaxesAndFeesCell = (valueGetterParams) => {
        const colKey = valueGetterParams.colDef.headerName;
        const column = valueGetterParams.data[colKey];
        const rowKey = valueGetterParams.data.rowHeader;
        if (column) {
            const cell = column[rowKey];
            return hotelTaxesAndFees(cell, colKey);
        }
    };

    const sumNonReimbursablesCell = (valueGetterParams) => {
        const colKey = valueGetterParams.colDef.headerName;
        const column = valueGetterParams.data[colKey];
        const rowKey = valueGetterParams.data.rowHeader;
        if (column) {
            let cell = column[rowKey];
            if (cell) {
                cell = cell.filter((travelExpenditure) => travelExpenditure.expenditure.purchase_date === colKey);
                return cell.reduce((sum, travelExpenditure) => {
                    const totalCell = new ErgoMoney().subtract(travelExpenditure.hotel_non_reimburse).getNumberAmount();
                    sum += totalCell;
                    return sum;
                }, 0);
            }
        }
    };

    const getPerDiemCellValue = (valueGetterParams) => {
        try {
            const colKey = valueGetterParams.colDef.headerName;
            const column = valueGetterParams.data[colKey];
            const rowKey = valueGetterParams.data.rowHeader;
            const rowData = valueGetterParams.data;
            if (column) {
                const perDiemCell = rowData[colKey][rowKey];
                if (perDiemCell[0]) {
                    return perDiemCell[0].expenditure.cost;
                }
            }
        } catch (error) {
            errorToast('Error getting M&IE value');
        }
    };

    const sumTravelExpenditureCell = (valueGetterParams) => {
        const colKey = valueGetterParams.colDef.headerName;
        const rowKey = valueGetterParams.data.rowHeader;
        const rowData = valueGetterParams.data;
        const column = rowData[colKey];
        if (column) {
            const cell = column[rowKey] || [];
            return sumCellExpendituresForDisplay(cell);
        }
    };

    const travelExpenditureValueGetter = (data) => {
        const fieldName = data.colDef.field;
        const headerName = data.data.rowHeader;

        if (fieldName === 'rowHeader') {
            return headerName;
        }
        if (fieldName === 'personal') {
            return sumPersonalRow(data);
        }
        if (fieldName === 'business') {
            return sumBusinessRow(data);
        }
        if (fieldName === 'grand_total') {
            return sumGrandTotalRow(data);
        }
        if (headerName === 'Total') {
            return sumInputColumnToTotal(data);
        }
        if (headerName === 'Miles Driven') {
            return sumMilesDrivenCell(data);
        }
        if (headerName === 'Mileage Reimbursement') {
            return sumMileageReimbursmentCell(data);
        }
        if (headerName === 'Hotel') {
            return sumHotelCell(data);
        }
        if (headerName === 'Hotel Taxes & Fees') {
            return sumHotelTaxesAndFeesCell(data);
        }
        if (headerName === 'Non-Reimbursables') {
            return sumNonReimbursablesCell(data);
        }
        if (headerName === 'M&IE (First & Last day @ 75%)') {
            return getPerDiemCellValue(data);
        }
        return sumTravelExpenditureCell(data);
    };

    const handleCellClickedEvent = (event) => {
        const { hoistTravelExpenditures } = props;
        const rowKey = event.data.rowHeader;
        const colKey = event.colDef.headerName;
        const travelExpenditures = event.data[colKey][rowKey];

        let perDiemRowFlag = false;
        if (rowKey === 'M&IE (First & Last day @ 75%)') {
            perDiemRowFlag = true;
        }

        if (travelExpenditures.length) {
            hoistTravelExpenditures(travelExpenditures, perDiemRowFlag);
        }
    };

    return (
        <div className="Ag-theme-blue">
            <AgGridReact
                domLayout="autoHeight"
                rowData={rows}
                columnDefs={columns}
                suppressClickEdit={true}
                suppressScrollOnNewData={true}
                headerHeight={38}
                pagination={false}
                columnTypes={{
                    rowHeaders: {
                        width: 220,
                    },
                    inputColumn: {
                        cellRenderer: renderInputColumnCell,
                        onCellClicked: handleCellClickedEvent,
                    },
                    pinnedColumn: {
                        cellRenderer: renderPinnedColumnCell,
                    },
                }}
                defaultColDef={{
                    width: 100,
                    resizable: true,
                    autoHeight: true,
                    editable: false,
                    filter: false,
                    sortable: false,
                    cellClass: 'Align-right',
                    valueGetter: travelExpenditureValueGetter,
                }}
            />
        </div>
    );
};

export default TravelExpenseTable;
