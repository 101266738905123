import React from 'react';
//Library
import { Formik } from 'formik';
import isPresent from 'is-present';
//Shared Component
import FieldWithError from '../forms/FieldWithError';

class SignatureForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: !isPresent(this.props.initialValues.name),
            isLinkingToDb: false,
        };
    }

    render() {
        const { isEditing, isLinkingToDb } = this.state;
        const { signature, readOnly, ...props } = this.props;
        let userOptions = [];
        if (this.props.users) {
            userOptions.push({ label: 'Name', value: '' });
            this.props.users.forEach((user) => {
                userOptions.push({
                    label: user.name,
                    value: user.id,
                    title: user.site_role,
                    id: user.id,
                });
            });
        }
        return (
            <Formik isInitialValid={isPresent(this.props.initialValues.name)} {...props}>
                {({ values, errors, touched, isValid, submitForm, resetForm, setFieldValue }) => (
                    <div className="">
                        <p style={{ height: '2em', borderBottom: '1px solid black' }}>
                            {values.signed && (
                                <>
                                    {values.name}
                                    {values.link_to_db && (
                                        <a href={values.link_to_db} rel="noreferrer" style={{ marginLeft: 8 }}>
                                            Open in DB
                                        </a>
                                    )}
                                </>
                            )}
                        </p>
                        <div />
                        {userOptions ? (
                            <div>
                                {!isEditing || isLinkingToDb ? (
                                    <div>
                                        <FieldWithError name="name" type="text" placeholder="Name" readOnly />
                                        <FieldWithError name="title" type="text" readOnly />
                                    </div>
                                ) : (
                                    <div>
                                        {!values.signed && (
                                            <FieldWithError
                                                name="name"
                                                component="select"
                                                options={userOptions}
                                                errors={errors}
                                                touched={touched}
                                                readOnly={!isEditing}
                                                onChange={(values) => {
                                                    setFieldValue('name', values);
                                                    setFieldValue('name_display', values.label);
                                                    setFieldValue('id', this.props.initialValues.id);
                                                    setFieldValue('user_id', values.id);
                                                    setFieldValue('title', values.title);
                                                    setFieldValue('site_role', values.title);
                                                    setFieldValue('valueToUpdate', this.props.initialValues.id);
                                                }}
                                            />
                                        )}
                                        <FieldWithError name="title" type="text" placeholder="Title" readOnly />
                                    </div>
                                )}
                                {isEditing && isLinkingToDb && (
                                    <FieldWithError
                                        name="link_to_db"
                                        type="text"
                                        placeholder="Link to DB"
                                        errors={errors}
                                        touched={touched}
                                    />
                                )}
                            </div>
                        ) : (
                            <div>
                                <FieldWithError
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    errors={errors}
                                    touched={touched}
                                    readOnly={!isEditing}
                                />

                                <FieldWithError
                                    name="title"
                                    type="text"
                                    placeholder="Title"
                                    errors={errors}
                                    touched={touched}
                                    readOnly={!isEditing}
                                />
                                <FieldWithError
                                    name="organization"
                                    type="text"
                                    placeholder="Organization"
                                    errors={errors}
                                    touched={touched}
                                    readOnly={!isEditing}
                                />
                                {isEditing && isLinkingToDb && (
                                    <FieldWithError
                                        name="link_to_db"
                                        type="text"
                                        placeholder="Link to DB"
                                        errors={errors}
                                        touched={touched}
                                    />
                                )}
                            </div>
                        )}

                        {isEditing ? (
                            <>
                                {!this.props.readOnly && (
                                    <button
                                        className="flex flex-column flex-1 Link-button"
                                        type="button"
                                        onClick={() => this.setState({ isEditing: false }) && resetForm()}
                                    >
                                        Cancel
                                    </button>
                                )}
                                {!this.props.readOnly && (
                                    <button
                                        className="flex flex-column flex-1 Link-button"
                                        type="button"
                                        onClick={() => {
                                            if (isValid) this.setState({ isEditing: false });
                                            submitForm();
                                        }}
                                    >
                                        Save
                                    </button>
                                )}
                            </>
                        ) : (
                            <>
                                {!this.props.readOnly && (
                                    <button
                                        className="flex flex-column flex-1 Link-button"
                                        type="button"
                                        onClick={() => this.setState({ isLinkingToDb: true, isEditing: true })}
                                    >
                                        Attach Signature
                                    </button>
                                )}
                                {!this.props.readOnly && (
                                    <button
                                        className="flex flex-column flex-1 Link-button"
                                        type="button"
                                        onClick={async () => {
                                            setFieldValue('signed', true);
                                            await Promise.resolve();
                                            submitForm();
                                        }}
                                    >
                                        Sign
                                    </button>
                                )}
                                {!this.props.readOnly && (
                                    <button
                                        className="flex flex-column flex-1 Link-button"
                                        type="button"
                                        onClick={() => this.setState({ isEditing: true })}
                                    >
                                        Edit
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                )}
            </Formik>
        );
    }
}

export default SignatureForm;
