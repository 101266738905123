import React from 'react';
import moment from 'moment';
import { QuarterlyReportForm } from '../ReportForms';
import Table from '../../components/tables/Table';
import ReportHeader from '../ReportHeader';
import errorToast from '../../components/messages/ErrorMessage';
import ExportCSVButton from '../../components/buttons/ExportCSVButton';

//API connections
import ProjectsApi from '../../api/projects';

class QuarterlyAvtivityReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: null,
            data: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onTableReady = this.onTableReady.bind(this);
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    componentDidMount = async () => {
        const { data: activities } = await ProjectsApi.fetchAllActivityReports();
        this.setState({
            activities: activities.quarterly,
            data: activities.quarterly,
        });
    };

    handleSubmit = (values, actions) => {
        const momentEnd = moment(values.endDate);
        const momentStart = moment(values.startDate);

        if (!momentEnd.isValid() || !momentStart.isValid() || momentEnd < momentStart) {
            errorToast('There is an error in the Dates you chose please review');
            actions.setSubmitting(false);
            return;
        }
        let start = momentStart.toDate();
        let end = momentEnd.toDate();

        let acts = this.state.activities;

        let results = acts.filter((a) => new Date(a.to_date) < end && new Date(a.from_date) > start);
        this.setState({
            data: results,
        });
        actions.resetForm();
        actions.setSubmitting(false);
    };

    render() {
        let form = <QuarterlyReportForm handleSubmit={this.handleSubmit} />;
        return (
            <div>
                <div className="Report-form-container Wide-single-layout">
                    <label className="FieldLabel">Report</label>
                    <div>{form}</div>
                </div>
                <div className="Report-card Wide-single-layout">
                    <ReportHeader title="Quarterly Activity Reports" />
                    <div className="Report-output">
                        <div className="Form__buttons--Reversed Buttons_format">
                            <div className="Print-button-with-csv">
                                <ExportCSVButton
                                    fileName="quarterly-activity-reports"
                                    exportFunction={this.exportDataAsCsv}
                                />
                            </div>
                        </div>
                        <Table
                            columns={[
                                {
                                    headerName: 'Activity Start Date',
                                    field: 'from_date',
                                    type: 'date',
                                },
                                { headerName: 'Activity End Date', field: 'to_date', type: 'date' },
                                { headerName: 'Service', field: 'service', type: 'name', filter: true },
                                {
                                    headerName: 'Office Symbol',
                                    field: 'office_symbol',
                                    type: 'shortname',
                                    filter: true,
                                },
                                { headerName: 'Project Name', field: 'project_name', type: 'name', filter: true },
                                {
                                    headerName: 'Project No.',
                                    field: 'project_num',
                                    cellRenderer: 'LinkRenderer',
                                    cellRendererParams: {
                                        routeBase: '/projects/',
                                        routeKeyField: 'project_id',
                                    },
                                    type: 'idNum',
                                    filter: true,
                                },
                                {
                                    headerName: 'Activity/Impact',
                                    field: 'impact',
                                    type: 'longtext',
                                    filter: true,
                                },
                                { headerName: 'Date Entered', field: 'created_on', type: 'date' },
                            ]}
                            data={this.state.data}
                            sortable={true}
                            onTableReady={this.onTableReady}
                            tableClassName="Activity-report"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default QuarterlyAvtivityReport;
