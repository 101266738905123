import React from 'react';
import { withRouter } from 'react-router';
import SubNav, { SubNavLink } from '../components/navigation/SubNav';

const CoreFundingSubNav = () => {
    let url = '/coreFunding';
    return (
        <SubNav>
            <SubNavLink to={url} exact>
                Expenditures & Purchases
            </SubNavLink>
            <SubNavLink to={url + '/travel'}>Travel</SubNavLink>
            <SubNavLink to={url + '/expenditures/approvals/'}>Approvals</SubNavLink>
        </SubNav>
    );
};

export default withRouter(CoreFundingSubNav);
