// React
import React, { Component } from 'react';

// Libraries
import _find from 'lodash/find';

//Components
import ExecDirHomePage from './ExecDirHomePage';
import AFRLHomePage from './AFRLHomePage';
import DefaultPage from './Default';
import errorToast from '../components/messages/ErrorMessage';

// API
import ServicesApi from '../api/services';
import ServDirHomePage from './ServDirHomePage';
import AuthService from '../auth/auth.service';

class HomePage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isUserAdmin: false,
            isUserProjectManager: false,
            isUserFinancial: false,
            isUserExecutiveDirector: false,
            isUserServiceDirector: false,
            isUserAFRL: false,
            needsRedirected: false,
            services: null,
            user: null,
        };
    }

    async componentDidMount() {
        let user = AuthService.getUser();

        let isUserAdmin = false;
        let isUserProjectManager = false;
        let isUserFinancial = false;
        let isUserExecutiveDirector = false;
        let isUserServiceDirector = false;
        let isUserAFRL = false;

        if (user != null) {
            isUserAdmin = user.site_role === 'Admin';
            isUserProjectManager = user.site_role === 'Project Manager';
            isUserFinancial = user.site_role === 'Financial';
            isUserExecutiveDirector = user.site_role === 'Executive Director';
            isUserServiceDirector = user.site_role === 'Service Director';
            isUserAFRL = user.site_role === 'PIA Program Manager';
        }

        const { data: services } = await ServicesApi.getServices();

        this.setState({
            isUserAdmin,
            isUserProjectManager,
            isUserFinancial,
            isUserExecutiveDirector,
            isUserServiceDirector,
            isUserAFRL,
            services,
            user,
        });
    }

    render() {
        const {
            isUserAdmin,
            isUserProjectManager,
            isUserAFRL,
            isUserExecutiveDirector,
            isUserFinancial,
            isUserServiceDirector,
        } = this.state;

        let homepage = '';
        if (!this.state.user) return <div>Loading...</div>;
        switch (true) {
            case isUserAdmin:
                homepage = <DefaultPage title="Home" />;
                break;
            case isUserProjectManager:
                homepage = <DefaultPage title="Home" />;
                break;
            case isUserFinancial:
                homepage = <DefaultPage title="Home" />;
                break;
            case isUserExecutiveDirector:
                homepage = <ExecDirHomePage />;
                break;
            case isUserAFRL:
                homepage = <AFRLHomePage />;
                break;
            case isUserServiceDirector:
                let correspondingService = _find(this.state.services, {
                    director_id: this.state.user.id,
                });
                if (correspondingService) {
                    homepage = <ServDirHomePage service={correspondingService} />;
                } else {
                    errorToast('Service Director is not assigned to a service!');
                    homepage = <div />;
                }
                break;
            default:
                errorToast('Undefined site_role detected, or no user object');
                homepage = <div />;
                break;
        }

        return <div>{homepage}</div>;
    }
}

export default HomePage;
