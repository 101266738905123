// React
import React, { Component } from 'react';
//Library
import { Formik, Form, FieldArray as BasicFieldArray } from 'formik';
import LabeledField from '../../components/forms/LabeledField';
import yup from '../../yup';

//Api
import GrantsApi from '../../api/grants';
import ProjectsApi from '../../api/projects';
import TransactionApi from '../../api/transaction';

//Shared Component
import { history } from '../../store/store';
import Message from '../../components/messages/Message';
import ErgoMoney from '../../utils/ErgoMoney';
import errorToast from '../../components/messages/ErrorMessage';

const getInitiaProjectValues = () => ({
    amount: '',
    purpose: '',
    project_id: '',
});

class NewCommercialForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getProjectTotal = this.getProjectTotal.bind(this);

        this.state = {
            grants: [],
            projects: null,
        };
    }

    async componentDidMount() {
        try {
            const { data: projects } = await ProjectsApi.fetchProjects();

            this.setState({
                projects,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    getProjectTotal(values) {
        let projectAmount = values.projects;
        let total = 0;

        if (projectAmount && projectAmount.length === 1) {
            total = projectAmount[0].amount;
        } else {
            projectAmount.forEach((project) => {
                total = Number(total) + Number(project.amount);
            });
        }
        return total;
    }

    handleSubmit = (values, actions) => {
        let isTotalMatch = this.getProjectTotal(values);
        let ga_date = values.received_date;
        if (isTotalMatch) {
            let attributes = {
                company_name: values.company_name,
                contract_number: values.contract_number,
                link_to_db: values.link_to_db,
                total_amount: values.total_amount,
                projects: values.projects,
            };

            if (values.received_date) attributes.received_date = values.received_date;

            if (values.end_date) attributes.end_date = values.end_date;

            if (values.funds_received) attributes.funds_received = values.funds_received;

            GrantsApi.createGrant(attributes)
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data;
                        let attributes;
                        data.projects.forEach((e) => {
                            attributes = {
                                amount: e.amount,
                                description: data.company_name,
                                destination_id: e.project_id,
                                reason: e.purpose,
                                source_id: data.id,
                                destination_type: 'Project',
                                source_type: 'Grant',
                                ga_date,
                            };
                            TransactionApi.createTransaction(attributes)
                                .then((response) => {
                                    if (response.status !== 200) return;
                                })
                                .catch((errorMessage) => {
                                    this.setState({ errorMessage });
                                });
                        });
                        let destination = '/finance/grant';
                        history.push(destination);
                        this.setState({ total: null });
                    }
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
            actions.resetForm();
        }
    };

    render() {
        let projectOptions = [];
        let projectTotals;

        if (this.state.projects) {
            this.state.projects.forEach((project) => {
                projectOptions.push({
                    label: project.number,
                    value: project.id,
                    name: project.name,
                });
            });
        }

        if (this.state.total) projectTotals = new ErgoMoney(this.state.total);
        else projectTotals = new ErgoMoney(0);

        return (
            <div className="Centered-form">
                <Formik
                    initialValues={{
                        company_name: '',
                        contract_number: '',
                        received_date: '',
                        end_date: '',
                        funds_received: '',
                        total_amount: '',
                        link_to_db: '',
                        projects: [getInitiaProjectValues()],
                    }}
                    validationSchema={yup.object().shape({
                        company_name: yup.string().required(),
                        contract_number: yup.string(),
                        received_date: yup.date().required(),
                        end_date: yup.date(),
                        funds_received: yup.date(),
                        total_amount: yup.number().required(),
                        link_to_db: yup.string(),
                        projects: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    amount: yup.string().required(),
                                    project_id: yup.string().required(),
                                })
                            )
                            .min(1),
                    })}
                    onSubmit={this.handleSubmit}
                >
                    {({ values, errors, touched, resetForm }) => (
                        <Form>
                            <LabeledField
                                label="Company Name*"
                                name="company_name"
                                type="text"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Contract No."
                                name="contract_number"
                                type="text"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Grant Received Date*"
                                name="received_date"
                                type="date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Contract End Date"
                                name="end_date"
                                type="date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Funds Received"
                                name="funds_received"
                                type="date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Link to DB"
                                name="link_to_db"
                                type="text"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Total Grant Amount* "
                                name="total_amount"
                                component="Money"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Sum of Project Amounts"
                                name="total"
                                type="text"
                                value={projectTotals}
                                readOnly
                            />

                            <div className="Divider" />
                            <div className="Divider" />
                            <div className="Divider" />

                            <BasicFieldArray
                                className="acrn_block"
                                name="projects"
                                render={(arrayHelpers) => (
                                    <>
                                        {values.projects.map((project, index) => (
                                            <React.Fragment key={index}>
                                                <LabeledField
                                                    label="Amount*"
                                                    name={`projects.${index}.amount`}
                                                    component="Money"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Purpose"
                                                    name={`projects.${index}.purpose`}
                                                    type="text"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="WBI Project No.*"
                                                    name={`projects.${index}.project_id`}
                                                    component="select"
                                                    options={projectOptions}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </React.Fragment>
                                        ))}
                                        <div className="Divider" />
                                        <div className="Divider" />
                                        <div className="Form__buttons--Reversed">
                                            <button
                                                type="button"
                                                className="add_button"
                                                onClick={() => arrayHelpers.push(getInitiaProjectValues())}
                                            >
                                                Add Project +
                                            </button>
                                        </div>
                                    </>
                                )}
                            />
                            <LabeledField
                                label="Sum of Project Amounts"
                                name="total"
                                type="text"
                                value={(projectTotals = new ErgoMoney(this.getProjectTotal(values)))}
                                readOnly
                            />
                            {new ErgoMoney(values.total_amount).getStringAmount() !==
                            projectTotals.getStringAmount() ? (
                                <Message value="*The Total Commercial Contract Amount must equal the Sum of Project Amounts" />
                            ) : null}

                            <div className="Form__buttons--Reversed column-span-3 flex justify-content-end">
                                <button type="button" onClick={() => resetForm()}>
                                    Cancel
                                </button>
                                <button
                                    disabled={
                                        new ErgoMoney(values.total_amount).getStringAmount() !==
                                        projectTotals.getStringAmount()
                                    }
                                    type="submit"
                                >
                                    Save
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default NewCommercialForm;
