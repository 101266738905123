import React, { useState, useEffect } from 'react';
import Modal from '../../components/modals/Modal';

const CheckAgreementStatusModal = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const { closeModal, confirmClosing, confirmOpening, agreement, type } = props;

    useEffect(() => {
        setOpenModal(props.openModal);
    }, [props.openModal]);

    return (
        <Modal isOpen={openModal} style={{ minWidth: '30vw' }}>
            <div className="flex Align-right">
                <button onClick={closeModal}>
                    <i className="fa fa-window-close" />
                </button>
            </div>
            <div>
                {type === 'close' && <p>Are you sure about closing {agreement.number} agreement?</p>}
                {type === 'reopen' && <p>Are you sure about opening {agreement.number} agreement?</p>}
            </div>
            <div className="mt2 flex justify-content-between">
                {type === 'close' && (
                    <button onClick={confirmClosing} className="mr1">
                        YES
                    </button>
                )}
                {type === 'reopen' && (
                    <button onClick={confirmOpening} className="mr1">
                        YES
                    </button>
                )}
                <button onClick={closeModal}>NO</button>
            </div>
        </Modal>
    );
};

export default CheckAgreementStatusModal;
