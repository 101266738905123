import React from 'react';
import yup from '../../../yup';
import FieldArray from '../../../components/forms/FieldArray';
import FieldWithError from '../../../components/forms/FieldWithError';
import DraggingTable from '../../../components/tables/DraggingTable';
import EditingTable from '../../../components/tables/EditingTable';
import ProjectsApi from '../../../api/projects';

class KeyGoalsTable extends React.Component {
    constructor(props) {
        super(props);

        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.addRow = this.addRow.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);
        this.onRowDragEnd = this.onRowDragEnd.bind(this);
        this.onRowDragEnter = this.onRowDragEnter.bind(this);

        this.state = {
            row: null,
            rowIndex: null,
            colKey: '',
            errorMessage: '',
            forceSetData: false,
        };
    }

    handleKeyGoals(keyGoals) {
        this.props.handleKeyGoals(keyGoals);
    }

    onRemoveSelected(evt) {
        const { data } = evt;
        ProjectsApi.removeGoal(data.project_id, data.id).then(() => {
            const keyGoals = this.props.key_goals.filter((k) => k.id !== data.id);
            this.handleKeyGoals(keyGoals);
        });
    }

    onRowValueChanged(evt) {
        const { data } = evt;
        const { key_goals } = this.props;
        let idx = key_goals.findIndex((i) => i.id === evt.node.data.id);
        key_goals[idx].attainment = data.attainment;
        key_goals[idx].reached = data.attainment === '100';
        key_goals[idx].order = data.order;
        key_goals[idx].description = data.description;
        ProjectsApi.updateProjectDataPageTables(data.project_id, 'Goals', key_goals)
            .then((response) => {
                if (response) {
                    this.setState(
                        {
                            row: null,
                            rowIndex: null,
                            colKey: '',
                            forceSetData: true,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    getRowData(evt) {
        this.setState({
            row: evt.node.data,
            rowIndex: evt.node.rowIndex,
            colKey: 'description',
        });
    }

    onRowDragEnter = (event) => {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
        });
    };

    onRowDragEnd = (event) => {
        let newIndex = event.node.rowIndex;
        let rowIndex = this.state.rowIndex;
        let movingItem = event.node.data;
        movingItem.order = newIndex;
        let secondPartArray = this.props.key_goals.filter((_, i) => rowIndex !== i);
        let firstPartArray = secondPartArray.splice(0, newIndex);
        firstPartArray.push(movingItem);

        let newArray = firstPartArray.concat(secondPartArray);
        for (let i = 0; i < newArray.length; i++) {
            newArray[i].order = i;
        }
        ProjectsApi.updateProjectDataPageTables(event.node.data.project_id, 'Goals', newArray)
            .then(() => {
                console.log('new Array ', newArray);
                this.props.handleKeyGoals(newArray);
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    };

    addRow(valueToUpdate) {
        const { projectId } = this.props;
        if (!valueToUpdate.attainment) {
            valueToUpdate.attainment = 0;
        }

        if (projectId !== 'new') {
            const attributes = {
                description: valueToUpdate.description,
                attainment: valueToUpdate.attainment,
                reached: valueToUpdate.attainment === '100',
                order: this.props.key_goals.length,
            };
            ProjectsApi.createGoal(projectId, attributes)
                .then((response) => {
                    const keyGoals = this.props.key_goals.map((k) => k);
                    const { data } = response;
                    const newKeyGoal = {
                        attainment: data.attainment,
                        description: data.description,
                        id: data.id,
                        project_id: data.project_id,
                        reached: data.reached,
                        order: data.order,
                    };
                    keyGoals.push(newKeyGoal);
                    this.handleKeyGoals(keyGoals);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        const { values, errors, touched, isEditing, projectId } = this.props;
        let key_goals = this.props.key_goals;
        if (key_goals) {
            key_goals.sort((a, b) => a.order - b.order);
        }

        return (
            <FieldArray
                label="Key Goals and Objectives"
                name="key_goals"
                initialValues={{
                    description: '',
                    attainment: '',
                    reached: '',
                    project_id: projectId,
                }}
                onRemoveSelected={this.onRemoveSelected}
                addRow={this.addRow}
                validationSchema={yup.object().shape({
                    description: yup.string().required(),
                    attainment: yup.number().integer().min(0).max(100),
                })}
                values={values}
                errors={errors}
                touched={touched}
                readOnly={!isEditing}
                renderArray={() => (
                    <EditingTable
                        // eslint-disable-next-line camelcase
                        data={key_goals}
                        columns={[
                            {
                                headerName: 'Goals',
                                field: 'description',
                                editable: true,
                                rowDrag: true,
                                type: 'mediumtext',
                            },
                            {
                                headerName: 'Goal Attainment %',
                                field: 'attainment',
                                editable: true,
                            },
                            {
                                headerName: 'Goal Reached?',
                                field: 'reached',
                                cellRenderer: 'BooleanRenderer',
                                editable: false,
                                type: 'status',
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Edit',
                                field: 'edit',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'EditSymbolRenderer',
                                onCellClicked: this.getRowData,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Del.',
                                field: 'delete',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'TrashSymbolRenderer',
                                onCellClicked: this.onRemoveSelected,
                            },
                        ]}
                        edit={isEditing}
                        drag={isEditing}
                        rowDragEnd={this.onRowDragEnd}
                        rowDragEnter={this.onRowDragEnter}
                        addedNewData={this.state.forceSetData}
                        rowValueChanged={this.onRowValueChanged}
                        sizeToFit={!isEditing}
                    />
                )}
                renderFields={({ errors, touched }) => (
                    <>
                        <FieldWithError
                            className="Text-area-space"
                            name="description"
                            placeholder="Add item..."
                            component="textarea"
                            errors={errors}
                            touched={touched}
                        />
                        <div className="Bottom-part">
                            <label>
                                Goal Attainment
                                <div>
                                    <FieldWithError
                                        className="Goal-att-input"
                                        name="attainment"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <span className="Percent-box"> %</span>
                                </div>
                            </label>
                        </div>
                    </>
                )}
            />
        );
    }
}

export default KeyGoalsTable;
