import React, { useState, useEffect } from 'react';
import GridSection from '../components/section/GridSection';
import { TravelExpenseForm } from './travelExpense/TravelExpenseForm';
import TravelHistoryTable from './travelExpense/TravelHistory';
import TravelExpenditureFormManager from './travelExpense/TravelExpenditureFormManager';
import { TravelExpenseTable } from './travelExpense/TravelExpenseTable';
import { confirm } from '../components/modals/ConfirmModal';
import { SummaryCard } from './travelExpense/SummaryCard';
import { recalculatePerDays, tableDataPrep } from './travelExpense/HelperFunctions';
import { UpdatePerDiemModal } from './travelExpense/UpdatePerDiemModal';
import errorToast from '../components/messages/ErrorMessage';

export const TravelExpensePage = (props) => {
    const createDefaultTableData = () => ({
        columns: [],
        rowData: {},
        summaryCardValues: {
            total_expenses: 0,
            total_non_reimbursables: 0,
            company_charges: 0,
            amount_to_employee: 0,
        },
    });

    const { isProject, isArchived } = props;
    const [projectId] = useState(props.match.params.id);
    const [accountId, setAccountId] = useState(projectId);
    const [coreFundId, setCoreFundId] = useState(null);
    const [cardHolderId, setCardHolderId] = useState(null);
    const [rowToUpdate, setRowToUpdate] = useState(null);
    const [travelExpenseId, setTravelExpenseId] = useState(null);
    const [tableExpenditures, setTableExpenditures] = useState([]);
    const [travelExpendituresToUpdate, setTravelExpendituresToUpdate] = useState([]);
    const [historyTableRefreshFlag, setHistoryTableRefreshFlag] = useState(false);
    const [perDays, setPerDays] = useState([]);
    const [tableData, setTableData] = useState(createDefaultTableData());
    const [showPerDiemModal, setShowPerDiemModal] = useState(false);
    const [manualPerDiem, setManualPerDiem] = useState([]);

    const hoistTravelExpenseForPerDayCalc = (travelExpense) => {
        try {
            const recalulatedPerDiem = recalculatePerDays(travelExpense);
            setPerDays(recalulatedPerDiem);
        } catch (error) {
            errorToast(error);
        }
    };

    const refreshHistoryTable = () => {
        setHistoryTableRefreshFlag(!historyTableRefreshFlag);
    };

    const hoistCardHolderId = (id) => {
        setCardHolderId(id);
    };

    const hoistTravelExpenseId = (id) => {
        setTravelExpenseId(id);
    };

    const hoistTravelExpendituresToUpdate = (travelExpenditures, isPerDiemRowFlag) => {
        try {
            if (isPerDiemRowFlag) {
                setTravelExpendituresToUpdate([]);
                setShowPerDiemModal(true);
                return;
            }
            setTravelExpendituresToUpdate(travelExpenditures);
        } catch (error) {
            errorToast(error);
        }
    };

    const confirmResubmitForApprovalIfApproved = async (travelExpense) => {
        let confirmed = true;
        if (travelExpense.status === 'Approved') {
            confirmed = await confirm({
                text: 'Editing an approved expense will resubmit the expense for approval.',
            });
        }
        return confirmed;
    };

    const hoistTravelExpenseToUpdate = async (event) => {
        if (event.data) {
            const travelExpense = event.data;

            if (travelExpense.status === 'Applied') {
                await confirm({
                    text: 'This expense has already been applied and can no longer be edited by a project user, please contact finance.',
                    acceptText: 'Ok',
                    rejectText: null,
                });
                return;
            }

            const confirmed = await confirmResubmitForApprovalIfApproved(travelExpense);

            if (confirmed) {
                setManualPerDiem(travelExpense.manual_per_diem);
                setRowToUpdate(travelExpense);
                setTableExpenditures(travelExpense.travel_expenditures);
                setCardHolderId(travelExpense.cc_holder_id);
                setPerDays(recalculatePerDays(travelExpense, travelExpense.manual_per_diem));
            }
        } else {
            setRowToUpdate(null);
            setTableExpenditures([]);
            setCardHolderId(null);
        }
    };

    const updatePerDiemsManual = (values) => {
        setManualPerDiem(values);
        setPerDays(recalculatePerDays(rowToUpdate, values));
        confirm({
            text: 'For the new Per Diems to save, please either Submit or Save Draft the expense. Changing rates or dates reverts the Per Diems back to their newly calculated values.',
            acceptText: 'Ok',
            rejectText: null,
        });
    };

    const clearAllData = () => {
        setTableData({
            columns: [],
            rowData: {},
            summaryCardValues: {
                total_expenses: 0,
                total_non_reimbursables: 0,
                company_charges: 0,
                amount_to_employee: 0,
            },
        });
        setManualPerDiem([]);
        setRowToUpdate(null);
        setTravelExpenseId(null);
        setTableExpenditures([]);
        setTravelExpendituresToUpdate([]);
        setPerDays([]);
        setManualPerDiem([]);
    };

    useEffect(() => {
        if (coreFundId) {
            setAccountId(coreFundId);
        } else if (rowToUpdate) {
            setAccountId(rowToUpdate.account_id);
        } else {
            setAccountId(projectId);
        }
    }, [coreFundId, rowToUpdate, projectId]);

    useEffect(() => {
        const tempTableData = tableDataPrep(perDays, tableExpenditures);
        setTableData(tempTableData);
    }, [perDays, tableExpenditures, rowToUpdate]);

    return (
        <div className="Travel-expense-page">
            <GridSection area="card" title="Travel Expense Summary">
                <SummaryCard summaryCardValues={tableData.summaryCardValues} />
            </GridSection>
            <GridSection title="Expense Form Data" area="expense">
                <TravelExpenseForm
                    isProject={isProject}
                    projectId={projectId}
                    expenseId={travelExpenseId}
                    isArchived={isArchived}
                    rowToUpdate={rowToUpdate}
                    setCardHolderId={hoistCardHolderId}
                    setAccountId={setCoreFundId}
                    setRowToUpdate={hoistTravelExpenseToUpdate}
                    setTravelExpenseId={hoistTravelExpenseId}
                    refreshHistoryTable={refreshHistoryTable}
                    hoistTravelExpenseForPerDayCalc={hoistTravelExpenseForPerDayCalc}
                    manualPerDiem={manualPerDiem}
                    clearAllData={clearAllData}
                />
            </GridSection>
            <GridSection title="Current Expenditure" area="expenditure">
                <TravelExpenditureFormManager
                    travelExpendituresToUpdate={travelExpendituresToUpdate}
                    tableExpenditures={tableExpenditures}
                    expenseId={travelExpenseId}
                    rowToUpdate={rowToUpdate}
                    cardHolderId={cardHolderId}
                    accountId={accountId}
                    isProject={isProject}
                    perDays={perDays}
                    setPerDays={hoistTravelExpenseForPerDayCalc}
                    setTravelExpenseId={hoistTravelExpenseId}
                    setExpenditures={hoistTravelExpendituresToUpdate}
                    setTableExpenditures={setTableExpenditures}
                    readOnly={false}
                />
            </GridSection>
            <GridSection title="Expenses" area="breakdown">
                <TravelExpenseTable
                    tableData={tableData}
                    columns={tableData.columns}
                    rowData={tableData.rowData}
                    hoistTravelExpenditures={hoistTravelExpendituresToUpdate}
                />
            </GridSection>
            <GridSection title="Expense History" area="history">
                <TravelHistoryTable
                    isProject={isProject}
                    projectId={projectId}
                    tableRefresh={historyTableRefreshFlag}
                    setRowToUpdate={hoistTravelExpenseToUpdate}
                />
            </GridSection>
            <UpdatePerDiemModal
                perDays={perDays}
                showModal={showPerDiemModal}
                closeModal={setShowPerDiemModal}
                updatePerDiems={updatePerDiemsManual}
            />
        </div>
    );
};

export default TravelExpensePage;
