import React from 'react';
import Table from '../../../components/tables/Table';
import errorToast from '../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../components/buttons/ExportCSVButton';
import ProjectsApi from '../../../api/projects';

class PiaModSummary extends React.Component {
    constructor(props) {
        super(props);

        this.onTableReady = this.onTableReady.bind(this);

        this.state = {
            piaMods: null,
            selectedService: this.props.selectedService,
        };
    }

    async componentDidMount() {
        try {
            const { data: piaMods } = await ProjectsApi.fetchAllProjectsPiaMods();
            this.setState({ piaMods });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ selectedService: nextProps.selectedService });
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    render() {
        const { piaMods, selectedService } = this.state;

        let filteredMods = [];
        if (piaMods && selectedService) {
            if (selectedService.length === 1) {
                filteredMods = piaMods.filter((mod) => mod.service_id === selectedService[0].id);
            } else {
                filteredMods = piaMods;
            }
        }

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="pia-mod-summary" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div>
                    <Table
                        columns={[
                            { headerName: 'PIA', field: 'pia.name' },
                            { headerName: 'CPO', field: 'cpo.number' },
                            { headerName: 'Mod No.', field: 'mod_number' },
                            { headerName: 'ACRN', field: 'acrn' },
                            { headerName: 'Mod Rec. Date', field: 'received_date', type: 'date' },
                            {
                                headerName: 'Funds Received Date',
                                field: 'funds_received_date',
                                type: 'date',
                            },
                            { headerName: 'Amount', field: 'amount', type: 'money' },
                            { headerName: 'PR Number', field: 'pr_num' },
                            {
                                headerName: 'Project No.',
                                field: 'project_num',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'project_id',
                                },
                                type: 'idNum',
                            },
                            { headerName: 'WBI Lead', field: 'wbi_lead.name', type: 'name' },
                        ]}
                        data={filteredMods}
                        onTableReady={this.onTableReady}
                        filter={true}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}

export default PiaModSummary;
