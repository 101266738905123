import React from 'react';

const ViewPageExternalLink = ({ text, link, disabled }) => {
    if (link) {
        let externalLink;
        if (link.includes('http://') || link.includes('https://')) {
            externalLink = link;
        } else {
            externalLink = 'http://' + link;
        }
        return (
            <li>
                {disabled ? (
                    text
                ) : (
                    <a href={externalLink} target="_blank" rel="noopener noreferrer" aria-label="Opens in new tab">
                        {text}
                    </a>
                )}
            </li>
        );
    }
    return null;
};

export default ViewPageExternalLink;
