import React from 'react';
import { Formik, Form } from 'formik';
import yup from '../../yup';
import FieldWithError from '../../components/forms/FieldWithError';
import DraggingTable from '../../components/tables/DraggingTable';
import ProjectsApi from '../../api/projects';

class IssuesTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            issues: [],
            row: null,
            rowIndex: null,
            colKey: '',
            incommingNewData: false,
        };

        this.handleNewIssue = this.handleNewIssue.bind(this);
        this.clickEditHandler = this.clickEditHandler.bind(this);
        this.clickCloseHandler = this.clickCloseHandler.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);
        this.onRowDragEnd = this.onRowDragEnd.bind(this);
        this.onRowDragEnter = this.onRowDragEnter.bind(this);
    }

    componentDidMount() {
        const { project } = this.props;
        this.setState({ issues: project.issues });
    }

    handleNewIssue(values, actions) {
        const { project } = this.props;
        const { issues } = this.state;
        const attributes = {
            description: values.description,
            order: issues.length,
        };

        ProjectsApi.createIssue(project.id, attributes).then((response) => {
            issues.push(response.data);

            this.setState(
                {
                    issues: issues.sort((a, b) => a.order - b.order),
                    incommingNewData: true,
                },
                () => {
                    this.setState({ incommingNewData: false });
                }
            );
            actions.resetForm();
        });
    }

    onRowValueChanged(evt) {
        const { data } = evt;
        const { issues } = this.props.project;
        let idx = issues.findIndex((i) => i.id === data.id);

        issues[idx] = data;
        ProjectsApi.updateProjectDataPageTables(this.props.project.id, 'Issues', issues)
            .then(() => {
                this.setState({
                    row: null,
                    rowIndex: null,
                    colKey: '',
                });
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    onRowDragEnter = (event) => {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
        });
    };

    onRowDragEnd = (event) => {
        const { issues } = this.state;
        let newIndex = event.node.rowIndex;
        let rowIndex = this.state.rowIndex;
        let movingItem = event.node.data;
        movingItem.order = newIndex;
        let secondPartArray = issues.filter((_, i) => rowIndex !== i);
        let firstPartArray = secondPartArray.splice(0, newIndex);
        firstPartArray.push(movingItem);
        let newArray = firstPartArray.concat(secondPartArray);

        for (let i = 0; i < newArray.length; i++) {
            newArray[i].order = i;
        }
        ProjectsApi.updateProjectDataPageTables(event.node.data.project_id, 'Issues', newArray)
            .then((response) => {
                this.setState(
                    {
                        issues: response.data.sort((a, b) => a.order - b.order),
                        incommingNewData: true,
                    },
                    () => {
                        this.setState({ incommingNewData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    };

    clickEditHandler(evt) {
        this.setState({
            row: evt.node.data,
            rowIndex: evt.node.rowIndex,
            colKey: 'description',
        });
    }

    clickCloseHandler(evt) {
        const { project } = this.props;
        const { issues } = this.state;
        const issueId = evt.data.id;

        const filteredIssues = issues.filter((i) => i.id !== issueId);

        ProjectsApi.removeIssue(project.id, issueId).then(() => {
            this.setState({ issues: filteredIssues });
        });
    }

    render() {
        if (!this.props.project) return <div>loading...</div>;

        let issues = this.state.issues.sort((a, b) => a.order - b.order);

        return (
            <div className="Issues-table">
                <DraggingTable
                    data={issues}
                    columns={[
                        {
                            headerName: 'Issues',
                            field: 'description',
                            editable: true,
                            type: 'longtext',
                            rowDrag: true,
                        },
                        {
                            headerName: 'Initiated By',
                            field: 'initiator',
                            type: 'name',
                            editable: false,
                        },
                        {
                            headerName: 'Initiated On',
                            field: 'created_on',
                            type: 'date',
                            editable: false,
                        },
                        {
                            headerName: 'Last Update By',
                            field: 'last_update_by',
                            type: 'name',
                            editable: false,
                        },
                        {
                            headerName: 'Updated On',
                            field: 'updated_on',
                            type: 'date',
                            editable: false,
                        },
                        {
                            headerName: 'Edit',
                            type: 'symbol',
                            editable: false,
                            cellRenderer: 'EditSymbolRenderer',
                            onCellClicked: this.clickEditHandler,
                        },
                        {
                            headerName: 'Close',
                            type: 'symbol',
                            editable: false,
                            cellRenderer: 'TrashSymbolRenderer',
                            onCellClicked: this.clickCloseHandler,
                        },
                    ]}
                    edit={true}
                    drag={true}
                    rowDragEnd={this.onRowDragEnd}
                    rowDragEnter={this.onRowDragEnter}
                    rowValueChanged={this.onRowValueChanged}
                    addedNewData={this.state.incommingNewData}
                />
                <Formik
                    initialValues={{ description: '' }}
                    validationSchema={yup.object().shape({
                        description: yup.string().required(),
                    })}
                    onSubmit={this.handleNewIssue}
                >
                    {({ errors, touched }) => (
                        <Form className="flex align-items-end">
                            <FieldWithError
                                name="description"
                                placeholder="Add description..."
                                component="textarea"
                                errors={errors}
                                touched={touched}
                            />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button type="submit">Add</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default IssuesTable;
