import React from 'react';
import NotesSymbolModal from './NotesSymbolModal';

const NotesSymbolRenderer = ({ value }) => {
    if (value) {
        return <NotesSymbolModal>{value}</NotesSymbolModal>;
    }
    return null;
};

export default NotesSymbolRenderer;
