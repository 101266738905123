import api from './api';

import endpoints from './endpoints';

export default {
    getApprovers() {
        return api.get(endpoints.APPROVERS, 'Error getting list of approvers');
    },

    getNonapprovers() {
        return api.get(endpoints.NONAPPROVERS, 'Error getting list of nonapprovers');
    },
};
