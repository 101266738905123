/**
 * This bit of code here is a little strange... You can't use Node.js
 * environment variables in client-side JavaScript. But, this file isn't
 * served to the client directly, it is pre-processed by webpack during
 * the build process. Therefore, we can use this little trick to switch out
 * production and development API hosts. Use this trick with caution.
 *
 * See https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#adding-custom-environment-variables
 * for more information.
 */
let HOST = 'http://localhost:5000';
if (process.env.NODE_ENV === 'production') {
    HOST = process.env.REACT_APP_API_HOST;
}

export default {
    AUTH_CHANGE_PASSWORD: `${HOST}/auth/change_password`,
    AUTH_CONFIRM_SIGN_UP: `${HOST}/auth/confirm_signup`,
    AUTH_FORGOT_PASSWORD: `${HOST}/auth/forgot_password`,
    AUTH_INVITE: `${HOST}/auth/invite`,
    AUTH_REFRESH: `${HOST}/auth/refresh`,
    AUTH_RESEND: `${HOST}/auth/resend`,
    AUTH_RESET: `${HOST}/auth/forgot_password`,
    AUTH_RESET_CONFIRM: `${HOST}/auth/confirm_password`,
    AUTH_SIGN_IN: `${HOST}/auth`,
    AUTH_SIGN_UP: `${HOST}/auth/signup`,
    AUTH_INIT: `${HOST}/auth/init`,
    AUTH_CONFIRM: `${HOST}/auth/complete_auth`,

    AGREEMENTS: `${HOST}/api/agreements`,
    AGREEMENT_INVOICE: `${HOST}/api/agreement_invoices`,
    APPROVERS: `${HOST}/approvers`,
    BANK_STATEMENTS: `${HOST}/bank_statements`,
    CCHOLDERS: `${HOST}/credit-card-holders`,
    COMMERCIAL_CONTRACTS: `${HOST}/commercial_contracts`,
    CORE_BUDGET_TYPES: `${HOST}/core_budget_types`,
    CPO: `${HOST}/cpos`,
    CPO_PARAGRAPHS: `${HOST}/cpo_paragraphs`,
    DROPBOX: `${HOST}/dropbox`,
    EXPENDITURES: `${HOST}/expenditures`,
    EXPENDITURES_ALL: `${HOST}/expenditures_all`,
    EXPENDITURES_NO_APP: `${HOST}/expenditures_no_approver`,
    EXPENDITURES_APPROVER: `${HOST}/expenditures_approver`,
    FINANCIAL_REPORTS: `${HOST}/financial_reports`,
    GRANTS: `${HOST}/grants`,
    MODS: `${HOST}/mods`,
    NONAPPROVERS: `${HOST}/nonapprovers`,
    NONCCHOLDERS: `${HOST}/non-credit-card-holders`,
    NOTIFICATIONS: `${HOST}/notifications`,
    OFFICE_SYMBOL: `${HOST}/office_symbols`,
    RESIDUAL_FUND: `${HOST}/residual_funds`,
    RESIDUAL_FUND_WITH_FUNDS: `${HOST}/residual_fund_with_funds`,
    RECONCILIATION: `${HOST}/reconciliation`,
    PIA: `${HOST}/pias`,
    PROJECTS: `${HOST}/projects`,
    PROJECT_METRICS: `${HOST}/project_metrics`,
    PROJECT_NAMES: `${HOST}/project_names`,
    PROJECT_TYPE: `${HOST}/project_types`,
    SEARCH: `${HOST}/search`,
    SERVICE: `${HOST}/service_types`,
    STRATEGIC_METRIC: `${HOST}/strategic_metrics`,
    TRANSACTIONS: `${HOST}/transactions`,
    TRANSACTIONS_ALL: `${HOST}/transactions_all`,
    TRANSFER_REQUESTS: `${HOST}/transfer_requests`,
    TRAVEL_REQUEST: `${HOST}/travel_requests`,
    TRAVEL_REQUEST_TYPES: `${HOST}/travel_request_types`,
    TRAVEL_EXPENSE: `${HOST}/travel_expenses`,
    TRAVEL_EXPENDITURES: `${HOST}/travel_expenses/travel_expenditures`,
    USERS: `${HOST}/users`,
    VENDORS: `${HOST}/api/vendors`,
    GARATE: `${HOST}/garate`,
};
