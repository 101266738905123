import React from 'react';
import LabeledField from '../components/forms/LabeledField';

export default function AddGeneralDocuments({ errors, touched, index, values }) {
    return (
        <div>
            <LabeledField
                label="Document Name"
                name={`documents.${index}.name`}
                component="input"
                value={values[index]['name']}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
            <LabeledField
                label="Document Link"
                name={`documents.${index}.doc_link`}
                component="input"
                value={values[index]['doc_link']}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
        </div>
    );
}
