import React, { Component } from 'react';
import Moment from 'moment';
import PagingTable from '../tables/PagingTable';
import errorToast from '../messages/ErrorMessage';
import NotifApi from '../../api/notifications';

const isNewOrUnviewedNotification = (params) => {
    if (params.data.viewed) {
        return { backgroundColor: '#ADADAD', height: '24px' };
    }

    let todaysDate = new Moment().startOf('day');
    let createdOn = Moment(params.data.created_on).startOf('day');
    if (createdOn.isSame(todaysDate)) return { backgroundColor: '#EDEDED', height: '24px' };
    return null;
};

class OpenNotifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            execDir: false,
            errorMessage: null,
            columns: [
                {
                    headerName: 'Open Notifications',
                    field: 'title',
                    cellClass: 'Cell-wrap-text',
                    type: 'longtext',
                    cellRenderer: 'NotificationsLinkRenderer',
                    cellRendererParams: {
                        routeBase: 'route',
                        routeKeyField: 'target_id',
                    },
                    cellStyle(params) {
                        return isNewOrUnviewedNotification(params);
                    },
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: notifications } = await NotifApi.getNotifications();
            let user = JSON.parse(localStorage.getItem('WBI.USER'));

            if (notifications) {
                notifications.forEach((notification) => {
                    notification.mark = NotifApi.markAsViewed;
                    // This is for deletions made by finance users
                    if (notification.target && notification.delete_object) {
                        notification.target.oldTarget = notification.delete_object;
                    } else if (!notification.target && notification.delete_object) {
                        notification.target = notification.delete_object;
                    }

                    if (
                        notification.title.includes('transfer request') ||
                        notification.title.includes('Funds Transfer')
                    ) {
                        if (user !== null && user.site_role === 'Financial') {
                            notification.route = '/finance/residualFunds/fundsTransfers';
                        } else {
                            notification.route = '/projects/' + notification.target.id + '/transfer-funds';
                        }
                    }

                    if (!notification.hasOwnProperty('target')) {
                        return;
                    }

                    if (notification.target.account_type === 'Project') {
                        notification.route = '/projects/' + notification.target.account_id + '/';
                    } else if (notification.target.account_type === 'CoreBudgetType') {
                        notification.route = '/coreFunding/';
                    } else if (notification.target.project_id !== null) {
                        notification.route = '/projects/' + notification.target.project_id + '/';
                    } else {
                        return;
                    }

                    switch (notification.target_type) {
                        case 'Expenditure':
                            if (notification.target.status === 'Pending') {
                                notification.route += 'expenditures/approvals/';
                            } else if (
                                notification.target.status === 'Submitted' &&
                                user !== null &&
                                user.site_role === 'Financial'
                            ) {
                                notification.route = '/finance/creditCard/';
                            } else if (notification.target.account_type === 'CoreBudgetType') {
                                break;
                            } else {
                                notification.route += 'expenditures/';
                            }
                            break;
                        case 'AgreementInvoice':
                            if (
                                notification.target &&
                                notification.target.status === 'Submitted' &&
                                user !== null &&
                                user.site_role === 'Financial'
                            ) {
                                notification.route = '/finance/invoices/';
                            } else {
                                notification.route += 'expenditures/invoices/';
                            }
                            break;
                        case 'TravelRequest':
                            if (notification.target.status === 'Pending' || notification.target.status === 'Review') {
                                notification.route += 'travel/approvals/';
                            } else {
                                notification.route += 'travel/';
                            }
                            break;
                        case 'TravelExpense':
                            if (notification.title.includes('review')) {
                                notification.route += 'travel/approvals/';
                            } else {
                                notification.route += 'travel/expense/';
                            }
                            if (user.site_role === 'Financial' && user !== null) {
                                notification.route = '/finance/travel/';
                            }
                            break;
                        case 'Project':
                            notification.route = '/projects/' + notification.target.id;
                            break;
                        default:
                            break;
                    }
                });
            }

            this.setState({ notifications });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRemoveSelected = (event) => {
        let notifyToDelete = event.data;

        NotifApi.deleteNotifications(notifyToDelete.id)
            .then((response) => {
                let notifications = this.state.notifications;
                let idx = notifications.indexOf(notifyToDelete);
                notifications.splice(idx, 1);
                this.setState(
                    {
                        notifications,
                        forceSetData: true,
                    },
                    () => {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    render() {
        if (!this.state.notifications) return <div>loading...</div>;
        const { notifications, forceSetData, columns } = this.state;

        return (
            <div>
                <PagingTable
                    columns={columns}
                    data={notifications}
                    sizeToFit={true}
                    pageSizeProp={25}
                    filter={true}
                    sortable={true}
                    tableClassName="Notify-table"
                    addedNewData={forceSetData}
                />
            </div>
        );
    }
}

export default OpenNotifications;
