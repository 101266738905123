import React from 'react';
import Table from '../../../../components/tables/Table';
import errorToast from '../../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../../components/buttons/ExportCSVButton';
import ProjectsApi from '../../../../api/projects';

class ServiceProjectFinancialSummary extends React.Component {
    constructor(props) {
        super(props);

        this.onTableReady = this.onTableReady.bind(this);
        this.sumColumnInfo = this.sumColumnInfo.bind(this);
        this.state = {
            activeProjects: null,
            selectedService: this.props.selectedService,
            columns: [
                {
                    headerName: 'WBI Project Name',
                    field: 'name',
                    type: 'mediumtext',
                },
                {
                    headerName: 'Project No.',
                    field: 'number',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'id',
                    },
                    type: 'idNum',
                },
                {
                    headerName: 'WBI Project Lead',
                    field: 'wbi_lead',
                    type: 'shortname',
                },
                {
                    headerName: 'Customer',
                    field: 'customer_name',
                    type: 'name',
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    type: 'number',
                },
                {
                    headerName: 'Start Date',
                    field: 'start_date',
                    type: 'date',
                },
                {
                    headerName: 'Estimated End Date',
                    field: 'estimated_end_date',
                    type: 'date',
                },
                {
                    headerName: 'PIA',
                    field: 'pia',
                    type: 'mediumnumber',
                },
                {
                    headerName: 'CPO',
                    field: 'cpo',
                    type: 'mediumnumber',
                    cellStyle: ({ node: { lastChild } }) =>
                        lastChild ? { fontWeight: 'bold', textAlign: 'right' } : null,
                },
                {
                    headerName: 'Total Received',
                    field: 'financial_info.total_received',
                    type: 'money',
                    cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                },
                {
                    headerName: 'Total Available to Project',
                    field: 'financial_info.total_available',
                    type: 'money',
                    cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                },
                {
                    headerName: 'Total Budget',
                    field: 'financial_info.project_budget',
                    type: 'money',
                    cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                },
                {
                    headerName: 'Total Expended',
                    field: 'financial_info.total_expended',
                    type: 'money',
                    cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                },
                {
                    headerName: 'Remaining Budget',
                    field: 'financial_info.remaining_budget',
                    type: 'money',
                    cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                },
                {
                    headerName: 'Unbudgeted',
                    field: 'financial_info.unbudgeted',
                    type: 'money',
                    cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                },
                {
                    headerName: 'Remaining Funds',
                    field: 'financial_info.remaining_funds',
                    type: 'money',
                    cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                },
            ],
        };
    }

    async componentDidMount() {
        const { data: activeProjects } = await ProjectsApi.fetchAllFinancialActiveProjects();
        try {
            this.setState({ activeProjects });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    async(nextProps) {
        try {
            this.setState({ selectedService: nextProps.selectedService });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    sumColumnInfo(filteredActiveProjects) {
        let totalRow = {
            name: '',
            number: '',
            wbi_lead: '',
            customer_name: '',
            pia: '',
            cpo: 'Total',
            start_date: '',
            estimated_end_date: '',
            financial_info: {
                total_received: 0,
                remaining_funds: 0,
                remaining_budget: 0,
                total_expended: 0,
                total_expended_gta: 0,
                project_budget: 0,
                unbudgeted: 0,
            },
        };
        filteredActiveProjects.forEach(({ financial_info }) => {
            totalRow.financial_info.total_received += financial_info.total_received;
            totalRow.financial_info.remaining_funds += financial_info.remaining_funds;
            totalRow.financial_info.remaining_budget += financial_info.remaining_budget;
            totalRow.financial_info.total_expended += financial_info.total_expended;
            totalRow.financial_info.total_expended_gta += financial_info.total_expended_gta;
            totalRow.financial_info.project_budget += financial_info.project_budget;
            totalRow.financial_info.unbudgeted += financial_info.unbudgeted;
        });

        return totalRow;
    }

    render() {
        const { activeProjects, selectedService, columns } = this.state;
        let filteredActiveProjects = [];

        if (activeProjects && selectedService) {
            if (selectedService.length === 1) {
                filteredActiveProjects = activeProjects.filter(
                    (project) => project.service_type_name === selectedService[0].name
                );
            } else {
                filteredActiveProjects = activeProjects;
            }
        }
        const totalRow = this.sumColumnInfo(filteredActiveProjects);
        filteredActiveProjects.push(totalRow);

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton
                            fileName="service-project-financial-summary"
                            exportFunction={this.exportDataAsCsv}
                        />
                    </div>
                </div>
                <div>
                    <Table
                        columns={columns}
                        data={filteredActiveProjects}
                        onTableReady={this.onTableReady}
                        sortable={true}
                        filter={true}
                    />
                </div>
            </div>
        );
    }
}
export default ServiceProjectFinancialSummary;
