import React from 'react';
import ExportCSVButton from '../../components/buttons/ExportCSVButton';

const AgreementInvoicesButtonBar = ({ returnInvoicesToSender, setInvoicesPaid, loadAllInvoices, exportDataAsCsv }) => {
    const handleReturnToSenderButton = () => {
        returnInvoicesToSender();
    };

    const handleSetStatusPaidButton = () => {
        setInvoicesPaid();
    };

    const handleLoadAllInvoicesButton = () => {
        loadAllInvoices();
    };

    return (
        <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
            <button type="button" onClick={() => handleLoadAllInvoicesButton()}>
                Load All Invoices
            </button>
            <button type="button" onClick={() => handleReturnToSenderButton()}>
                Return to Sender
            </button>
            <button type="button" onClick={() => handleSetStatusPaidButton()}>
                Set Status Paid
            </button>
            <ExportCSVButton fileName="Invoices" exportFunction={exportDataAsCsv} />
        </div>
    );
};

export default AgreementInvoicesButtonBar;
