import api from './api';

import endpoints from './endpoints';

export default {
    getCCholders() {
        return api.get(endpoints.CCHOLDERS, 'Error getting list of credit card holders');
    },

    getNonCCholders() {
        return api.get(endpoints.NONCCHOLDERS, 'Error getting list of non credit card holders');
    },
};
