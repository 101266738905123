// React
import React, { Component } from 'react';

// Libraries
import _find from 'lodash/find';
import { history } from '../store/store';

// Components
import CoreFundingSubNav from './CoreFundingSubNav';
import PageHeader from '../components/heading/PageHeader';
import errorToast from '../components/messages/ErrorMessage';
import ExpenseApprovalCards from '../components/cards/ExpenseApprovalCards';

// API
import ExpendituresApi from '../api/expenditures';
import UsersApi from '../api/users';

class ExpendituresPage extends Component {
    constructor(props) {
        super(props);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);

        this.state = {
            approvals: [],
        };
    }

    async componentDidMount() {
        try {
            const { data: expenditureApprovals } = await ExpendituresApi.fetchExpendituresApprover();
            const { data: users } = await UsersApi.getUsers();
            let approvals = expenditureApprovals;
            approvals = approvals.filter((approval) => approval.account_type === 'CoreBudgetType');

            if (approvals) {
                approvals.forEach((approval) => {
                    approval.typeOfApproval = 'Expenditure Approval Request';
                    if (users) {
                        let initiator = _find(users, { id: approval.initiator_id });
                        if (initiator) approval.initiator_name = initiator.name;
                    }
                });
            }

            this.setState({
                approvals,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    approve(id, index) {
        ExpendituresApi.approveExpenditure(id)
            .then(() => {
                let newApprovals = this.state.approvals.filter((_, i) => index !== i);

                this.setState({
                    approvals: newApprovals,
                });

                if (this.props.match.path.includes('coreFunding')) {
                    if (this.state.approvals.length === 0) {
                        history.push('/coreFunding');
                    }
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    reject(id, index) {
        ExpendituresApi.rejectExpenditure(id)
            .then(() => {
                let newApprovals = this.state.approvals.filter((_, i) => index !== i);

                this.setState({
                    approvals: newApprovals,
                });
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    render() {
        return (
            <div>
                <PageHeader title="Core Funding Approvals" />
                <CoreFundingSubNav />
                <>
                    {this.state.approvals ? (
                        <ExpenseApprovalCards
                            approvals={this.state.approvals}
                            approve={this.approve}
                            reject={this.reject}
                        />
                    ) : null}
                </>
            </div>
        );
    }
}

export default ExpendituresPage;
