import api from './api';

import endpoints from './endpoints';

const travelRequestRoute = endpoints.TRAVEL_REQUEST;
const travelRequestTypesRoute = endpoints.TRAVEL_REQUEST_TYPES;

export default {
    fetchTravelRequests() {
        return api.get(travelRequestRoute, 'Error getting travel requests for current user depending on account type');
    },

    // Projects - travel - Request Travel table
    fetchTravelRequestsProject(account_id) {
        return api.get(`${travelRequestRoute}/${account_id}`, 'Error getting travel requests in within project');
    },

    //Core funding - Request Travel table
    fetchTravelRequestsTraveler() {
        return api.get(`${travelRequestRoute}/traveler`, 'Error getting user travel requests in Core Funding');
    },

    // Projects and Core Funding - approval cards
    fetchTravelRequestsApprover(account_type) {
        let route;
        if (account_type) {
            route = `${travelRequestRoute}/approver?account_type=${account_type}`;
        } else {
            route = `${travelRequestRoute}/approver`;
        }
        return api.get(route, 'Error getting travel approval requests for current user');
    },

    createTravelRequests(attributes) {
        return api.post(travelRequestRoute, attributes, 'Error creating a travel request');
    },

    fetchStatuses() {
        return api.get(`${travelRequestRoute}/statuses`, 'Error getting travel request statuses list');
    },

    fetchTravelRequestTypes() {
        return api.get(travelRequestTypesRoute, 'Error getting travel request types');
    },

    updateTravelRequest(travelReqId, attributes) {
        return api.put(`${travelRequestRoute}/${travelReqId}`, attributes, 'Error updating a travel request');
    },

    approveTravelRequest(id) {
        return api.put(`${travelRequestRoute}/${id}/approve`, 'Error approving a travel request');
    },

    rejectTravelRequest(id) {
        return api.put(`${travelRequestRoute}/${id}/reject`, 'Error rejecting a travel request');
    },

    deleteTravelRequest(id) {
        return api.delete(`${travelRequestRoute}/${id}`, 'Error deleting a travel request');
    },
};
