import React, { Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import fileUpload from '../../icons/upload.svg';
import { Field as BasicField } from 'formik';
import UploadThumb from './UploadThumb';

const UploadFile = ({ values, setFieldValue, errors, touched, handleWrongFileType }) => {
    const handleRemoveFile = () => {
        setFieldValue('file', {});
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        multiple: false,
        onDrop: (file) => {
            if (file.length === 0) {
                return;
            }
            const fileObj = file[0];
            if (fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                setFieldValue('file', fileObj);
            } else {
                handleWrongFileType('FileTypeError', 'Only Excel .xlsx files accepted');
            }
        },
    });

    return (
        <section>
            <div {...getRootProps({ className: 'DropZoneFiles' })}>
                <input {...getInputProps()} />
                <div className="fileUpload">
                    <img src={fileUpload} alt="upload file" />
                </div>
                <p>Drag and drop file here</p>
                <button type="button" onClick={open}>
                    Browse Files
                </button>
                <BasicField
                    name="reconcilation-file"
                    render={() => (
                        <Fragment key="Reconcile-file">
                            {Object.keys(values.file).length !== 0 && (
                                <div className="UploadThumb">
                                    <UploadThumb errors={errors} touched={touched} values={values.file} />
                                    <div className="Right">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                handleRemoveFile(values);
                                            }}
                                        >
                                            <i className="fa fa-trash-o" />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                />
            </div>
        </section>
    );
};

export default UploadFile;
