import React, { useState, useEffect } from 'react';
import FinanceSubNav from './FinanceSubNav';
import PageHeader from '../components/heading/PageHeader';
import SelectionTable from '../components/tables/SelectionTable';
import errorToast from '../components/messages/ErrorMessage';
import { addNote } from '../components/modals/AddNoteModal';
import ReadOnlyFormModal from '../travel/travelExpense/ReadOnlyFormModal';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import TravelExpenseApi from '../api/travelExpenses';
import useFunctionAsState from '../hooks/useFunctionAsState';

const FinanceTravelPage = () => {
    const [projectTravelExpenses, setProjectTravelExpenses] = useState([]);
    const [coreFundTravelExpenses, setCoreFundTravelExpenses] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTravelExpense, setModalTravelExpense] = useState(null);
    const [exportDataAsCsvPT, setExportDataAsCsvPT] = useFunctionAsState(() => {});
    const [exportDataAsCsvCFT, setExportDataAsCsvCFT] = useFunctionAsState(() => {});

    const filterApprovedOrApplied = (travelExpenses) =>
        travelExpenses.filter((expense) => expense.status === 'Approved' || expense.status === 'Applied');

    const filterCoreFundTravelExpenses = (travelExpenses) =>
        travelExpenses.filter((expense) => expense.account_type === 'CoreBudgetType');

    const filterProjectTravelExpenses = (travelExpenses) =>
        travelExpenses.filter((expense) => expense.account_type === 'Project');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: travelExpenses } = await TravelExpenseApi.getTravelExpenses('All');
                const approvedOrAppliedTravelExpenses = filterApprovedOrApplied(travelExpenses);
                const coreFundTravelExpenses = filterCoreFundTravelExpenses(approvedOrAppliedTravelExpenses);
                const projectTravelExpenses = filterProjectTravelExpenses(approvedOrAppliedTravelExpenses);
                setCoreFundTravelExpenses(coreFundTravelExpenses);
                setProjectTravelExpenses(projectTravelExpenses);
            } catch (error) {
                errorToast(error);
            }
        };
        fetchData();
    }, []);

    const hoistTableParamsPT = (params) => {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            setExportDataAsCsvPT(params.exportDataAsCsv);
        }
    };

    const hoistTableParamsCFT = (params) => {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            setExportDataAsCsvCFT(params.exportDataAsCsv);
        }
    };

    const handleSelectionChange = (event) => {
        const rowList = event.api.getSelectedNodes();
        setSelectedRows(rowList);
    };

    const throwIfNoSelectedRows = () => {
        if (selectedRows && selectedRows.length === 0) {
            throw Error('No selected travel expenses!');
        }
    };

    const applyTravelExpense = async () => {
        try {
            throwIfNoSelectedRows();

            const gridApi = selectedRows[0].gridApi;
            for (const selectedRow of selectedRows) {
                const travelExpense = selectedRow.data;
                const rowNode = gridApi.getRowNode(selectedRow.id);

                const { data: paidTravelExpense } = await TravelExpenseApi.payTravelExpense(travelExpense.id);

                let status = 'Applied';
                if (paidTravelExpense.account_type === 'CoreBudgetType') {
                    status = 'Paid';
                }
                rowNode.setDataValue('status', status);
            }
            gridApi.deselectAll();
            setSelectedRows([]);
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    const returnTravelExpense = async () => {
        try {
            throwIfNoSelectedRows();

            const gridApi = selectedRows[0].gridApi;
            for (const selectedRow of selectedRows) {
                const travelExpense = selectedRow.data;

                const { data: note } = await addNote(travelExpense, 'TravelExpense');
                const attribute = {
                    notes: note,
                    in_draft: false,
                    return_note_flag: true,
                };

                await TravelExpenseApi.returnTravelExpense(travelExpense.id);
                await TravelExpenseApi.updateTravelExpense(travelExpense.id, attribute);

                gridApi.updateRowData({ remove: [travelExpense] });
            }
        } catch (error) {
            errorToast(error);
        }
    };

    const openModal = async (rowNode) => {
        try {
            const { data: travelExpense } = await TravelExpenseApi.getTravelExpenses(rowNode.data.id);
            setModalTravelExpense(travelExpense);
            setModalOpen(true);
        } catch (error) {
            errorToast(error);
        }
    };

    const closeModal = () => {
        setModalTravelExpense(null);
        setModalOpen(false);
    };

    const createColumnDefs = (isProject) => [
        { type: 'checkbox' },
        {
            headerName: 'Traveler',
            type: 'name',
            field: 'traveler',
        },
        {
            headerName: isProject ? 'Project No.' : 'Core Fund No.',
            type: 'name',
            field: 'account_number',
        },
        {
            hide: !isProject,
            headerName: 'CPO No.',
            field: 'cpo_number',
            editable: false,
            type: 'mediumname',
        },
        {
            headerName: 'Personal Charges',
            type: 'shortmoney',
            field: 'total_personal_charges',
        },
        {
            headerName: 'Company Charges',
            type: 'shortmoney',
            field: 'total_company_charges',
        },
        {
            headerName: 'Amount Due Employee',
            type: 'money',
            field: 'amount_due_employee',
        },
        {
            headerName: 'Status',
            type: 'status',
            field: 'status',
        },
        {
            headerName: 'Approver',
            type: 'name',
            field: 'approver',
        },
        {
            headerName: 'Initiated By',
            type: 'name',
            field: 'initiator',
        },
        {
            headerName: 'Initiated On',
            type: 'date',
            field: 'created_on',
        },
        {
            headerName: 'Last Updated By',
            type: 'name',
            field: 'last_updater',
        },
        {
            headerName: 'Last Updated On',
            type: 'date',
            field: 'updated_on',
        },
        {
            headerName: 'View Full Expense',
            cellRenderer: () => '<a>View</a>',
            onCellClicked: openModal,
        },
    ];

    return (
        <div>
            <PageHeader title="Travel" />
            <FinanceSubNav />
            <ReadOnlyFormModal openModal={modalOpen} closeModal={closeModal} travelExpense={modalTravelExpense} />
            <PageHeader title="Project Travel Expenses" />
            <SelectionTable
                data={projectTravelExpenses}
                columns={createColumnDefs(true)}
                sizeToFit={true}
                pagingProp={true}
                sortable={true}
                filter={true}
                onTableReady={hoistTableParamsPT}
                selectionChanged={handleSelectionChange}
                tableClassName="Travel-finance-tables"
            />
            <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
                <button type="button" onClick={() => returnTravelExpense()}>
                    Return to Sender
                </button>
                <button type="button" onClick={() => applyTravelExpense()}>
                    Apply to Financial Summary
                </button>
                <ExportCSVButton fileName="Project-Travel-Expenses" exportFunction={exportDataAsCsvPT} />
            </div>
            <PageHeader title="Core Fund Travel Expenses" />
            <SelectionTable
                data={coreFundTravelExpenses}
                columns={createColumnDefs(false)}
                sizeToFit={true}
                pagingProp={true}
                sortable={true}
                filter={true}
                onTableReady={hoistTableParamsCFT}
                selectionChanged={handleSelectionChange}
                tableClassName="Travel-finance-tables"
            />
            <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
                <button type="button" onClick={() => returnTravelExpense()}>
                    Return to Sender
                </button>
                <button type="button" onClick={() => applyTravelExpense()}>
                    Apply to Financial Summary
                </button>
                <ExportCSVButton fileName="CoreFund-Travel-Expenses" exportFunction={exportDataAsCsvCFT} />
            </div>
        </div>
    );
};

export default FinanceTravelPage;
