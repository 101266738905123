import React, { Component } from 'react';
import { Formik } from 'formik';
import FieldWithError from '../../components/forms/FieldWithError';

import ProjectsApi from '../../api/projects';
import errorToast from '../../components/messages/ErrorMessage';

class SignApprovalPop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signature: null,
            isLinkingToDb: false,
        };
    }

    async componentDidMount() {
        try {
            let user = JSON.parse(localStorage.getItem('WBI.USER'));
            let afrlSignature;
            const { data: signatures } = await ProjectsApi.getSignatures(this.props.approval.target.id);

            if (signatures && user) {
                signatures.forEach((signature) => {
                    if (signature.user_id === user.id) afrlSignature = signature;
                });
            }

            this.setState({ signature: afrlSignature });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        if (!this.state.signature) return <div>...Loading</div>;
        const { isLinkingToDb, signature } = this.state;
        let link;
        if (signature.link_to_db) {
            if (signature.link_to_db.includes('http://') || signature.link_to_db.includes('https://')) {
                link = signature.link_to_db;
            } else {
                link = 'http://' + signature.link_to_db;
            }
        } else {
            link = signature.link_to_db;
        }
        return (
            <Formik initialValues={this.state.signature}>
                {({ values, errors, touched, setFieldValue }) => (
                    <div style={{ width: '300px' }}>
                        <p style={{ height: '2em', borderBottom: '1px solid black' }}>
                            {values.signed && (
                                <>
                                    {values.name}
                                    {values.link_to_db !== null && (
                                        <a href={link} rel="noreferrer" style={{ marginLeft: 8 }}>
                                            Open in DB
                                        </a>
                                    )}
                                </>
                            )}
                        </p>
                        <div />
                        <>
                            <FieldWithError name="name" type="text" placeholder="Name" readOnly />
                            <FieldWithError name="title" type="text" readOnly />
                            <FieldWithError name="organization" type="text" readOnly />
                            {isLinkingToDb && (
                                <FieldWithError
                                    name="link_to_db"
                                    type="text"
                                    placeholder="Link to DB"
                                    errors={errors}
                                    touched={touched}
                                />
                            )}
                        </>
                        <>
                            <button
                                className="flex flex-column flex-1 Link-button"
                                type="button"
                                onClick={async () => {
                                    setFieldValue('signed', true);
                                    await Promise.resolve();
                                }}
                            >
                                Sign
                            </button>
                            <button
                                className="flex flex-column flex-1 Link-button"
                                type="button"
                                onClick={() => this.setState({ isLinkingToDb: true })}
                            >
                                Attach Signature
                            </button>
                            <button
                                className="flex flex-column flex-1 Link-button"
                                type="button"
                                onClick={() => {
                                    this.props.editSignature(values);
                                }}
                            >
                                Save
                            </button>
                        </>
                    </div>
                )}
            </Formik>
        );
    }
}

export default SignApprovalPop;
