import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import LabeledField from '../../components/forms/LabeledField';

export const ROStandardForm = (props) => {
    const { disablePaging, pageRight, pageLeft, formInitValues, cardHolderOptions, travelExpenditureTypeOptions } =
        props;

    return (
        <Fragment>
            <div className="Companion-form">
                <Formik enableReinitialize={true} initialValues={formInitValues} onSubmit={() => {}}>
                    {({ values }) => (
                        <Form>
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={travelExpenditureTypeOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Transaction date*"
                                name="purchase_date"
                                component="Date"
                                readOnly={true}
                            />
                            <LabeledField label="Vendor*" name="store" readOnly={true} />
                            <LabeledField label="Amount*" name="cost" component="Money" readOnly={true} />
                            {values.link_to_db && values.link_to_db.length > 0 && (
                                <LabeledField
                                    label="Link To DB*"
                                    name="link_to_db"
                                    readOnly={true}
                                    component="Link"
                                    value={values.link_to_db}
                                />
                            )}
                            {(!values.link_to_db || values.link_to_db.length === 0) && (
                                <LabeledField label="Link To DB*" name="link_to_db" readOnly={true} />
                            )}
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                readOnly={true}
                            />
                            <LabeledField label="Comments" name="notes" component="textarea" readOnly={true} />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button onClick={pageLeft} disabled={disablePaging}>
                                    Page Left
                                </button>
                                <button onClick={pageRight} disabled={disablePaging}>
                                    Page Right
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export const ROParkingTollsForm = (props) => {
    const { disablePaging, pageRight, pageLeft, formInitValues, cardHolderOptions, travelExpenditureTypeOptions } =
        props;

    return (
        <Fragment>
            <div className="Companion-form">
                <Formik enableReinitialize={true} initialValues={formInitValues} onSubmit={() => {}}>
                    {({ values }) => (
                        <Form>
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={travelExpenditureTypeOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Transaction date*"
                                name="purchase_date"
                                component="Date"
                                readOnly={true}
                            />
                            <LabeledField label="Amount*" name="cost" component="Money" readOnly={true} />
                            {values.link_to_db && values.link_to_db.length > 0 && (
                                <LabeledField
                                    label="Link To DB*"
                                    name="link_to_db"
                                    readOnly={true}
                                    component="Link"
                                    value={values.link_to_db}
                                />
                            )}
                            {(!values.link_to_db || values.link_to_db.length === 0) && (
                                <LabeledField label="Link To DB*" name="link_to_db" readOnly={true} />
                            )}
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                readOnly={true}
                            />
                            <LabeledField label="Comments" name="notes" component="textarea" readOnly={true} />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button onClick={pageLeft} disabled={disablePaging}>
                                    Page Left
                                </button>
                                <button onClick={pageRight} disabled={disablePaging}>
                                    Page Right
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export const ROCarRentalForm = (props) => {
    const { disablePaging, pageRight, pageLeft, formInitValues, cardHolderOptions, travelExpenditureTypeOptions } =
        props;

    return (
        <Fragment>
            <div className="Companion-form">
                <Formik enableReinitialize={true} initialValues={formInitValues} onSubmit={() => {}}>
                    {({ values }) => (
                        <Form>
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={travelExpenditureTypeOptions}
                                readOnly={true}
                            />
                            <LabeledField label="Vendor*" name="store" readOnly={true} />
                            <LabeledField
                                label="Start Date*"
                                name="expense_start_date"
                                component="Date"
                                readOnly={true}
                            />
                            <LabeledField label="End Date*" name="expense_end_date" component="Date" readOnly={true} />
                            <LabeledField
                                label="Transaction Date*"
                                name="purchase_date"
                                component="Date"
                                readOnly={true}
                            />
                            <LabeledField label="Total Charges*" name="cost" component="Money" readOnly={true} />
                            {values.link_to_db && values.link_to_db.length > 0 && (
                                <LabeledField
                                    label="Link To DB*"
                                    name="link_to_db"
                                    readOnly={true}
                                    component="Link"
                                    value={values.link_to_db}
                                />
                            )}
                            {(!values.link_to_db || values.link_to_db.length === 0) && (
                                <LabeledField label="Link To DB*" name="link_to_db" readOnly={true} />
                            )}
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                readOnly={true}
                            />
                            <LabeledField label="Comments" name="notes" component="textarea" readOnly={true} />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button onClick={pageLeft} disabled={disablePaging}>
                                    Page Left
                                </button>
                                <button onClick={pageRight} disabled={disablePaging}>
                                    Page Right
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export const ROBaggageForm = (props) => {
    const { disablePaging, pageRight, pageLeft, formInitValues, cardHolderOptions, travelExpenditureTypeOptions } =
        props;

    return (
        <Fragment>
            <div className="Companion-form">
                <Formik enableReinitialize={true} initialValues={formInitValues} onSubmit={() => {}}>
                    {({ values }) => (
                        <Form>
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={travelExpenditureTypeOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Transaction date*"
                                name="purchase_date"
                                component="Date"
                                readOnly={true}
                            />
                            <LabeledField label="Airline*" name="store" readOnly={true} />
                            <LabeledField label="Amount*" name="cost" component="Money" readOnly={true} />
                            {values.link_to_db && values.link_to_db.length > 0 && (
                                <LabeledField
                                    label="Link To DB*"
                                    name="link_to_db"
                                    readOnly={true}
                                    component="Link"
                                    value={values.link_to_db}
                                />
                            )}
                            {(!values.link_to_db || values.link_to_db.length === 0) && (
                                <LabeledField label="Link To DB*" name="link_to_db" readOnly={true} />
                            )}
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                readOnly={true}
                            />
                            <LabeledField label="Comments" name="notes" component="textarea" readOnly={true} />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button onClick={pageLeft} disabled={disablePaging}>
                                    Page Left
                                </button>
                                <button onClick={pageRight} disabled={disablePaging}>
                                    Page Right
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export const ROAirFareForm = (props) => {
    const { disablePaging, pageRight, pageLeft, formInitValues, cardHolderOptions, travelExpenditureTypeOptions } =
        props;

    return (
        <Fragment>
            <div className="Companion-form">
                <Formik enableReinitialize={true} initialValues={formInitValues} onSubmit={() => {}}>
                    {({ values }) => (
                        <Form>
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={travelExpenditureTypeOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Transaction date*"
                                name="purchase_date"
                                component="Date"
                                readOnly={true}
                            />
                            <LabeledField
                                label="Date of Departure*"
                                name="expense_start_date"
                                component="Date"
                                readOnly={true}
                            />
                            <LabeledField label="Airline*" name="store" readOnly={true} />
                            <LabeledField label="Amount*" name="cost" component="Money" readOnly={true} />
                            {values.link_to_db && values.link_to_db.length > 0 && (
                                <LabeledField
                                    label="Link To DB*"
                                    name="link_to_db"
                                    readOnly={true}
                                    component="Link"
                                    value={values.link_to_db}
                                />
                            )}
                            {(!values.link_to_db || values.link_to_db.length === 0) && (
                                <LabeledField label="Link To DB*" name="link_to_db" readOnly={true} />
                            )}
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                readOnly={true}
                            />
                            <LabeledField label="Comments" name="notes" component="textarea" readOnly={true} />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button onClick={pageLeft} disabled={disablePaging}>
                                    Page Left
                                </button>
                                <button onClick={pageRight} disabled={disablePaging}>
                                    Page Right
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export const ROMilesDrivenForm = (props) => {
    const {
        disablePaging,
        pageRight,
        pageLeft,
        formInitValues,
        cardHolderOptions,
        travelExpenditureTypeOptions,
        perDays,
    } = props;
    return (
        <Fragment>
            <div className="Companion-form">
                <Formik enableReinitialize={true} initialValues={formInitValues} onSubmit={() => {}}>
                    {({ values }) => (
                        <Form>
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={travelExpenditureTypeOptions}
                                readOnly={true}
                            />
                            <label className="FieldLabel">Miles Driven Per Day</label>
                            <div className="FieldArrayWrapper">
                                <FieldArray
                                    name="per_day_miles"
                                    render={() => (
                                        <Fragment>
                                            {values.per_day_miles.map((key, index) => (
                                                <div className="PerDayInput">
                                                    <LabeledField
                                                        key={index}
                                                        label={perDays[index].date}
                                                        value={key}
                                                        name={`per_day_miles.${index}`}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            ))}
                                        </Fragment>
                                    )}
                                />
                            </div>
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                readOnly={true}
                            />
                            <LabeledField label="Comments" name="notes" component="textarea" readOnly={true} />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button onClick={pageLeft} disabled={disablePaging}>
                                    Page Left
                                </button>
                                <button onClick={pageRight} disabled={disablePaging}>
                                    Page Right
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export const ROHotelForm = (props) => {
    const {
        disablePaging,
        pageRight,
        pageLeft,
        formInitValues,
        cardHolderOptions,
        travelExpenditureTypeOptions,
        perDays,
    } = props;

    return (
        <Fragment>
            <div className="Companion-form">
                <Formik enableReinitialize={true} initialValues={formInitValues} onSubmit={() => {}}>
                    {({ values }) => (
                        <Form>
                            <LabeledField
                                label="Traveler/Cardholder*"
                                name="cc_holder_id"
                                component="select"
                                options={cardHolderOptions}
                                readOnly={true}
                            />
                            <LabeledField
                                label="Category*"
                                name="travel_expenditure_type"
                                component="select"
                                options={travelExpenditureTypeOptions}
                                readOnly={true}
                            />
                            <LabeledField label="Vendor*" name="store" readOnly={true} />
                            <label className="FieldLabel">Rate Per Night</label>
                            <div className="FieldArrayWrapper">
                                <FieldArray
                                    name="hotel_per_night_rate"
                                    render={() => (
                                        <Fragment>
                                            {Object.keys(values.hotel_per_night_rate).map((key, index) => (
                                                <div className="PerDayInput">
                                                    <LabeledField
                                                        key={index}
                                                        label={perDays[index].date}
                                                        name={`hotel_per_night_rate.${key}`}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            ))}
                                        </Fragment>
                                    )}
                                />
                            </div>
                            <LabeledField
                                label="Non-Reimbursables*"
                                name="hotel_non_reimburse"
                                component="Money"
                                readOnly={true}
                            />
                            <LabeledField
                                label="Total Taxes & Fees*"
                                name="hotel_taxes_fees"
                                component="Money"
                                readOnly={true}
                            />
                            <LabeledField
                                label="Total Charges*"
                                name="cost"
                                component="Money"
                                value={Number(values.hotel_taxes_fees) + Number(values.hotel_non_reimburse)}
                                readOnly={true}
                            />
                            {values.link_to_db && values.link_to_db.length > 0 && (
                                <LabeledField
                                    label="Link To DB*"
                                    name="link_to_db"
                                    readOnly={true}
                                    component="Link"
                                    value={values.link_to_db}
                                />
                            )}
                            {(!values.link_to_db || values.link_to_db.length === 0) && (
                                <LabeledField label="Link To DB*" name="link_to_db" readOnly={true} />
                            )}
                            <LabeledField
                                label="Personal Charge*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Yes', value: 'personal' },
                                    { label: 'No', value: 'business' },
                                ]}
                                readOnly={true}
                            />
                            <LabeledField label="Comments" name="notes" component="textarea" readOnly={true} />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button onClick={pageLeft} disabled={disablePaging}>
                                    Page Left
                                </button>
                                <button onClick={pageRight} disabled={disablePaging}>
                                    Page Right
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export const ROTravelExpenseForm = (props) => {
    const { travelExpense } = props;
    const [multiCity, setMultiCity] = useState(false);
    const [visibleCity, setVisibleCity] = useState(0);

    useEffect(() => {
        if (travelExpense.itinerary && travelExpense.itinerary.length > 0) {
            setMultiCity(true);
        } else {
            setMultiCity(false);
        }
    }, [travelExpense.itinerary]);

    const selectItineraryField = (props) => (
        <Fragment>
            <label className="FieldLabel itinerary-label">Itinerary</label>
            <select
                className="Travel-itinerary-select"
                onChange={(event) => {
                    setVisibleCity(parseInt(event.target.value, 10));
                }}
            >
                {props.itinerary &&
                    props.itinerary.map((item, index) => (
                        <option key={item.id} value={index} selected={index === visibleCity && 'True'}>
                            ({index + 1}) {item.city_state}
                        </option>
                    ))}
            </select>
        </Fragment>
    );

    const multiCityReadOnly = (props) => (
        <FieldArray
            name="itinerary"
            render={(arrayHelpers) => (
                <div className="column-span-3">
                    {props.itinerary &&
                        props.itinerary.map((item, index) => (
                            <div
                                id={`vis_${index}`}
                                key={index}
                                className={`grid columns-3 ${index !== visibleCity && 'hidden'}`}
                            >
                                <LabeledField
                                    label="Travel Start Date"
                                    name={`itinerary.${index}.start_date`}
                                    component="Date"
                                    readOnly={true}
                                />
                                <LabeledField
                                    label="Travel End Date"
                                    name={`itinerary.${index}.end_date`}
                                    component="Date"
                                    readOnly={true}
                                />
                                <LabeledField
                                    label="Destination City, State"
                                    name={`itinerary.${index}.city_state`}
                                    readOnly={true}
                                />
                                <LabeledField
                                    label="Destination Zip Code"
                                    name={`itinerary.${index}.zip_code`}
                                    readOnly={true}
                                />
                                <LabeledField
                                    label="Per Diem Rate (M&IE)"
                                    name={`itinerary.${index}.per_diem_rate`}
                                    component="Money"
                                    readOnly={true}
                                />
                                <LabeledField
                                    label="Maximum Lodging Rate"
                                    name={`itinerary.${index}.max_lodge_rate`}
                                    component="Money"
                                    readOnly={true}
                                />
                                <LabeledField
                                    label="Mileage Rate"
                                    name={`itinerary.${index}.mileage_rate`}
                                    component="Money"
                                    readOnly={true}
                                />

                                <LabeledField
                                    label="Travel Purpose"
                                    name={`itinerary.${index}.notes`}
                                    component="textarea"
                                    readOnly={true}
                                />
                            </div>
                        ))}
                </div>
            )}
        />
    );

    const singleCityReadOnly = (props) => (
        <div className="column-span-3">
            <LabeledField label="Travel Start Date" name="props.start_date" component="Date" readOnly={true} />
            <LabeledField label="Travel End Date" name="props.end_date" component="Date" readOnly={true} />
            <LabeledField label="Destination City, State" name="props.city_state" readOnly={true} />
            <LabeledField label="Destination Zip Code" name="props.zip_code" readOnly={true} />
            <LabeledField label="Per Diem Rate (M&IE)" name="props.per_diem_rate" component="Money" readOnly={true} />
            <LabeledField label="Maximum Lodging Rate" name="props.max_lodge_rate" component="Money" readOnly={true} />
            <LabeledField label="Mileage Rate" name="props.mileage_rate" component="Money" readOnly={true} />

            <LabeledField label="Travel Purpose" name="props.notes" component="textarea" readOnly={true} />
        </div>
    );

    return (
        <div className="Companion-form">
            <Formik enableReinitialize={true} initialValues={travelExpense} onSubmit={() => {}}>
                {({ values }) => (
                    <Form>
                        <LabeledField label="Traveler/Cardholder" name="traveler" readOnly={true} />
                        <LabeledField label="Prepared By" name="initiator" readOnly={true} />
                        <LabeledField label="Address" name="address" readOnly={true} />
                        <LabeledField label="Approver" name="approver" readOnly={true} />
                        <LabeledField label="Account No" name="account_number" readOnly={true} />
                        {multiCity && selectItineraryField(values)}
                        {multiCity && multiCityReadOnly(values)}
                        {!multiCity && singleCityReadOnly(values)}
                    </Form>
                )}
            </Formik>
        </div>
    );
};
