import * as yup from 'yup';
import { setLocale } from 'yup';

setLocale({
    mixed: {
        required: 'This is required',
    },
});

export default yup;
