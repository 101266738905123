import React, { Component } from 'react';
//Shared component
import { shortDate } from '../../utils/utils';
import ErgoMoney from '../../utils/ErgoMoney';

class ApprovalCards extends Component {
    render() {
        if (this.props.approvals) {
            return this.props.approvals.map((item, index) => (
                <div className="Card-styling">
                    <table className="Approval_table" key={index}>
                        <tbody>
                            <tr>
                                <td className="Table_label">Transfer Type:</td>
                                <td>{item.transfer_type}</td>
                            </tr>
                            <tr>
                                <td className="Table_label">Initiated By:</td>
                                <td>{item.initiator_name}</td>
                            </tr>
                            <tr>
                                <td className="Table_label">Date:</td>
                                <td>{shortDate(item.created_on)}</td>
                            </tr>
                            <tr>
                                <td className="Table_label">Funds from AFRL Symbol:</td>
                                <td>{item.residual_funds_name}</td>
                            </tr>
                            <tr>
                                <td className="Table_label">Project No.:</td>
                                <td>{item.project_number}</td>
                            </tr>
                            <tr>
                                <td className="Table_label">Amount:</td>
                                <td>{new ErgoMoney(item.amount).getStringAmount()}</td>
                            </tr>
                            <tr>
                                <td className="Table_label">Description of Transfer:</td>
                                <td>{item.description}</td>
                            </tr>
                            <tr>
                                <td className="Table_label">Reason of Transfer:</td>
                                <td>{item.reason}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="Form__buttons--Reversed Card-buttons">
                        <button type="button" onClick={() => this.props.reject(item.id, index)}>
                            Reject Transfer
                        </button>
                        <button type="button" onClick={() => this.props.approve(item.id, index)}>
                            Approve Transfer
                        </button>
                    </div>
                </div>
            ));
        }
        return null;
    }
}

export default ApprovalCards;
