// React
import React from 'react';

// Libraries
import _filter from 'lodash/filter';
import { Formik, Form } from 'formik';
import moment from 'moment';
import yup from '../yup';

// Component
import EditingTable from '../components/tables/EditingTable';
import { fundTransferTypes } from './config';
import LabeledField from '../components/forms/LabeledField';
import ErgoMoney from '../utils/ErgoMoney';
import Message from '../components/messages/Message';
import { history } from '../store/store';
import errorToast from '../components/messages/ErrorMessage';
import { confirm } from '../components/modals/ConfirmModal';

// Api
import ProjectsApi from '../api/projects';
import TransferRequestApi from '../api/transferRequest';
import ResidualFundsApi from '../api/residualFunds';
import UsersApi from '../api/users';

class TransferFundsPage extends React.Component {
    constructor(props) {
        super(props);

        this.calculateNewFunds = this.calculateNewFunds.bind(this);
        this.isItProjectTransfer = this.isItProjectTransfer.bind(this);
        this.navigate = this.navigate.bind(this);
        this.navigateToSource = this.navigateToSource.bind(this);
        this.navigateToDestination = this.navigateToDestination.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.deleteTransfer = this.deleteTransfer.bind(this);

        this.state = {
            project: {},
            projects: [],
            transfers: [],
            approvals: null,
            residualFunds: [],
            users: [],
            subprojects: [],
            projectFinanceInfo: null,
            rowToUpdate: null,
            columns: [
                {
                    headerName: 'Transaction Date',
                    field: 'transaction_date',
                    type: 'date',
                },
                {
                    headerName: 'Transfer Type',
                    field: 'type',
                    type: 'mediumtext',
                },
                {
                    headerName: 'Source',
                    field: 'source',
                    type: 'mediumtext',
                    onCellClicked: this.navigateToSource,
                    cellRenderer: (params) => {
                        if (params.data.source && params.data.source.name) {
                            return `<a>${params.data.source.name}</a>`;
                        }
                        return null;
                    },
                },
                {
                    headerName: 'Destination',
                    field: 'destination',
                    onCellClicked: this.navigateToDestination,
                    type: 'mediumtext',
                    cellRenderer: (params) => {
                        if (params.data.destination && params.data.destination.name) {
                            return `<a>${params.data.destination.name}</a>`;
                        }
                        return null;
                    },
                },
                {
                    headerName: 'Amount',
                    field: 'amount',
                    type: 'shortmoney',
                },
                {
                    headerName: 'Description of Transfer',
                    field: 'description',
                    type: 'longtext',
                },
                {
                    headerName: 'Reason for Transfer',
                    field: 'reason',
                    type: 'longtext',
                },
                {
                    headerName: 'Link to DB',
                    field: 'link_to_db',
                    type: 'symbol',
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                    onCellClicked: this.directToDb,
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    type: 'status',
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator.name',
                    type: 'name',
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_updater.name',
                    type: 'name',
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.deleteTransfer,
                },
            ],
        };
    }

    async componentDidMount() {
        try {
            let projectId = this.props.match.params.id;
            const { data: project } = await ProjectsApi.fetchProject(projectId);
            const { data: projectFinanceInfo } = await ProjectsApi.fetchProjectFinancialInformation(projectId);
            const { data: projects } = await ProjectsApi.fetchProjectNames();
            const { data: subprojects } = await ProjectsApi.fetchSubProjectNames();
            const { data: residualFunds } = await ResidualFundsApi.getResidualFunds();
            const { data: users } = await UsersApi.getUsers();
            const { data: transactions } = await ProjectsApi.fetchProjectTransactionsTransfers(projectId);

            let projectsOptions = [];
            if (projects) {
                projects.forEach((project) => {
                    projectsOptions.push({
                        label: project.number,
                        value: project.number,
                        name: project.name,
                        id: project.id,
                    });
                });
                projectsOptions.sort((a, b) => a.label.localeCompare(b.label));
                if (projectId) {
                    projectsOptions = projectsOptions.filter((project) => project.id !== projectId);
                }
            }

            let residualFundsOptions = [];
            if (residualFunds) {
                residualFunds.forEach((rf) => {
                    residualFundsOptions.push({
                        label: rf.name,
                        value: rf.name,
                        name: rf.name,
                        id: rf.id,
                    });
                });
                residualFundsOptions.sort((a, b) => a.label.localeCompare(b.label));
            }

            let subprojectsOptions = [];
            let filteredSubprojects = [];
            if (subprojects) {
                filteredSubprojects = subprojects.filter((e) => (e.number.includes(project.number) ? e : null));
                filteredSubprojects.forEach((sub) => {
                    subprojectsOptions.push({ label: sub.number, value: sub.id, name: sub.name });
                });
                subprojectsOptions.sort((a, b) => a.label.localeCompare(b.label));
            }

            let transfers = [];
            for (let i = 0; i < transactions.length; i++) {
                if (transactions[i].destination_type === 'ResidualFund' && transactions[i].source_type === 'Project') {
                    transfers.push(transactions[i]);
                } else if (
                    transactions[i].destination_type === 'Project' &&
                    (transactions[i].source_type === 'Project' || transactions[i].source_type === 'ResidualFund')
                ) {
                    transfers.push(transactions[i]);
                }
            }

            let filteredProjects = _filter(projects, (project) => !project.subproject && project.id !== projectId);

            this.setState({
                project,
                projectsOptions,
                projects: filteredProjects,
                residualFundsOptions,
                users,
                subprojectsOptions,
                projectFinanceInfo,
                transfers,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    getLabel(destination, label) {
        destination = destination.value ? destination.value : destination;
        if (label === 'num') {
            if (destination === 'Project' || !destination) return 'To Project No. B';
            if (destination === 'ResidualFund') return 'To AFRL Symbol';
            if (destination === 'Subproject') {
                return 'To Sub-Project';
            }
        }
        if (destination === 'Project' || !destination) return 'Project B Name';
        if (destination === 'ResidualFund') return 'To AFRL Symbol';
        if (destination === 'Subproject') {
            return 'Subproject Name';
        }
    }

    getOptions(destination, projectsOptions, residualFundsOptions, subprojects) {
        destination = destination.value ? destination.value : destination;
        let options = [];
        if ((!destination || destination === 'Project') && projectsOptions) {
            options = projectsOptions;
        } else if (destination === 'ResidualFund' && residualFundsOptions) {
            options = residualFundsOptions;
        } else if (destination === 'Subproject' && subprojects) {
            options = subprojects;
        }
        return options;
    }

    deleteTransfer = async (event) => {
        if (event.data.status !== 'Applied') {
            const confirmed = await confirm({
                text: 'Are you sure you want to delete this transfer? All data will be lost',
            });

            let { transfers } = this.state;
            if (confirmed) {
                TransferRequestApi.deleteTransferRequest(event.data.id)
                    .then(() => {
                        transfers = transfers.filter((transfer) => transfer.id !== event.data.id);
                        const projectFinanceInfo = this.state.projectFinanceInfo;
                        projectFinanceInfo.remaining_funds += event.data.amount;
                        this.setState(
                            {
                                transfers,
                                forceSetData: true,
                                projectFinanceInfo,
                            },
                            () => {
                                this.setState({ forceSetData: false });
                            }
                        );
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            }
        } else {
            await confirm({
                text: 'Transfers can not be edited or deleted once acted on by finance.  Please contact the finance department.',
                acceptText: 'Ok',
                rejectText: null,
            });
        }
    };

    getRowData = async (event) => {
        if (event.node.data.status !== 'Applied') {
            let gridApi = event.node.gridApi;
            gridApi.updateRowData({ remove: [event.node.data] });
            const projectFinanceInfo = this.state.projectFinanceInfo;
            projectFinanceInfo.remaining_funds += event.node.data.amount;
            this.setState({
                rowToUpdate: event.node.data,
                projectFinanceInfo,
            });
        } else {
            await confirm({
                text: 'Transfers can not be edited or deleted once acted on by finance.  Please contact the finance department.',
                acceptText: 'Ok',
                rejectText: null,
            });
        }
    };

    handleSubmit = (values, actions) => {
        let { rowToUpdate, transfers } = this.state;

        let attributes = {
            amount: values.amount,
            description: values.description,
            destination_id: values.destination_id,
            reason: values.reason,
            link_to_db: values.link_to_db,
            source_id: this.state.project.id,
            source_type: 'Project',
            reviewer_ids: [],
            status: 'submitted',
        };

        let destinationType = values.destination_type.value ? values.destination_type.value : values.destination_type;
        if (destinationType === 'Project') {
            attributes.destination_type = 'Project';
        } else if (destinationType === 'ResidualFund') {
            attributes.destination_type = 'ResidualFund';
        } else if (destinationType === 'Subproject') {
            attributes.destination_type = 'Project';
        }

        if (rowToUpdate) {
            TransferRequestApi.updateTransferRequest(attributes, rowToUpdate.id)
                .then((response) => {
                    transfers = transfers.filter((transfer) => transfer.id !== rowToUpdate.id);
                    transfers.unshift(response.data);
                    const projectFinanceInfo = this.state.projectFinanceInfo;
                    projectFinanceInfo.remaining_funds -= values.amount;
                    this.setState(
                        {
                            rowToUpdate: null,
                            transfers,
                            forceSetData: true,
                            projectFinanceInfo,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        } else {
            TransferRequestApi.createTransferRequest(attributes)
                .then((response) => {
                    let transfers = this.state.transfers;
                    if (response.data.destination_type === 'Project') {
                        response.data.type = 'Funds Between Project and Project';
                    } else {
                        response.data.type = 'Funds Between Project and Residual Fund';
                    }
                    transfers.push(response.data);
                    const projectFinanceInfo = this.state.projectFinanceInfo;
                    projectFinanceInfo.remaining_funds -= values.amount;
                    this.setState(
                        {
                            transfers,
                            forceSetData: true,
                            projectFinanceInfo,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
        actions.resetForm();
    };

    calculateNewFunds(projectRemFunds, value) {
        return projectRemFunds - value;
    }

    isItProjectTransfer(value) {
        return value === 'Project' || value.value === 'Project';
    }

    navigate(type, id) {
        let location = '';
        if (type === 'ResidualFund') {
            location = '/finance/residualFunds/';
        } else if (type === 'Project') {
            location = '/projects/' + id;
        }
        if (location !== '') {
            history.push(location);
        }
    }

    navigateToDestination(event) {
        let type = event.data.destination_type;
        let id = event.data.destination.id;
        this.navigate(type, id);
    }

    navigateToSource(event) {
        let type = event.data.source_type;
        let id = event.data.source.id;
        this.navigate(type, id);
    }

    render() {
        if (!this.state.project || !this.state.residualFunds) return <div>loading...</div>;

        const {
            projectsOptions,
            residualFundsOptions,
            subprojectsOptions,
            rowToUpdate,
            columns,
            transfers,
            forceSetData,
        } = this.state;

        const isApproving = this.props.approval;
        let user = JSON.parse(localStorage.getItem('WBI.USER'));

        let projectName;
        let projectNumber;
        let projectRemFunds;
        if (this.state.project && this.state.projectFinanceInfo) {
            projectName = this.state.project.name;
            projectNumber = this.state.project.number;
            projectRemFunds = Number.parseFloat(this.state.projectFinanceInfo.remaining_funds).toFixed(2);
        }

        let formInitValues;
        if (rowToUpdate) {
            let destinationNumber;
            let destinationName;
            if (rowToUpdate.destination_type === 'ResidualFund') {
                destinationName = rowToUpdate.destination.name;
                destinationNumber = destinationName;
            } else {
                destinationName = rowToUpdate.destination.name;
                destinationNumber = rowToUpdate.destination.number;
            }

            formInitValues = {
                destination_type: rowToUpdate.destination_type,
                initiatior_name: rowToUpdate.initiator.name,
                date: rowToUpdate.transaction_date,
                amount: rowToUpdate.amount,
                description: rowToUpdate.description,
                reason: rowToUpdate.reason,
                link_to_db: rowToUpdate.link_to_db,
                source_number: projectNumber,
                source_name: projectName,
                source_funds: projectRemFunds,
                destination_number: destinationNumber,
                destination_name: destinationName,
                destination_id: rowToUpdate.destination_id,
            };
        } else {
            formInitValues = {
                destination_type: 'Project',
                initiatior_name: user.name,
                date: moment().format('YYYY-MM-DD'),
                amount: '',
                description: '',
                reason: '',
                link_to_db: '',
                source_number: projectNumber,
                source_name: projectName,
                source_funds: projectRemFunds,
                destination_number: '',
                destination_name: '',
            };
        }

        return (
            <>
                <div className="Wide-single-layout">
                    <Formik
                        enableReinitialize={true}
                        initialValues={formInitValues}
                        validationSchema={yup.object().shape({
                            destination_type: yup.string().required(),
                            initiatior_name: yup.string(),
                            date: yup.date(),
                            amount: yup.number().required(),
                            description: yup.string(),
                            link_to_db: yup.string(),
                            reason: yup.string(),
                            destination_number: yup.string().required(),
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, resetForm, setFieldValue }) => (
                            <Form className="flex flex-column">
                                <div
                                    className="grid"
                                    style={{
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                        gridGap: 16,
                                        marginTop: 24,
                                        marginBottom: 24,
                                        maxWidth: 800,
                                    }}
                                >
                                    <LabeledField
                                        label="Transfer Type*"
                                        name="destination_type"
                                        component="select"
                                        options={fundTransferTypes}
                                        errors={errors}
                                        touched={touched}
                                        readOnly={isApproving}
                                        onChange={(e) => {
                                            setFieldValue('destination_type', e);
                                            setFieldValue('destination_number', '');
                                            setFieldValue('destination_id', '');
                                            setFieldValue('destination_name', '');
                                        }}
                                    />
                                    <LabeledField label="Initiated By" name="initiatior_name" type="text" readOnly />
                                    <LabeledField
                                        label="Transaction Date"
                                        name="date"
                                        type="date"
                                        component="Date"
                                        readOnly
                                    />
                                    <LabeledField
                                        label="Amount*"
                                        name="amount"
                                        component="Money"
                                        readOnly={isApproving}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Description"
                                        name="description"
                                        component="textarea"
                                        readOnly={isApproving}
                                    />
                                    <LabeledField
                                        label="Reason"
                                        name="reason"
                                        component="textarea"
                                        readOnly={isApproving}
                                    />
                                    <LabeledField
                                        label="Link to DB"
                                        name="link_to_db"
                                        type="text"
                                        readOnly={isApproving}
                                    />
                                </div>
                                <div
                                    className="grid align-items-start"
                                    style={{
                                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                                        gridGap: 16,
                                        marginTop: 24,
                                        marginBottom: 24,
                                    }}
                                >
                                    <LabeledField
                                        label="From Project No. A"
                                        name="source_number"
                                        value={projectNumber}
                                        type="text"
                                        readOnly
                                    />
                                    <LabeledField
                                        label="Project A Name"
                                        name="source_name"
                                        value={projectName}
                                        type="text"
                                        readOnly
                                    />
                                    <LabeledField
                                        label={this.getLabel(values.destination_type, 'num') + '*'}
                                        name="destination_number"
                                        component="select"
                                        options={this.getOptions(
                                            values.destination_type,
                                            projectsOptions,
                                            residualFundsOptions,
                                            subprojectsOptions
                                        )}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            setFieldValue('destination_name', e.name);
                                            if (e.id) {
                                                setFieldValue('destination_number', e.value);
                                                setFieldValue('destination_id', e.id);
                                            } else {
                                                setFieldValue('destination_number', e.label);
                                                setFieldValue('destination_id', e.value);
                                            }
                                        }}
                                    />
                                    <LabeledField
                                        label={this.getLabel(values.destination_type, 'name')}
                                        name="destination_name"
                                        readOnly
                                    />
                                </div>
                                <div
                                    className="grid align-items-start"
                                    style={{
                                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                        gridGap: 16,
                                        marginTop: 24,
                                        marginBottom: 24,
                                    }}
                                >
                                    <div style={{ gridColumn: 'span 1' }} />
                                    <LabeledField
                                        label="Project A Funds Available for Transfer"
                                        name="source_funds"
                                        value={new ErgoMoney(projectRemFunds).getStringAmount()}
                                        type="text"
                                        readOnly
                                        className="Decimal_aligned"
                                    />
                                    <div style={{ gridColumn: 'span 1' }} />
                                    <LabeledField
                                        label="Funds to be Transfered"
                                        name="amount"
                                        value={new ErgoMoney(values.amount).getStringAmount()}
                                        type="text"
                                        readOnly
                                        className="Decimal_aligned"
                                    />
                                    <div style={{ gridColumn: 'span 1' }} />
                                    <LabeledField
                                        label="Project A Remaining Uncommitted Funds"
                                        name="source_remaining"
                                        value={
                                            values.amount !== 0
                                                ? new ErgoMoney(
                                                      this.calculateNewFunds(projectRemFunds, values.amount)
                                                  ).getStringAmount()
                                                : projectRemFunds
                                        }
                                        type="text"
                                        readOnly
                                        className="Decimal_aligned"
                                    />
                                </div>
                                {this.calculateNewFunds(projectRemFunds, values.amount) < 0 ? (
                                    <div className="Signatures-space">
                                        <Message value="*The Funds to be Transfered cannot exceed the Project A Funds Available for Transfer." />
                                    </div>
                                ) : null}
                                {!isApproving && (
                                    <div className="Form__buttons--Reversed Buttons_format extra_space">
                                        <button
                                            type="button"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                resetForm();
                                                this.setState(
                                                    {
                                                        rowToUpdate: null,
                                                        forceSetData: true,
                                                    },
                                                    () => {
                                                        this.setState({ forceSetData: true });
                                                    }
                                                );
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={this.calculateNewFunds(projectRemFunds, values.amount) < 0}
                                        >
                                            {this.isItProjectTransfer(values.destination_type)
                                                ? 'Submit for Approval'
                                                : 'Submit Transfer'}
                                        </button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="Table-styling tfp-table">
                    <EditingTable columns={columns} data={transfers} pagingProp={true} addedNewData={forceSetData} />
                </div>
            </>
        );
    }
}

export default TransferFundsPage;
