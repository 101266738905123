import React from 'react';

const idForRadioOption = (name, value) => 'Field-' + name + '-' + value;

const componentForRadioOption =
    ({ name, currentValue, inputComponent: InputComponent = 'input', readOnly }) =>
    (option) => {
        const isObject = typeof option === 'object';
        const value = isObject ? option.value : option;
        const label = isObject ? option.label : option;
        const id = idForRadioOption(name, value);

        return (
            <React.Fragment key={value}>
                <InputComponent
                    type="radio"
                    name={name}
                    value={value}
                    checked={currentValue === value}
                    disabled={readOnly && currentValue !== value}
                    id={id}
                    style={{ marginTop: 4 }}
                />
                <label htmlFor={id} style={{ marginLeft: 8, marginRight: 16 }}>
                    {label}
                </label>
            </React.Fragment>
        );
    };

const Radio = ({ options, ...props }) => (
    <div className="flex align-items-start">{options.map(componentForRadioOption(props))}</div>
);

export default Radio;
