// React
import React, { Component } from 'react';

// Components
import PagingTable from '../../components/tables/PagingTable';
import Select from '../../components/select/Select';

// API
import ProjectsApi from '../../api/projects';

class MyActionItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openActionItems: null,
            actionItemStatusOptions: ['Open', 'Closed'],
            statusFilterValue: {
                label: 'Open',
                value: 1,
            },
            statusFilterOptions: [
                {
                    label: 'All',
                    value: 'All',
                },
                {
                    label: 'Open',
                    value: 'Open',
                },
                {
                    label: 'Closed',
                    value: 'Closed',
                },
            ],
            columns: [
                {
                    headerName: 'Date Assigned',
                    field: 'date_assigned',
                    type: 'date',
                },
                {
                    headerName: 'Assigned By',
                    field: 'assigner',
                    type: 'shortname',
                },
                {
                    headerName: 'Project Name',
                    field: 'project_name',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'project_id',
                    },
                    type: 'mediumtext',
                },
                {
                    headerName: 'Action Item Description/Response',
                    field: 'description',
                    type: 'longtext',
                },
                {
                    headerName: 'Due Date',
                    field: 'due_date',
                    type: 'date',
                },
                {
                    headerName: 'Open/Closed',
                    field: 'status',
                    type: 'status',
                },
            ],
        };
    }

    componentDidMount = async () => {
        const { data: openActionItems } = await ProjectsApi.getUserOpenActionItem();

        this.setState({ openActionItems });
    };

    render() {
        if (!this.state.openActionItems) return <div>loading...</div>;

        const { actionItemStatusOptions, openActionItems, columns, statusFilterValue, statusFilterOptions } =
            this.state;

        openActionItems.forEach((item) => {
            if (item.open) {
                item.status = actionItemStatusOptions[0];
            } else {
                item.status = actionItemStatusOptions[1];
            }
        });

        let filteredActionItems;
        if (statusFilterValue.label === 'All') {
            filteredActionItems = openActionItems;
        } else {
            filteredActionItems = openActionItems.filter((item) => item.status === statusFilterValue.label);
        }
        filteredActionItems.sort((a, b) => {
            if (a.date_assigned === null) {
                return -1;
            }
            if (b.date_assigned === null) {
                return 0;
            }
            let result = new Date(a.date_assigned) - new Date(b.date_assigned);
            return result;
        });

        return (
            <div>
                <Select
                    name="statusFilter"
                    options={statusFilterOptions}
                    value={statusFilterValue}
                    placeholder="Filter by status..."
                    className="Overview-page-space"
                    onChange={(event) => {
                        this.setState({ statusFilterValue: event });
                    }}
                />
                <PagingTable
                    columns={columns}
                    data={filteredActionItems}
                    filter={true}
                    sortable={true}
                    tableClassName="Action-items-table"
                />
            </div>
        );
    }
}

export default MyActionItems;
