import React, { useState, useEffect, useRef } from 'react';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import { confirm } from '../components/modals/ConfirmModal';
import errorToast from '../components/messages/ErrorMessage';
import ProjectsApi from '../api/projects';
import AgreementInvoicesApi from '../api/agreementInvoices';
import EstimatedBudgetItemTable from './estimatedBudget/estimatedBudgetItemsTable';
import useFunctionAsState from '../hooks/useFunctionAsState';
import EstimatedBudgetItemForm from './estimatedBudget/estimatedBudgetItemForm';
import ErgoMoney from '../utils/ErgoMoney';

const EstimatedBudgetPage = (props) => {
    const [project, setProject] = useState(null);
    const [budgetItemToEdit, setBudgetItemToEdit] = useState(null);
    const [exportDataAsCsv, setExportDataAsCsv] = useFunctionAsState(null);
    const [budgetSummaryRows, setBudgetSummaryRows] = useState([]);
    const [budgetItems, setBudgetItems] = useState([]);
    const [projectFinancialSummary, setProjectFinancialSummary] = useState(null);
    const [budgetItemToDelete, setBudgetItemToDelete] = useState(null);
    const [budgetItemToAdd, setBudgetItemToAdd] = useState(null);
    const previousBudgetItemToEdit = useRef(budgetItemToEdit);

    const createAmountAvailableToProjectRow = (financials) => ({
        category: '',
        item: 'Amount Available to Project',
        contract: '',
        nte: null,
        expense: new ErgoMoney(financials.amountAvailableToProject).getStringAmount(),
        notes: '',
    });

    const createUnbudgetedRow = (financials) => ({
        category: '',
        item: 'Unbudgeted',
        contract: '',
        nte: new ErgoMoney(financials.unbudgeted).getStringAmount(),
        expense: null,
        notes: '',
    });

    const createTotalExpensesRow = (financials) => ({
        category: '',
        item: 'Total Expended',
        contract: '',
        nte: null,
        expense: financials.totalExpended,
        notes: '',
    });

    const createTotalCCExpensesRow = (financials) => ({
        category: '',
        item: 'Total CC Expenses',
        contract: '',
        nte: null,
        expense: financials.totalCCExpenses,
        notes: '',
    });

    const createTotalInvoiceExpensesRow = (financials) => ({
        category: '',
        item: 'Total Invoice Expenses',
        contract: '',
        nte: null,
        expense: financials.totalInvoiceExpenses,
        notes: '',
    });

    const createTotalPlannedExpensesRow = (plannedBudgetItems) => {
        const totalRow = {
            category: '',
            item: 'Total Planned Budget (NTE)',
            contract: '',
            nte: 0,
            expense: 0,
            notes: '',
        };

        for (const budgetItem of plannedBudgetItems) {
            totalRow.nte = new ErgoMoney(totalRow.nte).add(budgetItem.nte).getNumberAmount();
            totalRow.expense = new ErgoMoney(totalRow.expense).add(budgetItem.expense).getNumberAmount();
        }

        return totalRow;
    };

    const createSummaryRows = (budgetItems, financials) => {
        const summaryRows = [];
        summaryRows.push(createAmountAvailableToProjectRow(financials));
        summaryRows.push(createTotalPlannedExpensesRow(budgetItems));
        summaryRows.push(createUnbudgetedRow(financials));
        summaryRows.push(createTotalCCExpensesRow(financials));
        summaryRows.push(createTotalInvoiceExpensesRow(financials));
        summaryRows.push(createTotalExpensesRow(financials));
        return summaryRows;
    };

    useEffect(() => {
        if (budgetItems && projectFinancialSummary) {
            const budgetSummaryRows = createSummaryRows(budgetItems, projectFinancialSummary);
            setBudgetSummaryRows(budgetSummaryRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetItems, projectFinancialSummary]);

    const throwIfProjectIsArchived = (project) => {
        if (project) {
            const archivedProject = project.isArchived;
            if (archivedProject) {
                throw Error('Project is archived!');
            }
        }
    };

    const hoistOnTableReady = (tableParams) => {
        try {
            if (tableParams.exportDataAsCsv && typeof tableParams.exportDataAsCsv === 'function') {
                setExportDataAsCsv(tableParams.exportDataAsCsv);
            }
        } catch (error) {
            errorToast(error);
        }
    };

    const resetBudgetItemToEdit = () => {
        setBudgetItemToEdit(null);
    };

    useEffect(() => {
        const deleteBudgetItem = async (budgetItem) => {
            try {
                const confirmed = await confirm({
                    text: 'Are you sure you want to delete the budget item?',
                });

                throwIfProjectIsArchived(project.current);

                if (confirmed) {
                    const budgetItemId = budgetItem.id;
                    const projectId = budgetItem.project_id;
                    await ProjectsApi.removeBudgetItem(projectId, budgetItemId);
                    const updatedBudgetItems = budgetItems.filter((budgetItem) => budgetItem.id !== budgetItemId);
                    setBudgetItems(updatedBudgetItems);
                }
            } catch (error) {
                errorToast(error);
            }
        };

        if (budgetItemToDelete) {
            deleteBudgetItem(budgetItemToDelete);
            setBudgetItemToDelete(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetItemToDelete]);

    const hoistBudgetItemToDelete = (budgetItem) => {
        setBudgetItemToDelete(budgetItem);
    };

    useEffect(() => {
        const addBudgetItem = async (budgetItem) => {
            try {
                const projectId = project.id;
                if (!budgetItem.expense) {
                    delete budgetItem.expense;
                }
                const { data: newBudgetItem } = await ProjectsApi.createBudgetItem(projectId, budgetItem);

                const newBudgetItems = [...budgetItems];
                newBudgetItems.unshift(newBudgetItem);

                setBudgetItems(newBudgetItems);
            } catch (error) {
                errorToast(error);
            }
        };

        if (budgetItemToAdd) {
            addBudgetItem(budgetItemToAdd);
            setBudgetItemToAdd(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetItemToAdd]);

    const hoistBudgetItemToAdd = (budgetItem) => {
        setBudgetItemToAdd(budgetItem);
    };

    useEffect(() => {
        const editBudgetItem = async (budgetItem) => {
            try {
                const budgetItemId = budgetItem.id;
                delete budgetItem.id;
                const projectId = project.id;
                const { data: updatedBudgetItem } = await ProjectsApi.updateBudgetItem(
                    projectId,
                    budgetItemId,
                    budgetItem
                );
                const newBudgetItems = [...budgetItems];
                newBudgetItems.unshift(updatedBudgetItem);

                setBudgetItems(newBudgetItems);
                setBudgetItemToEdit(null);
            } catch (error) {
                errorToast(error);
            }
        };
        if (budgetItemToEdit && previousBudgetItemToEdit.current == null) {
            const updatedBudgetItems = budgetItems.filter((budgetItem) => budgetItemToEdit.id !== budgetItem.id);
            setBudgetItems(updatedBudgetItems);
        } else if (budgetItemToEdit) {
            editBudgetItem(budgetItemToEdit);
        }

        if (budgetItemToEdit !== previousBudgetItemToEdit.current) {
            previousBudgetItemToEdit.current = budgetItemToEdit;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetItemToEdit]);

    const hoistBudgetItemToEdit = (budgetItem) => {
        setBudgetItemToEdit(budgetItem);
    };

    const calculateTotalInvoiceExpenses = (invoices) => {
        const total = new ErgoMoney();
        if (invoices) {
            for (const invoice of invoices) {
                if (invoice.transaction.status !== 'Returned' && invoice.transaction.status !== 'Rejected') {
                    total.add(invoice.transaction.amount);
                }
            }
        }
        return total.getNumberAmount();
    };

    useEffect(() => {
        try {
            const fetch = async () => {
                const projectId = props.match.params.id;

                const { data: project } = await ProjectsApi.fetchProject(projectId);
                let { data: budgetItems } = await ProjectsApi.fetchBudgetItems(projectId);
                if (!budgetItems) {
                    budgetItems = [];
                }

                const { data: projectFinancialSummary } = await ProjectsApi.fetchProjectFinancialInformation(projectId);
                const { data: invoices } = await AgreementInvoicesApi.fetchInvoicesByProjectID(projectId);

                const invoicesTotal = calculateTotalInvoiceExpenses(invoices);

                const financials = {
                    amountAvailableToProject: projectFinancialSummary.total_available,
                    unbudgeted: projectFinancialSummary.unbudgeted,
                    totalExpended: projectFinancialSummary.total_expended,
                    totalInvoiceExpenses: invoicesTotal,
                    totalCCExpenses: new ErgoMoney(projectFinancialSummary.total_expended)
                        .subtract(invoicesTotal)
                        .getNumberAmount(),
                };

                setProject(project);
                setBudgetItems(budgetItems);
                setProjectFinancialSummary(financials);
            };
            fetch();
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <EstimatedBudgetItemForm
                budgetItemToEdit={budgetItemToEdit}
                hoistBudgetItemToEdit={hoistBudgetItemToEdit}
                hoistBudgetItemToAdd={hoistBudgetItemToAdd}
                resetBudgetItemToEdit={resetBudgetItemToEdit}
            />
            <EstimatedBudgetItemTable
                budgetItems={budgetItems}
                budgetSummaryRows={budgetSummaryRows}
                hoistOnTableReady={hoistOnTableReady}
                hoistBudgetItemToDelete={hoistBudgetItemToDelete}
                hoistBudgetItemToEdit={hoistBudgetItemToEdit}
            />
            <div className="Form__buttons Floating-button">
                <ExportCSVButton fileName="financial-summary" exportFunction={exportDataAsCsv} />
            </div>
        </>
    );
};

export default EstimatedBudgetPage;
