import React from 'react';
import errorToast from '../../components/messages/ErrorMessage';
import EditingTable from '../../components/tables/EditingTable';
import AgreementInvoicesApi from '../../api/agreementInvoices';
import ErgoMoney from '../../utils/ErgoMoney';

const AgreementInvoicesTable = ({ invoices, hoistSelectedNodes, onTableReady }) => {
    const handleDelete = async (event) => {
        try {
            const {
                data: { id },
                api,
                data,
            } = event;
            await AgreementInvoicesApi.deleteInvoiceByID(id);
            api.applyTransaction({ remove: [data] });
        } catch (error) {
            errorToast(error);
        }
    };

    const hoistInvoiceForEdit = async (data) => {
        try {
            const {
                id,
                bank_reference_number,
                notes,
                transaction: { amount },
                performance_period_end_date,
            } = data;
            const attributes = {
                id,
                bank_reference_number,
                notes,
                amount: new ErgoMoney(amount).getNumberAmount(),
                performance_period_end_date,
            };
            await AgreementInvoicesApi.editInvoice(id, attributes);
        } catch (error) {
            errorToast(error);
        }
    };

    const columns = [
        { headerName: '', type: 'checkbox', editable: false },
        {
            headerName: 'Vendor',
            field: 'agreement.vendor_name',
            type: 'mediumname',
            editable: false,
        },
        {
            headerName: 'Vendor Invoice No.',
            field: 'vendor_invoice_number',
            type: 'idNum',
            editable: false,
        },
        {
            headerName: 'Agreement No.',
            field: 'agreement.number',
            type: 'mediumname',
            editable: false,
        },
        {
            headerName: 'Project No.',
            field: 'project.number',
            cellRenderer: 'LinkRenderer',
            cellRendererParams: {
                routeBase: '/projects/',
                routeKeyField: 'project_id',
            },
            type: 'shortname',
            editable: false,
        },
        {
            headerName: 'CPO No.',
            field: 'project.cpo_number',
            editable: false,
            type: 'shortname',
        },
        {
            headerName: 'Bank Reference',
            field: 'bank_reference_number',
            type: 'shortname',
        },
        {
            headerName: 'Last Day of Performance Period',
            field: 'performance_period_end_date',
            type: 'date',
        },
        {
            headerName: 'Amount',
            field: 'transaction.amount',
            type: 'shortmoney',
        },
        {
            headerName: 'Status',
            field: 'transaction.status',
            sort: 'desc',
            type: 'shortname',
            editable: false,
        },
        {
            headerName: 'Link to Invoice',
            field: 'invoice_link',
            type: 'symbol',
            cellRenderer: 'ExternalLinkSymbolRenderer',
            editable: false,
        },
        {
            headerName: 'Date Paid',
            field: 'paid_date',
            type: 'date',
            editable: false,
        },
        {
            headerName: 'Notes',
            field: 'notes',
            type: 'symbol',
            cellRenderer: 'NotesSymbolRenderer',
        },
        {
            headerName: 'Initiated By',
            field: 'transaction.initiator.name',
            type: 'name',
            editable: false,
        },
        {
            headerName: 'Initiated On',
            field: 'transaction.created_on',
            type: 'date',
            editable: false,
        },
        {
            headerName: 'Last Update By',
            field: 'transaction.last_updater.name',
            type: 'name',
            editable: false,
        },
        {
            headerName: 'Updated On',
            field: 'transaction.updated_on',
            type: 'date',
            editable: false,
        },
        {
            headerName: 'Edit',
            field: 'edit',
            type: 'symbol',
            editable: false,
            cellRenderer: 'EditSymbolRenderer',
        },
        {
            headerName: 'Del.',
            field: 'delete',
            type: 'symbol',
            editable: false,
            cellRenderer: 'TrashSymbolRenderer',
            onCellClicked: handleDelete,
        },
    ];

    const handleSelectionChange = (event) => {
        const selectedNodes = event.api.getSelectedNodes();
        hoistSelectedNodes(selectedNodes);
    };

    return (
        <div className="Table-styling">
            <EditingTable
                data={invoices}
                columns={columns}
                pagingProp={true}
                selectionProp={true}
                selectionChanged={handleSelectionChange}
                cellEditingStopped={hoistInvoiceForEdit}
                onTableReady={onTableReady}
                tableClassName="Agreement-invoice-table"
            />
        </div>
    );
};

export default AgreementInvoicesTable;
