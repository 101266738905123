import React from 'react';
import { Route } from 'react-router-dom';
import EstimatedBudgetPage from './EstimatedBudgetPage';
import FinancialSummaryPage from './FinancialSummaryPage';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';

const BudgetAndFinancialPage = (props) => (
    <>
        <SubSubNav>
            <SubSubNavLink to={props.match.url} exact>
                Estimated Budget
            </SubSubNavLink>
            <SubSubNavLink to={props.match.url + '/summary'}>Financial Summary</SubSubNavLink>
        </SubSubNav>
        <Route path={props.match.path} exact component={EstimatedBudgetPage} />
        <Route path={props.match.path + '/summary'} component={FinancialSummaryPage} />
    </>
);

export default BudgetAndFinancialPage;
