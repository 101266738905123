import React from 'react';
import PagingTable from '../../../../components/tables/PagingTable';
import errorToast from '../../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../../components/buttons/ExportCSVButton';
import ProjectsApi from '../../../../api/projects';

class AllProjectsOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            columns: [
                {
                    headerName: 'Project Number',
                    field: 'number',
                    sort: 'desc',
                    type: 'idNum',
                },
                {
                    headerName: 'Project Name',
                    field: 'name',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'id',
                    },
                    type: 'name',
                },
                {
                    headerName: 'PIA',
                    field: 'pia.name',
                    type: 'shortnumber',
                },
                {
                    headerName: 'CPO',
                    field: 'cpo.number',
                    type: 'mediumnumber',
                },
                {
                    headerName: 'Updated Date',
                    field: 'updated_on',
                    type: 'date',
                },
                {
                    headerName: 'Last Status Update',
                    field: 'last_status_update',
                    type: 'name',
                },
                {
                    headerName: 'WBI Contact',
                    field: 'wbi_contact',
                    type: 'shortname',
                },
                {
                    headerName: 'Customer Contact',
                    field: 'customer_name',
                    type: 'mediumname',
                },
                {
                    headerName: 'Office Symbol',
                    field: 'office_symbol.name',
                    type: 'status',
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    type: 'shortname',
                },
                {
                    headerName: 'Background',
                    field: 'background',
                    type: 'name',
                },
                {
                    headerName: 'Brief Overview',
                    field: 'overview',
                    type: 'name',
                },
            ],
        };
        this.onTableReady = this.onTableReady.bind(this);
        this.gridApi = null;
        this.exportDataAsCsv = null;
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    componentDidMount = async () => {
        try {
            const { data: projects } = await ProjectsApi.fetchAllProjectsOverview();

            projects.forEach((project) => {
                project.users.forEach((user) => {
                    if (project.wbi_lead_user_id === user.id) {
                        project.wbi_contact = user.name;
                    }
                });
            });

            this.setState({ projects });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    render() {
        const { columns, projects } = this.state;
        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="all-projects-overview" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div>
                    <PagingTable
                        columns={columns}
                        data={projects}
                        onTableReady={this.onTableReady}
                        sortable={true}
                        filter={true}
                        sizeToFit={true}
                        tableClassName="Projects-table"
                    />
                </div>
            </div>
        );
    }
}

export default AllProjectsOverview;
