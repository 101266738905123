import React from 'react';
import PageHeader from '../components/heading/PageHeader';
import CreditCardTable from './creditCardPage/CreditCardTable';
import FinanceSubNav from './FinanceSubNav';

const CreditCardPage = () => (
    <div>
        <PageHeader title="Credit Card" />
        <FinanceSubNav />
        <CreditCardTable isProject={true} />
        <CreditCardTable isProject={false} />
    </div>
);

export default CreditCardPage;
