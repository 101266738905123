import React from 'react';
import LabeledField from '../../components/forms/LabeledField';

export default function AddVendorDocuments({ errors, touched, values }) {
    return (
        <>
            <LabeledField
                label="W-9"
                name="w9"
                component="input"
                value={values.w9}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
            <LabeledField
                label="Financial Audit"
                name="financial_audit"
                component="input"
                value={values.financial_audit}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
            <LabeledField
                label="General Audit"
                name="general_audit"
                component="input"
                value={values.general_audit}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left"
            />
        </>
    );
}
