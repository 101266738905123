import React from 'react';
import ReportSubnav from './ReportsSubNav';
import PageHeader from '../components/heading/PageHeader';
import QuarterlyAvtivityReport from './APTabs/QuarterlyAvtivityReport';
import AllProjects from './APTabs/AllProjects';
import WeeklyActivityReport from './APTabs/WeeklyActivityReport';

class AllProjectsPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleSwitch = this.handleSwitch.bind(this);

        this.state = {
            selectedTab: '1',
        };
    }

    handleSwitch(e) {
        if (e.target.value === '1') {
            this.setState({ selectedTab: '1' });
        } else if (e.target.value === '2') {
            this.setState({ selectedTab: '2' });
        } else {
            this.setState({ selectedTab: '3' });
        }
    }

    render() {
        const { selectedTab } = this.state;

        let form;
        let firstTab;
        let secondTab;
        let thirdTab;
        switch (selectedTab) {
            case '1':
                form = <AllProjects />;
                firstTab = true;
                secondTab = false;
                thirdTab = false;
                break;

            case '2':
                form = <WeeklyActivityReport />;
                secondTab = true;
                firstTab = false;
                thirdTab = false;
                break;

            case '3':
                form = <QuarterlyAvtivityReport />;
                thirdTab = true;
                firstTab = false;
                secondTab = false;
                break;

            default:
                form = <AllProjects />;
                firstTab = true;
                secondTab = false;
                thirdTab = false;
        }

        return (
            <div>
                <PageHeader title="Reports" />
                <ReportSubnav />
                <div className="Wide-single-layout">
                    <div className="ApSubNav">
                        <ul>
                            <li>
                                <button
                                    onClick={this.handleSwitch}
                                    value="1"
                                    className={firstTab ? 'Selected-tab' : null}
                                >
                                    All Projects{' '}
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={this.handleSwitch}
                                    value="2"
                                    className={secondTab ? 'Selected-tab' : null}
                                >
                                    Weekly Activity Reports
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={this.handleSwitch}
                                    value="3"
                                    className={thirdTab ? 'Selected-tab' : null}
                                >
                                    Quarterly Activity Reports
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                {form}
            </div>
        );
    }
}

export default AllProjectsPage;
