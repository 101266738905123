import React from 'react';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import LabeledField from '../components/forms/LabeledField';
import ProjectSelect from '../components/select/ProjectSelect';

export const BasicProjectReportForm = (props) => (
    <div className="Reports-companion-form">
        <Formik
            initialValues={{
                name: props.name,
                number: props.num,
            }}
            onSubmit={props.handleSubmit}
        >
            {({ values, isSubmitting }) => (
                <Form>
                    <ProjectSelect
                        onChange={(e) => {
                            props.onChange(e, values);
                        }}
                        selected={props.selectedProject}
                    />
                    <LabeledField label="Project Number" name="number" type="text" readOnly={true} />

                    <div className="Form__buttons--Reversed Buttons_format">
                        <button type="submit" disabled={isSubmitting || !props.selectedProject}>
                            Run Report
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
);

export const FundsTransferSumForm = (props) => (
    <div className="Reports-companion-form">
        <Formik
            initialValues={{
                name: props.name,
                number: props.num,
            }}
            onSubmit={props.handleSubmit}
        >
            {({ values, isSubmitting }) => (
                <Form>
                    <ProjectSelect
                        onChange={(e) => {
                            props.onChange(e, values);
                        }}
                        selected={props.selectedProject}
                    />
                    <LabeledField label="Project Number" name="number" type="text" readOnly={true} />
                    <div className="Form__buttons--Reversed Buttons_format">
                        <button
                            type="submit"
                            disabled={isSubmitting || !props.selectedProject}
                            style={{ gridColumnEnd: -1 }}
                        >
                            Run Report
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
);
export const ProjectManagerForm = (props) => (
    <div className="Reports-companion-form">
        <Formik
            initialValues={
                {
                    // date: '',
                }
            }
            onSubmit={props.handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div className="Form__buttons--Reversed Buttons_format">
                        <button type="submit" disabled={isSubmitting}>
                            Run Report
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
);

export const AllProjectBasic = (props) => (
    <div className="Reports-companion-form">
        <Formik initialValues={{}} onSubmit={props.onSubmit}>
            {({ isSubmitting }) => (
                <Form>
                    <div className="Form__buttons--Reversed Buttons_format">
                        <button type="submit" disabled={isSubmitting} style={{ gridColumnEnd: -1 }}>
                            Run Report
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
);

export const AllProjectMissingData = (props) => {
    let reportOptions = [];
    let reportType = props.submenu;
    let i;
    if (reportType) {
        for (i = 0; i < reportType.length; i++) {
            reportOptions.push({
                label: reportType[i].name,
                value: reportType[i].reportOutput,
            });
        }
    }
    return (
        <div className="APMD-reports-companion-form">
            <Formik initialValues={{}} onSubmit={props.onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <label className="FieldLabel">{props.label}</label>
                        <Select
                            value={props.selected}
                            options={reportOptions}
                            onChange={(e) => {
                                props.updateContent(e);
                            }}
                        />
                        <div className="Form__buttons--Reversed Buttons_format">
                            <button type="submit" disabled={isSubmitting} style={{ gridColumnEnd: -1 }}>
                                Run Report
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export const QuarterlyReportForm = (props) => (
    <div className="Reports-companion-form">
        <Formik
            initialValues={{
                startDate: '',
                endDate: '',
            }}
            onSubmit={props.handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <LabeledField label="From" name="startDate" type="date" component="Date" />
                    <LabeledField label="To" name="endDate" type="date" component="Date" />
                    <div className="Form__buttons--Reversed Buttons_format">
                        <button type="submit" disabled={isSubmitting} style={{ gridColumnEnd: -1 }}>
                            Run Report
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
);

export const WeeklyReportForm = (props) => (
    <div className="Reports-companion-form">
        <Formik
            initialValues={{
                startDate: '',
                endDate: '',
            }}
            onSubmit={props.handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <LabeledField label="From" name="startDate" type="date" component="Date" />
                    <LabeledField label="To" name="endDate" type="date" component="Date" />
                    <div className="Form__buttons--Reversed Buttons_format">
                        <button type="submit" disabled={isSubmitting} style={{ gridColumnEnd: -1 }}>
                            Run Report
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
);
