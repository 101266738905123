import React from 'react';
import Modal from './Modal';

class HelpModal extends React.Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
    }

    openModal = () => this.setState({ isOpen: true });

    closeModal = () => this.setState({ isOpen: false });

    render() {
        const { children, ...props } = this.props;
        return (
            <>
                <i className="fa fa-question-circle" onClick={this.openModal} style={{ margin: 4 }} />
                <Modal isOpen={this.state.isOpen} handleClose={this.closeModal} shouldCloseOnOverlayClick {...props}>
                    <button onClick={this.closeModal} className="align-self-end">
                        <i className="fa fa-window-close" />
                    </button>
                    {children}
                </Modal>
            </>
        );
    }
}

export default HelpModal;
