// React
import React from 'react';

// Libraries
import moment from 'moment';
import yup from '../../../yup';

// Components
import FieldArray from '../../../components/forms/FieldArray';
import FieldWithError from '../../../components/forms/FieldWithError';
import EditingTable from '../../../components/tables/EditingTable';
import errorToast from '../../../components/messages/ErrorMessage';

// API
import ProjectsApi from '../../../api/projects';

class MilestonesTable extends React.Component {
    constructor(props) {
        super(props);

        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.addRow = this.addRow.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            row: null,
            rowIndex: null,
            colKey: '',
            errorMessage: '',
        };
    }

    handleMilestones(milestones) {
        this.props.handleMilestones(milestones);
    }

    onRemoveSelected(evt) {
        const { data } = evt;
        ProjectsApi.removeMilestone(data.project_id, data.id).then(() => {
            const milestones = this.props.milestones.filter((m) => m.id !== data.id);
            this.handleMilestones(milestones);
        });
    }

    onRowValueChanged(evt) {
        const { data } = evt;
        if (moment(data.to_date) < moment(data.from_date)) {
            errorToast('There is an error in the Dates you chose please review');
            return;
        }
        if (data.completed === 'Yes' || data.completed === 'No') {
            data.completed = data.completed === 'Yes';
        }

        const attributes = {
            description: data.description,
            from_date: new Date(data.from_date),
            to_date: new Date(data.to_date),
            event_type: 'milestone',
            completed: data.completed,
            project_id: data.project_id,
        };
        ProjectsApi.updateMilestone(data.project_id, data.id, attributes)
            .then((response) => {
                if (response) {
                    this.setState({
                        row: null,
                        rowIndex: null,
                        colKey: '',
                    });
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    getRowData(evt) {
        this.setState({
            row: evt.node.data,
            rowIndex: evt.node.rowIndex,
            colKey: 'description',
        });
    }

    addRow(valueToUpdate) {
        const { projectId } = this.props;
        if (moment(valueToUpdate.to_date) < moment(valueToUpdate.from_date)) {
            errorToast('There is an error in the Dates you chose please review');
            return;
        }
        if (projectId !== 'new') {
            const attributes = {
                description: valueToUpdate.description,
                from_date: new Date(valueToUpdate.from_date),
                to_date: new Date(valueToUpdate.to_date),
                event_type: 'milestone',
                completed: valueToUpdate.completed,
            };
            ProjectsApi.addMilestone(projectId, attributes)
                .then((response) => {
                    const milestones = this.props.milestones.map((m) => m);
                    const { data } = response;
                    const newMileStone = {
                        description: data.description,
                        from_date: data.from_date,
                        to_date: data.to_date,
                        id: data.id,
                        event_type: 'milestone',
                        project_id: data.project_id,
                        completed: data.completed,
                    };
                    milestones.push(newMileStone);
                    this.handleMilestones(milestones);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        const { values, errors, touched, isEditing, isNew } = this.props;
        const { rowIndex, colKey } = this.state;
        let milestones = this.props.milestones;
        if (milestones) {
            milestones.sort((a, b) => new Date(a.from_date) - new Date(b.from_date));
        }

        return (
            <FieldArray
                label="Milestones"
                name="milestones"
                initialValues={{ description: '', from_date: '', to_date: '' }}
                validationSchema={yup.object().shape({
                    description: yup.string().required(),
                    from_date: yup.date().required(),
                    to_date: yup.date().required(),
                })}
                onRemoveSelected={this.onRemoveSelected}
                addRow={this.addRow}
                values={values}
                errors={errors}
                touched={touched}
                readOnly={!isEditing}
                renderArray={() => (
                    <EditingTable
                        className={isNew ? 'Visibile' : 'Hide'}
                        data={milestones}
                        columns={[
                            {
                                headerName: 'Milestones',
                                field: 'description',
                                editable: true,
                                type: 'mediumtext',
                            },
                            {
                                headerName: 'From',
                                field: 'from_date',
                                type: 'date',
                                editable: true,
                            },
                            {
                                headerName: 'To',
                                field: 'to_date',
                                type: 'date',
                                editable: true,
                            },
                            {
                                headerName: 'Complete',
                                field: 'completed',
                                type: 'shortnumber',
                                cellRenderer: 'BooleanRenderer',
                                editable: true,
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {
                                    values: ['No', 'Yes'],
                                },
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Edit',
                                field: 'edit',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'EditSymbolRenderer',
                                onCellClicked: this.getRowData,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Del.',
                                field: 'delete',
                                type: 'symbol',
                                editable: false,
                                cellRenderer: 'TrashSymbolRenderer',
                                onCellClicked: this.onRemoveSelected,
                            },
                        ]}
                        rowIndex={rowIndex}
                        colKey={colKey}
                        rowValueChanged={this.onRowValueChanged}
                        sizeToFit={!isEditing}
                    />
                )}
                renderFields={({ errors, touched }) => (
                    <>
                        <FieldWithError
                            className="Text-area-space"
                            name="description"
                            placeholder="Add item..."
                            component="textarea"
                            errors={errors}
                            touched={touched}
                        />
                        <div className="Milestone-date-range-box">
                            <label>
                                From
                                <FieldWithError
                                    name="from_date"
                                    type="date"
                                    component="Date"
                                    errors={errors}
                                    touched={touched}
                                />
                            </label>
                            <label>
                                To
                                <FieldWithError
                                    name="to_date"
                                    type="date"
                                    component="Date"
                                    errors={errors}
                                    touched={touched}
                                />
                            </label>
                        </div>
                        <FieldWithError
                            name="completed"
                            component="Checkbox"
                            label="Completed?"
                            errors={errors}
                            touched={touched}
                        />
                    </>
                )}
            />
        );
    }
}

export default MilestonesTable;
