import React from 'react';

const ExternalLinkSymbolRenderer = ({ value }) => {
    if (value) {
        let link;
        if (value.includes('http://') || value.includes('https://')) {
            link = value;
        } else {
            link = 'http://' + value;
        }

        return (
            <div className="ExternalLinkSymbol">
                <a href={link} target="_blank" rel="noopener noreferrer" aria-label="Opens in new tab">
                    <i className="fa fa-external-link" />
                </a>
            </div>
        );
    }

    return null;
};

export default ExternalLinkSymbolRenderer;
