import React from 'react';
import { FieldArray as BasicFieldArray } from 'formik';
import SignatureForm from './SignatureForm';

export const getInitialSignatureValues = () => ({
    name: '',
    title: '',
    organization: '',
    signed: false,
    link_to_db: '',
    new_signature: true,
    user_id: '',
});

export const getInitialProjectSignatures = () => [getInitialSignatureValues()];

const SignaturesForm = ({
    signaturesReference,
    name,
    values,
    readOnly,
    setFieldValue,
    editSignature,
    suppressNewSignatures,
    users,
    ...props
}) => (
    <div {...props}>
        <BasicFieldArray
            name={name}
            render={(arrayHelpers) => (
                <>
                    {values[name].map((signature, index) => (
                        <SignatureForm
                            users={users}
                            initialValues={signature}
                            readOnly={readOnly}
                            onSubmit={(values) => {
                                if (users && values.name.label) {
                                    let userComplete = values.name;
                                    values.name = userComplete.label;
                                    //values.id = userComplete.id;
                                    values.user_id = userComplete.value;
                                    values.organization = 'Wright Brothers Institute';
                                }
                                let newValues = { ...values };
                                if (newValues.link_to_db && !newValues.signed) {
                                    newValues.signed = true;
                                    newValues.new_signature = false;
                                }
                                if (signaturesReference && signaturesReference[index]) {
                                    newValues.id = signaturesReference[index].id;
                                }
                                if (editSignature && typeof editSignature === 'function') {
                                    editSignature(newValues);
                                }
                                setFieldValue(`${name}.${index}`, newValues);
                            }}
                            descriptor={signature.organization === 'Wright Brothers Institute' ? 'WBI' : 'Client'}
                            key={signature.name + index}
                        />
                    ))}
                    <div>
                        {!readOnly && !suppressNewSignatures && (
                            <button
                                className="End-button"
                                type="button"
                                onClick={() => arrayHelpers.push(getInitialSignatureValues())}
                            >
                                Add Signature Line
                            </button>
                        )}
                    </div>
                </>
            )}
        />
    </div>
);

export default SignaturesForm;
