import React from 'react';
import { Link } from 'react-router-dom';

import ResetPasswordForm from './ResetConfirmForm';

const ResetConfirmPage = () => (
    <main className="AccountPage__content">
        <div className="page__subnav">
            <Link to="/account/reset">&laquo; Back to Reset Request</Link>
        </div>

        <div className="page__body">
            <h2>Reset Your Password</h2>
            <p className="lead">
                Please provide a new password for your account. <br />
                Make sure to check your email for your confirmation code.
            </p>
            <ResetPasswordForm />
        </div>
    </main>
);

export default ResetConfirmPage;
