import React from 'react';
import DraggingTable from '../../components/tables/DraggingTable';
import ProjectsApi from '../../api/projects';

class GoalsTable extends React.Component {
    constructor(props) {
        super(props);

        this.onRowDragEnter = this.onRowDragEnter.bind(this);
        this.onRowDragEnd = this.onRowDragEnd.bind(this);

        this.state = {
            rowData: [],
            row: null,
            rowIndex: null,
            project: null,
        };
    }

    async componentDidMount() {
        const projectId = this.props.project.id;
        const { data: project } = await ProjectsApi.fetchProject(projectId);
        this.setState({
            rowData: project.key_goals,
            project,
        });
    }

    onRowDragEnter = (event) => {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
        });
    };

    onRowDragEnd = (event) => {
        const { rowData } = this.state;
        let newIndex = event.node.rowIndex;
        let rowIndex = this.state.rowIndex;
        let movingItem = event.node.data;
        movingItem.order = newIndex;
        let secondPartArray = rowData.filter((_, i) => rowIndex !== i);
        let firstPartArray = secondPartArray.splice(0, newIndex);
        firstPartArray.push(movingItem);

        let newArray = firstPartArray.concat(secondPartArray);
        for (let i = 0; i < newArray.length; i++) {
            newArray[i].order = i;
        }

        ProjectsApi.updateProjectDataPageTables(event.node.data.project_id, 'Goals', newArray)
            .then((response) => {
                this.setState({
                    rowData: response.data,
                });
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    };

    render() {
        if (!this.props.project) return <div>loading...</div>;
        const { rowData } = this.state;

        return (
            <div className="Open-action-items-table">
                <DraggingTable
                    data={rowData}
                    columns={[
                        {
                            headerName: 'Goals',
                            field: 'description',
                            type: 'longtext',
                            rowDrag: true,
                            editable: true,
                        },
                        {
                            headerName: 'Goal Attainment %',
                            field: 'attainment',
                            width: 75,
                            editable: true,
                        },
                        {
                            headerName: 'Goal Reached',
                            field: 'reached',
                            type: 'status',
                            cellRenderer: 'BooleanRenderer',
                            editable: false,
                        },
                    ]}
                    drag={true}
                    rowDragEnd={this.onRowDragEnd}
                    rowDragEnter={this.onRowDragEnter}
                />
            </div>
        );
    }
}

export default GoalsTable;
