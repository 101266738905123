import React from 'react';

const UserMessage = (props) => {
    let error;
    let success;
    let info = null;

    if (props.error) {
        error = <p className="Message-error">{props.error}</p>;
    }

    if (props.success) {
        success = <p className="Message-success">{props.success}</p>;
    }

    if (props.info) {
        info = <p className="Message-info">{props.info}</p>;
    }

    return (
        <span className="Message">
            {info} {success} {error}
        </span>
    );
};

export default UserMessage;
