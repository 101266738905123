import React, { useEffect, useState, Fragment } from 'react';
import { Formik, Form, FieldArray as BasicFieldArray } from 'formik';
import yup from '../../yup';

import SectionHeading from '../../components/heading/SectionHeading';
import errorToast from '../../components/messages/ErrorMessage';
import Modal from '../../components/modals/Modal';
import GridSection from '../../components/section/GridSection';

import AddAgreementInfo from './AddAgreementInfo';
import AddAgreementDocs from './AddAgreementDocs';
import AddAgreementProjects from './AddAgreementProjects';
import AddGeneralDocuments from '../AddGeneralDocuments';
import ExportCSVButton from '../../components/buttons/ExportCSVButton';
import ErgoMoney from '../../utils/ErgoMoney';

import AgreementHistoryTable from '../AgreementHistoryTable';

import AgreementsApi from '../../api/agreements';
import AgreementMessageModal from './AgreementMessageModal';

export default function EditAgreementModal(props) {
    const {
        closeModal,
        agreement,
        agreementNumberList,
        vendor,
        closeAgreement,
        hoistEditTableParams,
        exportDataAsCsv,
    } = props;
    const [openModal, setOpenModal] = useState(false);
    const [modalMessageOpen, setMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [amendmentNumberList, setAmendmentNumberList] = useState([]);

    useEffect(() => {
        if (agreement.amendments) {
            if (Object.keys(agreement.amendments).length !== 0) {
                let amendmentsNumber = [];
                let amendments = agreement.amendments;
                amendments.forEach((amend) => {
                    if (agreement.number !== amend.number) {
                        amendmentsNumber.push(amend.number);
                    }
                });
                setAmendmentNumberList(amendmentsNumber);
            }
        }
    }, [agreement]);

    const openAmendAgreement = () => {
        setMessage('While editing an agreement you can not amend an agreement');
        setMessageModalOpen(true);
    };

    const closeAmendAgreement = () => {
        setMessageModalOpen(false);
    };

    const createDefaultDocsValues = (newDoc) => {
        //change
        if (!newDoc && agreement.documents) {
            let documents = [];
            // eslint-disable-next-line array-callback-return
            agreement.documents.map((doc) => {
                documents.push({
                    name: doc.name || '',
                    doc_link: doc.doc_link || '',
                });
            });

            return documents;
        }

        if (newDoc) {
            return {
                name: '',
                doc_link: '',
            };
        }

        return [];
    };

    const createDefaultProjectValues = (newProject) => {
        if (!newProject && agreement.projects) {
            let projects = [];
            // eslint-disable-next-line array-callback-return
            agreement.projects.map((proj) => {
                projects.push({
                    project_name: proj.project_name || '',
                    project_number: proj.project_number || '',
                    id: proj.project_id || '',
                    nte: proj.nte || '',
                });
            });

            return projects;
        }

        if (newProject) {
            return {
                id: '',
                project_name: '',
                project_number: '',
                nte: '',
            };
        }

        return [
            {
                id: '',
                project_name: '',
                project_number: '',
                nte: '',
            },
        ];
    };

    const createDefaultFormValues = (newProject) => {
        const defaultProjects = createDefaultProjectValues(newProject);
        const defaultDocs = createDefaultDocsValues(newProject);
        return {
            number: agreement.number || '',
            description: agreement.description || '',
            type: agreement.type || '',
            nte: agreement.nte || '',
            start_date: agreement.start_date || null,
            end_date: agreement.end_date || null,
            agreement_link: agreement.agreement_link || '',
            bids_package: agreement.bids_package || '',
            sole_source: agreement.sole_source || '',
            projects: defaultProjects,
            documents: defaultDocs,
        };
    };

    const editSubmitAgreement = (values) => {
        let newProject = [];
        let projects = values.projects;
        let projectTotal = new ErgoMoney();
        let nte = parseFloat(values.nte);
        projects.forEach((p) => {
            projectTotal.add(p.nte);
            if (p.id !== '') {
                if (projectTotal.lessThanOrEqualTo(nte)) {
                    newProject.push(p);
                } else {
                    errorToast('A project NTE violated total NTE');
                }
            }
        });

        return {
            number: values.number,
            description: values.description,
            type: values.type,
            nte: values.nte,
            start_date: values.start_date,
            end_date: values.end_date,
            agreement_link: values.agreement_link,
            bids_package: values.bids_package,
            sole_source: values.sole_source,
            projects: newProject,
            documents: values.documents,
        };
    };

    const createYupSchema = () => {
        let currentProjectTotal = 0;
        let projectNameList = [];
        let currentNTE = 0;
        return yup.object().shape({
            number: yup
                .string()
                .required('Enter Agreement Number')
                .test('number', 'Agreement Number Entered Already in Use', (value) => {
                    let matchFound = false;
                    agreementNumberList.forEach((number) => {
                        if (value === number && agreement.number !== value) {
                            matchFound = true;
                        }
                    });
                    amendmentNumberList.forEach((amendNumber) => {
                        if (value === amendNumber) {
                            matchFound = true;
                        }
                    });
                    if (matchFound) {
                        return false;
                    }
                    return true;
                }),
            description: yup.string().required('Enter a Description'),
            type: yup.string().required('Select Agreement Type'),
            nte: yup
                .string()
                .test('totalNTE', (value) => {
                    currentNTE = value;
                    return true;
                })
                .required('Enter NTE amount'),
            start_date: yup.date().required('Enter Start Date'),
            end_date: yup.date().required('Enter End Date'),
            bids_package: yup.string().url('Must a valid url'),
            sole_source: yup.string().url('Must a valid url'),
            projects: yup
                .array()
                .of(
                    yup.object().shape({
                        project_name: yup
                            .string()
                            .test('uniqueProject', 'Select an unique Project', (value) => {
                                let count = 0;
                                projectNameList.forEach((name) => {
                                    if (name === value) {
                                        count += 1;
                                    }
                                });
                                if (count > 1) {
                                    return false;
                                }
                                return true;
                            })
                            .required('Select a Project'),
                        nte: yup
                            .number()
                            .required('Enter NTE amount')
                            .typeError('Enter NTE amount')
                            .test('NTE', 'Enter an NTE amount that does not exceed total agreement NTE', () => {
                                if (new ErgoMoney(currentProjectTotal).greaterThan(currentNTE)) {
                                    return false;
                                }
                                return true;
                            }),
                    })
                )
                .test('totalNTE', (value) => {
                    let currentTotal = 0;
                    let projectNames = [];
                    value.forEach((p) => {
                        projectNames.push(p.project_name);
                        currentTotal += parseFloat(p.nte);
                    });
                    currentProjectTotal = currentTotal;
                    projectNameList = projectNames;
                    return true;
                }),
            documents: yup
                .array()
                .of(yup.object().shape({ name: yup.string(), doc_link: yup.string().url('Must a valid url') })),
        });
    };

    const handleAddProjects = (arrayHelpers) => {
        arrayHelpers.push(createDefaultProjectValues(true));
    };

    const handleRemoveProjects = (arrayHelpers, idx) => {
        arrayHelpers.remove(idx);
    };

    const handleAddDocs = (arrayHelpers) => {
        arrayHelpers.push(createDefaultDocsValues(true));
    };

    const [initialValues, setInitialValues] = useState(createDefaultFormValues(false));

    const handleFormSubmit = (values) => {
        try {
            AgreementsApi.updateAgreementById(agreement.id, values).then(() => {
                closeModal();
            });
        } catch (error) {
            errorToast(error);
        }
    };

    useEffect(() => {
        setOpenModal(props.openModal);
    }, [props.openModal]);

    useEffect(() => {
        if (agreement) {
            setInitialValues(createDefaultFormValues(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreement]);

    return (
        <Modal isOpen={openModal} style={{ minWidth: '80vw' }}>
            <div className="EditAgreement">
                <div className="flex justify-content-between mb2">
                    <div>
                        <button onClick={closeModal} className="TextButton">
                            <i className="fa fa-arrow-left" /> Back
                        </button>
                        {vendor.name && <SectionHeading className="align-left"> {vendor.name} </SectionHeading>}
                        {agreement.number && <div>{agreement.number}</div>}
                    </div>
                    <button onClick={closeModal}>
                        <i className="fa fa-window-close" />
                    </button>
                </div>
                <div className="mb2 pt2 flex justify-content-between">
                    <div className="flex flex-column">
                        <h4>Agreement Status</h4>
                        {agreement.status && <div>{agreement.status}</div>}
                    </div>
                    <div className="mt2 flex justify-content-end">
                        <button className="TextButton" type="reset" onClick={closeModal}>
                            Cancel
                        </button>
                        <button className="TextButton" type="submit" form="addAgreementForm">
                            Save
                        </button>
                    </div>
                </div>

                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={createYupSchema}
                    onSubmit={(values) => {
                        handleFormSubmit(editSubmitAgreement(values));
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form id="addAgreementForm" className="flex flex-row flex-wrap">
                            <GridSection title="Current Agreement Information">
                                <div className="mr2 flex flex-column width-30">
                                    <AddAgreementInfo values={values} errors={errors} touched={touched} edit={true} />
                                </div>
                                <div className="VerticalLine" />
                                <div className="mr2 width-30">
                                    <BasicFieldArray
                                        name="projects"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.projects.map((project, index) => (
                                                    <Fragment key={'ProjectsGrid-' + index}>
                                                        <div className="ProjectsGrid">
                                                            <div className="Left mt3">
                                                                <button
                                                                    type="button"
                                                                    className="Trash"
                                                                    onClick={() =>
                                                                        handleRemoveProjects(arrayHelpers, index)
                                                                    }
                                                                >
                                                                    <i className="fa fa-trash-o" />
                                                                </button>
                                                            </div>
                                                            <AddAgreementProjects
                                                                key={index}
                                                                index={index}
                                                                values={values.projects}
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                        </div>
                                                    </Fragment>
                                                ))}
                                                <button
                                                    type="button"
                                                    className="TextButton"
                                                    onClick={() => handleAddProjects(arrayHelpers)}
                                                >
                                                    + Add Project
                                                </button>
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="VerticalLine" />
                                <div className="mr2 ml2 width-30">
                                    <h4 className="Subheading">Documents</h4>
                                    <AddAgreementDocs values={values} errors={errors} touched={touched} edit={true} />
                                    <BasicFieldArray
                                        name="documents"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.documents.map((doc, index) => (
                                                    <Fragment key={'GeneralDocs- ' + index}>
                                                        <div key={'horz ' + index} className="HorizontalLine mt2 mb2" />
                                                        <AddGeneralDocuments
                                                            key={index}
                                                            errors={errors}
                                                            touched={touched}
                                                            index={index}
                                                            values={values.documents}
                                                        />
                                                    </Fragment>
                                                ))}
                                                <button
                                                    type="button"
                                                    className="TextButton"
                                                    onClick={() => handleAddDocs(arrayHelpers)}
                                                >
                                                    + New Documents
                                                </button>
                                            </>
                                        )}
                                    />
                                </div>
                            </GridSection>
                        </Form>
                    )}
                </Formik>

                <GridSection title="Agreement History">
                    <div className="flex flex-column column-flex-100">
                        <AgreementHistoryTable
                            data={agreement.amendments}
                            onTableReady={hoistEditTableParams}
                            mode="view"
                        />
                        <div className="ViewVendorButtons Form__buttons Align-right">
                            <ExportCSVButton
                                fileName={vendor.name + '-Agreement-History'}
                                exportFunction={exportDataAsCsv}
                            />
                            <div className={`${!modalMessageOpen && 'hidden'}`}>
                                <button type="button" onClick={openAmendAgreement}>
                                    Amend Agreement
                                </button>
                            </div>
                        </div>
                    </div>
                </GridSection>
            </div>
            <AgreementMessageModal closeModal={closeAmendAgreement} openModal={modalMessageOpen} message={message} />
            <div className="ViewVendorButtons Form__buttons mt2 flex justify-content-between">
                <button type="reset" onClick={closeModal}>
                    <i className="fa fa-arrow-left mr1" />
                    Back
                </button>
                <button type="submit" onClick={closeAgreement}>
                    Close Agreement
                </button>
            </div>
        </Modal>
    );
}
