import React from 'react';
import { withRouter } from 'react-router';
import SubNav, { SubNavLink } from '../components/navigation/SubNav';

const AdminSubNav = () => {
    const URL = '/admin';
    return (
        <SubNav>
            <SubNavLink to={URL + '/vendorsAndAgreements'} exact>
                Vendors & Agreements
            </SubNavLink>
            <SubNavLink to={URL + '/usersAndPermissions'} exact>
                Enter New Users & Permissions
            </SubNavLink>
            <SubNavLink to={URL} exact>
                Set Approvers
            </SubNavLink>
            <SubNavLink to={URL + '/ccHolder'} exact>
                Set Credit Card Holders
            </SubNavLink>
            <SubNavLink to={URL + '/coreBudgetTypes'} exact>
                Set Core Budget Types
            </SubNavLink>
            <SubNavLink to={URL + '/newPia'} exact>
                Enter New PIA
            </SubNavLink>
            <SubNavLink to={URL + '/newCpo'} exact>
                Enter New CPO
            </SubNavLink>
            <SubNavLink to={URL + '/gaRates'} exact>
                Set G&A Rates
            </SubNavLink>
            <SubNavLink to={URL + '/oldProjects'}>Create Old Projects</SubNavLink>
            <SubNavLink to={URL + '/projectTypes'} exact>
                Set Project Types
            </SubNavLink>
            <SubNavLink to={URL + '/residualFunds'} exact>
                Set Residual Funds
            </SubNavLink>
            <SubNavLink to={URL + '/officeSymbols'} exact>
                Set Office Symbols
            </SubNavLink>
            <SubNavLink to={URL + '/services'} exact>
                Set Services
            </SubNavLink>
            <SubNavLink to={URL + '/setStrategicMetrics'} exact>
                Set Strategic Metrics
            </SubNavLink>
        </SubNav>
    );
};

export default withRouter(AdminSubNav);
