import api from './api';
import endpoints from './endpoints';

const reconciliationRoute = endpoints.RECONCILIATION;

export default {
    importQuickbooksFile(data) {
        return api.post_form_data(reconciliationRoute, data, 'Error creating reconciliation file');
    },
};
