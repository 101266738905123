import React from 'react';
import Select from 'react-select';
import { AllProjectMissingData, AllProjectBasic } from '../ReportForms';
import PiaModSummary from '../output/AllProjects/PiaModSummary';
import AgreementSummary from '../output/AllProjects/AgreementSummary';
import NoSignedPD from '../output/AllProjects/APROutput/NoSignedPD';
import StaleUpdate from '../output/AllProjects/APROutput/StaleUpdate';
import Overdue from '../output/AllProjects/APROutput/Overdue';
import ActiveLastReview from '../output/AllProjects/APROutput/ActiveLastReview';
import MilestoneDeliverablesStatus from '../output/AllProjects/APROutput/MilestoneDeliverablesStatus';
import LastStatusUpdate from '../output/AllProjects/APROutput/LastStatusUpdate';
import ServiceProjectFinancialSummary from '../output/AllProjects/APROutput/ServiceProjectFinancialSummary';
import AllProjectsOverview from '../output/AllProjects/APROutput/AllProjectsOverview';
import ReportHeader from '../ReportHeader';
import errorToast from '../../components/messages/ErrorMessage';
import ServicesApi from '../../api/services';

class AllProjects extends React.Component {
    constructor(props) {
        super(props);

        this.updateContent = this.updateContent.bind(this);
        this.updateSubContent = this.updateSubContent.bind(this);
        this.handleBasicSubmit = this.handleBasicSubmit.bind(this);
        this.selectService = this.selectService.bind(this);

        this.state = {
            reportType: [
                {
                    name: 'Agreements Summary',
                    inputId: 1,
                    reportOutput: 1,
                },
                {
                    name: 'Missing Data Report',
                    inputId: 2,
                    subMenu: [
                        {
                            name: 'Active projects that do not have a signed PD',
                            reportOutput: 'b',
                        },
                        {
                            name: 'Active projects that have not updated their status in the past 90 days',
                            reportOutput: 'c',
                        },
                        {
                            name: 'Projects with overdue milestones or deliverables',
                            reportOutput: 'd',
                        },
                    ],
                },
                {
                    name: 'PIA Mod Summary',
                    inputId: 3,
                    reportOutput: 2,
                },
                {
                    name: 'Active Projects Last Reviewed Date',
                    inputId: 4,
                    reportOutput: 3,
                },
                {
                    name: 'Service Area Financial Summary',
                    inputId: 5,
                    reportOutput: 4,
                },
                {
                    name: 'Milestones and Deliverables',
                    inputId: 6,
                    reportOutput: 5,
                },
                {
                    name: 'Last Status Update',
                    inputId: 7,
                    reportOutput: 6,
                },
                {
                    name: 'All Projects Overview',
                    inputId: 8,
                    reportOutput: 7,
                },
            ],
            selectedReportType: null,
            selectedSubmenuItem: {},
            selectedReportOutput: null,
            selectedService: null,
            projects: [],
            isSubmitted: false,
            agreementFilter: 'all',
            services: [],
        };
    }

    async componentDidMount() {
        const { data: services } = await ServicesApi.getServices();
        this.setState({ services });
    }

    handleBasicSubmit = (values, actions) => {
        this.setState({
            agreementFilter: values.filter,
            isSubmitted: true,
        });
        actions.setSubmitting(false);
        actions.resetForm();
    };

    updateContent(e) {
        let item = e;
        if (item) {
            if (item.value === 2) {
                this.setState({
                    selectedReportType: item,
                    selectedReportOutput: null,
                    isSubmitted: false,
                });
            } else {
                this.setState({
                    selectedReportType: item,
                    selectedReportOutput: item.reportOutput,
                    isSubmitted: false,
                });
            }
        } else {
            this.setState({
                selectedReportType: item,
                selectedReportOutput: item,
                isSubmitted: false,
            });
        }
    }

    selectService(event) {
        const { selectedReportOutput } = this.state;

        if (event) {
            this.setState({
                selectedService: event.label,
                selectedReportOutput,
                isSubmitted: false,
            });
        } else {
            this.setState({
                selectedService: 'All',
                selectedReportOutput,
                isSubmitted: false,
            });
        }
    }

    updateSubContent(e) {
        let item = e;
        if (item) {
            this.setState({
                selectedSubmenuItem: item,
                selectedReportOutput: item.value,
                isSubmitted: false,
            });
        } else {
            this.setState({
                selectedSubmenuItem: item,
                selectedReportOutput: item,
                isSubmitted: false,
            });
        }
    }

    render() {
        const {
            selectedReportType,
            selectedService,
            selectedSubmenuItem,
            selectedReportOutput,
            isSubmitted,
            reportType,
        } = this.state;
        let serviceOptions = [];
        let currentService;
        if (this.state.services) {
            // All selection, overloaded to work with the select component
            serviceOptions.push({
                name: 'All',
                label: 'All',
                value: null,
            });
            this.state.services.forEach((service) => {
                serviceOptions.push({
                    name: service.name,
                    reportOutput: service,
                });
            });
        }

        let service;
        if (selectedService !== null && serviceOptions.length > 0) {
            if (selectedService === 'All') {
                service = [];
                currentService = serviceOptions[0];
            } else {
                service = this.state.services.filter((serv) => serv.name === selectedService);
                currentService = {
                    label: service[0].name,
                    value: service[0],
                    id: service[0].id,
                };
            }
        }

        let reportOptions = [];
        if (reportType) {
            for (let i = 0; i < reportType.length; i++) {
                if (reportType[i].inputId === 1) {
                    reportOptions.push({
                        value: reportType[i].inputId,
                        label: reportType[i].name,
                        reportOutput: reportType[i].reportOutput,
                    });
                } else {
                    reportOptions.push({
                        value: reportType[i].inputId,
                        label: reportType[i].name,
                        reportOutput: reportType[i].reportOutput,
                        submenu: reportType[i].subMenu,
                    });
                }
            }
        }

        let output;
        let title = '';
        if (isSubmitted) {
            switch (selectedReportOutput) {
                case 1:
                    output = <AgreementSummary />;
                    title = reportType.name;
                    break;
                case 2:
                    output = <PiaModSummary selectedService={service} />;
                    title = reportType.name;
                    break;
                case 3:
                    output = <ActiveLastReview selectedService={service} />;
                    title = reportType.name;
                    break;
                case 4:
                    output = <ServiceProjectFinancialSummary selectedService={service} />;
                    title = reportType.name;
                    break;
                case 5:
                    output = <MilestoneDeliverablesStatus selectedService={currentService.id} />;
                    title = reportType.name;
                    break;
                case 6:
                    output = <LastStatusUpdate selectedService={currentService.id} />;
                    title = reportType.name;
                    break;
                case 7:
                    output = <AllProjectsOverview />;
                    title = reportType.name;
                    break;
                case 'b':
                    output = <NoSignedPD />;
                    title = reportType.name;
                    break;
                case 'c':
                    output = <StaleUpdate />;
                    title = reportType.name;
                    break;
                case 'd':
                    output = <Overdue />;
                    title = reportType.name;
                    break;
                default:
                    errorToast('Invalid selected report');
                    break;
            }
        }

        let form;
        if (selectedReportType) {
            let value = selectedReportType.value;
            if (value === 1 || value === 8) {
                form = <AllProjectBasic onSubmit={this.handleBasicSubmit} />;
            } else if (value === 2) {
                form = (
                    <AllProjectMissingData
                        label="Missing Data Reports"
                        submenu={selectedReportType.submenu}
                        updateContent={this.updateSubContent}
                        selected={selectedSubmenuItem}
                        onSubmit={this.handleBasicSubmit}
                    />
                );
            } else if (value === 3 || value === 4 || value === 5 || value === 6 || value === 7) {
                form = (
                    <AllProjectMissingData
                        label="Service Types"
                        submenu={serviceOptions}
                        updateContent={this.selectService}
                        selected={currentService}
                        onSubmit={this.handleBasicSubmit}
                    />
                );
            } else {
                errorToast('Invalid selected report');
            }
        }

        return (
            <div className="All-projects-container">
                <div className="Report-form-container Wide-single-layout">
                    <label className="FieldLabel">Report</label>
                    <Select
                        value={selectedReportType}
                        options={reportOptions}
                        onChange={(e) => {
                            this.updateContent(e);
                        }}
                    />
                    {form}
                </div>
                <div className="Wide-single-layout Report-card">
                    {isSubmitted ? (
                        <div>
                            <ReportHeader title={title} />
                            {output}
                        </div>
                    ) : (
                        <h3>Please select a report to run </h3>
                    )}
                </div>
            </div>
        );
    }
}

export default AllProjects;
