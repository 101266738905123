import React from 'react';

import Fieldset from '../Fieldset';
import LabeledField from '../../components/forms/LabeledField';

import calculateAcceptanceDecisionScore, { ACCEPTANCE_THRESHOLD } from '../utils/calculateAcceptanceDecisionScore';

const InnovationSection = (props) => {
    const { values, errors, touched, isEditing } = props;
    const calculatedAcceptanceDecisionScore = calculateAcceptanceDecisionScore(values.score);
    const go = calculatedAcceptanceDecisionScore >= ACCEPTANCE_THRESHOLD;

    const yesNoOptions = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ];
    const yesNoNaOptions = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
        { label: 'NA', value: 'na' },
    ];
    const lowMediumHighOptions = [
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' },
    ];
    return (
        <>
            <Fieldset title="Project Acceptance Decision">
                <LabeledField
                    longLabel
                    label="Is it legal under the funding (COTR Support?)"
                    name="score.legal"
                    component="Radio"
                    currentValue={values.score.legal}
                    options={yesNoOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label='Is this project primarily a "pass through" with only limited WBI involvement?'
                    name="score.primarily_pass_through"
                    component="Radio"
                    currentValue={values.score.primarily_pass_through}
                    options={yesNoOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="Is the client primarily just looking for the use of our facilities (with little other WBI support or involvement)?"
                    name="score.just_facility_use"
                    component="Radio"
                    currentValue={values.score.just_facility_use}
                    options={yesNoOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="How important is this to AFRL or one of its Directorates?"
                    name="score.afrl_importance"
                    component="Radio"
                    currentValue={values.score.afrl_importance}
                    options={lowMediumHighOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label='How "jazzed" is the client about the project?'
                    name="score.client_jazzed"
                    component="Radio"
                    currentValue={values.score.client_jazzed}
                    options={lowMediumHighOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label='How strong is the advocate for this?  (i.e. how much "pull" do they have?)'
                    name="score.advocate_strength"
                    component="Radio"
                    currentValue={values.score.advocate_strength}
                    options={lowMediumHighOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label='To what extent does this project promote "AFRL Thought Leadership"?'
                    name="score.promote_thought_leadership"
                    component="Radio"
                    currentValue={values.score.promote_thought_leadership}
                    options={lowMediumHighOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="Does this project allow us to try something new or add significantly to an existing capability?"
                    name="score.try_something_new"
                    component="Radio"
                    currentValue={values.score.try_something_new}
                    options={yesNoOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="Does project tie directly to a PIA focus area of Workforce Development, Tech Transition, or Innovation?"
                    name="score.cpo_focus_area"
                    component="Radio"
                    currentValue={values.score.cpo_focus_area}
                    options={yesNoOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="What is the probability of the client doing something extraordinary with this (follow-up plan?)"
                    name="score.client_probability"
                    component="Radio"
                    currentValue={values.score.client_probability}
                    options={lowMediumHighOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="What is the potential for public promotion/excitement or gardnering of national attention/recognition?"
                    name="score.potential_for_promotion"
                    component="Radio"
                    currentValue={values.score.potential_for_promotion}
                    options={lowMediumHighOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="Given the length and size of the project, do we have sufficient manpower and funding resources?"
                    name="score.sufficent_resources"
                    component="Radio"
                    currentValue={values.score.sufficent_resources}
                    options={yesNoOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="If there are not sufficient WBI resources, can sufficient resources be obtained?"
                    name="score.can_obtain_resources"
                    component="Radio"
                    currentValue={values.score.can_obtain_resources}
                    options={yesNoNaOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="Is the client willing to bring needed resources ($, people, equipment, etc.)?"
                    name="score.client_willing_to_supply"
                    component="Radio"
                    currentValue={values.score.client_willing_to_supply}
                    options={yesNoNaOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="How significant is the AF problem that this addresses?"
                    name="score.problem_significance"
                    component="Radio"
                    currentValue={values.score.problem_significance}
                    options={lowMediumHighOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="Does the project involve more than one WBI service area?"
                    name="score.more_than_one_service_area"
                    component="Radio"
                    currentValue={values.score.more_than_one_service_area}
                    options={yesNoOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />
                <LabeledField
                    longLabel
                    label="Can WBI provide a significant value-added?"
                    name="score.wbi_add_value"
                    component="Radio"
                    currentValue={values.score.wbi_add_value}
                    options={yesNoOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                />

                <div
                    className="column-span-2 text-align-right"
                    style={{
                        color: go ? 'green' : 'red',
                    }}
                >
                    Acceptance Decision Score
                </div>
                <div>{calculatedAcceptanceDecisionScore}</div>
                <>
                    <label htmlFor="goNoGo" className="FieldLabel" style={{ gridColumn: 'span 2' }}>
                        Acceptance Decision Recommendation
                    </label>

                    <div
                        id="goNoGo"
                        className="text-align-left bold"
                        style={{
                            color: go ? 'green' : 'red',
                        }}
                    >
                        {go ? 'Go' : 'No-Go'}
                    </div>
                </>
            </Fieldset>
            <hr />
        </>
    );
};

export default InnovationSection;
