import React, { useEffect, useMemo, useState } from 'react';
import ExportCSVButton from '../../components/buttons/ExportCSVButton';

const CreditCardButtonBar = ({ isProject, exportTableAsCsv, applyToAccount, returnToSender }) => {
    const [csvFileName, setCsvFilename] = useState();
    const [applyButtonLabel, setApplyButtonLabel] = useState();

    useEffect(() => {
        if (isProject === true) {
            setApplyButtonLabel('Apply to Financial Summary');
            setCsvFilename('Project-Credit-Cards');
        } else {
            setApplyButtonLabel('Apply to Core Budget');
            setCsvFilename('Core-Fund-Credit-Cards');
        }
    }, [isProject]);

    const exportButton = useMemo(
        () => <ExportCSVButton fileName={csvFileName} exportFunction={exportTableAsCsv} />,
        [csvFileName, exportTableAsCsv]
    );

    return (
        <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
            <button type="button" onClick={() => returnToSender()}>
                Return to Sender
            </button>
            <button type="button" onClick={() => applyToAccount()}>
                {applyButtonLabel}
            </button>
            {exportButton}
        </div>
    );
};

export default CreditCardButtonBar;
