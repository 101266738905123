import api from './api';
import endpoints from './endpoints';

const agreementInvoiceRoute = endpoints.AGREEMENT_INVOICE;
const agreementRoute = endpoints.AGREEMENTS;

export default {
    deleteInvoiceByID(invoiceID) {
        return api.delete(`${agreementInvoiceRoute}/${invoiceID}`, 'Error Deleting Invoice');
    },

    submitInvoiceForPayment(agreementID, invoice) {
        return api.put(`${agreementRoute}/${agreementID}/invoice`, invoice, 'Error submitting Invoice for payment');
    },

    editInvoice(invoiceID, invoice) {
        return api.put(`${agreementInvoiceRoute}/${invoiceID}`, invoice, 'Error updating Invoice');
    },

    fetchInvoicesByProjectID(projectID) {
        return api.get(`${agreementInvoiceRoute}/project/${projectID}`, 'Error getting Invoices on Project');
    },

    fetchInvoicesByStatus() {
        return api.get(`${agreementInvoiceRoute}?status=submitted,returned`, 'Error getting Invoices');
    },

    fetchAllInvoices() {
        return api.get(`${agreementInvoiceRoute}`, 'Error getting Invoices');
    },

    setStatusPaid(invoiceIDs) {
        return api.patch(`${agreementInvoiceRoute}/pay`, invoiceIDs, 'Error paying Invoice');
    },

    returnToSender(id, payload) {
        return api.patch(`${agreementInvoiceRoute}/${id}/return`, payload, 'Error returning Invoice');
    },
};
