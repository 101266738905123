import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import yup from '../../yup';
import UploadFile from './UploadFile';
import ReconciliationModal from './ReconciliationModal';
import ReconcilationApi from '../../api/reconciliation';

const ReconciliationUploadForm = () => {
    const [initialValues, setInitialValues] = useState({ file: {} });
    const [modalOpen, setModalOpen] = useState(false);
    const [type, setType] = useState('Awaiting');
    const [modalMessage, setModalMessage] = useState('');
    const [successModal, setSuccessModal] = useState(false);

    const handleOnSubmit = (values, actions) => {
        let formData = new FormData();
        formData.append('excel_file', values.file);

        setModalMessage('Please wait while comparisons are made... this may take up to 3 minutes.');
        setModalOpen(true);
        ReconcilationApi.importQuickbooksFile(formData)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const fileName = response.headers['content-disposition'];
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                setInitialValues({ file: { link } });
                link.click();
                actions.resetForm();
                setSuccessModal(true);
            })
            .catch((errorMessage) => {
                console.error(errorMessage);
            })
            .finally(() => {
                setModalOpen(false);
                setInitialValues({ file: {} });
            });
    };

    useEffect(() => {
        if (successModal) {
            setType('DownloadReady');
            setModalMessage('Reconciliation File will be automatically downloaded');
            setModalOpen(true);
            setInitialValues({ file: {} });
            setSuccessModal(false);
        }
    }, [successModal]);

    const closeModal = () => {
        setModalOpen(false);
        if (type === 'FileTypeError') {
            setType('Awaiting');
        }
        if (type === 'DownloadReady') {
            setType('Awaiting');
        }
    };

    const createYupSchema = () =>
        yup.object({
            file: yup
                .mixed()
                .required('Only Excel (.xlsx) file extentsion accepted')
                .test('fileFormat', '.xlsx only', (value) => {
                    if (value.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        return true;
                    }
                    return false;
                }),
        });
    const handleWrongFileType = (fileType, errorMessage) => {
        setType(fileType);
        setModalMessage(errorMessage);
        setModalOpen(true);
    };

    return (
        <div>
            <h3 className="UploadHeading">Upload File</h3>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    handleOnSubmit(values, actions);
                }}
                validationSchema={createYupSchema}
            >
                {({ values, handleSubmit, setFieldValue, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="DropZone">
                            <UploadFile
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                handleWrongFileType={handleWrongFileType}
                            />
                        </div>
                        <div className="ButtonContent">
                            <button type="submit" className="mt2 Submit-button">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            <ReconciliationModal openModal={modalOpen} closeModal={closeModal} type={type} message={modalMessage} />
        </div>
    );
};

export default ReconciliationUploadForm;
