import api from './api';

import endpoints from './endpoints';

const gaRoute = endpoints.GARATE;

export default {
    getGARates() {
        return api.get(`${gaRoute}/0`, 'Error getting list of GA Rates');
    },

    getGARate(id) {
        return api.get(`${gaRoute}/${id}`, 'Error getting list of GA Rates');
    },

    createRate(values) {
        return api.post(`${gaRoute}/create`, values, 'Error creating a new GA Rate');
    },

    updateRate(values) {
        return api.post(`${gaRoute}/update`, values, 'Error updating GA Rate. May be in use.');
    },

    removeRate(id) {
        return api.get(`${gaRoute}/delete/${id}`, 'Error deleting rate. May be in use.');
    },
};
