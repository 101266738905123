import React, { Component } from 'react';
//Library
import { Formik, Form } from 'formik';
//Shared component
import { shortDate } from '../../utils/utils';
import SignaturesForm from '../../components/signatureForm/SignaturesForm';
import ErgoMoney from '../../utils/ErgoMoney';

class TransferApprovalCards extends Component {
    render() {
        if (this.props.approvals) {
            this.props.approvals.forEach((approval) => {
                approval.reviewers.forEach((approver) => {
                    approver.organization = 'Wright Brothers Institute';
                });
            });
            return this.props.approvals.map((item, index) => (
                <div className="Card-styling">
                    <table className="Approval_table--wide" key={index}>
                        <tbody>
                            <tr className="Table_area_double_1">
                                <td className="Table_label">Transfer Type:</td>
                                <td>{item.transfer_type}</td>
                            </tr>
                            <tr className="Table_area_double_2">
                                <td className="Table_label">Initiated By:</td>
                                <td>{item.initiator_name}</td>
                            </tr>
                            <tr className="Table_area_double_3">
                                <td className="Table_label">Date:</td>
                                <td>{shortDate(item.created_on)}</td>
                            </tr>
                            <tr className="Table_area_double_4">
                                <td className="Table_label">Amount:</td>
                                <td>{new ErgoMoney(item.amount).getStringAmount()}</td>
                            </tr>
                            <tr className="Table_area_double_5">
                                <td className="Table_label">Description of Transfer:</td>
                                <td>{item.description}</td>
                            </tr>
                            <tr className="Table_area_double_6">
                                <td className="Table_label">Reason of Transfer:</td>
                                <td>{item.reason}</td>
                            </tr>
                            <tr className="Table_area_triple_1">
                                <td className="Table_label">From Project No. A:</td>
                                <td>{item.source_number}</td>
                            </tr>
                            <tr className="Table_area_triple_2">
                                <td className="Table_label">Project A Name:</td>
                                <td>{item.source_name}</td>
                            </tr>
                            {item.destination_type === 'Project' ? (
                                <tr className="Table_area_triple_4">
                                    <td className="Table_label">To Project No. B:</td>
                                    <td>{item.destination_number}</td>
                                </tr>
                            ) : null}
                            {item.destination_type === 'Project' ? (
                                <tr className="Table_area_triple_5">
                                    <td className="Table_label">Project B Name:</td>
                                    <td>{item.destination_name}</td>
                                </tr>
                            ) : null}
                            {item.residual_funds_name ? (
                                <tr className="Table_area_triple_4">
                                    <td className="Table_label">Funds to AFRL Symbol:</td>
                                    <td>{item.destination_name}</td>
                                </tr>
                            ) : null}
                            <tr className="Table_area_triple_3">
                                <td className="Table_label">Project A Funds Available for Transfer:</td>
                                <td>{new ErgoMoney(item.source_funds).getStringAmount()}</td>
                            </tr>
                            <tr className="Table_area_triple_6">
                                <td className="Table_label">Funds to be Transferred to Project B:</td>
                                <td>{new ErgoMoney(item.amount).getStringAmount()}</td>
                            </tr>
                            <tr className="Table_area_triple_7">
                                <td className="Table_label">Project A Remaining Uncommited Funds:</td>
                                <td>{new ErgoMoney(item.source_remaining).getStringAmount()}</td>
                            </tr>
                        </tbody>
                        {item.reviewers ? (
                            <Formik
                                initialValues={{
                                    signatures: item.reviewers,
                                }}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <div className="Signatures-space">
                                            <SignaturesForm
                                                name="signatures"
                                                editSignature={this.props.editSignature}
                                                values={values}
                                                readOnly
                                                setFieldValue={setFieldValue}
                                                className="grid column-span-3"
                                                style={{
                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                    gridGap: 16,
                                                    width: '60%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        ) : null}
                    </table>
                    <div className="Form__buttons--Reversed Card-buttons">
                        <button type="button" onClick={() => this.props.reject(item.id, index)}>
                            Reject Transfer
                        </button>
                        <button type="button" onClick={() => this.props.approve(item.id, index, item)}>
                            Approve Transfer
                        </button>
                    </div>
                </div>
            ));
        }
        return null;
    }
}

export default TransferApprovalCards;
