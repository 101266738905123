import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LabeledField from '../../components/forms/LabeledField';
import yup from '../../yup';
import ErgoMoney from '../../utils/ErgoMoney';
import SectionHeading from '../../components/heading/SectionHeading';
import './AgreementInvoiceForm.scss';
import AgreementInvoicesApi from '../../api/agreementInvoices';
import errorToast from '../../components/messages/ErrorMessage';
import VendorsApi from '../../api/vendors';
import createDropdownOption from '../../utils/DropdownUtils';
import { useParams } from 'react-router';

const VendorInvoiceForm = ({ isArchived, hoistPageEvent, hoistInvoiceMessage }) => {
    const [vendors, setVendors] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [selectedVendorID, setSelectedVendorID] = useState();
    const [agreementNumberOptions, setAgreementNumberOptions] = useState([]);
    const [agreementDescriptionOptions, setAgreementDescriptionOptions] = useState([]);
    const [selectedAgreementID, setSelectedAgreementID] = useState();
    const [associatedAgreements, setAssociatedAgreements] = useState([]);
    const [associatedRemainingNTE, setAssociatedRemainingtNTE] = useState(-1);

    const { id: projectID } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await VendorsApi.getVendorByProjectId(projectID);
                setVendors(data);
            } catch (error) {
                errorToast(error);
            }
        };
        fetchData(projectID);
    }, [projectID]);

    const createInitialValues = () => ({
        vendor_name: '',
        agreement_description: '',
        agreement_number: '',
        vendor_invoice_number: '',
        performance_period_end_date: '',
        amount: '',
        invoice_link: '',
        notes: '',
        received_date: '',
    });

    const handleSubmitForPayment = useCallback(
        async (data, formikBag) => {
            try {
                const validData = {
                    ...data,
                    project_id: projectID,
                };
                delete validData.vendor_name;
                delete validData.agreement_description;
                delete validData.agreement_number;
                await AgreementInvoicesApi.submitInvoiceForPayment(selectedAgreementID, validData);
                formikBag.setSubmitting(false);
                formikBag.resetForm();
                hoistPageEvent('submit');
            } catch (error) {
                errorToast(error);
            }
        },
        [selectedAgreementID, projectID, hoistPageEvent]
    );

    const createYupValidationSchema = () =>
        yup.object().shape({
            vendor_name: yup.string().required(),
            agreement_description: yup.string().required(),
            agreement_number: yup.string().required(),
            vendor_invoice_number: yup.string().required(),
            performance_period_end_date: yup.date().required(),
            amount: yup
                .number()
                .max(associatedRemainingNTE, "Enter an Amount that does not exceeds Project's allocated NTE")
                .required(),
            invoice_link: yup.string(),
            notes: yup.string(),
            received_date: yup.date().required(),
        });

    useEffect(() => {
        const options = vendors.map((vendor) => createDropdownOption(vendor.name, vendor.id));
        setVendorOptions(options);
    }, [vendors]);

    useEffect(() => {
        const selectedVendor = vendors.find((vendor) => vendor.id === selectedVendorID);
        if (selectedVendor) {
            if (selectedVendor.active) {
                let options = selectedVendor.agreements.map((agreement) =>
                    createDropdownOption(agreement.description, agreement.id)
                );
                setAgreementDescriptionOptions(options);

                options = selectedVendor.agreements.map((agreement) =>
                    createDropdownOption(agreement.number, agreement.id)
                );
                setAgreementNumberOptions(options);
                setAssociatedAgreements(selectedVendor.agreements);
            } else {
                hoistInvoiceMessage('inactive', selectedVendor.name);
            }
        }
    }, [selectedVendorID, vendors, hoistInvoiceMessage]);

    useEffect(() => {
        const selectedAgreement = associatedAgreements.find((agreement) => agreement.id === selectedAgreementID);
        if (selectedAgreement) {
            let selectedAgreementInvoice = selectedAgreement.invoices;
            let selectedAgreementProject = selectedAgreement.projects;
            let project = selectedAgreementProject.find((selectedProject) => selectedProject.project_id === projectID);
            let projectNTE = 0;
            if (project.nte) {
                projectNTE = new ErgoMoney(project.nte);
                if (selectedAgreementInvoice) {
                    let nteSum = 0;
                    selectedAgreementInvoice.forEach((invoice) => {
                        if (invoice.project.id === project.project_id) {
                            nteSum += invoice.amount;
                        }
                    });

                    let remainingNTE = projectNTE.subtract(nteSum).getNumberAmount();
                    setAssociatedRemainingtNTE(remainingNTE);
                } else {
                    setAssociatedRemainingtNTE(projectNTE.getNumberAmount());
                }
            } else {
                hoistInvoiceMessage('projectNTE', selectedAgreement.number);
            }
        }
    }, [selectedAgreementID, associatedAgreements, projectID, hoistInvoiceMessage]);

    return (
        <>
            <SectionHeading>Agreement Invoices</SectionHeading>
            <div className="VendorInvoiceForm">
                <Formik
                    initialValues={createInitialValues()}
                    validationSchema={createYupValidationSchema()}
                    onSubmit={handleSubmitForPayment}
                >
                    {({ errors, touched, resetForm, isSubmitting, setFieldValue }) => (
                        <Form>
                            <button
                                className="TextButton HeaderRight"
                                type="reset"
                                onClick={resetForm}
                                disabled={isArchived || isSubmitting}
                            >
                                Clear Form
                            </button>
                            <div className="LeftColumn">
                                <h5>Select Agreement</h5>
                                <LabeledField
                                    label="*Vendor"
                                    name="vendor_name"
                                    component="select"
                                    options={vendorOptions}
                                    getValue={(event) => setSelectedVendorID(event.value)}
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="*Agreement Description"
                                    name="agreement_description"
                                    component="select"
                                    options={agreementDescriptionOptions}
                                    onChange={(event) => {
                                        const { value: id } = event;
                                        setSelectedAgreementID(id);
                                        setFieldValue('agreement_description', id);
                                        setFieldValue('agreement_number', id);
                                    }}
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="*Agreement No."
                                    name="agreement_number"
                                    component="select"
                                    options={agreementNumberOptions}
                                    onChange={(event) => {
                                        const { value: id } = event;
                                        setSelectedAgreementID(id);
                                        setFieldValue('agreement_description', id);
                                        setFieldValue('agreement_number', id);
                                    }}
                                    errors={errors}
                                    touched={touched}
                                />
                            </div>
                            <div className="VerticalLine" />
                            <div className="RightColumn">
                                <h5>Invoice Information</h5>
                                <LabeledField
                                    label="*Vendor Invoice No."
                                    name="vendor_invoice_number"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="*Last Day of Invoice Performance Period"
                                    name="performance_period_end_date"
                                    type="date"
                                    component="Date"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="*Received Date"
                                    name="received_date"
                                    type="date"
                                    component="Date"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="*Amount"
                                    name="amount"
                                    component="Money"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="*Link to Invoice"
                                    name="invoice_link"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="Notes"
                                    name="notes"
                                    component="textarea"
                                    errors={errors}
                                    touched={touched}
                                />
                            </div>
                            <div className="Form__buttons--Reversed mt2 FooterRight">
                                <button type="submit" disabled={isArchived || isSubmitting || !selectedAgreementID}>
                                    Submit for Payment
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default VendorInvoiceForm;
