import React from 'react';

const BankStatementsReviewButtonBar = ({ loadAllExpenditures, unmatch, requestReceipts, reconcile }) => {
    const handleLoadAllExpendituresButton = () => {
        loadAllExpenditures();
    };

    const handleSetUnMatchButton = () => {
        unmatch();
    };

    const handleSetRequestReceiptsButton = () => {
        requestReceipts();
    };

    const handleSetReconcileButton = () => {
        reconcile();
    };

    return (
        <div className="Form__buttons--Reversed column-span-6 flex justify-content-end">
            <button type="button" onClick={() => handleLoadAllExpendituresButton()}>
                Load All Expenditures
            </button>
            <button type="button" onClick={() => handleSetUnMatchButton()}>
                Unmatch
            </button>
            <button type="button" onClick={() => handleSetRequestReceiptsButton()}>
                Request Receipts
            </button>
            <button type="button" onClick={() => handleSetReconcileButton()}>
                Reconcile
            </button>
        </div>
    );
};

export default BankStatementsReviewButtonBar;
