import React, { Component } from 'react';

import { history } from '../../store/store';
import AuthApi from '../../api/auth';
import AuthService from '../../auth/auth.service';

import UserButton from '../../components/buttons/UserButton';
import UserMessage from '../../components/messages/UserMessage';
import UserInput from '../../components/input/UserInput';

import { confirm } from '../../components/modals/ConfirmModal';

class RegistrationConfirmPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            confirmCode: '',
            emailAddress: AuthService.getEmail() || '',
            errorMessage: null,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(evt) {
        let changes = {};
        changes[evt.target.name] = evt.target.value;
        changes.errorMessage = null;
        this.setState(changes);
    }

    onSubmit(event) {
        event.preventDefault();
        let { confirmCode, emailAddress } = this.state;

        confirmCode = confirmCode.trim();

        this.setState({ errorMessage: null }, () => {
            AuthApi.confirmSignup(confirmCode, emailAddress)
                .then(async () => {
                    const confirmed = await confirm({
                        text: 'Please make sure to check your email for a code to reset your password.',
                        rejectText: null,
                        acceptText: 'OK',
                    });
                    if (confirmed) {
                        history.push('/account/confirm');
                    }
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        });
    }

    render() {
        return (
            <div className="LoginPage">
                <div className="AccountPage__content">
                    <h2>Confirm Your Account</h2>
                    <form onSubmit={this.onSubmit} className="AccountPage__form">
                        <UserInput
                            name="emailAddress"
                            title="Enter your email"
                            label="Email:"
                            placeholder="you@example.com"
                            type="text"
                            value={this.state.emailAddress}
                            onChange={this.onChange}
                        />

                        <UserInput
                            name="confirmCode"
                            label="Confirmation code:"
                            type="text"
                            value={this.state.confirmCode}
                            onChange={this.onChange}
                        />

                        <div className="Form__buttons--Reversed-clean">
                            <UserButton
                                type="submit"
                                defaultText="Confirm account"
                                busyText="Signing in"
                                isbusy={this.state.isbusy}
                            />
                        </div>

                        <div className="Form__footer">
                            <UserMessage error={this.state.errorMessage} />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default RegistrationConfirmPage;
