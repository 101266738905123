import createDropdownOption from './DropdownUtils';

const createPiaOptions = (pias) => {
    try {
        return pias.map((pia) => createDropdownOption(pia.name, pia.id));
    } catch (error) {
        throw new Error('Error creating PIA options');
    }
};

export { createPiaOptions as default };
