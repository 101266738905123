import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import errorToast from '../components/messages/ErrorMessage';
import AgreementsApi from '../api/agreements';
import useFunctionAsState from '../hooks/useFunctionAsState';
import AgreementsProjectTable from './AgreementsProjectTable';

const AgreementsProjectPage = () => {
    const [agreementsData, setAgreementsData] = useState([]);
    const { id } = useParams();
    const [exportDataAsCsv, setExportDataAsCsv] = useFunctionAsState(null);

    const hoistTableParams = (params) => {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            setExportDataAsCsv(params.exportDataAsCsv);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await AgreementsApi.getAgreementsByProjects(id); //change api call
                setAgreementsData(data);
            } catch (error) {
                errorToast(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            <AgreementsProjectTable agreementsData={agreementsData} onTableReady={hoistTableParams} projectID={id} />
            <div className="Form__buttons Align-right">
                <ExportCSVButton fileName="Vendors" exportFunction={exportDataAsCsv} />
            </div>
        </div>
    );
};

export default AgreementsProjectPage;
