import React from 'react';
import classNames from 'classnames';

class Fieldset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: props.collapsed === true,
        };
    }

    toggleCollapsed = () => {
        this.setState(
            (prevState) => ({ collapsed: !prevState.collapsed }),
            () => {
                if (this.props.onToggleCollapsed && typeof this.props.onToggleCollapsed === 'function') {
                    this.props.onToggleCollapsed(this.state.collapsed);
                }
            }
        );
    };

    render() {
        const { title, children, isNew } = this.props;
        return (
            <fieldset className="ProjectFieldset">
                {!isNew && (
                    <legend
                        onClick={() => {
                            if (!isNew) {
                                this.toggleCollapsed();
                            }
                        }}
                    >
                        <i
                            className={classNames('fa', this.state.collapsed ? 'fa-angle-up' : 'fa-angle-down')}
                            aria-hidden="true"
                            style={{ marginRight: 8 }}
                        />
                        {title}
                    </legend>
                )}
                <div style={this.state.collapsed ? { display: 'none' } : null}>{children}</div>
            </fieldset>
        );
    }
}

export default Fieldset;
