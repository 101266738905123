import React, { useState, useEffect } from 'react';
import LabeledField from '../../components/forms/LabeledField';
import ProjectsApi from '../../api/projects';

export default function AddAgreementProjects({ index, values, errors, touched }) {
    const [projectList, setProjectList] = useState([]);
    const [projectNumber, setProjectNumber] = useState();
    // eslint-disable-next-line no-shadow
    const selectProject = (event, values) => {
        setProjectNumber(event.object.number);
        // eslint-disable-next-line no-param-reassign
        values[index]['project_name'] = event.label;
        // eslint-disable-next-line no-param-reassign
        values[index]['project_number'] = event.object.number;
        // eslint-disable-next-line no-param-reassign
        values[index]['id'] = event.object.id;
    };

    useEffect(() => {
        setProjectNumber(values[index].project_number);
    }, [values, index]);

    useEffect(() => {
        const fetchData = async () => {
            const { data: projects } = await ProjectsApi.fetchProjects();
            let list = [];
            projects.forEach((project) => {
                list.push({
                    label: project.name,
                    value: project.name,
                    object: project,
                });
            });
            setProjectList(list);
        };
        fetchData();
    }, []);

    return (
        <div className="flex ml2 mr2">
            <div className="mr2">
                <LabeledField
                    label="Project Name"
                    name={`projects.${index}.project_name`}
                    component="select"
                    options={projectList}
                    errors={errors}
                    touched={touched}
                    value={values[index].project_name}
                    onChange={(event) => {
                        selectProject(event, values);
                    }}
                    labelClassName="mt2 SelectProject"
                />
                <div className="mb2 mt1">{projectNumber}</div>
            </div>
            <div className="flex-nowrap">
                <LabeledField
                    label="NTE Allocation"
                    name={`projects.${index}.nte`}
                    component="Money"
                    value={values[index]['nte']}
                    errors={errors}
                    touched={touched}
                    labelClassName="text-align-left mt2"
                />
            </div>
        </div>
    );
}
