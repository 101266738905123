import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import yup from '../yup';
import AuthApi from '../api/auth';
import UsersApi from '../api/users';
import LabeledField from '../components/forms/LabeledField';
import { confirm } from '../components/modals/ConfirmModal';
import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';
import EditingTable from '../components/tables/EditingTable';
import errorToast from '../components/messages/ErrorMessage';

class UsersAndPermissionsPage extends Component {
    constructor(props) {
        super(props);

        this.getRowData = this.getRowData.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.getRowIndex = this.getRowIndex.bind(this);
        this.updatePermissionData = this.updatePermissionData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            users: [],
            row: null,
            rowIndex: null,
            siteRoles: [],
            forceSetData: false,
            colKey: '',
            rowSelection: 'single',
            rowData: this.getRowData,
        };
    }

    componentDidMount = async () => {
        try {
            const { data: users } = await UsersApi.getUsers();
            const { data: siteRoles } = await UsersApi.listSiteRoles();

            this.setState({
                users,
                siteRoles,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    handleSubmit(values, actions) {
        if (values.newName && values.newEmailAddress && values.newPhone) {
            let phone = values.newPhone.replace(/[^A-Za-z0-9_]/g, '');
            AuthApi.invite(values.newName, values.newEmailAddress, phone)
                .then((response) => {
                    let users = this.state.users;
                    users.push(response.data);
                    actions.resetForm();
                    this.setState(
                        {
                            users,
                            forceSetData: true,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                    this.setState({ errorMessage });
                });
        }
    }

    onRowValueChanged(event) {
        let userToUpdate = event.node.data;
        const getKey = (obj, val) => Object.keys(obj).find((key) => obj[key] === val);
        let phone;
        if (userToUpdate.phone_number) {
            phone = userToUpdate.phone_number.replace(/[^A-Za-z0-9_]/g, '');
        }
        if (userToUpdate) {
            UsersApi.updateUser(userToUpdate.id, {
                name: userToUpdate.name,
                email_address: userToUpdate.email_address,
                phone_number: phone,
                site_role: getKey(this.state.siteRoles, userToUpdate.site_role),
            })
                .then(() => {
                    //
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'name',
        });
    }

    getRowIndex(event) {
        this.setState({ rowIndex: event.node.rowIndex });
    }

    onRemoveSelected = async (event) => {
        let message = (
            <div>
                <p>Are you sure you want to remove user?</p>{' '}
                <i>If user is connected to projects or services they will not be deleted.</i>
            </div>
        );
        const confirmed = await confirm({
            text: message,
            acceptText: 'Ok',
            rejectText: 'Cancel',
        });
        let userToUpdate = event.node.data;
        if (userToUpdate && confirmed) {
            UsersApi.removeUser(userToUpdate.id)
                .then(() => {
                    let newUsers = this.state.users.filter((item, i) => userToUpdate.id !== item.id);
                    this.setState({ users: newUsers });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    resetPassword = async (event) => {
        let userEmail = event.node.data.email_address;
        confirm({
            text: 'A link will be sent to the user to reset their password.  The code will only be good for 1 hour.',
            acceptText: null,
            rejectText: 'Ok',
        });

        AuthApi.forgotPassword(userEmail)
            .then(() => {})
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    };

    resendCode = async (event) => {
        if (!event.node.data.verified) {
            let userEmail = event.node.data.email_address;
            confirm({
                text: 'A new confirmation code will be sent to the user via email.',
                acceptText: null,
                rejectText: 'Ok',
            });
            AuthApi.resendCode(userEmail)
                .then(() => {})
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        } else {
            confirm({
                text: 'This user has already been verified.',
                acceptText: null,
                rejectText: 'Ok',
            });
        }
    };

    updatePermissionData(event) {
        let newUsers = this.state.users;
        newUsers[this.state.rowIndex].site_role = event.target.value;
        let userToUpdate = newUsers[this.state.rowIndex];

        this.setState({ users: newUsers, forceSetData: true }, function () {
            this.setState({ forceSetData: false });
        });

        if (userToUpdate) {
            UsersApi.updateUser(userToUpdate.id, {
                site_role: userToUpdate.site_role,
            })
                .then(() => {})
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        if (!this.state.siteRoles) return <div>loading...</div>;
        let siteRoleOptions = Object.values(this.state.siteRoles);
        return (
            <div>
                <PageHeader title="Users & Permissions" />
                <AdminSubNav />
                <div className="">
                    <div className="Single-layout Companion-form">
                        <Formik
                            initialValues={{
                                newEmailAddress: '',
                                newName: '',
                                newPhone: '',
                            }}
                            validationSchema={yup.object().shape({
                                newEmailAddress: yup.string().email('Invalid email').required(),
                                newName: yup.string().required(),
                                newPhone: yup.string().required(),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({ errors, touched, resetForm }) => (
                                <Form>
                                    <LabeledField
                                        label="Name*"
                                        name="newName"
                                        component="input"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Email Address*"
                                        name="newEmailAddress"
                                        component="input"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Phone*"
                                        name="newPhone"
                                        component="Phone"
                                        errors={errors}
                                        touched={touched}
                                        type="tel"
                                    />
                                    <div className="Form__buttons--Reversed Buttons_format">
                                        <button type="button" onClick={() => resetForm()}>
                                            Cancel
                                        </button>
                                        <button type="submit">Invite User</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="Table-styling">
                        <EditingTable
                            data={this.state.users}
                            columns={[
                                {
                                    headerName: 'Name',
                                    field: 'name',
                                    editable: true,
                                    sort: 'desc',
                                    type: 'name',
                                },
                                {
                                    headerName: 'Permissions',
                                    field: 'site_role',
                                    editable: true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values: siteRoleOptions,
                                    },
                                    type: 'name',
                                },
                                {
                                    headerName: 'Email',
                                    field: 'email_address',
                                    editable: false,
                                    type: 'email',
                                },
                                {
                                    headerName: 'Phone',
                                    field: 'phone_number',
                                    type: 'phone',
                                    editable: true,
                                    sortable: true,
                                },
                                {
                                    headerName: 'Reset Password',
                                    field: 'resetPassword',
                                    editable: false,
                                    onCellClicked: this.resetPassword,
                                    cellRenderer() {
                                        return '<a>Reset Password</a>';
                                    },
                                    sortable: true,
                                },
                                {
                                    headerName: 'Resend Confirmation Code',
                                    field: 'resetPassword',
                                    editable: false,
                                    onCellClicked: this.resendCode,
                                    cellRenderer() {
                                        return '<a>Resend Code</a>';
                                    },
                                    sortable: true,
                                },
                                {
                                    headerName: 'Edit',
                                    field: 'edit',
                                    type: 'symbol',
                                    editable: false,
                                    cellRenderer: 'EditSymbolRenderer',
                                    onCellClicked: this.getRowData,
                                },
                                {
                                    headerName: 'Del.',
                                    field: 'delete',
                                    type: 'symbol',
                                    editable: false,
                                    cellRenderer: 'TrashSymbolRenderer',
                                    onCellClicked: this.onRemoveSelected,
                                },
                            ]}
                            rowIndex={this.state.rowIndex}
                            colKey={this.state.colKey}
                            sizeToFit={true}
                            rowValueChanged={this.onRowValueChanged}
                            addedNewData={this.state.forceSetData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersAndPermissionsPage;
