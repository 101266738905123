// React
import React, { useCallback, useEffect, useState } from 'react';

// Components
import FinanceSubNav from './FinanceSubNav';
import BankStatementItemReviewTable from './bankStatements/BankStatementItemReviewTable';
import BankStatementLineItemTable from './bankStatements/BankStatementLineItemTable';
import CreditCardItemReviewTable from './bankStatements/CreditCardItemReviewTable';
import PageHeader from '../components/heading/PageHeader';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';
import errorToast from '../components/messages/ErrorMessage';
import BankStatementsReviewButtonBar from './bankStatements/bankStatementsReviewButtonBar';

// API
import StatementsApi from '../api/bankStatements';
import ExpendituresApi from '../api/expenditures';

const BankStatementsReviewPage = () => {
    const [reviewGridApi, setReviewGridApi] = useState('');
    const [inReview, setInReview] = useState('');
    const [notReconciled, setNotReconciled] = useState('');
    const [unmatchedCC, setUnmatchedCC] = useState('');
    const [selectedCCEntry, setSelectedCCEntry] = useState('');
    const [selectedNotReconciledEntry, setSelectedNotReconciledEntry] = useState('');
    const [selectedReviewEntries, setSelectedReviewEntries] = useState('');
    const [expenditures, setExpenditures] = useState('');
    const [forceSetData, setForceSetData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: statements } = await StatementsApi.fetchAllStatementItems();
                const { data: expenditures } = await ExpendituresApi.fetchAllMatchableUnmatchedExpenditures();

                setUnmatchedCC(expenditures.filter((expenditure) => !expenditure.matched));

                setNotReconciled(statements.filter((s) => s.status === 'Not Reconciled'));
                //TODO: Merge filters into performant setInReview; filter steps include
                // 1. filter statements by status review, and a transaction_id exists
                // 2. to each filtered statement item attach the corresponding expenditure on transaction_id
                // 3. filter the statements again where a statement/expenditure match exists

                const reviewPrep = statements.filter((s) => s.status === 'Review' && s.transaction_id);
                reviewPrep.forEach((s) => {
                    s.expenditure = expenditures.find((e) => e.transaction_id === s.transaction_id);
                });
                setInReview(reviewPrep.filter((s) => s.expenditure));
            } catch (errorMessage) {
                errorToast(errorMessage);
            }
        };
        fetchData();
    }, []);

    const setSelectedReview = (event) => {
        setSelectedReviewEntries(event.api.getSelectedRows());
        setReviewGridApi(event.api);
    };

    const hoistSelectedStatementLineItem = (selectedItem) => {
        setSelectedNotReconciledEntry(selectedItem);
    };

    const hoistSelectedCreditCardItem = (selectedItem) => {
        setSelectedCCEntry(selectedItem);
    };
    const loadAllExpenditures = useCallback(async () => {
        setUnmatchedCC([]);
        try {
            const { data: expenditures } = await ExpendituresApi.fetchAllExpendituresNoAccounts();
            setUnmatchedCC(expenditures.filter((expenditure) => !expenditure.matched));
        } catch (error) {
            errorToast(error);
        }
    }, []);

    const unmatch = async () => {
        if (selectedReviewEntries !== null && selectedReviewEntries.length > 0) {
            selectedReviewEntries.forEach((entry) => {
                let attributes = {
                    status: 'Not Reconciled',
                };

                StatementsApi.updateStatementItem(entry.id, attributes)
                    // eslint-disable-next-line prettier/prettier
                    .then(() => {
                            reviewGridApi.updateRowData({ remove: [entry] });
                            setSelectedReviewEntries(null);
                            setNotReconciled(notReconciled.unshift(entry));
                            setUnmatchedCC(unmatchedCC.unshift(entry.expenditure));
                            setForceSetData(true);
                        },
                        () => {
                            setForceSetData(false);
                        }
                    )
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            });
        } else {
            errorToast('No selected entries to unmatch!');
        }
    };

    const requestReceipts = async () => {
        if (selectedNotReconciledEntry) {
            let statementToNotify;
            if (selectedNotReconciledEntry.data.card_holder_id) {
                statementToNotify = selectedNotReconciledEntry.data.id;
            } else {
                errorToast('Cardholder is not in the system yet!');
                return;
            }

            StatementsApi.requestReceipt(statementToNotify)
                .then(() => {
                    selectedNotReconciledEntry.gridApi.deselectAll();
                    setSelectedNotReconciledEntry(null);
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        } else {
            errorToast('Please select from Unreconciled Line Items when requesting receipts');
        }
    };

    const reconcile = () => {
        let attributes = {
            status: 'Reconciled',
        };

        if (selectedCCEntry && selectedNotReconciledEntry) {
            attributes = {
                ...attributes,
                transaction_id: selectedCCEntry.data.transaction_id,
            };

            StatementsApi.updateStatementItem(selectedNotReconciledEntry.data.id, attributes)
                .then(() => {
                    let ccGridApi = selectedCCEntry.gridApi;
                    let unRecGridApi = selectedNotReconciledEntry.gridApi;

                    ccGridApi.updateRowData({ remove: [selectedCCEntry.data] });
                    ccGridApi.deselectAll();

                    unRecGridApi.updateRowData({
                        remove: [selectedNotReconciledEntry.data],
                    });
                    unRecGridApi.deselectAll();

                    setSelectedCCEntry(null);
                    setSelectedNotReconciledEntry(null);
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        } else if (selectedReviewEntries !== null && selectedReviewEntries.length > 0) {
            selectedReviewEntries.forEach((entry) => {
                attributes = {
                    ...attributes,
                    transaction_id: entry.transaction_id,
                };
                StatementsApi.updateStatementItem(entry.id, attributes)
                    .then(() => {
                        reviewGridApi.updateRowData({ remove: [entry] });
                        reviewGridApi.deselectAll();

                        setSelectedReviewEntries(null);
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            });
        } else {
            errorToast('No selected entries to reconcile!');
        }
    };

    return (
        <div>
            <PageHeader title="Bank Statements" />
            <FinanceSubNav />
            <SubSubNav>
                <SubSubNavLink to="/finance/bankStatements" exact>
                    View Bank Statements
                </SubSubNavLink>
                <SubSubNavLink to="/finance/bankStatements/import">Import Bank Statement</SubSubNavLink>
                <SubSubNavLink to="/finance/bankStatements/review">Review &amp; Reconcile</SubSubNavLink>
            </SubSubNav>
            <div className="flex flex-column">
                <div className="Two-col-table">
                    <div className="flex flex-column column-span-6">
                        <h4 className="">REVIEW</h4>
                        <BankStatementItemReviewTable
                            records={inReview}
                            expenditures={expenditures}
                            selectionType="multiple"
                            setSelectedReview={setSelectedReview}
                        />
                    </div>
                    <div className="flex flex-column column-span-3">
                        <h4 className="">UNRECONCILED LINE ITEMS</h4>
                        <BankStatementLineItemTable
                            records={notReconciled}
                            hoistSelectedStatementLineItem={hoistSelectedStatementLineItem}
                        />
                    </div>
                    <div className="flex flex-column column-span-3">
                        <h4 className="">UNMATCHED CC ENTRIES</h4>
                        <CreditCardItemReviewTable
                            records={unmatchedCC}
                            hoistSelectedCreditCardItem={hoistSelectedCreditCardItem}
                        />
                    </div>
                    <BankStatementsReviewButtonBar
                        loadAllExpenditures={loadAllExpenditures}
                        unmatch={unmatch}
                        requestReceipts={requestReceipts}
                        reconcile={reconcile}
                    />
                </div>
            </div>
        </div>
    );
};

export default BankStatementsReviewPage;
