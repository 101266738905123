import React, { Component } from 'react';
import GridSection from '../../components/section/GridSection';

import ProjectsApi from '../../api/projects';

class FinancialSumChartWrapper extends Component {
    constructor(props) {
        super(props);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleNewFinancialSummaryDate = this.handleNewFinancialSummaryDate.bind(this);

        this.state = {
            financial_summary_errors: null,
            financial_summary_start: '',
            financial_summary_end: '',
            latestWeeklyStatus: null,
        };
    }

    async componentDidMount() {
        try {
            let projectId = this.props.project.id;
            const { data: activity_reports } = await ProjectsApi.fetchActivityReports(projectId);
            let weekly_activity = [];
            if (activity_reports.length > 0) {
                activity_reports.forEach((r) => {
                    if (r.report_type === 'weekly') {
                        weekly_activity.push(r);
                    }
                });

                weekly_activity.sort((a, b) => new Date(b.from_date) - new Date(a.from_date));

                this.setState({ latestWeeklyStatus: weekly_activity[0] });
            }
        } catch (errorMessage) {
            console.error(errorMessage);
        }
    }

    handleStartDateChange = (e) => {
        this.setState({ financial_summary_start: e.target.value });
    };

    handleEndDateChange = (e) => {
        this.setState({ financial_summary_end: e.target.value });
    };

    handleNewFinancialSummaryDate = () => {
        // e, values, actions
        //   console.log('e', e);
        //   console.log('values', values);
    };

    render() {
        const { latestWeeklyStatus } = this.state;
        return (
            <GridSection title="Latest Weekly Activity Report" area="summary">
                <h3>Latest Weekly Activity Report</h3>
                {!latestWeeklyStatus ? (
                    <div>
                        <h6>There are no weekly activity reports yet</h6>
                    </div>
                ) : (
                    <div>
                        <h6>
                            {latestWeeklyStatus.from_date} – {latestWeeklyStatus.to_date}
                        </h6>
                        <p className="Overview-impact">{latestWeeklyStatus.impact}</p>
                    </div>
                )}
            </GridSection>
        );
    }
}

export default FinancialSumChartWrapper;
