import React from 'react';
import { Formik, Form } from 'formik';
import yup from '../yup';

import ProjectsApi from '../api/projects';

import { shortDate } from '../utils/utils';

import StatusSymbolRenderer from '../components/symbols/StatusSymbol';
import IssuesTable from './OverviewTables/IssuesTable';
import OpenActionItemsTable from './OverviewTables/OpenActionItemsTable';
import GoalsTable from './OverviewTables/GoalsTable';
import LabeledField from '../components/forms/LabeledField';
import GridSection from '../components/section/GridSection';

import FinancialSumChartWrapper from './OverviewTables/FinancialSumChartWrapper';
import ProjectFinancialInfo from './ProjectFinancialInfo';
import errorToast from '../components/messages/ErrorMessage';

class OverviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.getRowData = this.getRowData.bind(this);
        this.handleNewLastReviewDate = this.handleNewLastReviewDate.bind(this);

        this.state = {
            project: null,
            latestStatus: null,
        };
    }

    async componentDidMount() {
        try {
            let projectId = this.props.match.params.id;
            const { data: project } = await ProjectsApi.fetchProject(projectId);
            const { data: status } = await ProjectsApi.listStatus(projectId);

            let sortedstatus = status.sort((e, f) => new Date(f.date).getTime() - new Date(e.date).getTime());
            if (project.wbi_lead_user_id) {
                project.users.forEach((user) => {
                    if (project.wbi_lead_user_id === user.id) {
                        project.wbi_lead_user_name = user.name;
                    }
                });
            }

            let latestStatus = sortedstatus[0];

            this.setState({
                project,
                latestStatus,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'description',
        });
    }

    handleNewLastReviewDate = (values, actions) => {
        const { project } = this.state;
        let projectId = this.props.match.params.id;
        let attributes = {
            last_reviewed_on: values.last_reviewed_on,
            people: [],
            wbi_lead_user_id: project.wbi_lead_user_id,
            wbi_assistant_user_id: project.wbi_assistant_user_id,
        };
        project.users.forEach((e) => {
            attributes.people.push(e.id);
        });
        ProjectsApi.updateProject(projectId, attributes)
            .then(() => {
                let updatedProject = this.state.project;
                updatedProject.last_reviewed_on = values.last_reviewed_on;
                if (updatedProject.wbi_lead_user_id) {
                    updatedProject.users.forEach((user) => {
                        if (updatedProject.wbi_lead_user_id === user.id) {
                            updatedProject.wbi_lead_user_name = user.name;
                        }
                    });
                }
                actions.resetForm();
                this.setState({ project: updatedProject });
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    };

    render() {
        if (!this.state.project) return <div>loading...</div>;

        const { project } = this.state;
        let keyStatusSection = (
            <div>
                <div className="bold">
                    Last Status Update - Last Updated:{' '}
                    {this.state.latestStatus ? shortDate(this.state.latestStatus.date) : ''}
                </div>
                <div className="flex">
                    {this.state.latestStatus ? (
                        <div className="overview-status">
                            <StatusSymbolRenderer status={this.state.latestStatus.status} />
                        </div>
                    ) : null}
                    <p>{this.state.latestStatus ? this.state.latestStatus.description : ''}</p>
                </div>
            </div>
        );

        return (
            <div className="ProjectOverviewPage">
                <GridSection title="Identifying Information" area="identity">
                    <table className="data">
                        <tbody>
                            <tr>
                                <td>WBI PM:</td>
                                <td>{project.wbi_lead_user_name}</td>
                            </tr>
                            <tr>
                                <td>AFRL Customer Name:</td>
                                <td>{project.customer_name}</td>
                            </tr>
                            <tr>
                                <td>Office Symbol:</td>
                                <td>{project.office_symbol ? project.office_symbol.name : null}</td>
                            </tr>
                            <tr>
                                <td>Project Status:</td>
                                <td>{project.status}</td>
                            </tr>
                            <tr>
                                <td>Project Type:</td>
                                <td>{project.primary_type !== undefined ? project.primary_type.name : ''}</td>
                            </tr>
                            <tr>
                                <td>Project Description Status:</td>
                                <td>
                                    {project.pd_status_complete_date
                                        ? `${project.pd_status} on ${shortDate(project.pd_status_complete_date)}`
                                        : project.pd_status}
                                </td>
                            </tr>
                            {project.last_reviewed_on ? (
                                <tr>
                                    <td>Last Reviewed:</td>
                                    <td>{shortDate(project.last_reviewed_on)}</td>
                                </tr>
                            ) : null}
                            <tr>
                                <td colSpan="2">
                                    <Formik
                                        initialValues={{ last_reviewed_on: '' }}
                                        validationSchema={yup.object().shape({
                                            last_reviewed_on: yup.date().required(),
                                        })}
                                        onSubmit={this.handleNewLastReviewDate}
                                    >
                                        {({ errors, touched }) => (
                                            <Form className="Form-in-table">
                                                <LabeledField
                                                    label="ERGO Reviewed:"
                                                    name="last_reviewed_on"
                                                    type="date"
                                                    component="Date"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <div className="Form__buttons--Reversed Buttons_format right">
                                                    <button type="submit">Save</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GridSection>

                <GridSection title="Brief Overview" area="overview">
                    <p>{project.overview}</p>
                </GridSection>

                <GridSection title="Project Activity" area="activity">
                    {keyStatusSection}

                    <div className="bold">Open Action Items:</div>
                    <OpenActionItemsTable project={project} />

                    <div className="bold">Issues:</div>
                    <IssuesTable project={project} />

                    <div className="bold">Key Goals and Objectives:</div>
                    <GoalsTable project={project} />

                    <ProjectFinancialInfo projectId={this.state.project.id} />
                    <button
                        type="button"
                        className="End-button"
                        onClick={() => {
                            window.print();
                            return false;
                        }}
                    >
                        Print
                    </button>
                </GridSection>

                <FinancialSumChartWrapper project={this.state.project} />
            </div>
        );
    }
}
export default OverviewPage;
