import React, { useState, useEffect } from 'react';
import Modal from '../../components/modals/Modal';

const AgreementInvoiceMessageModal = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const { closeModal } = props;

    useEffect(() => {
        setOpenModal(props.openModal);
    }, [props.openModal]);

    return (
        <Modal isOpen={openModal} style={{ minWidth: '30vw' }}>
            <div className="flex Align-right">
                <button onClick={closeModal}>
                    <i className="fa fa-window-close" />
                </button>
            </div>
            <div>
                <p>{props.message}</p>
            </div>
        </Modal>
    );
};

export default AgreementInvoiceMessageModal;
