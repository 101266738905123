import React, { Component } from 'react';

import AuthApi from '../api/auth';
import { history } from '../store/store';
import AuthService from '../auth/auth.service';
import UserButton from '../components/buttons/UserButton';
import UserMessage from '../components/messages/UserMessage';

class LoginForm extends Component {
    constructor(props, context) {
        super(props, context);

        let initialEmail = AuthService.getEmail() || '';

        this.state = {
            email: initialEmail,
            isbusy: false,
            password: '',
            errorMessage: null,
        };

        this.onDone = this.props.onDone;
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(evt) {
        let changes = {};
        changes[evt.target.name] = evt.target.value;
        this.setState(changes);
    }

    onSubmit(event) {
        event.preventDefault();
        const { email, password } = this.state;
        this.setState({ errorMessage: null, isbusy: true }, () => {
            AuthApi.init_auth(email, password)
                .then((resp) => {
                    if (resp && resp.need_mfa) {
                        this.onDone({
                            need_mfa: resp.need_mfa,
                            email,
                            password,
                            session: resp.session,
                        });
                    } else {
                        let destination = localStorage.getItem('WBI.RETURN_LOCATION');
                        if (destination) {
                            localStorage.removeItem('WBI.RETURN_LOCATION');
                        } else {
                            destination = '/';
                        }
                        history.push(destination);
                    }
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage, isbusy: false });
                });
        });
    }

    render() {
        return (
            <form onSubmit={this.onSubmit} className="LoginForm">
                <div className="AccountPage__form">
                    <div className="Form__iconfield">
                        <label htmlFor="email" title="Enter your email">
                            <i className="fa fa-envelope" />
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="you@example.com"
                            autoCapitalize="off"
                            autoCorrect="off"
                            required="required"
                            value={this.state.email}
                            onChange={this.onChange}
                        />
                    </div>

                    <div className="Form__iconfield">
                        <label htmlFor="password" title="Enter your password">
                            <i className="fa fa-lock" />
                        </label>
                        <input
                            type="password"
                            name="password"
                            autoComplete="off"
                            placeholder="password"
                            required="required"
                            onChange={this.onChange}
                        />
                    </div>

                    <div className="Form__buttons--Reversed-clean">
                        <UserButton
                            type="submit"
                            defaultText="Sign in"
                            busyText="Signing in"
                            isbusy={this.state.isbusy}
                        />
                    </div>
                    <div className="Form__footer">
                        <UserMessage error={this.state.errorMessage} />
                    </div>
                </div>
            </form>
        );
    }
}

export default LoginForm;
