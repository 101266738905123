// React
import React from 'react';

//Components
import MyProjects from './MyProjects';
import MyActionItems from './MyActionItems';
import OpenNotifications from '../components/notifications/OpenNotifications';
import PageHeader from '../components/heading/PageHeader';
import GridSection from '../components/section/GridSection';
import StatusUpdate from './StatusUpdate';

const ServDirHomePage = (props) => {
    let myProjectsTitle;
    if (props.service) {
        myProjectsTitle = props.service.name + ' Projects';
    } else {
        myProjectsTitle = 'My Projects';
    }

    return (
        <div>
            <PageHeader title="Service Director Home" />
            <div className="Homepage Homepage--Service">
                <GridSection title={myProjectsTitle} area="projects">
                    <MyProjects />
                </GridSection>
                <GridSection title="Open Notifications" area="notification">
                    <OpenNotifications isFinance={false} />
                </GridSection>
                <GridSection title="Status Update" area="status">
                    <StatusUpdate service={props.service} />
                </GridSection>
                <GridSection title="My Action Items" area="actions">
                    <MyActionItems />
                </GridSection>
            </div>
        </div>
    );
};

export default ServDirHomePage;
