import React from 'react';
import Table from '../../../../components/tables/Table';
import errorToast from '../../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../../components/buttons/ExportCSVButton';
import ProjectsApi from '../../../../api/projects';

class NoSignedPD extends React.Component {
    constructor(props) {
        super(props);

        this.onTableReady = this.onTableReady.bind(this);

        this.state = {
            data: null,
        };
    }

    async componentDidMount() {
        const { data: missingPDSignatures } = await ProjectsApi.fetchAllProjectsPDSignatureStatus();

        this.updateData(missingPDSignatures);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.updateData(this.props.data, this.props.date);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    async updateData(data) {
        try {
            data.forEach((s) => {
                s.signed_pd = 'Not Signed';
            });
            this.setState({
                data,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        const { data } = this.state;

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="project-pd-status" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div>
                    <Table
                        columns={[
                            {
                                headerName: 'Project No.',
                                field: 'project_num',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'id',
                                },
                                type: 'idNum',
                            },
                            { headerName: 'Project', field: 'project_name', type: 'mediumtext' },
                            { headerName: 'Signed PD', field: 'signed_pd' },
                            { headerName: 'Start Date', field: 'start_date', type: 'date' },
                            {
                                headerName: 'Estimated End Date',
                                field: 'estimated_end_date',
                                type: 'date',
                            },
                            { headerName: 'Office Symbol', field: 'office_symbol' },
                        ]}
                        data={data}
                        filter={true}
                        sortable={true}
                        onTableReady={this.onTableReady}
                    />
                </div>
            </div>
        );
    }
}
export default NoSignedPD;
