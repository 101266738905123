// React
import React from 'react';

//Components
import OpenNotifications from '../components/notifications/OpenNotifications';
import StatusUpdate from './StatusUpdate';
import FinancialInfo from './exec_Director/FinancialInfo';
import AccomplishmentReport from './exec_Director/AccomplishmentReport';
import ActionItems from './exec_Director/MyActionItems';
import PageHeader from '../components/heading/PageHeader';
import GridSection from '../components/section/GridSection';

const ExecDirHomePage = (props) => (
    <div>
        <PageHeader title="Executive Director Home" />
        <div className="Homepage Homepage--Executive">
            <GridSection title="Status Update" area="status">
                <StatusUpdate />
            </GridSection>
            <GridSection title="Action Items" area="actions">
                <ActionItems />
            </GridSection>
            <GridSection title="Open Notifications" area="notifications">
                <OpenNotifications />
            </GridSection>
            <GridSection title="Financial Information" area="finance">
                <div className="Section">
                    <FinancialInfo match={props.match} />
                </div>
            </GridSection>
            <GridSection title="Weekly Accomplishment Report" area="table">
                <AccomplishmentReport />
            </GridSection>
        </div>
    </div>
);

export default ExecDirHomePage;
