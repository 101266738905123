import { createStore, applyMiddleware, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const createRootReducer = combineReducers({
    router: connectRouter(history),
});

const store = createStore(connectRouter(history)(createRootReducer), applyMiddleware(routerMiddleware(history)));

export default store;
