import api from './api';

import endpoints from './endpoints';

const grantsRoute = endpoints.GRANTS;

export default {
    getGrants() {
        return api.get(grantsRoute, 'Error getting grants');
    },

    createGrant(attributes) {
        return api.post(grantsRoute, attributes, 'Error creating a grant');
    },

    updateGrant(grant_id, attributes) {
        return api.put(`${grantsRoute}/${grant_id}`, attributes, 'Error updating grant');
    },

    removeGrant(grant_id) {
        return api.delete(`${grantsRoute}/${grant_id}`, 'Error removing grant');
    },

    updateGrantProject(grant_id, id, attributes) {
        return api.put(`${grantsRoute}/${grant_id}/grant_projects/${id}`, attributes, 'Error updating a grant project');
    },

    removeGrantProject(grant_id, id) {
        return api.delete(`${grantsRoute}/${grant_id}/grant_projects/${id}`, 'Error removing a grant project');
    },
};
