import React from 'react';
import yup from '../../../yup';
import FieldWithError from '../../../components/forms/FieldWithError';
import FieldArray from '../../../components/forms/FieldArray';
import DraggingTable from '../../../components/tables/DraggingTable';
import ProjectsApi from '../../../api/projects';

class OtherRolesTable extends React.Component {
    constructor(props) {
        super(props);

        this.onRowDragEnd = this.onRowDragEnd.bind(this);
        this.onRowDragEnter = this.onRowDragEnter.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.addRow = this.addRow.bind(this);
        this.getRowData = this.getRowData.bind(this);

        this.state = {
            row: null,
            rowIndex: null,
            colKey: '',
            errorMessage: '',
        };
    }

    handleOtherRoles(otherRoles) {
        this.props.handleOtherRoles(otherRoles);
    }

    onRemoveSelected(evt) {
        const { data } = evt;

        ProjectsApi.removeOtherRole(data.project_id, data.id).then(() => {
            const otherRoles = this.props.other_roles.filter((o) => o.id !== data.id);
            this.handleOtherRoles(otherRoles);
        });
    }

    onRowValueChanged(evt) {
        const { data } = evt;
        const { other_roles } = this.props;
        let idx = other_roles.findIndex((i) => i.id === data.id);
        other_roles[idx] = data;

        ProjectsApi.updateProjectDataPageTables(data.project_id, 'OtherRoles', other_roles)
            .then((response) => {
                if (response) {
                    this.setState({
                        row: null,
                        rowIndex: null,
                        colKey: '',
                    });
                }
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    getRowData(evt) {
        this.setState({
            row: evt.node.data,
            rowIndex: evt.node.rowIndex,
            colKey: 'description',
        });
    }

    onRowDragEnter = (event) => {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
        });
    };

    onRowDragEnd = (event) => {
        let newIndex = event.node.rowIndex;
        let rowIndex = this.state.rowIndex;
        let movingItem = event.node.data;
        let secondPartArray = this.props.other_roles.filter((_, i) => rowIndex !== i);
        let firstPartArray = secondPartArray.splice(0, newIndex);
        firstPartArray.push(movingItem);

        let newArray = firstPartArray.concat(secondPartArray);

        for (let i = 0; i < newArray.length; i++) {
            newArray[i].order = i;
        }

        ProjectsApi.updateProjectDataPageTables(event.node.data.project_id, 'OtherRoles', newArray)
            .then(() => {
                this.props.handleOtherRoles(newArray);
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    };

    addRow(valueToUpdate) {
        const { projectId } = this.props;
        if (projectId !== 'new') {
            const attributes = {
                description: valueToUpdate.description,
                order: this.props.other_roles.length,
            };
            ProjectsApi.createOtherRole(projectId, attributes)
                .then((response) => {
                    const otherRoles = this.props.other_roles.map((o) => o);
                    const { data } = response;
                    const newOtherRole = {
                        description: data.description,
                        id: data.id,
                        project_id: data.project_id,
                        order: data.order,
                    };
                    otherRoles.push(newOtherRole);
                    this.handleOtherRoles(otherRoles);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        const { values, errors, isEditing } = this.props;
        let other_roles = this.props.other_roles.sort((a, b) => a.order - b.order);

        return (
            <FieldArray
                label="Other Affiliates Roles & Responsibilities"
                name="other_roles"
                initialValues={{ description: '' }}
                onRemoveSelected={this.onRemoveSelected}
                addRow={this.addRow}
                validationSchema={yup.object().shape({
                    description: yup.string().required(),
                })}
                values={values}
                errors={errors}
                readOnly={!isEditing}
                renderArray={() => (
                    <DraggingTable
                        data={other_roles}
                        columns={[
                            {
                                headerName: 'Other Affiliates Roles & Responsibilities',
                                field: 'description',
                                editable: true,
                                type: 'longtext',
                                rowDrag: true,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Edit',
                                field: 'edit',
                                type: 'symbol',
                                cellRenderer: 'EditSymbolRenderer',
                                onCellClicked: this.getRowData,
                            },
                            {
                                hide: !isEditing,
                                headerName: 'Del.',
                                field: 'delete',
                                type: 'symbol',
                                cellRenderer: 'TrashSymbolRenderer',
                                onCellClicked: this.onRemoveSelected,
                            },
                        ]}
                        edit={isEditing}
                        drag={isEditing}
                        rowDragEnd={this.onRowDragEnd}
                        rowDragEnter={this.onRowDragEnter}
                        rowValueChanged={this.onRowValueChanged}
                        sizeToFit={!isEditing}
                    />
                )}
                renderFields={({ errors, handleKeyPress }) => (
                    <FieldWithError
                        name="description"
                        placeholder="Add item..."
                        component="textarea"
                        onKeyPress={handleKeyPress}
                        errors={errors}
                    />
                )}
            />
        );
    }
}

export default OtherRolesTable;
