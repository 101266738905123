import React from 'react';

const PasswordReq = (props) => (
    <div className="Pass-instructions">
        <p>Passwords must be/have:</p>
        <ul>
            <li className={props.validPasswordPattern.length ? 'Pass-instructions' : null}> Atleast 8 character</li>
            <li className={props.validPasswordPattern.uc ? 'Pass-instructions' : null}> Atleast 1 Uppercase Letter</li>
            <li className={props.validPasswordPattern.lc ? 'Pass-instructions' : null}> Atleast 1 Lowercase Letter</li>
            <li className={props.validPasswordPattern.num ? 'Pass-instructions' : null}>Atleast 1 Number</li>
            <li className={props.validPasswordPattern.sym ? 'Pass-instructions' : null}>Atleast 1 Symbol</li>
        </ul>
    </div>
);

export default PasswordReq;
