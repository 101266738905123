import api from './api';

import endpoints from './endpoints';

const coreBudgetRoute = endpoints.CORE_BUDGET_TYPES;

export default {
    getCoreBudgetTypes() {
        return api.get(coreBudgetRoute, 'Error getting list of core budget types');
    },

    getCoreBudgetType(id) {
        return api.get(`${coreBudgetRoute}/${id}`, 'Error getting core budget type');
    },

    getCoreBudgetExpenditures() {
        return api.get(`${coreBudgetRoute}/expenditures`, 'Error getting core budget expenditures');
    },

    createCoreBudgetType(userAttributes) {
        return api.post(coreBudgetRoute, userAttributes, 'Error creating a new core budget type');
    },

    removeCoreBudgetType(id) {
        return api.delete(`${coreBudgetRoute}/${id}`, 'Error removing core budget type');
    },

    updateCoreBudgetType(id, userAttributes) {
        return api.put(`${coreBudgetRoute}/${id}`, userAttributes, 'Error updating core budget type');
    },
};
