import createDropdownOption from './DropdownUtils';

export const createCpoOptions = (cpos) => {
    try {
        return cpos.map((cpo) => createDropdownOption(cpo.number, cpo.id));
    } catch (error) {
        throw new Error('Error creating CPO options');
    }
};

export const filterCposOnPiaId = (cpos, id) => {
    try {
        return cpos.filter((cpo) => cpo.pia_id === id);
    } catch (error) {
        throw new Error('Error filtering CPOs on PIA');
    }
};
