import api from './api';

import endpoints from './endpoints';

const piaRoute = endpoints.PIA;

export default {
    getPias() {
        return api.get(piaRoute, 'Error getting list of PIAs');
    },

    createPia(piaAttributes) {
        return api.post(piaRoute, piaAttributes, 'Error creating a new PIA');
    },

    updatePia(piaId, piaAttributes) {
        return api.put(`${piaRoute}/${piaId}`, piaAttributes, 'Error creating a new PIA');
    },
};
