import React from 'react';
import StatusSymbol from './StatusSymbol';

const StatusSymbolRenderer = (props) => (
    <div>
        <StatusSymbol status={props.value} />
    </div>
);

export default StatusSymbolRenderer;
