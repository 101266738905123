import React, { Component } from 'react';

import { history } from '../../store/store';
import AuthApi from '../../api/auth';
import AuthService from '../../auth/auth.service';
import UserButton from '../../components/buttons/UserButton';
import UserMessage from '../../components/messages/UserMessage';
import UserInput from '../../components/input/UserInput';
import passwordValidate from '../passwordValidation';
import PasswordReq from '../PasswordReq';

class ResetConfirmForm extends Component {
    constructor(props, context) {
        super(props, context);

        const initialEmail = AuthService.getEmail() || '';
        this.state = {
            email: initialEmail,
            code: '',
            password: '',
            passwordconfirm: '',
            validPasswordPattern: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onChange(evt) {
        let changes = {};
        changes[evt.target.name] = evt.target.value;
        changes.validPasswordPattern = passwordValidate(evt.target.value);

        this.setState(changes);
    }

    onFormSubmit(event) {
        event.preventDefault();

        const { email, confirmationCode, newPassword, passwordconfirm } = this.state;

        let trimCode = confirmationCode.trim();

        if (newPassword !== passwordconfirm) {
            return this.setState({
                errorMessage: 'Passwords do not match',
                isbusy: false,
            });
        }
        return this.setState({ errorMessage: null, isbusy: true }, () => {
            AuthApi.confirm(email, trimCode, newPassword)
                .then(() => {
                    this.setState({ errorMessage: null, isbusy: false });
                    history.push('/');
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage, isbusy: false });
                });
        });
    }

    render() {
        return (
            <form onSubmit={this.onFormSubmit} className="AccountPage__form">
                <UserInput
                    name="email"
                    label="Email Address"
                    type="email"
                    placeholder="you@example.com"
                    value={this.state.email}
                    onChange={this.onChange}
                />

                <UserInput
                    name="confirmationCode"
                    label="Confirmation Code"
                    type="text"
                    placeholder="123456"
                    value={this.state.confirmationCode}
                    onChange={this.onChange}
                />

                <UserInput
                    name="newPassword"
                    label="New Password:"
                    type="password"
                    value={this.state.newPassword}
                    onChange={this.onChange}
                />
                <PasswordReq validPasswordPattern={this.state.validPasswordPattern} />

                <UserInput
                    name="passwordconfirm"
                    label="Confirm Password:"
                    type="password"
                    value={this.state.passwordconfirm}
                    onChange={this.onChange}
                />

                <div className="Form__buttons--Reversed-clean">
                    <UserButton
                        type="submit"
                        defaultText="Reset password"
                        busyText="Resetting password"
                        isbusy={this.state.isbusy}
                    />
                </div>

                <div className="Form__footer">
                    <UserMessage error={this.state.errorMessage} />
                </div>
            </form>
        );
    }
}

export default ResetConfirmForm;
