import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import TableFactory from './factory/tableFactory';
import PageSizeSelector from './pagingTable/pageSizeSelector';
import useFunctionAsState from '../../hooks/useFunctionAsState';

const EditingTable = ({
    data,
    columns,
    rowIndex,
    colKey,
    sizeToFit,
    selectionProp,
    pagingProp,
    pageSizeProp,
    selectionChanged,
    onTableReady,
    addedNewData,
    cellEditingStopped,
    rowValueChanged,
    overrideGridReady,
    hoistPinnedBottomRowData,
    removeRows,
    sortable,
    filter,
    editable,
    tableClassName,
}) => {
    const DEFAULT_PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [startPageSize, setStartPageSize] = useState();
    const [paging, setPaging] = useState(pagingProp);
    const [selection, setSelection] = useState(selectionProp);
    const [onSelectionChanged, setOnSelectionChanged] = useFunctionAsState(null);
    const [onRowValueChanged, setOnRowValueChanged] = useFunctionAsState(null);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [gridApi, setGridApi] = useFunctionAsState(null);

    const resizeGrid = useCallback(() => {
        if (gridApi) {
            gridApi.resetRowHeights();
            if (sizeToFit) {
                gridApi.sizeColumnsToFit();
            }
        }
    }, [gridApi, sizeToFit]);

    useEffect(() => {
        resizeGrid();
    }, [gridApi, resizeGrid]);

    const onGridReady = useCallback(
        (params) => {
            const { api: gridApi } = params;
            setGridApi(gridApi);

            const exportDataAsCsv = (nameOfExport) => {
                const filename = nameOfExport + '-' + moment().format('YYYYMDDHHmmss');
                gridApi.exportDataAsCsv({ fileName: filename });
            };

            const removeSelected = () => {
                let selectedRows = gridApi.getSelectedRows();
                gridApi.applyTransaction({ remove: selectedRows });
                gridApi.deselectAll();
            };

            if (onTableReady && typeof onTableReady === 'function') {
                params.exportDataAsCsv = exportDataAsCsv;
                onTableReady(params);
            }
            if (removeRows && typeof removeRows === 'function') {
                params.removeSelected = removeSelected;
                removeRows(params);
            }
            if (overrideGridReady && typeof overrideGridReady === 'function') {
                overrideGridReady(params);
            }
        },
        [setGridApi, removeRows, onTableReady, overrideGridReady]
    );

    useEffect(() => {
        if (pageSizeProp) {
            setPageSize(pageSizeProp);
            setStartPageSize(pageSizeProp);
        } else {
            setPageSize(DEFAULT_PAGE_SIZE);
            setStartPageSize(DEFAULT_PAGE_SIZE);
        }
    }, [pageSizeProp]);

    useEffect(() => {
        if (pagingProp) {
            setPaging(pagingProp);
        } else {
            setPaging(false);
        }
    }, [pagingProp]);

    useEffect(() => {
        if (selectionProp) {
            setSelection(selectionProp);
        } else {
            setSelection(false);
        }
    }, [selectionProp]);

    useEffect(() => {
        if (gridApi) {
            gridApi.paginationSetPageSize(pageSize);
        }
    }, [gridApi, pageSize]);

    const onPageSizeChanged = (e) => {
        let pageSizeTemp = e.target.value;
        setPageSize(pageSizeTemp);
    };

    useEffect(() => {
        setOnSelectionChanged(selectionChanged);
    }, [setOnSelectionChanged, selectionChanged]);

    useEffect(() => {
        setOnRowValueChanged(rowValueChanged);
    }, [data, setOnRowValueChanged, rowValueChanged]);

    useEffect(() => {
        setPinnedBottomRowData(hoistPinnedBottomRowData);
    }, [hoistPinnedBottomRowData]);

    useEffect(() => {
        if ((rowIndex === 0 || rowIndex) && colKey) {
            gridApi.setFocusedCell(rowIndex, 'edit');
            gridApi.startEditingCell({ rowIndex, colKey });
        }
    }, [gridApi, rowIndex, colKey]);

    if (data === null || data === undefined) {
        throw new Error("Don't pass null or undefined!!!");
    }

    useEffect(() => {
        if (gridApi) {
            gridApi.setRowData(data);
        }
    }, [gridApi, data, data.length]);

    useEffect(() => {
        if (gridApi && addedNewData) {
            gridApi.setRowData(data);
        }
    }, [gridApi, data, addedNewData]);

    const handleCellEditingStopped = useCallback(
        (event) => {
            if (typeof cellEditingStopped === 'function') {
                const { data } = event;
                cellEditingStopped(data);
            }
        },
        [cellEditingStopped]
    );

    const table = useMemo(
        () => (
            <TableFactory
                rowData={data}
                columns={columns}
                edit={true}
                selection={selection}
                paging={paging}
                pageSize={pageSize}
                gridReady={onGridReady}
                resizeGrid={resizeGrid}
                selectionChanged={onSelectionChanged}
                cellEditingStopped={handleCellEditingStopped}
                rowValueChanged={onRowValueChanged}
                pinnedBottomRowData={pinnedBottomRowData}
                sortable={sortable}
                filter={filter}
                editable={editable}
                tableClassName={tableClassName}
            >
                {paging === true && (
                    <PageSizeSelector
                        onPageSizeChanged={onPageSizeChanged}
                        pageSize={pageSize}
                        startPageSize={startPageSize}
                    />
                )}
            </TableFactory>
        ),
        [
            data,
            columns,
            selection,
            paging,
            pageSize,
            startPageSize,
            sortable,
            filter,
            editable,
            tableClassName,
            onGridReady,
            resizeGrid,
            onSelectionChanged,
            onRowValueChanged,
            pinnedBottomRowData,
            handleCellEditingStopped,
        ]
    );

    return <div className="Table_wrap">{table}</div>;
};

export default EditingTable;
