import React, { Component } from 'react';

import { history } from '../../store/store';
import AuthApi from '../../api/auth';
import AuthService from '../../auth/auth.service';
import UserButton from '../../components/buttons/UserButton';
import UserMessage from '../../components/messages/UserMessage';

class ResetRequestForm extends Component {
    constructor(props, context) {
        super(props, context);

        let initialEmail = AuthService.getEmail() || '';

        this.state = {
            email: initialEmail,
            errorMessage: null,
        };

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onChange(evt) {
        let changes = {};
        changes[evt.target.name] = evt.target.value;
        this.setState(changes);
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.setState({ errorMessage: null, isbusy: true }, () => {
            AuthApi.reset(this.state.email)
                .then(() => {
                    // set email so that we can read it on the
                    // confirmation page
                    AuthService.setEmail(this.state.email);
                    // clear errors and busy status
                    this.setState({ errorMessage: null, isbusy: false });
                    // go to /account/confirm page
                    history.push('/account/confirm');
                })
                .catch((errorMessage) => {
                    this.setState({
                        errorMessage,
                        isbusy: false,
                    });
                });
        });
    }

    render() {
        return (
            <form onSubmit={this.onFormSubmit} className="AccountPage__form">
                <div className="Form__iconfield">
                    <label htmlFor="email">
                        <i className="fa fa-envelope" />
                    </label>
                    <input
                        type="email"
                        name="email"
                        placeholder="you@example.com"
                        autoCapitalize="off"
                        autoCorrect="off"
                        value={this.state.email}
                        onChange={this.onChange}
                    />
                </div>

                <div className="Form__buttons--Reversed-clean">
                    <UserButton
                        type="submit"
                        defaultText="Request reset"
                        busyText="Requesting reset"
                        isbusy={this.state.isbusy}
                    />
                </div>

                <div className="Form__footer">
                    <UserMessage error={this.state.errorMessage} />
                </div>
            </form>
        );
    }
}

export default ResetRequestForm;
