import React, { useCallback, useEffect, useState } from 'react';
import SectionHeading from '../components/heading/SectionHeading';
import AgreementInvoiceForm from './agreementInvoices/AgreementInvoiceForm';
import AgreementInvoiceTable from './agreementInvoices/AgreementInvoiceTable';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import AgreementInvoicesApi from '../api/agreementInvoices';
import ProjectsApi from '../api/projects';
import errorToast from '../components/messages/ErrorMessage';
import useFunctionAsState from '../hooks/useFunctionAsState';
import AgreementInvoiceMessageModal from './agreementInvoices/AgreementInvoiceMessageModal';

const AgreementInvoicesPage = (props) => {
    const [invoices, setInvoices] = useState([]);
    const [refreshTable, setRefreshTable] = useState(false);
    const [projectID, setProjectID] = useState(null);
    const [projectStatus, setProjectStatus] = useState('');
    const [message, setMessage] = useState('');
    const [exportTableAsCsv, setExportTableAsCsv] = useFunctionAsState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const close = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        setRefreshTable(true);
    }, []);

    useEffect(() => {
        const {
            match: {
                params: { id },
            },
        } = props;
        setProjectID(id);
        setRefreshTable(true);
    }, [props, props.match, props.match.params]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await AgreementInvoicesApi.fetchInvoicesByProjectID(projectID);
                const { data: project } = await ProjectsApi.fetchProject(projectID);
                setInvoices(data);
                setProjectStatus(project.status);
            } catch (error) {
                errorToast(error);
            }
        };
        if (refreshTable === true) {
            fetchData();
            setRefreshTable(false);
        }
    }, [refreshTable, projectID]);

    const hoistPageEvent = (event) => {
        if (event === 'submit') {
            setRefreshTable(true);
        }
    };

    const hoistPaidEvent = (event) => {
        if (event === 'Paid') {
            setModalOpen(true);
            setMessage('You are not allowed to edit a Paid Agreement Invoice');
        }
    };

    const hoistInvoiceMessage = useCallback((status, name) => {
        if (status === 'inactive') {
            setModalOpen(true);
            setMessage(
                `Agreements associated with ${name} are closed. You can not add an invoice on a closed Agreement`
            );
        }
        if (status === 'projectNTE') {
            setModalOpen(true);
            setMessage(
                `This Project associated with ${name} agreement does not have a defined project NTE. Enter a project NTE in ${name} Agreement`
            );
        }
    }, []);

    const onTableReady = ({ exportDataAsCsv }) => {
        if (exportDataAsCsv && typeof exportDataAsCsv === 'function') {
            setExportTableAsCsv(exportDataAsCsv);
        }
    };

    return (
        <>
            <AgreementInvoiceForm hoistPageEvent={hoistPageEvent} hoistInvoiceMessage={hoistInvoiceMessage} />
            <SectionHeading>Invoice Status</SectionHeading>
            <div className="Table-styling">
                <AgreementInvoiceTable
                    invoices={invoices}
                    projectStatus={projectStatus}
                    onTableReady={onTableReady}
                    hoistPageEvent={hoistPageEvent}
                    hoistPaidEvent={hoistPaidEvent}
                />
            </div>
            <div className="Form__buttons Floating-button">
                <ExportCSVButton fileName="agreement-invoices" exportFunction={exportTableAsCsv} />
            </div>
            <AgreementInvoiceMessageModal closeModal={close} openModal={modalOpen} message={message} />
        </>
    );
};

export default AgreementInvoicesPage;
