import React from 'react';
import Logo from '../images/Logo.png';

const ReportHeader = ({ title, children }) => (
    <div className="Header">
        <img src={Logo} alt="WBI Logo" />
        <h2 className="report-name">{title}</h2>
        {children}
    </div>
);
export default ReportHeader;
