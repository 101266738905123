import React, { Component } from 'react';
import { Formik, Form } from 'formik';

import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';

import OfficeSymbolApi from '../api/officeSymbols';

import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';
import errorToast from '../components/messages/ErrorMessage';

class OfficeSymbolPage extends Component {
    constructor(props) {
        super(props);

        this.onRowValueChanged = this.onRowValueChanged.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.removeSymbol = this.removeSymbol.bind(this);

        this.state = {
            officeSymbols: [],
            row: null,
            rowIndex: null,
            colKey: null,
            forceSetData: false,
            columns: [
                {
                    headerName: 'Office Symbol',
                    field: 'name',
                    editable: true,
                    sort: 'desc',
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.removeSymbol,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: officeSymbols } = await OfficeSymbolApi.getOfficeSymbols();
            this.setState({
                officeSymbols: officeSymbols.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                }),
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRowValueChanged(event) {
        let officeSymbolToUpdate = event.node.data;
        if (officeSymbolToUpdate) {
            OfficeSymbolApi.updateOfficeSymbol(officeSymbolToUpdate.id, {
                name: officeSymbolToUpdate.name,
            })
                .then(() => {
                    //
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'name',
        });
    }

    handleSubmit = (values, actions) => {
        if (values) {
            OfficeSymbolApi.createOfficeSymbols({
                name: values.name,
            })
                .then((response) => {
                    let newSymbols = this.state.officeSymbols;
                    newSymbols.push(response.data);
                    actions.setSubmitting(false);
                    actions.resetForm();
                    this.setState(
                        {
                            officeSymbols: newSymbols.sort((a, b) => {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (a.name > b.name) {
                                    return 1;
                                }
                                return 0;
                            }),
                            forceSetData: true,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    removeSymbol(event) {
        let officeSymbolToBeRemoved = event.node.data;
        if (officeSymbolToBeRemoved) {
            OfficeSymbolApi.removeOfficeSymbols(officeSymbolToBeRemoved.id)
                .then((response) => {
                    if (response.status === 200) {
                        let officeSymbols = this.state.officeSymbols;
                        let nOS = officeSymbols.filter((item) => officeSymbolToBeRemoved.id !== item.id);
                        this.setState(
                            {
                                officeSymbols: nOS,
                                forceSetData: true,
                            },
                            function () {
                                this.setState({ forceSetData: false });
                            }
                        );
                    }
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        return (
            <div>
                <PageHeader title="Office Symbols" />
                <AdminSubNav />
                <div>
                    <div className="Single-layout Companion-form">
                        <Formik
                            initialValues={{
                                name: '',
                            }}
                            validationSchema={yup.object().shape({
                                name: yup.string().required(),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <LabeledField
                                        label="Office Symbol*"
                                        name="name"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <div className="Form__buttons--Reversed Buttons_format">
                                        <button type="submit">Add Office Symbol</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="Table-styling">
                        <EditingTable
                            columns={this.state.columns}
                            data={this.state.officeSymbols}
                            rowIndex={this.state.rowIndex}
                            colKey={this.state.colKey}
                            rowValueChanged={this.onRowValueChanged}
                            sizeToFit={true}
                            pagingProp={true}
                            addedNewData={this.state.forceSetData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default OfficeSymbolPage;
