import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';

import ApproversApi from '../api/approvers';
import UsersApi from '../api/users';

import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import { confirm } from '../components/modals/ConfirmModal';
import yup from '../yup';
import errorToast from '../components/messages/ErrorMessage';

class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            approvers: [],
            nonapprovers: [],
            errorMessage: null,
            incomingNewData: false,
            columns: [
                {
                    headerName: 'Approvers',
                    field: 'name',
                    type: 'name',
                    editable: false,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    maxWidth: 50,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: approvers } = await ApproversApi.getApprovers();
            const { data: nonapprovers } = await ApproversApi.getNonapprovers();

            this.setState({
                approvers,
                nonapprovers,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    handleSubmit(values) {
        if (values.approver) {
            UsersApi.updateUser(values.approver, {
                approver: true,
            })
                .then((response) => {
                    let userToUpdate = response.data;
                    let newApprovers = this.state.approvers;

                    let newNonapprovers = this.state.nonapprovers.filter(
                        (approver, _) => userToUpdate.name !== approver.name
                    );

                    newApprovers.push(userToUpdate);

                    this.setState(
                        {
                            approvers: newApprovers,
                            nonapprovers: newNonapprovers,
                            incomingNewData: true,
                        },
                        function () {
                            this.setState({ incomingNewData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    onRemoveSelected = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to remove approver?',
        });

        let userToUpdate = event.node.data;

        if (userToUpdate && confirmed) {
            UsersApi.updateUser(userToUpdate.id, {
                approver: false,
            })
                .then(() => {
                    let newApprovers = this.state.approvers.filter((item, i) => userToUpdate.id !== item.id);
                    let newNonapprovers = this.state.nonapprovers;
                    newNonapprovers.push(userToUpdate);
                    this.setState({
                        approvers: newApprovers,
                        nonapprovers: newNonapprovers,
                    });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    render() {
        let options = [];

        let i;
        if (this.state.nonapprovers) {
            for (i = 0; i < this.state.nonapprovers.length; i++) {
                options.push({
                    value: this.state.nonapprovers[i].id,
                    label: this.state.nonapprovers[i].name,
                });
            }

            options.sort((a, b) => {
                const aLabel = a.label.toUpperCase();
                const bLable = b.label.toUpperCase();
                if (aLabel > bLable) {
                    return 1;
                }
                return -1;
            });
        }

        return (
            <div>
                <PageHeader title="Approvers" />
                <AdminSubNav />
                <div className="Single-layout Companion-form">
                    <Formik
                        initialValues={{ approver: '' }}
                        validationSchema={yup.object().shape({
                            approver: yup.string().required(),
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <LabeledField
                                    label="Approver*"
                                    name="approver"
                                    component="select"
                                    options={options}
                                    errors={errors}
                                    touched={touched}
                                />
                                <div className="Form__buttons--Reversed Buttons_format">
                                    <button type="submit">Add Approver</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="Table-styling">
                    <EditingTable
                        columns={this.state.columns}
                        data={this.state.approvers}
                        sizeToFit={true}
                        addedNewData={this.state.incomingNewData}
                    />
                </div>
            </div>
        );
    }
}

export default AdminPage;
