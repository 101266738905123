// React
import React, { Component } from 'react';

// Libraries
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

// Component
import FinanceSubNav from './FinanceSubNav';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';
import { confirm } from '../components/modals/ConfirmModal';
import PageHeader from '../components/heading/PageHeader';
import Table from '../components/tables/Table_old';
import errorToast from '../components/messages/ErrorMessage';
import ExportCSVButton from '../components/buttons/ExportCSVButton';

// Api
import GrantsApi from '../api/grants';
import ProjectsApi from '../api/projects';

class GrantPage extends Component {
    constructor(props) {
        super(props);

        this.onRowValueChanged = this.onRowValueChanged.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.populateProjects = this.populateProjects.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.onTableReady = this.onTableReady.bind(this);

        this.state = {
            grants: null,
            grantProjects: [],
            projects: null,
            columns: [
                {
                    headerName: 'Company',
                    field: 'company_name',
                    editable: true,
                    type: 'name',
                },
                {
                    headerName: 'Contract No.',
                    field: 'contract_number',
                    editable: true,
                    type: 'idNum',
                },
                {
                    headerName: 'Commercial Received Day',
                    field: 'received_date',
                    type: 'date',
                    sort: 'desc',
                },
                {
                    headerName: 'Contract End Date',
                    field: 'end_date',
                    type: 'date',
                },
                {
                    headerName: 'Funds Received Date',
                    field: 'funds_received',
                    type: 'date',
                },
                {
                    headerName: 'Amount',
                    field: 'amount',
                    type: 'money',
                },
                {
                    headerName: 'Purpose',
                    field: 'purpose',
                    type: 'longtext',
                    editable: true,
                },
                {
                    headerName: 'WBI Project No.',
                    field: 'project_name',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'project_id',
                    },
                    type: 'idNum',
                },
                {
                    headerName: 'Link to DB',
                    field: 'link_to_db',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator',
                    type: 'name',
                    editable: false,
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                    editable: false,
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_update_by',
                    type: 'name',
                    editable: false,
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                    editable: false,
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
        };
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    componentDidMount = async () => {
        try {
            const { data: grants } = await GrantsApi.getGrants();
            const { data: projects } = await ProjectsApi.fetchProjects();

            let grantProjects = [];

            grantProjects = this.populateProjects(grants, projects, grantProjects);

            this.setState({
                grants,
                grantProjects,
                projects,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRowValueChanged(event) {
        let grantProjectToUpdate = event.node.data;

        if (grantProjectToUpdate) {
            GrantsApi.updateGrantProject(grantProjectToUpdate.grant_id, grantProjectToUpdate.id, {
                amount: grantProjectToUpdate.amount,
                purpose: grantProjectToUpdate.purpose,
            })
                .then(() => {
                    //
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });

            GrantsApi.updateGrant(grantProjectToUpdate.grant_id, {
                company_name: grantProjectToUpdate.company_name,
                contract_number: grantProjectToUpdate.contract_number,
                received_date: grantProjectToUpdate.received_date,
                end_date: grantProjectToUpdate.end_date,
                funds_received: grantProjectToUpdate.funds_received,
                link_to_db: grantProjectToUpdate.link_to_db,
            })
                .then((response) => {
                    let updatedGrant = response.data;
                    let newGrants;
                    let newGrantProjects = [];

                    let grantIndex = _findIndex(this.state.grants, {
                        id: grantProjectToUpdate.grant_id,
                    });
                    newGrants = this.state.grants.filter((_, j) => grantIndex !== j);
                    newGrants.push(updatedGrant);

                    newGrantProjects = this.populateProjects(newGrants, this.state.projects, newGrantProjects);

                    this.setState({
                        grantProjects: newGrantProjects,
                        grants: newGrants,
                        row: null,
                        rowIndex: null,
                        colKey: '',
                    });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'company_name',
        });
    }

    onRemoveSelected = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to remove a item?',
        });

        let grantProjectToRemove = event.node.data;

        if (grantProjectToRemove && confirmed) {
            let grantToRemove = _find(this.state.grants, {
                id: grantProjectToRemove.grant_id,
            });

            GrantsApi.removeGrantProject(grantProjectToRemove.grant_id, grantProjectToRemove.id)
                .then(() => {
                    if (grantToRemove.projects.length <= 1) {
                        GrantsApi.removeGrant(grantToRemove.id)
                            .then(() => {
                                //
                            })
                            .catch((errorMessage) => {
                                this.setState({ errorMessage });
                            });
                    }
                    let newGrantProjects = this.state.grantProjects.filter((item) => event.node.data.id !== item.id);
                    this.setState({ grantProjects: newGrantProjects });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    populateProjects(grants, projects, grantProjects) {
        grants.forEach((grant) => {
            if (grant.projects.length > 0) {
                grant.projects.forEach((project) => {
                    let grantProject = _find(projects, {
                        id: project.project_id,
                    });

                    project.project_name = grantProject.name;
                    project.company_name = grant.company_name;
                    project.contract_number = grant.contract_number;
                    project.received_date = grant.received_date;
                    project.end_date = grant.end_date;
                    project.funds_received = grant.funds_received;
                    project.link_to_db = grant.link_to_db;

                    grantProjects.push(project);
                });
            }
        });

        return grantProjects;
    }

    render() {
        const { match } = this.props;
        return (
            <div>
                <PageHeader title="Grant" />
                <FinanceSubNav />
                <SubSubNav>
                    <SubSubNavLink to={match.url} exact>
                        Grant Funding
                    </SubSubNavLink>
                    <SubSubNavLink to={match.url + '/newGrant'}>Create New Grant Funding</SubSubNavLink>
                </SubSubNav>
                <div className="Table-styling">
                    <Table
                        columns={this.state.columns}
                        data={this.state.grantProjects}
                        rowIndex={this.state.rowIndex}
                        colKey={this.state.colKey}
                        onRowValueChanged={this.onRowValueChanged}
                        onTableReady={this.onTableReady}
                    />
                </div>
                <div className="Form__buttons Floating-button--Multiple-buttons Align-right">
                    <ExportCSVButton fileName="Grant-Funding" exportFunction={this.exportDataAsCsv} />
                </div>
            </div>
        );
    }
}

export default GrantPage;
