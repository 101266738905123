import React from 'react';
import Nav from './header';
import ConfirmModal from './components/modals/ConfirmModal';
import AddNoteModal from './components/modals/AddNoteModal';
import Routes from './routes/routes';
import './App.scss';

export default () => (
    <div className="Layout">
        <Nav />
        <Routes />
        <ConfirmModal />
        <AddNoteModal />
    </div>
);
