import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AuthService from '../auth/auth.service';

const AdminRoute = ({ component: Component, ...attrs }) => (
    <Route
        {...attrs}
        render={(props) =>
            AuthService.isAuthenticated() === true &&
            AuthService.getUser() !== null &&
            (AuthService.getUser().site_role === 'Admin' ||
                AuthService.getUser().site_role === 'Executive Director' ||
                AuthService.getUser().site_role === 'Financial') ? (
                <Component {...props} />
            ) : (
                <Redirect to="/" />
            )
        }
    />
);

export default AdminRoute;
