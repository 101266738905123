import React from 'react';
import ProjectsApi from '../../../api/projects';
import GrantsApi from '../../../api/grants';
import ModsApi from '../../../api/mods';
import CommercialApi from '../../../api/commercials';
import AgreementInvoicesApi from '../../../api/agreementInvoices';
import errorToast from '../../../components/messages/ErrorMessage';
import Table from '../../../components/tables/Table';
import ProjectFinancialInfo from '../../../projects/ProjectFinancialInfo';
import ErgoMoney from '../../../utils/ErgoMoney';

let printBtn = (
    <button
        type="submit"
        className="Print-button"
        onClick={() => {
            window.print();
            return false;
        }}
    >
        Print
    </button>
);

class ProjectFundsStatusReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.createInitialState();
    }

    async componentDidMount() {
        try {
            const {
                props: {
                    data: { id: projectId },
                },
            } = this;
            await this.updateData(projectId);
        } catch (error) {
            errorToast(error);
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        try {
            const {
                props: {
                    data: { id: currentProjectId },
                },
            } = this;

            const {
                data: { id: nextProjectId },
            } = nextProps;

            if (nextProjectId !== currentProjectId) {
                const initialState = this.createInitialState();
                this.setState(initialState);
                this.updateData(nextProjectId);
            }
        } catch (error) {
            errorToast(error);
        }
    }

    createInitialState() {
        return {
            projectId: null,
            projectBudgetItems: [],
            expenditures: [],
            projectFinanceInfo: null,
            modsForProject: [],
            grantsForProject: [],
            commercialsForProject: [],
        };
    }

    async updateData(projectId) {
        try {
            const { data: project } = await ProjectsApi.fetchProject(projectId);
            const { data: mods } = await ModsApi.getMods();
            const { data: grants } = await GrantsApi.getGrants();
            const { data: commercial } = await CommercialApi.getCommercialContracts();
            const { data: invoices } = await AgreementInvoicesApi.fetchInvoicesByProjectID(projectId);
            const { data: expenditures } = await ProjectsApi.fetchExpendituresInProject(projectId);
            const { data: projectFinanceInfo } = await ProjectsApi.fetchProjectFinancialInformation(projectId);
            const { budget_items: projectBudgetItems } = project;
            const budgetNteAmounts = projectBudgetItems.map((budgetItem) => budgetItem.nte);
            const budgetExpenseAmounts = projectBudgetItems.map((budgetItem) => budgetItem.expense);
            const budgetTotal = new ErgoMoney().sum(budgetNteAmounts).getNumberAmount();
            const expenseTotal = new ErgoMoney().sum(budgetExpenseAmounts).getNumberAmount();
            const totalRow = {
                source: ' ',
                item: 'Total Project Budget/Commitments:',
                contract: ' ',
                nte: budgetTotal,
                expense: expenseTotal,
                remaining: '',
            };
            projectBudgetItems.push(totalRow);

            const modsForProject = mods.filter((mod) => {
                if (mod.acrns.length > 0) {
                    const { acrns } = mod;
                    for (const acrn of acrns) {
                        if (acrn.project_id === projectId) {
                            return mod;
                        }
                    }
                }
                return null;
            });

            const grantsForProject = grants.filter((grant) => {
                if (grant.projects.length) {
                    const { projects } = grant;
                    for (const project of projects) {
                        if (project.project_id === projectId) {
                            return grant;
                        }
                    }
                }
                return null;
            });

            const commercialsForProject = commercial.filter((contract) => {
                if (contract.acrns.length) {
                    const { projects } = contract;
                    for (const contract of projects) {
                        if (contract.project_id === projectId) {
                            return contract;
                        }
                    }
                }
                return null;
            });

            const projectExpenditures = expenditures.filter((expenditure) => expenditure.account_type === 'Project');
            const expenditureAmounts = projectExpenditures.map((expenditure) => expenditure.cost);
            const expendituresSum = new ErgoMoney().sum(expenditureAmounts).getNumberAmount();

            const appliedInvoices = invoices.filter((invoice) => {
                const {
                    transaction: { status },
                } = invoice;
                if (status === 'Paid' || status === 'Applied') {
                    return invoice;
                }
                return null;
            });
            const appliedInvoicesAmounts = appliedInvoices.map((invoice) => invoice.transaction.amount);
            const appliedInvoicesSum = new ErgoMoney().sum(appliedInvoicesAmounts).getNumberAmount();

            const expendituresAndInvoicesSum = new ErgoMoney()
                .add(expendituresSum)
                .add(appliedInvoicesSum)
                .getNumberAmount();

            const createInvoiceForRowData = (invoice) => ({
                type: 'AgreementInvoice',
                purchase_date: invoice.received_date,
                account: {
                    vendor_name: invoice.agreement.vendor_name,
                    number: invoice.project.number,
                },
                item_description: invoice.notes,
                cost: invoice.transaction.amount,
                store: invoice.agreement.number,
                status: invoice.transaction.status,
            });

            const invoicesForRowData = appliedInvoices.map((invoice) => {
                let result = null;
                result = createInvoiceForRowData(invoice);
                return result;
            });

            const expendituresRowData = [...projectExpenditures, ...invoicesForRowData];

            const costTotalRow = {
                type: '',
                cc_type: '',
                purchase_date: '',
                account: {
                    vendor_name: '',
                    number: '',
                },
                item_description: 'Total Expenses',
                cost: expendituresAndInvoicesSum,
                store: '',
            };
            expendituresRowData.push(costTotalRow);

            this.setState({
                projectId,
                projectBudgetItems,
                expenditures: expendituresRowData,
                modsForProject,
                grantsForProject,
                commercialsForProject,
                projectFinanceInfo,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        const {
            projectBudgetItems,
            expenditures,
            modsForProject: mods,
            projectId,
            grantsForProject: grants,
            commercialsForProject: commercial,
            projectFinanceInfo,
        } = this.state;

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">{printBtn}</div>
                <div className="Report-top-row">
                    <div className="Report-top-row__overview">
                        <ProjectFinancialInfo projectId={projectId} />
                    </div>
                    <div className="Report-top-row__IncrementMods">
                        <h5 className="Report-section-title">Incremental Funding Actions and Amounts</h5>
                        <table className="data">
                            {mods.length ? (
                                <tbody>
                                    {mods.map((mod) => (
                                        <tr key={mod.id}>
                                            <td>
                                                <strong>MOD {mod.number}</strong>
                                            </td>
                                            <td>{`${new ErgoMoney(mod.total_amount)}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td>There are currently no mods for this project.</td>
                                    </tr>
                                </tbody>
                            )}
                            {grants.length ? (
                                <tbody>
                                    {grants.map((grant) => (
                                        <tr key={grant.id}>
                                            <td>
                                                <strong>GRANT {grant.contract_number}</strong>
                                            </td>
                                            <td>{`${new ErgoMoney(grant.total_amount)}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td>There are currently no grants for this project.</td>
                                    </tr>
                                </tbody>
                            )}
                            {commercial.length ? (
                                <tbody>
                                    {commercial.map((comm) => (
                                        <tr key={comm.id}>
                                            <td>
                                                <strong>COMMERCIAL {comm.contract_number}</strong>
                                            </td>
                                            <td>{`${new ErgoMoney(comm.total_amount)}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td>There is currently no commercial funding for this project.</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
                <div className="Report-second-row">
                    <h5 className="Report-section-title">Project Budget/Commitments</h5>
                    <Table
                        data={projectBudgetItems}
                        columns={[
                            { headerName: 'Category', field: 'category', editable: true, filter: true },
                            {
                                headerName: 'Item',
                                field: 'item',
                                cellStyle: ({ node: { lastChild } }) =>
                                    lastChild ? { fontWeight: 'bold', textAlign: 'right' } : null,
                                editable: false,
                                type: 'longtext',
                                filter: true,
                            },
                            {
                                headerName: 'Contract No.',
                                field: 'contract',
                                editable: true,
                                type: 'idNum',
                                filter: true,
                            },
                            {
                                headerName: 'Expense (NTE)',
                                field: 'nte',
                                type: 'money',
                                cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                                editable: false,
                                filter: true,
                            },
                            {
                                headerName: 'Actual Expenses',
                                field: 'expense',
                                type: 'money',
                                cellStyle: ({ node: { lastChild } }) => (lastChild ? { fontWeight: 'bold' } : null),
                                editable: false,
                                filter: true,
                            },
                            {
                                headerName: 'Remaining',
                                field: 'remaining',
                                type: 'money',
                                valueGetter: (params) => {
                                    const {
                                        data: { nte, expense },
                                    } = params;
                                    return new ErgoMoney(nte).subtract(expense).getNumberAmount();
                                },
                                filter: true,
                            },
                            {
                                headerName: 'Notes/Assumptions',
                                field: 'notes',
                                editable: false,
                                type: 'mediumtext',
                                filter: true,
                            },
                        ]}
                        sortable={true}
                    />
                    <div className="Report-second-row__sub-row">
                        <div>
                            <h5 className="Report-section-title">Unbudgeted</h5>
                            {projectFinanceInfo && (
                                <p>
                                    {`${new ErgoMoney(projectFinanceInfo.total_available)}`} -{' '}
                                    {`${new ErgoMoney(projectFinanceInfo.project_budget)}`} ={' '}
                                    {`${new ErgoMoney(projectFinanceInfo.unbudgeted)}`}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="Report-third-row">
                    <h5 className="Report-section-title">Project Expenditures</h5>
                    <Table
                        data={expenditures}
                        columns={[
                            {
                                headerName: 'Type',
                                field: 'type',
                                editable: false,
                                filter: true,
                            },
                            {
                                headerName: 'CC Type',
                                field: 'cc_type',
                                editable: false,
                                type: 'type',
                                filter: true,
                            },
                            {
                                headerName: 'Date',
                                field: 'purchase_date',
                                type: 'date',
                                editable: false,
                                filter: true,
                            },
                            {
                                headerName: 'Vendor',
                                field: 'account.vendor_name',
                                editable: false,
                                filter: true,
                                type: 'longtext',
                            },
                            {
                                headerName: 'Contract No.',
                                field: 'account.number',
                                editable: false,
                                filter: true,
                                type: 'idNum',
                            },
                            {
                                headerName: 'Item Description',
                                field: 'item_description',
                                editable: false,
                                filter: true,
                                type: 'longtext',
                            },
                            { headerName: 'Cost', field: 'cost', type: 'money', editable: false, filter: true },
                            { headerName: 'Store', field: 'store', editable: false, filter: true, type: 'name' },
                            { headerName: 'Status', field: 'status', filter: true, type: 'status' },
                        ]}
                        sortable={true}
                    />
                    <div className="Report-third-row__sub-row">
                        <h5 className="Report-section-title">Remaining Commitments</h5>
                        {projectFinanceInfo && (
                            <p>
                                {`${new ErgoMoney(projectFinanceInfo.project_budget)}`} -{' '}
                                {`${new ErgoMoney(projectFinanceInfo.expenditures_with_ga)}`} ={' '}
                                {`${new ErgoMoney(projectFinanceInfo.remaining_commitments)}`}
                            </p>
                        )}
                    </div>
                    <div className="Report-third-row__sub-row">
                        <h5 className="Report-section-title">Remaining Funds</h5>
                        {projectFinanceInfo && (
                            <p>
                                {`${new ErgoMoney(projectFinanceInfo.total_received)}`} -{' '}
                                {`${new ErgoMoney(projectFinanceInfo.expenditures_and_transfered_out)}`} -{' '}
                                {`${new ErgoMoney(projectFinanceInfo.expenditures_with_ga)}`} ={' '}
                                {`${new ErgoMoney(projectFinanceInfo.remaining_funds)}`}
                            </p>
                        )}
                        <em>G&A is not applied to transfers out of a project</em>
                    </div>
                </div>
            </div>
        );
    }
}
export default ProjectFundsStatusReport;
