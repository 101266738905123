// React
import React, { useEffect, useState, useRef } from 'react';

// Libraries
import { Formik, Form } from 'formik';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';

// Components
import HelpModal from '../components/modals/HelpModal';
import { confirm } from '../components/modals/ConfirmModal';
import SectionHeading from '../components/heading/SectionHeading';

const MAX_NON_APPROVED = 5000;

const ExpendituresForm = (props) => {
    const [approvalRequired, setApprovalRequired] = useState(false);

    const { initialValues } = props;
    const buttonRef = useRef();

    const pendingApproval = (value) => {
        buttonRef.current.disabled = value;
    };

    useEffect(() => {
        if (initialValues) {
            if (initialValues.status !== 'Pending') {
                pendingApproval(false);
            } else {
                pendingApproval(true);
            }
        }
    }, [initialValues]);

    const handleChanges = (values) => {
        let { cost, greatest_unit_cost = MAX_NON_APPROVED } = values;
        if (greatest_unit_cost === '') {
            greatest_unit_cost = MAX_NON_APPROVED;
        }
        if (cost >= MAX_NON_APPROVED && greatest_unit_cost >= MAX_NON_APPROVED && initialValues.status !== 'Approved') {
            setApprovalRequired(true);
        } else {
            setApprovalRequired(false);
        }
    };

    let helpModal = (
        <HelpModal style={{ maxWidth: '66rem' }}>
            <p>Unit Cost of Item - All purchases require proof of purchase (i.e., receipt)</p>
            <p>Unit cost under $2,500 - Only the proof of purchase (receipt) is required.</p>
            <p>
                Unit cost between $2,500 - $4,999 - Requires three price quotes of identical or comparable items. A
                written justification must be submitted if vendor selected is not the lowest cost or if no competitive
                bids can be procured because of sole source situation.
            </p>
            <p>
                Unit cost greater than $5,000 - Requires at least three price quotes of identical or comparable items. A
                written justification must be submitted if vendor selected is no the lowest cost or if no competitive
                bids can be procured because of sole source situation. In addition, the purchase requires Government
                Agreements Officer approval.
            </p>
        </HelpModal>
    );

    return (
        <>
            <SectionHeading>Credit Card</SectionHeading>
            <div className="Single-layout Companion-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        type: props.initialValues.type,
                        cc_type: props.initialValues.cc_type,
                        ccHolder_id: props.initialValues.ccHolder_id,
                        purchase_date: props.initialValues.purchase_date || null,
                        item_description: props.initialValues.item_description,
                        cost: props.initialValues.cost,
                        greatest_unit_cost: props.initialValues.greatest_unit_cost,
                        store: props.initialValues.store,
                        link_to_db: props.initialValues.link_to_db,
                        approver_id: props.initialValues.approver_id,
                        account_type: props.initialValues.account_type,
                    }}
                    validationSchema={yup.object().shape({
                        type: yup.string().required(),
                        cc_type: yup.string().required(),
                        ccHolder_id: yup.string().required(),
                        purchase_date: yup.date().required(),
                        item_description: yup.string().required(),
                        cost: yup.number().required(),
                        store: yup.string().required(),
                        approver_id: yup.string(),
                        link_to_db: yup.string(),
                    })}
                    onSubmit={props.handleSubmit}
                >
                    {({ values, errors, touched, isSubmitting, resetForm }) => (
                        <Form onChange={handleChanges(values)}>
                            <LabeledField
                                label="Type*"
                                name="type"
                                component="select"
                                options={props.expendituresTypeOptions}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Card Holder*"
                                name="ccHolder_id"
                                component="select"
                                options={props.ccHolderOptions}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Credit Card Type*"
                                name="cc_type"
                                component="Radio"
                                currentValue={values.cc_type}
                                options={[
                                    { label: 'Business', value: 'business' },
                                    { label: 'Personal', value: 'personal' },
                                ]}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Core Budget Type*"
                                name="account_type"
                                component="select"
                                options={props.cbtOptions}
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Transaction Date*"
                                name="purchase_date"
                                component="Date"
                                errors={errors}
                                touched={touched}
                            />

                            <LabeledField
                                label="Description*"
                                name="item_description"
                                component="textarea"
                                errors={errors}
                                touched={touched}
                            />
                            <LabeledField
                                label="Cost*"
                                name="cost"
                                component="Money"
                                errors={errors}
                                touched={touched}
                                renderSuffix={() => helpModal}
                            />
                            {values.cost >= MAX_NON_APPROVED && (
                                <LabeledField
                                    label="Greatest Unit Cost in Submission"
                                    name="greatest_unit_cost"
                                    component="Money"
                                    errors={errors}
                                    touched={touched}
                                />
                            )}
                            <LabeledField label="Store*" name="store" type="text" errors={errors} touched={touched} />
                            {values.cost >= MAX_NON_APPROVED && (
                                <LabeledField
                                    label="Approver*"
                                    name="approver_id"
                                    component="select"
                                    options={props.approverUserOptions}
                                    errors={errors}
                                    touched={touched}
                                />
                            )}
                            <LabeledField
                                label="Link to DB"
                                name="link_to_db"
                                type="text"
                                disabled={approvalRequired}
                                errors={errors}
                                touched={touched}
                            />
                            <div className="Form__buttons--Reversed Buttons_format">
                                <button
                                    type="button"
                                    onClick={() => {
                                        resetForm();
                                        props.cancelEdit();
                                    }}
                                    disabled={isSubmitting}
                                    style={{ marginRight: 8 }}
                                >
                                    Cancel
                                </button>
                                {values.cost < MAX_NON_APPROVED ? (
                                    <button type="submit" ref={buttonRef} disabled={approvalRequired}>
                                        Submit to Finance
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        onClick={(e) => {
                                            if (!values.approver_id) {
                                                e.preventDefault();
                                                confirm({
                                                    text: 'The Approver Field is required for this action, please select an approver and submit again.',
                                                    acceptText: 'Ok',
                                                    rejectText: null,
                                                });
                                            }
                                        }}
                                    >
                                        Submit for Approval
                                    </button>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default ExpendituresForm;
