import api from './api';

import endpoints from './endpoints';

const expendituresRoute = endpoints.EXPENDITURES;
const expendituresRouteNoApp = endpoints.EXPENDITURES_NO_APP;
const expendituresAllRoute = endpoints.EXPENDITURES_ALL;
const expendituresApproverRoute = endpoints.EXPENDITURES_APPROVER;
const financialReportsRoute = endpoints.FINANCIAL_REPORTS;

export default {
    fetchExpenditures() {
        return api.get(expendituresRoute, 'Error getting expenditures concerning the logged user');
    },

    fetchExpenditure(id) {
        return api.get(`${expendituresRoute}/${id}`, 'Error getting an expenditure');
    },

    fetchAllExpenditures() {
        return api.get(expendituresAllRoute, 'Error getting all expenditures');
    },

    fetchAllExpendituresNoAccounts() {
        return api.get(expendituresAllRoute + '?no_accounts=true', 'Error getting all expenditures with no account');
    },

    fetchProjectExpenditures() {
        return api.get(expendituresAllRoute + '?projects=true', 'Error getting all project expenditures');
    },

    fetchNonProjectExpenditures() {
        return api.get(expendituresAllRoute + '?projects=false', 'Error getting all non project expenditures');
    },

    fetchAllMatchedExpenditures() {
        return api.get(expendituresAllRoute + '?matched=true', 'Error getting all matched expenditures');
    },

    fetchAllMatchableUnmatchedExpenditures() {
        return api.get(expendituresAllRoute + '?matchable=true', 'Error getting all matched expenditures');
    },

    fetchFinancialReportData() {
        return api.get(financialReportsRoute, 'Error getting financial reports');
    },

    fetchExpendituresApprover() {
        return api.get(expendituresApproverRoute, 'Error getting expenditures that need approval by logged user');
    },

    fetchAllPersonalExpenditures() {
        return api.get(expendituresAllRoute + '?cc_type=personal', 'Error getting all personal expenditures');
    },

    createExpenditure(attributes) {
        return api.post(expendituresRoute, attributes, 'Error creating an expenditure');
    },

    createExpenditureNoApprover(attributes) {
        return api.post(expendituresRouteNoApp, attributes, 'Error creating an expenditure with no approvers');
    },

    updateExpenditure(expenditureId, attributes) {
        return api.put(`${expendituresRoute}/${expenditureId}`, attributes, 'Error updating an expenditure');
    },

    fetchExpenditureTypes() {
        return api.get(`${expendituresRoute}/types`, 'Error getting expenditures types');
    },

    applyExpenditures(expenditureIds) {
        return api.patch(`${expendituresRoute}/apply`, expenditureIds, 'Error applying an expenditure receipt');
    },

    removeExpenditures(expenditureId) {
        return api.delete(`${expendituresRoute}/${expenditureId}`, 'Error removing an expenditure');
    },

    returnExpenditure(expenditureId, attribute) {
        return api.put(
            `${expendituresRoute}/${expenditureId}/return`,
            attribute,
            'Error returning an expenditure receipt'
        );
    },

    submitExpenditure(expenditureId, attributes) {
        return api.put(
            `${expendituresRoute}/${expenditureId}/submit`,
            attributes,
            'Error submitting an expenditure receipt'
        );
    },

    updateAndSubmitExpenditure(expenditureId, attributes) {
        return api.put(
            `${expendituresRoute}/${expenditureId}/update_and_submit`,
            attributes,
            'Error updating and submitting an expenditure receipt'
        );
    },

    updateAndSubmitApprovalExpenditure(expenditureId, attributes) {
        return api.put(
            `${expendituresRoute}/${expenditureId}/update_and_submit_approval`,
            attributes,
            'Error updating and submitting an expenditure for approval'
        );
    },

    approveExpenditure(expenditureId) {
        return api.patch(`${expendituresRoute}/${expenditureId}/approve`, 'Error approving an expenditure');
    },

    rejectExpenditure(expenditureId) {
        return api.patch(`${expendituresRoute}/${expenditureId}/reject`, 'Error rejecting an expenditure');
    },

    markExpenditureAsUnmatchable(expenditureId) {
        return api.put(
            `${expendituresRoute}/${expenditureId}/unmatchable`,
            null,
            'Error marking expenditure as unmatchable'
        );
    },

    markExpenditureAsMatchable(expenditureId) {
        return api.put(`${expendituresRoute}/${expenditureId}/matchable`, 'Error marking expenditure as matchable');
    },
};
