import api from './api';

import endpoints from './endpoints';

const serviceRoute = endpoints.SERVICE;

export default {
    getServices() {
        return api.get(serviceRoute, 'Error getting list of services');
    },

    getService(serviceId) {
        return api.get(`${serviceRoute}/${serviceId}`, 'Error getting a specific service by id');
    },

    fetchFinancialInformation(id) {
        return api.get(`${serviceRoute}/${id}/financial_information`, 'Error getting service financial information');
    },

    createService(serviceAttributes) {
        return api.post(serviceRoute, serviceAttributes, 'Error creating service type');
    },

    updateService(serviceId, serviceAttributes) {
        return api.put(`${serviceRoute}/${serviceId}`, serviceAttributes, 'Error updating service type');
    },
};
