import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AuthService from '../auth/auth.service';

// https://tylermcginnis.com/react-router-protected-routes-authentication/
const AuthenticatedRoute = ({ component: Component, ...attrs }) => (
    <Route
        {...attrs}
        render={(props) =>
            AuthService.isAuthenticated() === true ? <Component {...props} /> : <Redirect to="/account/signin" />
        }
    />
);

export default AuthenticatedRoute;
