import React, { useEffect, useState } from 'react';
import LabeledField from '../../components/forms/LabeledField';
import ErgoMoney from '../../utils/ErgoMoney';

export default function NTEChange({ errors, touched, values, oldAgreement }) {
    const [showFields, setShowFields] = useState(false);
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        values.show_nte === 'yes' ? setShowFields(true) : setShowFields(false);
    }, [values.show_nte]);

    useEffect(() => {
        if (showFields === false) {
            // eslint-disable-next-line no-param-reassign
            values.nte_change = 0;
        }
    });

    useEffect(() => {
        if (oldAgreement) {
            // eslint-disable-next-line no-param-reassign
            values.new_nte =
                new ErgoMoney(oldAgreement.nte).getNumberAmount() + new ErgoMoney(values.nte_change).getNumberAmount();
        }
    }, [values.new_nte, values.nte_change, oldAgreement]);

    return (
        <>
            <LabeledField
                label="Did the NTE amount change?"
                name="show_nte"
                component="Radio"
                currentValue={values.show_nte || 'no'}
                options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                ]}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mb0 mt0"
            />
            {showFields && (
                <>
                    <div className="mr2 flex flex-row justify-content-between">
                        <div>Old NTE</div> {oldAgreement.nte && <div>${oldAgreement.nte}</div>}
                    </div>

                    <div className="mr2 flex flex-row justify-content-between">
                        <LabeledField
                            label="Amendment Amount"
                            name="nte_change"
                            component="Money"
                            errors={errors}
                            touched={touched}
                            labelClassName="text-align-left mr2"
                        />
                    </div>
                    <div className="HorizontalLine mb2" />
                    <div className="mr2 flex flex-row justify-content-between">
                        <div>New NTE</div> {values.new_nte && <div>${values.new_nte}</div>}
                    </div>
                </>
            )}
        </>
    );
}
