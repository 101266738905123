import createDropdownOption from './DropdownUtils';

export const siteRoles = {
    ADMIN: 'Admin',
    EXEC: 'Executive Director',
    FINANCIAL: 'Financial',
    PM: 'Project Manager',
    SERVICE_DIRECTOR: 'Service Director',
};

export const getUserRole = () => {
    const user = JSON.parse(localStorage.getItem('WBI.USER'));
    return user ? user.site_role : null;
};

export const prepareUserOptions = (users) => {
    try {
        return users.map((user) => createDropdownOption(user.name, user.id));
    } catch (error) {
        throw new Error('Error preparing user options');
    }
};

export const prepareApproverOptions = (users) => {
    try {
        let currentUser = JSON.parse(localStorage.getItem('WBI.USER'));
        const approvers = users.filter((user) => user.approver && user.name !== currentUser.name);
        return prepareUserOptions(approvers);
    } catch (error) {
        throw new Error('Error preparing approver options');
    }
};

export const prepareCardHolderOptions = (users) => {
    try {
        const cardHolders = users.filter((user) => user.cc_holder);
        return prepareUserOptions(cardHolders);
    } catch (error) {
        throw new Error('Error preparing cardholder options');
    }
};

export const prepareTableCardHolderOptions = (users) => {
    try {
        const cardHolders = users.filter((user) => user.cc_holder);
        return cardHolders.map((user) => user.name);
    } catch (error) {
        throw new Error('Error preparing cardholder options');
    }
};
