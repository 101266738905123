import React from 'react';
import PagingTable from '../../../components/tables/PagingTable';
import errorToast from '../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../components/buttons/ExportCSVButton';

class ActionItemSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: null,
            rowData: null,
            users: null,
            actionItems: null,
            exportDataAsCsv: null,
        };
        this.onTableReady = this.onTableReady.bind(this);
    }

    async componentDidMount() {
        let data = this.props.data;
        let date = this.props.date;

        this.updateData(data, date);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.updateData(this.props.data, this.props.date);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.setState({ exportDataAsCsv: params.exportDataAsCsv });
        }
    }

    async updateData(data) {
        let projects = data;
        let actionItemsData = [];

        try {
            projects.forEach((p) => {
                if (p.open_action_items) {
                    p.open_action_items.forEach((a) => {
                        a.projectNum = p.number;
                        a.projectName = p.name;
                        actionItemsData.push(a);
                    });
                }
            });

            this.setState({
                projects,
                rowData: actionItemsData,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        const { rowData, exportDataAsCsv } = this.state;
        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="action-items-reports" exportFunction={exportDataAsCsv} />
                    </div>
                </div>
                <div className="Report-second-row">
                    <PagingTable
                        columns={[
                            {
                                headerName: 'Project No.',
                                field: 'projectNum',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'project_id',
                                },
                                type: 'idNum',
                                filter: true,
                            },
                            { headerName: 'Project Name', field: 'projectName', type: 'name', filter: true },
                            { headerName: 'Action Item', field: 'description', type: 'longtext', filter: true },
                        ]}
                        data={rowData}
                        onTableReady={this.onTableReady}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}
export default ActionItemSummary;
