// React
import React, { Component } from 'react';

// Components
import { shortDate } from '../../utils/utils';
import ReadOnlyFormModal from '../travelExpense/ReadOnlyFormModal';
import ErgoMoney from '../../utils/ErgoMoney';

class TravelApprovalCards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            modalExpense: null,
        };
    }

    openModal = (item) => {
        this.setState({ open: true, modalExpense: item });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    render() {
        const { open, modalExpense } = this.state;
        return this.props.approvals.map((item, index) => (
            <div key={item} className="Card-styling">
                <ReadOnlyFormModal openModal={open} closeModal={this.closeModal} travelExpense={modalExpense} />
                <h6 className="Table_label">{item.typeOfApproval}</h6>
                <table className="Approval_table" key={index}>
                    {item.typeOfApproval === 'Travel Approval Request' && (
                        <div>
                            <tbody>
                                <tr>
                                    <td className="Table_label">Traveler:</td>
                                    <td>{item.traveler_name}</td>
                                </tr>
                                {item.account_type === 'CoreBudgetType' ? (
                                    <tr>
                                        <td className="Table_label">Type:</td>
                                        <td>{item.account.name}</td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td className="Table_label">Type:</td>
                                        <td>{item.travel_request_type}</td>
                                    </tr>
                                )}
                                {item.itinerary.map((itin) => (
                                    <React.Fragment key={itin}>
                                        <tr>
                                            <td className="Table_label">Location of Travel:</td>
                                            <td>
                                                <h4>{itin.location_of_travel}</h4>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="Table_label">Date of Travel:</td>
                                            <td>{shortDate(itin.date_of_travel)}</td>
                                        </tr>
                                        <tr>
                                            <td className="Table_label">Date of Return:</td>
                                            <td>{shortDate(itin.date_of_return)}</td>
                                        </tr>

                                        <tr>
                                            <td className="Table_label">ZIP Code:</td>
                                            <td>{itin.zip_code}</td>
                                        </tr>
                                        <tr>
                                            <td className="Table_label">Description:</td>
                                            <td>{itin.description}</td>
                                        </tr>
                                        <tr>
                                            <td className="Table_label">Per Diem Rate:</td>
                                            <td>${itin.per_diem_rate}</td>
                                        </tr>
                                        <tr>
                                            <td className="Table_label">Max Lodge Rate:</td>
                                            <td>${itin.max_lodge_rate}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}

                                <tr>
                                    <td className="Table_label">Approver:</td>
                                    <td>{item.approver_name}</td>
                                </tr>
                            </tbody>
                            <div className="Form__buttons--Reversed Card-buttons">
                                <button type="button" onClick={() => this.props.reject(item.id, index)}>
                                    Reject
                                </button>
                                <button type="button" onClick={() => this.props.approve(item.id, index)}>
                                    Approve
                                </button>
                            </div>
                        </div>
                    )}
                    {item.typeOfApproval === 'Travel Expense Approval Request' && (
                        <div>
                            <tbody>
                                <tr>
                                    <td className="Table_label">Account Number:</td>
                                    <td>{item.account_number}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">Traveler:</td>
                                    <td>{item.traveler}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">Initiated By:</td>
                                    <td>{item.initiator}</td>
                                </tr>

                                <tr>
                                    <td className="Table_label">Start Date:</td>
                                    <td>{shortDate(item.itinerary[0].start_date)}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">End Date:</td>
                                    <td>{shortDate(item.itinerary[item.itinerary.length - 1].end_date)}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">Total Amount:</td>
                                    <td>{new ErgoMoney(item.total_amount).getStringAmount()}</td>
                                </tr>
                                {item.itinerary.map((itin) => (
                                    <React.Fragment key={itin}>
                                        <tr>
                                            <td className="Table_label">Description ({itin.city_state}):</td>
                                            <td>{itin.notes}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                                <tr>
                                    <td className="Table_label">View Full Expense:</td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                this.openModal(item);
                                            }}
                                        >
                                            Open
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <div className="Form__buttons--Reversed Card-buttons">
                                <button type="button" onClick={() => this.props.returnExpense(item.id, index)}>
                                    Return
                                </button>
                                <button type="button" onClick={() => this.props.approveExpense(item.id, index)}>
                                    Approve
                                </button>
                            </div>
                        </div>
                    )}
                </table>
            </div>
        ));
    }
}

export default TravelApprovalCards;
