import React from 'react';
import EditingTable from '../components/tables/EditingTable';
import PagingTable from '../components/tables/PagingTable';
import errorToast from '../components/messages/ErrorMessage';
import ErgoMoney from '../utils/ErgoMoney';
import AgreementsApi from '../api/agreements';

const AgreementHistoryTable = ({ data, onTableReady, hoistDeleteEvent, hoistUpdateEvent, mode, locked }) => {
    console.log({ data });
    const columns = [
        { headerName: 'Updated', field: 'updated_on', type: 'date' },
        {
            headerName: 'Agreement No.',
            field: 'number',
            cellRenderer: 'ExternalLinkRenderer',
            cellRendererParams: {
                docLink: 'doc_link',
                locked: 'locked',
                disabled: locked,
            },
            type: 'mediumtext',
        },
        {
            headerName: 'Primary Change',
            field: 'change_type',
            type: 'shorttext',
        },
        {
            headerName: 'Notes',
            field: 'notes',
            type: 'mediumtext',
        },
        {
            headerName: 'Amendment Amount',
            field: 'nte_change',
            type: 'money',
        },
        { headerName: 'NTE', field: 'nte', type: 'money' },
        {
            headerName: 'End Date Change',
            field: 'end_date',
            type: 'date',
        },
    ];

    const handleDeleteEvent = (event) => {
        let amendmentId = event.data.id;
        let agreementId = event.data.agreement_id;
        let deletedNTEChange = event.data.nte_change;
        hoistDeleteEvent(agreementId, amendmentId, deletedNTEChange);
    };

    const changeTypeConvert = (values) => {
        let newChangeType = '';
        switch (values) {
            case 'Added work / Scope change':
                newChangeType = 'added_work';
                break;
            case 'Modified Timelines (including cancellations)':
                newChangeType = 'modified_timelines';
                break;
            case 'Funding Update':
                newChangeType = 'funding_update';
                break;
            case 'Terms & Conditions change':
                newChangeType = 'terms_and_conditions';
                break;
            case 'Agreement Created':
                newChangeType = 'agreement_created';
                break;
            case 'Updated change request':
                newChangeType = 'updated_change_request';
                break;
            default:
                break;
        }
        return newChangeType;
    };

    const hoistUpdatedRowData = async (event) => {
        try {
            const { id, agreement_id, end_date, notes, nte_change, number, change_type } = event.node.data;
            const changeType = changeTypeConvert(change_type);
            const newDocLink = number.link;
            const newNumber = number.name;
            await AgreementsApi.updateAmendmentById(agreement_id, id, {
                change_type: changeType,
                doc_link: newDocLink,
                end_date,
                notes,
                nte_change: new ErgoMoney(nte_change).getNumberAmount(),
                number: newNumber,
            }).then(() => {
                hoistUpdateEvent('nte_change');
            });
        } catch (error) {
            errorToast(error);
        }
    };

    if (mode === 'edit' && !locked) {
        const columns = [
            {
                headerName: 'Updated',
                field: 'updated_on',
                type: 'date',
                editable: false,
            },
            {
                headerName: 'Agreement No.',
                field: 'number',
                cellRenderer: 'ExternalLinkRenderer',
                cellRendererParams: {
                    docLink: 'doc_link',
                },
                cellEditor: 'ExternalLinkEditor',
                cellEditorParams: {
                    docLink: 'doc_link',
                    name: 'Agreement No.',
                    nameLink: 'Dropbox Link',
                },
                type: 'mediumlongtext',
            },
            {
                headerName: 'Primary Change',
                field: 'change_type',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: [
                        'Added work / Scope change',
                        'Agreement Created',
                        'Modified Timelines (including cancellations)',
                        'Funding Update',
                        'Terms & Conditions change',
                        'Updated change request',
                    ],
                },
                cellRenderer: (params) => params.value,
                type: 'mediumtext',
            },
            {
                headerName: 'Notes',
                field: 'notes',
                type: 'mediumtext',
            },
            {
                headerName: 'Amendment Amount',
                field: 'nte_change',
                type: 'money',
            },
            { headerName: 'NTE', field: 'nte', type: 'money', editable: false },
            {
                headerName: 'End Date Change',
                field: 'end_date',
                type: 'date',
            },
            {
                headerName: 'Edit',
                field: 'edit',
                type: 'symbol',
                editable: false,
                cellRenderer: 'EditSymbolRenderer',
                disabled: locked,
            },
            {
                headerName: 'Delete',
                editable: false,
                cellRenderer: 'TrashSymbolRenderer',
                cellRendererParams: {
                    type: 'creationCheck',
                },
                type: 'symbol',
                onCellClicked: handleDeleteEvent,
            },
        ];

        return (
            <EditingTable
                columns={columns}
                data={data}
                pageSizeProp={25}
                sizeToFit={true}
                rowValueChanged={hoistUpdatedRowData}
                onTableReady={onTableReady}
                tableClassName="AgreementHistoryTable"
            />
        );
    }

    return (
        <PagingTable
            columns={columns}
            data={data}
            pageSizeProp={25}
            sizeToFit={true}
            onTableReady={onTableReady}
            tableClassName="AgreementHistoryTable"
        />
    );
};

export default AgreementHistoryTable;
