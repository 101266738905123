const passwordValidate = (password) => {
    let valid = {};
    let upper = '';
    let lower = '';
    let num = '';
    let sym = '';
    upper = password.match(/[A-Z]+/g);
    lower = password.match(/[a-z]+/g);
    num = password.match(/\d/g);
    sym = password.match(/\W/g);

    if (password.length > 7) {
        valid.length = true;
    } else {
        valid.length = false;
    }
    if (upper !== null) {
        valid.uc = true;
    } else {
        valid.uc = false;
    }
    if (lower !== null) {
        valid.lc = true;
    } else {
        valid.lc = false;
    }
    if (num !== null) {
        valid.num = true;
    } else {
        valid.num = false;
    }
    if (sym !== null) {
        valid.sym = true;
    } else {
        valid.sym = false;
    }

    return valid;
};

export { passwordValidate as default };
