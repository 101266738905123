import React from 'react';
import LabeledField from '../../components/forms/LabeledField';

export default function AddAgreementDocs({ values, errors, touched, edit }) {
    return (
        <>
            <LabeledField
                label="DB Link to Agreement"
                name="agreement_link"
                component="input"
                value={values.agreement_link}
                errors={errors}
                touched={touched}
                //readOnly={edit}
                labelClassName="text-align-left mr2"
            />
            <LabeledField
                label="DB Link to Bids Package"
                name="bids_package"
                component="input"
                value={values.bids_package}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mr2 mt2"
            />
            <LabeledField
                label="DB Link to Sole Source Justification"
                name="sole_source"
                component="input"
                value={values.sole_source}
                errors={errors}
                touched={touched}
                labelClassName="text-align-left mr2 mt2"
            />
        </>
    );
}
