import React from 'react';

const ExclamationPointRenderer = (params) => {
    let closeAgreement = false;

    if (params.data.expired_agreements > 0 || params.data.agreements_past_nte > 0) {
        closeAgreement = true;
    }

    if (closeAgreement) {
        return (
            <span>
                {params.value}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="18px" height="18px">
                    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
                </svg>
            </span>
        );
    }
    return <span> {params.value} </span>;
};

export default ExclamationPointRenderer;
