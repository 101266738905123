import React, { Component } from 'react';
import _find from 'lodash/find';
import PagingTable from '../components/tables/PagingTable';
import ProjectsApi from '../api/projects';
import ServicesApi from '../api/services';

class MyProjects extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    headerName: 'Project Number',
                    field: 'number',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'id',
                    },
                    type: 'idNum',
                },
                {
                    headerName: 'Project Name',
                    field: 'name',
                    type: 'mediumtext',
                },
            ],
            myProjects: null,
        };
    }

    componentDidMount = async () => {
        let user = JSON.parse(localStorage.getItem('WBI.USER'));
        let service = null;

        if (user !== null && user.site_role.includes('Service')) {
            const { data: services } = await ServicesApi.getServices();
            if (services) {
                service = _find(services, { director_id: user.id });
            }
        }

        if (service) {
            const { data: myProjects } = await ProjectsApi.fetchProjectsCurrentUser(service.id);
            this.setState({ myProjects });
        } else {
            const { data: myProjects } = await ProjectsApi.fetchProjectsCurrentUser();
            this.setState({ myProjects });
        }
    };

    render() {
        if (!this.state.myProjects) return <div>loading...</div>;
        return (
            <div>
                <PagingTable
                    columns={this.state.columns}
                    pageSizeProp={25}
                    sizeToFit={true}
                    data={this.state.myProjects}
                    filter={true}
                    sortable={true}
                    tableClassName="My-projects-table"
                />
            </div>
        );
    }
}

export default MyProjects;
