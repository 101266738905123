import React, { Component } from 'react';
import { Formik, Form } from 'formik';

import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';

import CoreBudgetTypesApi from '../api/coreBudgetTypes';

import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import { confirm } from '../components/modals/ConfirmModal';
import yup from '../yup';
import errorToast from '../components/messages/ErrorMessage';

class CoreBudgetTypesPage extends Component {
    constructor(props) {
        super(props);
        this.getRowData = this.getRowData.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            coreBudgetTypes: [],
            row: null,
            rowIndex: null,
            colKey: '',
            forceSetData: false,
            columns: [
                {
                    headerName: 'Budget No.',
                    field: 'number',
                    type: 'number',
                    editable: true,
                },
                {
                    headerName: 'Core Budget Type',
                    field: 'name',
                    type: 'longtext',
                    editable: true,
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: coreBudgetTypes } = await CoreBudgetTypesApi.getCoreBudgetTypes();
            this.setState({ coreBudgetTypes });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRowValueChanged(event) {
        let cbtToUpdate = event.node.data;

        if (cbtToUpdate) {
            CoreBudgetTypesApi.updateCoreBudgetType(cbtToUpdate.id, {
                number: cbtToUpdate.number,
                name: cbtToUpdate.name,
            }).catch((errorMessage) => {
                this.setState({ errorMessage });
            });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'number',
        });
    }

    onRemoveSelected = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to remove Core Budget Type?',
        });

        let cbtToUpdate = event.node.data;

        if (cbtToUpdate && confirmed) {
            CoreBudgetTypesApi.removeCoreBudgetType(cbtToUpdate.id)
                .then(() => {
                    let newCoreBudgetTypes = this.state.coreBudgetTypes.filter((item) => cbtToUpdate.id !== item.id);
                    this.setState({ coreBudgetTypes: newCoreBudgetTypes });
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    handleSubmit = (values, actions) => {
        if (values) {
            CoreBudgetTypesApi.createCoreBudgetType({
                number: values.number,
                name: values.name,
            })
                .then((response) => {
                    let newCoreBudgetTypes = this.state.coreBudgetTypes;
                    newCoreBudgetTypes.push(response.data);
                    this.setState(
                        {
                            coreBudgetTypes: newCoreBudgetTypes,
                            forceSetData: true,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
        actions.resetForm();
    };

    render() {
        return (
            <div>
                <PageHeader title="Core Budget Types" />
                <AdminSubNav />
                <div>
                    <div className="Single-layout Companion-form">
                        <Formik
                            initialValues={{
                                number: '',
                                name: '',
                            }}
                            validationSchema={yup.object().shape({
                                number: yup.number().required(),
                                name: yup.string().required(),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <LabeledField
                                        label="Budget No.*"
                                        name="number"
                                        type="number"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Core Budget Type*"
                                        name="name"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />

                                    <div className="Form__buttons--Reversed Buttons_format">
                                        <div />
                                        <button type="submit">Add Core Budget Type</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="Table-styling">
                        <EditingTable
                            columns={this.state.columns}
                            data={this.state.coreBudgetTypes}
                            rowIndex={this.state.rowIndex}
                            colKey={this.state.colKey}
                            sizeToFit={true}
                            pagingProp={true}
                            addedNewData={this.state.forceSetData}
                            rowValueChanged={this.onRowValueChanged}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default CoreBudgetTypesPage;
