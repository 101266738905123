import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AuthService from '../auth/auth.service';
import VendorsApi from '../api/vendors';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import useFunctionAsState from '../hooks/useFunctionAsState';
import SectionHeading from '../components/heading/SectionHeading';
import errorToast from '../components/messages/ErrorMessage';
import Modal from '../components/modals/Modal';
import GridSection from '../components/section/GridSection';
import AgreementsTable from './AgreementsTable';
import AddNewVendorsModal from './vendorForm/AddNewVendorModal';
import AddAgreementModal from './agreementForm/AddAgreementModal';
import ViewPageExternalLink from '../utils/ViewPageExternalLink';
import { phoneFormat } from '../utils/utils';
import DeleteVendorModal from './vendorForm/DeleteVendorModal';

export default function VendorViewPage() {
    const { projectID, vendorID } = useParams();
    const history = useHistory();
    const { pathname } = useLocation();
    const route = pathname.includes('admin') ? 'admin' : 'projects';
    const user = AuthService.getUser();
    const [isExecDir, setIsExecDir] = useState(false);
    const [vendor, setVendor] = useState({});
    const [vendorModalOpen, setVendorModalOpen] = useState(false);
    const [vendorDeleteModalOpen, setDeleteVendorModalOpen] = useState(false);
    const [agreementOpenCheck, setAgreementOpenCheck] = useState(false);
    const [agreementsModalOpen, setAgreementsModalOpen] = useState(false);
    const [exportDataAsCsv, setExportDataAsCsv] = useFunctionAsState(() => {});
    const isNotVerified = vendor.watchlist === true;

    const openEditVendor = () => {
        setVendorModalOpen(true);
    };

    const closeEditVendor = () => {
        setVendorModalOpen(false);
        setVendor({});
    };

    const openAddAgreement = () => {
        setAgreementsModalOpen(true);
    };

    const closeAddAgreement = () => {
        setAgreementsModalOpen(false);
        setVendor({});
    };

    const deleteVendor = () => {
        setDeleteVendorModalOpen(true);
    };

    const closeDeleteVendor = () => {
        setDeleteVendorModalOpen(false);
        history.goBack();
    };

    const hoistTableParams = (params) => {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            setExportDataAsCsv(params.exportDataAsCsv);
        }
    };

    const editVendorNameFileName = (str) => {
        if (str) {
            return str.replaceAll('.', '');
        }
        return str;
    };

    useEffect(() => {
        if (user !== null && user.site_role === 'Executive Director') {
            setIsExecDir(true);
        }
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (route === 'admin') {
                    if (vendorID) {
                        const { data } = await VendorsApi.getVendorById(vendorID);
                        setVendor(data);
                    }
                } else if (route === 'projects') {
                    if (vendorID && projectID) {
                        const { data } = await VendorsApi.getVendorByIdForProject(vendorID, projectID);
                        setVendor(data);
                    }
                }
            } catch (error) {
                errorToast(error);
            }
        };
        if (!vendor.name) {
            fetchData();
        }
    }, [vendor, route, vendorID, projectID]);

    useEffect(() => {
        if (vendor.agreements) {
            let agreements = vendor.agreements;
            agreements.forEach((agreement) => {
                if (agreement.status === 'open') {
                    setAgreementOpenCheck(true);
                }
            });
        }
    }, [vendor]);

    return (
        <Modal isOpen={true} style={{ minWidth: '80vw' }}>
            <div className="ViewVendor">
                <div className="flex justify-content-between">
                    <SectionHeading className="align-left"> {vendor.name} </SectionHeading>
                    <button onClick={() => history.goBack()}>
                        <i className="fa fa-window-close" />
                    </button>
                </div>
                <div className="VendorStatus mb2 flex justify-content-between">
                    <div className="flex">
                        <div className="mr2">
                            {vendor.approved === 'approved' ? (
                                <>
                                    <i className="mr1 fa fa-check-circle-o Status-green " aria-hidden="true" />
                                    <span>Approved</span>
                                </>
                            ) : (
                                <>
                                    <i className="mr1 fa fa-times-circle-o Status-red" aria-hidden="true" />
                                    <span>Not Approved</span>
                                </>
                            )}
                        </div>
                        <div>
                            {vendor.active ? (
                                <>
                                    <i className="fa fa-circle Status-green" aria-hidden="true" /> <span>Active</span>
                                </>
                            ) : (
                                <>
                                    <i className="fa fa-circle-o Status-red" aria-hidden="true" /> <span>Inactive</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="grid">
                            {pathname.includes('admin') && !isExecDir && (
                                <button type="button" className="mt1 TextButton" onClick={openEditVendor}>
                                    <i className="mr1 fa fa-pencil-square-o" aria-hidden="true" />
                                    <span>Edit Vendor</span>
                                </button>
                            )}
                        </div>
                        <div className="grid">
                            {pathname.includes('admin') && !isExecDir && (
                                <button type="button" className="mt1 TextButton" onClick={deleteVendor}>
                                    <i className="mr1 fa fa-trash-o" aria-hidden="true" />
                                    <span>Delete Vendor</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap">
                    <GridSection title="Vendor Information">
                        <div className="mr2 flex flex-column width-30">
                            <h4>Vendor Name</h4>
                            <div className="mb2">{vendor.name}</div>
                            <h4>Address</h4>
                            {vendor.address1 ? (
                                <div>
                                    {vendor.address1} <br />
                                    {vendor.address2} <br />
                                    {vendor.city}, {vendor.state} {vendor.zip}
                                    <br />
                                    {vendor.country}
                                </div>
                            ) : (
                                <div className="EmptyFormState"> No Full Address Entered</div>
                            )}
                        </div>
                        <div className="VerticalLine mr2" />
                        <div className="mr2 flex flex-column width-30">
                            <h4>Approved</h4>
                            <div className="mb2">{vendor.approved === 'approved' ? 'Yes' : 'No'}</div>
                            <div className="mb2">
                                {isNotVerified ? (
                                    <>
                                        <i className="Status-red mr1 fa fa-exclamation" aria-hidden="true" />
                                        <span>Debarment & Ineligibility Certification Not Verified</span>
                                    </>
                                ) : (
                                    <>
                                        <i className="Status-green mr1 fa fa-check" aria-hidden="true" />
                                        <span>Debarment & Ineligibility Certification Verified</span>
                                    </>
                                )}
                            </div>
                            <div className="mr2 mb2 flex flex-row justify-content-between">
                                <div>
                                    <h4>CAGE Code</h4>
                                    {vendor.sams ? (
                                        <div>{vendor.sams}</div>
                                    ) : (
                                        <div className="EmptyFormState">No CAGE Code Entered</div>
                                    )}
                                </div>
                                <div>
                                    <h4>Expires</h4>
                                    {vendor.sams_expire_on ? (
                                        <div>{vendor.sams_expire_on}</div>
                                    ) : (
                                        <div className="EmptyFormState">N/A</div>
                                    )}
                                </div>
                            </div>
                            <div className="mr2 mb2 flex flex-row justify-content-between">
                                <div>
                                    <h4>UEI Number</h4>
                                    {vendor.uei ? (
                                        <div>{vendor.uei}</div>
                                    ) : (
                                        <div className="EmptyFormState">No UEI Number Entered</div>
                                    )}
                                </div>
                                <div>
                                    <h4>Expires</h4>
                                    {vendor.uei_expire_on ? (
                                        <div>{vendor.uei_expire_on}</div>
                                    ) : (
                                        <div className="EmptyFormState">N/A</div>
                                    )}
                                </div>
                            </div>
                            <h4>Notes</h4>
                            {vendor.notes ? <div>{vendor.notes}</div> : <div>-</div>}
                        </div>
                        <div className="VerticalLine mr2" />
                        <div className="mr2 flex flex-column width-30">
                            <h4>Documents</h4>
                            <ul>
                                {vendor.w9_available && pathname.includes('admin') && (
                                    <ViewPageExternalLink link={vendor.w9} text="W-9" />
                                )}
                                {vendor.w9_available && pathname.includes('projects') && (
                                    <div>
                                        <h4>W-9</h4>
                                        <div className="EmptyFormState">W-9 is Available</div>
                                    </div>
                                )}
                                {!vendor.w9_available && (
                                    <div>
                                        <h4>W-9</h4>
                                        <div className="EmptyFormState">No Document Entered</div>
                                    </div>
                                )}
                                {vendor.financial_audit_available && pathname.includes('admin') && (
                                    <ViewPageExternalLink link={vendor.financial_audit} text="Financial Audit" />
                                )}
                                {vendor.financial_audit_available && pathname.includes('projects') && (
                                    <div>
                                        <h4>Financial Audit</h4>
                                        <div className="EmptyFormState">Financial Audit is Available</div>
                                    </div>
                                )}
                                {!vendor.financial_audit_available && (
                                    <div>
                                        <h4>Financial Audit</h4>
                                        <div className="EmptyFormState">No Document Entered</div>
                                    </div>
                                )}
                                {vendor.general_audit_available && pathname.includes('admin') && (
                                    <ViewPageExternalLink link={vendor.general_audit} text="General Audit" />
                                )}
                                {vendor.general_audit_available && pathname.includes('projects') && (
                                    <div>
                                        <h4>Financial Audit</h4>
                                        <div className="EmptyFormState">General Audit is Available</div>
                                    </div>
                                )}
                                {!vendor.general_audit_available && (
                                    <div>
                                        <h4>General Audit</h4>
                                        <div className="EmptyFormState">No Document Entered</div>
                                    </div>
                                )}
                                {vendor.documents &&
                                    pathname.includes('admin') &&
                                    vendor.documents.map((doc, index) => (
                                        <ViewPageExternalLink key={index} link={doc.doc_link} text={doc.name} />
                                    ))}
                            </ul>
                        </div>
                    </GridSection>
                    <GridSection title="Points of Contact">
                        {vendor.contacts ? (
                            vendor.contacts.map((poc, index) => (
                                <div key={index} className="flex flex-column mr2">
                                    <h4>
                                        {poc.first_name} {poc.last_name}
                                    </h4>
                                    {poc.email ? (
                                        <div>{poc.email}</div>
                                    ) : (
                                        <div className="EmptyFormState">No Emailed Entered</div>
                                    )}
                                    {poc.phone ? (
                                        <div>{phoneFormat(poc.phone)}</div>
                                    ) : (
                                        <div className="EmptyFormState">No Phone Number Entered</div>
                                    )}
                                    {poc.associated_agreement ? (
                                        <div>
                                            <h4>Associated Agreement</h4>
                                            {poc.associated_agreement}
                                        </div>
                                    ) : (
                                        <div className="EmptyFormState">No Associated Agreement</div>
                                    )}
                                    <h4>Notes</h4>
                                    <div> {poc.notes || '-'} </div>
                                </div>
                            ))
                        ) : (
                            <div className="EmptyFormState">No Point of Contacts Entered</div>
                        )}
                    </GridSection>
                    <GridSection title="Agreements">
                        <AgreementsTable data={vendor.agreements} onTableReady={hoistTableParams} />
                    </GridSection>
                </div>
            </div>
            <div className="ViewVendorButtons Form__buttons Align-right">
                <ExportCSVButton
                    fileName={editVendorNameFileName(vendor.name) + '-Agreements'}
                    exportFunction={exportDataAsCsv}
                />
                {pathname.includes('admin') && !isExecDir && (
                    <button type="button" onClick={openAddAgreement}>
                        Add Agreement
                    </button>
                )}
            </div>
            <AddNewVendorsModal vendor={vendor} openModal={vendorModalOpen} closeModal={closeEditVendor} />
            <AddAgreementModal vendor={vendor} openModal={agreementsModalOpen} closeModal={closeAddAgreement} />
            <DeleteVendorModal
                vendor={vendor}
                openModal={vendorDeleteModalOpen}
                closeModal={closeDeleteVendor}
                openAgreementCheck={agreementOpenCheck}
            />
        </Modal>
    );
}
