import React from 'react';
import AgreementInvoicesApi from '../../api/agreementInvoices';
import errorToast from '../../components/messages/ErrorMessage';
import EditingTable from '../../components/tables/EditingTable';

const AgreementInvoiceTable = ({ invoices, onTableReady, hoistPageEvent, hoistPaidEvent, projectStatus }) => {
    const handleDelete = async (event) => {
        try {
            const {
                data: {
                    id,
                    transaction: { status },
                },
                data,
                api,
            } = event;
            if (status !== 'Paid') {
                await AgreementInvoicesApi.deleteInvoiceByID(id);
                api.applyTransaction({ remove: [data] });
            } else {
                errorToast('Invoice already paid!');
            }
        } catch (error) {
            errorToast(error);
        }
    };

    const handleEdit = async (event) => {
        try {
            const {
                data: {
                    transaction: { status },
                },
                api,
            } = event;
            if (status === 'Paid') {
                api.stopEditing();
                hoistPaidEvent('Paid');
            }
            api.refreshCells({});
        } catch (error) {
            errorToast(error);
        }
    };

    const hoistUpdatedRowData = async (data) => {
        try {
            const {
                id,
                vendor_invoice_number,
                transaction: { amount, status },
                notes,
                invoice_link,
                performance_period_end_date,
            } = data;
            if (status !== 'Paid') {
                const attributes = {
                    amount: amount || 0,
                    vendor_invoice_number,
                    notes,
                    invoice_link,
                    performance_period_end_date,
                };
                await AgreementInvoicesApi.editInvoice(id, attributes);
                hoistPageEvent('submit');
            }
        } catch (error) {
            errorToast(error);
        }
    };

    if (projectStatus !== 'archived') {
        const columns = [
            { headerName: 'Vendor', field: 'agreement.vendor_name', type: 'name', editable: false },
            { headerName: 'Agreement Number', field: 'agreement.number', type: 'idNum', editable: false },
            { headerName: 'Vendor Invoice Number', field: 'vendor_invoice_number', type: 'idNum' },
            {
                headerName: 'Last Day of Performance Period',
                field: 'performance_period_end_date',
                type: 'date',
            },
            {
                headerName: 'Amount',
                field: 'transaction.amount',
                type: 'shortmoney',
            },
            {
                headerName: 'Status',
                field: 'transaction.status',
                type: 'mediumnumber',
                editable: false,
            },
            {
                headerName: 'Notes',
                field: 'notes',
                type: 'symbol',
                cellRenderer: 'NotesSymbolRenderer',
            },
            {
                headerName: 'Link to Invoice',
                field: 'invoice_link',
                type: 'symbol',
                cellRenderer: 'ExternalLinkSymbolRenderer',
            },
            {
                headerName: 'Initiated By',
                field: 'transaction.initiator.name',
                type: 'name',
                editable: false,
            },
            {
                headerName: 'Initiated On',
                field: 'transaction.created_on',
                type: 'date',
                editable: false,
            },
            {
                headerName: 'Last Update By',
                field: 'transaction.last_updater.name',
                type: 'name',
                editable: false,
            },
            {
                headerName: 'Updated On',
                field: 'transaction.updated_on',
                type: 'date',
                editable: false,
            },
            {
                headerName: 'Edit',
                field: 'edit',
                type: 'symbol',
                editable: false,
                cellRenderer: 'EditSymbolRenderer',
                onCellClicked: handleEdit,
            },
            {
                headerName: 'Del.',
                field: 'delete',
                type: 'symbol',
                editable: false,
                cellRenderer: 'TrashSymbolRenderer',
                onCellClicked: handleDelete,
            },
        ];

        return (
            <EditingTable
                data={invoices}
                columns={columns}
                pagingProp={true}
                sizeToFit={true}
                cellEditingStopped={hoistUpdatedRowData}
                onTableReady={onTableReady}
            />
        );
    }

    const columns = [
        { headerName: 'Vendor', field: 'agreement.vendor_name', type: 'name', editable: false },
        { headerName: 'Agreement Number', field: 'agreement.number', type: 'idNum', editable: false },
        { headerName: 'Vendor Invoice Number', field: 'vendor_invoice_number', type: 'idNum', editable: false },
        {
            headerName: 'Last Day of Performance Period',
            field: 'performance_period_end_date',
            type: 'date',
            editable: false,
        },
        {
            headerName: 'Amount',
            field: 'transaction.amount',
            type: 'shortmoney',
            editable: false,
        },
        {
            headerName: 'Status',
            field: 'transaction.status',
            type: 'mediumnumber',
            editable: false,
        },
        {
            headerName: 'Notes',
            field: 'notes',
            type: 'symbol',
            editable: false,
            cellRenderer: 'NotesSymbolRenderer',
        },
        {
            headerName: 'Link to Invoice',
            field: 'invoice_link',
            type: 'symbol',
            editable: false,
            cellRenderer: 'ExternalLinkSymbolRenderer',
        },
        {
            headerName: 'Initiated By',
            field: 'transaction.initiator.name',
            type: 'name',
            editable: false,
        },
        {
            headerName: 'Initiated On',
            field: 'transaction.created_on',
            type: 'date',
            editable: false,
        },
        {
            headerName: 'Last Update By',
            field: 'transaction.last_updater.name',
            type: 'name',
            editable: false,
        },
        {
            headerName: 'Updated On',
            field: 'transaction.updated_on',
            type: 'date',
            editable: false,
        },
    ];
    return <EditingTable data={invoices} columns={columns} sizeToFit={true} onTableReady={onTableReady} />;
};

export default AgreementInvoiceTable;
