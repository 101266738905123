import React from 'react';
import TravelApprovalCards from './travelApproval/TravelApprovalCards';
import errorToast from '../components/messages/ErrorMessage';
import { addNote } from '../components/modals/AddNoteModal';
import TravelRequestsApi from '../api/travelRequests';
import UsersApi from '../api/users';
import TravelExpenseApi from '../api/travelExpenses';

class TravelApprovalPage extends React.Component {
    constructor(props) {
        super(props);

        this.getUserNames = this.getUserNames.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);

        this.state = {
            currentUser: null,
            users: null,
            needApproval: null,
        };
    }

    async componentDidMount() {
        try {
            let accountType;
            let currentPath = this.props.match.url;
            if (currentPath.includes('coreFunding')) {
                accountType = 'CoreBudgetType';
            } else {
                accountType = 'Project';
            }
            const { data: userApprover } = await TravelRequestsApi.fetchTravelRequestsApprover(accountType);
            const { data: users } = await UsersApi.getUsers();
            let { data: travelExpenses } = await TravelExpenseApi.getTravelExpenses('Approver');

            let user = localStorage.getItem('WBI.AUTH.NAME');
            let needApprover = [];
            if (userApprover && users) {
                for (let i = 0; i < userApprover.length; i++) {
                    userApprover[i] = this.getUserNames(users, userApprover[i]);
                    if (userApprover[i].status === 'Pending') {
                        userApprover[i].typeOfApproval = 'Travel Approval Request';
                        needApprover.push(userApprover[i]);
                    }
                }
            }

            if (travelExpenses) {
                travelExpenses = travelExpenses.filter((expense) => expense.status === 'Pending');
                travelExpenses = travelExpenses.filter((expense) => expense.account_type === accountType);
                travelExpenses.forEach((expense) => {
                    expense.typeOfApproval = 'Travel Expense Approval Request';
                    needApprover.push(expense);
                });
            }

            this.setState({
                currentUser: user,
                travelHistory: userApprover,
                users,
                needApproval: needApprover,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    setDateRange = (startDate, endDate) => {
        startDate = new Date(startDate);
        endDate = new Date(endDate);

        let dateRange = [];
        let currentDate = startDate;
        let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        while (currentDate <= endDate) {
            currentDate.setDate(currentDate.getDate() + 1);
            dateRange.push(currentDate.toLocaleDateString('en-US', options));
        }
        return dateRange;
    };

    getUserNames(users, request) {
        if (request) {
            users.forEach((user) => {
                if (request.traveler_id === user.id) request.traveler_name = user.name;

                if (request.approver.id === user.id) request.approver_name = user.name;
            });
        }
        return request;
    }

    approveExpense = (id, index) => {
        TravelExpenseApi.approveTravelExpense(id)
            .then(() => {
                let newApproval = this.state.needApproval.filter((_, i) => index !== i);
                this.setState({ needApproval: newApproval });
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    };

    returnExpense = async (id, index) => {
        const { needApproval } = this.state;
        let expense = needApproval[index];

        const note = await addNote(expense, 'TravelExpense');
        let attribute = {
            notes: note,
            // Must have in_draft for update
            in_draft: false,
            // Add flag to prevent flip from returned to pending
            return_note_flag: true,
        };

        TravelExpenseApi.returnTravelExpense(id)
            .then(() => {
                TravelExpenseApi.updateTravelExpense(expense.id, attribute).catch((errorMessage) => {
                    errorToast(errorMessage);
                });

                let newApproval = needApproval.filter((_, i) => index !== i);
                this.setState({ needApproval: newApproval });
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    };

    approve(id, index) {
        TravelRequestsApi.approveTravelRequest(id)
            .then(() => {
                let newApproval = this.state.needApproval.filter((_, i) => index !== i);
                this.setState({ needApproval: newApproval });
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    reject(id, index) {
        TravelRequestsApi.rejectTravelRequest(id)
            .then(() => {
                let newApproval = this.state.needApproval.filter((_, i) => index !== i);
                this.setState({ needApproval: newApproval });
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    render() {
        const { needApproval } = this.state;

        let approval = null;
        if (needApproval) {
            approval = (
                <TravelApprovalCards
                    approvals={needApproval}
                    approve={this.approve}
                    reject={this.reject}
                    approveExpense={this.approveExpense}
                    returnExpense={this.returnExpense}
                />
            );
        }

        return <>{approval}</>;
    }
}

export default TravelApprovalPage;
