import React, { useState, useEffect } from 'react';
import FinanceSubNav from './FinanceSubNav';
import PageHeader from '../components/heading/PageHeader';
import ModsTable from './mods/modsTable';
import ModsForm from './mods/ModsForm';
import errorToast from '../components/messages/ErrorMessage';
import ModsApi from '../api/mods';
import PiaApi from '../api/pia';
import CpoApi from '../api/cpo';
import ProjectsApi from '../api/projects';
import UsersApi from '../api/users';

const ModsPage = () => {
    const [mods, setMods] = useState([]);
    const [pias, setPias] = useState([]);
    const [cpos, setCpos] = useState([]);
    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [modToUpdate, setModToUpdate] = useState(null);

    const removeModFromListById = (modId) => {
        setMods((mods) => mods.filter((mod) => mod.id !== modId));
    };

    const addModToTable = (mod) => {
        setMods((mods) => [mod, ...mods]);
    };

    const updateMod = (mod) => {
        setModToUpdate(mod);
        removeModFromListById(mod.id);
    };

    const returnModToTable = (returnedMod) => {
        addModToTable(returnedMod);
        setModToUpdate(null);
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const { data: mods } = await ModsApi.getMods();
                setMods(mods);
                const { data: pias } = await PiaApi.getPias();
                setPias(pias);
                const { data: cpos } = await CpoApi.getCpos();
                setCpos(cpos);
                const { data: projects } = await ProjectsApi.fetchProjects();
                setProjects(projects);
                const { data: users } = await UsersApi.getAllUsers();
                setUsers(users);
            } catch (error) {
                errorToast(error);
            }
        };
        getData();
    }, []);

    return (
        <div>
            <PageHeader title="Mods" />
            <FinanceSubNav />
            <ModsForm
                modToUpdate={modToUpdate}
                returnModToTable={returnModToTable}
                addModToTable={addModToTable}
                pias={pias}
                cpos={cpos}
                projects={projects}
            />
            <ModsTable
                isAFRL={false}
                updateMod={updateMod}
                removeMod={removeModFromListById}
                mods={mods}
                pias={pias}
                cpos={cpos}
                users={users}
                projects={projects}
            />
        </div>
    );
};

export default ModsPage;
