import React, { useState, useEffect } from 'react';
import Modal from '../../components/modals/Modal';
import VendorsApi from '../../api/vendors';

const DeleteVendorModal = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const { closeModal, vendor, openAgreementCheck } = props;

    useEffect(() => {
        setOpenModal(props.openModal);
    }, [props.openModal]);

    const deleteVendor = () => {
        if (vendor) {
            VendorsApi.deleteVendor(vendor.id).then(() => closeModal());
        }
    };
    return (
        <Modal isOpen={openModal} style={{ minWidth: '30vw' }}>
            <div className="flex Align-right">
                <button onClick={closeModal}>
                    <i className="fa fa-window-close" />
                </button>
            </div>
            {openAgreementCheck ? (
                <div>
                    <p>The {vendor.name} vendor is not allowed to be deleted.</p>
                    <p> {vendor.name} has at least one open agreement.</p>
                    <div className="mt2 flex Align-right">
                        <button onClick={closeModal}>Close</button>
                    </div>
                </div>
            ) : (
                <div>
                    <p>Are you sure about deleting the {vendor.name} vendor?</p>
                    <div className="mt2 flex Align-right">
                        <button className="mr1" onClick={deleteVendor}>
                            YES
                        </button>
                        <button onClick={closeModal}>NO</button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default DeleteVendorModal;
