import React from 'react';
import SubNav, { SubNavLink } from '../components/navigation/SubNav';

const ReportsSubNav = () => {
    const url = '/reports';
    return (
        <SubNav>
            <SubNavLink to={url} exact>
                Project Reports
            </SubNavLink>
            <SubNavLink to={url + '/project-manager-reports'}>Project Manager Reports</SubNavLink>
            <SubNavLink to={url + '/all-projects-reports'}>All Projects Reports</SubNavLink>
        </SubNav>
    );
};

export default ReportsSubNav;
