import React, { Component } from 'react';
//Library
import _find from 'lodash/find';
//api
import ProjectsApi from '../../api/projects';
import ResidualFundsApi from '../../api/residualFunds';
import TransactionApi from '../../api/transaction';
import UsersApi from '../../api/users';
//Shared Component
import FinanceSubNav from '../FinanceSubNav';
import PageHeader from '../../components/heading/PageHeader';
import SubSubNav, { SubSubNavLink } from '../../components/navigation/SubSubNav';
import ApprovalCards from './ApprovalCards';
import errorToast from '../../components/messages/ErrorMessage';

class FundsApprovalsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            approvals: null,
        };
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
    }

    componentDidMount = async () => {
        try {
            const { data: approvals } = await TransactionApi.getTransactionsByApprover();
            const { data: projects } = await ProjectsApi.fetchProjects();
            const { data: users } = await UsersApi.getUsers();
            const { data: residualFunds } = await ResidualFundsApi.getResidualFundWithFundsTotal();

            if (approvals) {
                approvals.forEach((approval) => {
                    if (approval.destination_type === 'Project' && projects) {
                        approval.transfer_type = 'Transfer Residual Funds to Project';
                        let project = _find(projects, { id: approval.destination_id });
                        approval.project_number = project.number;
                        let residualFund = _find(residualFunds, { id: approval.source_id });
                        approval.residual_funds_name = residualFund.name;
                    }

                    if (approval.initiator_id && users) {
                        let user = _find(users, { id: approval.initiator_id });
                        approval.initiator_name = user.name;
                    }
                });
            }
            this.setState({ approvals });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    approve(id, index) {
        TransactionApi.approveTransaction(id, {})
            .then(() => {
                let newApprovals = this.state.approvals.filter((_, i) => index !== i);

                this.setState({
                    approvals: newApprovals,
                });
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    reject(id, index) {
        TransactionApi.rejectTransaction(id)
            .then(() => {
                let newApprovals = this.state.approvals.filter((_, i) => index !== i);

                this.setState({
                    approvals: newApprovals,
                });
            })
            .catch((errorMessage) => {
                this.setState({ errorMessage });
            });
    }

    render() {
        const { match } = this.props;
        let approvals;

        if (!this.state.approvals) return <div>loading...</div>;

        if (this.state.approvals) approvals = this.state.approvals;

        return (
            <div>
                <PageHeader title="Residual Funds & Transfers" />
                <FinanceSubNav />
                <SubSubNav>
                    <SubSubNavLink to="/finance/residualFunds" exact>
                        Residual Funds
                    </SubSubNavLink>
                    <SubSubNavLink to={match.url}>Approvals</SubSubNavLink>
                </SubSubNav>
                <ApprovalCards approvals={approvals} approve={this.approve} reject={this.reject} />
            </div>
        );
    }
}

export default FundsApprovalsPage;
