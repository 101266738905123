import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AuthService from '../auth/auth.service';
import AgreementsApi from '../api/agreements';
import VendorsApi from '../api/vendors';
import SectionHeading from '../components/heading/SectionHeading';
import errorToast from '../components/messages/ErrorMessage';
import Modal from '../components/modals/Modal';
import GridSection from '../components/section/GridSection';
import AmendAgreementForm from './agreementForm/AmendAgreementForm';
import AgreementHistoryTable from './AgreementHistoryTable';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import useFunctionAsState from '../hooks/useFunctionAsState';
import EditAgreementModal from './agreementForm/EditAgreementModal';
import AgreementMessageModal from './agreementForm/AgreementMessageModal';
import CheckAgreementStatusModal from './agreementForm/CheckAgreementStatusModal';
import ViewPageExternalLink from '../utils/ViewPageExternalLink';
import ViewPageInternalLink from '../utils/ViewPageInternalLink';
import ErgoMoney from '../utils/ErgoMoney';
import { capitalizeWord } from '../utils/utils';
import siteRoles from '../utils/SiteRoles';

export default function AgreementViewPage() {
    const { id } = useParams();
    const history = useHistory();
    const { pathname } = useLocation();
    const user = AuthService.getUser();
    const [userRole, setUserRole] = useState('');
    const [documentLockDisabled, setDocumentLockDisabled] = useState(true);
    const [lockChecked, setLockChecked] = useState(false);
    const [vendor, setVendor] = useState({});
    const [vendorFullData, setVendorFullData] = useState({});
    const [agreement, setAgreement] = useState({});
    const [agreementNumberList, setAgreementNumberList] = useState([]);
    const [amendments, setAmendments] = useState([]);
    const [updateAgreementId, setUpdateAgreementId] = useState('');
    const [updateAmendmentId, setUpdateAmendmentId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalCheckAgreement, setModalCheckAgreement] = useState(false);
    const [modalAmendOpen, setAmendModalOpen] = useState(true);
    const [modalMessageOpen, setMessageModalOpen] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [message, setMessage] = useState('');
    const [agreementStatusCheck, setAgreementStatusCheck] = useState(false);
    const [agreementCheckType, setAgreementCheckType] = useState('');
    const [exportDataAsCsv, setExportDataAsCsv] = useFunctionAsState(() => {});
    const locked = documentLockDisabled ? lockChecked : documentLockDisabled;

    const hoistTableParams = (params) => {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            setExportDataAsCsv(params.exportDataAsCsv);
        }
    };

    const hoistDeleteEvent = (agreementId, amendmentId, deletedNTEChange) => {
        setUpdateAgreementId(agreementId);
        setUpdateAmendmentId(amendmentId);
        if (deletedNTEChange !== 0) {
            setMessageModalOpen(true);
            setMessage("Update each assigned Project's NTE Allocation");
        }
    };

    const hoistUpdateEvent = (type) => {
        if (type === 'nte_change') {
            setMessageModalOpen(true);
            setMessage("Check each assigned Project's NTE Allocation");
            setUpdate(true);
        }
    };

    const closeMessageAgreement = () => {
        setMessageModalOpen(false);
        setUpdate(true);
    };

    const openEdit = () => {
        setModalOpen(true);
        setUpdate(false);
    };

    const closeEdit = () => {
        setUpdate(true);
        setModalOpen(false);
    };

    const openCheckAgreement = (type) => {
        setModalCheckAgreement(true);
        setAgreementCheckType(type);
    };

    const closeCloseAgreementModal = () => {
        setModalCheckAgreement(false);
    };

    const closeAgreementConfirmation = () => {
        setConfirmClose(true);
        setModalCheckAgreement(false);
    };
    const openAgreementConfirmation = () => {
        setConfirmOpen(true);
        setModalCheckAgreement(false);
    };

    const openAmendAgreement = () => {
        setUpdate(false);
        setAmendModalOpen(false);
    };

    const closeAmendAgreement = (nteChanged) => {
        setAgreement({});
        setAmendments([]);
        setAmendModalOpen(true);
        if (nteChanged) {
            setMessageModalOpen(true);
            setMessage("Agreement's NTE changed. Update each assigned Project's NTE Allocation");
        }
    };

    const closeAgreement = () => {
        openCheckAgreement('close');
    };

    const reopenAgreement = () => {
        openCheckAgreement('reopen');
    };

    const onLockChecked = async (event) => {
        const { target } = event;
        setLockChecked(target.checked);
        try {
            await AgreementsApi.lockAgreementById(id, { locked: target.checked });
        } catch (error) {
            setLockChecked(!target.checked);
            errorToast(error);
        }
    };

    useEffect(() => {
        if (confirmClose) {
            let closedAgreement = {
                status: 'closed',
            };
            AgreementsApi.setAgreementStatus(id, closedAgreement).then(() => {
                history.goBack();
                setModalOpen(false);
                setConfirmClose(false);
                setUpdate(true);
            });
        }
        if (confirmOpen) {
            let reopenAgreementStatus = {
                status: 'open',
            };
            AgreementsApi.setAgreementStatus(id, reopenAgreementStatus).then(() => {
                setConfirmOpen(false);
                setUpdate(true);
            });
        }
    }, [id, confirmClose, confirmOpen, history]);

    useEffect(() => {
        if (user !== null) {
            switch (user.site_role) {
                case siteRoles.EXEC:
                    setUserRole(siteRoles.EXEC);
                    break;
                case siteRoles.ADMIN:
                    setUserRole(siteRoles.ADMIN);
                    setDocumentLockDisabled(false);
                    break;
                case siteRoles.FINANCIAL:
                    setUserRole(siteRoles.FINANCIAL);
                    setDocumentLockDisabled(false);
                    break;
                case siteRoles.PM:
                    setUserRole(siteRoles.PM);
                    break;
                default:
                    break;
            }
        }

        if (agreement.status === 'open') {
            setAgreementStatusCheck(true);
        }
    }, [user, agreement]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await AgreementsApi.getAgreementById(id);
                if (data.amendments) {
                    setAmendments(data.amendments);
                }

                setAgreement(data);
                setVendor(data.vendor);
                setLockChecked(data.locked);
            } catch (error) {
                errorToast(error);
            }
        };
        if (!agreement.number || update) {
            fetchData();
            setUpdate(false);
        }
    }, [agreement, update, id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (vendor.id) {
                    const { data } = await VendorsApi.getVendorById(vendor.id);
                    setVendorFullData(data);
                }
            } catch (error) {
                errorToast(error);
            }
        };
        fetchData();
    }, [vendor]);

    useEffect(() => {
        if (vendorFullData.agreements) {
            if (Object.keys(vendorFullData.agreements).length !== 0) {
                let agreementsNumber = [];
                let agreements = vendorFullData.agreements;
                agreements.forEach((a) => {
                    agreementsNumber.push(a.number);
                });
                setAgreementNumberList(agreementsNumber);
            }
        }
    }, [vendorFullData, amendments]);

    useEffect(() => {
        const updateData = async () => {
            try {
                const { data } = await AgreementsApi.deleteAmendmentById(updateAgreementId, updateAmendmentId);
                setAmendments(data.amendments);
                setAgreement(data);
            } catch (error) {
                errorToast(error);
            }
        };
        if (updateAmendmentId !== '' && updateAgreementId !== '') {
            updateData();
        }
    }, [updateAgreementId, updateAmendmentId]);

    return (
        <Modal isOpen={true} style={{ minWidth: '80vw' }}>
            <div className="ViewAgreement">
                <div className="flex justify-content-between mb2">
                    <div>
                        <button onClick={() => history.goBack()} className="TextButton">
                            <i className="fa fa-arrow-left" /> Back
                        </button>
                        {vendor.name && <SectionHeading className="align-left"> {vendor.name} </SectionHeading>}
                        {agreement.number && <div>{agreement.number}</div>}
                    </div>
                    <button onClick={() => history.goBack()}>
                        <i className="fa fa-window-close" />
                    </button>
                </div>
                <div className="mb2 pt2 flex justify-content-between">
                    <div className="flex flex-column">
                        <h4>Agreement Status</h4>
                        {agreement.status && <div>{capitalizeWord(agreement.status)}</div>}
                    </div>
                    {pathname.includes(siteRoles.ADMIN.toLocaleLowerCase()) &&
                        userRole !== siteRoles.EXEC &&
                        agreement.status === 'open' && (
                            <button type="button" className="TextButton" onClick={openEdit}>
                                <i className="fa fa-pencil-square-o" aria-hidden="true" /> Edit Agreement
                            </button>
                        )}
                </div>
                <GridSection title="Current Agreement Information">
                    <div className="mr2 flex flex-column width-30">
                        <h4>Agreement No.</h4>
                        {agreement.number ? (
                            <div className="mb2">{agreement.number}</div>
                        ) : (
                            <div className="EmptyFormState mt1">No Agreement Number Entered</div>
                        )}
                        <h4>Agreement Description</h4>
                        {agreement.description ? (
                            <div className="mb2">{agreement.description}</div>
                        ) : (
                            <div className="EmptyFormState mt1">No Agreement Description Entered</div>
                        )}
                        <h4>Agreement Type</h4>
                        {agreement.type ? (
                            <div className="mb2"> {agreement.type}</div>
                        ) : (
                            <div className="EmptyFormState mb1">No Agreement Type Selected</div>
                        )}
                        <h4>Not to Exceed (NTE)</h4>
                        {agreement.nte ? (
                            <div className="mb2">{new ErgoMoney(agreement.nte).getStringAmount()}</div>
                        ) : (
                            <div className="EmptyFormState mb1">No NTE Entered</div>
                        )}
                        <div className="flex">
                            <div className="mr2">
                                <h4>Start Date </h4>
                                {agreement.start_date ? (
                                    <div> {agreement.start_date} </div>
                                ) : (
                                    <div className="EmptyFormState mt1">N/A</div>
                                )}
                            </div>

                            <div>
                                <h4>End Date</h4>
                                {agreement.end_date ? (
                                    <div> {agreement.end_date} </div>
                                ) : (
                                    <div className="EmptyFormState mt1">N/A</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="VerticalLine mr2" />
                    <div className="mr2 flex flex-column width-30">
                        <div className="ProjectsGrid">
                            <h4 className="Left mb2">Assigned Projects</h4>
                            <h4 className="Right mb2">NTE Allocation</h4>
                            {agreement.projects ? (
                                agreement.projects.map((project, index) => (
                                    <Fragment key={'ProjectsGrid-' + index}>
                                        <h4 className="Left">
                                            <ViewPageInternalLink
                                                route={`/projects/${project.project_id}`}
                                                linkName={project.project_name}
                                            />
                                        </h4>
                                        <div className="Left">{project.project_number}</div>
                                        {project.nte !== null && <div className="Right">${project.nte}</div>}
                                        {project.nte === null && <div className="Right">No NTE Entered</div>}
                                    </Fragment>
                                ))
                            ) : (
                                <>
                                    <div className="Left EmptyFormState">No Projects Selected</div>
                                </>
                            )}
                            <div className="HorizontalLine mt1" />
                        </div>
                    </div>
                    <div className="VerticalLine mr2" />
                    <div className="mr2 flex flex-column width-20">
                        <h4>Documents</h4>
                        <ul>
                            {agreement.agreement_link ? (
                                <ViewPageExternalLink
                                    link={agreement.agreement_link}
                                    text="DB Link to Agreement"
                                    disabled={locked}
                                />
                            ) : (
                                <div>
                                    <h4>DB Link to Agreement</h4>
                                    <div className="EmptyFormState">No Document Entered</div>
                                </div>
                            )}
                            {agreement.bids_package ? (
                                <ViewPageExternalLink link={agreement.bids_package} text="DB Link to Bids Package" />
                            ) : (
                                <div>
                                    <h4>DB Link to Bids Package</h4>
                                    <div className="EmptyFormState">No Document Entered</div>
                                </div>
                            )}
                            {agreement.sole_source ? (
                                <ViewPageExternalLink
                                    link={agreement.sole_source}
                                    text="DB Link to Sole Source Justification"
                                />
                            ) : (
                                <div>
                                    <h4>DB Link to Sole Source Justification</h4>
                                    <div className="EmptyFormState">No Document Entered</div>
                                </div>
                            )}
                            {agreement.documents &&
                                agreement.documents.map((doc, index) => (
                                    <ViewPageExternalLink key={index} link={doc.doc_link} text={doc.name} />
                                ))}
                        </ul>
                    </div>
                    <div className="LockDocument">
                        <input
                            type="checkbox"
                            id="locked"
                            name="locked"
                            onChange={(e) => onLockChecked(e)}
                            checked={lockChecked}
                            disabled={documentLockDisabled}
                        />
                        <label htmlFor="locked">Locked</label>
                    </div>
                </GridSection>
                <GridSection title="Agreement History">
                    <div className="flex flex-column column-flex-100">
                        <AgreementHistoryTable
                            data={amendments}
                            onTableReady={hoistTableParams}
                            hoistDeleteEvent={hoistDeleteEvent}
                            hoistUpdateEvent={hoistUpdateEvent}
                            mode={userRole === siteRoles.PM ? 'view' : 'edit'}
                            locked={locked}
                        />
                        <div className="ViewVendorButtons Form__buttons Align-right">
                            <ExportCSVButton
                                fileName={vendor.name + '-Agreement-History'}
                                exportFunction={exportDataAsCsv}
                            />
                            {pathname.includes(siteRoles.ADMIN.toLocaleLowerCase()) &&
                                userRole !== siteRoles.EXEC &&
                                agreement.status === 'open' && (
                                    <button type="button" onClick={openAmendAgreement}>
                                        Amend Agreement
                                    </button>
                                )}
                        </div>
                    </div>
                </GridSection>
                <GridSection title="Amend Agreement" className={`AmendAgreement ${modalAmendOpen && 'hidden'}`}>
                    <AmendAgreementForm closeModal={closeAmendAgreement} id={id} agreement={agreement} />
                </GridSection>
            </div>
            <div className="mt2 flex ViewVendorButtons Form__buttons justify-content-between">
                <button type="reset" onClick={() => history.goBack()}>
                    <i className="fa fa-arrow-left mr1" />
                    Back
                </button>
                {pathname.includes(siteRoles.ADMIN.toLocaleLowerCase()) &&
                    userRole !== siteRoles.EXEC &&
                    agreementStatusCheck && (
                        <button type="submit" onClick={closeAgreement}>
                            Close Agreement
                        </button>
                    )}
                {pathname.includes(siteRoles.ADMIN.toLocaleLowerCase()) &&
                    userRole !== siteRoles.EXEC &&
                    !agreementStatusCheck && (
                        <button type="submit" onClick={reopenAgreement}>
                            Reopen Agreement
                        </button>
                    )}
            </div>
            <AgreementMessageModal closeModal={closeMessageAgreement} openModal={modalMessageOpen} message={message} />
            <CheckAgreementStatusModal
                closeModal={closeCloseAgreementModal}
                openModal={modalCheckAgreement}
                agreement={agreement}
                confirmClosing={closeAgreementConfirmation}
                confirmOpening={openAgreementConfirmation}
                type={agreementCheckType}
            />
            <EditAgreementModal
                closeModal={closeEdit}
                openModal={modalOpen}
                closeAgreement={closeAgreement}
                agreement={agreement}
                agreementNumberList={agreementNumberList}
                vendor={vendor}
                hoistOnTableReady={hoistTableParams}
                exportDataAsCsv={exportDataAsCsv}
            />
        </Modal>
    );
}
