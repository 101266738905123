import React from 'react';
import { Route } from 'react-router-dom';
import ExpendituresPage from './ExpendituresPage';
import AgreementInvoicesPage from './AgreementInvoicesPage';
import ApprovalsPage from './ApprovalsPage';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';

const ExpendituresAndInvoicesPage = (props) => {
    const { match } = props;
    return (
        <>
            <SubSubNav>
                <SubSubNavLink to={match.url} exact>
                    Credit Card
                </SubSubNavLink>
                <SubSubNavLink to={match.url + '/invoices'}>Agreement Invoices</SubSubNavLink>
                <SubSubNavLink to={match.url + '/approvals'}>Approvals</SubSubNavLink>
            </SubSubNav>
            <Route path={match.path} exact component={ExpendituresPage} />
            <Route path={match.path + '/invoices'} component={AgreementInvoicesPage} />
            <Route path={match.path + '/approvals'} component={ApprovalsPage} />
        </>
    );
};

export default ExpendituresAndInvoicesPage;
