import React, { Component } from 'react';
import _find from 'lodash/find';
import CoreFundingSubNav from './CoreFundingSubNav';
import PageHeader from '../components/heading/PageHeader';
import ExpendituresForm from './ExpendituresForm';
import EditingTable from '../components/tables/EditingTable';
import { confirm } from '../components/modals/ConfirmModal';
import errorToast from '../components/messages/ErrorMessage';
import CoreBudgetTypesApi from '../api/coreBudgetTypes';
import ExpendituresApi from '../api/expenditures';
import UsersApi from '../api/users';

class ExpendituresPage extends Component {
    constructor(props) {
        super(props);

        this.getRowData = this.getRowData.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.createExpenditure = this.createExpenditure.bind(this);
        this.submitExpenditure = this.submitExpenditure.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);

        this.state = {
            expenditures: [],
            expendituresTypeOptions: null,
            coreBudgetTypes: null,
            cbtOptions: null,
            users: null,
            rowToUpdate: null,
            forceSetData: false,
            columns: [
                {
                    headerName: 'Type',
                    field: 'type',
                    type: 'type',
                    editable: true,
                },
                {
                    headerName: 'Core Fund',
                    field: 'account.name',
                    type: 'mediumtext',
                    editable: true,
                },
                {
                    headerName: 'Credit Card Type',
                    field: 'cc_type',
                    type: 'mediumnumber',
                    editable: true,
                },
                {
                    headerName: 'Transaction Date',
                    field: 'purchase_date',
                    type: 'date',
                    editable: true,
                },
                {
                    headerName: 'Item Description',
                    field: 'item_description',
                    type: 'mediumtext',
                    editable: true,
                },
                {
                    headerName: 'Cost',
                    field: 'cost',
                    type: 'shortmoney',
                    editable: true,
                },
                {
                    headerName: 'Store',
                    field: 'store',
                    type: 'name',
                    editable: true,
                },
                {
                    headerName: 'Card Holder',
                    field: 'cardHolder',
                    editable: true,
                    type: 'shortname',
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    type: 'shortname',
                    editable: true,
                },
                {
                    headerName: 'Link to DB',
                    field: 'link_to_db',
                    type: 'symbol',
                    cellRenderer: 'ExternalLinkSymbolRenderer',
                    onCellClicked: this.directToDb,
                },
                {
                    headerName: 'Initiated By',
                    field: 'initiator',
                    type: 'name',
                },
                {
                    headerName: 'Initiated On',
                    field: 'created_on',
                    type: 'date',
                },
                {
                    headerName: 'Last Update By',
                    field: 'last_updated_by',
                    type: 'name',
                },
                {
                    headerName: 'Updated On',
                    field: 'updated_on',
                    type: 'date',
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.onRemoveSelected,
                },
            ],
        };
    }

    async componentDidMount() {
        try {
            const { data: coreBudgetTypes } = await CoreBudgetTypesApi.getCoreBudgetTypes();
            const { data: expenditureTypes } = await ExpendituresApi.fetchExpenditureTypes();
            const { data: users } = await UsersApi.getUsers();
            let { data: expenditures } = await CoreBudgetTypesApi.getCoreBudgetExpenditures();

            if (expenditures) {
                expenditures = expenditures.filter((e) => e.type !== 'Travel Expenditure');
            }

            let approvalUsers = users.filter((user) => user.approver === true);
            let approverUserOptions = [];
            approvalUsers.forEach((user) => {
                approverUserOptions.push({ label: user.name, value: user.id });
            });

            let ccHolders = users.filter((e) => e.cc_holder === true);
            let ccHolderOptions = [];
            ccHolders.forEach((user) => {
                ccHolderOptions.push({ label: user.name, value: user.id });
            });

            let cbtOptions = [];
            if (coreBudgetTypes) {
                coreBudgetTypes.forEach((cbt) => {
                    cbtOptions.push({ label: cbt.name, value: cbt.id });
                });
                cbtOptions.sort((a, b) => a.label.localeCompare(b.label));
            }

            let expendituresTypeOptions = [];
            if (expenditureTypes) {
                for (let pd in expenditureTypes) {
                    expendituresTypeOptions.push({ label: expenditureTypes[pd], value: pd });
                }
                expendituresTypeOptions.sort((a, b) => a.label.localeCompare(b.label));
            }

            this.setState({
                coreBudgetTypes,
                cbtOptions,
                expendituresTypeOptions,
                expenditures,
                approverUserOptions,
                ccHolderOptions,
                users,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    isEquivalent = (a, b) => {
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);

        if (aProps.length !== bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        return true;
    };

    getRowData = async (event) => {
        let rowToUpdate = event.node.data;
        if (rowToUpdate.status === 'Applied') {
            await confirm({
                text: 'This expenditure has already been applied and can no longer be edited by a project user, please contact finance.',
                acceptText: 'Ok',
                rejectText: null,
            });
        } else {
            let { expenditures } = this.state;
            if (expenditures) {
                expenditures = expenditures.filter((expenditure) => rowToUpdate.id !== expenditure.id);
            }
            this.setState({
                rowToUpdate,
                expenditures,
            });
        }
    };

    cancelEdit = () => {
        let { rowToUpdate, expenditures } = this.state;

        if (rowToUpdate) {
            expenditures.unshift(rowToUpdate);
            expenditures.sort((a) => (a.status === 'submitted' ? 1 : -1));
            this.setState(
                {
                    expenditures,
                    rowToUpdate: null,
                    forceSetData: true,
                },
                () => {
                    this.setState({ forceSetData: false });
                }
            );
        }
    };

    onRemoveSelected = async (event) => {
        let expToUpdate = event.data;
        if (expToUpdate.status === 'Applied') {
            await confirm({
                text: 'This expenditure has already been applied and can no longer be deleted by a project user, please contact finance.',
                acceptText: 'Ok',
                rejectText: null,
            });
        } else {
            const confirmed = await confirm({
                text: 'Are you sure you want to remove this expenditure?  All data will be lost.',
            });

            if (confirmed) {
                ExpendituresApi.removeExpenditures(expToUpdate.id)
                    .then(() => {
                        let newExpenditures = this.state.expenditures.filter(
                            (expenditure) => expToUpdate.id !== expenditure.id
                        );
                        this.setState({ expenditures: newExpenditures });
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            }
        }
    };

    handleSubmit = (values, actions) => {
        const { rowToUpdate } = this.state;

        let attributes = {
            cc_type: values.cc_type,
            ccHolder_id: values.ccHolder_id,
            cost: values.cost,
            greatest_unit_cost: values.greatest_unit_cost,
            item_description: values.item_description,
            link_to_db: values.link_to_db,
            purchase_date: values.purchase_date,
            store: values.store,
            type: values.type,
            account_type: 'CoreBudgetType',
        };

        attributes.type = values.type.value ? values.type.value : values.type;
        attributes.account_id = values.account_type.value ? values.account_type.value : values.account_type;
        if (values.approver_id) {
            attributes.approver_id = values.approver_id.value ? values.approver_id.value : values.approver_id;
        }

        if (!values.approver_id && !rowToUpdate) {
            // Create new expenditure with no approver
            this.createExpenditure('createExpenditureNoApprover', attributes);
        } else if (values.approver_id && !rowToUpdate) {
            // Create new expenditure with approver
            this.createExpenditure('createExpenditure', attributes);
        } else if (rowToUpdate && (rowToUpdate.status === 'Submitted' || rowToUpdate.status === 'Pending')) {
            let changeOfApprover = false;
            if (rowToUpdate.approver) {
                changeOfApprover = rowToUpdate.approver.id !== attributes.approver_id;
            } else if (!rowToUpdate.approver && values.approver_id) {
                changeOfApprover = true;
            }
            if (changeOfApprover) {
                this.submitExpenditure('updateAndSubmitApprovalExpenditure', rowToUpdate.id, attributes);
            } else {
                ExpendituresApi.updateExpenditure(rowToUpdate.id, attributes)
                    .then((response) => {
                        let { expenditures } = this.state;
                        expenditures.unshift(response.data);

                        this.setState(
                            {
                                expenditures,
                                forceSetData: true,
                                rowToUpdate: null,
                            },
                            () => {
                                this.setState({ forceSetData: false });
                            }
                        );
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            }
        } else if (rowToUpdate && rowToUpdate.status === 'Approved') {
            // Submits expenditure for payment
            let comparisonAttributes = {
                cc_type: rowToUpdate.cc_type,
                ccHolder_id: rowToUpdate.ccHolder_id,
                cost: rowToUpdate.cost,
                greatest_unit_cost: rowToUpdate.greatest_unit_cost,
                item_description: rowToUpdate.item_description,
                link_to_db: rowToUpdate.link_to_db,
                purchase_date: rowToUpdate.purchase_date,
                store: rowToUpdate.store,
                type: rowToUpdate.type.toLowerCase(),
                approver_id: rowToUpdate.approver.id,
                account_id: rowToUpdate.account_id,
                account_type: 'CoreBudgetType',
            };

            let equal = this.isEquivalent(attributes, comparisonAttributes);
            if (!equal) {
                delete comparisonAttributes.link_to_db;
                delete attributes.link_to_db;
            }

            equal = this.isEquivalent(attributes, comparisonAttributes);
            let changeOfApprover = rowToUpdate.approver.id !== attributes.approver_id;
            if (!equal || changeOfApprover) {
                this.submitExpenditure('updateAndSubmitApprovalExpenditure', rowToUpdate.id, attributes);
            } else {
                attributes.link_to_db = values.link_to_db;
                this.submitExpenditure('submitExpenditure', rowToUpdate.id, attributes);
            }
        } else if (rowToUpdate && rowToUpdate.status === 'Rejected') {
            // Expenditure needs to be submitted for approval after being rejected
            this.submitExpenditure('updateAndSubmitApprovalExpenditure', rowToUpdate.id, attributes);
        } else if (rowToUpdate && rowToUpdate.status === 'Returned') {
            // Updates and submit existing expenditure
            ExpendituresApi.updateAndSubmitExpenditure(rowToUpdate.id, attributes)
                .then((response) => {
                    let { expenditures } = this.state;

                    expenditures.unshift(response.data);

                    this.setState(
                        {
                            expenditures,
                            forceSetData: true,
                            rowToUpdate: null,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
        actions.resetForm();
    };

    submitExpenditure = (method, id, attributes) => {
        ExpendituresApi[method](id, attributes)
            .then((response) => {
                response.data.purchase_date = attributes.purchase_date;

                let { expenditures } = this.state;
                expenditures.unshift(response.data);
                expenditures.sort((a) => (a.status === 'submitted' ? 1 : -1));

                this.setState(
                    {
                        expenditures,
                        rowToUpdate: null,
                        forceSetData: true,
                    },
                    () => {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    };

    createExpenditure = (method, attributes) => {
        ExpendituresApi[method](attributes)
            .then((response) => {
                response.data.purchase_date = attributes.purchase_date;

                let { expenditures } = this.state;
                expenditures.unshift(response.data);
                expenditures.sort((a) => (a.status === 'submitted' ? 1 : -1));

                this.setState(
                    {
                        expenditures,
                        forceSetData: true,
                    },
                    () => {
                        this.setState({ forceSetData: false });
                    }
                );
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    };

    render() {
        const {
            rowToUpdate,
            expendituresTypeOptions,
            approverUserOptions,
            cbtOptions,
            expenditures,
            columns,
            ccHolderOptions,
            forceSetData,
        } = this.state;

        if (!expendituresTypeOptions || !cbtOptions) {
            return <div>loading...</div>;
        }

        let initialValues = null;
        if (rowToUpdate) {
            let invType;
            if (rowToUpdate.type) {
                invType = _find(expendituresTypeOptions, {
                    label: rowToUpdate.type,
                });
            }

            let invApprover;
            if (rowToUpdate.approver) {
                invApprover = _find(approverUserOptions, {
                    value: rowToUpdate.approver.id,
                });
            }

            initialValues = {
                type: invType,
                cc_type: rowToUpdate.cc_type,
                ccHolder_id: rowToUpdate.ccHolder_id,
                purchase_date: rowToUpdate.purchase_date,
                item_description: rowToUpdate.item_description,
                cost: rowToUpdate.cost,
                greatest_unit_cost: rowToUpdate.greatest_unit_cost,
                store: rowToUpdate.store,
                link_to_db: rowToUpdate.link_to_db,
                approver_id: invApprover,
                account_type: rowToUpdate.account_id,
                status: rowToUpdate.status,
            };
        } else {
            initialValues = {
                type: '',
                cc_type: '',
                ccHolder_id: '',
                purchase_date: '',
                item_description: '',
                cost: '',
                greatest_unit_cost: '',
                store: '',
                link_to_db: '',
                approver_id: '',
                account_type: '',
            };
        }

        return (
            <div>
                <PageHeader title="Expenditures and Purchases" />
                <CoreFundingSubNav />
                <ExpendituresForm
                    initialValues={initialValues}
                    expendituresTypeOptions={expendituresTypeOptions}
                    ccHolderOptions={ccHolderOptions}
                    approverUserOptions={approverUserOptions}
                    cbtOptions={cbtOptions}
                    handleSubmit={this.handleSubmit}
                    cancelEdit={this.cancelEdit}
                />
                <div className="Table-styling">
                    <EditingTable data={expenditures} columns={columns} addedNewData={forceSetData} pagingProp={true} />
                </div>
            </div>
        );
    }
}

export default ExpendituresPage;
