import React, { Component } from 'react';

import { history } from '../../store/store';

import AuthApi from '../../api/auth';
import AuthService from '../../auth/auth.service';
import UserButton from '../../components/buttons/UserButton';
import UserMessage from '../../components/messages/UserMessage';
import UserInput from '../../components/input/UserInput';

class ChangePasswordForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            isbusy: false,
            errorMessage: null,
        };

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    onChange(evt) {
        let changes = {};
        changes[evt.target.name] = evt.target.value;
        this.setState(changes);
    }

    onFormSubmit(event) {
        event.preventDefault();

        this.setState({ errorMessage: null, isbusy: true }, () => {
            const isValid = this.validateForm();
            if (!isValid) {
                this.setState({ isbusy: false });
                return;
            }
            let email = AuthService.getEmail();
            AuthApi.changePassword(email, this.state.oldPassword, this.state.newPassword)
                .then(() => {
                    this.setState({ errorMessage: null, isbusy: false });
                    history.push('/');
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage, isbusy: false });
                });
        });
    }

    validateForm() {
        const { newPassword, confirmPassword } = this.state;
        if (newPassword !== confirmPassword) {
            this.setState({ errorMessage: 'Passwords do not match.' });
            return false;
        }
        if (newPassword.length < 8) {
            this.setState({ errorMessage: 'Password must be at least 8 characters long.' });
            return false;
        }
        return true;
    }

    render() {
        return (
            <form onSubmit={this.onFormSubmit}>
                <div className="Pass-instructions">
                    <p>
                        Passwords must be a minimum of Eight Characters and contain a combination of Uppercase Letters,
                        Lowercase letters, Numbers, and Symbols
                    </p>
                </div>
                <UserInput
                    name="oldPassword"
                    label="Old Password:"
                    type="password"
                    value={this.state.oldPassword}
                    onChange={this.onChange}
                />

                <UserInput
                    name="newPassword"
                    label="New Password:"
                    type="password"
                    value={this.state.newPassword}
                    onChange={this.onChange}
                />

                <UserInput
                    name="confirmPassword"
                    label="Confirm New Password:"
                    type="password"
                    placeholder="Confirm New Password"
                    value={this.state.confirmPassword}
                    onChange={this.onChange}
                />

                <div className="Form__buttons">
                    <UserButton
                        type="submit"
                        defaultText="Change Password"
                        busyText="Changing Password"
                        isbusy={this.state.isbusy}
                    />
                </div>

                <div className="Form__footer">
                    <UserMessage error={this.state.errorMessage} />
                </div>
            </form>
        );
    }
}

export default ChangePasswordForm;
