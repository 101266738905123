import React, { useEffect, useState } from 'react';
import Table from '../components/tables/Table';
import ExportCSVButton from '../components/buttons/ExportCSVButton';
import errorToast from '../components/messages/ErrorMessage';
import ProjectsApi from '../api/projects';
import AgreementInvoicesApi from '../api/agreementInvoices';
import TravelExpenseApi from '../api/travelExpenses';
import ProjectFinancialSummaryCalculator from '../utils/ProjectFinancialSummaryCalculator';
import useFunctionAsState from '../hooks/useFunctionAsState';
import ErgoMoney from '../utils/ErgoMoney';

const FinancialSummaryPage = (props) => {
    const [summedTransactions, setSummedTransactions] = useState([]);
    const [exportDataAsCsv, setExportDataAsCsv] = useFunctionAsState(() => {});

    const columns = [
        {
            headerName: 'Transaction Date',
            field: 'transaction_date',
            type: 'date',
            cellRenderer: (params) => {
                if (params.data.artifact_type === 'AgreementInvoice') {
                    const date = new Date(params.data.created_on);
                    const formattedDate = `${date.toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                    })}`;
                    return formattedDate;
                }

                return params.data.transaction_date;
            },
        },
        {
            headerName: 'Recorded Date',
            field: 'updated_on',
            type: 'date',
        },
        {
            headerName: 'Type',
            field: 'type',
            cellRenderer: (params) => {
                if (params.data.account_id && params.source_type === 'Project') {
                    return `<a href='/projects/${params.data.account_id}'>Project ${params.data.type}</a>`;
                }
                return params.data.type;
            },
            type: 'mediumshorttext',
        },
        {
            headerName: 'Description',
            field: 'description',
            type: 'mediumshorttext',
        },
        {
            headerName: 'Status',
            field: 'status',
            type: 'status',
        },
        {
            headerName: 'Funds Received',
            valueGetter: (params) => {
                let amount = new ErgoMoney(params.data.amount);
                let destinationId = params.data.destination_id;
                if (destinationId === props.match.params.id && amount.greaterThan(0)) {
                    return amount.getNumberAmount();
                }
                return null;
            },
            type: 'shortmoney',
        },
        {
            headerName: 'Expenditures and Transfers Out',
            valueGetter: (params) => {
                let amount;
                let sourceId;
                let destId;

                if (params.data.type && params.data.type.includes('AgreementInvoice')) {
                    amount = new ErgoMoney(params.data.transaction.amount);
                    if (amount.greaterThan(0)) {
                        return amount.multiply(-1).getNumberAmount();
                    }
                    if (amount.lessThan(0)) {
                        return amount.getNumberAmount();
                    }
                } else {
                    amount = new ErgoMoney(params.data.amount);
                    sourceId = params.data.source_id;
                    destId = params.data.destination_id;
                    if (sourceId === props.match.params.id && amount.greaterThan(0)) {
                        return amount.multiply(-1).getNumberAmount();
                    }
                    if (
                        sourceId !== props.match.params.id &&
                        amount.greaterThan(0) &&
                        destId === props.match.params.id
                    ) {
                        return null;
                    }
                    return amount.multiply(-1).getNumberAmount();
                }
            },
            type: 'money',
        },
        {
            headerName: 'Expenditures + G&A',
            valueGetter: (params) => {
                let amountPlusGA;
                let sourceId;

                if (params.data.type.includes('Transfer')) {
                    return null;
                }

                if (params.data.type && params.data.type.includes('AgreementInvoice')) {
                    amountPlusGA = new ErgoMoney(params.data.amount_plus_g_a);
                    if (amountPlusGA.greaterThan(0)) {
                        return -amountPlusGA.getNumberAmount();
                    }
                    return amountPlusGA.multiply(-1).getStringAmount();
                }
                amountPlusGA = new ErgoMoney(params.data.amount_plus_g_a);
                sourceId = params.data.source_id;
                if (sourceId === props.match.params.id && amountPlusGA.greaterThan(0)) {
                    return amountPlusGA.multiply(-1).getStringAmount();
                }
                return amountPlusGA.multiply(-1).getStringAmount();
            },
            type: 'money',
        },
        {
            headerName: 'Remaining Funds',
            valueGetter: (params) => params.data.remaining_funds,
            type: 'money',
        },
    ];

    useEffect(() => {
        try {
            const projectId = props.match.params.id;
            const fetchData = async () => {
                let { data: transactions } = await ProjectsApi.fetchProjectTransactions(projectId);
                const { data: invoices } = await AgreementInvoicesApi.fetchInvoicesByProjectID(projectId);
                const { data: travelExpenses } = await TravelExpenseApi.getTravelExpenses('Project-' + projectId);
                const financialSummaryTransactions = new ProjectFinancialSummaryCalculator(
                    projectId,
                    [...transactions],
                    [...invoices],
                    [...travelExpenses]
                ).runCalculator();
                setSummedTransactions(financialSummaryTransactions);
            };
            fetchData();
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }, [props.match.params.id]);

    const colorRowsByStatus = (params) => {
        let rowStatus = params.data.status;
        if (rowStatus === 'Returned to Sender') {
            return {
                background: 'rgba(255, 0, 0, 0.3)',
            };
        }
        if (rowStatus.includes('Pending')) {
            return {
                background: 'rgba(240, 255, 0, 0.3)',
            };
        }
    };

    const onTableReady = (params) => {
        if (summedTransactions > 0) {
            params.api.ensureIndexVisible(summedTransactions.length - 1);
        }

        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            setExportDataAsCsv(params.exportDataAsCsv);
        }
    };

    return (
        <>
            <div className="Table-styling">
                <Table
                    data={summedTransactions}
                    sortable={true}
                    filter={true}
                    columns={columns}
                    sizeToFit={true}
                    onTableReady={onTableReady}
                    getRowStyle={colorRowsByStatus}
                />
            </div>
            <div className="Form__buttons Floating-button">
                <ExportCSVButton fileName="financial-summary" exportFunction={exportDataAsCsv} />
            </div>
        </>
    );
};

export default FinancialSummaryPage;
