import React from 'react';

import Fieldset from '../Fieldset';
import Table from '../../components/tables/Table';
import { confirm } from '../../components/modals/ConfirmModal';
import errorToast from '../../components/messages/ErrorMessage';

import ProjectsApi from '../../api/projects';

class VersionHistorySection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
        };

        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.addRow = this.addRow.bind(this);
        this.saveNewVersion = this.saveNewVersion.bind(this);
        this.handleDownloadLink = this.handleDownloadLink.bind(this);
        this.handleCreateNewPd = this.handleCreateNewPd.bind(this);
    }

    handleVersions(versions) {
        this.props.handleVersions(versions);
    }

    async handleDownloadLink(syntheticEvent) {
        const { projectId, project } = this.props;
        const versionId = syntheticEvent.data.id;
        try {
            await ProjectsApi.generateVersionDescription(
                projectId,
                versionId,
                project.number,
                syntheticEvent.data.number
            );
        } catch (error) {
            errorToast(error, 'Error generating version description');
        }
    }

    async handleCreateNewPd() {
        let text = (
            <div>
                <p>
                    This action will clear all fields in the Project Data Page that appears in the Project Description
                    Document{' '}
                </p>{' '}
                <p>
                    <b>Are you sure you want to proceed?</b>
                </p>
            </div>
        );

        const confirmed = await confirm({
            text,
        });

        if (confirmed) {
            this.props.handleCreateNewPd();
        }
    }

    onRemoveSelected(evt) {
        const { data } = evt;
        ProjectsApi.removeProjectVersion(data.project_id, data.id).then(() => {
            const versions = this.props.versions.filter((v) => v.id !== data.id);
            this.handleVersions(versions);
        });
    }

    getVersionNumber(previousVersions) {
        return previousVersions.length > 0 ? Math.max(...previousVersions) + 1 : 1;
    }

    saveNewVersion() {
        const { versions } = this.props;
        const versionNumbers = versions.map((v) => v.number);
        const newVersionNumber = this.getVersionNumber(versionNumbers);
        this.addRow(newVersionNumber.toString());
    }

    addRow(versionNumber) {
        const { projectId, project } = this.props;
        if (projectId !== 'new') {
            const attributes = {
                number: versionNumber,
                version_type: 'Final PD',
                project_description: JSON.stringify({
                    background: project.background,
                    applicable_cpo_paragraphs: project.applicable_cpo_paragraphs,
                    key_goals: project.key_goals,
                    tasks: project.tasks,
                    deliverables: project.deliverables,
                    milestones: project.milestones,
                    funding_plan: project.funding_plan,
                    initial_budget: project.initial_budget,
                    wbi_roles: project.wbi_roles,
                    customer_roles: project.customer_roles,
                    other_roles: project.other_roles,
                    information_security: project.information_security,
                    customer_value_proposition: project.customer_value_proposition,
                }),
            };
            ProjectsApi.createProjectVersion(projectId, attributes)
                .then((response) => {
                    const { data } = response;

                    const versions = this.props.versions.map((version) => {
                        version.version_type = 'Version';
                        return version;
                    });
                    const newVersion = {
                        number: versionNumber,
                        version_type: 'Final PD',
                        created_on: Date(data.created_on),
                        id: data.id,
                        project_id: data.project_id,
                        project_description: {
                            background: project.background,
                            applicable_cpo_paragraphs: project.applicable_cpo_paragraphs,
                            key_goals: project.key_goals,
                            tasks: project.tasks,
                            deliverables: project.deliverables,
                            milestones: project.milestones,
                            funding_plan: project.funding_plan,
                            initial_budget: project.initial_budget,
                            wbi_roles: project.wbi_roles,
                            customer_roles: project.customer_roles,
                            other_roles: project.other_roles,
                            information_security: project.information_security,
                            customer_value_proposition: project.customer_value_proposition,
                        },
                    };
                    versions.forEach((version) => {
                        const versionObj = {
                            number: version.number,
                            version_type: version.version_type,
                        };
                        ProjectsApi.updateProjectVersion(projectId, version.id, versionObj);
                    });
                    versions.push(newVersion);
                    this.handleVersions(versions);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        const { versions, isEditing } = this.props;

        return (
            <Fieldset title="Project Description Version History">
                <div className="Table-styling column-span-3">
                    <Table
                        data={versions}
                        columns={[
                            {
                                headerName: 'Version',
                                field: 'number',
                                sort: 'desc',
                                type: 'number',
                            },
                            {
                                headerName: 'Date',
                                field: 'created_on',
                                type: 'date',
                            },
                            {
                                headerName: 'Type',
                                field: 'version_type',
                                type: 'type',
                            },
                            {
                                headerName: 'Download',
                                cellRenderer: 'DownloadLinkRenderer',
                                onCellClicked: this.handleDownloadLink,
                            },
                            {
                                headerName: 'Delete',
                                cellRenderer: 'TrashSymbolRenderer',
                                type: 'symbol',
                                onCellClicked: this.onRemoveSelected,
                            },
                        ]}
                        sizeToFit={true}
                    />
                </div>
                {!isEditing && (
                    <div className="Form__buttons--Reversed end-buttons_format " style={{ marginBottom: 10 }}>
                        <button type="button" onClick={() => window.print()}>
                            Print Project Data
                        </button>
                        <button type="button" onClick={this.saveNewVersion}>
                            Save New Version of Project Description
                        </button>
                        <button type="button" onClick={this.handleCreateNewPd}>
                            Clear All Project Description Data
                        </button>
                    </div>
                )}
            </Fieldset>
        );
    }
}

export default VersionHistorySection;
