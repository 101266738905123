import React from 'react';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import EditingTable from './EditingTable';
import ProjectsApi from '../../api/projects';

function sortByDate(rd) {
    rd.sort((a, b) => {
        let first;
        let second;
        if (a.event_type === 'deliverable') {
            first = new Date(a.due_date);
            second = new Date(b.due_date);
        } else {
            first = new Date(a.from_date);
            second = new Date(b.from_date);
        }
        return second.getTime() < first.getTime();
    });
    return rd;
}

class GanttChart extends React.Component {
    constructor(props) {
        super(props);
        this.getDeliverableRowData = this.getDeliverableRowData.bind(this);
        this.onRemoveDeliverable = this.onRemoveDeliverable.bind(this);
        this.getMilestoneRowData = this.getMilestoneRowData.bind(this);
        this.onRemoveMilestone = this.onRemoveMilestone.bind(this);

        this.state = {
            forceSetData: false,
            milestones: [],
            deliverables: [],
            chartData: [],
            startDate: moment().startOf('day'),
            timeframe: null,
            rowDeliverable: null,
            rowIndexDeliverable: null,
            colKeyDeliverable: '',
            rowMilestone: null,
            rowIndexMilestone: null,
            colKeyMilestone: '',
            rowSelection: 'single',
            chartRow: [
                { type: 'string', label: 'Task ID' },
                { type: 'string', label: 'Task Name' },
                { type: 'string', label: 'Type' },
                { type: 'date', label: 'Start Date' },
                { type: 'date', label: 'End Date' },
                { type: 'number', label: 'Duration' },
                { type: 'number', label: 'Percent Complete' },
                { type: 'string', label: 'Dependencies' },
            ],
        };
    }

    async componentDidMount() {
        let projectId = this.props.projectId;
        this.updateSchedule(projectId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataUpdated !== this.props.dataUpdated) {
            this.updateSchedule(this.props.projectId);
        }
    }

    onRemoveDeliverable(evt) {
        return ProjectsApi.removeDeliverable(this.props.projectId, evt.data.id).then(() => {
            this.updateSchedule(this.props.projectId);
        });
    }

    onDeliverableChanged(evt) {
        const { data } = evt;
        if (data.completed === 'Yes' || data.completed === 'No') {
            data.completed = data.completed === 'Yes';
        }
        const deliverableObj = {
            due_date: data.due_date,
            description: data.description,
            event_type: 'deliverable',
            completed: data.completed,
        };
        return ProjectsApi.updateDeliverable(this.props.projectId, data.id, deliverableObj).then(() => {
            this.updateSchedule(data.project_id);
        });
    }

    onRemoveMilestone(evt) {
        return ProjectsApi.removeMilestone(this.props.projectId, evt.data.id).then(() => {
            this.updateSchedule(this.props.projectId);
        });
    }

    onMilestoneChanged(evt) {
        const { data } = evt;
        if (data.completed === 'Yes' || data.completed === 'No') {
            data.completed = data.completed === 'Yes';
        }
        const milestoneObj = {
            from_date: data.from_date,
            to_date: data.to_date,
            description: data.description,
            event_type: 'milestone',
            completed: data.completed,
        };
        return ProjectsApi.updateMilestone(this.props.projectId, data.id, milestoneObj).then(() => {
            this.updateSchedule(data.project_id);
        });
    }

    getMilestoneRowData(evt) {
        this.setState({
            rowDeliverable: null,
            rowIndexDeliverable: null,
            colKeyDeliverable: '',
            rowMilestone: evt.node.data,
            rowIndexMilestone: evt.node.rowIndex,
            colKeyMilestone: 'edit',
        });
    }

    getDeliverableRowData(evt) {
        this.setState({
            rowDeliverable: evt.node.data,
            rowIndexDeliverable: evt.node.rowIndex,
            colKeyDeliverable: 'edit',
            rowMilestone: null,
            rowIndexMilestone: null,
            colKeyMilestone: '',
        });
    }

    async updateSchedule(projectId) {
        let rd = [];
        try {
            const { data: milestones } = await ProjectsApi.fetchMilestones(projectId);
            const { data: deliverables } = await ProjectsApi.fetchDeliverables(projectId);
            let timeframe = this.props.timeframe;

            if (milestones) {
                milestones.sort((a, b) => new Date(a.to_date) - new Date(b.to_date));
            }

            if (deliverables) {
                deliverables.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
            }

            this.setState(
                {
                    forceSetData: true,
                    milestones,
                    deliverables,
                    timeframe,
                },
                () => {
                    this.setState({ forceSetData: false });
                }
            );

            let chart = [];
            let rangeStart = moment(timeframe[0]).startOf('day').toDate();
            let rangeEnd = moment(timeframe[1]).startOf('day').toDate();
            // column names is first item in array
            chart.push(this.state.chartRow);
            if (deliverables && deliverables.length > 0) {
                deliverables.forEach((d) => {
                    let startofDay = moment(d.due_date).startOf('day').toDate();
                    let endofDay = moment(d.due_date).endOf('day').toDate();
                    if (startofDay >= rangeStart && endofDay <= rangeEnd) {
                        chart.push([d.id, d.description, 'deliverable', startofDay, endofDay, null, 0, null]);
                    }
                });
            }
            if (milestones && milestones.length > 0) {
                milestones.forEach((m) => {
                    let startofDay = moment(m.from_date).startOf('day').toDate();
                    let endofDay = moment(m.to_date).endOf('day').toDate();
                    if (startofDay >= rangeStart && startofDay <= rangeEnd) {
                        chart.push([m.id, m.description, 'milestone', startofDay, endofDay, null, 0, null]);
                    }
                });
            }
            let rowData = sortByDate(rd);
            this.setState({
                rowData,
                chartData: chart,
                rowDeliverable: null,
                rowIndexDeliverable: null,
                colKeyDeliverable: '',
                rowMilestone: null,
                rowIndexMilestone: null,
                colKeyMilestone: '',
            });
        } catch (errorMessage) {
            console.log(errorMessage);
        }
    }

    render() {
        const {
            forceSetData,
            deliverables,
            milestones,
            chartData,
            rowIndexDeliverable,
            colKeyDeliverable,
            rowIndexMilestone,
            colKeyMilestone,
        } = this.state;

        return (
            <div className="Chart-output">
                {chartData.length > 1 ? (
                    <div>
                        <div className="row">
                            <div className="column-flex-50">
                                <EditingTable
                                    data={milestones}
                                    columns={[
                                        {
                                            headerName: 'Milestones',
                                            field: 'description',
                                            editable: true,
                                            cellClass: 'Cell-wrap-text',
                                            autoHeight: true,
                                            maxWidth: 600,
                                        },
                                        {
                                            headerName: 'From',
                                            field: 'from_date',
                                            type: 'date',
                                            editable: true,
                                        },
                                        {
                                            headerName: 'To',
                                            field: 'to_date',
                                            type: 'date',
                                            editable: true,
                                        },
                                        {
                                            headerName: 'Complete',
                                            field: 'completed',
                                            type: 'shortnumber',
                                            cellRenderer: 'BooleanRenderer',
                                            editable: true,
                                            cellEditor: 'agSelectCellEditor',
                                            cellEditorParams: {
                                                values: ['No', 'Yes'],
                                            },
                                        },
                                        {
                                            headerName: 'Edit',
                                            field: 'edit',
                                            maxWidth: 65,
                                            editable: false,
                                            cellRenderer: 'EditSymbolRenderer',
                                            onCellClicked: this.getMilestoneRowData,
                                        },
                                        {
                                            headerName: 'Del.',
                                            field: 'delete',
                                            maxWidth: 65,
                                            width: 65,
                                            editable: false,
                                            cellRenderer: 'TrashSymbolRenderer',
                                            onCellClicked: this.onRemoveMilestone,
                                        },
                                    ]}
                                    addedNewData={forceSetData}
                                    rowIndex={rowIndexMilestone}
                                    colKey={colKeyMilestone}
                                    rowValueChanged={this.onMilestoneChanged.bind(this)}
                                    sizeToFit={true}
                                />
                            </div>
                            <div className="column-flex-50">
                                <EditingTable
                                    data={deliverables}
                                    columns={[
                                        {
                                            headerName: 'Deliverables',
                                            field: 'description',
                                            editable: true,
                                            cellClass: 'Cell-wrap-text',
                                            autoHeight: true,
                                            maxWidth: 600,
                                        },
                                        {
                                            headerName: 'Due Date',
                                            field: 'due_date',
                                            type: 'date',
                                            editable: true,
                                        },
                                        {
                                            headerName: 'Complete',
                                            field: 'completed',
                                            type: 'shortnumber',
                                            cellRenderer: 'BooleanRenderer',
                                            editable: true,
                                            cellEditor: 'agSelectCellEditor',
                                            cellEditorParams: {
                                                values: ['No', 'Yes'],
                                            },
                                        },
                                        {
                                            headerName: 'Edit',
                                            field: 'edit',
                                            maxWidth: 65,
                                            editable: false,
                                            cellRenderer: 'EditSymbolRenderer',
                                            onCellClicked: this.getDeliverableRowData,
                                        },
                                        {
                                            headerName: 'Del.',
                                            field: 'delete',
                                            maxWidth: 65,
                                            width: 65,
                                            editable: false,
                                            cellRenderer: 'TrashSymbolRenderer',
                                            onCellClicked: this.onRemoveDeliverable,
                                        },
                                    ]}
                                    addedNewData={forceSetData}
                                    rowIndex={rowIndexDeliverable}
                                    colKey={colKeyDeliverable}
                                    rowValueChanged={this.onDeliverableChanged.bind(this)}
                                    sizeToFit={true}
                                />
                            </div>
                        </div>
                        <div className="Chart-output__Gantt-chart">
                            <Chart
                                width="100%"
                                height={1000}
                                chartType="Gantt"
                                loader={<div>Loading Chart</div>}
                                data={chartData}
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <h4>There is currently no data to show</h4>
                    </div>
                )}
            </div>
        );
    }
}

export default GanttChart;
