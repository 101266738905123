// Libraries
import moment from 'moment';

export const roundToTwoDecimals = (value) => Number(Math.round(value + 'e2') + 'e-2');

export const shortDate = (date) => moment(date).format('D-MMM-YY');
export const shortTime = (date) => moment(date).format('D MMM YY @ HH:mm');

export const quartersForThisYear = () => {
    const yearCode = moment().format('YY');
    return [`1QFY${yearCode}`, `2QFY${yearCode}`, `3QFY${yearCode}`, `4QFY${yearCode}`];
};

export const phoneFormat = (value) => {
    let phone;
    if (value === undefined) {
        phone = '';
    } else if (value !== '') {
        let n = value.split('');
        if (n[0] === '+') {
            phone = value;
        } else if (n[0] !== '(') {
            phone = `(${n[0]}${n[1]}${n[2]})${n[3]}${n[4]}${n[5]}-${n[6]}${n[7]}${n[8]}${n[9]}`;
        } else {
            phone = value;
        }
    } else {
        phone = '';
    }
    return phone;
};

export const capitalizeWord = (value) => value.charAt(0).toUpperCase() + value.slice(1);

export const isEquivalent = (a, b) => {
    let aProps = Object.getOwnPropertyNames(a);
    let bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
};
