import api from './api';

import endpoints from './endpoints';

const notifRoute = endpoints.NOTIFICATIONS;

export default {
    getNotifications() {
        return api.get(notifRoute, 'Error getting notitications');
    },

    deleteNotifications(id) {
        return api.delete(`${notifRoute}/${id}`, 'Error deleting notification');
    },

    markAsViewed(id) {
        return api.put(`${notifRoute}/${id}/viewed`, 'Error viewing notification');
    },
};
