import api from './api';
import endpoints from './endpoints';

const travelExpenseRoute = endpoints.TRAVEL_EXPENSE;
const travelExpenditureRoute = endpoints.TRAVEL_EXPENDITURES;

export default {
    createTravelExpense(data) {
        return api.post(travelExpenseRoute, data, 'Error creating travel expense');
    },

    getTravelExpenses(id) {
        return api.get(`${travelExpenseRoute}/${id}`, 'Error getting travel expenses');
    },

    updateTravelExpense(id, data) {
        return api.put(`${travelExpenseRoute}/${id}`, data, 'Error updating travel expense');
    },

    deleteTravelExpense(id) {
        return api.delete(`${travelExpenseRoute}/${id}`, 'Error deleting travel expense');
    },

    createTravelExpenditure(data) {
        return api.post(travelExpenditureRoute, data, 'Error creating travel expenditure');
    },

    updateTravelExpenditure(id, data) {
        return api.put(`${travelExpenditureRoute}/${id}`, data, 'Error updating travel expenditure');
    },

    deleteTravelExpenditure(id) {
        return api.delete(`${travelExpenditureRoute}/${id}`, 'Error deleting travel expenditure');
    },

    createTravelExpenseDraft(data) {
        return api.post(`${travelExpenseRoute}/draft`, data, 'Error saving draft');
    },

    approveTravelExpense(id) {
        return api.put(`${travelExpenseRoute}/approve/${id}`, 'Error approving travel expense');
    },

    returnTravelExpense(id) {
        return api.put(`${travelExpenseRoute}/return/${id}`, 'Error returning travel expense');
    },

    payTravelExpense(id) {
        return api.put(`${travelExpenseRoute}/pay/${id}`, 'Error paying travel expense');
    },
};
