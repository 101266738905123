import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../components/heading/PageHeader';
import ResultListItem from './ResultListItem';
import SearchResults from '../api/search';

import { history } from '../store/store';
import errorToast from '../components/messages/ErrorMessage';

class SearchResultsPage extends Component {
    constructor() {
        super();
        this.state = {
            results: null,
            term: history.location.state.term,
        };
    }

    componentDidMount = async () => {
        try {
            const { data: results } = await SearchResults.fetchResults(history.location.state.term);
            results.forEach((result) => {
                result.users.forEach((user) => {
                    if (result.wbi_lead_user_id === user.id) {
                        result.wbi_contact = user.name;
                    }
                });
            });
            this.setState({
                results,
                term: history.location.state.term,
            });
        } catch (error) {
            errorToast(error);
        }
    };

    render() {
        let resultsList;
        if (!this.state.results) return <div>loading...</div>;

        const { term } = this.state;
        if (term !== history.location.state.term) {
            this.componentDidMount();
        }

        if (this.state.results) {
            if (this.state.results.length > 0) {
                resultsList = (
                    <div className="Wide-single-layout">
                        {' '}
                        {this.state.results.map((result) => (
                            <Link to={`/projects/${result.id}`} key={result.id}>
                                <ResultListItem result={result}> </ResultListItem>
                            </Link>
                        ))}
                    </div>
                );
            } else {
                resultsList = (
                    <div className="Wide-single-layout">
                        <h6>No Results Found.</h6>{' '}
                    </div>
                );
            }
        }
        return (
            <div>
                <PageHeader title="Search Results" />
                {resultsList}
            </div>
        );
    }
}

export default SearchResultsPage;
