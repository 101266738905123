// React
import React from 'react';

// Components
import MyProjects from './MyProjects';
import MyActionItems from './MyActionItems';
import OpenNotifications from '../components/notifications/OpenNotifications';
import PageHeader from '../components/heading/PageHeader';
import GridSection from '../components/section/GridSection';

const DefaultPage = (props) => {
    let mainTitle;
    let myProjectsTitle;
    if (props.title && props.myProjectsTitle) {
        mainTitle = props.title;
        myProjectsTitle = props.myProjectsTitle;
    } else if (props.title) {
        mainTitle = props.title;
        myProjectsTitle = 'My Projects';
    } else {
        mainTitle = 'Home';
    }
    return (
        <div>
            <PageHeader title={mainTitle} />
            <div className="Homepage">
                <GridSection title={myProjectsTitle} area="projects">
                    <MyProjects />
                </GridSection>
                <GridSection title="My Action Items" area="actions">
                    <MyActionItems />
                </GridSection>
                <GridSection title="Open Notifications" area="notifications">
                    <OpenNotifications isFinance={false} />
                </GridSection>
            </div>
        </div>
    );
};

export default DefaultPage;
