import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';
import ResidualFundsApi from '../api/residualFunds';
import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';
import errorToast from '../components/messages/ErrorMessage';

class ResidualFundsPage extends Component {
    constructor(props) {
        super(props);

        this.getRowData = this.getRowData.bind(this);
        this.removeFund = this.removeFund.bind(this);
        this.onRowValueChanged = this.onRowValueChanged.bind(this);

        this.state = {
            residualFunds: [],
            row: null,
            rowIndex: null,
            colKey: null,
            forceSetData: false,
            columns: [
                {
                    headerName: 'Residual Fund',
                    field: 'name',
                    editable: true,
                    sort: 'desc',
                },
                {
                    headerName: 'Edit',
                    field: 'edit',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'EditSymbolRenderer',
                    onCellClicked: this.getRowData,
                },
                {
                    headerName: 'Del.',
                    field: 'delete',
                    type: 'symbol',
                    editable: false,
                    cellRenderer: 'TrashSymbolRenderer',
                    onCellClicked: this.removeFund,
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: residualFunds } = await ResidualFundsApi.getResidualFunds();
            this.setState({ residualFunds });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRowValueChanged(event) {
        let residualFundToUpdate = event.node.data;

        if (residualFundToUpdate) {
            ResidualFundsApi.updateResidualFund(residualFundToUpdate.id, {
                name: residualFundToUpdate.name,
            }).catch((errorMessage) => {
                this.setState({ errorMessage });
            });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'name',
        });
    }

    handleSubmit = (values, actions) => {
        if (values) {
            ResidualFundsApi.createResidualFund({
                name: values.name,
                order: values.order,
            })
                .then((response) => {
                    let newResidualFund = this.state.residualFunds;
                    newResidualFund.push(response.data);

                    this.setState(
                        {
                            residualFunds: newResidualFund,
                            forceSetData: true,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
        actions.resetForm();
    };

    removeFund(event) {
        let fundToBeRemove = event.node.data;
        if (fundToBeRemove) {
            ResidualFundsApi.removeResidualFund(fundToBeRemove.id)
                .then((response) => {
                    if (response.status === 200) {
                        let residualFunds = this.state.residualFunds;
                        let nRF = residualFunds.filter((item) => fundToBeRemove.id !== item.id);
                        this.setState(
                            {
                                residualFunds: nRF,
                                forceSetData: true,
                            },
                            function () {
                                this.setState({ forceSetData: false });
                            }
                        );
                    }
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    render() {
        return (
            <div>
                <PageHeader title="Residual Funds" />
                <AdminSubNav />
                <div>
                    <div className="Single-layout Companion-form">
                        <Formik
                            initialValues={{
                                name: '',
                            }}
                            validationSchema={yup.object().shape({
                                order: yup.number(),
                                name: yup.string().required(),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <LabeledField
                                        label="Residual Fund*"
                                        name="name"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <div className="Form__buttons--Reversed Buttons_format">
                                        <button type="submit">Add Residual Fund</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="Table-styling">
                        <EditingTable
                            columns={this.state.columns}
                            data={this.state.residualFunds}
                            rowIndex={this.state.rowIndex}
                            colKey={this.state.colKey}
                            rowValueChanged={this.onRowValueChanged}
                            sizeToFit={true}
                            addedNewData={this.state.forceSetData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ResidualFundsPage;
