import React, { useEffect, useState } from 'react';
import ProjectsApi from '../api/projects';
import HelpModal from '../components/modals/HelpModal';
import ErgoMoney from '../utils/ErgoMoney';

const FinancialSummaryHelpModal = () => (
    <HelpModal>
        <table className="Table-info">
            <thead>
                <tr>
                    <th width="240px">&nbsp;</th>
                    <th>Definition</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="text-align-right bold">Total Received:</td>
                    <td>Total amount received via PIA mods and others sources.</td>
                </tr>
                <tr>
                    <td className="text-align-right bold">Total Available to Project:</td>
                    <td>Amount available to project manager to accomplish the effort.</td>
                </tr>
                <tr>
                    <td className="text-align-right bold">Total Budget:</td>
                    <td>
                        The projected amount required to complete the project; it includes all planned expenses and all
                        transfers out of the project.
                    </td>
                </tr>
                <tr>
                    <td className="text-align-right bold">Total Expended:</td>
                    <td>
                        The sum of all the invoices paid, credit card charges, travel vouchers, transfers out of the
                        project, etc.
                    </td>
                </tr>
                <tr>
                    <td className="text-align-right bold">Remaining Budget:</td>
                    <td>Amount of the budgeted funds that have not been expended.</td>
                </tr>
                <tr>
                    <td className="text-align-right bold">Unbudgeted:</td>
                    <td>Amount of funds not allocated against a specific planned expense.</td>
                </tr>
                <tr>
                    <td className="text-align-right bold">Remaining Funds:</td>
                    <td>Amount of funds remaining in the bank bookmarked against the project.</td>
                </tr>
            </tbody>
        </table>
    </HelpModal>
);

const FinancialSummary = (props) => {
    const financialInfo = props.financialInfo;
    return (
        <table className="data">
            <tbody>
                <tr>
                    <td>Total Received:</td>
                    <td>
                        <div className="Decimal_aligned">
                            {new ErgoMoney(financialInfo.total_received).getStringAmount()}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Total Available to Project:</td>
                    <td>
                        <div className="Decimal_aligned">
                            {new ErgoMoney(financialInfo.total_available).getStringAmount()}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Total Budget:</td>
                    <td>
                        <div className="Decimal_aligned">
                            {new ErgoMoney(financialInfo.project_budget).getStringAmount()}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Total Expended:</td>
                    <td>
                        <div className="Decimal_aligned">
                            {new ErgoMoney(financialInfo.total_expended).getStringAmount()}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Remaining Budget:</td>
                    <td>
                        <div className="Decimal_aligned">
                            {new ErgoMoney(financialInfo.remaining_budget).getStringAmount()}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Unbudgeted:</td>
                    <td>
                        <div className="Decimal_aligned">
                            {new ErgoMoney(financialInfo.unbudgeted).getStringAmount()}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Remaining Funds:</td>
                    <td>
                        <div className="Decimal_aligned">
                            {new ErgoMoney(financialInfo.remaining_funds).getStringAmount()}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const ProjectFinancialInfo = (props) => {
    const [financialInfo, setFinancialInfo] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let projectId = props.projectId;

            const { data: financial_information } = await ProjectsApi.fetchProjectFinancialInformation(projectId);

            setFinancialInfo(financial_information);
        };
        fetchData();
    }, [props.projectId]);

    if (!financialInfo) {
        return (
            <div>
                <div className="flex" style={{ marginTop: 16, marginBottom: 8 }}>
                    <div className="bold flex-1">Loading financial summary...</div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="flex" style={{ marginTop: 16, marginBottom: 8 }}>
                <div className="bold flex-1">Financial Information</div>
                <FinancialSummaryHelpModal />
            </div>
            <FinancialSummary financialInfo={financialInfo} />
        </div>
    );
};

export default ProjectFinancialInfo;
