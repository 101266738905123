import React, { Component } from 'react';
import _find from 'lodash/find';
import ProjectsApi from '../../api/projects';
import PagingTable from '../../components/tables/PagingTable';
import errorToast from '../../components/messages/ErrorMessage';

class SourceFundsTable extends Component {
    constructor(props) {
        super(props);
        this.updateTransactions = this.updateTransactions.bind(this);

        this.state = {
            projects: null,
            columns: [
                {
                    headerName: 'date',
                    field: 'created_on',
                    type: 'date',
                    sort: 'desc',
                },
                {
                    headerName: 'Type',
                    field: 'type',
                },
                {
                    headerName: 'WBI Project Name',
                    field: 'project_name',
                    cellRenderer: 'LinkRenderer',
                    cellRendererParams: {
                        routeBase: '/projects/',
                        routeKeyField: 'project_id',
                    },
                },
                {
                    headerName: 'Source',
                    field: 'source.name',
                },
                {
                    headerName: 'Destination',
                    field: 'destination.name',
                },

                {
                    headerName: 'Amount',
                    field: 'amount',
                    type: 'money',
                },
                {
                    headerName: 'Status',
                    field: 'status',
                },
            ],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: projects } = await ProjectsApi.fetchProjects();
            this.setState({
                projects,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    updateTransactions(projects) {
        let transactions = this.props.data;

        transactions.forEach((transaction) => {
            if (transaction.destination_type === 'Project') {
                transaction.type = 'Transfer Out';
                transaction.project_id = transaction.destination_id;
                let project = _find(projects, { id: transaction.destination_id });
                transaction.project_name = project.name;
                transaction.project_number = project.number;
            } else {
                transaction.type = 'Transfer In';
                transaction.project_id = transaction.source_id;
                let project = _find(projects, { id: transaction.source_id });
                transaction.project_name = project.name;
                transaction.project_number = project.number;
            }
        });

        return transactions;
    }

    render() {
        let transactions;
        if (this.state.projects) transactions = this.updateTransactions(this.state.projects);
        return (
            <div className="Table-styling">
                <PagingTable columns={this.state.columns} data={transactions} animateRows={true} />
            </div>
        );
    }
}

export default SourceFundsTable;
