import api from './api';

import endpoints from './endpoints';

const transactionsRoute = endpoints.TRANSACTIONS;
// const transactionsAllRoute = endpoints.TRANSACTIONS_ALL;

export default {
    createTransaction(transactionAtts) {
        return api.post(transactionsRoute, transactionAtts, 'Error creating a transaction');
    },

    getTransactionsByApprover() {
        return api.get(transactionsRoute, 'Error getting expenditures concerning the logged user');
    },

    approveTransaction(id, attributes) {
        return api.put(`${transactionsRoute}/${id}/approve`, attributes, 'Error approving transaction');
    },

    applyTransaction(id) {
        return api.put(`${transactionsRoute}/${id}/apply`, 'Error applying transaction');
    },

    rejectTransaction(id) {
        return api.put(`${transactionsRoute}/${id}/reject`, 'Error rejecting transaction');
    },
};
