import api from './api';

import endpoints from './endpoints';

export default {
    fetchStatements() {
        return api.get(endpoints.BANK_STATEMENTS, 'Error getting list of bank statements');
    },

    fetchStatementItems(bank_statement_id) {
        return api.get(
            endpoints.BANK_STATEMENTS + '/' + bank_statement_id + '/items',
            'Error getting list of statement items'
        );
    },

    fetchAllStatementItems() {
        return api.get(endpoints.BANK_STATEMENTS + '/items', 'Error getting list of statement items');
    },

    importStatement(statement) {
        return api.post_form_data(endpoints.BANK_STATEMENTS + '/import', statement, 'Error importing a bank statement');
    },

    updateStatement(bank_statement_id, changes) {
        return api.put(endpoints.BANK_STATEMENTS + '/' + bank_statement_id, changes, 'Error updating bank statement');
    },

    deleteStatement(bank_statement_id) {
        return api.delete(endpoints.BANK_STATEMENTS + '/' + bank_statement_id, 'Error deleting bank statement');
    },

    updateStatementItem(bank_statement_item_id, changes) {
        return api.put(
            endpoints.BANK_STATEMENTS + '/items/' + bank_statement_item_id,
            changes,
            'Error updating bank statement item'
        );
    },

    applyStatementItem(bank_statement_id, item_id) {
        return api.put(
            endpoints.BANK_STATEMENTS + '/' + bank_statement_id + '/items/' + item_id + '/apply',
            'Error applying statement item'
        );
    },

    requestReceipt(item_id) {
        return api.post(endpoints.BANK_STATEMENTS + '/items/request_receipt/' + item_id, 'Error requesting receipts');
    },
};
