import React, { Component } from 'react';
import errorToast from '../messages/ErrorMessage';
import statementsApi from '../../api/bankStatements';

export default class ItemStatusEditorRenderer extends Component {
    statusEnumVals = {
        'Not Reconciled': 'NOT_RECONCILED',
        Review: 'REVIEW',
        Reconciled: 'RECONCILED',
        'G&A': 'G_AND_A',
        Core: 'CORE',
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = {
            value: this.props.value,
            selectOptions: [
                { label: 'Not Reconciled', value: 'Not Reconciled' },
                { label: 'Review', value: 'Review' },
                { label: 'Reconciled', value: 'Reconciled' },
                { label: 'G&A', value: 'G&A' },
                { label: 'Core', value: 'Core' },
            ],
        };
    }

    onChange(e) {
        const newStatus = e.target.value;
        const item_id = this.props.data.id;

        statementsApi
            .updateStatementItem(item_id, {
                status: newStatus,
            })
            .then(() => {
                this.setState({
                    value: newStatus,
                });
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    render() {
        return (
            <div className="Grid-select">
                <select onChange={this.onChange} value={this.state.value}>
                    {this.state.selectOptions.map((opt) => (
                        <option key={opt.value} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}
