import api from './api';

import endpoints from './endpoints';

const stratMetRoute = endpoints.STRATEGIC_METRIC;
const projectsRoute = endpoints.PROJECTS;
const projectMetricsRoute = endpoints.PROJECT_METRICS;

export default {
    getStrategicMetrics() {
        return api.get(stratMetRoute, 'Error getting list of Strategic Metrics');
    },

    getMetricsByYear(year) {
        return api.get(`${stratMetRoute}/filtered/${year}`, 'Error getting list of Strategic Metrics by year');
    },

    createMetric(metricAttributes) {
        return api.post(stratMetRoute, metricAttributes, 'Error creating a strategic metric');
    },

    copyAnnualPlan(yearAttributes) {
        return api.put(stratMetRoute, yearAttributes, 'Error copying annual plan');
    },

    updateMetric(metricId, metricAttributes) {
        return api.put(`${stratMetRoute}/${metricId}`, metricAttributes, 'Error updating a strategic metric');
    },

    removeMetric(metricId) {
        return api.delete(`${stratMetRoute}/${metricId}`, 'Error deleting a strategic metric');
    },

    fetchAllProjectMetrics() {
        return api.get(`${projectsRoute}/metrics`, 'Error getting all project metrics');
    },

    fetchProjectMetrics(projectId) {
        return api.get(`${projectsRoute}/${projectId}/metrics`, 'Error getting project metrics');
    },

    fetchProjectMetricQuarters(projectId, projectMetricId) {
        return api.get(
            `${projectsRoute}/${projectId}/metrics/${projectMetricId}`,
            'Error getting project metric quarters'
        );
    },

    fetchProjectGoals(projectId) {
        return api.get(`${projectsRoute}/${projectId}/goals`, 'Error getting project metric goals');
    },

    createProjectGoal(projectId, projectMetricId, goalValue) {
        return api.post(
            `${projectsRoute}/${projectId}/goals`,
            { project_metric_id: projectMetricId, planned: goalValue },
            'Error creating a project metric goal'
        );
    },

    fetchProjectAchievements(projectMetricId, quarter) {
        if (quarter === false) {
            return api.get(
                `${projectMetricsRoute}/${projectMetricId}/achievements`,
                'Error getting project metric achievements'
            );
        }
        return api.get(
            `${projectMetricsRoute}/${projectMetricId}/achievements?quarter=${quarter}`,
            'Error getting project metric achievements'
        );
    },

    createProjectAchievement(projectMetricId, fiscalYear, quarter, note, value) {
        return api.post(
            `${projectMetricsRoute}/${projectMetricId}/achievements`,
            { fiscal_year: fiscalYear, quarter, note, value },
            'Error creating a project metric achievement'
        );
    },

    updateProjectAchievement(projectMetricId, achievementId, note, value) {
        return api.put(
            `${projectMetricsRoute}/${projectMetricId}/achievements/${achievementId}`,
            { note, value },
            'Error updating a project metric achievement'
        );
    },

    removeProjectAchievement(projectMetricId, achievementId) {
        return api.delete(
            `${projectMetricsRoute}/${projectMetricId}/achievements/${achievementId}`,
            'Error deleting project achievement'
        );
    },
};
