import React, { useState, useEffect } from 'react';
import Modal from '../../components/modals/Modal';
import fileDownload from '../../icons/download.svg';
import spinner from '../../icons/spinner.svg';

const ReconciliationModal = (props) => {
    const { closeModal, type, message } = props;
    const [openModal, setOpenModal] = useState(false);
    useEffect(() => {
        setOpenModal(props.openModal);
    }, [props.openModal]);
    if (type === 'FileTypeError') {
        return (
            <Modal isOpen={openModal} style={{ minWidth: '20vw' }}>
                <div className="flex Align-right">
                    <button onClick={closeModal}>
                        <i className="fa fa-window-close" />
                    </button>
                </div>
                <div>
                    <p>{message}</p>
                </div>
            </Modal>
        );
    }
    if (type === 'DownloadReady') {
        return (
            <Modal isOpen={openModal} style={{ minWidth: '30vw' }}>
                <div>
                    <div className="flex Align-right">
                        <button onClick={closeModal}>
                            <i className="fa fa-window-close" />
                        </button>
                    </div>
                    <div className="fileDownload">
                        <img src={fileDownload} alt="file download" />
                    </div>
                    <div className="Align-center">
                        <p>{message}</p>
                    </div>
                </div>
            </Modal>
        );
    }
    return (
        <Modal isOpen={openModal} style={{ minWidth: '30vw' }}>
            <div>
                <div className="spinning">
                    <img className="fa M2spin" src={spinner} alt="spinner" />
                </div>
                <div className="Align-center">
                    <p>{message}</p>
                </div>
            </div>
        </Modal>
    );
};

export default ReconciliationModal;
