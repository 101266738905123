import React from 'react';

class NumericEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.setState({ value: this.props.value });
    }

    handleChange(evt) {
        this.setState({ value: evt.target.value });
    }

    getValue() {
        return this.state.value;
    }

    render() {
        return (
            <input
                type="number"
                value={this.state.value}
                onChange={this.handleChange}
                style={{ margin: '0', padding: '.25em' }}
            />
        );
    }
}

export default NumericEditor;
