import React from 'react';
import ProjectsApi from '../../../api/projects';
import errorToast from '../../../components/messages/ErrorMessage';
import Table from '../../../components/tables/Table';
import ExportCSVButton from '../../../components/buttons/ExportCSVButton';

class ProjectStatusReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: null,
            activity_reports: null,
            selected_reports: null,
        };

        this.onTableReady = this.onTableReady.bind(this);
    }

    async componentDidMount() {
        const projectId = this.props.data.id;
        this.updateData(projectId);
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data.id !== this.props.data.id) {
            this.updateData(nextProps.data.id);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    async updateData(projectId) {
        try {
            const { data: project } = await ProjectsApi.fetchProject(projectId);
            const { data: activity_reports } = await ProjectsApi.fetchActivityReports(projectId);

            if (project.wbi_lead_user_id) {
                project.users.forEach((user) => {
                    if (project.wbi_lead_user_id === user.id) {
                        project.wbi_lead_user_name = user.name;
                    }
                });
            }

            this.setState({
                project,
                activity_reports,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        const { activity_reports } = this.state;

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="project-activity-reports" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div>
                    <Table
                        columns={[
                            { headerName: 'From', field: 'from_date', type: 'date' },
                            { headerName: 'To', field: 'to_date', type: 'date' },
                            { headerName: 'Type', field: 'report_type', filter: true },
                            { headerName: 'Notes', field: 'notes', type: 'longtext', filter: true },
                            {
                                headerName: 'Impact/Accomplishments',
                                field: 'impact',
                                type: 'longtext',
                                filter: true,
                            },
                        ]}
                        data={activity_reports}
                        onTableReady={this.onTableReady}
                        filter={true}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}
export default ProjectStatusReport;
