export const tableStringComparator = (valueA, valueB) => {
    valueA = valueA || '';
    valueB = valueB || '';

    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};
export const nodeFundsValueComparator = (valueA, valueB, nodeA, nodeB) => {
    let fundA = nodeA.data.funds_total;
    let fundB = nodeB.data.funds_total;
    if (fundA === fundB) {
        return 0;
    }
    return fundA > fundB ? 1 : -1;
};
