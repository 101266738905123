import React from 'react';
import classNames from 'classnames';

const Rating = ({ name, value, onChange, onBlur, className, ...props }) => {
    const stars = [];

    for (let i = 0; i < 5; i++) {
        stars.push(
            <i
                className={classNames('fa', value > i ? 'fa-star' : 'fa-star-o')}
                onClick={() => onChange(i + 1)}
                key={i}
                style={{ marginRight: 4 }}
            />
        );
    }

    return <div className={classNames('flex', className)}>{stars}</div>;
};

export default Rating;
