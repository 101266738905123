import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import LabeledField from '../components/forms/LabeledField';
import { TravelRequestTable } from './travelRequest/TravelRequestTable';
import errorToast from '../components/messages/ErrorMessage';
import { confirm } from '../components/modals/ConfirmModal';
import { prepareUserOptions, prepareApproverOptions } from '../utils/UserUtils';
import UpdateItineraryModal from './travelRequest/UpdateItineraryModal';
import TravelRequestsApi from '../api/travelRequests';
import CoreBudgetTypesApi from '../api/coreBudgetTypes';
import UsersApi from '../api/users';

export const TravelRequestForm = (props) => {
    const projectId = props.match.params.id;
    const defaultBlankFields = {
        traveler_id: '',
        travel_request_type: '',
        approver_id: '',
        itinerary: [
            {
                location_of_travel: '',
                zip_code: '',
                date_of_travel: '',
                date_of_return: '',
                per_diem_rate: '',
                max_lodge_rate: '',
                description: '',
            },
        ],
    };
    const { isProject, isArchived } = props;

    const [travelHistory, setTravelHistory] = useState([]);
    const [accountOptions, setAccountOptions] = useState([]);
    const [rowToUpdate, setRowToUpdate] = useState(null);
    const [rowIndex, setRowIndex] = useState(null);
    const [isMultiCity, setIsMultiCity] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [approvalUsersOptions, setApprovalUsersOptions] = useState([]);
    const [formInitValues, setFormInitValues] = useState(defaultBlankFields);
    const [visibleCity, setVisibleCity] = useState(0);

    const isAccountYupRequired = (isProjectCheck) => {
        let accountRequired = yup.string();
        if (!isProjectCheck) {
            accountRequired = yup.string().required();
        }
        return accountRequired;
    };

    const createYupValidationSchema = (isProjectYup) =>
        yup.object().shape({
            traveler_id: yup.string().required(),
            approver_id: yup.string().required(),
            travel_request_type: isAccountYupRequired(isProjectYup),
            itinerary: yup
                .array()
                .of(
                    yup.object().shape({
                        location_of_travel: yup.string().required(),
                        zip_code: yup.string().required(),
                        date_of_travel: yup.date().required(),
                        date_of_return: yup
                            .date()
                            .min(yup.ref('date_of_travel'), 'Date of Return cannot be before Date of Arrival')
                            .required(),
                        per_diem_rate: yup.string().required(),
                        max_lodge_rate: yup.string().required(),
                        description: yup.string(),
                    })
                )
                .min(1),
        });

    const fetchData = useCallback(async () => {
        setTravelHistory([]);
        try {
            if (isProject) {
                const { data: travelRequestsProject = {} } = await TravelRequestsApi.fetchTravelRequestsProject(
                    projectId
                );
                setTravelHistory(travelRequestsProject);
            } else {
                const { data: travelRequestsUser = {} } = await TravelRequestsApi.fetchTravelRequestsTraveler();
                setTravelHistory(travelRequestsUser);
            }

            const { data: users } = await UsersApi.getUsers();
            const options = prepareUserOptions(users);
            setUserOptions(options);
            const approverOptions = prepareApproverOptions(users);
            setApprovalUsersOptions(approverOptions);

            const { data: tempCoreBudgetTypes } = await CoreBudgetTypesApi.getCoreBudgetTypes();
            // setCoreBudgetType(tempCoreBudgetTypes);
            let tempAccountOptions = [];
            if (tempCoreBudgetTypes) {
                tempCoreBudgetTypes.forEach((account) => {
                    tempAccountOptions.push({
                        label: account.name,
                        value: account.id,
                    });
                });
                tempAccountOptions.sort((a, b) => a.label.localeCompare(b.label));
                setAccountOptions(tempAccountOptions);
            }
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }, [isProject, projectId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getRowData = (event) => {
        setRowToUpdate(event.data);
        setFormInitValues({
            traveler_id: event.data.traveler_id,
            travel_request_type: isProject ? '' : event.data.account.id,
            zip_code: event.data.zip_code,
            itinerary: event.data.itinerary,
            approver_id: event.data.approver.id,
        });
        // tempGridApi.updateRowData({ remove: [event.data] });
        setRowIndex(event.node.rowIndex);
        if (event.data.itinerary.length > 1) {
            setIsMultiCity(true);
        } else {
            setIsMultiCity(false);
        }
    };

    const formSubmit = async (data, actions) => {
        console.log({ data });
        // setIsSubmitting(true);

        let travelRequest = {
            traveler_id: data.traveler_id.value ? data.traveler_id.value : data.traveler_id,
            account_id: isProject ? projectId : data.travel_request_type.value,
            account_type: isProject ? 'Project' : 'CoreBudgetType',
            itinerary: data.itinerary,
            approver_id: data.approver_id.value ? data.approver_id.value : data.approver_id,
        };

        if (rowToUpdate === null) {
            TravelRequestsApi.createTravelRequests(travelRequest)
                .then(() => {
                    setFormInitValues(defaultBlankFields);
                    fetchData();
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        } else {
            let confirmed = true;
            if (rowToUpdate.status === 'Approved' || rowToUpdate.status === 'Rejected') {
                confirmed = await confirm({
                    text: 'This will resubmit for approval.  Continue?',
                    acceptText: 'Yes',
                    rejectText: 'No',
                });
            }
            if (confirmed) {
                TravelRequestsApi.updateTravelRequest(rowToUpdate.id, travelRequest)
                    .then(() => {
                        // gridApi.updateRowData({ add: [response.data], addIndex: rowIndex });
                        setRowIndex(null);
                        setRowToUpdate(null);
                        setFormInitValues(defaultBlankFields);
                        fetchData();
                    })
                    .catch((errorMessage) => {
                        errorToast(errorMessage);
                    });
            }
        }

        setIsMultiCity(false);
        setVisibleCity(0);
        actions.resetForm();
        // setIsSubmitting(false);
    };

    const selectItineraryField = (values, setFieldValue) => (
        <>
            <label className="FieldLabel itinerary-label">Itinerary</label>
            <select
                className="Travel-itinerary-select"
                onChange={(event) => {
                    setVisibleCity(parseInt(event.target.value, 10));
                }}
            >
                {values.itinerary.map((item, index) => (
                    <option key={index} value={index} selected={index === visibleCity && 'True'}>
                        ({index + 1}) {item.location_of_travel}
                    </option>
                ))}
            </select>
            <UpdateItineraryModal values={values} setVis={setVisibleCity} setField={setFieldValue} />
        </>
    );

    if (_.isEmpty(userOptions)) return <div>Loading...</div>;

    return (
        <>
            <div className="Single-layout Companion-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formInitValues}
                    validationSchema={createYupValidationSchema(isProject)}
                    onSubmit={(values, actions) => {
                        formSubmit(values, actions);
                    }}
                >
                    {({ errors, touched, isSubmitting, setFieldValue, values, handleReset }) => (
                        <Form>
                            <LabeledField
                                label="Traveler*"
                                name="traveler_id"
                                component="select"
                                options={userOptions}
                                errors={errors}
                                touched={touched}
                                onChange={(e) => {
                                    setFieldValue('traveler_id', e);
                                }}
                            />

                            <LabeledField
                                label="Approver*"
                                name="approver_id"
                                component="select"
                                options={approvalUsersOptions}
                                errors={errors}
                                touched={touched}
                                onChange={(e) => {
                                    setFieldValue('approver_id', e);
                                }}
                            />

                            {!isProject && (
                                <LabeledField
                                    label="Account*"
                                    name="travel_request_type"
                                    component="select"
                                    options={accountOptions}
                                    errors={errors}
                                    touched={touched}
                                    onChange={(e) => {
                                        setFieldValue('travel_request_type', e);
                                    }}
                                />
                            )}

                            {isMultiCity && selectItineraryField(values, setFieldValue)}

                            <FieldArray
                                name="itinerary"
                                render={() => (
                                    <div className="column-span-3">
                                        {values.itinerary.map((item, index) => (
                                            <div
                                                id={`vis_${index}`}
                                                key={index}
                                                className={`grid columns-3 ${index !== visibleCity && 'hidden'}`}
                                            >
                                                <LabeledField
                                                    label="Location of Travel*"
                                                    name={`itinerary.${index}.location_of_travel`}
                                                    type="text"
                                                    errors={errors}
                                                    touched={touched}
                                                />

                                                <LabeledField
                                                    label="ZIP Code*"
                                                    name={`itinerary.${index}.zip_code`}
                                                    type="text"
                                                    errors={errors}
                                                    touched={touched}
                                                />

                                                <LabeledField
                                                    label="Date of Travel*"
                                                    name={`itinerary.${index}.date_of_travel`}
                                                    type="date"
                                                    component="Date"
                                                    errors={errors}
                                                    touched={touched}
                                                />

                                                <LabeledField
                                                    label="Return*"
                                                    name={`itinerary.${index}.date_of_return`}
                                                    type="date"
                                                    component="Date"
                                                    errors={errors}
                                                    touched={touched}
                                                />

                                                <LabeledField
                                                    label="Per Diem Rate (M&IE)*"
                                                    name={`itinerary.${index}.per_diem_rate`}
                                                    type="number"
                                                    errors={errors}
                                                    touched={touched}
                                                />

                                                <LabeledField
                                                    label="Maximum Lodging Rate*"
                                                    name={`itinerary.${index}.max_lodge_rate`}
                                                    type="number"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                                <LabeledField
                                                    label="Description"
                                                    name={`itinerary.${index}.description`}
                                                    component="textarea"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                        ))}
                                        <div className="column-span-3 text-align-right Travel-form-add-city">
                                            <button
                                                onClick={() => {
                                                    setIsMultiCity(true);
                                                    values.itinerary.push(defaultBlankFields.itinerary[0]);

                                                    setVisibleCity(values.itinerary.length - 1);
                                                }}
                                            >
                                                Add Additional City
                                            </button>
                                        </div>
                                    </div>
                                )}
                            />

                            <div className="Form__buttons--Reversed Buttons_format">
                                <>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setRowToUpdate(null);
                                            setRowIndex(null);
                                            setFormInitValues(defaultBlankFields);
                                            handleReset();
                                        }}
                                        disabled={isSubmitting || isArchived}
                                        style={{ marginRight: 8 }}
                                    >
                                        Cancel
                                    </button>
                                    <button disabled={isArchived} type="submit">
                                        Request Travel Approval
                                    </button>
                                </>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <TravelRequestTable
                travelHistory={travelHistory}
                rowIndex={rowIndex}
                getRowData={getRowData}
                isProject={isProject}
            />
        </>
    );
};

export default TravelRequestForm;
