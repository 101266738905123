import React from 'react';
import PagingTable from '../../../components/tables/PagingTable';
import errorToast from '../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../components/buttons/ExportCSVButton';

class MilestoneDeliverablesStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: null,
            rowData: null,
            users: null,
            exportDataAsCsv: null,
        };
        this.onTableReady = this.onTableReady.bind(this);
    }

    async componentDidMount() {
        let data = this.props.data;
        let date = this.props.date;
        this.updateData(data, date);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.updateData(this.props.data, this.props.date);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.setState({ exportDataAsCsv: params.exportDataAsCsv });
        }
    }

    async updateData(data) {
        let projects = data;
        let milestonesAndDeliverableData = [];
        try {
            projects.forEach((p) => {
                if (p.milestones) {
                    p.milestones.forEach((m) => {
                        m.projectNum = p.number;
                        m.projectName = p.name;
                        m.due_date = m.to_date;
                        milestonesAndDeliverableData.push(m);
                    });
                }
                if (p.deliverables) {
                    p.deliverables.forEach((d) => {
                        d.projectNum = p.number;
                        d.projectName = p.name;
                        milestonesAndDeliverableData.push(d);
                    });
                }
            });

            this.setState({
                projects,
                rowData: milestonesAndDeliverableData,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        const { rowData, exportDataAsCsv } = this.state;

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="Milestones-Deliverables-reports" exportFunction={exportDataAsCsv} />
                    </div>
                </div>
                <div className="Report-second-row">
                    <PagingTable
                        columns={[
                            {
                                headerName: 'Project No.',
                                field: 'projectNum',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'project_id',
                                },
                                type: 'idNum',
                                filter: true,
                            },
                            { headerName: 'Project', field: 'projectName', type: 'longtext', filter: true },
                            { headerName: 'Type', field: 'event_type', filter: true },
                            { headerName: 'Description', field: 'description', type: 'longtext', filter: true },
                            { headerName: 'Due Date', field: 'due_date', type: 'date', filter: true },
                        ]}
                        data={rowData}
                        onTableReady={this.onTableReady}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}

export default MilestoneDeliverablesStatus;
