import React from 'react';
import Table from '../../../../components/tables/Table';
import errorToast from '../../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../../components/buttons/ExportCSVButton';
import ProjectsApi from '../../../../api/projects';

class MilestoneDeliverablesStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rowData: null,
            selectedService: this.props.selectedService,
        };
        this.onTableReady = this.onTableReady.bind(this);
    }

    async componentDidMount() {
        const { data: records } = await ProjectsApi.fetchAllProjectsDeliverablesMilestones({
            service: this.state.selectedService,
            overdue: false,
        });
        records.forEach((r) => {
            r.done = r.completed ? 'Yes' : 'No';
        });
        try {
            this.setState({ rowData: records });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    render() {
        const { rowData } = this.state;

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton
                            fileName="Milestones-Deliverables-reports"
                            exportFunction={this.exportDataAsCsv}
                        />
                    </div>
                </div>
                <div className="Report-second-row">
                    <Table
                        columns={[
                            {
                                headerName: 'Project No.',
                                field: 'project_num',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'project_id',
                                },
                                type: 'idNum',
                            },
                            { headerName: 'Project', field: 'project_name', type: 'mediumtext' },
                            {
                                headerName: 'Project Lead',
                                field: 'project_lead',
                                type: 'shortname',
                            },
                            { headerName: 'Due Date', field: 'due_date', type: 'date' },
                            { headerName: 'Project Status', field: 'status', type: 'shortname' },
                            { headerName: 'Complete', field: 'done', type: 'shortname' },
                            { headerName: 'Type', field: 'event_type', type: 'shortname' },
                            { headerName: 'Description', field: 'description', type: 'mediumtext' },
                        ]}
                        data={rowData}
                        onTableReady={this.onTableReady}
                        filter={true}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}
export default MilestoneDeliverablesStatus;
