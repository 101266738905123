import ErgoMoney from './ErgoMoney';

class ProjectFinancialSummaryCalculator {
    constructor(projectId, transactions, agreementInvoices, travelExpenses) {
        this.projectId = projectId;
        this.transactions = transactions;
        this.agreementInvoices = agreementInvoices;
        this.travelExpenses = travelExpenses;

        this.TRANSACTION_TYPES = {
            Expenditure: 'Expenditure',
            TravelExpense: 'TravelExpense',
            AgreementInvoice: 'AgreementInvoice',
            Project: 'Project',
            Mod: 'Mod',
            ResidualFund: 'ResidualFund',
            Commercial: 'Commercial',
            Grant: 'Grant',
        };
    }

    findInvoiceById(id) {
        return this.agreementInvoices.find((invoice) => invoice.id === id);
    }

    assignTypeToTransactionForDisplay() {
        for (const transaction of this.transactions) {
            if (
                transaction.artifact_type &&
                transaction.artifact_type === this.TRANSACTION_TYPES.TravelExpense &&
                transaction.source_id &&
                transaction.source_id === this.projectId
            ) {
                transaction.type = this.TRANSACTION_TYPES.TravelExpense;
            } else if (
                transaction.artifact_type &&
                transaction.artifact_type === this.TRANSACTION_TYPES.AgreementInvoice &&
                transaction.source_id
            ) {
                const invoice = this.findInvoiceById(transaction.artifact_id);
                if (invoice) {
                    if (transaction.source_id === this.projectId) {
                        transaction.description = invoice.notes;
                        if (invoice.agreement) {
                            transaction.type = 'Invoice from ' + invoice.agreement.vendor_name;
                        } else {
                            transaction.type = 'Invoice from Other';
                        }
                    } else {
                        transaction.type = 'Agreement Invoice';
                    }
                }
            } else if (
                transaction.destination_type &&
                transaction.destination_type === this.TRANSACTION_TYPES.Project &&
                transaction.destination_id &&
                transaction.destination_id === this.projectId
            ) {
                if (transaction.source_id === transaction.destination_id) {
                    transaction.type = 'Prior Funding';
                    transaction.account_id = transaction.source_id;
                } else if (transaction.source_type && transaction.source_type === this.TRANSACTION_TYPES.Mod) {
                    transaction.type = `Transfer in from Mod ${transaction.description}`;
                    transaction.account_id = transaction.source_id;
                } else if (transaction.source_type && transaction.source_type === this.TRANSACTION_TYPES.ResidualFund) {
                    transaction.type = `Transfer from ${transaction.source.name}`;
                    transaction.account_id = transaction.source_id;
                } else if (transaction.source_type && transaction.source_type === this.TRANSACTION_TYPES.Commercial) {
                    transaction.type = `Transfer in from Commercial Contract ${transaction.description}`;
                    transaction.account_id = transaction.source_id;
                } else if (transaction.source_type && transaction.source_type === this.TRANSACTION_TYPES.Grant) {
                    transaction.type = `Transfer in from Grant ${transaction.description}`;
                    transaction.account_id = transaction.source_id;
                } else {
                    transaction.type = `Transfer from ${transaction.source.name}`;
                    transaction.account_id = transaction.source_id;
                }
            } else if (
                transaction.source_type &&
                transaction.source_type === this.TRANSACTION_TYPES.Project &&
                transaction.source_id &&
                transaction.source_id === this.projectId
            ) {
                if (transaction.destination_type === this.TRANSACTION_TYPES.Project) {
                    transaction.type = `Transfer to ${transaction.destination.name}`;
                    transaction.account_id = transaction.destination_id;
                } else if (transaction.destination_type === this.TRANSACTION_TYPES.ResidualFund) {
                    transaction.type = `Transfer to ${transaction.destination.name}`;
                } else if (transaction.artifact_type === this.TRANSACTION_TYPES.Expenditure) {
                    transaction.type = 'CC';
                }
            }
        }
    }

    filterOutInDraftTravelExpenses() {
        this.travelExpenses = this.travelExpenses.filter((travelExpense) => travelExpense.status !== 'In Draft');
    }

    static addPerDiem(travelExpense, travelExpenseTransaction) {
        travelExpenseTransaction.amount = new ErgoMoney(travelExpenseTransaction.amount)
            .add(travelExpense.per_diem_sum)
            .getNumberAmount();
        return travelExpenseTransaction;
    }

    static subtractNonreimburseablesFromMAndIE(travelExpense, travelExpenseTransaction) {
        for (const travelExpenditure of travelExpense.travel_expenditures) {
            travelExpenseTransaction.amount = new ErgoMoney(travelExpenseTransaction.amount)
                .subtract(travelExpenditure.hotel_non_reimburse)
                .getNumberAmount();
        }
        return travelExpenseTransaction;
    }

    assignTravelExpenditureTypesToTransactions(travelExpense) {
        for (const travelExpenditure of travelExpense.travel_expenditures) {
            const artifactIds = this.transactions.map((transaction) => transaction.artifact_id);
            const transactionIndex = artifactIds.indexOf(travelExpenditure.expenditure.id);
            this.transactions[transactionIndex].type = 'CC Travel Expenditure';
            this.transactions[transactionIndex].description = travelExpenditure.travel_expenditure_type;
        }
    }

    prepareTravelExpenseTransactions() {
        for (const travelExpense of this.travelExpenses) {
            let travelExpenseTransaction = {
                transaction_date: travelExpense.end_date,
                updated_on: travelExpense.updated_on,
                type: 'Travel M&IE',
                description: travelExpense.notes,
                status: travelExpense.status,
                amount: 0,
                amount_plus_g_a: 0,
                ga_rate: travelExpense.ga_rate,
            };

            travelExpenseTransaction = ProjectFinancialSummaryCalculator.addPerDiem(
                travelExpense,
                travelExpenseTransaction
            );

            this.assignTravelExpenditureTypesToTransactions(travelExpense);

            travelExpenseTransaction = ProjectFinancialSummaryCalculator.subtractNonreimburseablesFromMAndIE(
                travelExpense,
                travelExpenseTransaction
            );

            travelExpenseTransaction.amount_plus_g_a = new ErgoMoney(travelExpenseTransaction.amount)
                .multiply(travelExpenseTransaction.ga_rate + 1)
                .getNumberAmount();

            this.transactions.push(travelExpenseTransaction);
        }
    }

    prepareAgreementInvoiceAmountWithGA() {
        for (const invoice of this.agreementInvoices) {
            invoice.amount_plus_g_a = new ErgoMoney(invoice.amount)
                .multiply(invoice.transaction.ga_rate)
                .getNumberAmount();
        }
    }

    alterTransactionStatusForDisplay() {
        for (const transaction of this.transactions) {
            if (transaction.status === 'Returned') {
                transaction.status = 'Returned to Sender';
            } else if (transaction.status === 'Pending') {
                transaction.status = 'Pending Approval';
            } else if (transaction.status === 'Submitted' || transaction.status === 'Approved') {
                transaction.status = 'Pending Review';
            } else if (transaction.status === 'Applied') {
                transaction.status = 'Paid';
            }
        }
    }

    sortTransactionsOldToNew() {
        this.transactions = this.transactions.sort(
            (transactionA, transactionB) => new Date(transactionA.updated_on) - new Date(transactionB.updated_on)
        );
    }

    calculateRemainingFunds() {
        const runningTotal = new ErgoMoney();
        for (const transaction of this.transactions) {
            if (
                transaction.source_type === this.TRANSACTION_TYPES.Mod ||
                (transaction.destination_type === this.TRANSACTION_TYPES.Project &&
                    transaction.destination_id === this.projectId)
            ) {
                runningTotal.add(transaction.amount);
                transaction.remaining_funds = runningTotal.getNumberAmount();
            } else if (
                transaction.destination_type === this.TRANSACTION_TYPES.Project &&
                transaction.destination_id !== this.projectId
            ) {
                runningTotal.subtract(transaction.amount);
                transaction.remaining_funds = runningTotal.getNumberAmount();
            } else if (transaction.destination_type === this.TRANSACTION_TYPES.ResidualFund) {
                runningTotal.subtract(transaction.amount);
                transaction.remaining_funds = runningTotal.getNumberAmount();
            } else if (transaction.status === 'Returned to Sender') {
                transaction.remaining_funds = runningTotal.getNumberAmount();
            } else {
                runningTotal.subtract(transaction.amount_plus_g_a);
                transaction.remaining_funds = runningTotal.getNumberAmount();
            }
        }
    }

    runCalculator() {
        this.assignTypeToTransactionForDisplay();
        this.filterOutInDraftTravelExpenses();
        this.prepareTravelExpenseTransactions();
        this.prepareAgreementInvoiceAmountWithGA();
        this.alterTransactionStatusForDisplay();
        this.sortTransactionsOldToNew();
        this.calculateRemainingFunds();
        return this.transactions;
    }
}

export default ProjectFinancialSummaryCalculator;
