import React from 'react';
import { Formik, Form } from 'formik';
import LabeledField from '../forms/LabeledField';
import Modal from './Modal';
import errorToast from '../messages/ErrorMessage';

// This actual component should be mounted once in a global location like in <App />
// The static addNote in the component

let instance;

class AddNoteModal extends React.Component {
    static addNote = (item, item_type) =>
        new Promise((resolve) => {
            instance.setState({
                isOpen: true,
                item,
                item_type,
            });
            instance.resolve = resolve;
        });

    constructor() {
        super();
        this.state = {
            isOpen: false,
            returnNote: null,
            item: null,
            item_type: null,
        };
    }

    componentDidMount() {
        if (instance) {
            errorToast('More than one <AddNoteModal> exists! Remove all except for one');
            return;
        }
        instance = this;
    }

    handleAccept = (values) => {
        if (!values.note) {
            values.note = '';
        }
        this.resolve && this.resolve(values.note);
        this.setState({ isOpen: false });
    };

    render = () => {
        const { isOpen, item, item_type } = this.state;

        let formInit;
        if (item_type !== 'TravelExpense') {
            formInit = {
                ...item,
            };
        } else if (item_type === 'TravelExpense') {
            formInit = {
                ...item,
                note: item.notes,
            };
        } else {
            formInit = {
                note: '',
            };
        }

        let formIdFields;
        if (item) {
            if (item_type === 'Expenditure') {
                formIdFields = [
                    <LabeledField key="project_name" label="Project Name:" name="account.name" readOnly />,
                    <LabeledField key="card_holder" label="Card Holder:" name="cardHolder" readOnly />,
                    <LabeledField
                        label="Transaction Date:"
                        name="purchase_date"
                        component="Date"
                        key="purchase_date"
                        readOnly
                    />,
                    <LabeledField key="cost" label="Amount:" name="cost" component="Money" readOnly />,
                    <LabeledField
                        label="Description:"
                        name="item_description"
                        component="textarea"
                        key="item_description"
                        readOnly
                    />,
                ];
            } else if (item_type === 'AgreementInvoice') {
                console.log(item);
                formIdFields = [
                    <LabeledField key={0} label="Project Number:" name="project.number" readOnly />,
                    <LabeledField key={1} label="Vendor Invoice number:" name="vendor_invoice_number" readOnly />,
                    <LabeledField key={2} label="Received Date:" name="received_date" component="Date" readOnly />,
                    <LabeledField key={3} label="Amount:" name="transaction.amount" component="Money" readOnly />,
                ];
            } else if (item_type === 'TravelExpense') {
                formIdFields = [
                    <LabeledField label="Account Number:" name="account_number" key="account_name" readOnly />,
                    <LabeledField label="Traveler:" name="traveler" key="traveler" readOnly />,
                    <LabeledField label="Start Date:" name="start_date" component="Date" key="start_date" readOnly />,
                    <LabeledField label="End Date:" name="end_date" component="Date" key="end_date" readOnly />,
                ];
            } else {
                errorToast('No return card for this item!');
            }
        }

        return (
            <Modal isOpen={isOpen} style={{ minWidth: '40vw' }}>
                <>
                    <label className="Card_title">Add a note</label>
                    <div className="Note-card-form">
                        <Formik initialValues={formInit} onSubmit={this.handleAccept}>
                            {(errors, touched) => (
                                <Form>
                                    <div className="Note-card-form__note_grid_narrow">
                                        {formIdFields}
                                        <LabeledField
                                            label="Note"
                                            name="note"
                                            component="textarea"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <button type="submit">Add note</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </>
            </Modal>
        );
    };
}

export const addNote = AddNoteModal.addNote;
export default AddNoteModal;
