// React
import React, { useCallback, useEffect, useState } from 'react';

//Components
import PageHeader from '../components/heading/PageHeader';
import errorToast from '../components/messages/ErrorMessage';
import AgreementInvoicesButtonBar from './agreementInvoices/agreementInvoicesButtonBar';
import AgreementInvoicesTable from './agreementInvoices/agreementInvoicesTable';
import FinanceSubNav from './FinanceSubNav';
import useFunctionAsState from '../hooks/useFunctionAsState';
import { confirm } from '../components/modals/ConfirmModal';
import { addNote } from '../components/modals/AddNoteModal';
// API
import AgreementInvoicesApi from '../api/agreementInvoices';

const AgreementInvoicesPage = () => {
    const [invoices, setInvoices] = useState([]);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [exportTableAsCsv, setExportTableAsCsv] = useFunctionAsState(() => {});

    const returnInvoicesToSender = useCallback(async () => {
        try {
            const confirmed = await confirm({
                text: 'Are you sure you want to return this/these invoice(s)?',
            });
            if (confirmed) {
                for (const node of selectedNodes) {
                    if (node.data.transaction.status !== 'Paid') {
                        const notes = await addNote(node.data, 'AgreementInvoice');
                        await AgreementInvoicesApi.returnToSender(node.data.id, { notes });
                        node.setDataValue('notes', 'notes');
                        node.setDataValue('transaction.status', 'Returned');
                    } else {
                        const message = `Invoice ${node.data.vendor_invoice_number} already paid! Can't be returned.`;
                        errorToast(message);
                    }
                    node.setSelected(false);
                }
                setSelectedNodes([]);
            }
        } catch (error) {
            errorToast(error);
        }
    }, [selectedNodes]);

    const setInvoicesPaid = useCallback(async () => {
        try {
            const confirmed = await confirm({
                text: 'Are you sure you want to pay this/these invoice(s)?',
            });
            if (confirmed) {
                let filteredNodes = selectedNodes.filter((node) => node.data.transaction.status !== 'Paid');

                const invoiceIDs = filteredNodes.map((node) => node.data.id);
                await AgreementInvoicesApi.setStatusPaid(invoiceIDs);
                for (const node of selectedNodes) {
                    node.setDataValue('transaction.status', 'Paid');
                    node.setDataValue('paid_date', new Date());
                    node.setSelected(false);
                }

                setSelectedNodes([]);
            }
        } catch (error) {
            errorToast(error);
        }
    }, [selectedNodes]);

    const loadAllInvoices = useCallback(async () => {
        setInvoices([]);
        try {
            const { data } = await AgreementInvoicesApi.fetchAllInvoices();
            setInvoices(data);
        } catch (error) {
            errorToast(error);
        }
    }, []);

    const hoistSelectedNodes = (nodes) => {
        setSelectedNodes(nodes);
    };

    const onTableReady = ({ exportDataAsCsv }) => {
        if (exportDataAsCsv && typeof exportDataAsCsv === 'function') {
            setExportTableAsCsv(exportDataAsCsv);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await AgreementInvoicesApi.fetchInvoicesByStatus();
                setInvoices(data);
            } catch (error) {
                errorToast(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="Invoices-Page">
            <PageHeader title="Agreement Invoices" />
            <FinanceSubNav />
            <AgreementInvoicesTable
                invoices={invoices}
                hoistSelectedNodes={hoistSelectedNodes}
                onTableReady={onTableReady}
            />
            <AgreementInvoicesButtonBar
                setInvoicesPaid={setInvoicesPaid}
                returnInvoicesToSender={returnInvoicesToSender}
                loadAllInvoices={loadAllInvoices}
                exportDataAsCsv={exportTableAsCsv}
            />
        </div>
    );
};

export default AgreementInvoicesPage;
