import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

const Select = ({ options, readOnly, className, ...props }) => {
    const [validOptions, setValidOptions] = useState([]);

    useEffect(() => {
        let result = options;
        if (options && options.length && typeof options[0] === 'string') {
            result = options.map((opt) => ({ label: opt, value: opt }));
        }
        setValidOptions(result);
    }, [options]);

    return (
        <ReactSelect
            options={validOptions}
            resetValue=""
            inputProps={{ readOnly }}
            disabled={readOnly}
            className={classNames(className, readOnly ? 'read-only' : null)}
            {...props}
        />
    );
};

export default Select;
