import React from 'react';
import PagingTable from '../../../components/tables/PagingTable';
import errorToast from '../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../components/buttons/ExportCSVButton';

class ProjectSummaries extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: null,
            rowData: null,
            users: null,
            exportDataAsCsv: null,
        };
        this.onTableReady = this.onTableReady.bind(this);
    }

    async componentDidMount() {
        let data = this.props.data;
        let date = this.props.date;
        this.updateData(data, date);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.updateData(this.props.data, this.props.date);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.setState({ exportDataAsCsv: params.exportDataAsCsv });
        }
    }

    async updateData(data) {
        let projects = data;

        try {
            projects.forEach((p) => {
                let weekly = [];
                let additionalUsers = [];
                if (p.activity_report_updates) {
                    p.activity_report_updates.forEach((r) => {
                        if (r.report_type === 'weekly') {
                            weekly.push(r);
                            weekly.sort((a, b) => new Date(b.from_date) - new Date(a.from_date));
                            if (weekly[0]) {
                                p.latestActivityStatus = weekly[0];
                            } else {
                                p.latestActivityStatus = 'no status updates';
                            }
                        }
                    });
                    if (p.users) {
                        p.users.forEach((user) => {
                            let userObject = {
                                label: user.name,
                                value: user.id,
                                email_address: user.email_address,
                                phone_number: user.phone_number,
                                name: user.name,
                                id: user.id,
                                approver: user.approver,
                                cc_holder: user.holder,
                                site_role: user.site_role,
                            };
                            if (p.wbi_lead_user_id === user.id) {
                                p.wbi_lead_name = userObject;
                                p.wbi_lead_name_txt = user.name;
                                p.wbi_lead_email_address = user.email_address;
                                p.wbi_lead_phone_number = user.phone_number;
                            } else if (p.wbi_assistant_user_id === user.id) {
                                p.wbi_assistant_name = userObject;
                                p.wbi_assistant_email_address = user.email_address;
                                p.wbi_assistant_phone_number = user.phone_number;
                            } else {
                                additionalUsers.push(user);
                            }
                        });
                    }
                    p.users = additionalUsers;
                }
            });

            this.setState({
                projects,
                rowData: projects,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        const { rowData, exportDataAsCsv } = this.state;

        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="project-summary-reports" exportFunction={exportDataAsCsv} />
                    </div>
                </div>
                <div className="Report-second-row">
                    <PagingTable
                        columns={[
                            {
                                headerName: 'Project No.',
                                field: 'number',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'id',
                                },
                                type: 'idNum',
                                filter: true,
                            },
                            { headerName: 'Project', field: 'name', type: 'longtext', filter: true },
                            {
                                headerName: 'Latest Status',
                                field: 'latestActivityStatus.impact',
                                type: 'longtext',
                                filter: true,
                            },
                            { headerName: 'WBI Contact', field: 'wbi_lead_name_txt', type: 'name', filter: true },
                            { headerName: 'AFRL Contact', field: 'customer_name', type: 'name', filter: true },
                            { headerName: 'Office Symbol', field: 'office_symbol.name', filter: true },
                            { headerName: 'Status', field: 'status', type: 'status', filter: true },
                            {
                                headerName: 'Unbudgeted Funds',
                                field: 'financial_summary.unbudgeted',
                                type: 'money',
                                filter: true,
                            },
                        ]}
                        data={rowData}
                        onTableReady={this.onTableReady}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}

export default ProjectSummaries;
