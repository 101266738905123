// React
import React from 'react';

// Libraries
import { Formik, Form } from 'formik';
import yup from '../../yup';

// Components
import EditingTable from '../../components/tables/EditingTable';
import LabeledField from '../../components/forms/LabeledField';
import errorToast from '../../components/messages/ErrorMessage';
import { confirm } from '../../components/modals/ConfirmModal';
import Select from '../../components/select/Select';

// API
import ProjectsApi from '../../api/projects';
import UsersApi from '../../api/users';

class OpenActionItemsTable extends React.Component {
    constructor(props) {
        super(props);

        this.submitActionItem = this.submitActionItem.bind(this);
        this.clickEditHandler = this.clickEditHandler.bind(this);
        this.removeOpenActionItem = this.removeOpenActionItem.bind(this);

        this.state = {
            rowToUpdate: null,
            users: null,
            forceSetData: false,
            statusFilterValue: {
                label: 'All',
                value: 0,
            },
            actionItems: [],
            actionItemStatusOptions: ['Open', 'Closed'],
            formInitValues: {
                description: '',
                assignee: '',
                due_date: '',
                status: '',
            },
            statusFilterOptions: [
                {
                    label: 'All',
                    value: 'All',
                },
                {
                    label: 'Open',
                    value: 'Open',
                },
                {
                    label: 'Closed',
                    value: 'Closed',
                },
            ],
        };
    }

    componentDidMount() {
        const { project } = this.props;
        let actionItems = project.open_action_items;

        UsersApi.getUsers()
            .then((response) => {
                this.setState({
                    users: response.data,
                    actionItems,
                });
            })
            .catch((errorMessage) => {
                errorToast(errorMessage);
            });
    }

    submitActionItem(values, actions) {
        const { actionItems, rowToUpdate } = this.state;
        const { project } = this.props;

        if (values.status === 'Open') {
            values.open = true;
        } else if (values.status === 'Closed') {
            values.open = false;
        }
        // Delete attribute for model validation
        delete values.status;

        if (rowToUpdate) {
            ProjectsApi.updateOpenActionItem(rowToUpdate.project_id, rowToUpdate.id, values)
                .then((response) => {
                    actionItems.push(response.data);

                    this.setState(
                        {
                            actionItems,
                            rowToUpdate: null,
                            formInitValues: {
                                description: '',
                                assignee: '',
                                due_date: '',
                                status: '',
                            },
                            forceSetData: true,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );

                    actions.resetForm();
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        } else {
            const openActionItems = actionItems.map((o) => o);

            let openActionValues = {
                description: values.description,
                assignee: values.assignee,
                due_date: values.due_date,
                open: values.open,
            };

            ProjectsApi.createOpenActionItem(project.id, openActionValues)
                .then((response) => {
                    openActionItems.push(response.data);
                    this.setState(
                        {
                            actionItems: openActionItems.sort((a, b) => a.order - b.order),
                            forceSetData: true,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                    actions.resetForm();
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
    }

    clickEditHandler(event) {
        const openActionItems = this.state.actionItems.filter((o) => o.id !== event.data.id);

        let selectedUser = this.state.users.filter((user) => user.name === event.data.assignee);

        this.setState(
            {
                formInitValues: {
                    description: event.data.description,
                    assignee: selectedUser[0].id,
                    due_date: event.data.due_date,
                    status: event.data.status,
                },
                rowToUpdate: event.data,
                actionItems: openActionItems,
                forceSetData: true,
            },
            () => {
                this.setState({ forceSetData: false });
            }
        );
    }

    async removeOpenActionItem(evt) {
        const confirmed = await confirm({
            text: 'Are you sure you want to delete the action item?',
            acceptText: 'Yes',
            rejectText: 'No',
        });

        if (confirmed) {
            const { project } = this.props;
            const { actionItems } = this.state;
            const openActionItemId = evt.data.id;

            const openActionItems = actionItems.filter((o) => o.id !== openActionItemId);

            ProjectsApi.removeOpenActionItem(project.id, openActionItemId)
                .then(() => {
                    this.setState({ actionItems: openActionItems });
                })
                .catch((errorMessage) => {
                    errorToast(errorMessage);
                });
        }
    }

    render() {
        if (!this.props.project) return <div>loading...</div>;

        const { users, actionItemStatusOptions, formInitValues, statusFilterValue, statusFilterOptions } = this.state;

        let actionItems = this.state.actionItems.sort((a, b) => {
            if (a.date_assigned === null) {
                return -1;
            }
            if (b.date_assigned === null) {
                return 0;
            }
            let result = new Date(a.date_assigned) - new Date(b.date_assigned);
            return result;
        });

        actionItems.forEach((item) => {
            for (let i = 0; i < users.length; i++) {
                if (item.assignee === users[i].id) {
                    item.assignee = users[i].name;
                }
                if (item.assigner === users[i].id) {
                    item.assigner = users[i].name;
                }
            }
        });

        actionItems.forEach((item) => {
            if (item.open) {
                item.status = actionItemStatusOptions[0];
            } else {
                item.status = actionItemStatusOptions[1];
            }
        });

        let filteredActionItems;
        if (statusFilterValue.label === 'All') {
            filteredActionItems = actionItems;
        } else {
            filteredActionItems = actionItems.filter((item) => item.status === statusFilterValue.label);
        }

        let userOptions = [];
        let userLabelOptions = [];
        if (users) {
            users.forEach((user) => {
                userOptions.push({ label: user.name, value: user.id });
                userLabelOptions.push(user.name);
            });
            userOptions.sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1));
        }

        return (
            <div className="Open-action-items-table">
                <Select
                    name="statusFilter"
                    options={statusFilterOptions}
                    value={statusFilterValue}
                    placeholder="Filter by status..."
                    className="Overview-page-space"
                    onChange={(event) => {
                        this.setState({ statusFilterValue: event });
                    }}
                />
                <EditingTable
                    data={filteredActionItems}
                    columns={[
                        {
                            headerName: 'Date Assigned',
                            field: 'date_assigned',
                            type: 'date',
                        },
                        {
                            headerName: 'Assigned By',
                            field: 'assigner',
                            type: 'name',
                        },
                        {
                            headerName: 'Assigned To',
                            field: 'assignee',
                            type: 'name',
                        },
                        {
                            headerName: 'Action Item Description/Response',
                            field: 'description',
                            type: 'longtext',
                        },
                        {
                            headerName: 'Due Date',
                            field: 'due_date',
                            type: 'date',
                        },
                        {
                            headerName: 'Open/Closed',
                            field: 'status',
                            type: 'status',
                        },
                        {
                            headerName: 'Edit',
                            cellRenderer: 'EditSymbolRenderer',
                            type: 'symbol',
                            editable: false,
                            onCellClicked: this.clickEditHandler,
                        },
                        {
                            headerName: 'Delete',
                            cellRenderer: 'TrashSymbolRenderer',
                            type: 'symbol',
                            editable: false,
                            onCellClicked: this.removeOpenActionItem,
                        },
                    ]}
                    addedNewData={this.state.forceSetData}
                />
                <Formik
                    enableReinitialize
                    initialValues={formInitValues}
                    validationSchema={yup.object().shape({
                        description: yup.string().required(),
                        assignee: yup.string().required(),
                        due_date: yup.date().required(),
                        status: yup.string().required(),
                    })}
                    onSubmit={this.submitActionItem}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="Overview-page-space">
                                <LabeledField
                                    name="description"
                                    placeholder="Add new action item description..."
                                    label="Description"
                                    component="textarea"
                                    errors={errors}
                                    touched={touched}
                                />
                            </div>
                            <div className="Overview-page-space">
                                <LabeledField
                                    name="due_date"
                                    component="Date"
                                    label="Due By"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    name="assignee"
                                    placeholder="Assignee..."
                                    component="select"
                                    label="Assign to"
                                    options={userOptions}
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    name="status"
                                    placeholder="Select..."
                                    component="select"
                                    label="Status"
                                    options={[statusFilterOptions[1], statusFilterOptions[2]]}
                                    errors={errors}
                                    touched={touched}
                                />
                            </div>
                            <div className="Form__buttons--Reversed Buttons_format Floating-button">
                                <button>Add</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default OpenActionItemsTable;
