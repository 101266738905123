import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import yup from '../../yup';
import errorToast from '../../components/messages/ErrorMessage';
import AgreementInfo from './AmendmentInfo';
import NTEChange from './NTEChange';
import EndDateChange from './EndDateChange';
import AgreementApi from '../../api/agreements';
import ErgoMoney from '../../utils/ErgoMoney';

export default function AmendAgreementForm({ closeModal, id, agreement }) {
    const [amendmentNumber, setAmendmentNumber] = useState([]);
    useEffect(() => {
        if (Object.keys(agreement).length !== 0) {
            let amendmentsNum = [];
            let amendments = agreement.amendments;
            if (amendments && Object.keys(amendments).length !== 0) {
                amendments.forEach((amendment) => {
                    amendmentsNum.push(amendment.number);
                });
            }
            setAmendmentNumber(amendmentsNum);
        }
    }, [agreement]);
    const createDefaultFormValues = () => ({
        number: '',
        doc_link: '',
        change_type: '',
        notes: '',
        nte_change: 0,
        end_date: moment().format('YYYY-MM-DD'),
        created_on: moment().format('YYYY-MM-DD'),
    });
    const returnFormValues = (values) => ({
        number: values.number,
        doc_link: values.doc_link,
        change_type: values.change_type,
        notes: values.notes,
        nte_change: new ErgoMoney(values.nte_change).getNumberAmount(),
        end_date: values.end_date,
        //created_on: values.created_on,
    });

    const createYupSchema = () =>
        yup.object().shape({
            number: yup
                .string()
                .required()
                .test('number', 'Agreement Number Entered Already In Use', (value) => {
                    let matchFound = false;
                    amendmentNumber.forEach((number) => {
                        if (value === number) {
                            matchFound = true;
                        }
                    });
                    if (matchFound) {
                        return false;
                    }
                    return true;
                }),
            doc_link: yup.string().url('Must a valid url').required(),
            change_type: yup.string().required(),
        });

    // eslint-disable-next-line no-unused-vars
    const [initialValues, setInitialValues] = useState(createDefaultFormValues());

    const handleFormSubmit = (values) => {
        try {
            let submitFormValues = returnFormValues(values);
            let tempNewNTE = new ErgoMoney(values.new_nte).getNumberAmount();
            AgreementApi.addAmendmentForAgreementById(id, submitFormValues).then(() => {
                if (tempNewNTE !== agreement.nte) {
                    closeModal(true);
                }
                closeModal(false);
            });
            setInitialValues(createDefaultFormValues());
        } catch (error) {
            errorToast(error);
        }
    };
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values) => {
                    handleFormSubmit(values);
                }}
                validationSchema={createYupSchema}
            >
                {({ values, errors, touched }) => (
                    <Form id="amendAgreementForm" className="flex">
                        <div className="mr2 flex flex-column width-30">
                            <AgreementInfo errors={errors} touched={touched} />
                        </div>
                        <div className="VerticalLine" />
                        <div className="mr2 flex flex-column width-30">
                            <NTEChange errors={errors} touched={touched} values={values} oldAgreement={agreement} />
                        </div>
                        <div className="VerticalLine" />
                        <div className="mr2 flex flex-column width-30">
                            <EndDateChange errors={errors} touched={touched} values={values} oldAgreement={agreement} />
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="mt2 flex justify-content-end">
                <button
                    className="TextButton"
                    type="reset"
                    onClick={() => {
                        closeModal(false);
                    }}
                >
                    Cancel
                </button>
                <button type="submit" form="amendAgreementForm">
                    Save
                </button>
            </div>
        </>
    );
}
