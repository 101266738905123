import React from 'react';

const ExternalLinkRenderer = ({ docLink, value, data, disabled }) => {
    let externalLink = data[docLink];

    if (value && typeof value === 'object' && !disabled) {
        let externalLink = value.link;
        let name = value.name;
        return (
            <div>
                <a href={externalLink} target="_blank" rel="noopener noreferrer" aria-label="Opens in new tab">
                    {name}
                </a>
            </div>
        );
    }
    if (externalLink && !disabled) {
        let link;
        if (externalLink.includes('http://') || externalLink.includes('https://')) {
            link = externalLink;
        } else {
            link = 'http://' + externalLink;
        }
        return (
            <div>
                <a href={link} target="_blank" rel="noopener noreferrer" aria-label="Opens in new tab">
                    {value}
                </a>
            </div>
        );
    }

    return <div>{value}</div>;
};

export default ExternalLinkRenderer;
