import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import EditingTable from '../../components/tables/EditingTable';
import SectionHeading from '../../components/heading/SectionHeading';
import { confirm } from '../../components/modals/ConfirmModal';
import errorToast from '../../components/messages/ErrorMessage';
import TravelRequestsApi from '../../api/travelRequests';

const deleteRequest = async (event) => {
    const { gridApi } = event.node;
    const confirmed = await confirm({
        text: 'Delete travel request?',
        acceptText: 'Yes',
        rejectText: 'No',
    });
    if (confirmed) {
        gridApi.updateRowData({ remove: [event.data] });
        TravelRequestsApi.deleteTravelRequest(event.data.id).catch((errorMessage) => {
            errorToast(errorMessage);
        });
    }
};

export const TravelRequestTable = (props) => {
    const { travelHistory = [], rowIndex, isProject, getRowData } = props;
    const [travel, setTravel] = useState([]);
    const columns = [
        {
            headerName: 'Traveler',
            field: 'traveler_name',
            type: 'name',
        },
        {
            headerName: 'Account',
            field: 'account.name',
            type: 'longtext',
            hide: isProject,
        },
        {
            headerName: 'Date of Travel',
            field: 'date_of_travel',
            type: 'date',
        },
        {
            headerName: 'Return Date',
            field: 'date_of_return',
            type: 'date',
        },
        {
            headerName: 'Location of Travel',
            field: 'location_of_travel',
            type: 'name',
        },
        {
            headerName: 'ZIP Code',
            field: 'zip_code',
            type: 'zip',
        },
        {
            headerName: 'Description',
            field: 'description',
            type: 'longtext',
        },
        {
            headerName: 'Approval Status',
            field: 'status',
            type: 'status',
        },
        {
            headerName: 'Approver',
            field: 'approver.name',
            type: 'name',
        },
        {
            headerName: 'Initiated By',
            field: 'initiator',
            type: 'name',
        },
        {
            headerName: 'Initiated On',
            field: 'created_on',
            type: 'date',
        },
        {
            headerName: 'Last Update By',
            field: 'last_update_by',
            type: 'name',
        },
        {
            headerName: 'Updated On',
            field: 'updated_on',
            type: 'date',
        },
        {
            headerName: 'Edit',
            field: 'edit',
            type: 'symbol',
            editable: false,
            cellRenderer: 'EditSymbolRenderer',
            onCellClicked: getRowData,
        },
        {
            headerName: 'Del.',
            field: 'delete',
            type: 'symbol',
            editable: false,
            cellRenderer: 'TrashSymbolRenderer',
            onCellClicked: (e) => {
                deleteRequest(e);
            },
        },
    ];

    useEffect(() => {
        const newTravel = _.clone(travelHistory);
        for (let i = 0; i < newTravel.length; i++) {
            const current = newTravel[i];
            if (current.itinerary.length > 0) {
                newTravel[i].date_of_travel = current.itinerary[0].date_of_travel;
                newTravel[i].location_of_travel = `${current.itinerary[0].location_of_travel} ${
                    current.itinerary.length > 1 ? `and ${current.itinerary.length - 1} others.` : ''
                }`;
                newTravel[i].zip_code = current.itinerary[0].zip_code;
                newTravel[i].description = current.itinerary[0].description;
                newTravel[i].date_of_return = current.itinerary[current.itinerary.length - 1].date_of_return;
            }
        }
        setTravel(newTravel);
    }, [travelHistory]);

    return (
        <>
            <SectionHeading>Travel History</SectionHeading>
            <div className="Table-styling">
                <EditingTable data={travel} columns={columns} rowIndex={rowIndex} pagingProp={true} />
            </div>
        </>
    );
};

export default TravelRequestTable;
