export const ACCEPTANCE_THRESHOLD = 155;

export default (scores) => {
    let score = 0;

    if (scores.legal === 'no') score += -9999;
    if (scores.primarily_pass_through === 'yes') score += -30;
    if (scores.just_facility_use === 'yes') score += -30;
    if (scores.afrl_importance === 'high') {
        score += 30;
    } else if (scores.afrl_importance === 'medium') {
        score += 15;
    }
    if (scores.client_jazzed === 'high') {
        score += 20;
    } else if (scores.client_jazzed === 'medium') {
        score += 10;
    }
    if (scores.advocate_strength === 'high') {
        score += 20;
    } else if (scores.advocate_strength === 'medium') {
        score += 10;
    } else if (scores.advocate_strength === 'low') {
        score += 5;
    }
    if (scores.promote_thought_leadership === 'high') {
        score += 20;
    } else if (scores.promote_thought_leadership === 'medium') {
        score += 10;
    }
    if (scores.try_something_new === 'yes') score += 10;
    if (scores.cpo_focus_area === 'yes') {
        score += 10;
    } else if (scores.cpo_focus_area === 'no') {
        score += -5;
    }
    if (scores.client_probability === 'high') {
        score += 30;
    } else if (scores.client_probability === 'medium') {
        score += 20;
    } else if (scores.client_probability === 'low') {
        score += 5;
    }
    if (scores.potential_for_promotion === 'high') {
        score += 30;
    } else if (scores.potential_for_promotion === 'medium') {
        score += 15;
    }
    if (scores.sufficent_resources === 'no') score += -50;
    if (scores.can_obtain_resources === 'yes') {
        score += 50;
    } else if (scores.can_obtain_resources === 'no') {
        score += -100;
    }
    if (scores.client_willing_to_supply === 'yes') {
        score += 30;
    } else if (scores.client_willing_to_supply === 'no') {
        score += -100;
    }
    if (scores.problem_significance === 'high') {
        score += 50;
    } else if (scores.problem_significance === 'medium') {
        score += 20;
    }
    if (scores.more_than_one_service_area === 'yes') score += 10;
    if (scores.wbi_add_value === 'yes') {
        score += 15;
    } else if (scores.wbi_add_value === 'no') {
        score += -5;
    }

    return score;
};
