const DATE_STRING_OPTIONS = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timezone: 'America/New_York',
};
const DATE_LOCALE = 'en-US';
const DATE_SEPARATOR = '-';

const convertToEst = (value) => new Date(value);

const createErgoDateForDisplay = (value) => {
    const ergoDate = convertToEst(value);
    ergoDate.setHours(ergoDate.getHours() + ergoDate.getTimezoneOffset() / 60);
    const splitDate = ergoDate.toLocaleDateString(DATE_LOCALE, DATE_STRING_OPTIONS).replace(/,/g, '').split(' ');
    return [splitDate[1], splitDate[0], splitDate[2]].join(DATE_SEPARATOR);
};

export default createErgoDateForDisplay;
