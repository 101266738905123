import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Formik, Form } from 'formik';

import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';
import gaRatesApi from '../api/gaRates';

import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';

function GARates() {
    // STATES
    const [saved, setSaved] = useState(true);
    const [gaRates, setGaRates] = useState([]);
    const [minimumDate, setMinimumDate] = useState('1956-01-01');
    const [formSettings /*, setFormSettings */] = useState({
        row: null,
        rowIndex: null,
        colKey: [],
        forceSetData: true,
    });
    const [gaForm /*, setGaForm */] = useState({
        rate: 6.0,
        date_start: Date.now(),
        date_ended: null,
        date_created: Date.now(),
        id: null,
    });

    const deleteGA = (value) => {
        const id = value.node.data.id;
        gaRatesApi.removeRate(id).then(() => {
            setSaved(true);
        });
    };

    const [columns /*, setColumns */] = useState([
        {
            headerName: 'G&A Rate (%)',
            field: 'rate',
            editable: true,
            type: 'number',
            width: 200,
        },
        {
            headerName: 'Start Date',
            field: 'date_start',
            editable: true,
            type: 'date',
            width: 200,
        },
        {
            headerName: 'Date Ended',
            field: 'date_ended',
            editable: false,
            type: 'date',
            width: 200,
        },
        {
            headerName: 'Initiated By',
            field: 'user_name',
            editable: false,
            type: 'number',
            width: 200,
        },
        {
            headerName: 'Initiated On',
            field: 'date_created',
            editable: false,
            type: 'date',
            width: 200,
        },
        {
            headerName: 'Del.',
            field: 'delete',
            type: 'symbol',
            editable: false,
            cellRenderer: 'TrashSymbolRenderer',
            onCellClicked: deleteGA,
        },
    ]);

    const saveNewEntry = async (values) => {
        let item = _.cloneDeep(values);
        item.rate = (item.rate / 100).toPrecision(4);
        gaRatesApi.createRate(item).then(() => {
            setSaved(true);
        });
    };

    useEffect(() => {
        if (saved) {
            gaRatesApi.getGARates().then((items) => {
                // Figured out the latest start date, make that the min date
                setMinimumDate(
                    moment(items.data[0].date_start, 'dd, DD MMM YYYY h:m:s GMT').add(1, 'day').format('YYYY-MM-DD')
                );
                // Fix dates as the table is making them 1 day behind (bug)
                let entries = [];
                let i;
                for (i in items.data) {
                    let item = _.cloneDeep(items.data[i]);
                    item.rate = (item.rate * 100).toPrecision(3);
                    item.date_start = moment(items.data[i].date_start, 'dd, DD MMM YYYY h:m:s GMT');
                    item.date_ended =
                        items.data[i].date_ended && moment(items.data[i].date_ended, 'dd, DD MMM YYYY h:m:s GMT');
                    item.date_created = moment(items.data[i].date_created, 'dd, DD MMM YYYY h:m:s GMT');
                    entries.push(item);
                }
                setGaRates(entries);
                setSaved(false);
            });
        }
    }, [saved]);

    return (
        <div>
            <PageHeader title="New G&A Rate" />
            <AdminSubNav />
            <div>
                <div className="Single-layout Companion-form">
                    <Formik
                        initialValues={{
                            rate: gaForm.rate,
                            date_start: gaForm.date_start,
                            id: gaForm.id,
                        }}
                        validationSchema={yup.object().shape({
                            rate: yup.number().required(),
                            date_start: yup.date().required(),
                        })}
                        onSubmit={saveNewEntry}
                    >
                        {({ errors, touched, resetForm }) => (
                            <Form>
                                <LabeledField
                                    label="G&A Rate (%)*"
                                    name="rate"
                                    type="number"
                                    errors={errors}
                                    touched={touched}
                                    min={0.0}
                                    max={100.0}
                                    step={0.01}
                                    required={true}
                                />
                                <LabeledField
                                    label="Start Date*"
                                    name="date_start"
                                    type="date"
                                    errors={errors}
                                    touched={touched}
                                    min={minimumDate}
                                    required={true}
                                />

                                <div className="Space-in-form" />

                                <div className="Form__buttons--Reversed Buttons_format">
                                    <button type="button" onClick={() => resetForm()}>
                                        Cancel
                                    </button>
                                    <button type="submit">Add Rate</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="Table-styling">
                    <EditingTable
                        columns={columns}
                        data={gaRates}
                        animateRows={true}
                        rowIndex={formSettings.rowIndex}
                        colKey={formSettings.colKey}
                        pagingProp={true}
                        addedNewData={formSettings.forceSetData}
                    />
                </div>
            </div>
        </div>
    );
}

export default GARates;
