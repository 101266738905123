// React
import React from 'react';

// Libraries
import { Formik, Form } from 'formik';

// Components
import Modal from '../modals/Modal';
import LabeledField from '../forms/LabeledField';
import errorToast from '../messages/ErrorMessage';

class NoteCardBuilder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            target: props.target,
            target_type: props.target_type,
            notecard_type: props.notecard_type,
            title: props.title,
            mark: props.mark,
            notificationId: props.notificationId,
        };
    }

    openModal = () => {
        this.state.mark(this.state.notificationId);
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    renderEditedFields = (fields) =>
        fields.map((field, index) => {
            if (field.type === 'String') {
                field.type = undefined;
            }
            return [
                <LabeledField
                    label="Field Edited:"
                    key={`field-${index}`}
                    name={`field-${index}`}
                    value={field.field}
                    readOnly
                />,
                <LabeledField
                    label="Previous:"
                    key={`prev-${index}`}
                    name={`prev-${index}`}
                    value={field.previous}
                    component={field.type}
                    readOnly
                />,
                <LabeledField
                    label="Edited To:"
                    key={`curr-${index}`}
                    name={`curr-${index}`}
                    value={field.current}
                    component={field.type}
                    readOnly
                />,
            ];
        });

    render = () => {
        const { open, target, target_type, notecard_type, title } = this.state;

        let notecard;
        switch (target_type) {
            case 'StatementItem':
                if (notecard_type === 'receipt_card') {
                    notecard = <ExpenditureReceiptCard target={target} />;
                }
                break;
            case 'Expenditure':
                if (notecard_type === 'delete_card') {
                    notecard = <ExpenditureDeleteCard target={target} />;
                } else if (notecard_type === 'edit_card') {
                    notecard = (
                        <ExpenditureEditCard
                            target={target}
                            editedFields={this.renderEditedFields(target.oldTarget || [])}
                        />
                    );
                } else if (notecard_type === 'return_card') {
                    notecard = <ExpenditureReturnCard target={target} />;
                }
                break;
            case 'Transaction':
                if (notecard_type === 'delete_card') {
                    notecard = <TransferDeleteCard target={target} />;
                } else if (notecard_type === 'edit_card') {
                    notecard = (
                        <TransferEditCard
                            target={target}
                            editedFields={this.renderEditedFields(target.oldTarget || [])}
                        />
                    );
                }
                break;
            case 'AgreementInvoice':
                if (notecard_type === 'delete_card') {
                    notecard = <AgreementInvoiceDeleteCard target={target} />;
                } else if (notecard_type === 'edit_card') {
                    notecard = (
                        <AgreementInvoiceEditCard
                            target={target}
                            editedFields={this.renderEditedFields(target.oldTarget || [])}
                        />
                    );
                } else if (notecard_type === 'return_card') {
                    notecard = <AgreementInvoiceReturnCard target={target} />;
                }
                break;
            case 'ACRN':
                if (notecard_type === 'delete_card') {
                    notecard = <ModDeleteCard target={target} />;
                } else if (notecard_type === 'edit_card') {
                    notecard = (
                        <ModEditCard target={target} editedFields={this.renderEditedFields(target.oldTarget || [])} />
                    );
                }
                break;
            case 'Mods':
                if (notecard_type === 'delete_card') {
                    notecard = <ModDeleteCard target={target} />;
                } else if (notecard_type === 'edit_card') {
                    notecard = <ModEditCard target={target} />;
                }
                break;
            case 'TravelExpense':
                if (notecard_type === 'return_card') {
                    notecard = <TravelExpenseReturnCard target={target} />;
                } else if (notecard_type === 'delete_card') {
                    notecard = <TravelExpenseDeleteCard target={target} />;
                } else if (notecard_type === 'edit_card') {
                    notecard = <TravelExpenseEditCard target={target} />;
                }
                break;
            default:
                notecard = null;
                errorToast('No notecard for this type!');
        }

        return (
            <div className="NoteCardBuilder">
                <a href="/#" onClick={this.openModal}>
                    {title}
                </a>
                <Modal isOpen={open} className="NoteCardBuilder__modal">
                    <button onClick={this.closeModal} className="align-self-end">
                        <i className="fa fa-window-close" />
                    </button>
                    {notecard}
                </Modal>
            </div>
        );
    };
}

export default NoteCardBuilder;

const TravelExpenseReturnCard = (props) => {
    const { target } = props;

    return (
        <>
            <label className="Card_title">The following travel expense entry has been returned:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Account Number:" name="account_number" readOnly />
                                <LabeledField label="Traveler:" name="traveler" readOnly />
                                <LabeledField label="Start Date:" name="start_date" component="Date" readOnly />
                                <LabeledField label="End Date:" name="end_date" component="Date" readOnly />
                                <LabeledField label="Description:" name="notes" component="textarea" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const TravelExpenseDeleteCard = (props) => {
    const { target } = props;

    return (
        <>
            <label className="Card_title">The following travel expense entry has been deleted:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Account Number:" name="account_number" readOnly />
                                <LabeledField label="Traveler:" name="traveler" readOnly />
                                <LabeledField label="Start Date:" name="start_date" component="Date" readOnly />
                                <LabeledField label="End Date:" name="end_date" component="Date" readOnly />
                                <LabeledField label="Description:" name="notes" component="textarea" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const TravelExpenseEditCard = (props) => {
    const { target } = props;

    return (
        <>
            <label className="Card_title">The following travel expense entry has been edited:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Account Number:" name="account_number" readOnly />
                                <LabeledField label="Traveler:" name="traveler" readOnly />
                                <LabeledField label="Start Date:" name="start_date" component="Date" readOnly />
                                <LabeledField label="End Date:" name="end_date" component="Date" readOnly />
                                <LabeledField label="Description:" name="notes" component="textarea" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const ExpenditureDeleteCard = (props) => {
    const { target } = props;

    return (
        <>
            <label className="Card_title">The following CC entry has been deleted:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Project Name:" name="account.name" readOnly />
                                <LabeledField label="Card Holder:" name="cardHolder" readOnly />
                                <LabeledField
                                    label="Transaction Date:"
                                    name="purchase_date"
                                    component="Date"
                                    readOnly
                                />
                                <LabeledField label="Amount:" name="cost" component="Money" readOnly />
                                <LabeledField
                                    label="Description:"
                                    name="item_description"
                                    component="textarea"
                                    readOnly
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const ExpenditureEditCard = (props) => {
    const { target, editedFields } = props;

    return (
        <>
            <label className="Card_title">The following CC entry has been edited:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Two_column_card">
                                <div className="Note-card-form__note_grid">
                                    <LabeledField label="Project Name:" name="account.name" readOnly />
                                    <LabeledField label="Card Holder:" name="cardHolder" readOnly />
                                    <LabeledField
                                        label="Transaction Date:"
                                        name="purchase_date"
                                        component="Date"
                                        readOnly
                                    />
                                    <LabeledField label="Amount:" name="cost" component="Money" readOnly />
                                    <LabeledField
                                        label="Description:"
                                        name="item_description"
                                        component="textarea"
                                        readOnly
                                    />
                                </div>
                                <div className="Note-card-form__note_grid">
                                    <LabeledField label="Edited By:" name="last_updated_by" readOnly />
                                    <LabeledField label="Date Edited:" name="updated_on" component="Date" readOnly />
                                    {editedFields}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const ExpenditureReceiptCard = (props) => {
    const { target } = props;

    return (
        <>
            <label className="Card_title">
                Finance department has requested the receipt for the following CC entry:
            </label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Card Holder:" name="card_holder" readOnly />
                                <LabeledField
                                    label="Transaction Date:"
                                    name="transaction_date"
                                    component="Date"
                                    readOnly
                                />
                                <LabeledField label="Amount:" name="amount" component="Money" readOnly />
                                <LabeledField label="Description:" name="description" component="textarea" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const ExpenditureReturnCard = (props) => {
    const { target } = props;
    const { id: projectId } = target.account;
    return (
        <>
            <label className="Card_title">Finance department has returned the following CC entry:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField
                                    label="Project Name:"
                                    name="account.name"
                                    readOnly
                                    link={`/projects/${projectId}`}
                                />
                                <LabeledField label="Card Holder:" name="cardHolder" readOnly />
                                <LabeledField
                                    label="Transaction Date:"
                                    name="purchase_date"
                                    component="Date"
                                    readOnly
                                />
                                <LabeledField label="Amount:" name="cost" component="Money" readOnly />
                                <LabeledField
                                    label="Description:"
                                    name="item_description"
                                    component="textarea"
                                    readOnly
                                />
                                <LabeledField label="Returned Note:" name="notes" component="textarea" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const TransferDeleteCard = (props) => {
    const { target } = props;

    return (
        <>
            <label className="Card_title">The following transfer has been deleted:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Project Name:" name="source.name" readOnly />
                                <LabeledField label="Date of Submission:" name="created_on" component="Date" readOnly />
                                <LabeledField label="Transfer Type:" name="type" readOnly />
                                <LabeledField label="Amount:" name="amount" component="Money" readOnly />
                                <LabeledField label="Description:" name="description" component="textarea" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const TransferEditCard = (props) => {
    const { target, editedFields } = props;

    return (
        <>
            <label className="Card_title">The following transfer has been edited:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Two_column_card">
                                <div className="Note-card-form__note_grid">
                                    <LabeledField label="Project Name:" name="source.name" readOnly />
                                    <LabeledField
                                        label="Date of Submission:"
                                        name="created_on"
                                        component="Date"
                                        readOnly
                                    />
                                    <LabeledField label="Transfer Type:" name="type" readOnly />
                                    <LabeledField label="Amount:" name="amount" component="Money" readOnly />
                                    <LabeledField
                                        label="Description:"
                                        name="description"
                                        component="textarea"
                                        readOnly
                                    />
                                </div>
                                <div className="Note-card-form__note_grid">
                                    <LabeledField label="Edited By:" name="last_updater.name" readOnly />
                                    <LabeledField label="Date Edited:" name="updated_on" component="Date" readOnly />
                                    {editedFields}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const AgreementInvoiceDeleteCard = (props) => {
    const { target } = props;

    return (
        <>
            <label className="Card_title">The following invoice has been deleted:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Invoice Number:" name="number" readOnly />
                                <LabeledField label="Project Name:" name="project_name" readOnly />
                                <LabeledField
                                    label="Date of submission:"
                                    name="date_received"
                                    component="Date"
                                    readOnly
                                />
                                <LabeledField label="Amount:" name="total_amount" component="Money" readOnly />
                                <LabeledField label="Description:" name="notes" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const AgreementInvoiceEditCard = (props) => {
    const { target, editedFields } = props;

    return (
        <>
            <label className="Card_title">The following invoice has been edited:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Invoice No.:" name="number" readOnly />
                                <LabeledField label="Project Name:" name="project_name" readOnly />
                                <LabeledField label="Edited by:" name="transaction.last_updater.name" readOnly />
                                <LabeledField
                                    label="Date Edited:"
                                    name="transaction.updated_on"
                                    component="Date"
                                    readOnly
                                />
                                {editedFields}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const AgreementInvoiceReturnCard = (props) => {
    const { target } = props;

    return (
        <>
            <label className="Card_title">Finance department has returned the following invoice entry:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="WBI number:" name="wbi_number" readOnly />
                                <LabeledField label="Project Name:" name="project_name" readOnly />
                                <LabeledField
                                    label="Transaction Date:"
                                    name="date_received"
                                    component="Date"
                                    readOnly
                                />
                                <LabeledField label="Last Update By:" name="transaction.last_updater.name" readOnly />
                                <LabeledField label="Last Update On:" name="updated_on" component="Date" readOnly />
                                <LabeledField label="Returned Note:" name="notes" component="textarea" readOnly />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const ModDeleteCard = (props) => {
    const { target } = props;
    return (
        <>
            <label className="Card_title">The following MOD has been deleted:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="Mod No.:" name="mod_number" readOnly />
                                <LabeledField label="Project Name:" name="project_name" readOnly />
                                <LabeledField
                                    label="Funds Allocated to Project:"
                                    name="amount"
                                    component="Money"
                                    readOnly
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const ModEditCard = (props) => {
    const { target, editedFields } = props;

    return (
        <>
            <label className="Card_title">The following MOD has been edited:</label>
            <div className="Note-card-form">
                <Formik initialValues={target} onSubmit={() => {}}>
                    {() => (
                        <Form>
                            <div className="Note-card-form__note_grid">
                                <LabeledField label="MOD No.:" name="mod_number" readOnly />
                                <LabeledField label="Project Name:" name="project_name" component="textarea" readOnly />
                                <LabeledField label="Edited by:" name="last_update_by" readOnly />
                                <LabeledField label="Date Edited:" name="updated_on" component="Date" readOnly />
                                {editedFields}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};
