import React from 'react';

import Fieldset from '../Fieldset';
import LabeledField from '../../components/forms/LabeledField';
import { confirm } from '../../components/modals/ConfirmModal';
import { history } from '../../store/store';
import { projectSuffixLength } from '../../utils/ProjectUtils';

import ProjectsApi from '../../api/projects';

class ProjectIntroSection extends React.Component {
    constructor(props) {
        super(props);

        this.handleStatusChange = this.handleStatusChange.bind(this);

        this.state = {
            cpos: null,
            selectedCpo: null,
            selectedCpos: null,
            cpoParagraphs: null,
            parentOptions: [],
            selectedParent: null,
            parentProjects: [],
            projectTypes: this.props.projectTypeOptions || [],
            selectedService: this.props.values.service_type_id || null,
        };
    }

    async componentDidMount() {
        const { data: parentProjects } = await ProjectsApi.fetchParentProjectNames();

        const parentOptions = [];
        parentProjects.map((parent) => {
            parentOptions.push({
                label: parent.number + ' - ' + parent.name,
                value: {
                    parent_name: parent.name,
                    parent_number: parent.number,
                    parent_cpo_id: parent.cpo_id,
                    parent_service_id: parent.service_type_id,
                },
            });
            return null;
        });

        let parent = null;
        if (this.props.values.category === 'sub-project') {
            const projectNumber = this.props.values.number;

            // Slice the projectNumber to remove the sub-project suffix
            let parentProjectNumber = projectNumber.split('-').slice(0, -1).join('-');
            parent = parentOptions.filter((option) => option.value.parent_number === parentProjectNumber);
        }

        if (parent?.value) {
            this.props.values.parent_name = parent.value.parent_name;
        }

        let projectTypes = [];
        if (this.props.values.service_type_id) {
            projectTypes = this.props.projectTypeOptions.filter(
                (e) => e.service_type_id === this.props.values.service_type_id && e.disabled !== true
            );
        }

        this.setState({
            parentOptions,
            selectedParent: parent,
            projectTypes,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ cpos: nextProps.cpos });
    }

    handleStatusChange = async (event, setFieldValue) => {
        const { project, financialInformation, values } = this.props;
        if (event.label === 'terminated' || event.label === 'archived') {
            let message = '';
            if (financialInformation.remaining_funds !== 0 || financialInformation.remaining_funds !== 0.0) {
                message = (
                    <div>
                        <p>Remaining funds must be transferred in order to archive or terminate this project.</p>
                        <p>
                            <em>
                                If you press &quotOk&quot you will be redirected to the transfer funds page. If you have
                                changes they will not be saved.
                            </em>
                        </p>
                    </div>
                );
                const confirmed = await confirm({
                    text: message,
                    acceptText: 'Ok',
                    rejectText: 'Cancel',
                });

                if (confirmed) {
                    history.push(`/projects/${project.id}/transfer-funds`);
                } else {
                    setFieldValue('status', values.status);
                }
            } else {
                message = (
                    <div>
                        <p>There are no funds in this project and is safe to archive or terminate.</p>
                    </div>
                );
                const confirmed = await confirm({
                    text: message,
                    acceptText: 'Ok',
                    rejectText: 'Cancel',
                });
                if (!confirmed) {
                    setFieldValue('status', values.status);
                } else {
                    setFieldValue('status', event.value);
                }
            }
        } else {
            setFieldValue('status', event.value);
        }
    };

    filterCpos(e) {
        let selectedCpos = [];
        if (this.state.cpos) {
            this.state.cpos.forEach((cpo) => {
                if (cpo.pia_id === e.value) {
                    selectedCpos.push(cpo);
                }
            });
        }
        this.setState({ selectedCpos });
    }

    render() {
        const {
            values,
            errors,
            touched,
            isEditing,
            cpos,
            piaOptions,
            statusOptions,
            officeSymbolOptions,
            projectTypeOptions,
            setFieldValue,
            isNew,
        } = this.props;
        const { projectTypes } = this.state;
        const isParentProject = values.category === 'project';
        const isSubProject = values.category === 'sub-project';
        const isNewProject = values.isNew || isNew;
        let requiredAsterisk = isEditing ? '*' : '';
        let cpoOptions = [];
        let parent = null;
        let parentOptions = this.state.parentOptions.sort((a, b) => a.label.localeCompare(b.label));
        let serviceTypeOptions = this.props.serviceTypeOptions;
        let isAdmin = false;
        let user = JSON.parse(localStorage.getItem('WBI.USER'));

        if (user !== null && user.site_role === 'Admin') {
            isAdmin = true;
        }

        if (serviceTypeOptions) {
            serviceTypeOptions = serviceTypeOptions.filter((e) => e.disabled !== true);
        }

        if (this.state.selectedCpos) {
            this.state.selectedCpos.forEach((cpo) => {
                cpoOptions.push({ label: cpo.number, value: cpo.id });
            });
        } else if (cpos) {
            let selectedCpoValues = cpos.filter((cpo) => cpo.pia_id === values.pia);
            if (selectedCpoValues.length > 0) {
                selectedCpoValues.forEach((selectedValue) => {
                    cpoOptions.push({ label: selectedValue.number, value: selectedValue.id });
                });
            }
        }

        if (!isNewProject && this.state.selectedParent) {
            parent = this.state.selectedParent[0];
            if (parent) {
                values.parent_name = parent.value.parent_number + ' - ' + parent.value.parent_name;
                values.subproject = true;
            }
        } else if (isNewProject && isSubProject && this.state.selectedParent) {
            values.service_type_id = serviceTypeOptions.find(
                (option) => option.value === this.state.selectedParent.value.parent_service_id
            );
        }

        const renderParentProjectNameField = () =>
            isNewProject ? (
                <LabeledField
                    label={'Parent Project Number - Name' + requiredAsterisk}
                    name="parent_name"
                    component="select"
                    options={parentOptions}
                    errors={errors}
                    touched={touched}
                    readOnly={!isEditing}
                    onChange={(e) => {
                        this.setState({
                            selectedParent: e,
                        });
                        setFieldValue('parent_name', e.value.parent_name);
                        setFieldValue('cpo_id', e.value.parent_cpo_id);
                    }}
                    value={this.state.selectedParent}
                />
            ) : (
                <LabeledField
                    label="Parent Project Number - Name"
                    name="parent_name"
                    type="text"
                    errors={errors}
                    touched={touched}
                    readOnly
                />
            );

        return (
            <div>
                <Fieldset title="Project Introduction">
                    <LabeledField
                        label={'Project Name' + requiredAsterisk}
                        name="name"
                        type="text"
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                    />
                    {isSubProject && renderParentProjectNameField()}
                    <LabeledField
                        label={'Category' + requiredAsterisk}
                        name="category"
                        component="Radio"
                        currentValue={values.category}
                        options={[
                            { label: 'Project', value: 'project' },
                            { label: 'Sub-Project', value: 'sub-project' },
                        ]}
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing || (values.number && values.number.length > 0)}
                    />
                    {isParentProject ? (
                        <>
                            <LabeledField
                                label={'PIA' + requiredAsterisk}
                                name="pia"
                                component="select"
                                options={piaOptions}
                                errors={errors}
                                touched={touched}
                                readOnly={!isEditing}
                                onChange={(e) => {
                                    this.filterCpos(e);
                                    setFieldValue('pia', e);
                                }}
                            />
                            <LabeledField
                                label={'CPO' + requiredAsterisk}
                                name="cpo_id"
                                component="select"
                                options={cpoOptions}
                                errors={errors}
                                touched={touched}
                                readOnly={!isEditing}
                                onChange={(e) => {
                                    this.props.filterCpoParagraphs(e);
                                    setFieldValue('cpo_id', e);
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <LabeledField
                                label={'PIA' + requiredAsterisk}
                                name="pia"
                                component="select"
                                options={piaOptions}
                                errors={errors}
                                touched={touched}
                                readOnly
                                onChange={(e) => {
                                    this.filterCpos(e);
                                    setFieldValue('pia', e);
                                }}
                            />
                            <LabeledField
                                label={'CPO' + requiredAsterisk}
                                name="cpo_id"
                                component="select"
                                options={cpoOptions}
                                errors={errors}
                                touched={touched}
                                readOnly
                                onChange={(e) => {
                                    this.props.filterCpoParagraphs(e);
                                    setFieldValue('cpo_id', e);
                                }}
                            />
                        </>
                    )}
                    <LabeledField
                        label={'Status' + requiredAsterisk}
                        name="status"
                        component="select"
                        options={statusOptions}
                        errors={errors}
                        touched={touched}
                        readOnly={!isEditing}
                        onChange={(e) => {
                            this.handleStatusChange(e, setFieldValue);
                        }}
                    />
                    <LabeledField
                        label={'Service Type' + requiredAsterisk}
                        name="service_type_id"
                        component="select"
                        options={serviceTypeOptions}
                        errors={errors}
                        touched={touched}
                        readOnly={
                            !((isAdmin && isParentProject && isEditing) || (values && isParentProject && isNewProject))
                        }
                        onChange={(e) => {
                            this.props.selectInnovationServices(e);
                            setFieldValue('service_type_id', e);
                            this.setState({
                                selectedService: e.value,
                                projectTypes: projectTypeOptions.filter((el) => el.service_type_id === e.value),
                            });
                        }}
                    />
                    {!isNewProject && (
                        <>
                            <LabeledField
                                label="Office Symbol"
                                name="office_symbol_id"
                                component="select"
                                options={officeSymbolOptions}
                                errors={errors}
                                touched={touched}
                                readOnly={!isEditing}
                                onChange={(e) => {
                                    setFieldValue('office_symbol_id', e);
                                }}
                            />
                            <LabeledField
                                label="Primary Project Type"
                                name="primary_type_id"
                                component="select"
                                options={projectTypes}
                                errors={errors}
                                touched={touched}
                                readOnly={!isEditing}
                            />
                            <LabeledField
                                label="Secondary Project Type"
                                name="secondary_type_id"
                                component="select"
                                options={projectTypes}
                                errors={errors}
                                touched={touched}
                                readOnly={!isEditing}
                            />
                        </>
                    )}
                </Fieldset>
            </div>
        );
    }
}

export default ProjectIntroSection;
