import React from 'react';
import ProjectsApi from '../../../api/projects';
import UsersApi from '../../../api/users';
import errorToast from '../../../components/messages/ErrorMessage';
import Table from '../../../components/tables/Table';
import ExportCSVButton from '../../../components/buttons/ExportCSVButton';

class FundsTransferSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            project: null,
            rowData: null,
            row: null,
            rowIndex: null,
            colKey: '',
            exrowDate: null,
            users: null,
            exrow: null,
            exrowIndex: null,
            excolKey: null,
            forceSetData: false,
        };
        this.onTableReady = this.onTableReady.bind(this);
    }

    async componentDidMount() {
        const projectId = this.props.data.id;
        this.updateData(projectId);
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data.id !== this.props.data.id) {
            this.updateData(nextProps.data.id);
        }
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    async updateData(projectId) {
        try {
            const { data: project } = await ProjectsApi.fetchProject(projectId);
            const { data: users } = await UsersApi.getUsers();
            const { data: projectTransactions } = await ProjectsApi.fetchProjectTransactions(projectId);

            let transfers = [];
            let projectTransfers = projectTransactions.filter((transaction) => {
                let transactionOutput = null;
                if (transaction.source_type === 'Mod') {
                    transaction.type = 'Mod';
                    transactionOutput = transaction;
                } else if (
                    transaction.source_type === 'Project' &&
                    transaction.source_type === transaction.destination_type
                ) {
                    transaction.type = 'Project to Project';
                    transactionOutput = transaction;
                } else if (transaction.source_type === 'ResidualFund' && transaction.destination_type === 'Project') {
                    transaction.type = 'Residual fund to Project';
                    transactionOutput = transaction;
                } else if (transaction.source_type === 'Project' && transaction.destination_type === 'ResidualFund') {
                    transaction.type = 'Project to Residual fund';
                    transactionOutput = transaction;
                }
                return transactionOutput;
            });
            projectTransfers.forEach((t) => {
                if (t.destination_type !== null) {
                    users.forEach((u) => {
                        if (u.id === t.initiator_id) {
                            t.initiator = u;
                        }
                    });
                    transfers.push(t);
                }
            });

            this.setState({
                project,
                rowData: transfers,
                users,
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        const { rowData } = this.state;
        console.log('row data ', rowData);
        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="fundsTransferReports" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div>
                    <Table
                        columns={[
                            { headerName: 'Transfer Type', field: 'type', type: 'longtext' },
                            { headerName: 'Initiated By', field: 'initiator.name', type: 'name' },
                            { headerName: 'Date', field: 'created_on', type: 'date' },
                            { headerName: 'Amount', field: 'amount', type: 'money', filter: true },
                            { headerName: 'Status', field: 'status', type: 'status', filter: true },
                        ]}
                        data={rowData}
                        onTableReady={this.onTableReady}
                        sortable={true}
                        filter={true}
                    />
                </div>
            </div>
        );
    }
}
export default FundsTransferSummary;
