import React, { useState } from 'react';
import Modal from '../modals/Modal';

const NotesSymbolModal = (props) => {
    const { children } = props;
    const [openModal, setOpenModal] = useState(false);

    const closeNotesModal = () => {
        setOpenModal(false);
    };

    const openNotesModal = () => {
        setOpenModal(true);
    };

    return (
        <div className="NotesSymbol">
            <i className="fa fa-sticky-note" aria-hidden="true" onClick={openNotesModal} />
            <Modal isOpen={openModal} handleClose={closeNotesModal} shouldCloseOnOverlayClick {...props}>
                <button onClick={closeNotesModal} className="align-self-end">
                    <i className="fa fa-window-close" />
                </button>
                <div className="notes">{children}</div>
            </Modal>
        </div>
    );
};

export default NotesSymbolModal;
