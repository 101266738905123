import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import _find from 'lodash/find';
import yup from '../yup';
import { confirm } from '../components/modals/ConfirmModal';

import AdminSubNav from './AdminSubNav';
import PageHeader from '../components/heading/PageHeader';
import EditingTable from '../components/tables/EditingTable';
import LabeledField from '../components/forms/LabeledField';

import ServicesApi from '../api/services';
import ProjectTypesApi from '../api/projectTypes';
import UsersApi from '../api/users';
import errorToast from '../components/messages/ErrorMessage';

class ServicesPage extends Component {
    constructor(props) {
        super(props);

        this.onRowValueChanged = this.onRowValueChanged.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.getUserOptions = this.getUserOptions.bind(this);
        this.getSiteRoleOptions = this.getSiteRoleOptions.bind(this);

        this.state = {
            services: [],
            users: null,
            projectTypes: null,
            forceSetData: false,
            row: null,
            rowIndex: null,
            colKey: '',
            rowData: this.getRowData,
            userOptions: [],
            siteRoleOptions: [],
        };
    }

    componentDidMount = async () => {
        try {
            const { data: services } = await ServicesApi.getServices();
            const { data: users } = await UsersApi.getUsers();
            const { data: projectTypes } = await ProjectTypesApi.getProjectTypes();

            if (services) {
                services.forEach((service) => {
                    if (service.director_id) {
                        let user = _find(users, { id: service.director_id });

                        if (user) {
                            service.director_name = user.name;
                        }
                    }
                });
            }

            let userOptions = this.getUserOptions(users);
            let siteRoleOptions = this.getSiteRoleOptions(userOptions);

            this.setState({
                services: services.filter((e) => e.disabled !== true),
                users,
                userOptions,
                siteRoleOptions,
                projectTypes: projectTypes.filter((e) => e.disabled !== true),
            });
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    onRowValueChanged(event) {
        let serviceToUpdate = event.node.data;
        let attributes = {
            name: serviceToUpdate.name,
            letter_identifier: serviceToUpdate.letter_identifier,
            location: serviceToUpdate.location,
        };
        if (serviceToUpdate.director_name) {
            let user = _find(this.state.users, {
                name: serviceToUpdate.director_name,
            });
            if (user) attributes.director_id = user.id;
        }

        if (serviceToUpdate) {
            ServicesApi.updateService(serviceToUpdate.id, attributes)
                .then(() => {
                    let newUserOptions = this.getUserOptions(this.state.users);
                    let newSiteRoleOptions = this.getSiteRoleOptions(newUserOptions);

                    this.setState(
                        {
                            forceSetData: true,
                            userOptions: newUserOptions,
                            siteRoleOptions: newSiteRoleOptions,
                            row: '',
                            rowIndex: '',
                            colKey: '',
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    }

    getRowData(event) {
        this.setState({
            row: event.node.data,
            rowIndex: event.node.rowIndex,
            colKey: 'name',
        });
    }

    getUserOptions(users) {
        let userOptions = [];
        if (users) {
            users.map((user) => {
                if (user.site_role.includes('Service')) {
                    userOptions.push({ label: user.name, value: user.id });
                    userOptions.sort((a, b) => {
                        let aLabel = a.label.toUpperCase();
                        let bLable = b.label.toUpperCase();
                        if (aLabel > bLable) {
                            return 1;
                        }
                        return -1;
                    });
                }

                return null;
            });
        }
        return userOptions;
    }

    getSiteRoleOptions(userOptions) {
        let siteRoleOptions = [];
        if (userOptions.length > 0) {
            userOptions.forEach((user) => {
                siteRoleOptions.push(user.label);
            });
        }
        return siteRoleOptions;
    }

    disableType = async (event) => {
        const confirmed = await confirm({
            text: 'Are you sure you want to Disable this Service Type?',
        });

        let typeToDisable = event.node.data;
        if (confirmed) {
            ServicesApi.updateService(typeToDisable.id, {
                disabled: true,
            })
                .then((response) => {
                    let services = this.state.services.filter((e) => e.id !== response.data.id);

                    let projectTypesToDisable = [];
                    this.state.projectTypes.forEach((e) => {
                        if (e.service_type_id === typeToDisable.id) {
                            e.disabled = true;
                            projectTypesToDisable.push(e);
                        }
                    });

                    if (projectTypesToDisable.length > 0) {
                        ProjectTypesApi.updateProjectTypes(projectTypesToDisable)
                            .then(() => {})
                            .catch((errorMessage) => {
                                this.setState({ errorMessage });
                            });
                    }

                    this.setState(
                        {
                            forceSetData: true,
                            services,
                        },
                        () => {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    handleSubmit = (values, actions) => {
        if (values) {
            ServicesApi.createService({
                name: values.name,
                letter_identifier: values.letter_identifier,
                director_id: values.director_name.value,
                location: values.location,
                disabled: false,
            })
                .then((response) => {
                    let newServices = this.state.services;

                    response.data.director_name = values.director_name.label;
                    newServices.push(response.data);

                    let newUserOptions = this.getUserOptions(this.state.users);
                    let newSiteRoleOptions = this.getSiteRoleOptions(newUserOptions);

                    this.setState(
                        {
                            services: newServices,
                            forceSetData: true,
                            userOptions: newUserOptions,
                            siteRoleOptions: newSiteRoleOptions,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                    actions.resetForm();
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }
    };

    render() {
        let columns = [
            {
                headerName: 'Service Name',
                field: 'name',
                editable: true,
                sort: 'desc',
            },
            {
                headerName: 'Letter Identifier',
                field: 'letter_identifier',
                editable: true,
            },
            {
                headerName: 'Director',
                field: 'director_name',
                type: 'name',
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.siteRoleOptions,
                },
            },
            {
                headerName: 'Location',
                field: 'location',
                editable: true,
            },
            {
                headerName: 'Edit',
                field: 'edit',
                type: 'symbol',
                editable: false,
                cellRenderer: 'EditSymbolRenderer',
                onCellClicked: this.getRowData,
            },
            {
                headerName: 'Del.',
                field: 'disable',
                type: 'symbol',
                editable: false,
                cellRenderer: 'TrashSymbolRenderer',
                onCellClicked: this.disableType,
            },
        ];
        return (
            <div>
                <PageHeader title="Services" />
                <AdminSubNav />
                <div>
                    <div className="Single-layout Companion-form">
                        <Formik
                            initialValues={{
                                name: '',
                                director_name: '',
                                location: '',
                                letter_identifier: '',
                            }}
                            validationSchema={yup.object().shape({
                                name: yup.string().required(),
                                director_name: yup.string().required(),
                                location: yup.string().required(),
                                letter_identifier: yup.string().required(),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({ errors, touched, resetForm, setFieldValue }) => (
                                <Form>
                                    <LabeledField
                                        label="Service Name*"
                                        name="name"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <LabeledField
                                        label="Service Director*"
                                        name="director_name"
                                        component="select"
                                        options={this.state.userOptions}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            setFieldValue('director_name', e);
                                        }}
                                    />
                                    <LabeledField
                                        label="Location*"
                                        name="location"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                    {/* Customer requested this field be renamed to Letter Identifier and increase the character limit */}
                                    <LabeledField
                                        label="Letter Identifier*"
                                        name="letter_identifier"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />

                                    <div className="Form__buttons--Reversed Buttons_format">
                                        <button type="button" onClick={() => resetForm()}>
                                            Cancel
                                        </button>
                                        <button type="submit">Add Service</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="Table-styling">
                        <EditingTable
                            columns={columns}
                            data={this.state.services}
                            rowIndex={this.state.rowIndex}
                            colKey={this.state.colKey}
                            rowValueChanged={this.onRowValueChanged}
                            sizeToFit={true}
                            addedNewData={this.state.forceSetData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ServicesPage;
