import React from 'react';

import { Route } from 'react-router-dom';

import PageHeader from '../components/heading/PageHeader';
import AdminSubNav from '../admin/AdminSubNav';
import VendorsPage from './VendorsPage';

const VendorsAndAgreementsPage = () => (
    <div>
        <PageHeader title="Vendors" />
        <AdminSubNav />
        <Route exact path="/admin/vendorsAndAgreements" component={VendorsPage} />
    </div>
);

export default VendorsAndAgreementsPage;
