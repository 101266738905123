// React
import React from 'react';

// Libraries
import { AgGridReact } from 'ag-grid-react';
import deepEqual from 'deep-eql';
import isPresent from 'is-present';
import moment from 'moment';

// Utils
import tableColumnsTypes from './utils/tableColumnsTypes';

// Symbol Renderers
import TimerSymbolRenderer from '../symbols/TimerSymbolRenderer';
import TrashSymbolRenderer from '../symbols/TrashSymbolRenderer';
import DateEditor from '../symbols/DateEditor';
import EditSymbolRenderer from '../symbols/EditSymbolRenderer';
import StatusSymbolRenderer from '../symbols/StatusSymbolRenderer';
import NotesSymbolRenderer from '../symbols/NotesSymbolRenderer';
import BooleanRenderer from '../symbols/BooleanRenderer';
import DownloadLinkRenderer from '../symbols/DownloadLinkRenderer';
import DropboxLink from '../dropbox/DropboxLink';
import ExternalLinkSymbolRenderer from '../symbols/ExternalLinkSymbolRender';
import LinkRenderer from '../symbols/LinkRenderer';
import NotificationsLinkRenderer from '../symbols/NotificationsLinkRenderer';

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.exportDataAsCsv = this.exportDataAsCsv.bind(this);
        const columns = this.getColumnMappings(props);
        this.state = {
            columns,
            tableRef: React.createRef(),
            headerY: 0,
            stickyHeader: !!props.stickyHeader,
            classes: '',
            pageSize: props.pageSize || 10,
            tableId: props.tableId || '',
        };
        // this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
    }

    // mount scroll listener for sticky header
    componentDidMount() {
        if (this.state.stickyHeader) {
            window.addEventListener('scroll', this.handleScroll, true);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const columns = this.getColumnMappings(nextProps);
        this.setState({ columns });
    }

    componentDidUpdate() {
        if (this.api && this.props.forceSetData) {
            this.api.setRowData(this.props.data);
        }
    }

    // unmount scroll listener for sticky header
    componentWillUnmount() {
        if (this.state.stickyHeader) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    onGridReady = (params) => {
        this.api = params.api;
        this.columnApi = params.columnApi;
        this.resizeGrid();

        // Get Y position of header for sticky
        const { tableRef } = this.state;
        this.setState({ headerY: tableRef.current.offsetParent.offsetTop });

        if (this.props.callerGridReady && typeof this.props.callerGridReady === 'function') {
            this.props.callerGridReady(params);
        }
        if (typeof this.props.onAfterGridReady === 'function') {
            this.props.onAfterGridReady();
        }

        if (isPresent(this.props.initialSelection)) {
            this.api.forEachNode((node) => {
                if (this.props.initialSelection.find((item) => deepEqual(item, node.data))) {
                    node.setSelected(true);
                }
            });
        }

        if (this.props.onTableReady && typeof this.props.onTableReady === 'function') {
            params.exportDataAsCsv = this.exportDataAsCsv;
            this.props.onTableReady(params, this.props.tableId);
        }
    };

    onPageSizeChanged = (event) => {
        this.setState({ pageSize: event.target.value }, () => {
            this.api.paginationSetPageSize(this.state.pageSize);
        });
    };

    getColumnMappings(props) {
        const columns = props.columns.map((col) => col);
        return columns;
    }

    resizeGrid = () => {
        if (this.api) {
            this.api.resetRowHeights();
            this.api.deselectAll();

            if (this.props.sizeToFit) {
                this.api.sizeColumnsToFit();
            }
        }
    };

    // Sticky header if the scroll goes past the header
    handleScroll = () => {
        if (!this.state.stickyHeader) {
            return;
        }
        const { classes } = this.state;
        if (window.pageYOffset > this.state.headerY && classes !== 'sticky') {
            this.setState({ classes: 'sticky' });
        } else if (window.pageYOffset < this.state.headerY && classes === 'sticky') {
            this.setState({ classes: '' });
        }
    };

    exportDataAsCsv(nameOfExport) {
        const filename = nameOfExport + '-' + moment().format('YYYYMDDHHmmss');
        this.api.exportDataAsCsv({ fileName: filename });
    }

    render() {
        let { data, style, colKey, rowIndex, disablePaging, customGridSize, ...props } = this.props;
        const { columns, pageSize } = this.state;
        let rowData = data;
        let colData = columns;

        let pageSelector = null;
        let hasPageSelector = this.props.hasPageSelector || !this.props.disablePaging;
        if (hasPageSelector === undefined) {
            hasPageSelector = true;
        }

        if (hasPageSelector) {
            pageSelector = (
                <div className="Table_page_size">
                    <span className="label">Page Size:</span>
                    <select value={pageSize} onChange={this.onPageSizeChanged}>
                        {[10, 25, 50, 100, '10', '25', '50', '100'].indexOf(pageSize) === -1 && (
                            <option value={pageSize}>{pageSize}</option>
                        )}
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            );
        }

        if ((rowIndex === 0 || rowIndex) && colKey) {
            this.api.setFocusedCell(rowIndex, 'edit');
            this.api.startEditingCell({
                rowIndex,
                colKey,
            });
        }

        if (!rowData || rowData.length === 0) {
            rowData = [];
        }

        return (
            <div className="Table_wrap">
                <div id="table-fixed-scrollbar">
                    <div />
                </div>
                <div className="Ag-theme-blue" ref={this.state.tableRef}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colData}
                        suppressClickEdit={true}
                        suppressScrollOnNewData={true}
                        rowDragManaged={true}
                        onGridReady={this.onGridReady}
                        onViewportChanged={this.resizeGrid}
                        onColumnEverythingChanged={this.resizeGrid}
                        onRowDataChanged={this.resizeGrid}
                        headerHeight={48}
                        domLayout="autoHeight"
                        pagination={!disablePaging}
                        paginationPageSize={this.state.pageSize}
                        columnTypes={tableColumnsTypes}
                        frameworkComponents={{
                            LinkRenderer,
                            TimerSymbolRenderer,
                            TrashSymbolRenderer,
                            BooleanRenderer,
                            DownloadLinkRenderer,
                            EditSymbolRenderer,
                            ExternalLinkSymbolRenderer,
                            NotesSymbolRenderer,
                            StatusSymbolRenderer,
                            DropboxLink,
                            DateEditor,
                            NotificationsLinkRenderer,
                            ...props.frameworkComponents,
                        }}
                        defaultColDef={{
                            width: 150,
                            editable: true,
                            resizable: true,
                            autoHeight: true,
                            sortable: true,
                            filter: true,
                        }}
                        {...props}
                    />
                    {pageSelector}
                </div>
            </div>
        );
    }
}

export default Table;
