import api from './api';
import endpoints from './endpoints';

// TODO: [ERGO-1226] remove endpoints subclassing in agreement routes
export default {
    setAgreementStatus(id, data) {
        return api.patch(`${endpoints.AGREEMENTS}/${id}/status`, data, 'Error setting agreement status');
    },

    getAllAgreement() {
        return api.get(endpoints.AGREEMENTS, 'Error getting agreements');
    },

    createAgreement(data) {
        return api.post(endpoints.AGREEMENTS, data, 'Error creating agreement');
    },

    addAgreement(id, data) {
        return api.put(`${endpoints.VENDORS}/${id}/agreement`, data, 'Error adding Agreement');
    },

    getAgreementById(id) {
        return api.get(`${endpoints.AGREEMENTS}/${id}`, 'Error getting agreement by ID');
    },

    patchAgreementById(id, data) {
        return api.patch(`${endpoints.AGREEMENTS}/${id}`, data, 'Error patching an agreement by ID');
    },

    updateAgreementById(id, data) {
        return api.put(`${endpoints.AGREEMENTS}/${id}`, data, 'Error updating an agreement by ID');
    },

    addAmendmentForAgreementById(id, data) {
        return api.put(`${endpoints.AGREEMENTS}/${id}/amendment`, data, 'Error adding amendment to an agreement by ID');
    },

    deleteAgreementById(id) {
        return api.delete(`${endpoints.AGREEMENTS}/${id}`, 'Error deleting an agreement by ID');
    },

    deleteAmendmentById(agreementId, id) {
        return api.delete(
            `${endpoints.AGREEMENTS}/${agreementId}/amendments/${id}`,
            'Error deleting an amendment by ID'
        );
    },

    updateAmendmentById(agreementId, amendmentId, data) {
        return api.put(
            `${endpoints.AGREEMENTS}/${agreementId}/amendments/${amendmentId}`,
            data,
            'Error updating amendment by ID'
        );
    },

    getAgreementsByProjects(projectID) {
        return api.get(`${endpoints.AGREEMENTS}/project/${projectID}`, 'Error getting an agreement from project by ID');
    },

    getAgreementsByAgreement(agreement) {
        return api.get(`${endpoints.AGREEMENTS}/agreement/${agreement}`);
    },

    lockAgreementById(agreementId, data) {
        return api.put(`${endpoints.AGREEMENTS}/${agreementId}/lock`, data, 'Error locking agreement by ID');
    },
};
