import React, { Component, Fragment } from 'react';

import { shortDate } from '../../utils/utils';
import ErgoMoney from '../../utils/ErgoMoney';

class ExpenseApprovalCards extends Component {
    render() {
        return this.props.approvals.map((item, index) => (
            <Fragment key={index}>
                <div className="Card-styling">
                    <h6 className="Table_label">{item.typeOfApproval}</h6>
                    <table className="Approval_table" key={index}>
                        {item.typeOfApproval === 'Expenditure Approval Request' && (
                            <tbody>
                                <tr>
                                    <td className="Table_label">Type:</td>
                                    <td>{item.type}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">CC Type:</td>
                                    <td>{item.cc_type}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">Initiated By:</td>
                                    <td>{item.initiator_name}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">Date:</td>
                                    <td>{shortDate(item.purchase_date)}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">Cost:</td>
                                    <td>{new ErgoMoney(item.cost).getStringAmount()}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">Store:</td>
                                    <td>{item.store}</td>
                                </tr>
                                <tr>
                                    <td className="Table_label">Description:</td>
                                    <td>{item.item_description}</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                    <div className="Form__buttons--Reversed Card-buttons">
                        <button type="button" onClick={() => this.props.reject(item.id, index, item.typeOfApproval)}>
                            Reject
                        </button>
                        <button type="button" onClick={() => this.props.approve(item.id, index, item.typeOfApproval)}>
                            Approve
                        </button>
                    </div>
                </div>
            </Fragment>
        ));
    }
}

export default ExpenseApprovalCards;
