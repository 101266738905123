import currency from 'currency.js';

class ErgoMoney {
    constructor(number) {
        this.currencyAmount = currency(number);
    }

    getNumberAmount() {
        return this.currencyAmount.value;
    }

    getStringAmount() {
        return this.currencyAmount.format(true);
    }

    add(number) {
        this.currencyAmount = this.currencyAmount.add(number);
        return this;
    }

    subtract(number) {
        this.currencyAmount = this.currencyAmount.subtract(number);
        return this;
    }

    multiply(number) {
        this.currencyAmount = this.currencyAmount.multiply(number);
        return this;
    }

    divide(number) {
        this.currencyAmount = this.currencyAmount.divide(number);
        return this;
    }

    sum(numbers) {
        this.currencyAmount = numbers.reduce(
            (sum, currentValue) => (sum = currency(sum).add(currentValue)),
            this.getNumberAmount()
        );
        return this;
    }

    greaterThan(number) {
        return this.currencyAmount.value > currency(number).value;
    }

    lessThan(number) {
        return this.currencyAmount.value < currency(number).value;
    }

    equalTo(number) {
        return this.currencyAmount.value === currency(number).value;
    }

    greaterThanOrEqualTo(number) {
        return this.currencyAmount.value >= currency(number).value;
    }

    lessThanOrEqualTo(number) {
        return this.currencyAmount.value <= currency(number).value;
    }
}

ErgoMoney.prototype.toString = function ergoMoneyToString() {
    return currency(this.currencyAmount.value).format(true);
};

export default ErgoMoney;
