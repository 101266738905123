import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Modal from '../../components/modals/Modal';

export const UpdatePerDiemModal = (props) => {
    const { perDays, showModal, closeModal, updatePerDiems } = props;
    const [perDiems, setPerDiems] = useState([]);

    useEffect(() => {
        const newPerDiems = [];
        for (let i = 0; i < perDays.length; i++) {
            newPerDiems.push(perDays[i].per_diem_rate);
        }
        setPerDiems(newPerDiems);
    }, [perDays]);

    const changeValue = (e, index) => {
        const newPerDiems = _.clone(perDiems);
        newPerDiems[index] = parseFloat(e.target.value);
        setPerDiems(newPerDiems);
    };

    return (
        <>
            <div className="Modal-cmd-container">
                <Modal
                    isOpen={showModal}
                    contentLabel="Update itinerary modal"
                    handleClose={() => {
                        closeModal(false);
                    }}
                    className="Perdiem-modal"
                >
                    <button
                        className="Modal-close-btn "
                        onClick={() => {
                            closeModal(false);
                        }}
                    >
                        <i className="fa fa-window-close" />
                    </button>
                    <div className="Perdiems-update-table">
                        <h2>Update Per Diems Manually</h2>
                        <form>
                            <table>
                                <thead>
                                    <tr>
                                        {perDays.map((item, index) => (
                                            <th key={index} className="Headers">
                                                {item.date}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {perDiems.map((value, index) => (
                                            <td key={index}>
                                                <div>
                                                    <input
                                                        name="perDiems[]"
                                                        onChange={(e) => {
                                                            changeValue(e, index);
                                                        }}
                                                        type="number"
                                                        value={value}
                                                    />
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                    <div className="Modal-buttons">
                        <button
                            className="text-align-left"
                            onClick={() => {
                                updatePerDiems(perDiems);
                                closeModal(false);
                            }}
                        >
                            Update Rates
                        </button>
                        <button
                            className="text-align-left"
                            onClick={() => {
                                closeModal(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default UpdatePerDiemModal;
