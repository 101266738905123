import React, { Component } from 'react';
import Select from 'react-select';

import ErgoMoney from '../../utils/ErgoMoney';

import ServicesApi from '../../api/services';
import ProjectsApi from '../../api/projects';
import HelpModal from '../../components/modals/HelpModal';
import errorToast from '../../components/messages/ErrorMessage';

class FinancialInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            services: [],
            selectedService: null,
            received: 0,
            available: 0,
            budget: 0,
            unbudgeted: 0,
            expended: 0,
            remainingCommitments: 0,
            remainingFunds: 0,
        };
    }

    componentDidMount = async () => {
        try {
            const { data: services } = await ServicesApi.getServices();
            // add the "All" option
            services.unshift({ id: 'all', name: 'All' });
            this.setState({ services });
            this.updateContent(null);
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    };

    async updateContent(e) {
        let financial_information = null;
        let selectedValue = 'all';
        let selectedLabel = 'All';
        if (e && e.value !== undefined && e.value !== 'all') {
            selectedValue = e.value;
            selectedLabel = e.label;
            const response = await ServicesApi.fetchFinancialInformation(selectedValue);
            financial_information = response.data;
        } else {
            const response = await ProjectsApi.fetchAllFinancialInformation();
            financial_information = response.data;
        }
        this.setState({
            selectedService: { value: selectedValue, label: selectedLabel },
            remaining_commitments: financial_information.remaining_commitments,
            remaining_funds: financial_information.remaining_funds,
            budget: financial_information.budget,
            total_available: financial_information.total_available,
            total_expended: financial_information.total_expended,
            total_received: financial_information.total_received,
            unbudgeted: financial_information.unbudgeted,
        });
    }

    render() {
        let servicesOptions = [];
        const {
            services,
            total_received,
            total_available,
            budget,
            unbudgeted,
            remaining_commitments,
            remaining_funds,
            total_expended,
        } = this.state;

        if (services) {
            for (let i = 0; i < this.state.services.length; i++) {
                servicesOptions.push({
                    value: this.state.services[i].id,
                    label: this.state.services[i].name,
                });
            }
            servicesOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        return (
            <div>
                <div>
                    <label className="FieldLabel">Service</label>
                    <Select
                        value={this.state.selectedService}
                        options={servicesOptions}
                        onChange={(e) => {
                            this.updateContent(e);
                        }}
                    />
                </div>
                <div className="Companion-form">
                    <div className="flex" style={{ marginTop: 16, marginBottom: 8 }}>
                        <div className="bold flex-1">Financial Information</div>
                        <div>
                            <HelpModal>
                                <table className="Table-info">
                                    <thead>
                                        <tr>
                                            <th width="240px">&nbsp;</th>
                                            <th>Definition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-align-right bold">Total Received:</td>
                                            <td>Total amount received via PIA mods and others sources.</td>
                                        </tr>
                                        <tr>
                                            <td className="text-align-right bold">Total Available to Project:</td>
                                            <td>Amount available to project manager to accomplish the effort.</td>
                                        </tr>
                                        <tr>
                                            <td className="text-align-right bold">Total Budget:</td>
                                            <td>
                                                The projected amount required to complete the project; it includes all
                                                planned expenses and all transfers out of the project.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-align-right bold">Total Expended:</td>
                                            <td>
                                                The sum of all the invoices paid, credit card charges, travel vouchers,
                                                transfers out of the project, etc.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-align-right bold">Remaining Budget:</td>
                                            <td>Amount of the budgeted funds that have not been expended.</td>
                                        </tr>
                                        <tr>
                                            <td className="text-align-right bold">Unbudgeted:</td>
                                            <td>Amount of funds not allocated against a specific planned expense.</td>
                                        </tr>
                                        <tr>
                                            <td className="text-align-right bold">Remaining Funds:</td>
                                            <td>
                                                Amount of funds remaining in the bank bookmarked against the project.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </HelpModal>
                        </div>
                    </div>

                    <table className="Data-list">
                        <tbody>
                            <tr>
                                <td className="label">Total Received:</td>
                                <td>
                                    <div className="Decimal_aligned">
                                        {new ErgoMoney(total_received).getStringAmount()}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Total Available:</td>
                                <td>
                                    <div className="Decimal_aligned">
                                        {new ErgoMoney(total_available).getStringAmount()}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Total Budget:</td>
                                <td>
                                    <div className="Decimal_aligned">{new ErgoMoney(budget).getStringAmount()}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Total Expended:</td>
                                <td>
                                    <div className="Decimal_aligned">
                                        {new ErgoMoney(total_expended).getStringAmount()}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Remaining Budget:</td>
                                <td>
                                    <div className="Decimal_aligned">
                                        {new ErgoMoney(remaining_commitments).getStringAmount()}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Unbudgeted:</td>
                                <td>
                                    <div className="Decimal_aligned">{new ErgoMoney(unbudgeted).getStringAmount()}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Remaining Funds:</td>
                                <td>
                                    <div className="Decimal_aligned">
                                        {new ErgoMoney(remaining_funds).getStringAmount()}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default FinancialInfo;
