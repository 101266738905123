import React from 'react';
import PagingTable from '../../../components/tables/PagingTable';
import errorToast from '../../../components/messages/ErrorMessage';
import ExportCSVButton from '../../../components/buttons/ExportCSVButton';
import AgreementsApi from '../../../api/agreements';

class AgreementSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rowData: null,
        };
        this.onTableReady = this.onTableReady.bind(this);
    }

    async componentDidMount() {
        const { data: agreement } = await AgreementsApi.getAllAgreement();

        this.updateData(agreement);
    }

    onTableReady(params) {
        if (params.exportDataAsCsv && typeof params.exportDataAsCsv === 'function') {
            this.exportDataAsCsv = params.exportDataAsCsv;
        }
    }

    async updateData(data) {
        try {
            if (data.length > 0) {
                const agreementRowData = data.map((agreement) => {
                    let associatedProject = {
                        projectName: '',
                        projectId: '',
                        projectNumber: '',
                        projectPia: '',
                        projectCpo: '',
                        projectUser: '',
                    };
                    if (agreement.projects !== null) {
                        let returnProject = agreement.projects.map((project) => ({
                            projectName: project.project_name,
                            projectId: project.project_id,
                            projectNumber: project.project_number,
                            projectPia: project.project_pia,
                            projectCpo: project.project_cpo,
                            projectUser: project.project_user,
                        }));
                        associatedProject = returnProject[0];
                    }
                    let totalCharges = 0;
                    if (agreement.invoices !== null) {
                        let invoices = agreement.invoices;
                        invoices.forEach((invoice) => {
                            totalCharges += invoice.transaction.amount;
                        });
                    }

                    return {
                        project_num: associatedProject.projectNumber,
                        project_name: associatedProject.projectName,
                        project_id: associatedProject.projectId,
                        status: agreement.status,
                        project_pia: associatedProject.projectPia,
                        project_cpo: associatedProject.projectCpo,
                        project_user: associatedProject.projectUser,
                        vendor: agreement.vendor.name,
                        nte: agreement.nte,
                        number: agreement.number,
                        end_date: agreement.end_date,
                        charges: totalCharges,
                    };
                });
                this.setState({
                    rowData: agreementRowData, //data,
                });
            }
        } catch (errorMessage) {
            errorToast(errorMessage);
        }
    }

    render() {
        const { rowData } = this.state;
        return (
            <div className="Report-output">
                <div className="Form__buttons--Reversed Buttons_format">
                    <div className="Print-button-with-csv">
                        <ExportCSVButton fileName="agreement-summary" exportFunction={this.exportDataAsCsv} />
                    </div>
                </div>
                <div>
                    <PagingTable
                        columns={[
                            {
                                headerName: 'Project No.',
                                field: 'project_num',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'project_id',
                                },
                                type: 'mediumtext',
                            },
                            { headerName: 'Project', field: 'project_name', type: 'mediumtext' },
                            {
                                headerName: 'Status',
                                field: 'status',
                                type: 'shortname',
                            },
                            {
                                headerName: 'CPO',
                                field: 'project_cpo',
                                type: 'shortname',
                            },
                            {
                                headerName: 'PIA',
                                field: 'project_pia',
                                type: 'shortname',
                            },
                            {
                                headerName: 'WBI Contact',
                                field: 'project_user',
                                type: 'shortname',
                            },
                            { headerName: 'Agreement No.', field: 'number', type: 'mediumtext' },
                            {
                                headerName: 'Vendor',
                                field: 'vendor',
                                type: 'name',
                            },
                            { headerName: 'NTE', field: 'nte', type: 'money' },
                            { headerName: 'Total Charges', field: 'charges', type: 'shortmoney' },
                            { headerName: 'End Date', field: 'end_date', type: 'date' },
                        ]}
                        data={rowData}
                        filter={true}
                        sortable={true}
                        onTableReady={this.onTableReady}
                    />
                </div>
            </div>
        );
    }
}

export default AgreementSummary;
