import React, { Component } from 'react';
import { Formik, Form } from 'formik';

import AdminSubNav from '../AdminSubNav';
import SubSubNav, { SubSubNavLink } from '../../components/navigation/SubSubNav';
import PageHeader from '../../components/heading/PageHeader';

import PagingTable from '../../components/tables/PagingTable';
import LabeledField from '../../components/forms/LabeledField';
import yup from '../../yup';

import ProjectsApi from '../../api/projects';
import ServicesApi from '../../api/services';
import CpoApi from '../../api/cpo';
import PiaApi from '../../api/pia';
import TransactionApi from '../../api/transaction';
import ResidualFundApi from '../../api/residualFunds';
import ModApi from '../../api/mods';
import ExpendituresApi from '../../api/expenditures';
import { history } from '../../store/store';

class ArchivedProjectsPage extends Component {
    constructor(props) {
        super(props);
        this.getServicesAndCposNames = this.getServicesAndCposNames.bind(this);
        this.navigate = this.navigate.bind(this);
        this.navigateToDestination = this.navigateToDestination.bind(this);

        this.state = {
            services: [],
            oldProjects: [],
            projectFirstTransactions: {},
            activeProjects: [],
            pias: null,
            cpos: null,
            selectedCpos: null,
            forceSetData: false,
            residualFunds: null,
        };
    }

    componentDidMount = async () => {
        const { data: services } = await ServicesApi.getServices();
        const { data: projects } = await ProjectsApi.fetchProjects();
        const { data: pias } = await PiaApi.getPias();
        const { data: cpos } = await CpoApi.getCpos();
        const { data: residualFunds } = await ResidualFundApi.getResidualFunds();

        let oldProjects = [];
        let activeProjects = [];
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].old && projects[i].status === 'archived') {
                let addedProject = this.getServicesAndCposNames(projects[i], services, cpos, pias);
                const { data: projectTransactions } = await ProjectsApi.fetchProjectTransactions(addedProject.id);
                if (projectTransactions) {
                    if (
                        projectTransactions[0].destination_type === 'Project' ||
                        projectTransactions[0].destination_type === 'ResidualFund'
                    ) {
                        addedProject.transferLocation = projectTransactions[0];
                    } else {
                        addedProject.transferLocation = { amount: 0.0, destination: { name: '' } };
                    }
                }
                oldProjects.push(addedProject);
            }

            if (projects[i].status !== 'archived') {
                activeProjects.push(projects[i]);
            }
        }

        this.setState({
            oldProjects,
            activeProjects,
            services,
            cpos,
            pias,
            residualFunds,
        });
    };

    getServicesAndCposNames(project, services, cpos, pias) {
        if (project) {
            services.forEach((service) => {
                if (project.service_type_id === service.id) {
                    project.service_name = service.name;
                }
            });

            cpos.forEach((cpo) => {
                if (project.cpo_id === cpo.id) {
                    project.cpo_name = cpo.number;

                    pias.forEach((pia) => {
                        if (cpo.pia_id === pia.id) {
                            project.pia_name = pia.name;
                        }
                    });
                }
            });

            if (project.subproject) project.category = 'subproject';
            else project.category = 'project';

            return project;
        }
    }

    handleSubmit = (values, actions) => {
        let user = JSON.parse(localStorage.getItem('WBI.USER'));

        if (values) {
            ProjectsApi.createProject({
                service_type_id: values.service_name.value,
                number: values.number,
                name: values.name,
                cpo_id: values.cpo_id.value,
                start_date: new Date(),
                old: true,
                status: 'archived',
                wbi_lead_user_id: user.id,
                customer_name: 'Customer',
                people: [user.id, ''],
            })
                .then((response) => {
                    let addedProject = response.data;
                    let newOldProjects = this.state.oldProjects;

                    addedProject.pia_name = values.pia.label;
                    addedProject.cpo_name = values.cpo_id.label;
                    addedProject.service_name = values.service_name.label;

                    if (values.destination_type) {
                        let attributes = {
                            amount: values.remaining_funds,
                            initiator_id: user.id,
                            source_id: addedProject.id,
                            source_type: 'Project',
                            destination_type: values.destination_type,
                        };

                        if (values.destination_type === 'ResidualFund')
                            attributes.destination_id = values.residual_fund_id.value;
                        else if (values.destination_type === 'Project')
                            attributes.destination_id = values.project_id.value;
                        else attributes.destination_id = values.id;

                        if (values.destination_type !== 'na') {
                            TransactionApi.createTransaction(attributes).then((response) => {
                                addedProject.transferLocation = response.data;

                                newOldProjects.push(addedProject);
                                this.setState(
                                    {
                                        forceSetData: true,
                                        oldProjects: newOldProjects,
                                    },
                                    function () {
                                        this.setState({ forceSetData: false });
                                    }
                                );
                            });
                        } else {
                            let recievedAttributes = {
                                cpo_id: addedProject.cpo_id,
                                number: values.number,
                                link_to_db: '',
                                total_amount: values.prior_funds,
                                acrns: [
                                    {
                                        amount: values.prior_funds,
                                        name: 'OP',
                                        pr_number: 'OP1',
                                        project_id: addedProject.id,
                                        purpose: 'adding project',
                                    },
                                ],
                            };

                            let expenseAttributes = {
                                cost: values.prior_expenses,
                                account_id: addedProject.id,
                                account_type: 'Project',
                                old_project_flag: true,
                                purchase_date: new Date(),
                                item_description: '',
                            };

                            ModApi.createMod(recievedAttributes).then((response) => {
                                if (response.status === 200) {
                                    let data = response.data;
                                    let acrn;
                                    data.acrns.forEach((e) => {
                                        acrn = {
                                            amount: e.amount,
                                            description: '',
                                            destination_id: e.project_id,
                                            reason: e.purpose,
                                            source_id: data.id,
                                            destination_type: 'Project',
                                            source_type: 'Mod',
                                        };

                                        TransactionApi.createTransaction(acrn)
                                            .then((response) => {
                                                if (response.status !== 200) return;
                                                let newProjectFirstTransactions = this.state.projectFirstTransactions;
                                                newProjectFirstTransactions[addedProject.id] = response.data;
                                                this.setState(
                                                    {
                                                        forceSetData: true,
                                                        oldProjects: newOldProjects,
                                                        projectFirstTransactions: newProjectFirstTransactions,
                                                    },
                                                    function () {
                                                        this.setState({ forceSetData: false });
                                                    }
                                                );
                                            })
                                            .catch((errorMessage) => {
                                                this.setState({ errorMessage });
                                            });
                                    });
                                }
                            });

                            ExpendituresApi.createExpenditureNoApprover(expenseAttributes)
                                .then((response) => {
                                    if (response.status !== 200) return;
                                })
                                .catch((errorMessage) => {
                                    this.setState({ errorMessage });
                                });
                        }
                    }
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                });
        }

        actions.resetForm();
    };

    navigate(type, id) {
        let location = '';
        if (type === 'ResidualFund') {
            location = '/finance/residualFunds/';
        } else if (type === 'Project') {
            location = '/projects/' + id;
        }
        if (location !== '') {
            history.push(location);
        }
    }

    navigateToDestination(event) {
        let type = this.state.projectFirstTransactions[event.data.id].destination_type;
        let id = this.state.projectFirstTransactions[event.data.id].destination_id;
        this.navigate(type, id);
    }

    filterCpos(e) {
        let selectedCpos = [];
        if (this.state.cpos) {
            this.state.cpos.forEach((cpo) => {
                if (cpo.pia_id === e.value) {
                    selectedCpos.push(cpo);
                }
            });
        }
        this.setState({ selectedCpos });
    }

    render() {
        const { match } = this.props;
        let servicesOptions = [];
        let piaOptions = [];
        let cpoOptions = [];
        let osOptions = [];
        let projectOptions = [];
        let i;
        if (this.state.services) {
            for (i = 0; i < this.state.services.length; i++) {
                servicesOptions.push({
                    value: this.state.services[i].id,
                    label: this.state.services[i].name,
                    disabled: this.state.services[i].disabled,
                });
            }
            servicesOptions = servicesOptions.filter((service) => service.disabled === false);
        }

        if (this.state.pias) {
            this.state.pias.forEach((pia) => {
                piaOptions.push({ label: pia.name, value: pia.id });
            });
        }

        if (this.state.selectedCpos) {
            this.state.selectedCpos.forEach((cpo) => {
                cpoOptions.push({ label: cpo.number, value: cpo.id });
            });
        }

        if (this.state.residualFunds) {
            this.state.residualFunds.forEach((os) => {
                osOptions.push({ label: os.name, value: os.id });
            });
            osOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        if (this.state.activeProjects) {
            this.state.activeProjects.forEach((active) => {
                projectOptions.push({ label: active.name, value: active.id });
            });
            projectOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        return (
            <div>
                <PageHeader title="Old Projects" />
                <AdminSubNav />
                <SubSubNav>
                    <SubSubNavLink to="/admin/oldProjects" exact>
                        Active Projects
                    </SubSubNavLink>
                    <SubSubNavLink to={match.url}>Archived Projects</SubSubNavLink>
                </SubSubNav>
                <div className="Single-layout Companion-form">
                    <Formik
                        initialValues={{
                            service_name: '',
                            number: '',
                            name: '',
                            cpo_id: '',
                            pia: '',
                            remaining_funds: '',
                            prior_funds: '',
                            prior_expenses: '',
                            destination_type: '',
                            office_symbol_id: '',
                            project_id: '',
                        }}
                        validationSchema={yup.object().shape({
                            number: yup.string().required(),
                            service_name: yup.string().required(),
                            name: yup.string().required(),
                            pia: yup.string().required(),
                            cpo_id: yup.string().required(),
                            remaining_funds: yup.number().required(),
                            prior_funds: yup.number().required(),
                            prior_expenses: yup.number().required(),
                            destination_type: yup.string().required(),
                            residual_fund_id: yup.string().required(),
                            project_id: yup.string().required(),
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({ values, errors, touched, isValid, isSubmitting, resetForm, setFieldValue }) => (
                            <Form>
                                <LabeledField
                                    label="Service Area*"
                                    name="service_name"
                                    component="select"
                                    options={servicesOptions}
                                    onChange={(e) => {
                                        setFieldValue('service_name', e);
                                    }}
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="Project No.*"
                                    name="number"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="Project Name*"
                                    name="name"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="PIA*"
                                    name="pia"
                                    component="select"
                                    options={piaOptions}
                                    errors={errors}
                                    touched={touched}
                                    onChange={(e) => {
                                        this.filterCpos(e);
                                        setFieldValue('pia', e);
                                    }}
                                />
                                {values.pia && (
                                    <LabeledField
                                        label="CPO*"
                                        name="cpo_id"
                                        component="select"
                                        options={cpoOptions}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            setFieldValue('cpo_id', e);
                                        }}
                                    />
                                )}
                                <LabeledField
                                    label="Move Remaining Funds to Either"
                                    name="destination_type"
                                    component="Radio"
                                    currentValue={values.destination_type}
                                    options={[
                                        { label: 'Residual Fund', value: 'ResidualFund' },
                                        { label: 'Active Project', value: 'Project' },
                                        { label: 'N/A', value: 'na' },
                                    ]}
                                    errors={errors}
                                    touched={touched}
                                />
                                {(values.destination_type === 'Project' ||
                                    values.destination_type === 'ResidualFund') && (
                                    <LabeledField
                                        label="Remaining Funds Amount"
                                        name="remaining_funds"
                                        type="text"
                                        component="Money"
                                        errors={errors}
                                        touched={touched}
                                    />
                                )}
                                {values.destination_type === 'na' && (
                                    <LabeledField
                                        label="Prior Funding"
                                        name="prior_funds"
                                        type="text"
                                        component="Money"
                                        errors={errors}
                                        touched={touched}
                                    />
                                )}
                                {values.destination_type === 'na' && (
                                    <LabeledField
                                        label="Prior Expenses"
                                        name="prior_expenses"
                                        type="text"
                                        component="Money"
                                        errors={errors}
                                        touched={touched}
                                    />
                                )}
                                {values.destination_type === 'ResidualFund' && (
                                    <LabeledField
                                        name="residual_fund_id"
                                        type="text"
                                        component="select"
                                        options={osOptions}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            setFieldValue('residual_fund_id', e);
                                        }}
                                    />
                                )}
                                {values.destination_type === 'Project' && (
                                    <LabeledField
                                        name="project_id"
                                        type="text"
                                        component="select"
                                        options={projectOptions}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            setFieldValue('project_id', e);
                                        }}
                                    />
                                )}
                                <div className="Form__buttons--Reversed Buttons_format">
                                    <button type="button" onClick={() => resetForm()}>
                                        Cancel
                                    </button>
                                    <button type="submit">Save</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="Table-styling">
                    <PagingTable
                        data={this.state.oldProjects}
                        columns={[
                            { headerName: 'Service Area', field: 'service_name' },
                            { headerName: 'Project No.', field: 'number', sort: 'desc' },
                            { headerName: 'Project Name', field: 'name' },
                            { headerName: 'PIA', field: 'pia_name' },
                            { headerName: 'CPO', field: 'cpo_name' },
                            {
                                headerName: 'Transfer Amount',
                                field: 'transferLocation.amount',
                                type: 'money',
                                valueGetter: (params) => {
                                    if (params.data.transferLocation.amount) {
                                        return params.data.transferLocation.amount;
                                    }
                                    return 0.0;
                                },
                            },
                            {
                                headerName: 'Transfer Location',
                                field: 'transferLocation.destination.name',
                                onCellClicked: this.navigateToDestination,
                                cellRenderer: (params) => {
                                    if (params.data && params.data.name) {
                                        return `<a>${params.data.transferLocation.destination.name}</a>`;
                                    }
                                    return null;
                                },
                            },
                        ]}
                        addedNewData={this.state.forceSetData}
                        animateRows={true}
                        filter={true}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}

export default ArchivedProjectsPage;
