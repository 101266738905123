import api from './api';

import endpoints from './endpoints';

const projectTypeRoute = endpoints.PROJECT_TYPE;

export default {
    getProjectTypes() {
        return api.get(projectTypeRoute, 'Error getting list of project types');
    },

    updateProjectType(ptId, ptAttributes) {
        return api.put(`${projectTypeRoute}/${ptId}`, ptAttributes, 'Error updating project type');
    },

    updateProjectTypes(projectTypes) {
        return api.put(projectTypeRoute, projectTypes, 'Error updating list of project types');
    },

    createProjectType(ptAttributes) {
        return api.post(projectTypeRoute, ptAttributes, 'Error creating project type');
    },
};
