import ErgoMoney from '../../../utils/ErgoMoney';
import createErgoDateForDisplay from '../../../utils/DateUtils';
import { phoneFormat, capitalizeWord } from '../../../utils/utils';
import { dateComparator, dateSortComparator } from './dateUtil';
import { tableStringComparator } from './tableUtils';

const tableColumnsTypes = {
    date: {
        width: 100,
        valueFormatter: ({ value }) => {
            if (value) {
                return createErgoDateForDisplay(value);
            }
            return null;
        },
        cellEditor: 'DateEditor',
        cellEditorParams: {
            cellRenderer(params) {
                return params.value;
            },
        },
        filter: 'agDateColumnFilter',
        filterParams: { browserDatePicker: true, comparator: dateComparator },
        comparator: dateSortComparator,
    },
    money: {
        width: 150,
        valueFormatter: ({ value }) => (value === null ? null : new ErgoMoney(value).getStringAmount()),
        cellClass: 'Align-right',
    },
    shortmoney: {
        width: 100,
        valueFormatter: ({ value }) => (value === null ? null : new ErgoMoney(value).getStringAmount()),
        cellClass: 'Align-right',
    },
    shorttextstatus: {
        width: 200,
        valueFormatter: ({ value }) => (value === null ? null : capitalizeWord(value)),
        comparator: tableStringComparator,
    },
    checkbox: {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        maxWidth: 45,
        width: 45,
        minWidth: 45,
        filter: false,
        sortable: false,
    },
    mediumshorttext: {
        width: 250,
        autoHeight: true,
        cellStyle: { 'white-space': 'normal !important' },
        comparator: tableStringComparator,
    },
    mediumtext: {
        width: 300,
        autoHeight: true,
        cellStyle: { 'white-space': 'normal !important' },
        comparator: tableStringComparator,
    },
    mediumlongtext: {
        width: 350,
        autoHeight: true,
        comparator: tableStringComparator,
    },
    longtext: {
        width: 600,
        autoHeight: true,
        cellStyle: { 'white-space': 'normal !important' },
        comparator: tableStringComparator,
    },
    phone: {
        valueFormatter: ({ value }) => (value === null ? null : phoneFormat(value)),
        width: 125,
    },
    symbol: {
        maxWidth: 75,
        minWidth: 75,
        width: 75,
        sortable: true,
    },
    type: {
        width: 125,
    },
    name: {
        width: 175,
        comparator: tableStringComparator,
    },
    status: {
        width: 125,
    },
    number: {
        width: 100,
    },
    idNum: {
        width: 180,
    },
    zip: {
        width: 75,
    },
    email: {
        width: 300,
    },
    shorttext: {
        width: 200,
    },
    mediumnumber: {
        width: 80,
    },
    shortnumber: {
        width: 60,
    },
    shortname: {
        width: 100,
        comparator: tableStringComparator,
    },
    mediumname: {
        width: 130,
        comparator: tableStringComparator,
    },
};

export default tableColumnsTypes;
