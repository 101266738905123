// React
import React from 'react';

// Components
import SectionHeading from '../../components/heading/SectionHeading';
import EditingTable from '../../components/tables/EditingTable';

const StrategicGoalsHistory = (props) => {
    if (!props.history) return <div>loading...</div>;
    return (
        <>
            <div>
                {props.history.length > 0 ? (
                    <>
                        <SectionHeading className="History mt0">Source of Numbers</SectionHeading>
                        <EditingTable
                            data={props.history}
                            columns={[
                                {
                                    headerName: 'Date',
                                    field: 'created_on',
                                    type: 'date',
                                },
                                {
                                    headerName: 'Actual',
                                    field: 'value',
                                },
                                {
                                    headerName: 'Note',
                                    field: 'note',
                                    cellRenderer: 'NotesSymbolRenderer',
                                },
                                {
                                    headerName: 'Initiator',
                                    field: 'initiator',
                                    type: 'longtext',
                                    editable: false,
                                },
                                {
                                    headerName: 'Initiated On',
                                    field: 'created_on',
                                    type: 'date',
                                    editable: false,
                                },
                                {
                                    headerName: 'Last Update By',
                                    field: 'last_updated_by',
                                    type: 'longtext',
                                    editable: false,
                                },
                                {
                                    headerName: 'Updated On',
                                    field: 'updated_on',
                                    type: 'date',
                                    editable: false,
                                },
                                {
                                    headerName: 'Edit',
                                    field: 'edit',
                                    maxWidth: 50,
                                    editable: false,
                                    cellRenderer: 'EditSymbolRenderer',
                                    onCellClicked: props.handleEdit,
                                },
                                {
                                    headerName: 'Del.',
                                    field: 'delete',
                                    maxWidth: 50,
                                    editable: false,
                                    cellRenderer: 'TrashSymbolRenderer',
                                    onCellClicked: props.onRemoveSelected,
                                },
                            ]}
                            addedNewData={props.forceSetData}
                        />
                    </>
                ) : null}
            </div>
        </>
    );
};

export default StrategicGoalsHistory;
