import React from 'react';
import SectionHeading from '../components/heading/SectionHeading';
import FinanceSubNav from './FinanceSubNav';
import ReconciliationUploadForm from './reconciliation/reconciliationUploadForm';

const ReconciliationPage = () => (
    <div>
        <SectionHeading>Reconciliation</SectionHeading>
        <FinanceSubNav />
        <div className="ReconciliationForm">
            <ReconciliationUploadForm />
        </div>
    </div>
);

export default ReconciliationPage;
