import api from './api';

import endpoints from './endpoints';

const usersRoute = endpoints.USERS;

export default {
    updateUser(userId, userAttributes) {
        return api.put(`${usersRoute}/${userId}`, userAttributes, 'Error updating user');
    },

    getUsers() {
        return api.get(usersRoute, 'Error getting list of users');
    },

    getAllUsers() {
        return api.get(`${usersRoute}?filter=all`, 'Error getting list of users');
    },

    getUserOptions() {
        return api.get(`${usersRoute}/options`, 'Error getting list of user options');
    },

    removeUser(userId) {
        return api.delete(
            `${usersRoute}/${userId}`,
            'User is not able to be removed, make sure they are not connected to a project.'
        );
    },

    listSiteRoles() {
        return api.get(`${usersRoute}/site-roles`, 'Error getting site roles');
    },
};
