// React
import React, { Component } from 'react';

// Libraries
import { Formik, Form } from 'formik';

// Components
import AdminSubNav from './AdminSubNav';
import SubSubNav, { SubSubNavLink } from '../components/navigation/SubSubNav';
import PageHeader from '../components/heading/PageHeader';
import PagingTable from '../components/tables/PagingTable';
import LabeledField from '../components/forms/LabeledField';
import yup from '../yup';
import { confirm } from '../components/modals/ConfirmModal';
import errorToast from '../components/messages/ErrorMessage';

// API
import ProjectsApi from '../api/projects';
import ServicesApi from '../api/services';
import CpoApi from '../api/cpo';
import PiaApi from '../api/pia';
import TransactionApi from '../api/transaction';
import ExpendituresApi from '../api/expenditures';
import ModApi from '../api/mods';

class OldProjectsPage extends Component {
    constructor(props) {
        super(props);
        this.getServicesAndCposNames = this.getServicesAndCposNames.bind(this);

        this.state = {
            services: [],
            oldProjects: [],
            pias: null,
            cpos: null,
            selectedCpos: null,
            forceSetData: false,
            category: null,
            parentOptions: [{ label: '', value: '' }],
            project_number: '',
            subprojects: [],
        };
    }

    componentDidMount = async () => {
        const { data: services } = await ServicesApi.getServices();
        const { data: projects } = await ProjectsApi.fetchProjects();
        const { data: pias } = await PiaApi.getPias();
        const { data: cpos } = await CpoApi.getCpos();
        const { data: parentProjects } = await ProjectsApi.fetchParentProjectNames();
        const { data: subprojects } = await ProjectsApi.fetchSubProjectNames();
        const parentOptions = [];
        parentProjects.map((parent) => {
            parentOptions.push({
                label: parent.number + ' - ' + parent.name,
                value: {
                    parent_name: parent.name,
                    parent_number: parent.number,
                    parent_cpo_id: parent.cpo_id,
                },
            });
            return null;
        });

        let oldProjects = [];

        for (let i = 0; i < projects.length; i++) {
            if (projects[i].old && projects[i].status === 'active') {
                let addedProject = this.getServicesAndCposNames(projects[i], services, cpos, pias);

                oldProjects.push(addedProject);
            }
        }

        this.setState({
            oldProjects,
            services,
            cpos,
            pias,
            parentOptions,
            subprojects,
        });
    };

    getServicesAndCposNames(project, services, cpos, pias) {
        if (project) {
            services.forEach((service) => {
                if (project.service_type_id === service.id) {
                    project.service_name = service.name;
                }
            });

            cpos.forEach((cpo) => {
                if (project.cpo_id === cpo.id) {
                    project.cpo_name = cpo.number;

                    pias.forEach((pia) => {
                        if (cpo.pia_id === pia.id) {
                            project.pia_name = pia.name;
                        }
                    });
                }
            });

            if (project.subproject) project.category = 'subproject';
            else project.category = 'project';
            return project;
        }
    }

    handleSubmit = (values, actions) => {
        let isSubProject = values.category === 'subproject';
        let user = JSON.parse(localStorage.getItem('WBI.USER'));
        let projectAttributes = {};

        projectAttributes = {
            number: values.number,
            name: values.name,
            service_type_id: values.service_name.value,
            subproject: isSubProject,
            cpo_id: values.cpo_id.value,
            prior_funds: values.prior_funds,
            prior_expenses: values.prior_expenses,
            start_date: new Date(),
            old: true,
            wbi_lead_user_id: user.id,
            customer_name: 'Customer',
            people: [user.id, ''],
        };
        if (values) {
            ProjectsApi.createProject(projectAttributes)
                .then((response) => {
                    let addedProject = response.data;

                    let newOldProjects = [];
                    if (this.state.oldProjects) newOldProjects = this.state.oldProjects;

                    addedProject.pia_name = values.pia.label;
                    addedProject.cpo_name = values.cpo_id.label;
                    addedProject.service_name = values.service_name.label;

                    if (addedProject.subproject) addedProject.category = 'subproject';
                    else addedProject.category = 'project';

                    newOldProjects.push(addedProject);

                    let recievedAttributes = {
                        cpo_id: addedProject.cpo_id,
                        number: values.number,
                        link_to_db: '',
                        total_amount: addedProject.prior_funds,
                        received_date: new Date(),
                        acrns: [
                            {
                                amount: addedProject.prior_funds,
                                name: 'OP',
                                pr_number: 'OP1',
                                project_id: addedProject.id,
                                purpose: 'adding project',
                            },
                        ],
                    };

                    let expenseAttributes = {
                        cost: addedProject.prior_expenses,
                        account_id: addedProject.id,
                        account_type: 'Project',
                        old_project_flag: true,
                        purchase_date: new Date(),
                        item_description: '',
                    };

                    ModApi.createMod(recievedAttributes).then((response) => {
                        let data = response.data;
                        let acrn;
                        data.acrns.forEach((e) => {
                            acrn = {
                                amount: e.amount,
                                description: '',
                                destination_id: e.project_id,
                                reason: e.purpose,
                                source_id: data.id,
                                destination_type: 'Project',
                                source_type: 'Mod',
                            };

                            TransactionApi.createTransaction(acrn).catch((errorMessage) => {
                                this.setState({
                                    errorMessage,
                                });
                                errorToast(errorMessage);
                            });
                        });
                    });

                    ExpendituresApi.createExpenditureNoApprover(expenseAttributes).catch((errorMessage) => {
                        this.setState({ errorMessage });
                        errorToast(errorMessage);
                    });

                    this.setState(
                        {
                            oldProjects: newOldProjects,
                            forceSetData: true,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                            actions.resetForm();
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                    errorToast(errorMessage);
                });
        }
    };

    removeProject = async (event) => {
        let projectToRemove = event.node.data;
        let message = (
            <div>
                <p>Are you sure you want to delete this project?</p>{' '}
            </div>
        );
        const confirmed = await confirm({
            text: message,
            acceptText: 'ok',
            rejectText: 'cancel',
        });

        if (confirmed) {
            ProjectsApi.removeProject(projectToRemove.id, projectToRemove)
                .then(() => {
                    let newOldProjects = this.state.oldProjects.filter((e) => e.id !== projectToRemove.id);
                    this.setState(
                        {
                            oldProjects: newOldProjects,
                            forceSetData: true,
                        },
                        function () {
                            this.setState({ forceSetData: false });
                        }
                    );
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage });
                    errorToast(errorMessage);
                });
        }
    };

    filterCpos(e) {
        let selectedCpos = [];
        if (this.state.cpos) {
            this.state.cpos.forEach((cpo) => {
                if (cpo.pia_id === e.value) {
                    selectedCpos.push(cpo);
                }
            });
        }
        this.setState({ selectedCpos });
    }

    render() {
        const { match } = this.props;
        let servicesOptions = [];
        let piaOptions = [];
        let cpoOptions = [];

        let i;
        if (this.state.services) {
            for (i = 0; i < this.state.services.length; i++) {
                servicesOptions.push({
                    value: this.state.services[i].id,
                    label: this.state.services[i].name,
                    disabled: this.state.services[i].disabled,
                });
            }
            servicesOptions = servicesOptions.filter((service) => service.disabled !== true);
            servicesOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        if (this.state.pias) {
            this.state.pias.forEach((pia) => {
                piaOptions.push({ label: pia.name, value: pia.id });
            });
            piaOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        if (this.state.selectedCpos) {
            this.state.selectedCpos.forEach((cpo) => {
                cpoOptions.push({ label: cpo.number, value: cpo.id });
            });
            cpoOptions.sort((a, b) => a.label.localeCompare(b.label));
        }

        return (
            <div>
                <PageHeader title="Old Projects" />
                <AdminSubNav />
                <SubSubNav>
                    <SubSubNavLink to={match.url} exact>
                        Active Projects
                    </SubSubNavLink>
                    <SubSubNavLink to={match.url + '/archivedProjects'}>Archived Projects</SubSubNavLink>
                </SubSubNav>
                <div className="Single-layout Companion-form">
                    <Formik
                        initialValues={{
                            number: '',
                            name: '',
                            service_name: '',
                            category: '',
                            cpo_id: '',
                            pia: '',
                            status: '',
                            prior_funds: '',
                            prior_expenses: '',
                        }}
                        validationSchema={yup.object().shape({
                            category: yup.string().required(),
                            number: yup.string().required(),
                            name: yup.string().required(),
                            cpo_id: yup.string().required(),
                            service_name: yup.string().required(),
                            pia: yup.string().required(),
                            prior_funds: yup.number().required(),
                            prior_expenses: yup.number().required(),
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({ values, errors, touched, resetForm, setFieldValue }) => (
                            <Form>
                                <LabeledField
                                    label="Category*"
                                    name="category"
                                    component="Radio"
                                    currentValue={values.category}
                                    options={[
                                        { label: 'Project', value: 'project' },
                                        { label: 'Sub-Project', value: 'subproject' },
                                    ]}
                                    onChange={(e) => {
                                        this.setState({ category: e.value });
                                    }}
                                    errors={errors}
                                    touched={touched}
                                />
                                {values.category === 'subproject' ? (
                                    <LabeledField
                                        label="Parent Project Number - Name*"
                                        name="parent_number"
                                        component="select"
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            setFieldValue('parent_number', e);
                                            if (this.state.project_number === '') {
                                                // generate number from existing children numbers
                                                let parentNumber = e.value.parent_number;
                                                let projectNumber = parentNumber + '-';
                                                let children = this.state.subprojects.filter((child) =>
                                                    child.number.includes(parentNumber)
                                                );
                                                let newLetter = '';
                                                if (children.length > 0) {
                                                    let lastChild;
                                                    children.forEach((c) => {
                                                        let childNum = c.number.substring(0, c.number.lastIndexOf('-'));
                                                        if (childNum === parentNumber) {
                                                            lastChild = c;
                                                        }
                                                    });

                                                    let lastChildNumber = lastChild.number;
                                                    newLetter = String.fromCharCode(
                                                        lastChildNumber.charCodeAt(lastChildNumber.length - 1) + 1
                                                    );
                                                } else {
                                                    newLetter = 'A';
                                                }
                                                projectNumber += newLetter;
                                                setFieldValue('number', projectNumber);
                                            }
                                            this.setState({
                                                parent_number: e.value.parent_number,
                                            });
                                        }}
                                        options={this.state.parentOptions}
                                        required
                                    />
                                ) : null}
                                <LabeledField
                                    label="PIA*"
                                    name="pia"
                                    component="select"
                                    options={piaOptions}
                                    errors={errors}
                                    touched={touched}
                                    onChange={(e) => {
                                        this.filterCpos(e);
                                        setFieldValue('pia', e);
                                    }}
                                />
                                {values.pia && (
                                    <LabeledField
                                        label="CPO*"
                                        name="cpo_id"
                                        component="select"
                                        options={cpoOptions}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e) => {
                                            setFieldValue('cpo_id', e);
                                        }}
                                    />
                                )}
                                <LabeledField
                                    label="Project No.*"
                                    name="number"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    readOnly={values.category === 'subproject'}
                                    onChange={(e) => {
                                        this.setState({
                                            project_number: e.target.value,
                                        });
                                        setFieldValue('number', e.target.value);
                                    }}
                                />
                                <LabeledField
                                    label="Project Name*"
                                    name="name"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="Service Area*"
                                    name="service_name"
                                    component="select"
                                    options={servicesOptions}
                                    errors={errors}
                                    touched={touched}
                                    onChange={(e) => {
                                        setFieldValue('service_name', e);
                                    }}
                                />
                                <LabeledField
                                    label="Prior Funding*"
                                    name="prior_funds"
                                    type="text"
                                    component="Money"
                                    errors={errors}
                                    touched={touched}
                                />
                                <LabeledField
                                    label="Prior Expenses*"
                                    name="prior_expenses"
                                    type="text"
                                    component="Money"
                                    errors={errors}
                                    touched={touched}
                                />
                                <div className="Form__buttons--Reversed Buttons_format">
                                    <button type="button" onClick={() => resetForm()}>
                                        Cancel
                                    </button>
                                    <button type="submit">Save</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="Table-styling">
                    <PagingTable
                        addedNewData={this.state.forceSetData}
                        data={this.state.oldProjects}
                        columns={[
                            {
                                headerName: 'Project No.',
                                field: 'number',
                                sort: 'desc',
                                type: 'idNum',
                            },
                            {
                                headerName: 'Project Name',
                                field: 'name',
                                type: 'longtext',
                                cellRenderer: 'LinkRenderer',
                                cellRendererParams: {
                                    routeBase: '/projects/',
                                    routeKeyField: 'id',
                                },
                            },
                            { headerName: 'Service Area', field: 'service_name', type: 'longtext' },
                            { headerName: 'Category', field: 'category', type: 'status' },
                            { headerName: 'PIA', field: 'pia_name', type: 'number' },
                            { headerName: 'CPO', field: 'cpo_name', type: 'number' },
                            { headerName: 'Status', field: 'status', type: 'status' },
                            { headerName: 'Prior Funding', field: 'prior_funds', type: 'money' },
                            {
                                headerName: 'Prior Expenses',
                                field: 'prior_expenses',
                                type: 'money',
                            },
                        ]}
                        filter={true}
                        sortable={true}
                    />
                </div>
            </div>
        );
    }
}

export default OldProjectsPage;
